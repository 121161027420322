import 'regenerator-runtime/runtime';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/python/python';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sass/sass';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/hint/sql-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/dialog/dialog';
import CodeMirror from 'codemirror/lib/codemirror';
import jsonlint from 'jsonlint-mod';
import {html, LitElement, unsafeCSS} from 'lit-element';
import styles from '!to-string-loader!css-loader!sass-loader!../scss/components/codemirror.scss';

window.jsonlint = jsonlint;

class CodeMirrorWebComponent extends LitElement {
    static get properties() {
        return {
            hasFocus: {type: Boolean},
            value: {type: String},
            readOnly: {type: Boolean},
            mode: {type: String},
            indentWidth : {type : Number}
        };
    }


    static get styles() {
        return unsafeCSS(styles);
    }

    render() {
        return html`
             <div class="codemirror-wrapper">
                <div class="codemirror"/>
             </div>
       `;
    }

    constructor() {
        super();
        this.value = '';
        this.readOnly = false;
        this.hasFocus = false;
        this.mode = 'text/plain';
        this.indentWidth = 2;
        this._codeMirror = null;
    }

    firstUpdated(changedProperties) {
        const indentWidth = this.indentWidth;
        const editorElement = this.shadowRoot.querySelector('.codemirror');
        this._codeMirror = CodeMirror(editorElement, {
            lineNumbers: true,
            styleActiveLine: {nonEmpty: true},
            smartIndent: true,
            indentWithTabs: false,
            lint: true,
            tabSize: indentWidth,
            indentUnit: indentWidth,
            readOnly: this.readOnly,
            mode: this.mode,
            value: this.value,
            gutters: ['CodeMirror-lint-markers'],
            extraKeys: {
                'Tab': 'indent',
                'Shift-Tab': 'indentLess'
            }
        });
        this._codeMirror.on('change', () => {
            this.dispatchEvent(new Event('code_change'));
        });
    }

    get value() {
        if (!this._codeMirror) return '';
        return this._codeMirror.getValue();
    }

    set value(value) {
        if (this._codeMirror && value !== null) {
            const prevScrollPosition = this._codeMirror.getScrollInfo();
            this._codeMirror.setValue(value);
            this._codeMirror.scrollTo(prevScrollPosition.left, prevScrollPosition.top);
        }
    }

    updated(changedProperties) {
        if (this._codeMirror) {
            if (changedProperties.has('hasFocus')) {
                if (this.hasFocus) {
                    this._codeMirror.focus();
                    this._codeMirror.setCursor(this._codeMirror.lineCount(), 0);
                }
            }
            if (changedProperties.has('readOnly')) {
                this._codeMirror.setOption('readOnly', this.readOnly);
                this.shadowRoot
                    .querySelector('.codemirror-wrapper')
                    .classList
                    .toggle('disabled-element', this.readOnly);
            }
            if (changedProperties.has('mode')) {
                this._codeMirror.setOption('mode', this.mode);
            }
            if (changedProperties.has('indentWidth')) {
                this._codeMirror.setOption('tabSize', this.indentWidth);
                this._codeMirror.setOption('indentUnit', this.indentWidth);
            }
        }
    }
}

customElements.define('code-mirror', CodeMirrorWebComponent);

