const appStateStorageKey = 'app_state';

export function getAppState() {
    const appStateStr = localStorage.getItem(appStateStorageKey);
    if(appStateStr != null)
        return JSON.parse(appStateStr)
    else
        return {
            selectedBranchKey: "prod"
        }
}

export function saveAppState(appState){
    localStorage.setItem(appStateStorageKey, JSON.stringify(appState))
}
