function registerInterception(func) {

    (XHR => {

        const send = XHR.prototype.send;

        XHR.prototype.send = function (data) {
            const self = this;

            function onLoad(event) {
                if (self.readyState === 4 /* complete */) {
                    func(self);
                }
            }

            this.addEventListener('load', onLoad, true);

            send.call(this, data);
        };
    })(XMLHttpRequest);
}

export function handleAjaxRedirects() {
    registerInterception((request) => {
            if (request.status === 278) { // Redirect
                const redirectUrl = JSON.parse(request.response).url;
                window.location.replace(redirectUrl);
            }
        }
    );
}
