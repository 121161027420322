(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File === 'function' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[94m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fC.bJ === region.ge.bJ)
	{
		return 'on line ' + region.fC.bJ;
	}
	return 'on lines ' + region.fC.bJ + ' through ' + region.ge.bJ;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return word
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.kh,
		impl.a,
		impl.mr,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_dispatchEffects(managers, result.b, subscriptions(model));
	}

	_Platform_dispatchEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				p: bag.n,
				q: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.q)
		{
			x = temp.p(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		r: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		r: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].r;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		gD: func(record.gD),
		W: record.W,
		V: record.V
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.gD;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.W;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.V) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.kh,
		impl.a,
		impl.mr,
		function(sendToApp, initialModel) {
			var view = impl.mZ;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.kh,
		impl.a,
		impl.mr,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.fB && impl.fB(sendToApp)
			var view = impl.mZ;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.h$);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.mC) && (_VirtualDom_doc.title = title = doc.mC);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.k6;
	var onUrlRequest = impl.k7;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		fB: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.gS === next.gS
							&& curr.j5 === next.j5
							&& curr.lp.a === next.lp.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		kh: function(flags)
		{
			return A3(impl.kh, flags, _Browser_getUrl(), key);
		},
		mZ: impl.mZ,
		a: impl.a,
		mr: impl.mr
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { j2: 'hidden', h7: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { j2: 'mozHidden', h7: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { j2: 'msHidden', h7: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { j2: 'webkitHidden', h7: 'webkitvisibilitychange' }
		: { j2: 'hidden', h7: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		g3: _Browser_getScene(),
		hs: {
			m2: _Browser_window.pageXOffset,
			m3: _Browser_window.pageYOffset,
			m0: _Browser_doc.documentElement.clientWidth,
			j0: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		m0: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		j0: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			g3: {
				m0: node.scrollWidth,
				j0: node.scrollHeight
			},
			hs: {
				m2: node.scrollLeft,
				m3: node.scrollTop,
				m0: node.clientWidth,
				j0: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			g3: _Browser_getScene(),
			hs: {
				m2: x,
				m3: y,
				m0: _Browser_doc.documentElement.clientWidth,
				j0: _Browser_doc.documentElement.clientHeight
			},
			ji: {
				m2: x + rect.left,
				m3: y + rect.top,
				m0: rect.width,
				j0: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.eY.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.eY.b, xhr)); });
		$elm$core$Maybe$isJust(request.hl) && _Http_track(router, xhr, request.hl.a);

		try {
			xhr.open(request.kR, request.mO, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.mO));
		}

		_Http_configureRequest(xhr, request);

		request.h$.a && xhr.setRequestHeader('Content-Type', request.h$.a);
		xhr.send(request.h$.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.gr; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.mA.a || 0;
	xhr.responseType = request.eY.d;
	xhr.withCredentials = request.hH;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		mO: xhr.responseURL,
		ee: xhr.status,
		fE: xhr.statusText,
		gr: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			l1: event.loaded,
			g9: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			ly: event.loaded,
			g9: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.kZ) { flags += 'm'; }
	if (options.h5) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $author$project$Main$LinkClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 7, a: a};
};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.e) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.f),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.f);
		} else {
			var treeLen = builder.e * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.g) : builder.g;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.e);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.f) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.f);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{g: nodeList, e: (len / $elm$core$Array$branchFactor) | 0, f: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {gn: fragment, j5: host, ll: path, lp: port_, gS: protocol, gT: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$Hidden = {$: 0};
var $author$project$Main$DocsPage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$LoggedOutPage = {$: 2};
var $author$project$Main$MainPage = function (a) {
	return {$: 0, a: a};
};
var $author$project$Lib$RemoteData$EmptyLoading = {$: 0};
var $author$project$Pages$DocsPage$defaultModel = {bz: $author$project$Lib$RemoteData$EmptyLoading};
var $author$project$Pages$Main$MainPage$Hidden = {$: 0};
var $author$project$Pages$Main$CommandsView$Hidden = {$: 0};
var $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing = {$: 0};
var $author$project$Pages$Main$EntityListing$TreeView$State = $elm$core$Basics$identity;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$Pages$Main$EntityListing$TreeView$defaultState = {T: $elm$core$Set$empty};
var $author$project$Lib$DragDrop$NotDragging = {$: 0};
var $author$project$Lib$DragDrop$init = $author$project$Lib$DragDrop$NotDragging;
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel = {ag: $author$project$Lib$DragDrop$init, aJ: $elm$core$Dict$empty, J: $elm$core$Maybe$Nothing, en: $author$project$Pages$Main$EntityListing$TreeView$defaultState};
var $author$project$Pages$Main$CommandsView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$CommandsView$Hidden};
var $author$project$Pages$Main$ConnectionsView$Hidden = {$: 0};
var $author$project$Pages$Main$ConnectionsView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$ConnectionsView$Hidden};
var $author$project$Pages$Main$EndpointsView$Hidden = {$: 0};
var $author$project$Pages$Main$EndpointsView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$EndpointsView$Hidden};
var $author$project$Pages$Main$EnvironmentsView$Hidden = {$: 0};
var $author$project$Pages$Main$EnvironmentsView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$EnvironmentsView$Hidden};
var $author$project$Pages$Main$LogsView$defaultModel = {bh: '', bi: '', bv: false, bH: $elm$core$Dict$empty, bL: '', Q: $author$project$Lib$RemoteData$EmptyLoading, gL: 1, gM: 10};
var $author$project$Pages$Main$PrincipalsView$Hidden = {$: 0};
var $author$project$Pages$Main$PrincipalsView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$PrincipalsView$Hidden};
var $author$project$Pages$Main$RevisionsView$Hidden = {$: 0};
var $author$project$Pages$Main$RevisionsView$defaultModel = {bO: $author$project$Pages$Main$RevisionsView$Hidden, aW: $author$project$Lib$RemoteData$EmptyLoading, av: $elm$core$Maybe$Nothing};
var $author$project$Pages$Main$RolesView$Hidden = {$: 0};
var $author$project$Pages$Main$RolesView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$RolesView$Hidden};
var $author$project$Pages$Main$TenantSettingsView$defaultModel = {gl: $author$project$Lib$RemoteData$EmptyLoading};
var $author$project$Pages$Main$ViewsView$Hidden = {$: 0};
var $author$project$Pages$Main$ViewsView$defaultModel = {j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing, ap: $author$project$Pages$Main$EntityListing$GenericEntityListingView$defaultModel, bO: $author$project$Pages$Main$ViewsView$Hidden};
var $author$project$Pages$Main$Repository$emptyRepository = {_: $author$project$Lib$RemoteData$EmptyLoading, O: $author$project$Lib$RemoteData$EmptyLoading, ab: $author$project$Lib$RemoteData$EmptyLoading, iy: $author$project$Lib$RemoteData$EmptyLoading, ai: $author$project$Lib$RemoteData$EmptyLoading, aj: $author$project$Lib$RemoteData$EmptyLoading, ak: $author$project$Lib$RemoteData$EmptyLoading, gg: $author$project$Lib$RemoteData$EmptyLoading, e5: $author$project$Lib$RemoteData$EmptyLoading, fp: $author$project$Lib$RemoteData$EmptyLoading, fq: $author$project$Lib$RemoteData$EmptyLoading, fx: $author$project$Lib$RemoteData$EmptyLoading, g0: $author$project$Lib$RemoteData$EmptyLoading, fL: $author$project$Lib$RemoteData$EmptyLoading, m$: $author$project$Lib$RemoteData$EmptyLoading};
var $author$project$Pages$Main$MainPage$defaultModel = F2(
	function (_v0, route) {
		var selectedBranchKey = _v0.l$;
		return {aA: $author$project$Lib$RemoteData$EmptyLoading, bj: $author$project$Pages$Main$CommandsView$defaultModel, bk: $author$project$Pages$Main$ConnectionsView$defaultModel, bs: $author$project$Pages$Main$EndpointsView$defaultModel, bt: $author$project$Pages$Main$EnvironmentsView$defaultModel, bM: $author$project$Pages$Main$LogsView$defaultModel, bO: $author$project$Pages$Main$MainPage$Hidden, bV: $author$project$Pages$Main$PrincipalsView$defaultModel, n: $author$project$Pages$Main$Repository$emptyRepository, bW: $author$project$Pages$Main$RevisionsView$defaultModel, bX: $author$project$Pages$Main$RolesView$defaultModel, aX: route, l$: selectedBranchKey, b1: $author$project$Pages$Main$TenantSettingsView$defaultModel, Z: $author$project$Lib$RemoteData$EmptyLoading, b9: $author$project$Pages$Main$ViewsView$defaultModel};
	});
var $author$project$Main$routeToDefaultPage = F2(
	function (flags, route) {
		switch (route.$) {
			case 0:
				var mainPageRoute = route.a;
				return $author$project$Main$MainPage(
					A2($author$project$Pages$Main$MainPage$defaultModel, flags, mainPageRoute));
			case 1:
				return $author$project$Main$DocsPage($author$project$Pages$DocsPage$defaultModel);
			default:
				return $author$project$Main$LoggedOutPage;
		}
	});
var $author$project$Routes$Commands = 0;
var $author$project$Routes$MainPage = function (a) {
	return {$: 0, a: a};
};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {an: frag, lk: params, Y: unvisited, mY: value, ay: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.Y;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.mY);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.mY);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.ll),
					$elm$url$Url$Parser$prepareQuery(url.gT),
					url.gn,
					$elm$core$Basics$identity)));
	});
var $author$project$Routes$DocsPage = {$: 1};
var $author$project$Routes$LoggedOutPage = {$: 2};
var $author$project$Routes$Connections = 3;
var $author$project$Routes$Endpoints = 5;
var $author$project$Routes$Environments = 4;
var $author$project$Routes$Logs = 7;
var $author$project$Routes$MissingPermissions = 10;
var $author$project$Routes$Principals = 6;
var $author$project$Routes$Revisions = 8;
var $author$project$Routes$Roles = 2;
var $author$project$Routes$TenantSettings = 9;
var $author$project$Routes$Views = 1;
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.ay;
		var unvisited = _v0.Y;
		var params = _v0.lk;
		var frag = _v0.an;
		var value = _v0.mY;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.ay;
			var unvisited = _v1.Y;
			var params = _v1.lk;
			var frag = _v1.an;
			var value = _v1.mY;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.ay;
		var unvisited = _v0.Y;
		var params = _v0.lk;
		var frag = _v0.an;
		var value = _v0.mY;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $author$project$Routes$mainPageRouteParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			10,
			$elm$url$Url$Parser$s('missingPermissions')),
			A2(
			$elm$url$Url$Parser$map,
			0,
			$elm$url$Url$Parser$s('commands')),
			A2(
			$elm$url$Url$Parser$map,
			1,
			$elm$url$Url$Parser$s('views')),
			A2(
			$elm$url$Url$Parser$map,
			2,
			$elm$url$Url$Parser$s('roles')),
			A2(
			$elm$url$Url$Parser$map,
			3,
			$elm$url$Url$Parser$s('connections')),
			A2(
			$elm$url$Url$Parser$map,
			4,
			$elm$url$Url$Parser$s('environments')),
			A2(
			$elm$url$Url$Parser$map,
			5,
			$elm$url$Url$Parser$s('endpoints')),
			A2(
			$elm$url$Url$Parser$map,
			6,
			$elm$url$Url$Parser$s('principals')),
			A2(
			$elm$url$Url$Parser$map,
			9,
			$elm$url$Url$Parser$s('tenantSettings')),
			A2(
			$elm$url$Url$Parser$map,
			7,
			$elm$url$Url$Parser$s('logs')),
			A2(
			$elm$url$Url$Parser$map,
			8,
			$elm$url$Url$Parser$s('revisions'))
		]));
var $author$project$Routes$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2($elm$url$Url$Parser$map, $author$project$Routes$MainPage, $author$project$Routes$mainPageRouteParser),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$DocsPage,
			$elm$url$Url$Parser$s('docs')),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Routes$LoggedOutPage,
			$elm$url$Url$Parser$s('loggedOut'))
		]));
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Routes$urlToRoute = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Routes$MainPage(0),
		A2($elm$url$Url$Parser$parse, $author$project$Routes$routeParser, url));
};
var $author$project$Main$defaultModel = F3(
	function (flags, url, key) {
		return {
			c$: flags,
			bO: $author$project$Main$Hidden,
			dr: key,
			F: A2(
				$author$project$Main$routeToDefaultPage,
				flags,
				$author$project$Routes$urlToRoute(url))
		};
	});
var $author$project$Main$DocsPageMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$MainPageMsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$NoOp = {$: 0};
var $author$project$Main$PageMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$DocsPage$BeginLoadUserPermissions = {$: 0};
var $author$project$Pages$DocsPage$init = $author$project$Pages$DocsPage$BeginLoadUserPermissions;
var $author$project$Pages$Main$MainPage$BeginLoadUserPermissions = {$: 18};
var $author$project$Pages$Main$MainPage$init = $author$project$Pages$Main$MainPage$BeginLoadUserPermissions;
var $author$project$Main$initMsg = function (model) {
	var _v0 = model.F;
	switch (_v0.$) {
		case 0:
			return $author$project$Main$PageMsg(
				$author$project$Main$MainPageMsg($author$project$Pages$Main$MainPage$init));
		case 1:
			return $author$project$Main$PageMsg(
				$author$project$Main$DocsPageMsg($author$project$Pages$DocsPage$init));
		default:
			return $author$project$Main$NoOp;
	}
};
var $author$project$Utils$CmdUtils$msgToCmd = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var model = A3($author$project$Main$defaultModel, flags, url, key);
		return _Utils_Tuple2(
			model,
			$author$project$Utils$CmdUtils$msgToCmd(
				$author$project$Main$initMsg(model)));
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Main$AppMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Error = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$GotLogout = function (a) {
	return {$: 5, a: a};
};
var $author$project$AppMsg$OnHttpError = function (a) {
	return {$: 3, a: a};
};
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $ccapndave$elm_update_extra$Update$Extra$addCmd = F2(
	function (cmd_, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])));
	});
var $ccapndave$elm_update_extra$Update$Extra$andThen = F3(
	function (update, msg, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var _v1 = A2(update, msg, model);
		var model_ = _v1.a;
		var cmd_ = _v1.b;
		return _Utils_Tuple2(
			model_,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])));
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Utils$HttpUtils$apiErrorResponseToString = function (_v0) {
	var statusCode = _v0.ee;
	var statusText = _v0.fE;
	var message = _v0.gD;
	var callId = _v0.fX;
	return message + ('\n' + ('Kutsun ID : ' + (callId + ('\n' + ('Status : ' + (statusText + (' - ' + $elm$core$String$fromInt(statusCode))))))));
};
var $author$project$Utils$HttpUtils$httpErrorToString = function (error) {
	switch (error.$) {
		case 0:
			var text = error.a;
			return 'Bad Url: ' + text;
		case 1:
			return 'Http Timeout';
		case 2:
			return 'Network Error';
		case 3:
			var errorResponse = error.a;
			return $author$project$Utils$HttpUtils$apiErrorResponseToString(errorResponse);
		case 4:
			var message = error.a;
			return 'Bad Http Body: ' + message;
		default:
			return '';
	}
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Routes$mainPageRouteToUrlStr = function (route) {
	switch (route) {
		case 10:
			return '/missingPermissions';
		case 0:
			return '/commands';
		case 1:
			return '/views';
		case 2:
			return '/roles';
		case 3:
			return '/connections';
		case 4:
			return '/environments';
		case 5:
			return '/endpoints';
		case 6:
			return '/principals';
		case 9:
			return '/tenantSettings';
		case 7:
			return '/logs';
		default:
			return '/revisions';
	}
};
var $author$project$Routes$routeToUrlString = function (route) {
	switch (route.$) {
		case 0:
			var mainPageRoute = route.a;
			return $author$project$Routes$mainPageRouteToUrlStr(mainPageRoute);
		case 1:
			return '/docs';
		default:
			return '/loggedOut';
	}
};
var $author$project$Main$navigateToCmd = F2(
	function (model, route) {
		return A2(
			$elm$browser$Browser$Navigation$pushUrl,
			model.dr,
			$author$project$Routes$routeToUrlString(route));
	});
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $author$project$Lib$CustomHttp$AjaxRedirection = {$: 5};
var $author$project$Lib$CustomHttp$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $author$project$Lib$CustomHttp$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Lib$CustomHttp$NetworkError = {$: 2};
var $author$project$Lib$CustomHttp$Timeout = {$: 1};
var $author$project$Lib$CustomHttp$ajaxRedirectionStatusCode = 278;
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $author$project$Lib$CustomHttp$ApiErrorResponse = F4(
	function (message, callId, statusCode, statusText) {
		return {fX: callId, gD: message, ee: statusCode, fE: statusText};
	});
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Lib$CustomHttp$makeApiErrorResponseDecoder = F2(
	function (statusCode, statusText) {
		return A5(
			$elm$json$Json$Decode$map4,
			$author$project$Lib$CustomHttp$ApiErrorResponse,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$field, 'callId', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$succeed(statusCode),
			$elm$json$Json$Decode$succeed(statusText));
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Lib$CustomHttp$httpResponseToResult = F3(
	function (goodStatusToResult, redirectionResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$author$project$Lib$CustomHttp$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($author$project$Lib$CustomHttp$Timeout);
			case 2:
				return $elm$core$Result$Err($author$project$Lib$CustomHttp$NetworkError);
			case 3:
				var statusCode = response.a.ee;
				var statusText = response.a.fE;
				var body = response.b;
				var errorResponseDecoder = A2($author$project$Lib$CustomHttp$makeApiErrorResponseDecoder, statusCode, statusText);
				var errorResponse = A2(
					$elm$core$Result$withDefault,
					{fX: '-1', gD: body, ee: statusCode, fE: statusText},
					A2($elm$json$Json$Decode$decodeString, errorResponseDecoder, body));
				return $elm$core$Result$Err(
					$author$project$Lib$CustomHttp$BadStatus(errorResponse));
			default:
				var statusCode = response.a.ee;
				var body = response.b;
				return _Utils_eq(statusCode, $author$project$Lib$CustomHttp$ajaxRedirectionStatusCode) ? A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Result$Err($author$project$Lib$CustomHttp$AjaxRedirection),
					A2($elm$core$Maybe$map, $elm$core$Result$Ok, redirectionResult)) : goodStatusToResult(body);
		}
	});
var $author$project$Lib$CustomHttp$expectWhateverCustom = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		A2(
			$author$project$Lib$CustomHttp$httpResponseToResult,
			function (_v0) {
				return $elm$core$Result$Ok(0);
			},
			$elm$core$Maybe$Just(0)));
};
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.ee));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl = F2(
	function (method, url) {
		return {
			h$: $elm$http$Http$emptyBody,
			eY: $elm$http$Http$expectString(
				function (_v0) {
					return 0;
				}),
			gr: _List_Nil,
			kR: method,
			mA: $elm$core$Maybe$Nothing,
			mO: url,
			a6: false
		};
	});
var $lukewestby$elm_http_builder$HttpBuilder$post = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('POST');
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {gW: reqs, hb: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.hl;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.gW));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.hb)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					hH: r.hH,
					h$: r.h$,
					eY: A2(_Http_mapExpect, func, r.eY),
					gr: r.gr,
					kR: r.kR,
					mA: r.mA,
					hl: r.hl,
					mO: r.mO
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{hH: false, h$: r.h$, eY: r.eY, gr: r.gr, kR: r.kR, mA: r.mA, hl: r.hl, mO: r.mO}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{hH: true, h$: r.h$, eY: r.eY, gr: r.gr, kR: r.kR, mA: r.mA, hl: r.hl, mO: r.mO}));
};
var $lukewestby$elm_http_builder$HttpBuilder$request = function (builder) {
	var req = builder.a6 ? $elm$http$Http$riskyRequest : $elm$http$Http$request;
	return req(
		{h$: builder.h$, eY: builder.eY, gr: builder.gr, kR: builder.kR, mA: builder.mA, hl: $elm$core$Maybe$Nothing, mO: builder.mO});
};
var $author$project$Api$ApiUrls$urlRoot = '/api';
var $author$project$Api$ApiUrls$userActionsUrlRoot = $author$project$Api$ApiUrls$urlRoot + '/user';
var $lukewestby$elm_http_builder$HttpBuilder$withExpect = F2(
	function (expect, builder) {
		return {h$: builder.h$, eY: expect, gr: builder.gr, kR: builder.kR, mA: builder.mA, mO: builder.mO, a6: builder.a6};
	});
var $author$project$Api$UserApi$requestLogin = function (toMsg) {
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			$author$project$Lib$CustomHttp$expectWhateverCustom(toMsg),
			$lukewestby$elm_http_builder$HttpBuilder$post($author$project$Api$ApiUrls$userActionsUrlRoot + '/login')));
};
var $author$project$Api$UserApi$requestLogout = function (toMsg) {
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			$author$project$Lib$CustomHttp$expectWhateverCustom(toMsg),
			$lukewestby$elm_http_builder$HttpBuilder$post($author$project$Api$ApiUrls$userActionsUrlRoot + '/logout')));
};
var $ccapndave$elm_update_extra$Update$Extra$sequence = F3(
	function (update, msgs, init) {
		var foldUpdate = $ccapndave$elm_update_extra$Update$Extra$andThen(update);
		return A3($elm$core$List$foldl, foldUpdate, init, msgs);
	});
var $author$project$Pages$Main$MainPage$SetRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$MainPage$setRoute = $author$project$Pages$Main$MainPage$SetRoute;
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.gS;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.gn,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.gT,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.lp,
					_Utils_ap(http, url.j5)),
				url.ll)));
};
var $author$project$Pages$DocsPage$GotUserPermissions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Lib$RemoteData$Loading = function (a) {
	return {$: 1, a: a};
};
var $author$project$Authentication$UserPermissions$ManageCommands = 8;
var $author$project$AppMsg$NoOp = {$: 0};
var $author$project$Lib$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $author$project$Lib$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $author$project$Lib$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $author$project$Lib$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $author$project$Lib$RemoteData$Success(x);
	}
};
var $author$project$Lib$CustomHttp$BadBody = function (a) {
	return {$: 4, a: a};
};
var $author$project$Lib$CustomHttp$expectJsonCustom = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			A2(
				$author$project$Lib$CustomHttp$httpResponseToResult,
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						A2($elm$core$Basics$composeL, $author$project$Lib$CustomHttp$BadBody, $elm$json$Json$Decode$errorToString),
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				},
				$elm$core$Maybe$Nothing));
	});
var $lukewestby$elm_http_builder$HttpBuilder$get = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('GET');
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Authentication$UserPermissions$ManageConnections = 10;
var $author$project$Authentication$UserPermissions$ManageEndpoints = 12;
var $author$project$Authentication$UserPermissions$ManageEnvironments = 11;
var $author$project$Authentication$UserPermissions$ManageLogs = 16;
var $author$project$Authentication$UserPermissions$ManagePrincipals = 14;
var $author$project$Authentication$UserPermissions$ManageRevisions = 17;
var $author$project$Authentication$UserPermissions$ManageRoles = 13;
var $author$project$Authentication$UserPermissions$ManageTenantSettings = 15;
var $author$project$Authentication$UserPermissions$ManageViews = 9;
var $author$project$Authentication$UserPermissions$Other = 18;
var $author$project$Authentication$UserPermissions$ReadCommands = 1;
var $author$project$Authentication$UserPermissions$ReadConnections = 3;
var $author$project$Authentication$UserPermissions$ReadEndpoints = 5;
var $author$project$Authentication$UserPermissions$ReadEnvironments = 4;
var $author$project$Authentication$UserPermissions$ReadPrincipals = 7;
var $author$project$Authentication$UserPermissions$ReadRoles = 6;
var $author$project$Authentication$UserPermissions$ReadViewsAdmin = 2;
var $author$project$Authentication$UserPermissions$SelectBranch = 0;
var $author$project$Api$UserApi$userPermissionDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		return $elm$json$Json$Decode$succeed(
			function () {
				switch (str) {
					case 'SelectBranch':
						return 0;
					case 'ReadCommands':
						return 1;
					case 'ReadViewsAdmin':
						return 2;
					case 'ReadConnections':
						return 3;
					case 'ReadEnvironments':
						return 4;
					case 'ReadEndpoints':
						return 5;
					case 'ReadRoles':
						return 6;
					case 'ReadPrincipals':
						return 7;
					case 'ManageCommands':
						return 8;
					case 'ManageViews':
						return 9;
					case 'ManageConnections':
						return 10;
					case 'ManageEnvironments':
						return 11;
					case 'ManageEndpoints':
						return 12;
					case 'ManageRoles':
						return 13;
					case 'ManagePrincipals':
						return 14;
					case 'ManageTenantSettings':
						return 15;
					case 'ManageLogs':
						return 16;
					case 'ManageRevisions':
						return 17;
					default:
						return 18;
				}
			}());
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$UserApi$userPermissionsFromUserDataDecoder = A2(
	$elm$json$Json$Decode$field,
	'permissions',
	$elm$json$Json$Decode$list($author$project$Api$UserApi$userPermissionDecoder));
var $author$project$Api$UserApi$getUserPermissions = function (toMsg) {
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Api$UserApi$userPermissionsFromUserDataDecoder),
			$lukewestby$elm_http_builder$HttpBuilder$get($author$project$Api$ApiUrls$userActionsUrlRoot + '/userData')));
};
var $author$project$Lib$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $author$project$Lib$RemoteData$Success(
					f(value));
			case 1:
				var oldValue = data.a;
				return $author$project$Lib$RemoteData$Loading(
					f(oldValue));
			case 0:
				return $author$project$Lib$RemoteData$EmptyLoading;
			default:
				var error = data.a;
				return $author$project$Lib$RemoteData$Failure(error);
		}
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Pages$PageUpdateUtils$withoutActions = function (model) {
	return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$AppMsg$NoOp);
};
var $author$project$Pages$DocsPage$update = F2(
	function (msg, model) {
		if (!msg.$) {
			return _Utils_Tuple3(
				_Utils_update(
					model,
					{
						bz: $author$project$Lib$RemoteData$Loading(false)
					}),
				$author$project$Api$UserApi$getUserPermissions($author$project$Pages$DocsPage$GotUserPermissions),
				$author$project$AppMsg$NoOp);
		} else {
			var result = msg.a;
			return $author$project$Pages$PageUpdateUtils$withoutActions(
				_Utils_update(
					model,
					{
						bz: A2(
							$author$project$Lib$RemoteData$map,
							$elm$core$List$member(8),
							$author$project$Lib$RemoteData$fromResult(result))
					}));
		}
	});
var $author$project$Pages$Main$MainPage$BeginLoadBranches = {$: 19};
var $author$project$Pages$Main$OutMsg$BeginReloadAllEntities = {$: 1};
var $author$project$Pages$Main$MainPage$CommandsMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$MainPage$ConnectionsMsg = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Main$MainPage$EndpointsMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$MainPage$EnvironmentsMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Main$MainPage$GotBranches = function (a) {
	return {$: 21, a: a};
};
var $author$project$Pages$Main$MainPage$GotCommandGroups = function (a) {
	return {$: 38, a: a};
};
var $author$project$Pages$Main$MainPage$GotCommands = function (a) {
	return {$: 37, a: a};
};
var $author$project$Pages$Main$MainPage$GotConnectionGroups = function (a) {
	return {$: 42, a: a};
};
var $author$project$Pages$Main$MainPage$GotConnections = function (a) {
	return {$: 41, a: a};
};
var $author$project$Pages$Main$MainPage$GotEndpointGroups = function (a) {
	return {$: 46, a: a};
};
var $author$project$Pages$Main$MainPage$GotEndpoints = function (a) {
	return {$: 44, a: a};
};
var $author$project$Pages$Main$MainPage$GotEnvironmentGroups = function (a) {
	return {$: 45, a: a};
};
var $author$project$Pages$Main$MainPage$GotEnvironments = function (a) {
	return {$: 43, a: a};
};
var $author$project$Pages$Main$MainPage$GotIdentityProviders = function (a) {
	return {$: 51, a: a};
};
var $author$project$Pages$Main$MainPage$GotPrincipalGroups = function (a) {
	return {$: 50, a: a};
};
var $author$project$Pages$Main$MainPage$GotPrincipals = function (a) {
	return {$: 49, a: a};
};
var $author$project$Pages$Main$MainPage$GotRoleGroups = function (a) {
	return {$: 40, a: a};
};
var $author$project$Pages$Main$MainPage$GotRoles = function (a) {
	return {$: 39, a: a};
};
var $author$project$Pages$Main$MainPage$GotUserPermissions = function (a) {
	return {$: 20, a: a};
};
var $author$project$Pages$Main$MainPage$GotViewGroups = function (a) {
	return {$: 48, a: a};
};
var $author$project$Pages$Main$MainPage$GotViews = function (a) {
	return {$: 47, a: a};
};
var $author$project$Pages$Main$MainPage$LogsMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$MainPage$Message = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$MainPage$OutMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$MainPage$PrincipalsMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$AppMsg$RequestLogout = {$: 2};
var $author$project$Pages$Main$MainPage$RevisionsMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$MainPage$RolesMsg = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$MainPage$TenantSettingsMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$MainPage$ViewsMsg = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$PageUpdateUtils$addCmd = F2(
	function (cmd_, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var appMsg = _v0.c;
		return _Utils_Tuple3(
			model,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])),
			appMsg);
	});
var $author$project$AppMsg$BatchAppMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$PageUpdateUtils$andThen = F3(
	function (update, msg, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var appMsg = _v0.c;
		var _v1 = A2(update, msg, model);
		var model_ = _v1.a;
		var cmd_ = _v1.b;
		var appMsg_ = _v1.c;
		return _Utils_Tuple3(
			model_,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])),
			$author$project$AppMsg$BatchAppMsg(
				_List_fromArray(
					[appMsg, appMsg_])));
	});
var $author$project$Pages$Main$MainPage$getApiConfig = function (model) {
	return {l$: model.l$};
};
var $author$project$Entity$AavoBranch$AavoBranch = F2(
	function (key, title) {
		return {kt: key, mC: title};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Api$BranchApi$aavoBranchDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'title',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'key',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Entity$AavoBranch$AavoBranch)));
var $author$project$Api$BranchApi$getBranches = function (toMsg) {
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			A2(
				$author$project$Lib$CustomHttp$expectJsonCustom,
				toMsg,
				$elm$json$Json$Decode$list($author$project$Api$BranchApi$aavoBranchDecoder)),
			$lukewestby$elm_http_builder$HttpBuilder$get($author$project$Api$ApiUrls$urlRoot + '/branches')));
};
var $author$project$Api$ApiUrls$adminUrlRoot = $author$project$Api$ApiUrls$urlRoot + '/admin';
var $author$project$Api$ApiUrls$commandGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/commandGroups';
var $author$project$Entity$GenericEntityGroup$GenericEntityGroup = F6(
	function (id, pos, aproProjectId, name, description, parentId) {
		return {hM: aproProjectId, i$: description, db: id, k_: name, fk: parentId, dM: pos};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'parentId',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'description',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'aproProjectId',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'pos',
					$elm$json$Json$Decode$int,
					0,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Entity$GenericEntityGroup$GenericEntityGroup)))))));
var $author$project$Api$ApiUrls$aavoBranchHeader = 'X-aavo-branch';
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $author$project$Utils$DictUtils$mapKeys = function (_function) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Dict$toList,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map(
				function (_v0) {
					var key = _v0.a;
					var value = _v0.b;
					return _Utils_Tuple2(
						_function(key),
						value);
				}),
			$elm$core$Dict$fromList));
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Entity$Common$nonExistentId = -1;
var $author$project$Entity$Common$strToIdOrDefault = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toInt,
	$elm$core$Maybe$withDefault($author$project$Entity$Common$nonExistentId));
var $author$project$Decoding$DecodingUtils$entityDictDecoder = A2(
	$elm$core$Basics$composeR,
	$elm$json$Json$Decode$dict,
	$elm$json$Json$Decode$map(
		$author$project$Utils$DictUtils$mapKeys($author$project$Entity$Common$strToIdOrDefault)));
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $lukewestby$elm_http_builder$HttpBuilder$withHeader = F3(
	function (key, value, builder) {
		return _Utils_update(
			builder,
			{
				gr: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, key, value),
					builder.gr)
			});
	});
var $author$project$Api$EntityApi$getEntityDictData = F4(
	function (url, decoder, _v0, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2(
					$author$project$Lib$CustomHttp$expectJsonCustom,
					toMsg,
					$author$project$Decoding$DecodingUtils$entityDictDecoder(decoder)),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					$author$project$Api$ApiUrls$aavoBranchHeader,
					selectedBranchKey,
					$lukewestby$elm_http_builder$HttpBuilder$get(url))));
	});
var $author$project$Api$EntityApi$getCommandGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$commandGroupsUrl, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Entity$Command$Command = function (id) {
	return function (groupId) {
		return function (pos) {
			return function (aproProjectId) {
				return function (idAnchor) {
					return function (key) {
						return function (description) {
							return function (connections) {
								return function (extraInputFieldDefinitions) {
									return function (parameterMappings) {
										return function (baseClauses) {
											return function (timeoutSeconds) {
												return function (spec) {
													return {hM: aproProjectId, hZ: baseClauses, iy: connections, i$: description, jC: extraInputFieldDefinitions, j_: groupId, db: id, j9: idAnchor, kt: key, li: parameterMappings, dM: pos, mh: spec, mB: timeoutSeconds};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$CommandParameter$CommandParameter = F2(
	function (key, value) {
		return {kt: key, mY: value};
	});
var $author$project$Serializable$Bool_ = function (a) {
	return {$: 3, a: a};
};
var $author$project$Serializable$Float_ = function (a) {
	return {$: 2, a: a};
};
var $author$project$Serializable$Int_ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Serializable$String_ = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Serializable$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$map, $author$project$Serializable$Bool_, $elm$json$Json$Decode$bool),
			A2($elm$json$Json$Decode$map, $author$project$Serializable$Int_, $elm$json$Json$Decode$int),
			A2($elm$json$Json$Decode$map, $author$project$Serializable$Float_, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $author$project$Serializable$String_, $elm$json$Json$Decode$string)
		]));
var $author$project$Decoding$CommandParameterDecoding$commandParameterDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$CommandParameter$CommandParameter,
	A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'value',
		$elm$json$Json$Decode$nullable($author$project$Serializable$decoder)));
var $author$project$Entity$Command$SpecCalendarViewCommand = function (a) {
	return {$: 12, a: a};
};
var $author$project$Entity$Command$SpecChartCommand = function (a) {
	return {$: 9, a: a};
};
var $author$project$Entity$Command$SpecCronCommand = function (a) {
	return {$: 14, a: a};
};
var $author$project$Entity$Command$SpecDataViewCommand = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$Command$SpecDiagramCommand = function (a) {
	return {$: 10, a: a};
};
var $author$project$Entity$Command$SpecDownloadFileCommand = function (a) {
	return {$: 6, a: a};
};
var $author$project$Entity$Command$SpecFormCommand = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$Command$SpecMailCommand = function (a) {
	return {$: 4, a: a};
};
var $author$project$Entity$Command$SpecNotificationCommand = function (a) {
	return {$: 5, a: a};
};
var $author$project$Entity$Command$SpecProductionLineCommand = function (a) {
	return {$: 3, a: a};
};
var $author$project$Entity$Command$SpecQuickSightCommand = function (a) {
	return {$: 13, a: a};
};
var $author$project$Entity$Command$SpecReportCommand = function (a) {
	return {$: 8, a: a};
};
var $author$project$Entity$Command$SpecSingleClauseCommand = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$Command$SpecTreeViewCommand = function (a) {
	return {$: 11, a: a};
};
var $author$project$Entity$Command$SpecUploadFilesCommand = function (a) {
	return {$: 7, a: a};
};
var $author$project$Entity$Command$SpecViewComponentsCommand = function (a) {
	return {$: 15, a: a};
};
var $author$project$Entity$Command$CalendarViewCommandSpecification = F7(
	function (eventsClause, resourcesClause, eventEditedAction, eventClickAction, eventContextMenu, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, jt: eventClickAction, ju: eventContextMenu, jv: eventEditedAction, jx: eventsClause, lK: resourcesClause};
	});
var $author$project$Entity$ActionAndViewComponent$ButtonViewComponent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$ActionAndViewComponent$CommandExecutionAction = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$ActionAndViewComponent$ContainerViewComponent = function (a) {
	return {$: 3, a: a};
};
var $author$project$Entity$ActionAndViewComponent$CustomSubmitAction = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$ActionAndViewComponent$DialogAction = function (a) {
	return {$: 3, a: a};
};
var $author$project$Entity$ActionAndViewComponent$ElementDropZoneConfig = function (dropAction) {
	return {i8: dropAction};
};
var $author$project$Entity$ActionAndViewComponent$GeneratorViewComponent = function (a) {
	return {$: 4, a: a};
};
var $author$project$Entity$ActionAndViewComponent$InputViewComponent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$ActionAndViewComponent$NoneSubmitAction = {$: 0};
var $author$project$Entity$ActionAndViewComponent$OpenUrlAction = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$ActionAndViewComponent$ScriptAction = function (a) {
	return {$: 4, a: a};
};
var $author$project$Entity$ActionAndViewComponent$SequenceAction = function (a) {
	return {$: 5, a: a};
};
var $author$project$Entity$ActionAndViewComponent$TButtonViewComponent = F7(
	function (label, icon, action, dropZoneConfig, cssCode, attributes, componentParameterClauses) {
		return {fO: action, ba: attributes, cG: componentParameterClauses, bm: cssCode, cV: dropZoneConfig, j8: icon, kv: label};
	});
var $author$project$Entity$ActionAndViewComponent$TContainerViewComponent = F8(
	function (childComponents, clickAction, doubleClickAction, contextMenuComponents, dropZoneConfig, cssCode, attributes, componentParameterClauses) {
		return {ba: attributes, ia: childComponents, f$: clickAction, cG: componentParameterClauses, f4: contextMenuComponents, bm: cssCode, ga: doubleClickAction, cV: dropZoneConfig};
	});
var $author$project$Entity$ActionAndViewComponent$TDialogAction = F9(
	function (title, containerCssClass, containerId, cssCode, modalSize, components, params, parameterClauses, predicate) {
		return {it: components, iz: containerCssClass, iA: containerId, bm: cssCode, kY: modalSize, dJ: parameterClauses, lk: params, as: predicate, mC: title};
	});
var $author$project$Entity$ActionAndViewComponent$TGeneratorViewComponent = F4(
	function (generatorClause, template, dynamicTemplates, attributes) {
		return {ba: attributes, je: dynamicTemplates, jR: generatorClause, mv: template};
	});
var $author$project$Entity$ActionAndViewComponent$TInputViewComponent = F7(
	function (fieldDefinition, submitAction, dropZoneConfig, cssCode, autoFocus, attributes, componentParameterClauses) {
		return {ba: attributes, hT: autoFocus, cG: componentParameterClauses, bm: cssCode, cV: dropZoneConfig, jE: fieldDefinition, mq: submitAction};
	});
var $author$project$Entity$ActionAndViewComponent$TSequenceAction = F2(
	function (actions, predicate) {
		return {hA: actions, as: predicate};
	});
var $author$project$Entity$ActionAndViewComponent$TTextViewComponent = function (contentClause) {
	return function (useMarkdown) {
		return function (useHtml) {
			return function (clickAction) {
				return function (doubleClickAction) {
					return function (contextMenuComponents) {
						return function (dropZoneConfig) {
							return function (dragEnabled) {
								return function (cssCode) {
									return function (attributes) {
										return function (componentParameterClauses) {
											return {ba: attributes, f$: clickAction, cG: componentParameterClauses, iC: contentClause, f4: contextMenuComponents, bm: cssCode, ga: doubleClickAction, i4: dragEnabled, cV: dropZoneConfig, mQ: useHtml, mR: useMarkdown};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$ActionAndViewComponent$TextViewComponent = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$ActionAndViewComponent$ViewAction = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$ActionAndViewComponent$ViewDefaultSubmitAction = {$: 1};
var $author$project$Entity$Clauses$CommandClause = function (a) {
	return {$: 4, a: a};
};
var $author$project$Entity$Clauses$DatabaseClause = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$Clauses$DirectValueClause = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$Clauses$HttpClause = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$Clauses$KotlinClause = function (a) {
	return {$: 5, a: a};
};
var $author$project$Entity$Clauses$PythonClause = function (a) {
	return {$: 3, a: a};
};
var $author$project$Entity$Clauses$SequenceClause = function (a) {
	return {$: 6, a: a};
};
var $author$project$Entity$Clauses$SequenceClauseData = function (units) {
	return {mN: units};
};
var $author$project$Entity$Clauses$SequenceClauseUnit = F3(
	function (clause, separatePreviousClauseResult, resultParamName) {
		return {eH: clause, lM: resultParamName, l2: separatePreviousClauseResult};
	});
var $author$project$Entity$Clauses$CommandClauseData = F2(
	function (commandCallDefinition, asyncExecution) {
		return {hP: asyncExecution, il: commandCallDefinition};
	});
var $author$project$Entity$CommandCallDefinition$CommandCallDefinition = F2(
	function (commandId, params) {
		return {f2: commandId, lk: params};
	});
var $author$project$Decoding$ClauseDecoding$commandCallDefinitionDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$CommandCallDefinition$CommandCallDefinition,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'commandParams',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder)));
var $author$project$Decoding$ClauseDecoding$commandClauseDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'asyncExecution',
	$elm$json$Json$Decode$bool,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'commandCallDefinition',
		$author$project$Decoding$ClauseDecoding$commandCallDefinitionDecoder,
		$elm$json$Json$Decode$succeed($author$project$Entity$Clauses$CommandClauseData)));
var $author$project$Entity$Clauses$DatabaseClauseData = F5(
	function (command, interpretation, explicitConnectionId, resultSource, metadata) {
		return {ij: command, gi: explicitConnectionId, kn: interpretation, kQ: metadata, lN: resultSource};
	});
var $author$project$Entity$Clauses$DatabaseClauseColumnMetadata = function (convertTimeZone) {
	return {iE: convertTimeZone};
};
var $author$project$Entity$Clauses$DatabaseClauseMetadata = function (columns) {
	return {ii: columns};
};
var $author$project$Decoding$ClauseDecoding$databaseClauseMetadataDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'columns',
	$elm$json$Json$Decode$dict(
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'convertTimeZone',
			$elm$json$Json$Decode$bool,
			true,
			$elm$json$Json$Decode$succeed($author$project$Entity$Clauses$DatabaseClauseColumnMetadata))),
	$elm$core$Dict$empty,
	$elm$json$Json$Decode$succeed($author$project$Entity$Clauses$DatabaseClauseMetadata));
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Entity$Clauses$HorizontalList = 1;
var $author$project$Entity$Clauses$HorizontalMap = 3;
var $author$project$Entity$Clauses$Scalar = 0;
var $author$project$Entity$Clauses$Table = 5;
var $author$project$Entity$Clauses$VerticalList = 2;
var $author$project$Entity$Clauses$VerticalMap = 4;
var $author$project$Utils$TypeParsers$parseDatabaseClauseInterpretation = function (str) {
	switch (str) {
		case 'Scalar':
			return $elm$core$Result$Ok(0);
		case 'HorizontalList':
			return $elm$core$Result$Ok(1);
		case 'VerticalList':
			return $elm$core$Result$Ok(2);
		case 'HorizontalMap':
			return $elm$core$Result$Ok(3);
		case 'VerticalMap':
			return $elm$core$Result$Ok(4);
		case 'Table':
			return $elm$core$Result$Ok(5);
		default:
			return $elm$core$Result$Err('Invalid database clause interpretation ' + str);
	}
};
var $author$project$Entity$Clauses$GenericOutParameter = 1;
var $author$project$Entity$Clauses$LastSelect = 0;
var $author$project$Entity$Clauses$OracleRefCursor = 2;
var $author$project$Utils$TypeParsers$parseDatabaseClauseResultSource = function (str) {
	switch (str) {
		case 'LastSelect':
			return $elm$core$Result$Ok(0);
		case 'GenericOutParameter':
			return $elm$core$Result$Ok(1);
		case 'OracleRefCursor':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('Invalid DatabaseClauseResultSource ' + str);
	}
};
var $author$project$Decoding$DecodingUtils$fromResult = function (result) {
	if (!result.$) {
		var value = result.a;
		return $elm$json$Json$Decode$succeed(value);
	} else {
		var error = result.a;
		return $elm$json$Json$Decode$fail(error);
	}
};
var $author$project$Decoding$DecodingUtils$parserDecoder = function (parser) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			return $author$project$Decoding$DecodingUtils$fromResult(
				parser(str));
		},
		$elm$json$Json$Decode$string);
};
var $author$project$Decoding$ClauseDecoding$databaseClauseDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$Clauses$DatabaseClauseData,
	A2($elm$json$Json$Decode$field, 'sql', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'interpretation',
		$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseDatabaseClauseInterpretation)),
	A2(
		$elm$json$Json$Decode$field,
		'explicitConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'resultSource',
		$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseDatabaseClauseResultSource)),
	A2($elm$json$Json$Decode$field, 'metadata', $author$project$Decoding$ClauseDecoding$databaseClauseMetadataDecoder));
var $author$project$Entity$Clauses$DirectValueClauseData = function (value) {
	return {mY: value};
};
var $author$project$Decoding$ClauseDecoding$directValueClauseDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Entity$Clauses$DirectValueClauseData,
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string));
var $author$project$Utils$ListUtils$filterNotNothing = $elm$core$List$filterMap($elm$core$Basics$identity);
var $author$project$Entity$Clauses$HttpClauseData = F4(
	function (path, method, body, explicitConnectionId) {
		return {h$: body, gi: explicitConnectionId, kR: method, ll: path};
	});
var $author$project$Entity$HttpMethod$Delete = 3;
var $author$project$Entity$HttpMethod$Get = 0;
var $author$project$Entity$HttpMethod$Post = 1;
var $author$project$Entity$HttpMethod$Put = 2;
var $author$project$Utils$TypeParsers$parseHttpMethod = function (str) {
	switch (str) {
		case 'GET':
			return $elm$core$Result$Ok(0);
		case 'POST':
			return $elm$core$Result$Ok(1);
		case 'PUT':
			return $elm$core$Result$Ok(2);
		case 'DELETE':
			return $elm$core$Result$Ok(3);
		default:
			return $elm$core$Result$Err('Invalid HTTP-method ' + str);
	}
};
var $author$project$Decoding$ClauseDecoding$httpMethodDecoder = A2(
	$elm$json$Json$Decode$andThen,
	A2($elm$core$Basics$composeR, $author$project$Utils$TypeParsers$parseHttpMethod, $author$project$Decoding$DecodingUtils$fromResult),
	$elm$json$Json$Decode$string);
var $author$project$Decoding$ClauseDecoding$httpClauseDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$Clauses$HttpClauseData,
	A2($elm$json$Json$Decode$field, 'path', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'method', $author$project$Decoding$ClauseDecoding$httpMethodDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'body',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
	A2(
		$elm$json$Json$Decode$field,
		'explicitConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)));
var $author$project$Entity$Clauses$KotlinClauseData = F3(
	function (className, functionName, packageVersion) {
		return {ic: className, jQ: functionName, le: packageVersion};
	});
var $author$project$Decoding$ClauseDecoding$kotlinClauseDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'packageVersion',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'functionName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'className',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Entity$Clauses$KotlinClauseData))));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Entity$Clauses$PythonClauseData = F3(
	function (script, redirectedConnections, signature) {
		return {lz: redirectedConnections, lW: script, mf: signature};
	});
var $author$project$Entity$Clauses$PythonClauseConnectionRedirection = F2(
	function (name, connectionId) {
		return {iw: connectionId, k_: name};
	});
var $author$project$Decoding$ClauseDecoding$pythonClauseConnectionRedirection = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'connectionId',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Entity$Clauses$PythonClauseConnectionRedirection)));
var $author$project$Decoding$ClauseDecoding$pythonClauseDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$Clauses$PythonClauseData,
	A2($elm$json$Json$Decode$field, 'script', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'redirectedConnections',
		$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$pythonClauseConnectionRedirection)),
	A2(
		$elm$json$Json$Decode$field,
		'signature',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)));
function $author$project$Decoding$ClauseDecoding$cyclic$clauseDecoder() {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (clauseType) {
			switch (clauseType) {
				case 'directValue':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$Clauses$DirectValueClause, $author$project$Decoding$ClauseDecoding$directValueClauseDecoder);
				case 'database':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$Clauses$DatabaseClause, $author$project$Decoding$ClauseDecoding$databaseClauseDecoder);
				case 'http':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$Clauses$HttpClause, $author$project$Decoding$ClauseDecoding$httpClauseDecoder);
				case 'python':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$Clauses$PythonClause, $author$project$Decoding$ClauseDecoding$pythonClauseDecoder);
				case 'command':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$Clauses$CommandClause, $author$project$Decoding$ClauseDecoding$commandClauseDecoder);
				case 'kotlin':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$Clauses$KotlinClause, $author$project$Decoding$ClauseDecoding$kotlinClauseDecoder);
				case 'sequence':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$Clauses$SequenceClause,
						$author$project$Decoding$ClauseDecoding$cyclic$sequenceClauseDecoder());
				default:
					return $elm$json$Json$Decode$fail('Invalid clause type ' + clauseType);
			}
		},
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}
function $author$project$Decoding$ClauseDecoding$cyclic$sequenceClauseDecoder() {
	var unitDecoder = A2(
		$elm$json$Json$Decode$andThen,
		function (typeStr) {
			switch (typeStr) {
				case 'singleClause':
					return A2(
						$elm$json$Json$Decode$map,
						$elm$core$Maybe$Just,
						A4(
							$elm$json$Json$Decode$map3,
							$author$project$Entity$Clauses$SequenceClauseUnit,
							A2(
								$elm$json$Json$Decode$field,
								'clause',
								$author$project$Decoding$ClauseDecoding$cyclic$clauseDecoder()),
							A2($elm$json$Json$Decode$field, 'separatePreviousClauseResult', $elm$json$Json$Decode$bool),
							A2(
								$elm$json$Json$Decode$field,
								'resultParamName',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string))));
				case 'parallelGroup':
					return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
				default:
					return $elm$json$Json$Decode$fail('Unknown SingleClauseUnit type ' + typeStr);
			}
		},
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
	return A2(
		$elm$json$Json$Decode$map,
		$author$project$Entity$Clauses$SequenceClauseData,
		A2(
			$elm$json$Json$Decode$field,
			'units',
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Utils$ListUtils$filterNotNothing,
				$elm$json$Json$Decode$list(unitDecoder))));
}
var $author$project$Decoding$ClauseDecoding$clauseDecoder = $author$project$Decoding$ClauseDecoding$cyclic$clauseDecoder();
$author$project$Decoding$ClauseDecoding$cyclic$clauseDecoder = function () {
	return $author$project$Decoding$ClauseDecoding$clauseDecoder;
};
var $author$project$Decoding$ClauseDecoding$sequenceClauseDecoder = $author$project$Decoding$ClauseDecoding$cyclic$sequenceClauseDecoder();
$author$project$Decoding$ClauseDecoding$cyclic$sequenceClauseDecoder = function () {
	return $author$project$Decoding$ClauseDecoding$sequenceClauseDecoder;
};
var $author$project$Entity$ActionAndViewComponent$TCommandExecutionAction = F5(
	function (commandCall, requestMoreInput, displayResult, parameterClauses, predicate) {
		return {ik: commandCall, i2: displayResult, dJ: parameterClauses, as: predicate, lH: requestMoreInput};
	});
var $author$project$Decoding$ActionAndViewComponentDecoding$commandCallDefinitionDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$CommandCallDefinition$CommandCallDefinition,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'commandParams',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder)));
var $author$project$Entity$ParameterClause$NamedParameterClause = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$ParameterClause$SeparatedParameterClause = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$ParameterClause$TNamedParameterClause = F2(
	function (parameterName, clause) {
		return {eH: clause, lj: parameterName};
	});
var $author$project$Entity$ParameterClause$TSeparatedParameterClause = function (clause) {
	return {eH: clause};
};
var $author$project$Decoding$ClauseDecoding$parameterClauseDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (typeStr) {
		switch (typeStr) {
			case 'separated':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$ParameterClause$SeparatedParameterClause,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'clause',
						$author$project$Decoding$ClauseDecoding$clauseDecoder,
						$elm$json$Json$Decode$succeed($author$project$Entity$ParameterClause$TSeparatedParameterClause)));
			case 'named':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$ParameterClause$NamedParameterClause,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'clause',
						$author$project$Decoding$ClauseDecoding$clauseDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'parameterName',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Entity$ParameterClause$TNamedParameterClause))));
			default:
				return $elm$json$Json$Decode$fail('unknown type for ParameterClause: ' + typeStr);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Entity$Predicate$AlwaysPredicate = {$: 0};
var $author$project$Entity$Predicate$ParameterValuePredicate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$Predicate$ScreenSizePredicate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$Predicate$TParameterValuePredicate = F4(
	function (parameterName, operator, operand, listOperands) {
		return {kA: listOperands, k9: operand, la: operator, lj: parameterName};
	});
var $author$project$Entity$Predicate$TScreenSizePredicate = F2(
	function (minWidth, maxWidth) {
		return {kL: maxWidth, kV: minWidth};
	});
var $author$project$Entity$Predicate$AlwaysOperator = 0;
var $author$project$Entity$Predicate$EndsWithOperator = 14;
var $author$project$Entity$Predicate$EqualsOperator = 4;
var $author$project$Entity$Predicate$ExistsOperator = 2;
var $author$project$Entity$Predicate$GreaterThanOperator = 9;
var $author$project$Entity$Predicate$GreaterThanOrEqualsOperator = 11;
var $author$project$Entity$Predicate$IsBlankOperator = 17;
var $author$project$Entity$Predicate$IsEmptyOperator = 16;
var $author$project$Entity$Predicate$IsFalsyOperator = 7;
var $author$project$Entity$Predicate$IsNotBlankOperator = 19;
var $author$project$Entity$Predicate$IsNotEmptyOperator = 18;
var $author$project$Entity$Predicate$IsTruthyOperator = 6;
var $author$project$Entity$Predicate$LessThanOperator = 8;
var $author$project$Entity$Predicate$LessThanOrEqualsOperator = 10;
var $author$project$Entity$Predicate$ListContainsOperator = 20;
var $author$project$Entity$Predicate$ListNotContainsOperator = 21;
var $author$project$Entity$Predicate$NeverOperator = 1;
var $author$project$Entity$Predicate$NotEqualsOperator = 5;
var $author$project$Entity$Predicate$NotExistsOperator = 3;
var $author$project$Entity$Predicate$RegexOperator = 15;
var $author$project$Entity$Predicate$StartsWithOperator = 13;
var $author$project$Entity$Predicate$StringContainsOperator = 12;
var $author$project$Utils$TypeParsers$parsePredicateOperator = function (str) {
	switch (str) {
		case 'Always':
			return $elm$core$Result$Ok(0);
		case 'Never':
			return $elm$core$Result$Ok(1);
		case 'Exists':
			return $elm$core$Result$Ok(2);
		case 'NotExists':
			return $elm$core$Result$Ok(3);
		case 'Equals':
			return $elm$core$Result$Ok(4);
		case 'NotEquals':
			return $elm$core$Result$Ok(5);
		case 'IsTruthy':
			return $elm$core$Result$Ok(6);
		case 'IsFalsy':
			return $elm$core$Result$Ok(7);
		case 'NumberLessThan':
			return $elm$core$Result$Ok(8);
		case 'NumberGreaterThan':
			return $elm$core$Result$Ok(9);
		case 'NumberLessThanOrEquals':
			return $elm$core$Result$Ok(10);
		case 'NumberGreaterThanOrEquals':
			return $elm$core$Result$Ok(11);
		case 'StringContains':
			return $elm$core$Result$Ok(12);
		case 'StringStartsWith':
			return $elm$core$Result$Ok(13);
		case 'StringEndsWith':
			return $elm$core$Result$Ok(14);
		case 'StringRegex':
			return $elm$core$Result$Ok(15);
		case 'StringIsEmpty':
			return $elm$core$Result$Ok(16);
		case 'StringIsBlank':
			return $elm$core$Result$Ok(17);
		case 'StringIsNotEmpty':
			return $elm$core$Result$Ok(18);
		case 'StringIsNotBlank':
			return $elm$core$Result$Ok(19);
		case 'ListContains':
			return $elm$core$Result$Ok(20);
		case 'ListNotContains':
			return $elm$core$Result$Ok(21);
		default:
			return $elm$core$Result$Err('Invalid AttributePredicateOperator ' + str);
	}
};
var $author$project$Decoding$PredicateDecoding$predicateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (typeStr) {
		switch (typeStr) {
			case 'always':
				return $elm$json$Json$Decode$succeed($author$project$Entity$Predicate$AlwaysPredicate);
			case 'screenSizeLimit':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$Predicate$ScreenSizePredicate,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'maxWidth',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'minWidth',
							$elm$json$Json$Decode$int,
							$elm$json$Json$Decode$succeed($author$project$Entity$Predicate$TScreenSizePredicate))));
			case 'parameterValue':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$Predicate$ParameterValuePredicate,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'listOperands',
						$elm$json$Json$Decode$list(
							$elm$json$Json$Decode$nullable($author$project$Serializable$decoder)),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'operand',
							$elm$json$Json$Decode$nullable($author$project$Serializable$decoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'operator',
								$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parsePredicateOperator),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'parameterName',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Entity$Predicate$TParameterValuePredicate))))));
			default:
				return $elm$json$Json$Decode$fail('unknown value for Predicate: ' + typeStr);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Decoding$ActionAndViewComponentDecoding$commandExecutionActionDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$ActionAndViewComponent$TCommandExecutionAction,
	A2($elm$json$Json$Decode$field, 'commandCall', $author$project$Decoding$ActionAndViewComponentDecoding$commandCallDefinitionDecoder),
	A2($elm$json$Json$Decode$field, 'requestMoreInput', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'displayResult', $elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$field,
		'parameterClauses',
		$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder)),
	A2($elm$json$Json$Decode$field, 'predicate', $author$project$Decoding$PredicateDecoding$predicateDecoder));
var $author$project$Entity$InputFieldDefinition$InputFieldDefinition = F5(
	function (identifier, label, defaultValue, required, typeDefinition) {
		return {iV: defaultValue, kb: identifier, kv: label, lI: required, mL: typeDefinition};
	});
var $author$project$Entity$InputFieldDefinition$FileInputField = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$InputFieldDefinition$SelectionField = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$InputFieldDefinition$SimpleField = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$InputFieldDefinition$TFileInputField = function (allowMultipleFiles) {
	return {hJ: allowMultipleFiles};
};
var $author$project$Entity$InputFieldDefinition$TSelectionField = F5(
	function (isLazy, optionsClause, nullValueEnabled, placeholder, selectFirstValueByDefault) {
		return {kq: isLazy, k1: nullValueEnabled, lb: optionsClause, lm: placeholder, lZ: selectFirstValueByDefault};
	});
var $author$project$Entity$InputFieldDefinition$TSimpleInputField = F2(
	function (fieldType, addOffsetToTemporalTypes) {
		return {hE: addOffsetToTemporalTypes, jF: fieldType};
	});
var $author$project$Entity$InputFieldDefinition$BarcodeField = 9;
var $author$project$Entity$InputFieldDefinition$CheckboxField = 8;
var $author$project$Entity$InputFieldDefinition$ConstantField = 0;
var $author$project$Entity$InputFieldDefinition$DateField = 5;
var $author$project$Entity$InputFieldDefinition$DateTimeField = 7;
var $author$project$Entity$InputFieldDefinition$FloatField = 4;
var $author$project$Entity$InputFieldDefinition$IntegerField = 3;
var $author$project$Entity$InputFieldDefinition$TextAreaField = 2;
var $author$project$Entity$InputFieldDefinition$TextField = 1;
var $author$project$Entity$InputFieldDefinition$TimeField = 6;
var $author$project$Decoding$InputFieldDecoding$simpleFieldTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Constant':
				return $elm$json$Json$Decode$succeed(0);
			case 'Text':
				return $elm$json$Json$Decode$succeed(1);
			case 'TextArea':
				return $elm$json$Json$Decode$succeed(2);
			case 'Integer':
				return $elm$json$Json$Decode$succeed(3);
			case 'Float':
				return $elm$json$Json$Decode$succeed(4);
			case 'Date':
				return $elm$json$Json$Decode$succeed(5);
			case 'Time':
				return $elm$json$Json$Decode$succeed(6);
			case 'DateTime':
				return $elm$json$Json$Decode$succeed(7);
			case 'Checkbox':
				return $elm$json$Json$Decode$succeed(8);
			case 'Barcode':
				return $elm$json$Json$Decode$succeed(9);
			default:
				return $elm$json$Json$Decode$fail('Invalid simple argument help type ' + str);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Decoding$InputFieldDecoding$inputFieldTypeDefinitionDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'simple':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$InputFieldDefinition$SimpleField,
					A3(
						$elm$json$Json$Decode$map2,
						$author$project$Entity$InputFieldDefinition$TSimpleInputField,
						A2($elm$json$Json$Decode$field, 'simpleFieldType', $author$project$Decoding$InputFieldDecoding$simpleFieldTypeDecoder),
						A2($elm$json$Json$Decode$field, 'addOffsetToTemporalTypes', $elm$json$Json$Decode$bool)));
			case 'selection':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$InputFieldDefinition$SelectionField,
					A6(
						$elm$json$Json$Decode$map5,
						$author$project$Entity$InputFieldDefinition$TSelectionField,
						A2($elm$json$Json$Decode$field, 'isLazy', $elm$json$Json$Decode$bool),
						A2($elm$json$Json$Decode$field, 'optionsClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
						A2($elm$json$Json$Decode$field, 'nullValueEnabled', $elm$json$Json$Decode$bool),
						A2($elm$json$Json$Decode$field, 'placeholder', $elm$json$Json$Decode$string),
						A2($elm$json$Json$Decode$field, 'selectFirstValueByDefault', $elm$json$Json$Decode$bool)));
			case 'file':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$InputFieldDefinition$FileInputField,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'allowMultipleFiles',
						$elm$json$Json$Decode$bool,
						true,
						$elm$json$Json$Decode$succeed($author$project$Entity$InputFieldDefinition$TFileInputField)));
			default:
				return $elm$json$Json$Decode$fail('Invalid argument help type' + str);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Decoding$InputFieldDecoding$inputFieldDefinitionDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$InputFieldDefinition$InputFieldDefinition,
	A2($elm$json$Json$Decode$field, 'identifier', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'defaultValue',
		$elm$json$Json$Decode$nullable($author$project$Serializable$decoder)),
	A2($elm$json$Json$Decode$field, 'required', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'typeDefinition', $author$project$Decoding$InputFieldDecoding$inputFieldTypeDefinitionDecoder));
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$Entity$ActionAndViewComponent$TOpenUrlAction = F5(
	function (url, targetName, specs, params, predicate) {
		return {lk: params, as: predicate, mj: specs, mu: targetName, mO: url};
	});
var $author$project$Decoding$ActionAndViewComponentDecoding$openUrlActionDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'predicate',
	$author$project$Decoding$PredicateDecoding$predicateDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'params',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'specs',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'targetName',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'url',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$TOpenUrlAction))))));
var $author$project$Entity$ActionAndViewComponent$TScriptAction = F4(
	function (script, params, parameterClauses, predicate) {
		return {dJ: parameterClauses, lk: params, as: predicate, lW: script};
	});
var $author$project$Decoding$ActionAndViewComponentDecoding$scriptActionDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'predicate',
	$author$project$Decoding$PredicateDecoding$predicateDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'parameterClauses',
		$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'params',
			$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'script',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$TScriptAction)))));
var $author$project$Entity$ActionAndViewComponent$TViewAction = F5(
	function (target, actionSpecifier, params, parameterClauses, predicate) {
		return {hy: actionSpecifier, dJ: parameterClauses, lk: params, as: predicate, ms: target};
	});
var $author$project$Entity$ActionAndViewComponent$AllSiblingsAndSelfTarget = {$: 3};
var $author$project$Entity$ActionAndViewComponent$GlobalTarget = {$: 0};
var $author$project$Entity$ActionAndViewComponent$SelfTarget = {$: 1};
var $author$project$Entity$ActionAndViewComponent$SourceViewTarget = {$: 2};
var $author$project$Entity$ActionAndViewComponent$ViewTarget = function (a) {
	return {$: 4, a: a};
};
var $author$project$Decoding$DecodingUtils$viewIdDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$int,
			A2(
			$elm$json$Json$Decode$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$String$toInt,
				$elm$core$Maybe$withDefault(0)),
			$elm$json$Json$Decode$string)
		]));
var $author$project$Decoding$ActionAndViewComponentDecoding$viewActionTargetDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'global':
				return $elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$GlobalTarget);
			case 'self':
				return $elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$SelfTarget);
			case 'sourceView':
				return $elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$SourceViewTarget);
			case 'allSiblingsAndSelf':
				return $elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$AllSiblingsAndSelfTarget);
			case 'view':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$ActionAndViewComponent$ViewTarget,
					A2($elm$json$Json$Decode$field, 'viewId', $author$project$Decoding$DecodingUtils$viewIdDecoder));
			default:
				return $elm$json$Json$Decode$fail('Invalid ViewActionTarget type ' + str);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Decoding$ActionAndViewComponentDecoding$viewActionDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$ActionAndViewComponent$TViewAction,
	A2($elm$json$Json$Decode$field, 'target', $author$project$Decoding$ActionAndViewComponentDecoding$viewActionTargetDecoder),
	A2($elm$json$Json$Decode$field, 'actionSpecifier', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'params',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'parameterClauses',
		$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder)),
	A2($elm$json$Json$Decode$field, 'predicate', $author$project$Decoding$PredicateDecoding$predicateDecoder));
var $author$project$Entity$ActionAndViewComponent$CustomAttribute = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$ActionAndViewComponent$PredefinedAttribute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$ActionAndViewComponent$CustomViewComponentAttribute = F3(
	function (attributeId, attributeValue, predicate) {
		return {hQ: attributeId, hR: attributeValue, as: predicate};
	});
var $author$project$Decoding$ActionAndViewComponentDecoding$customViewComponentAttributeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'predicate',
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault($author$project$Entity$Predicate$AlwaysPredicate),
		$elm$json$Json$Decode$nullable($author$project$Decoding$PredicateDecoding$predicateDecoder)),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'attributeValue',
		$author$project$Serializable$decoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'attributeId',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$CustomViewComponentAttribute))));
var $author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (typeStr) {
		switch (typeStr) {
			case 'custom':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$ActionAndViewComponent$CustomAttribute, $author$project$Decoding$ActionAndViewComponentDecoding$customViewComponentAttributeDecoder);
			case 'predefined':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$ActionAndViewComponent$PredefinedAttribute,
					A2($elm$json$Json$Decode$field, 'scenario', $elm$json$Json$Decode$string));
			default:
				return $elm$json$Json$Decode$fail('Unknown ViewComponentAttribute type ' + typeStr);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$buttonViewComponentDecoder() {
	return A8(
		$elm$json$Json$Decode$map7,
		$author$project$Entity$ActionAndViewComponent$TButtonViewComponent,
		A2($elm$json$Json$Decode$field, 'label', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'icon', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'action',
			$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder()),
		A2(
			$elm$json$Json$Decode$field,
			'dropZoneConfig',
			$elm$json$Json$Decode$nullable(
				$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder())),
		A2($elm$json$Json$Decode$field, 'cssCode', $elm$json$Json$Decode$string),
		A2(
			$elm$json$Json$Decode$field,
			'attributes',
			$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder)),
		A2(
			$elm$json$Json$Decode$field,
			'componentParameterClauses',
			$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder)));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$containerViewComponentDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'componentParameterClauses',
		$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'attributes',
			$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'cssCode',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'dropZoneConfig',
					$elm$json$Json$Decode$nullable(
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder()),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'contextMenuComponents',
						$elm$json$Json$Decode$list(
							$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder()),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'doubleClickAction',
							$elm$json$Json$Decode$nullable(
								$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder()),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'clickAction',
								$elm$json$Json$Decode$nullable(
									$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder()),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'childComponents',
									$elm$json$Json$Decode$list(
										$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder()),
									$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$TContainerViewComponent)))))))));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$dialogActionDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'predicate',
		$author$project$Decoding$PredicateDecoding$predicateDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'parameterClauses',
			$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'params',
				$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'components',
					$elm$json$Json$Decode$list(
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder()),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'modalSize',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'cssCode',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'containerId',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'containerCssClass',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'title',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$TDialogAction))))))))));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$textViewComponentDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'componentParameterClauses',
		$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'attributes',
			$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'cssCode',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'dragEnabled',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'dropZoneConfig',
						$elm$json$Json$Decode$nullable(
							$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder()),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'contextMenuComponents',
							$elm$json$Json$Decode$list(
								$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder()),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'doubleClickAction',
								$elm$json$Json$Decode$nullable(
									$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder()),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'clickAction',
									$elm$json$Json$Decode$nullable(
										$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder()),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'useHtml',
										$elm$json$Json$Decode$bool,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'useMarkdown',
											$elm$json$Json$Decode$bool,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'contentClause',
												$author$project$Decoding$ClauseDecoding$clauseDecoder,
												$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$TTextViewComponent))))))))))));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentDecoder() {
	return A8(
		$elm$json$Json$Decode$map7,
		$author$project$Entity$ActionAndViewComponent$TInputViewComponent,
		A2($elm$json$Json$Decode$field, 'fieldDefinition', $author$project$Decoding$InputFieldDecoding$inputFieldDefinitionDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'submitAction',
			$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentSubmitActionDecoder()),
		A2(
			$elm$json$Json$Decode$field,
			'dropZoneConfig',
			$elm$json$Json$Decode$nullable(
				$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder())),
		A2($elm$json$Json$Decode$field, 'cssCode', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'autoFocus', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'attributes',
			$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder)),
		A2(
			$elm$json$Json$Decode$field,
			'componentParameterClauses',
			$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder)));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder() {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dropAction',
		$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder(),
		$elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$ElementDropZoneConfig));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$generatorViewComponentDecoder() {
	return A5(
		$elm$json$Json$Decode$map4,
		$author$project$Entity$ActionAndViewComponent$TGeneratorViewComponent,
		A2($elm$json$Json$Decode$field, 'generatorClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
		A2(
			$elm$json$Json$Decode$field,
			'template',
			$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder()),
		A2(
			$elm$json$Json$Decode$field,
			'dynamicTemplates',
			$elm$json$Json$Decode$keyValuePairs(
				$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder())),
		A2(
			$elm$json$Json$Decode$field,
			'attributes',
			$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder)));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentSubmitActionDecoder() {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (str) {
			switch (str) {
				case 'none':
					return $elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$NoneSubmitAction);
				case 'viewDefault':
					return $elm$json$Json$Decode$succeed($author$project$Entity$ActionAndViewComponent$ViewDefaultSubmitAction);
				case 'custom':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$CustomSubmitAction,
						A2(
							$elm$json$Json$Decode$field,
							'action',
							$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder()));
				default:
					return $elm$json$Json$Decode$fail('unknown value for InputViewComponentSubmitAction: ' + str);
			}
		},
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$sequenceActionDecoder() {
	return A3(
		$elm$json$Json$Decode$map2,
		$author$project$Entity$ActionAndViewComponent$TSequenceAction,
		A2(
			$elm$json$Json$Decode$field,
			'actions',
			$elm$json$Json$Decode$list(
				$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder())),
		A2($elm$json$Json$Decode$field, 'predicate', $author$project$Decoding$PredicateDecoding$predicateDecoder));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder() {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (typeStr) {
			switch (typeStr) {
				case 'commandExecution':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$ActionAndViewComponent$CommandExecutionAction, $author$project$Decoding$ActionAndViewComponentDecoding$commandExecutionActionDecoder);
				case 'viewAction':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$ActionAndViewComponent$ViewAction, $author$project$Decoding$ActionAndViewComponentDecoding$viewActionDecoder);
				case 'openUrl':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$ActionAndViewComponent$OpenUrlAction, $author$project$Decoding$ActionAndViewComponentDecoding$openUrlActionDecoder);
				case 'dialogAction':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$DialogAction,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$dialogActionDecoder());
				case 'scriptAction':
					return A2($elm$json$Json$Decode$map, $author$project$Entity$ActionAndViewComponent$ScriptAction, $author$project$Decoding$ActionAndViewComponentDecoding$scriptActionDecoder);
				case 'sequenceAction':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$SequenceAction,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$sequenceActionDecoder());
				default:
					return $elm$json$Json$Decode$fail('Unknown view action type ' + typeStr);
			}
		},
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}
function $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder() {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (typeStr) {
			switch (typeStr) {
				case 'button':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$ButtonViewComponent,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$buttonViewComponentDecoder());
				case 'input':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$InputViewComponent,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentDecoder());
				case 'text':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$TextViewComponent,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$textViewComponentDecoder());
				case 'container':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$ContainerViewComponent,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$containerViewComponentDecoder());
				case 'generator':
					return A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$ActionAndViewComponent$GeneratorViewComponent,
						$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$generatorViewComponentDecoder());
				default:
					return $elm$json$Json$Decode$fail('Invalid ViewComponent type ' + typeStr);
			}
		},
		A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
}
var $author$project$Decoding$ActionAndViewComponentDecoding$buttonViewComponentDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$buttonViewComponentDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$buttonViewComponentDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$buttonViewComponentDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$containerViewComponentDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$containerViewComponentDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$containerViewComponentDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$containerViewComponentDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$dialogActionDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$dialogActionDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$dialogActionDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$dialogActionDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$textViewComponentDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$textViewComponentDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$textViewComponentDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$textViewComponentDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$inputViewComponentDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$inputViewComponentDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$elementDropZoneConfigDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$elementDropZoneConfigDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$elementDropZoneConfigDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$generatorViewComponentDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$generatorViewComponentDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$generatorViewComponentDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$generatorViewComponentDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$inputViewComponentSubmitActionDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentSubmitActionDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$inputViewComponentSubmitActionDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$inputViewComponentSubmitActionDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$sequenceActionDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$sequenceActionDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$sequenceActionDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$sequenceActionDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$kappaActionDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder;
};
var $author$project$Decoding$ActionAndViewComponentDecoding$viewComponentDefinitionDecoder = $author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder();
$author$project$Decoding$ActionAndViewComponentDecoding$cyclic$viewComponentDefinitionDecoder = function () {
	return $author$project$Decoding$ActionAndViewComponentDecoding$viewComponentDefinitionDecoder;
};
var $author$project$Decoding$CommandDecoding$actionRequestFieldDecoder = A2(
	$elm$json$Json$Decode$field,
	'actionRequest',
	$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder));
var $author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder = A2(
	$elm$json$Json$Decode$field,
	'actionRequestsClause',
	$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder));
var $author$project$Decoding$CommandDecoding$calendarViewCommandSpecDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'eventContextMenu',
			$elm$json$Json$Decode$nullable(
				$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentDefinitionDecoder)),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'eventClickAction',
				$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'eventEditedAction',
					$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'resourcesClause',
						$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'eventsClause',
							$author$project$Decoding$ClauseDecoding$clauseDecoder,
							$elm$json$Json$Decode$succeed($author$project$Entity$Command$CalendarViewCommandSpecification))))))));
var $author$project$Entity$Command$ChartCommandSpecification = F9(
	function (chartBaseType, mainClause, series, actionBindings, contextMenuBindings, chartEventHandlers, returnType, actionRequestsClause, actionRequest) {
		return {fP: actionBindings, hu: actionRequest, hw: actionRequestsClause, h8: chartBaseType, h9: chartEventHandlers, iD: contextMenuBindings, gC: mainClause, lQ: returnType, l3: series};
	});
var $author$project$Entity$Command$AavoActionBinding = F3(
	function (bindKey, selectItemOnClick, action) {
		return {fO: action, fW: bindKey, l_: selectItemOnClick};
	});
var $author$project$Decoding$CommandDecoding$actionBindingDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$Command$AavoActionBinding,
	A2($elm$json$Json$Decode$field, 'bindKey', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'selectItemOnClick', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'action', $author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder));
var $author$project$Entity$Command$ChartCommandExportedFileReturnType = function (fileNameClause) {
	return {jG: fileNameClause};
};
var $author$project$Entity$Command$ExportedFileReturnType = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$Command$JsonReturnType = {$: 0};
var $author$project$Decoding$CommandDecoding$chartCommandReturnTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'json':
				return $elm$json$Json$Decode$succeed($author$project$Entity$Command$JsonReturnType);
			case 'exportedFile':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$Command$ExportedFileReturnType,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'fileNameClause',
						$author$project$Decoding$ClauseDecoding$clauseDecoder,
						$elm$json$Json$Decode$succeed($author$project$Entity$Command$ChartCommandExportedFileReturnType)));
			default:
				return $elm$json$Json$Decode$fail('unknown value for ChartCommandReturnType: ' + str);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Entity$Command$ChartCommandSeriesConfig = F2(
	function (mainClause, dataClause) {
		return {eO: dataClause, gC: mainClause};
	});
var $author$project$Decoding$CommandDecoding$chartCommandSeriesConfigDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$Command$ChartCommandSeriesConfig,
	A2($elm$json$Json$Decode$field, 'mainClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'dataClause', $author$project$Decoding$ClauseDecoding$clauseDecoder));
var $author$project$Entity$Command$ContextMenuBinding = F2(
	function (bindKey, menuComponents) {
		return {fW: bindKey, kO: menuComponents};
	});
var $author$project$Decoding$CommandDecoding$contextMenuBindingDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$Command$ContextMenuBinding,
	A2($elm$json$Json$Decode$field, 'bindKey', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'menuComponents',
		$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentDefinitionDecoder)));
var $author$project$Entity$Command$CommonCharts = 1;
var $author$project$Entity$Command$GanttChart = 0;
var $author$project$Utils$TypeParsers$parseChartBaseType = function (str) {
	switch (str) {
		case 'GanttChart':
			return $elm$core$Result$Ok(0);
		case 'CommonCharts':
			return $elm$core$Result$Ok(1);
		default:
			return $elm$core$Result$Err('Invalid value for ChartBaseType: ' + str);
	}
};
var $author$project$Decoding$CommandDecoding$chartCommandSpecDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'returnType',
			$author$project$Decoding$CommandDecoding$chartCommandReturnTypeDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'chartEventHandlers',
				$elm$json$Json$Decode$value,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contextMenuBindings',
					$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$contextMenuBindingDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'actionBindings',
						$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$actionBindingDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'series',
							$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$chartCommandSeriesConfigDecoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'mainClause',
								$author$project$Decoding$ClauseDecoding$clauseDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'chartBaseType',
									$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseChartBaseType),
									$elm$json$Json$Decode$succeed($author$project$Entity$Command$ChartCommandSpecification))))))))));
var $author$project$Entity$Command$CronCommandSpecification = F4(
	function (cronExpression, firstExecutionTime, clauseToExecute, doNotLogIfNoMatch) {
		return {id: clauseToExecute, iK: cronExpression, i3: doNotLogIfNoMatch, jL: firstExecutionTime};
	});
var $author$project$Decoding$CommandDecoding$cronCommandSpecDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$Command$CronCommandSpecification,
	A2($elm$json$Json$Decode$field, 'cronExpression', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'firstExecutionTime', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'clauseToExecute', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'doNotLogIfNoMatch', $elm$json$Json$Decode$bool));
var $author$project$Entity$Command$DataViewCommandInsertConfiguration = F2(
	function (directInsertClause, formInsertEnabled) {
		return {i0: directInsertClause, jN: formInsertEnabled};
	});
var $author$project$Entity$Command$DataViewCommandSpec = function (fetchDataClause) {
	return function (staticColumnDefinitions) {
		return function (defineColumnsByData) {
			return function (idColumnName) {
				return function (dynamicColumnDefinitionsClause) {
					return function (deleteClause) {
						return function (updateClause) {
							return function (insertConfig) {
								return function (rowClassRules) {
									return function (dynamicClassRulesClause) {
										return function (selectionMode) {
											return function (paginationMode) {
												return function (draggableRows) {
													return function (rowDropZoneConfig) {
														return function (actionRequestsClause) {
															return function (actionRequest) {
																return function (selectFirstRowByDefault) {
																	return {hu: actionRequest, hw: actionRequestsClause, iX: defineColumnsByData, iY: deleteClause, i7: draggableRows, ja: dynamicClassRulesClause, jb: dynamicColumnDefinitionsClause, jD: fetchDataClause, ka: idColumnName, kl: insertConfig, lf: paginationMode, lT: rowClassRules, lU: rowDropZoneConfig, lY: selectFirstRowByDefault, l0: selectionMode, ml: staticColumnDefinitions, hn: updateClause};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$Command$DataViewClassRule = F3(
	function (className, columnName, rule) {
		return {ic: className, ih: columnName, lV: rule};
	});
var $author$project$Decoding$CommandDecoding$dataViewClassRuleDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'rule',
	$elm$json$Json$Decode$string,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'columnName',
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Maybe$withDefault(''),
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'className',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Entity$Command$DataViewClassRule))));
var $author$project$Entity$Command$DataViewColumn = F5(
	function (inputField, isHidden, editable, cellRenderer, useValuesAsParameters) {
		return {h6: cellRenderer, jg: editable, ki: inputField, kp: isHidden, mT: useValuesAsParameters};
	});
var $author$project$Entity$Command$DefaultCellRenderer = 0;
var $author$project$Entity$Command$HtmlCellRenderer = 1;
var $author$project$Entity$Command$IconCellRenderer = 4;
var $author$project$Entity$Command$ImageCellRenderer = 2;
var $author$project$Entity$Command$LinkCellRenderer = 3;
var $author$project$Utils$TypeParsers$dataViewCellRendererParser = function (str) {
	switch (str) {
		case 'Default':
			return $elm$core$Result$Ok(0);
		case 'Html':
			return $elm$core$Result$Ok(1);
		case 'Image':
			return $elm$core$Result$Ok(2);
		case 'Link':
			return $elm$core$Result$Ok(3);
		case 'Icon':
			return $elm$core$Result$Ok(4);
		default:
			return $elm$core$Result$Err('Invalid value for DataViewCellRenderer: ' + str);
	}
};
var $author$project$Decoding$CommandDecoding$dataViewColumnDisplayInstructionDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$Command$DataViewColumn,
	A2($elm$json$Json$Decode$field, 'fieldDefinition', $author$project$Decoding$InputFieldDecoding$inputFieldDefinitionDecoder),
	A2($elm$json$Json$Decode$field, 'isHidden', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'isEditable', $elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$field,
		'cellRenderer',
		$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$dataViewCellRendererParser)),
	A2($elm$json$Json$Decode$field, 'useValuesAsParameters', $elm$json$Json$Decode$bool));
var $author$project$Entity$Command$ClientSidePagination = 1;
var $author$project$Entity$Command$PaginationDisabled = 0;
var $author$project$Entity$Command$ServerSidePagination = 2;
var $author$project$Utils$TypeParsers$parseDataViewPaginationMode = function (str) {
	switch (str) {
		case 'PaginationDisabled':
			return $elm$core$Result$Ok(0);
		case 'ClientSidePagination':
			return $elm$core$Result$Ok(1);
		case 'ServerSidePagination':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('Invalid DataViewPaginationMode ' + str);
	}
};
var $author$project$Entity$Command$MultiRow = 2;
var $author$project$Entity$Command$SelectionDisabled = 0;
var $author$project$Entity$Command$SingleRow = 1;
var $author$project$Utils$TypeParsers$parseDataViewSelectionMode = function (str) {
	switch (str) {
		case 'SelectionDisabled':
			return $elm$core$Result$Ok(0);
		case 'SingleRow':
			return $elm$core$Result$Ok(1);
		case 'MultiRow':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('Invalid DataViewSelectionMode ' + str);
	}
};
var $author$project$Decoding$CommandDecoding$dataViewCommandDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'selectFirstRowByDefault',
	$elm$json$Json$Decode$bool,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder,
		A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'rowDropZoneConfig',
				$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$elementDropZoneConfigDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'draggableRows',
					$elm$json$Json$Decode$bool,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'paginationMode',
						$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseDataViewPaginationMode),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'selectionMode',
							$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseDataViewSelectionMode),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'dynamicClassRulesClause',
								$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'classRules',
									$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$dataViewClassRuleDecoder),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'insertConfig',
										$elm$json$Json$Decode$nullable(
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'formInsertEnabled',
												$elm$json$Json$Decode$bool,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'directInsertClause',
													$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder),
													$elm$json$Json$Decode$succeed($author$project$Entity$Command$DataViewCommandInsertConfiguration)))),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'updateClause',
											$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'deleteClause',
												$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'dynamicColumnDefinitionsClause',
													$author$project$Decoding$ClauseDecoding$clauseDecoder,
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'idColumnName',
														$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
														$elm$core$Maybe$Nothing,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'defineColumnsByData',
															$elm$json$Json$Decode$bool,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'staticColumnDefinitions',
																$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$dataViewColumnDisplayInstructionDecoder),
																A3(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																	'fetchDataClause',
																	$author$project$Decoding$ClauseDecoding$clauseDecoder,
																	$elm$json$Json$Decode$succeed($author$project$Entity$Command$DataViewCommandSpec))))))))))))))))));
var $author$project$Entity$Command$DiagramCommandSpecification = F6(
	function (markdownClause, actionBindings, contextMenus, nodeParametersClause, actionRequestsClause, actionRequest) {
		return {fP: actionBindings, hu: actionRequest, hw: actionRequestsClause, f5: contextMenus, kG: markdownClause, k0: nodeParametersClause};
	});
var $elm$json$Json$Decode$map6 = _Json_map6;
var $author$project$Decoding$CommandDecoding$diagramCommandSpecDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Entity$Command$DiagramCommandSpecification,
	A2($elm$json$Json$Decode$field, 'markdownClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'actionBindings',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$actionBindingDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'contextMenuBindings',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$contextMenuBindingDecoder)),
	A2($elm$json$Json$Decode$field, 'nodeParametersClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$DownloadFileCommandSpecification = F4(
	function (filePathClause, tryConvertToPdf, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, jH: filePathClause, mK: tryConvertToPdf};
	});
var $author$project$Decoding$CommandDecoding$downloadFileCommandSpecDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$Command$DownloadFileCommandSpecification,
	A2($elm$json$Json$Decode$field, 'filePathClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'tryConvertToPdf', $elm$json$Json$Decode$bool),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$FormCommandSpecification = F5(
	function (updateClause, insertClause, postSubmitAction, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, kk: insertClause, lq: postSubmitAction, hn: updateClause};
	});
var $author$project$Decoding$CommandDecoding$formCommandSpecDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$Command$FormCommandSpecification,
	A2(
		$elm$json$Json$Decode$field,
		'updateClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'insertClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'postSubmitAction',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder)),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$MailCommandSpecification = F6(
	function (subjectClause, contentClause, receiversClause, resultClause, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, iC: contentClause, gU: receiversClause, fu: resultClause, mo: subjectClause};
	});
var $author$project$Decoding$CommandDecoding$mailCommandSpecDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Entity$Command$MailCommandSpecification,
	A2($elm$json$Json$Decode$field, 'subjectClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'contentClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'receiversClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'resultClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$NotificationCommandSpecification = F8(
	function (titleClause, bodyClause, receiversClause, resultClause, action, imageClause, actionRequestsClause, actionRequest) {
		return {fO: action, hu: actionRequest, hw: actionRequestsClause, h0: bodyClause, ke: imageClause, gU: receiversClause, fu: resultClause, mD: titleClause};
	});
var $elm$json$Json$Decode$map8 = _Json_map8;
var $author$project$Decoding$CommandDecoding$notificationCommandSpecDecoder = A9(
	$elm$json$Json$Decode$map8,
	$author$project$Entity$Command$NotificationCommandSpecification,
	A2($elm$json$Json$Decode$field, 'titleClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'bodyClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'receiversClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'resultClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'action', $author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'imageClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$ProductionLineCommandSpecification = F7(
	function (dataClause, boxStylingRulesClause, timeStylingRulesClause, boxOrderingClause, boxesPerRowClause, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, h1: boxOrderingClause, h2: boxStylingRulesClause, h3: boxesPerRowClause, eO: dataClause, mz: timeStylingRulesClause};
	});
var $author$project$Decoding$CommandDecoding$productionLineCommandSpecDecoder = A8(
	$elm$json$Json$Decode$map7,
	$author$project$Entity$Command$ProductionLineCommandSpecification,
	A2($elm$json$Json$Decode$field, 'dataClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'boxStylingRulesClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'timeStylingRulesClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'boxOrderingClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2($elm$json$Json$Decode$field, 'boxesPerRowClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$QuickSightCommandSpecification = F3(
	function (dashboardIdClause, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, iN: dashboardIdClause};
	});
var $author$project$Decoding$CommandDecoding$quickSightCommandDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$Command$QuickSightCommandSpecification,
	A2($elm$json$Json$Decode$field, 'dashboardIdClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$ReportCommandSpecification = F8(
	function (templateFileCommandCall, templateFileClause, dataClause, resultFileNameClause, reportTimeZoneId, reportLocale, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, eO: dataClause, lE: reportLocale, lF: reportTimeZoneId, lL: resultFileNameClause, mw: templateFileClause, mx: templateFileCommandCall};
	});
var $author$project$Decoding$CommandDecoding$commandCallDefinitionDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$CommandCallDefinition$CommandCallDefinition,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'commandParams',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder)));
var $author$project$Decoding$CommandDecoding$reportCommandSpecDecoder = A9(
	$elm$json$Json$Decode$map8,
	$author$project$Entity$Command$ReportCommandSpecification,
	A2(
		$elm$json$Json$Decode$field,
		'templateFileCommandCall',
		$elm$json$Json$Decode$nullable($author$project$Decoding$CommandDecoding$commandCallDefinitionDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'templateFileClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	A2($elm$json$Json$Decode$field, 'dataClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'resultFileNameClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	A2($elm$json$Json$Decode$field, 'reportTimeZoneId', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'reportLocale', $elm$json$Json$Decode$string),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$SingleClauseCommandSpec = F3(
	function (clause, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, eH: clause};
	});
var $author$project$Decoding$CommandDecoding$singleClauseCommandSpecDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$Command$SingleClauseCommandSpec,
	A2($elm$json$Json$Decode$field, 'clause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$TreeViewCommandSpecification = function (elementsClause) {
	return function (actions) {
		return function (contextMenus) {
			return function (onElementSelectionChanged) {
				return function (onElementClick) {
					return function (onElementDoubleClick) {
						return function (elementDropZoneConfig) {
							return function (elementGapDropZoneConfig) {
								return function (draggableElements) {
									return function (actionRequestsClause) {
										return function (actionRequest) {
											return {hu: actionRequest, hw: actionRequestsClause, hA: actions, f5: contextMenus, i6: draggableElements, jj: elementDropZoneConfig, jk: elementGapDropZoneConfig, jl: elementsClause, k3: onElementClick, k4: onElementDoubleClick, k5: onElementSelectionChanged};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Decoding$CommandDecoding$treeViewCommandSpecDecoder = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
		$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'draggableElements',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'elementGapDropZoneConfig',
				$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$elementDropZoneConfigDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'elementDropZoneConfig',
					$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$elementDropZoneConfigDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'onElementDoubleClick',
						$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'onElementClick',
							$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'onElementSelectionChanged',
								$elm$json$Json$Decode$nullable($author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'contextMenus',
									$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$contextMenuBindingDecoder),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'actions',
										$elm$json$Json$Decode$list($author$project$Decoding$CommandDecoding$actionBindingDecoder),
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'elementsClause',
											$author$project$Decoding$ClauseDecoding$clauseDecoder,
											$elm$json$Json$Decode$succeed($author$project$Entity$Command$TreeViewCommandSpecification))))))))))));
var $author$project$Entity$Command$UploadFilesCommandSpecification = F6(
	function (filesParameterName, targetFolderPathClause, postUploadClause, resultClause, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, jJ: filesParameterName, lr: postUploadClause, fu: resultClause, mt: targetFolderPathClause};
	});
var $author$project$Decoding$CommandDecoding$uploadFilesCommandSpecDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Entity$Command$UploadFilesCommandSpecification,
	A2($elm$json$Json$Decode$field, 'filesParameterName', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'targetFolderPathClause', $author$project$Decoding$ClauseDecoding$clauseDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'postUploadClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'resultClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Entity$Command$ViewComponentsCommandSpecification = F4(
	function (componentDefinitions, forcedValuesForExistingRecordClause, actionRequestsClause, actionRequest) {
		return {hu: actionRequest, hw: actionRequestsClause, ir: componentDefinitions, jM: forcedValuesForExistingRecordClause};
	});
var $author$project$Decoding$CommandDecoding$viewComponentsCommandDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$Command$ViewComponentsCommandSpecification,
	A2(
		$elm$json$Json$Decode$field,
		'componentDefinitions',
		$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentDefinitionDecoder)),
	A2(
		$elm$json$Json$Decode$field,
		'forcedValuesForExistingRecordClause',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ClauseDecoding$clauseDecoder)),
	$author$project$Decoding$CommandDecoding$actionRequestsClauseFieldDecoder,
	$author$project$Decoding$CommandDecoding$actionRequestFieldDecoder);
var $author$project$Decoding$CommandDecoding$commandSpecDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (cmdType) {
		switch (cmdType) {
			case 'singleClause':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecSingleClauseCommand, $author$project$Decoding$CommandDecoding$singleClauseCommandSpecDecoder);
			case 'dataView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecDataViewCommand, $author$project$Decoding$CommandDecoding$dataViewCommandDecoder);
			case 'form':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecFormCommand, $author$project$Decoding$CommandDecoding$formCommandSpecDecoder);
			case 'productionLine':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecProductionLineCommand, $author$project$Decoding$CommandDecoding$productionLineCommandSpecDecoder);
			case 'mail':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecMailCommand, $author$project$Decoding$CommandDecoding$mailCommandSpecDecoder);
			case 'notification':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecNotificationCommand, $author$project$Decoding$CommandDecoding$notificationCommandSpecDecoder);
			case 'downloadFile':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecDownloadFileCommand, $author$project$Decoding$CommandDecoding$downloadFileCommandSpecDecoder);
			case 'uploadFiles':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecUploadFilesCommand, $author$project$Decoding$CommandDecoding$uploadFilesCommandSpecDecoder);
			case 'report':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecReportCommand, $author$project$Decoding$CommandDecoding$reportCommandSpecDecoder);
			case 'chart':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecChartCommand, $author$project$Decoding$CommandDecoding$chartCommandSpecDecoder);
			case 'diagram':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecDiagramCommand, $author$project$Decoding$CommandDecoding$diagramCommandSpecDecoder);
			case 'treeView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecTreeViewCommand, $author$project$Decoding$CommandDecoding$treeViewCommandSpecDecoder);
			case 'calendar':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecCalendarViewCommand, $author$project$Decoding$CommandDecoding$calendarViewCommandSpecDecoder);
			case 'quickSight':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecQuickSightCommand, $author$project$Decoding$CommandDecoding$quickSightCommandDecoder);
			case 'cron':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecCronCommand, $author$project$Decoding$CommandDecoding$cronCommandSpecDecoder);
			case 'viewComponents':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Command$SpecViewComponentsCommand, $author$project$Decoding$CommandDecoding$viewComponentsCommandDecoder);
			default:
				return $elm$json$Json$Decode$fail('Command type ' + (cmdType + ' is invalid'));
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Entity$Connection$ConnectionReferenceSet = F5(
	function (dbConnectionId, httpConnectionId, fileServerConnectionId, mailServerConnectionId, awsConnectionId) {
		return {hW: awsConnectionId, iQ: dbConnectionId, jI: fileServerConnectionId, j7: httpConnectionId, kE: mailServerConnectionId};
	});
var $author$project$Decoding$ConnectionDecoding$connectionReferenceSetDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$Connection$ConnectionReferenceSet,
	A2(
		$elm$json$Json$Decode$field,
		'dbConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'httpConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'fileServerConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'mailServerConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'awsConnectionId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)));
var $author$project$Decoding$InputFieldDecoding$inputFieldDefinitionListDecoder = $elm$json$Json$Decode$list($author$project$Decoding$InputFieldDecoding$inputFieldDefinitionDecoder);
var $author$project$Decoding$CommandDecoding$commandDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'specification',
	$author$project$Decoding$CommandDecoding$commandSpecDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'timeoutSeconds',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'baseClauses',
			$elm$json$Json$Decode$list($author$project$Decoding$ClauseDecoding$parameterClauseDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'parameterMappings',
				$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'extraInputFieldDefinitions',
					$author$project$Decoding$InputFieldDecoding$inputFieldDefinitionListDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'connections',
						$author$project$Decoding$ConnectionDecoding$connectionReferenceSetDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'description',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'key',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'idAnchor',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'aproProjectId',
										$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'pos',
											$elm$json$Json$Decode$int,
											0,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'groupId',
												$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'id',
													$elm$json$Json$Decode$int,
													$elm$json$Json$Decode$succeed($author$project$Entity$Command$Command))))))))))))));
var $author$project$Api$ApiUrls$commandsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/commands';
var $author$project$Api$EntityApi$getCommands = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$commandsUrl, $author$project$Decoding$CommandDecoding$commandDecoder);
var $author$project$Api$ApiUrls$connectionGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/connectionGroups';
var $author$project$Api$EntityApi$getConnectionGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$connectionGroupsUrl, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Entity$Connection$Connection = F8(
	function (id, groupId, pos, aproProjectId, idAnchor, name, description, specification) {
		return {hM: aproProjectId, i$: description, j_: groupId, db: id, j9: idAnchor, k_: name, dM: pos, mi: specification};
	});
var $author$project$Entity$Connection$SpecAwsConnection = function (a) {
	return {$: 4, a: a};
};
var $author$project$Entity$Connection$SpecAzureAdConnection = function (a) {
	return {$: 5, a: a};
};
var $author$project$Entity$Connection$SpecDatabaseConnection = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$Connection$SpecDynamicConnection = {$: 0};
var $author$project$Entity$Connection$SpecHttpConnection = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$Connection$SpecMailServerConnection = function (a) {
	return {$: 3, a: a};
};
var $author$project$Entity$Connection$AwsConnectionSpecification = F4(
	function (awsRegion, awsAccessKey, awsAccessKeySecret, defaultS3Bucket) {
		return {hU: awsAccessKey, hV: awsAccessKeySecret, hX: awsRegion, iU: defaultS3Bucket};
	});
var $author$project$Decoding$ConnectionDecoding$awsConnectionSpecDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$Connection$AwsConnectionSpecification,
	A2($elm$json$Json$Decode$field, 'awsRegion', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'awsAccessKey', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'awsAccessKeySecret', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'defaultS3Bucket', $elm$json$Json$Decode$string));
var $author$project$Entity$Connection$AzureAdConnectionSpecification = F4(
	function (azureAdTenantId, clientId, clientSecret, sharePointRootSiteId) {
		return {hY: azureAdTenantId, ie: clientId, $8: clientSecret, l9: sharePointRootSiteId};
	});
var $author$project$Decoding$ConnectionDecoding$azureAdConnectionSpecDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$Connection$AzureAdConnectionSpecification,
	A2($elm$json$Json$Decode$field, 'azureAdTenantId', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'clientId', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'clientSecret', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'sharePointRootSiteId', $elm$json$Json$Decode$string));
var $author$project$Entity$Connection$DatabaseConnectionSpecification = F9(
	function (dbType, serverName, portNumber, databaseName, user, password, poolSettings, properties, convertTimesBetweenClientTimeZoneAndUTC) {
		return {iF: convertTimesBetweenClientTimeZoneAndUTC, iP: databaseName, iR: dbType, fl: password, ln: poolSettings, lo: portNumber, lx: properties, l4: serverName, hp: user};
	});
var $author$project$Entity$Connection$ConnectionPoolSettings = F9(
	function (maximumPoolSize, minimumIdle, connectionTimeout, maxLifetime, keepaliveTime, idleTimeout, leakDetectionThreshold, maxOverflowPoolSize, retryAttempts) {
		return {ix: connectionTimeout, kd: idleTimeout, ks: keepaliveTime, ky: leakDetectionThreshold, kJ: maxLifetime, kK: maxOverflowPoolSize, kM: maximumPoolSize, kW: minimumIdle, lP: retryAttempts};
	});
var $author$project$Decoding$ConnectionDecoding$databaseConnectionPoolSettingsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'retryAttempts',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'maxOverflowPoolSize',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'leakDetectionThreshold',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'idleTimeout',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'keepaliveTime',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'maxLifeTime',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'connectionTimeout',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'minimumIdle',
								$elm$json$Json$Decode$int,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'maximumPoolSize',
									$elm$json$Json$Decode$int,
									$elm$json$Json$Decode$succeed($author$project$Entity$Connection$ConnectionPoolSettings))))))))));
var $author$project$Entity$Connection$MariaDB = 3;
var $author$project$Entity$Connection$OracleDB = 2;
var $author$project$Entity$Connection$PostgreSQL = 0;
var $author$project$Entity$Connection$SqlServer = 1;
var $author$project$Utils$TypeParsers$parseDatabaseType = function (str) {
	switch (str) {
		case 'PostgreSQL':
			return $elm$core$Result$Ok(0);
		case 'SqlServer':
			return $elm$core$Result$Ok(1);
		case 'OracleDB':
			return $elm$core$Result$Ok(2);
		case 'MariaDB':
			return $elm$core$Result$Ok(3);
		default:
			return $elm$core$Result$Err('Invalid DatabaseType ' + str);
	}
};
var $author$project$Decoding$ConnectionDecoding$databaseConnectionSpecDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'convertTimesBetweenClientTimeZoneAndUTC',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'properties',
		$elm$json$Json$Decode$keyValuePairs($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'poolSettings',
			$author$project$Decoding$ConnectionDecoding$databaseConnectionPoolSettingsDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'password',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'username',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'databaseName',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'portNumber',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'serverName',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'dbType',
									$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseDatabaseType),
									$elm$json$Json$Decode$succeed($author$project$Entity$Connection$DatabaseConnectionSpecification))))))))));
var $author$project$Entity$Connection$HttpConnectionSpecification = F5(
	function (url, basicAuthCredentials, credentialsJson, defaultHeaders, installKtorJsonFeature) {
		return {h_: basicAuthCredentials, iH: credentialsJson, iT: defaultHeaders, km: installKtorJsonFeature, mO: url};
	});
var $author$project$Entity$Connection$HttpCredentials = F2(
	function (user, password) {
		return {fl: password, hp: user};
	});
var $author$project$Decoding$ConnectionDecoding$httpCredentialsDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$Connection$HttpCredentials,
	A2($elm$json$Json$Decode$field, 'username', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'password', $elm$json$Json$Decode$string));
var $author$project$Entity$Connection$HttpHeader = F3(
	function (name, value, encrypt) {
		return {jn: encrypt, k_: name, mY: value};
	});
var $author$project$Decoding$ConnectionDecoding$httpHeaderDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$Connection$HttpHeader,
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'value', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'encrypt', $elm$json$Json$Decode$bool));
var $author$project$Decoding$ConnectionDecoding$httpConnectionSpecDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$Connection$HttpConnectionSpecification,
	A2($elm$json$Json$Decode$field, 'url', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'basicAuthCredentials',
		$elm$json$Json$Decode$nullable($author$project$Decoding$ConnectionDecoding$httpCredentialsDecoder)),
	A2($elm$json$Json$Decode$field, 'credentials', $elm$json$Json$Decode$value),
	A2(
		$elm$json$Json$Decode$field,
		'defaultHeaders',
		$elm$json$Json$Decode$list($author$project$Decoding$ConnectionDecoding$httpHeaderDecoder)),
	A2($elm$json$Json$Decode$field, 'installKtorJsonFeature', $elm$json$Json$Decode$bool));
var $author$project$Entity$Connection$MailServerConnectionSpecification = F5(
	function (host, port_, from, username, password) {
		return {jP: from, j5: host, fl: password, lp: port_, mX: username};
	});
var $author$project$Decoding$ConnectionDecoding$mailServerConnectionSpecDecoder = A6(
	$elm$json$Json$Decode$map5,
	$author$project$Entity$Connection$MailServerConnectionSpecification,
	A2($elm$json$Json$Decode$field, 'host', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'port', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'fromAddress', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'username', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'password', $elm$json$Json$Decode$string));
var $author$project$Decoding$ConnectionDecoding$connectionSpecDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (connTypeStr) {
		switch (connTypeStr) {
			case 'dynamic':
				return $elm$json$Json$Decode$succeed($author$project$Entity$Connection$SpecDynamicConnection);
			case 'database':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Connection$SpecDatabaseConnection, $author$project$Decoding$ConnectionDecoding$databaseConnectionSpecDecoder);
			case 'http':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Connection$SpecHttpConnection, $author$project$Decoding$ConnectionDecoding$httpConnectionSpecDecoder);
			case 'mail_server':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Connection$SpecMailServerConnection, $author$project$Decoding$ConnectionDecoding$mailServerConnectionSpecDecoder);
			case 'aws':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Connection$SpecAwsConnection, $author$project$Decoding$ConnectionDecoding$awsConnectionSpecDecoder);
			case 'azure_ad':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$Connection$SpecAzureAdConnection, $author$project$Decoding$ConnectionDecoding$azureAdConnectionSpecDecoder);
			default:
				return $elm$json$Json$Decode$fail('Invalid connection type ' + connTypeStr);
		}
	},
	A2($elm$json$Json$Decode$field, 'conn_type', $elm$json$Json$Decode$string));
var $author$project$Decoding$DecodingUtils$groupIdDecoder = A2(
	$elm$json$Json$Decode$field,
	'groupId',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Decoding$DecodingUtils$idDecoder = A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$int);
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$Decoding$ConnectionDecoding$connectionDecoder = A9(
	$elm$json$Json$Decode$map8,
	$author$project$Entity$Connection$Connection,
	$author$project$Decoding$DecodingUtils$idDecoder,
	$author$project$Decoding$DecodingUtils$groupIdDecoder,
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(0),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$field, 'pos', $elm$json$Json$Decode$int))),
	A2(
		$elm$json$Json$Decode$field,
		'aproProjectId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'idAnchor',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'description', $elm$json$Json$Decode$string),
	$author$project$Decoding$ConnectionDecoding$connectionSpecDecoder);
var $author$project$Api$ApiUrls$connectionsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/connections';
var $author$project$Api$EntityApi$getConnections = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$connectionsUrl, $author$project$Decoding$ConnectionDecoding$connectionDecoder);
var $author$project$Api$ApiUrls$endpointGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/endpointGroups';
var $author$project$Api$EntityApi$getEndpointGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$endpointGroupsUrl, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Entity$Endpoint$Endpoint = function (id) {
	return function (groupId) {
		return function (pos) {
			return function (aproProjectId) {
				return function (idAnchor) {
					return function (name) {
						return function (description) {
							return function (path) {
								return function (methods) {
									return function (commandId) {
										return function (commandParamMappings) {
											return function (returnType) {
												return {hM: aproProjectId, f2: commandId, io: commandParamMappings, i$: description, j_: groupId, db: id, j9: idAnchor, kS: methods, k_: name, ll: path, dM: pos, lQ: returnType};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$Endpoint$BinaryFile = 2;
var $author$project$Entity$Endpoint$Json = 0;
var $author$project$Entity$Endpoint$PlainText = 1;
var $author$project$Utils$TypeParsers$parseEndpointReturnType = function (str) {
	switch (str) {
		case 'Json':
			return $elm$core$Result$Ok(0);
		case 'PlainText':
			return $elm$core$Result$Ok(1);
		case 'BinaryFile':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('Invalid EndpointReturnType ' + str);
	}
};
var $author$project$Decoding$EndpointDecoding$endpointDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'returnType',
	$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseEndpointReturnType),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'commandParamMappings',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'commandId',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'methods',
				$elm$json$Json$Decode$list(
					$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseHttpMethod)),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'path',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'description',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'idAnchor',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'aproProjectId',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'pos',
										$elm$json$Json$Decode$int,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'groupId',
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$elm$json$Json$Decode$int,
												$elm$json$Json$Decode$succeed($author$project$Entity$Endpoint$Endpoint)))))))))))));
var $author$project$Api$ApiUrls$endpointsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/endpoints';
var $author$project$Api$EntityApi$getEndpoints = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$endpointsUrl, $author$project$Decoding$EndpointDecoding$endpointDecoder);
var $author$project$Api$ApiUrls$environmentGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/environmentGroups';
var $author$project$Api$EntityApi$getEnvironmentGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$environmentGroupsUrl, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Entity$Environment$Environment = function (id) {
	return function (groupId) {
		return function (pos) {
			return function (aproProjectId) {
				return function (idAnchor) {
					return function (name) {
						return function (displayName) {
							return function (description) {
								return function (environmentType) {
									return function (connections) {
										return function (dynamicConnections) {
											return function (environmentParams) {
												return {hM: aproProjectId, iy: connections, i$: description, i1: displayName, jd: dynamicConnections, jq: environmentParams, jr: environmentType, j_: groupId, db: id, j9: idAnchor, k_: name, dM: pos};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$Environment$DynamicConnectionAssociation = F2(
	function (dynamicConnectionId, actualConnectionId) {
		return {hC: actualConnectionId, jc: dynamicConnectionId};
	});
var $author$project$Decoding$EnvironmentDecoding$dynamicConnectionAssociationDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'actualConnectionId',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dynamicConnectionId',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Entity$Environment$DynamicConnectionAssociation)));
var $author$project$Decoding$EnvironmentDecoding$environmentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'environmentParameters',
	$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dynamicConnections',
		$elm$json$Json$Decode$list($author$project$Decoding$EnvironmentDecoding$dynamicConnectionAssociationDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'connections',
			$author$project$Decoding$ConnectionDecoding$connectionReferenceSetDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'environmentType',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'description',
					$elm$json$Json$Decode$string,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'displayName',
						$elm$json$Json$Decode$string,
						'',
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'name',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'idAnchor',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'aproProjectId',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'pos',
										$elm$json$Json$Decode$int,
										0,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'groupId',
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'id',
												$elm$json$Json$Decode$int,
												$elm$json$Json$Decode$succeed($author$project$Entity$Environment$Environment)))))))))))));
var $author$project$Api$ApiUrls$environmentsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/environments';
var $author$project$Api$EntityApi$getEnvironments = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$environmentsUrl, $author$project$Decoding$EnvironmentDecoding$environmentDecoder);
var $author$project$Entity$IdentityProvider$IdentityProvider = F3(
	function (id, name, type_) {
		return {db: id, k_: name, mM: type_};
	});
var $author$project$Entity$IdentityProvider$Auth0IdentityProvider = 0;
var $author$project$Entity$IdentityProvider$OAuthIdentityProvider = 1;
var $author$project$Api$MiscApi$identityProviderTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Auth0':
				return $elm$json$Json$Decode$succeed(0);
			case 'OAuth':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unknown IdentityProviderType ' + str);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Api$MiscApi$identityProviderDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'type',
	$author$project$Api$MiscApi$identityProviderTypeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'name',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'id',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Entity$IdentityProvider$IdentityProvider))));
var $author$project$Api$ApiUrls$identityProvidersUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/identityProviders';
var $author$project$Api$MiscApi$getIdentityProviders = function (toMsg) {
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			A2(
				$author$project$Lib$CustomHttp$expectJsonCustom,
				toMsg,
				$elm$json$Json$Decode$list($author$project$Api$MiscApi$identityProviderDecoder)),
			$lukewestby$elm_http_builder$HttpBuilder$get($author$project$Api$ApiUrls$identityProvidersUrl)));
};
var $author$project$Pages$Main$MainPage$LoadCommandGroups = {$: 23};
var $author$project$Pages$Main$MainPage$LoadCommands = {$: 22};
var $author$project$Pages$Main$MainPage$LoadConnectionGroups = {$: 27};
var $author$project$Pages$Main$MainPage$LoadConnections = {$: 26};
var $author$project$Pages$Main$MainPage$LoadEndpointGroups = {$: 31};
var $author$project$Pages$Main$MainPage$LoadEndpoints = {$: 29};
var $author$project$Pages$Main$MainPage$LoadEnvironmentGroups = {$: 30};
var $author$project$Pages$Main$MainPage$LoadEnvironments = {$: 28};
var $author$project$Pages$Main$MainPage$LoadIdentityProviders = {$: 36};
var $author$project$Pages$Main$MainPage$LoadPrincipalGroups = {$: 35};
var $author$project$Pages$Main$MainPage$LoadPrincipals = {$: 34};
var $author$project$Pages$Main$MainPage$LoadRoleGroups = {$: 25};
var $author$project$Pages$Main$MainPage$LoadRoles = {$: 24};
var $author$project$Pages$Main$MainPage$LoadViewGroups = {$: 33};
var $author$project$Pages$Main$MainPage$LoadViews = {$: 32};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $author$project$Pages$Main$TenantSettingsView$LoadTenantSettings = {$: 1};
var $author$project$Pages$Main$TenantSettingsView$reloadTenantSettings = $author$project$Pages$Main$TenantSettingsView$LoadTenantSettings;
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$Pages$Main$MainPage$getLoadMessagesForAllPermittedEntities = function (model) {
	var loadMessagesByPermissionOptions = _List_fromArray(
		[
			_Utils_Tuple2(
			_List_fromArray(
				[1, 8]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadCommands, $author$project$Pages$Main$MainPage$LoadCommandGroups])),
			_Utils_Tuple2(
			_List_fromArray(
				[6, 13]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadRoles, $author$project$Pages$Main$MainPage$LoadRoleGroups])),
			_Utils_Tuple2(
			_List_fromArray(
				[3, 10]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadConnections, $author$project$Pages$Main$MainPage$LoadConnectionGroups])),
			_Utils_Tuple2(
			_List_fromArray(
				[4, 11]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadEnvironments, $author$project$Pages$Main$MainPage$LoadEnvironmentGroups])),
			_Utils_Tuple2(
			_List_fromArray(
				[5, 12]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadEndpoints, $author$project$Pages$Main$MainPage$LoadEndpointGroups])),
			_Utils_Tuple2(
			_List_fromArray(
				[2, 9]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadViews, $author$project$Pages$Main$MainPage$LoadViewGroups])),
			_Utils_Tuple2(
			_List_fromArray(
				[7, 14]),
			_List_fromArray(
				[$author$project$Pages$Main$MainPage$LoadPrincipals, $author$project$Pages$Main$MainPage$LoadPrincipalGroups, $author$project$Pages$Main$MainPage$LoadIdentityProviders])),
			_Utils_Tuple2(
			_List_fromArray(
				[15]),
			_List_fromArray(
				[
					$author$project$Pages$Main$MainPage$TenantSettingsMsg($author$project$Pages$Main$TenantSettingsView$reloadTenantSettings)
				]))
		]);
	var _v0 = model.Z;
	if (_v0.$ === 3) {
		var userPermissions = _v0.a;
		return A2(
			$elm$core$List$concatMap,
			$elm$core$Tuple$second,
			A2(
				$elm$core$List$filter,
				function (_v1) {
					var permissionOptions = _v1.a;
					return A2(
						$elm$core$List$any,
						function (permission) {
							return A2($elm$core$List$member, permission, userPermissions);
						},
						permissionOptions);
				},
				loadMessagesByPermissionOptions));
	} else {
		return _List_Nil;
	}
};
var $author$project$Api$ApiUrls$principalAssociationGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/principalAssociationGroups';
var $author$project$Api$EntityApi$getPrincipalGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$principalAssociationGroupsUrl, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Api$ApiUrls$principalAssociationsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/principals';
var $author$project$Entity$PrincipalAssociation$PrincipalAssociation = F9(
	function (id, groupId, pos, principalState, principalType, sessionExpirationDurationDays, description, environmentAssociations, userSettings) {
		return {i$: description, jo: environmentAssociations, j_: groupId, db: id, dM: pos, lv: principalState, lw: principalType, l5: sessionExpirationDurationDays, mW: userSettings};
	});
var $author$project$Entity$Environment$EnvironmentAssociation = F2(
	function (environmentId, roleId) {
		return {jp: environmentId, lS: roleId};
	});
var $author$project$Decoding$PrincipalDecoding$environmentAssociationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$Environment$EnvironmentAssociation,
	A2($elm$json$Json$Decode$field, 'environmentId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'roleId', $elm$json$Json$Decode$int));
var $author$project$Entity$PrincipalAssociation$PreparedPrincipalState = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$PrincipalAssociation$ReadyPrincipalState = function (a) {
	return {$: 1, a: a};
};
var $author$project$Decoding$PrincipalDecoding$principalStateDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'prepared':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$PrincipalAssociation$PreparedPrincipalState,
					A2(
						$elm$json$Json$Decode$map,
						function (id) {
							return {ls: id};
						},
						A2($elm$json$Json$Decode$field, 'preparedPrincipalId', $elm$json$Json$Decode$string)));
			case 'ready':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$PrincipalAssociation$ReadyPrincipalState,
					A2(
						$elm$json$Json$Decode$map,
						function (id) {
							return {lu: id};
						},
						A2($elm$json$Json$Decode$field, 'principalId', $elm$json$Json$Decode$string)));
			default:
				return $elm$json$Json$Decode$fail('Invalid PrincipalState ' + str);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Entity$PrincipalAssociation$TTokenPrincipal = function (clientId) {
	return {ie: clientId};
};
var $author$project$Entity$PrincipalAssociation$TUserPrincipal = F2(
	function (email, mfaEnabled) {
		return {gd: email, kT: mfaEnabled};
	});
var $author$project$Entity$PrincipalAssociation$TokenPrincipal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Entity$PrincipalAssociation$UserPrincipal = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $author$project$Decoding$PrincipalDecoding$principalTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'user':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$PrincipalAssociation$UserPrincipal,
					A3(
						$elm$json$Json$Decode$map2,
						$author$project$Entity$PrincipalAssociation$TUserPrincipal,
						A2($elm$json$Json$Decode$field, 'email', $elm$json$Json$Decode$string),
						A2($elm$json$Json$Decode$field, 'mfaEnabled', $elm$json$Json$Decode$bool)));
			case 'token':
				return A2(
					$elm$json$Json$Decode$map,
					$author$project$Entity$PrincipalAssociation$TokenPrincipal,
					A2(
						$elm$json$Json$Decode$map,
						$author$project$Entity$PrincipalAssociation$TTokenPrincipal,
						A2(
							$elm$json$Json$Decode$at,
							_List_fromArray(
								['tokenCredentials', 'clientId']),
							$elm$json$Json$Decode$string)));
			default:
				return $elm$json$Json$Decode$fail('Invalid principal type ' + str);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $elm$core$Basics$round = _Basics_round;
var $author$project$Utils$TimeUtils$secondsToDays = function (seconds) {
	return $elm$core$Basics$round(((seconds / 60) / 60) / 24);
};
var $author$project$Entity$PrincipalAssociation$UserSettings = F2(
	function (userParameters, useNfc) {
		return {mS: useNfc, mV: userParameters};
	});
var $author$project$Decoding$PrincipalDecoding$userSettingsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'useNfc',
	$elm$json$Json$Decode$bool,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'userParameters',
		$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
		$elm$json$Json$Decode$succeed($author$project$Entity$PrincipalAssociation$UserSettings)));
var $author$project$Decoding$PrincipalDecoding$principalDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'userSettings',
	$author$project$Decoding$PrincipalDecoding$userSettingsDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'environmentAssociations',
		$elm$json$Json$Decode$list($author$project$Decoding$PrincipalDecoding$environmentAssociationDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'description',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'sessionExpirationDurationSeconds',
				A2($elm$json$Json$Decode$map, $author$project$Utils$TimeUtils$secondsToDays, $elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'principalType',
					$author$project$Decoding$PrincipalDecoding$principalTypeDecoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'principalState',
						$author$project$Decoding$PrincipalDecoding$principalStateDecoder,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'pos',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'groupId',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'id',
									$elm$json$Json$Decode$int,
									$elm$json$Json$Decode$succeed($author$project$Entity$PrincipalAssociation$PrincipalAssociation))))))))));
var $author$project$Api$EntityApi$getPrincipals = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$principalAssociationsUrl, $author$project$Decoding$PrincipalDecoding$principalDecoder);
var $author$project$Api$ApiUrls$roleGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/roleGroups';
var $author$project$Api$EntityApi$getRoleGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$roleGroupsUrl, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Entity$Role$Role = function (id) {
	return function (groupId) {
		return function (pos) {
			return function (aproProjectId) {
				return function (idAnchor) {
					return function (name) {
						return function (description) {
							return function (isUserRole) {
								return function (defaultViewId) {
									return function (views) {
										return function (commandIds) {
											return function (includedRoleIds) {
												return function (permissions) {
													return function (settingsViewComponentCommandIds) {
														return function (appBarViewComponentCommandIds) {
															return {hL: appBarViewComponentCommandIds, hM: aproProjectId, $9: commandIds, iW: defaultViewId, i$: description, j_: groupId, db: id, j9: idAnchor, kg: includedRoleIds, kr: isUserRole, k_: name, gN: permissions, dM: pos, l8: settingsViewComponentCommandIds, m$: views};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$Role$ViewAssociation = F3(
	function (viewId, showDirectly, permissions) {
		return {gN: permissions, mc: showDirectly, m_: viewId};
	});
var $author$project$Entity$Role$DataViewPermissions = function (editingEnabled) {
	return {jh: editingEnabled};
};
var $author$project$Entity$Role$ViewPermissions = function (dataViewPermissions) {
	return {iO: dataViewPermissions};
};
var $author$project$Decoding$RoleDecoding$viewPermissionsDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Entity$Role$ViewPermissions,
	A2(
		$elm$json$Json$Decode$field,
		'dataViewPermissions',
		A2(
			$elm$json$Json$Decode$map,
			$author$project$Entity$Role$DataViewPermissions,
			A2($elm$json$Json$Decode$field, 'editingEnabled', $elm$json$Json$Decode$bool))));
var $author$project$Decoding$RoleDecoding$viewAssociationDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$Role$ViewAssociation,
	A2($elm$json$Json$Decode$field, 'viewId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'showDirectly', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'permissions', $author$project$Decoding$RoleDecoding$viewPermissionsDecoder));
var $author$project$Decoding$RoleDecoding$roleDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'appBarViewComponentCommandIds',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'settingsViewComponentCommandIds',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'permissions',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'includedRoleIds',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'commandIds',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'views',
						$elm$json$Json$Decode$list($author$project$Decoding$RoleDecoding$viewAssociationDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'defaultViewId',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'isUserRole',
								$elm$json$Json$Decode$bool,
								true,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'description',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'name',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'idAnchor',
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'aproProjectId',
												$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'pos',
													$elm$json$Json$Decode$int,
													0,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'groupId',
														$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'id',
															$elm$json$Json$Decode$int,
															$elm$json$Json$Decode$succeed($author$project$Entity$Role$Role))))))))))))))));
var $author$project$Api$ApiUrls$rolesUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/roles';
var $author$project$Api$EntityApi$getRoles = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$rolesUrl, $author$project$Decoding$RoleDecoding$roleDecoder);
var $author$project$Entity$ViewGroup$ViewGroup = function (id) {
	return function (pos) {
		return function (aproProjectId) {
			return function (idAnchor) {
				return function (name) {
					return function (displayName) {
						return function (description) {
							return function (icon) {
								return function (transparent) {
									return function (parentId) {
										return {hM: aproProjectId, i$: description, i1: displayName, j8: icon, db: id, j9: idAnchor, k_: name, fk: parentId, dM: pos, fJ: transparent};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$ViewGroup$viewGroupDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'parentId',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'transparent',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'icon',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'description',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'displayName',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'name',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'idAnchor',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'aproProjectId',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'pos',
									$elm$json$Json$Decode$int,
									0,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$int,
										$elm$json$Json$Decode$succeed($author$project$Entity$ViewGroup$ViewGroup)))))))))));
var $author$project$Api$ApiUrls$viewGroupsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/viewGroups';
var $author$project$Api$EntityApi$getViewGroups = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$viewGroupsUrl, $author$project$Entity$ViewGroup$viewGroupDecoder);
var $author$project$Entity$View$View = function (id) {
	return function (groupId) {
		return function (pos) {
			return function (aproProjectId) {
				return function (idAnchor) {
					return function (name) {
						return function (displayName) {
							return function (description) {
								return function (icon) {
									return function (scssCode) {
										return function (actionTriggers) {
											return function (staticParams) {
												return function (requiredParameters) {
													return function (componentCommands) {
														return function (defaultComponentAttributes) {
															return function (spec) {
																return {hz: actionTriggers, hM: aproProjectId, iq: componentCommands, iS: defaultComponentAttributes, i$: description, i1: displayName, j_: groupId, j8: icon, db: id, j9: idAnchor, k_: name, dM: pos, lJ: requiredParameters, lX: scssCode, mh: spec, mm: staticParams};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$View$ViewActionTrigger = F3(
	function (trigger, triggerConfig, action) {
		return {fO: action, mG: trigger, mI: triggerConfig};
	});
var $author$project$Decoding$ViewDecoding$viewActionTriggerDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$View$ViewActionTrigger,
	A2($elm$json$Json$Decode$field, 'trigger', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'triggerConfig', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'action', $author$project$Decoding$ActionAndViewComponentDecoding$kappaActionDecoder));
var $author$project$Entity$View$ViewComponentCommandBinding = F2(
	function (commandId, renderLocation) {
		return {f2: commandId, lB: renderLocation};
	});
var $author$project$Decoding$ViewDecoding$viewComponentCommandBindingDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$View$ViewComponentCommandBinding,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'renderLocation', $elm$json$Json$Decode$string));
var $author$project$Entity$View$ViewDefaultComponentAttributes = F2(
	function (componentType, attributes) {
		return {ba: attributes, is: componentType};
	});
var $author$project$Entity$View$DataViewDeleteButtonComponent = 4;
var $author$project$Entity$View$DataViewEditButtonComponent = 1;
var $author$project$Entity$View$DataViewNewRowButtonComponent = 2;
var $author$project$Entity$View$DataViewSaveButtonComponent = 3;
var $author$project$Entity$View$FormViewSubmitButtonComponent = 6;
var $author$project$Entity$View$FormViewToggleEditButtonComponent = 5;
var $author$project$Entity$View$RefreshButtonComponent = 0;
var $author$project$Utils$TypeParsers$viewDefaultComponentTypeParser = function (str) {
	switch (str) {
		case 'COMMON|REFRESH_BUTTON':
			return $elm$core$Result$Ok(0);
		case 'DATA_VIEW|EDIT_BUTTON':
			return $elm$core$Result$Ok(1);
		case 'DATA_VIEW|NEW_ROW_BUTTON':
			return $elm$core$Result$Ok(2);
		case 'DATA_VIEW|SAVE_BUTTON':
			return $elm$core$Result$Ok(3);
		case 'DATA_VIEW|DELETE_BUTTON':
			return $elm$core$Result$Ok(4);
		case 'FORM_VIEW|TOGGLE_EDIT_BUTTON':
			return $elm$core$Result$Ok(5);
		case 'FORM_VIEW|SUBMIT_BUTTON':
			return $elm$core$Result$Ok(6);
		default:
			return $elm$core$Result$Err('unknown value for ViewDefaultComponentIdentifier: ' + str);
	}
};
var $author$project$Decoding$ViewDecoding$viewDefaultComponentAttributeDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'attributes',
	$elm$json$Json$Decode$list($author$project$Decoding$ActionAndViewComponentDecoding$viewComponentAttributeDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'componentType',
		$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$viewDefaultComponentTypeParser),
		$elm$json$Json$Decode$succeed($author$project$Entity$View$ViewDefaultComponentAttributes)));
var $author$project$Entity$View$ViewRequiredParameter = F3(
	function (paramName, placeholder, allowEmptyValue) {
		return {hI: allowEmptyValue, lh: paramName, lm: placeholder};
	});
var $author$project$Decoding$ViewDecoding$viewRequiredParameterDecoder = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Entity$View$ViewRequiredParameter,
	A2($elm$json$Json$Decode$field, 'paramName', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'placeholder', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'allowEmptyValue', $elm$json$Json$Decode$bool));
var $author$project$Entity$View$VCommandLineView = function (a) {
	return {$: 2, a: a};
};
var $author$project$Entity$View$VComponentView = {$: 1};
var $author$project$Entity$View$VContainerView = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$View$VDataView = function (a) {
	return {$: 3, a: a};
};
var $author$project$Entity$View$VFormView = function (a) {
	return {$: 4, a: a};
};
var $author$project$Entity$View$VGeneratorView = function (a) {
	return {$: 6, a: a};
};
var $author$project$Entity$View$VViewWithSingleCommand = function (a) {
	return {$: 5, a: a};
};
var $author$project$Entity$View$CommandLineViewSpecification = function (commandId) {
	return {f2: commandId};
};
var $author$project$Decoding$ViewDecoding$commandLineViewSpecDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'commandId',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
	$elm$json$Json$Decode$succeed($author$project$Entity$View$CommandLineViewSpecification));
var $author$project$Entity$View$ContainerViewSpecification = F4(
	function (childViewIds, mainViewId, isDynamic, layoutConfig) {
		return {ib: childViewIds, ko: isDynamic, kw: layoutConfig, kF: mainViewId};
	});
var $author$project$Decoding$ViewDecoding$containerViewSpecDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Entity$View$ContainerViewSpecification,
	A2(
		$elm$json$Json$Decode$field,
		'childViewIds',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'mainViewId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'isDynamic', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'layoutConfig', $elm$json$Json$Decode$string));
var $author$project$Entity$View$DataViewSpecification = F2(
	function (commandId, formViewId) {
		return {f2: commandId, jO: formViewId};
	});
var $author$project$Decoding$ViewDecoding$idOrStringDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
			A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int),
			A2($elm$json$Json$Decode$map, $elm$core$String$toInt, $elm$json$Json$Decode$string)
		]));
var $author$project$Decoding$ViewDecoding$dataViewSpecDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$View$DataViewSpecification,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'formViewId', $author$project$Decoding$ViewDecoding$idOrStringDecoder));
var $author$project$Entity$View$FormViewSpecification = F2(
	function (commandId, editMode) {
		return {f2: commandId, jf: editMode};
	});
var $author$project$Entity$View$AlwaysEditable = 0;
var $author$project$Entity$View$AlwaysReadOnly = 1;
var $author$project$Entity$View$OptionallyEditable = 2;
var $author$project$Utils$TypeParsers$parseFormViewEditMode = function (str) {
	switch (str) {
		case 'AlwaysEditable':
			return $elm$core$Result$Ok(0);
		case 'AlwaysReadOnly':
			return $elm$core$Result$Ok(1);
		case 'OptionallyEditable':
			return $elm$core$Result$Ok(2);
		default:
			return $elm$core$Result$Err('Invalid FormViewEditMode ' + str);
	}
};
var $author$project$Decoding$ViewDecoding$formViewSpecDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$View$FormViewSpecification,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$field,
		'editMode',
		$author$project$Decoding$DecodingUtils$parserDecoder($author$project$Utils$TypeParsers$parseFormViewEditMode)));
var $author$project$Entity$View$GeneratorViewSpecification = F2(
	function (generatorCommandId, templateViewId) {
		return {jS: generatorCommandId, my: templateViewId};
	});
var $author$project$Decoding$ViewDecoding$generatorViewSpecDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$View$GeneratorViewSpecification,
	A2($elm$json$Json$Decode$field, 'generatorCommandId', $elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(0),
		A2($elm$json$Json$Decode$field, 'templateViewId', $author$project$Decoding$ViewDecoding$idOrStringDecoder)));
var $author$project$Entity$View$ViewWithSingleCommandSpec = F2(
	function (commandId, type_) {
		return {f2: commandId, mM: type_};
	});
var $author$project$Entity$View$CalendarView = 7;
var $author$project$Entity$View$ChartView = 1;
var $author$project$Entity$View$CustomHtmlView = 8;
var $author$project$Entity$View$DiagramView = 3;
var $author$project$Entity$View$DocumentView = 2;
var $author$project$Entity$View$MapView = 5;
var $author$project$Entity$View$ProductionLineView = 0;
var $author$project$Entity$View$QuickSightView = 6;
var $author$project$Entity$View$TreeView = 4;
var $author$project$Decoding$ViewDecoding$viewWithSingleCommandTypeDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'ProductionLineView':
				return $elm$json$Json$Decode$succeed(0);
			case 'ChartView':
				return $elm$json$Json$Decode$succeed(1);
			case 'DocumentView':
				return $elm$json$Json$Decode$succeed(2);
			case 'DiagramView':
				return $elm$json$Json$Decode$succeed(3);
			case 'TreeView':
				return $elm$json$Json$Decode$succeed(4);
			case 'MapView':
				return $elm$json$Json$Decode$succeed(5);
			case 'QuickSightView':
				return $elm$json$Json$Decode$succeed(6);
			case 'CalendarView':
				return $elm$json$Json$Decode$succeed(7);
			case 'CustomHtmlView':
				return $elm$json$Json$Decode$succeed(8);
			default:
				return $elm$json$Json$Decode$fail('Invalid ViewWithSingleCommandType ' + str);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Decoding$ViewDecoding$viewWithSingleCommandSpecDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$View$ViewWithSingleCommandSpec,
	A2($elm$json$Json$Decode$field, 'commandId', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'type', $author$project$Decoding$ViewDecoding$viewWithSingleCommandTypeDecoder));
var $author$project$Decoding$ViewDecoding$viewSpecDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (type_) {
		switch (type_) {
			case 'containerView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$View$VContainerView, $author$project$Decoding$ViewDecoding$containerViewSpecDecoder);
			case 'componentView':
				return $elm$json$Json$Decode$succeed($author$project$Entity$View$VComponentView);
			case 'commandLineView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$View$VCommandLineView, $author$project$Decoding$ViewDecoding$commandLineViewSpecDecoder);
			case 'dataView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$View$VDataView, $author$project$Decoding$ViewDecoding$dataViewSpecDecoder);
			case 'formView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$View$VFormView, $author$project$Decoding$ViewDecoding$formViewSpecDecoder);
			case 'generatorView':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$View$VGeneratorView, $author$project$Decoding$ViewDecoding$generatorViewSpecDecoder);
			case 'viewWithSingleCommand':
				return A2($elm$json$Json$Decode$map, $author$project$Entity$View$VViewWithSingleCommand, $author$project$Decoding$ViewDecoding$viewWithSingleCommandSpecDecoder);
			default:
				return $elm$json$Json$Decode$fail('Invalid view_type ' + type_);
		}
	},
	A2($elm$json$Json$Decode$field, 'view_type', $elm$json$Json$Decode$string));
var $author$project$Decoding$ViewDecoding$viewDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'specification',
	$author$project$Decoding$ViewDecoding$viewSpecDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'defaultComponentAttributes',
		$elm$json$Json$Decode$list($author$project$Decoding$ViewDecoding$viewDefaultComponentAttributeDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'componentCommands',
			$elm$json$Json$Decode$list($author$project$Decoding$ViewDecoding$viewComponentCommandBindingDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'requiredParameters',
				$elm$json$Json$Decode$list($author$project$Decoding$ViewDecoding$viewRequiredParameterDecoder),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'staticParams',
					$elm$json$Json$Decode$list($author$project$Decoding$CommandParameterDecoding$commandParameterDecoder),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'actionTriggers',
						$elm$json$Json$Decode$list($author$project$Decoding$ViewDecoding$viewActionTriggerDecoder),
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'scssCode',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'icon',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'description',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'displayName',
										$elm$json$Json$Decode$string,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'name',
											$elm$json$Json$Decode$string,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'idAnchor',
												$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'aproProjectId',
													$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'pos',
														$elm$json$Json$Decode$int,
														0,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'groupId',
															$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'id',
																$author$project$Decoding$DecodingUtils$viewIdDecoder,
																$elm$json$Json$Decode$succeed($author$project$Entity$View$View)))))))))))))))));
var $author$project$Api$ApiUrls$viewsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/views';
var $author$project$Api$EntityApi$getViews = A2($author$project$Api$EntityApi$getEntityDictData, $author$project$Api$ApiUrls$viewsUrl, $author$project$Decoding$ViewDecoding$viewDecoder);
var $author$project$Pages$PageUpdateUtils$justAppMsg = F2(
	function (model, appMsg) {
		return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, appMsg);
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Ports$selectedBranchChanged = _Platform_outgoingPort('selectedBranchChanged', $elm$json$Json$Encode$string);
var $author$project$Pages$PageUpdateUtils$sequence = F3(
	function (update, msgs, init) {
		var foldUpdate = $author$project$Pages$PageUpdateUtils$andThen(update);
		return A3($elm$core$List$foldl, foldUpdate, init, msgs);
	});
var $author$project$Lib$RemoteData$toLoading = function (remoteData) {
	switch (remoteData.$) {
		case 1:
			var oldData = remoteData.a;
			return $author$project$Lib$RemoteData$Loading(oldData);
		case 3:
			var data = remoteData.a;
			return $author$project$Lib$RemoteData$Loading(data);
		case 0:
			return $author$project$Lib$RemoteData$EmptyLoading;
		default:
			var e = remoteData.a;
			return $author$project$Lib$RemoteData$Failure(e);
	}
};
var $author$project$Pages$Main$CommandsView$AddNewCommandModal = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$CommandsView$BeginDeleteCommand = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$CommandsView$BeginDeleteGroup = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$CommandsView$BeginEditCommand = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$CommandsView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$CommandsView$BeginSaveCommand = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$CommandsView$BeginSaveGroup = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$CommandsView$GotDeleteCommand = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$CommandsView$GotDeleteGroup = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$CommandsView$GotPostCommand = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Main$CommandsView$GotPostGroup = function (a) {
	return {$: 18, a: a};
};
var $author$project$Lib$Form$LoadStatus$InProgress = {$: 1};
var $author$project$Pages$Main$CommandsView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$CommandsView$NoOp = {$: 0};
var $author$project$Pages$Main$OutMsg$NoOp = {$: 0};
var $author$project$Entity$Common$TEntity = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$Common$TGroup = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$OutMsg$UpdateRepository = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$OutMsg$BatchOutMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$SubViewUpdateUtils$andThen = F3(
	function (update, msg, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		var outMsg = _v0.c;
		var _v1 = A2(update, msg, model);
		var model_ = _v1.a;
		var cmd_ = _v1.b;
		var outMsg_ = _v1.c;
		return _Utils_Tuple3(
			model_,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[cmd, cmd_])),
			$author$project$Pages$Main$OutMsg$BatchOutMsg(
				_List_fromArray(
					[outMsg, outMsg_])));
	});
var $author$project$Forms$CommandForms$CommandForm$CalendarViewCommandForm = function (a) {
	return {$: 12, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$ChartCommandForm = function (a) {
	return {$: 9, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$CronCommandForm = function (a) {
	return {$: 14, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$DataViewCommandForm = function (a) {
	return {$: 1, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$DiagramCommandForm = function (a) {
	return {$: 10, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$DownloadFileCommandForm = function (a) {
	return {$: 6, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$FormCommandForm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$MailCommandForm = function (a) {
	return {$: 4, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$NotificationCommandForm = function (a) {
	return {$: 5, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$ProductionLineCommandForm = function (a) {
	return {$: 3, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$QuickSightCommandForm = function (a) {
	return {$: 13, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$ReportCommandForm = function (a) {
	return {$: 8, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$SingleClauseCommandForm = function (a) {
	return {$: 0, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$TreeViewCommandForm = function (a) {
	return {$: 11, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$UploadFilesCommandForm = function (a) {
	return {$: 7, a: a};
};
var $author$project$Forms$CommandForms$CommandForm$ViewComponentsCommandForm = function (a) {
	return {$: 15, a: a};
};
var $author$project$Forms$CommandForms$CalendarViewCommandForm$CalendarViewCommandFormValues = F8(
	function (commonPropsValues, eventsClauseValues, resourcesClauseValues, eventEditedActionValues, eventClickActionValues, eventContextMenuValues, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, eU: eventClickActionValues, eV: eventContextMenuValues, eW: eventEditedActionValues, eX: eventsClauseValues, ft: resourcesClauseValues};
	});
var $author$project$Forms$ClauseForms$ClauseFormValues = F8(
	function (type_, directValueValues, databaseValues, httpValues, pythonValues, commandValues, kotlinValues, sequenceValues) {
		return {cE: commandValues, cO: databaseValues, cR: directValueValues, da: httpValues, dk: kotlinValues, dZ: pythonValues, d8: sequenceValues, mM: type_};
	});
var $author$project$Forms$ClauseForms$WClauseFormValues = $elm$core$Basics$identity;
var $author$project$Utils$StringUtils$fromBool = function (bool) {
	return bool ? 'true' : 'false';
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $author$project$Serializable$toString = function (serializable) {
	switch (serializable.$) {
		case 0:
			var string_ = serializable.a;
			return string_;
		case 1:
			var int_ = serializable.a;
			return $elm$core$String$fromInt(int_);
		case 2:
			var float_ = serializable.a;
			return $elm$core$String$fromFloat(float_);
		default:
			var bool_ = serializable.a;
			return $author$project$Utils$StringUtils$fromBool(bool_);
	}
};
var $author$project$Forms$CommandParameterForm$createCommandParameterFormValues = function (commandParameter) {
	return {
		kt: commandParameter.kt,
		mY: A2($elm$core$Maybe$map, $author$project$Serializable$toString, commandParameter.mY)
	};
};
var $author$project$Forms$CommandCallDefinitionForm$createCommandCallDefinitionFormValues = function (_v0) {
	var commandId = _v0.f2;
	var params = _v0.lk;
	return {
		f2: commandId,
		lk: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, params)
	};
};
var $author$project$Forms$ClauseForms$createCommandClauseFormValues = function (_v0) {
	var commandCallDefinition = _v0.il;
	var asyncExecution = _v0.hP;
	return {
		hP: asyncExecution,
		il: $author$project$Forms$CommandCallDefinitionForm$createCommandCallDefinitionFormValues(commandCallDefinition)
	};
};
var $author$project$Forms$CommonForms$createOptionalFormValues = F2(
	function (_default, maybeValues) {
		if (!maybeValues.$) {
			var innerValues = maybeValues.a;
			return {bD: innerValues, bF: true};
		} else {
			return {bD: _default, bF: false};
		}
	});
var $author$project$Utils$ToStrings$databaseClauseInterpretationToString = function (interpretation) {
	switch (interpretation) {
		case 0:
			return 'Scalar';
		case 1:
			return 'HorizontalList';
		case 2:
			return 'VerticalList';
		case 3:
			return 'HorizontalMap';
		case 4:
			return 'VerticalMap';
		default:
			return 'Table';
	}
};
var $author$project$Utils$ToStrings$databaseClauseResultSourceToString = function (source) {
	switch (source) {
		case 0:
			return 'LastSelect';
		case 1:
			return 'GenericOutParameter';
		default:
			return 'OracleRefCursor';
	}
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$json$Json$Encode$dict = F3(
	function (toKey, toValue, dictionary) {
		return _Json_wrap(
			A3(
				$elm$core$Dict$foldl,
				F3(
					function (key, value, obj) {
						return A3(
							_Json_addField,
							toKey(key),
							toValue(value),
							obj);
					}),
				_Json_emptyObject(0),
				dictionary));
	});
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $author$project$Encoding$ClauseEncoding$encodeDatabaseClauseMetadata = function (_v0) {
	var columns = _v0.ii;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'columns',
				A3(
					$elm$json$Json$Encode$dict,
					$elm$core$Basics$identity,
					function (c) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'convertTimeZone',
									$elm$json$Json$Encode$bool(c.iE))
								]));
					},
					columns))
			]));
};
var $author$project$Forms$ClauseForms$createDatabaseClauseFormValues = function (data) {
	return {
		ij: data.ij,
		gi: A2($author$project$Forms$CommonForms$createOptionalFormValues, $author$project$Entity$Common$nonExistentId, data.gi),
		kn: $author$project$Utils$ToStrings$databaseClauseInterpretationToString(data.kn),
		kQ: A2(
			$elm$json$Json$Encode$encode,
			2,
			$author$project$Encoding$ClauseEncoding$encodeDatabaseClauseMetadata(data.kQ)),
		lN: $author$project$Utils$ToStrings$databaseClauseResultSourceToString(data.lN)
	};
};
var $author$project$Utils$ToStrings$httpMethodToString = function (httpMethod) {
	switch (httpMethod) {
		case 0:
			return 'GET';
		case 1:
			return 'POST';
		case 2:
			return 'PUT';
		default:
			return 'DELETE';
	}
};
var $author$project$Forms$ClauseForms$createHttpClauseFormValues = function (data) {
	return {
		h$: A2($elm$core$Maybe$withDefault, '', data.h$),
		gi: A2($author$project$Forms$CommonForms$createOptionalFormValues, $author$project$Entity$Common$nonExistentId, data.gi),
		kR: $author$project$Utils$ToStrings$httpMethodToString(data.kR),
		ll: data.ll
	};
};
var $author$project$Entity$CommandCallDefinition$createNewCommandCallDefinition = A2($author$project$Entity$CommandCallDefinition$CommandCallDefinition, $author$project$Entity$Common$nonExistentId, _List_Nil);
var $author$project$Entity$Clauses$createNewCommandClauseData = {hP: false, il: $author$project$Entity$CommandCallDefinition$createNewCommandCallDefinition};
var $author$project$Entity$Clauses$createNewDatabaseClauseData = {
	ij: '',
	gi: $elm$core$Maybe$Nothing,
	kn: 0,
	kQ: {ii: $elm$core$Dict$empty},
	lN: 0
};
var $author$project$Entity$Clauses$createNewDirectValueClauseData = {mY: ''};
var $author$project$Entity$Clauses$createNewHttpClauseData = {h$: $elm$core$Maybe$Nothing, gi: $elm$core$Maybe$Nothing, kR: 0, ll: ''};
var $author$project$Entity$Clauses$createNewKotlinClauseData = {ic: '', jQ: '', le: ''};
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm_community$list_extra$List$Extra$init = function (items) {
	if (!items.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var nonEmptyList = items;
		return A2(
			$elm$core$Maybe$map,
			$elm$core$List$reverse,
			$elm$core$List$tail(
				$elm$core$List$reverse(nonEmptyList)));
	}
};
var $author$project$Utils$ListUtils$initOrEmpty = A2(
	$elm$core$Basics$composeR,
	$elm_community$list_extra$List$Extra$init,
	$elm$core$Maybe$withDefault(_List_Nil));
var $elm$core$String$lines = _String_lines;
var $author$project$Utils$ListUtils$tailOrEmpty = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$tail,
	$elm$core$Maybe$withDefault(_List_Nil));
var $author$project$Entity$Clauses$defaultPythonScript = A2(
	$elm$core$String$join,
	'\n',
	$author$project$Utils$ListUtils$initOrEmpty(
		$author$project$Utils$ListUtils$tailOrEmpty(
			$elm$core$String$lines('\ndef run(params, api_client):\n    return ""\n'))));
var $author$project$Entity$Clauses$createNewPythonClauseData = {lz: _List_Nil, lW: $author$project$Entity$Clauses$defaultPythonScript, mf: $elm$core$Maybe$Nothing};
var $author$project$Entity$Clauses$createNewSequenceClauseData = {mN: _List_Nil};
var $author$project$Forms$ClauseForms$createClauseValues = function (clause) {
	var defaultValues = A8(
		$author$project$Forms$ClauseForms$ClauseFormValues,
		'',
		$author$project$Entity$Clauses$createNewDirectValueClauseData,
		$author$project$Forms$ClauseForms$createDatabaseClauseFormValues($author$project$Entity$Clauses$createNewDatabaseClauseData),
		$author$project$Forms$ClauseForms$createHttpClauseFormValues($author$project$Entity$Clauses$createNewHttpClauseData),
		$author$project$Entity$Clauses$createNewPythonClauseData,
		$author$project$Forms$ClauseForms$createCommandClauseFormValues($author$project$Entity$Clauses$createNewCommandClauseData),
		$author$project$Entity$Clauses$createNewKotlinClauseData,
		$author$project$Forms$ClauseForms$createSequenceClauseFormValues($author$project$Entity$Clauses$createNewSequenceClauseData));
	switch (clause.$) {
		case 0:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{cR: data, mM: 'directValue'});
		case 1:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{
					cO: $author$project$Forms$ClauseForms$createDatabaseClauseFormValues(data),
					mM: 'database'
				});
		case 2:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{
					da: $author$project$Forms$ClauseForms$createHttpClauseFormValues(data),
					mM: 'http'
				});
		case 3:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{dZ: data, mM: 'python'});
		case 4:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{
					cE: $author$project$Forms$ClauseForms$createCommandClauseFormValues(data),
					mM: 'command'
				});
		case 5:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{dk: data, mM: 'kotlin'});
		default:
			var data = clause.a;
			return _Utils_update(
				defaultValues,
				{
					d8: $author$project$Forms$ClauseForms$createSequenceClauseFormValues(data),
					mM: 'sequence'
				});
	}
};
var $author$project$Forms$ClauseForms$createSequenceClauseFormValues = function (clause) {
	var mapUnit = function (unit) {
		return {
			be: $author$project$Forms$ClauseForms$createClauseValues(unit.eH),
			lM: unit.lM,
			l2: unit.l2
		};
	};
	return {
		mN: A2($elm$core$List$map, mapUnit, clause.mN)
	};
};
var $author$project$Forms$CommandForms$CommandCommonForms$CommandCommonPropsValues = function (key) {
	return function (description) {
		return function (connectionValues) {
			return function (id) {
				return function (pos) {
					return function (aproProjectId) {
						return function (idAnchor) {
							return function (groupId) {
								return function (extraInputFieldDefinitionValues) {
									return function (parameterMappings) {
										return function (baseClauses) {
											return function (timeoutSeconds) {
												return function (metadata) {
													return {hM: aproProjectId, hZ: baseClauses, eL: connectionValues, i$: description, eZ: extraInputFieldDefinitionValues, j_: groupId, db: id, j9: idAnchor, kt: key, kQ: metadata, li: parameterMappings, dM: pos, mB: timeoutSeconds};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Entity$Command$commandCanHaveAwsConnection = function (command) {
	return false;
};
var $author$project$Entity$Command$commandCanHaveFileServerConnection = function (command) {
	var _v0 = command.mh;
	switch (_v0.$) {
		case 6:
			return true;
		case 7:
			return true;
		default:
			return false;
	}
};
var $author$project$Entity$Command$commandCanHaveMailServerConnection = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 4) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Forms$ConnectionForms$createConnectionSetFormValues = function (connectionSet) {
	var connectionIdOrEmpty = function (getFromConnectionSet) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Entity$Common$nonExistentId,
			getFromConnectionSet(connectionSet));
	};
	return {
		hW: connectionIdOrEmpty(
			function ($) {
				return $.hW;
			}),
		iQ: connectionIdOrEmpty(
			function ($) {
				return $.iQ;
			}),
		jI: connectionIdOrEmpty(
			function ($) {
				return $.jI;
			}),
		j7: connectionIdOrEmpty(
			function ($) {
				return $.j7;
			}),
		kE: connectionIdOrEmpty(
			function ($) {
				return $.kE;
			})
	};
};
var $author$project$Forms$InputFieldForms$InputFieldDefinitionFormValues = F5(
	function (identifier, label, defaultValue, required, typeDefinitionValues) {
		return {iV: defaultValue, kb: identifier, kv: label, lI: required, eo: typeDefinitionValues};
	});
var $author$project$Entity$Clauses$createNewClause = $author$project$Entity$Clauses$DirectValueClause($author$project$Entity$Clauses$createNewDirectValueClauseData);
var $author$project$Forms$InputFieldForms$fieldTypeDefinitionToString = function (definition) {
	switch (definition.$) {
		case 0:
			var fieldType = definition.a.jF;
			switch (fieldType) {
				case 0:
					return 'constant';
				case 1:
					return 'text';
				case 2:
					return 'textArea';
				case 3:
					return 'integer';
				case 4:
					return 'float';
				case 5:
					return 'date';
				case 6:
					return 'time';
				case 7:
					return 'dateTime';
				case 8:
					return 'checkbox';
				default:
					return 'barcode';
			}
		case 1:
			return 'selection';
		default:
			return 'file';
	}
};
var $author$project$Forms$InputFieldForms$createFieldTypeDefinitionFormValues = function (definition) {
	var ret = {
		hE: false,
		aI: false,
		aZ: false,
		a_: false,
		a$: $author$project$Forms$ClauseForms$createClauseValues($author$project$Entity$Clauses$createNewClause),
		a0: 'Valitse',
		a1: true,
		a4: $author$project$Forms$InputFieldForms$fieldTypeDefinitionToString(definition)
	};
	switch (definition.$) {
		case 1:
			var isLazy = definition.a.kq;
			var optionsClause = definition.a.lb;
			var nullValueEnabled = definition.a.k1;
			var placeholder = definition.a.lm;
			var selectFirstValueByDefault = definition.a.lZ;
			return _Utils_update(
				ret,
				{
					aZ: isLazy,
					a_: nullValueEnabled,
					a$: $author$project$Forms$ClauseForms$createClauseValues(optionsClause),
					a0: placeholder,
					a1: selectFirstValueByDefault
				});
		case 0:
			var addOffsetToTemporalTypes = definition.a.hE;
			return _Utils_update(
				ret,
				{hE: addOffsetToTemporalTypes});
		default:
			var allowMultipleFiles = definition.a.hJ;
			return _Utils_update(
				ret,
				{aI: allowMultipleFiles});
	}
};
var $author$project$Forms$InputFieldForms$createFieldDefinitionFormValues = function (definition) {
	return A5(
		$author$project$Forms$InputFieldForms$InputFieldDefinitionFormValues,
		definition.kb,
		definition.kv,
		A2($elm$core$Maybe$map, $author$project$Serializable$toString, definition.iV),
		definition.lI,
		$author$project$Forms$InputFieldForms$createFieldTypeDefinitionFormValues(definition.mL));
};
var $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues = function (parameterClause) {
	if (!parameterClause.$) {
		var clause = parameterClause.a.eH;
		return {
			be: $author$project$Forms$ClauseForms$createClauseValues(clause),
			lj: '',
			a4: 'separated'
		};
	} else {
		var clause = parameterClause.a.eH;
		var parameterName = parameterClause.a.lj;
		return {
			be: $author$project$Forms$ClauseForms$createClauseValues(clause),
			lj: parameterName,
			a4: 'named'
		};
	}
};
var $author$project$Entity$Command$extraParamInputFieldDefinitionsAreRelevant = function (command) {
	var _v0 = command.mh;
	switch (_v0.$) {
		case 0:
			return true;
		case 4:
			return true;
		case 6:
			return true;
		case 7:
			return true;
		case 8:
			return true;
		default:
			return false;
	}
};
var $author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues = function (command) {
	var metadata = {
		g5: $author$project$Entity$Command$commandCanHaveAwsConnection(command),
		g6: $author$project$Entity$Command$extraParamInputFieldDefinitionsAreRelevant(command),
		g7: $author$project$Entity$Command$commandCanHaveFileServerConnection(command),
		g8: $author$project$Entity$Command$commandCanHaveMailServerConnection(command)
	};
	return $author$project$Forms$CommandForms$CommandCommonForms$CommandCommonPropsValues(command.kt)(command.i$)(
		$author$project$Forms$ConnectionForms$createConnectionSetFormValues(command.iy))(command.db)(command.dM)(command.hM)(command.j9)(command.j_)(
		A2($elm$core$List$map, $author$project$Forms$InputFieldForms$createFieldDefinitionFormValues, command.jC))(
		A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, command.li))(
		A2($elm$core$List$map, $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues, command.hZ))(
		$elm$core$String$fromInt(command.mB))(metadata);
};
var $author$project$Forms$ClauseForms$createOptionalClauseValues = function (maybeClause) {
	return A2(
		$author$project$Forms$CommonForms$createOptionalFormValues,
		$author$project$Forms$ClauseForms$createClauseValues(
			$author$project$Entity$Clauses$DirectValueClause($author$project$Entity$Clauses$createNewDirectValueClauseData)),
		A2($elm$core$Maybe$map, $author$project$Forms$ClauseForms$createClauseValues, maybeClause));
};
var $author$project$Forms$ViewComponentAndActionForms$WrappedComponentValues = $elm$core$Basics$identity;
var $author$project$Forms$ViewComponentAndActionForms$WrappedKappaActionFormValues = $elm$core$Basics$identity;
var $author$project$Forms$ViewComponentAndActionForms$WrappedViewComponentListValues = $elm$core$Basics$identity;
var $author$project$Utils$ToStrings$predicateOperatorToString = function (operator) {
	switch (operator) {
		case 0:
			return 'Always';
		case 1:
			return 'Never';
		case 2:
			return 'Exists';
		case 3:
			return 'NotExists';
		case 4:
			return 'Equals';
		case 5:
			return 'NotEquals';
		case 6:
			return 'IsTruthy';
		case 7:
			return 'IsFalsy';
		case 8:
			return 'NumberLessThan';
		case 9:
			return 'NumberGreaterThan';
		case 10:
			return 'NumberLessThanOrEquals';
		case 11:
			return 'NumberGreaterThanOrEquals';
		case 12:
			return 'StringContains';
		case 13:
			return 'StringStartsWith';
		case 14:
			return 'StringEndWith';
		case 15:
			return 'StringRegex';
		case 16:
			return 'StringIsEmpty';
		case 17:
			return 'StringIsBlank';
		case 18:
			return 'StringIsNotEmpty';
		case 19:
			return 'StringIsNotBlank';
		case 20:
			return 'ListContains';
		default:
			return 'ListNotContains';
	}
};
var $author$project$Forms$PredicateForm$createPredicateFormValues = function (predicate) {
	var values = {
		bT: {
			kA: _List_Nil,
			k9: $elm$core$Maybe$Nothing,
			la: $author$project$Utils$ToStrings$predicateOperatorToString(4),
			lj: ''
		},
		bY: {kL: '10000', kV: '0'},
		a4: ''
	};
	switch (predicate.$) {
		case 0:
			return _Utils_update(
				values,
				{a4: 'always'});
		case 1:
			var minWidth = predicate.a.kV;
			var maxWidth = predicate.a.kL;
			return _Utils_update(
				values,
				{
					bY: {
						kL: $elm$core$String$fromInt(maxWidth),
						kV: $elm$core$String$fromInt(minWidth)
					},
					a4: 'screenSize'
				});
		default:
			var parameterName = predicate.a.lj;
			var operator = predicate.a.la;
			var operand = predicate.a.k9;
			var listOperands = predicate.a.kA;
			return _Utils_update(
				values,
				{
					bT: {
						kA: A2(
							$elm$core$List$map,
							function (listOperand) {
								return {
									k9: A2($elm$core$Maybe$map, $author$project$Serializable$toString, listOperand)
								};
							},
							listOperands),
						k9: A2($elm$core$Maybe$map, $author$project$Serializable$toString, operand),
						la: $author$project$Utils$ToStrings$predicateOperatorToString(operator),
						lj: parameterName
					},
					a4: 'parameterValue'
				});
	}
};
var $author$project$Forms$ViewComponentAndActionForms$createCommandExecutionActionValues = function (action) {
	return {
		ik: $author$project$Forms$CommandCallDefinitionForm$createCommandCallDefinitionFormValues(action.ik),
		i2: action.i2,
		dJ: A2($elm$core$List$map, $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues, action.dJ),
		m: $author$project$Forms$PredicateForm$createPredicateFormValues(action.as),
		lH: action.lH
	};
};
var $author$project$Entity$ActionAndViewComponent$createNewCommandExecutionAction = {ik: $author$project$Entity$CommandCallDefinition$createNewCommandCallDefinition, i2: false, dJ: _List_Nil, as: $author$project$Entity$Predicate$AlwaysPredicate, lH: false};
var $author$project$Entity$ActionAndViewComponent$createNewKappaAction = $author$project$Entity$ActionAndViewComponent$CommandExecutionAction($author$project$Entity$ActionAndViewComponent$createNewCommandExecutionAction);
var $author$project$Entity$ActionAndViewComponent$createNewButtonViewComponent = {fO: $author$project$Entity$ActionAndViewComponent$createNewKappaAction, ba: _List_Nil, cG: _List_Nil, bm: '', cV: $elm$core$Maybe$Nothing, j8: '', kv: ''};
var $author$project$Entity$ActionAndViewComponent$createNewDialogAction = {it: _List_Nil, iz: '', iA: $elm$core$Maybe$Nothing, bm: '', kY: 'SM', dJ: _List_Nil, lk: _List_Nil, as: $author$project$Entity$Predicate$AlwaysPredicate, mC: ''};
var $author$project$Entity$InputFieldDefinition$createNewInputFieldDefinition = {
	iV: $elm$core$Maybe$Nothing,
	kb: '',
	kv: '',
	lI: false,
	mL: $author$project$Entity$InputFieldDefinition$SimpleField(
		{hE: false, jF: 1})
};
var $author$project$Entity$ActionAndViewComponent$createNewInputViewComponent = {ba: _List_Nil, hT: false, cG: _List_Nil, bm: '', cV: $elm$core$Maybe$Nothing, jE: $author$project$Entity$InputFieldDefinition$createNewInputFieldDefinition, mq: $author$project$Entity$ActionAndViewComponent$NoneSubmitAction};
var $author$project$Entity$ActionAndViewComponent$createNewOpenUrlAction = {lk: _List_Nil, as: $author$project$Entity$Predicate$AlwaysPredicate, mj: '', mu: '', mO: ''};
var $author$project$Entity$ActionAndViewComponent$createNewScriptAction = {dJ: _List_Nil, lk: _List_Nil, as: $author$project$Entity$Predicate$AlwaysPredicate, lW: ''};
var $author$project$Entity$ActionAndViewComponent$createNewSequenceAction = {hA: _List_Nil, as: $author$project$Entity$Predicate$AlwaysPredicate};
var $author$project$Entity$ActionAndViewComponent$createNewTextViewComponent = {ba: _List_Nil, f$: $elm$core$Maybe$Nothing, cG: _List_Nil, iC: $author$project$Entity$Clauses$createNewClause, f4: _List_Nil, bm: '', ga: $elm$core$Maybe$Nothing, i4: false, cV: $elm$core$Maybe$Nothing, mQ: false, mR: false};
var $author$project$Entity$ActionAndViewComponent$createNewViewAction = {hy: '', dJ: _List_Nil, lk: _List_Nil, as: $author$project$Entity$Predicate$AlwaysPredicate, ms: $author$project$Entity$ActionAndViewComponent$SelfTarget};
var $author$project$Forms$ViewComponentAndActionForms$createOpenUrlActionValues = function (action) {
	return {
		lk: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, action.lk),
		m: $author$project$Forms$PredicateForm$createPredicateFormValues(action.as),
		mj: action.mj,
		mu: action.mu,
		mO: action.mO
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createScriptActionValues = function (action) {
	return {
		dJ: A2($elm$core$List$map, $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues, action.dJ),
		lk: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, action.lk),
		m: $author$project$Forms$PredicateForm$createPredicateFormValues(action.as),
		lW: action.lW
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createViewActionValues = function (action) {
	var target = function () {
		var _v0 = action.ms;
		switch (_v0.$) {
			case 0:
				return 'global';
			case 1:
				return 'self';
			case 2:
				return 'sourceView';
			case 3:
				return 'allSiblingsAndSelf';
			default:
				var viewId = _v0.a;
				return $elm$core$String$fromInt(viewId);
		}
	}();
	return {
		hy: action.hy,
		dJ: A2($elm$core$List$map, $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues, action.dJ),
		lk: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, action.lk),
		m: $author$project$Forms$PredicateForm$createPredicateFormValues(action.as),
		ms: target
	};
};
var $elm$core$String$toLower = _String_toLower;
var $author$project$Forms$ViewComponentAndActionForms$customAttributeValueToString = A2(
	$elm$core$Basics$composeR,
	$author$project$Serializable$toString,
	function (str) {
		return A2(
			$elm$core$List$member,
			str,
			_List_fromArray(
				['TRUE', 'FALSE'])) ? $elm$core$String$toLower(str) : str;
	});
var $author$project$Forms$ViewComponentAndActionForms$createViewComponentAttributeValues = function (attr) {
	if (!attr.$) {
		var customAttr = attr.a;
		return {
			aF: customAttr.hQ,
			ae: $author$project$Forms$ViewComponentAndActionForms$customAttributeValueToString(customAttr.hR),
			bn: $author$project$Forms$PredicateForm$createPredicateFormValues(customAttr.as),
			bU: '',
			a4: 'custom'
		};
	} else {
		var scenario = attr.a;
		return {
			aF: '',
			ae: '',
			bn: $author$project$Forms$PredicateForm$createPredicateFormValues($author$project$Entity$Predicate$AlwaysPredicate),
			bU: scenario,
			a4: 'predefined'
		};
	}
};
var $author$project$Entity$ActionAndViewComponent$getAttributes = function (def) {
	switch (def.$) {
		case 0:
			var def_ = def.a;
			return def_.ba;
		case 1:
			var def_ = def.a;
			return def_.ba;
		case 2:
			var def_ = def.a;
			return def_.ba;
		case 3:
			var def_ = def.a;
			return def_.ba;
		default:
			var def_ = def.a;
			return def_.ba;
	}
};
var $author$project$Entity$ActionAndViewComponent$getComponentParameterClauses = function (def) {
	switch (def.$) {
		case 0:
			var def_ = def.a;
			return def_.cG;
		case 1:
			var def_ = def.a;
			return def_.cG;
		case 2:
			var def_ = def.a;
			return def_.cG;
		case 3:
			var def_ = def.a;
			return def_.cG;
		default:
			return _List_Nil;
	}
};
var $author$project$Forms$CommonForms$mapToOptionalFormValues = F3(
	function (tagger, _default, maybeValue) {
		return A2(
			$author$project$Forms$CommonForms$createOptionalFormValues,
			tagger(_default),
			A2($elm$core$Maybe$map, tagger, maybeValue));
	});
var $author$project$Forms$ViewComponentAndActionForms$createButtonComponentFormValues = function (component) {
	return {
		fO: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(component.fO),
		bm: component.bm,
		D: $author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues()(component.cV),
		j8: component.j8,
		kv: component.kv
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createContainerComponentFormValues = function (component) {
	return {
		cz: A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues, component.ia),
		f$: $author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues()(component.f$),
		f4: A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues, component.f4),
		bm: component.bm,
		ga: $author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues()(component.ga),
		D: $author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues()(component.cV)
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createDialogActionValues = function (action) {
	return {
		cH: A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues, action.it),
		iz: action.iz,
		iA: A2($elm$core$Maybe$withDefault, '', action.iA),
		bm: action.bm,
		kY: action.kY,
		dJ: A2($elm$core$List$map, $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues, action.dJ),
		lk: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, action.lk),
		m: $author$project$Forms$PredicateForm$createPredicateFormValues(action.as),
		mC: action.mC
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createGeneratorComponentFormValues = function (component) {
	return {
		je: A2(
			$elm$core$List$map,
			function (_v3) {
				var key = _v3.a;
				var template = _v3.b;
				return {
					kt: key,
					aw: $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues(template)
				};
			},
			component.je),
		c0: $author$project$Forms$ClauseForms$createClauseValues(component.jR),
		aw: $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues(component.mv)
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createInputFieldComponentFormValues = function (component) {
	var submitActionValues = function () {
		var _v2 = component.mq;
		switch (_v2.$) {
			case 0:
				return {
					ad: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues($author$project$Entity$ActionAndViewComponent$createNewKappaAction),
					a4: 'none'
				};
			case 1:
				return {
					ad: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues($author$project$Entity$ActionAndViewComponent$createNewKappaAction),
					a4: 'viewDefault'
				};
			default:
				var action = _v2.a;
				return {
					ad: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(action),
					a4: 'custom'
				};
		}
	}();
	return {
		hT: component.hT,
		bm: component.bm,
		D: $author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues()(component.cV),
		cY: $author$project$Forms$InputFieldForms$createFieldDefinitionFormValues(component.jE),
		eg: submitActionValues
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues = function (action) {
	var values = {
		bg: $author$project$Forms$ViewComponentAndActionForms$createCommandExecutionActionValues($author$project$Entity$ActionAndViewComponent$createNewCommandExecutionAction),
		bp: $author$project$Forms$ViewComponentAndActionForms$createDialogActionValues($author$project$Entity$ActionAndViewComponent$createNewDialogAction),
		bQ: $author$project$Forms$ViewComponentAndActionForms$createOpenUrlActionValues($author$project$Entity$ActionAndViewComponent$createNewOpenUrlAction),
		bZ: $author$project$Forms$ViewComponentAndActionForms$createScriptActionValues($author$project$Entity$ActionAndViewComponent$createNewScriptAction),
		b_: $author$project$Forms$ViewComponentAndActionForms$createSequenceActionValues($author$project$Entity$ActionAndViewComponent$createNewSequenceAction),
		a4: '',
		b8: $author$project$Forms$ViewComponentAndActionForms$createViewActionValues($author$project$Entity$ActionAndViewComponent$createNewViewAction)
	};
	switch (action.$) {
		case 0:
			var action_ = action.a;
			return _Utils_update(
				values,
				{
					bg: $author$project$Forms$ViewComponentAndActionForms$createCommandExecutionActionValues(action_),
					a4: 'commandExecution'
				});
		case 1:
			var action_ = action.a;
			return _Utils_update(
				values,
				{
					a4: 'viewAction',
					b8: $author$project$Forms$ViewComponentAndActionForms$createViewActionValues(action_)
				});
		case 2:
			var action_ = action.a;
			return _Utils_update(
				values,
				{
					bQ: $author$project$Forms$ViewComponentAndActionForms$createOpenUrlActionValues(action_),
					a4: 'openUrl'
				});
		case 3:
			var action_ = action.a;
			return _Utils_update(
				values,
				{
					bp: $author$project$Forms$ViewComponentAndActionForms$createDialogActionValues(action_),
					a4: 'dialogAction'
				});
		case 4:
			var action_ = action.a;
			return _Utils_update(
				values,
				{
					bZ: $author$project$Forms$ViewComponentAndActionForms$createScriptActionValues(action_),
					a4: 'scriptAction'
				});
		default:
			var action_ = action.a;
			return _Utils_update(
				values,
				{
					b_: $author$project$Forms$ViewComponentAndActionForms$createSequenceActionValues(action_),
					a4: 'sequenceAction'
				});
	}
};
var $author$project$Forms$ViewComponentAndActionForms$createSequenceActionValues = function (action) {
	var mapChild = function (childAction) {
		return $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(childAction);
	};
	return {
		hA: A2($elm$core$List$map, mapChild, action.hA),
		m: $author$project$Forms$PredicateForm$createPredicateFormValues(action.as)
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createTextComponentFormValues = function (component) {
	return {
		f$: $author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues()(component.f$),
		cK: $author$project$Forms$ClauseForms$createClauseValues(component.iC),
		f4: A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues, component.f4),
		bm: component.bm,
		ga: $author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues()(component.ga),
		i4: component.i4,
		D: $author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues()(component.cV),
		mQ: component.mQ,
		mR: component.mR
	};
};
var $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues = function (def) {
	var values = {
		ba: A2(
			$elm$core$List$map,
			$author$project$Forms$ViewComponentAndActionForms$createViewComponentAttributeValues,
			$author$project$Entity$ActionAndViewComponent$getAttributes(def)),
		bd: $author$project$Forms$ViewComponentAndActionForms$createButtonComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewButtonViewComponent),
		cG: A2(
			$elm$core$List$map,
			$author$project$Forms$ParameterClauseForm$createParameterClauseFormValues,
			$author$project$Entity$ActionAndViewComponent$getComponentParameterClauses(def)),
		bl: $elm$core$Maybe$Nothing,
		by: $elm$core$Maybe$Nothing,
		bE: $author$project$Forms$ViewComponentAndActionForms$createInputFieldComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewInputViewComponent),
		b2: $author$project$Forms$ViewComponentAndActionForms$createTextComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewTextViewComponent),
		a4: ''
	};
	switch (def.$) {
		case 0:
			var def_ = def.a;
			return _Utils_update(
				values,
				{
					bd: $author$project$Forms$ViewComponentAndActionForms$createButtonComponentFormValues(def_),
					a4: 'button'
				});
		case 1:
			var def_ = def.a;
			return _Utils_update(
				values,
				{
					bE: $author$project$Forms$ViewComponentAndActionForms$createInputFieldComponentFormValues(def_),
					a4: 'input'
				});
		case 2:
			var def_ = def.a;
			return _Utils_update(
				values,
				{
					b2: $author$project$Forms$ViewComponentAndActionForms$createTextComponentFormValues(def_),
					a4: 'text'
				});
		case 3:
			var def_ = def.a;
			return _Utils_update(
				values,
				{
					bl: $elm$core$Maybe$Just(
						$author$project$Forms$ViewComponentAndActionForms$createContainerComponentFormValues(def_)),
					a4: 'container'
				});
		default:
			var def_ = def.a;
			return _Utils_update(
				values,
				{
					by: $elm$core$Maybe$Just(
						$author$project$Forms$ViewComponentAndActionForms$createGeneratorComponentFormValues(def_)),
					a4: 'generator'
				});
	}
};
function $author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues() {
	return A2(
		$author$project$Forms$CommonForms$mapToOptionalFormValues,
		function (config) {
			return {
				cU: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(config.i8)
			};
		},
		{i8: $author$project$Entity$ActionAndViewComponent$createNewKappaAction});
}
function $author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues() {
	return A2($author$project$Forms$CommonForms$mapToOptionalFormValues, $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues, $author$project$Entity$ActionAndViewComponent$createNewKappaAction);
}
var $author$project$Forms$ViewComponentAndActionForms$createElementDropZoneConfigFormValues = $author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues();
$author$project$Forms$ViewComponentAndActionForms$cyclic$createElementDropZoneConfigFormValues = function () {
	return $author$project$Forms$ViewComponentAndActionForms$createElementDropZoneConfigFormValues;
};
var $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues = $author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues();
$author$project$Forms$ViewComponentAndActionForms$cyclic$mapToOptionalActionValues = function () {
	return $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues;
};
var $author$project$Forms$CommandForms$CalendarViewCommandForm$createCalendarViewCommandFormValues = F2(
	function (command, spec) {
		return A8(
			$author$project$Forms$CommandForms$CalendarViewCommandForm$CalendarViewCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			$author$project$Forms$ClauseForms$createClauseValues(spec.jx),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.lK),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.jv),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.jt),
			A3(
				$author$project$Forms$CommonForms$mapToOptionalFormValues,
				$elm$core$List$map($author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues),
				_List_Nil,
				spec.ju),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$ChartCommandForm$ChartCommandFormValues = function (commonPropsValues) {
	return function (chartBaseType) {
		return function (mainClauseValues) {
			return function (seriesValues) {
				return function (actionBindingValues) {
					return function (contextMenuBindingValues) {
						return function (chartEventHandlers) {
							return function (returnTypeValues) {
								return function (actionRequestsClauseValues) {
									return function (actionRequestValues) {
										return {ez: actionBindingValues, hv: actionRequestValues, hx: actionRequestsClauseValues, h8: chartBaseType, h9: chartEventHandlers, ip: commonPropsValues, eM: contextMenuBindingValues, aq: mainClauseValues, fw: returnTypeValues, fz: seriesValues};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Utils$ToStrings$chartBaseTypeToString = function (chartBaseType) {
	if (!chartBaseType) {
		return 'GanttChart';
	} else {
		return 'CommonCharts';
	}
};
var $author$project$Forms$CommandForms$CommandCommonForms$createAavoActionBindingValues = function (action) {
	return {
		a7: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(action.fO),
		fW: action.fW,
		l_: action.l_
	};
};
var $author$project$Forms$CommandForms$ChartCommandForm$ChartCommandSeriesConfigValues = F2(
	function (mainClauseValues, dataClauseValues) {
		return {cN: dataClauseValues, aq: mainClauseValues};
	});
var $author$project$Forms$CommandForms$ChartCommandForm$createChartCommandSeriesConfigValues = function (config) {
	return A2(
		$author$project$Forms$CommandForms$ChartCommandForm$ChartCommandSeriesConfigValues,
		$author$project$Forms$ClauseForms$createClauseValues(config.gC),
		$author$project$Forms$ClauseForms$createClauseValues(config.eO));
};
var $author$project$Forms$CommandForms$CommandCommonForms$createContextMenuBindingValues = function (_v0) {
	var bindKey = _v0.fW;
	var menuComponents = _v0.kO;
	return {
		fW: bindKey,
		kN: A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues, menuComponents)
	};
};
var $author$project$Forms$ClauseForms$createDefaultClauseValues = $author$project$Forms$ClauseForms$createClauseValues($author$project$Entity$Clauses$createNewClause);
var $author$project$Forms$CommandForms$ChartCommandForm$createReturnTypeValues = function (returnType) {
	if (!returnType.$) {
		return {bw: $author$project$Forms$ClauseForms$createDefaultClauseValues, a4: 'json'};
	} else {
		var fileNameClause = returnType.a.jG;
		return {
			bw: $author$project$Forms$ClauseForms$createClauseValues(fileNameClause),
			a4: 'exportedFile'
		};
	}
};
var $author$project$Forms$CommandForms$ChartCommandForm$createChartCommandFormValues = F2(
	function (command, spec) {
		return $author$project$Forms$CommandForms$ChartCommandForm$ChartCommandFormValues(
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command))(
			$author$project$Utils$ToStrings$chartBaseTypeToString(spec.h8))(
			$author$project$Forms$ClauseForms$createClauseValues(spec.gC))(
			A2($elm$core$List$map, $author$project$Forms$CommandForms$ChartCommandForm$createChartCommandSeriesConfigValues, spec.l3))(
			A2($elm$core$List$map, $author$project$Forms$CommandForms$CommandCommonForms$createAavoActionBindingValues, spec.fP))(
			A2($elm$core$List$map, $author$project$Forms$CommandForms$CommandCommonForms$createContextMenuBindingValues, spec.iD))(
			A2($elm$json$Json$Encode$encode, 4, spec.h9))(
			$author$project$Forms$CommandForms$ChartCommandForm$createReturnTypeValues(spec.lQ))(
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw))(
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$CronCommandForm$CronCommandFormValues = F5(
	function (commonPropsValues, cronExpression, firstExecutionTime, clauseToExecuteValues, doNotLogIfNoMatch) {
		return {eI: clauseToExecuteValues, ip: commonPropsValues, iK: cronExpression, i3: doNotLogIfNoMatch, jL: firstExecutionTime};
	});
var $author$project$Forms$CommandForms$CronCommandForm$createCronCommandFormValues = F2(
	function (command, spec) {
		return A5(
			$author$project$Forms$CommandForms$CronCommandForm$CronCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			spec.iK,
			spec.jL,
			$author$project$Forms$ClauseForms$createClauseValues(spec.id),
			spec.i3);
	});
var $author$project$Forms$CommandForms$DataViewCommandForm$DataViewColumnValues = F5(
	function (inputFieldValues, isHidden, isEditable, cellRenderer, useValuesAsParameters) {
		return {h6: cellRenderer, de: inputFieldValues, di: isEditable, kp: isHidden, mT: useValuesAsParameters};
	});
var $author$project$Utils$ToStrings$dataViewCellRendererToString = function (cellRenderer) {
	switch (cellRenderer) {
		case 0:
			return 'Default';
		case 1:
			return 'Html';
		case 2:
			return 'Image';
		case 3:
			return 'Link';
		default:
			return 'Icon';
	}
};
var $author$project$Forms$CommandForms$DataViewCommandForm$createDataViewColumnFormValues = function (column) {
	return A5(
		$author$project$Forms$CommandForms$DataViewCommandForm$DataViewColumnValues,
		$author$project$Forms$InputFieldForms$createFieldDefinitionFormValues(column.ki),
		column.kp,
		column.jg,
		$author$project$Utils$ToStrings$dataViewCellRendererToString(column.h6),
		column.mT);
};
var $author$project$Utils$ToStrings$dataViewPaginationModeToString = function (mode) {
	switch (mode) {
		case 0:
			return 'PaginationDisabled';
		case 1:
			return 'ClientSidePagination';
		default:
			return 'ServerSidePagination';
	}
};
var $author$project$Utils$ToStrings$dataViewSelectionModeToString = function (mode) {
	switch (mode) {
		case 0:
			return 'SelectionDisabled';
		case 1:
			return 'SingleRow';
		default:
			return 'MultiRow';
	}
};
var $author$project$Forms$CommandForms$DataViewCommandForm$createDataViewCommandFormValues = F2(
	function (command, spec) {
		var insertConfigValues = A2(
			$author$project$Forms$CommonForms$createOptionalFormValues,
			{
				bq: $author$project$Forms$ClauseForms$createOptionalClauseValues($elm$core$Maybe$Nothing),
				jN: false
			},
			A2(
				$elm$core$Maybe$map,
				function (insertConfig) {
					return {
						bq: $author$project$Forms$ClauseForms$createOptionalClauseValues(insertConfig.i0),
						jN: insertConfig.jN
					};
				},
				spec.kl));
		return {
			hv: $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu),
			hx: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			cD: spec.lT,
			ip: $author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			iX: spec.iX,
			cP: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.iY),
			i7: spec.i7,
			ja: spec.ja,
			jb: $author$project$Forms$ClauseForms$createClauseValues(spec.jb),
			cX: $author$project$Forms$ClauseForms$createClauseValues(spec.jD),
			ka: A2($elm$core$Maybe$withDefault, '', spec.ka),
			df: insertConfigValues,
			dI: $author$project$Utils$ToStrings$dataViewPaginationModeToString(spec.lf),
			d2: $author$project$Forms$ViewComponentAndActionForms$createElementDropZoneConfigFormValues(spec.lU),
			lY: spec.lY,
			d7: $author$project$Utils$ToStrings$dataViewSelectionModeToString(spec.l0),
			ml: A2($elm$core$List$map, $author$project$Forms$CommandForms$DataViewCommandForm$createDataViewColumnFormValues, spec.ml),
			eq: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hn)
		};
	});
var $author$project$Forms$CommandForms$DiagramCommandForm$DiagramCommandFormValues = F7(
	function (commonPropsValues, nodeParametersClauseValues, markdownClauseValues, actionBindings, contextMenus, actionRequestsClauseValues, actionRequestValues) {
		return {fP: actionBindings, hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, f5: contextMenus, fb: markdownClauseValues, fc: nodeParametersClauseValues};
	});
var $author$project$Forms$CommandForms$DiagramCommandForm$createDiagramCommandFormValues = F2(
	function (command, spec) {
		return A7(
			$author$project$Forms$CommandForms$DiagramCommandForm$DiagramCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			$author$project$Forms$ClauseForms$createClauseValues(spec.k0),
			$author$project$Forms$ClauseForms$createClauseValues(spec.kG),
			A2($elm$core$List$map, $author$project$Forms$CommandForms$CommandCommonForms$createAavoActionBindingValues, spec.fP),
			A2($elm$core$List$map, $author$project$Forms$CommandForms$CommandCommonForms$createContextMenuBindingValues, spec.f5),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$DownloadFileCommandForm$DownloadFileCommandFormValues = F5(
	function (commonPropsValues, filePathClauseValues, tryConvertToPdf, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, e$: filePathClauseValues, mK: tryConvertToPdf};
	});
var $author$project$Forms$CommandForms$DownloadFileCommandForm$createDownloadFileCommandFormValues = F2(
	function (command, spec) {
		return A5(
			$author$project$Forms$CommandForms$DownloadFileCommandForm$DownloadFileCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			$author$project$Forms$ClauseForms$createClauseValues(spec.jH),
			spec.mK,
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$FormCommandForm$FormCommandFormValues = F6(
	function (commonPropsValues, updateClauseValues, insertClauseValues, postSubmitActionValues, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, e9: insertClauseValues, fn: postSubmitActionValues, eq: updateClauseValues};
	});
var $author$project$Forms$CommandForms$FormCommandForm$createFormCommandFormValues = F2(
	function (command, spec) {
		return A6(
			$author$project$Forms$CommandForms$FormCommandForm$FormCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hn),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.kk),
			A3($author$project$Forms$CommonForms$mapToOptionalFormValues, $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues, $author$project$Entity$ActionAndViewComponent$createNewKappaAction, spec.lq),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$MailCommandForm$createMailCommandFormValues = F2(
	function (command, spec) {
		return {
			hv: $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu),
			hx: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			ip: $author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			cK: $author$project$Forms$ClauseForms$createClauseValues(spec.iC),
			d$: $author$project$Forms$ClauseForms$createClauseValues(spec.gU),
			d0: $author$project$Forms$ClauseForms$createClauseValues(spec.fu),
			ef: $author$project$Forms$ClauseForms$createClauseValues(spec.mo)
		};
	});
var $author$project$Forms$CommandForms$NotificationCommandForm$createNotificationCommandFormValues = F2(
	function (command, spec) {
		return {
			hv: $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu),
			hx: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			a7: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(spec.fO),
			ct: $author$project$Forms$ClauseForms$createClauseValues(spec.h0),
			ip: $author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			dc: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.ke),
			d$: $author$project$Forms$ClauseForms$createClauseValues(spec.gU),
			d0: $author$project$Forms$ClauseForms$createClauseValues(spec.fu),
			el: $author$project$Forms$ClauseForms$createClauseValues(spec.mD)
		};
	});
var $author$project$Forms$CommandForms$ProductionLineCommandForm$createProductionLineCommandFormValues = F2(
	function (command, spec) {
		return {
			hv: $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu),
			hx: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			cu: $author$project$Forms$ClauseForms$createClauseValues(spec.h1),
			cv: $author$project$Forms$ClauseForms$createClauseValues(spec.h2),
			cw: $author$project$Forms$ClauseForms$createClauseValues(spec.h3),
			ip: $author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			cN: $author$project$Forms$ClauseForms$createClauseValues(spec.eO),
			ek: $author$project$Forms$ClauseForms$createClauseValues(spec.mz)
		};
	});
var $author$project$Forms$CommandForms$QuickSightCommandForm$QuickSightCommandFormValues = F4(
	function (commonPropsValues, dashboardIdClause, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, iN: dashboardIdClause};
	});
var $author$project$Forms$CommandForms$QuickSightCommandForm$createQuickSightCommandFormValues = F2(
	function (command, spec) {
		return A4(
			$author$project$Forms$CommandForms$QuickSightCommandForm$QuickSightCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			$author$project$Forms$ClauseForms$createClauseValues(spec.iN),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$ReportCommandForm$ReportCommandFormValues = F9(
	function (commonPropsValues, templateFileCommandCallValues, templateFileClauseValues, dataClauseValues, resultFileNameClauseValues, reportTimeZoneId, reportLocale, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, cN: dataClauseValues, lE: reportLocale, lF: reportTimeZoneId, fv: resultFileNameClauseValues, fH: templateFileClauseValues, fI: templateFileCommandCallValues};
	});
var $author$project$Forms$CommandForms$ReportCommandForm$createReportCommandFormValues = F2(
	function (command, spec) {
		return A9(
			$author$project$Forms$CommandForms$ReportCommandForm$ReportCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			A3($author$project$Forms$CommonForms$mapToOptionalFormValues, $author$project$Forms$CommandCallDefinitionForm$createCommandCallDefinitionFormValues, $author$project$Entity$CommandCallDefinition$createNewCommandCallDefinition, spec.mx),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.mw),
			$author$project$Forms$ClauseForms$createClauseValues(spec.eO),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.lL),
			spec.lF,
			spec.lE,
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$SingleClauseCommandForm$SingleClauseCommandFormValues = F4(
	function (commonPropsValues, clauseValues, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, be: clauseValues, ip: commonPropsValues};
	});
var $author$project$Forms$CommandForms$SingleClauseCommandForm$createSingleClauseCommandFormValues = F2(
	function (command, spec) {
		return A4(
			$author$project$Forms$CommandForms$SingleClauseCommandForm$SingleClauseCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			$author$project$Forms$ClauseForms$createClauseValues(spec.eH),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$TreeViewCommandForm$TreeViewCommandFormValues = function (commonPropsValues) {
	return function (elementsClauseValues) {
		return function (actions) {
			return function (contextMenus) {
				return function (onElementSelectionChangedValues) {
					return function (onElementClickValues) {
						return function (onElementDoubleClickValues) {
							return function (actionRequestsClauseValues) {
								return function (actionRequestValues) {
									return function (draggableElements) {
										return function (elementDropZoneConfigValues) {
											return function (elementGapDropZoneConfigValues) {
												return {hv: actionRequestValues, hx: actionRequestsClauseValues, hA: actions, ip: commonPropsValues, f5: contextMenus, i6: draggableElements, eR: elementDropZoneConfigValues, eS: elementGapDropZoneConfigValues, eT: elementsClauseValues, ff: onElementClickValues, fg: onElementDoubleClickValues, fh: onElementSelectionChangedValues};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Forms$CommandForms$TreeViewCommandForm$createTreeViewCommandFormValues = F2(
	function (command, spec) {
		return $author$project$Forms$CommandForms$TreeViewCommandForm$TreeViewCommandFormValues(
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command))(
			$author$project$Forms$ClauseForms$createClauseValues(spec.jl))(
			A2($elm$core$List$map, $author$project$Forms$CommandForms$CommandCommonForms$createAavoActionBindingValues, spec.hA))(
			A2($elm$core$List$map, $author$project$Forms$CommandForms$CommandCommonForms$createContextMenuBindingValues, spec.f5))(
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.k5))(
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.k3))(
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.k4))(
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw))(
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu))(spec.i6)(
			$author$project$Forms$ViewComponentAndActionForms$createElementDropZoneConfigFormValues(spec.jj))(
			$author$project$Forms$ViewComponentAndActionForms$createElementDropZoneConfigFormValues(spec.jk));
	});
var $author$project$Forms$CommandForms$UploadFilesCommandForm$createUploadFilesCommandFormValues = F2(
	function (command, spec) {
		return {
			hv: $author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu),
			hx: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			ip: $author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			jJ: spec.jJ,
			lr: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.lr),
			fu: $author$project$Forms$ClauseForms$createOptionalClauseValues(spec.fu),
			mt: $author$project$Forms$ClauseForms$createClauseValues(spec.mt)
		};
	});
var $author$project$Forms$CommandForms$ViewComponentsCommandForm$ViewComponentsCommandFormValues = F5(
	function (commonPropsValues, componentDefinitions, forcedValuesForExistingRecordClauseValues, actionRequestsClauseValues, actionRequestValues) {
		return {hv: actionRequestValues, hx: actionRequestsClauseValues, ip: commonPropsValues, ir: componentDefinitions, e2: forcedValuesForExistingRecordClauseValues};
	});
var $author$project$Forms$CommandForms$ViewComponentsCommandForm$createViewComponentsCommandFormValues = F2(
	function (command, spec) {
		return A5(
			$author$project$Forms$CommandForms$ViewComponentsCommandForm$ViewComponentsCommandFormValues,
			$author$project$Forms$CommandForms$CommandCommonForms$createCommonPropsValues(command),
			A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues, spec.ir),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.jM),
			$author$project$Forms$ClauseForms$createOptionalClauseValues(spec.hw),
			$author$project$Forms$ViewComponentAndActionForms$mapToOptionalActionValues(spec.hu));
	});
var $author$project$Forms$CommandForms$CommandForm$createCommandFormValues = function (command) {
	var _v0 = command.mh;
	switch (_v0.$) {
		case 0:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$SingleClauseCommandForm(
				A2($author$project$Forms$CommandForms$SingleClauseCommandForm$createSingleClauseCommandFormValues, command, spec));
		case 1:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$DataViewCommandForm(
				A2($author$project$Forms$CommandForms$DataViewCommandForm$createDataViewCommandFormValues, command, spec));
		case 2:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$FormCommandForm(
				A2($author$project$Forms$CommandForms$FormCommandForm$createFormCommandFormValues, command, spec));
		case 3:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$ProductionLineCommandForm(
				A2($author$project$Forms$CommandForms$ProductionLineCommandForm$createProductionLineCommandFormValues, command, spec));
		case 4:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$MailCommandForm(
				A2($author$project$Forms$CommandForms$MailCommandForm$createMailCommandFormValues, command, spec));
		case 5:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$NotificationCommandForm(
				A2($author$project$Forms$CommandForms$NotificationCommandForm$createNotificationCommandFormValues, command, spec));
		case 6:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$DownloadFileCommandForm(
				A2($author$project$Forms$CommandForms$DownloadFileCommandForm$createDownloadFileCommandFormValues, command, spec));
		case 7:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$UploadFilesCommandForm(
				A2($author$project$Forms$CommandForms$UploadFilesCommandForm$createUploadFilesCommandFormValues, command, spec));
		case 8:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$ReportCommandForm(
				A2($author$project$Forms$CommandForms$ReportCommandForm$createReportCommandFormValues, command, spec));
		case 9:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$ChartCommandForm(
				A2($author$project$Forms$CommandForms$ChartCommandForm$createChartCommandFormValues, command, spec));
		case 10:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$DiagramCommandForm(
				A2($author$project$Forms$CommandForms$DiagramCommandForm$createDiagramCommandFormValues, command, spec));
		case 11:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$TreeViewCommandForm(
				A2($author$project$Forms$CommandForms$TreeViewCommandForm$createTreeViewCommandFormValues, command, spec));
		case 12:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$CalendarViewCommandForm(
				A2($author$project$Forms$CommandForms$CalendarViewCommandForm$createCalendarViewCommandFormValues, command, spec));
		case 13:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$QuickSightCommandForm(
				A2($author$project$Forms$CommandForms$QuickSightCommandForm$createQuickSightCommandFormValues, command, spec));
		case 14:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$CronCommandForm(
				A2($author$project$Forms$CommandForms$CronCommandForm$createCronCommandFormValues, command, spec));
		default:
			var spec = _v0.a;
			return $author$project$Forms$CommandForms$CommandForm$ViewComponentsCommandForm(
				A2($author$project$Forms$CommandForms$ViewComponentsCommandForm$createViewComponentsCommandFormValues, command, spec));
	}
};
var $author$project$Forms$CreateNewCommandForm$NewCommandFormValues = function (type_) {
	return {mM: type_};
};
var $author$project$Forms$CreateNewCommandForm$createNewCommandFormValues = $author$project$Forms$CreateNewCommandForm$NewCommandFormValues('singleClause');
var $author$project$Entity$Common$lastPosition = -1;
var $author$project$Entity$Common$notPersistedId = 0;
var $author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup = {hM: $elm$core$Maybe$Nothing, i$: '', db: $author$project$Entity$Common$notPersistedId, k_: '', fk: $elm$core$Maybe$Nothing, dM: $author$project$Entity$Common$lastPosition};
var $lukewestby$elm_http_builder$HttpBuilder$delete = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('DELETE');
var $author$project$Lib$CustomHttp$expectStringCustom = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		A2($author$project$Lib$CustomHttp$httpResponseToResult, $elm$core$Result$Ok, $elm$core$Maybe$Nothing));
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$relative = F2(
	function (pathSegments, parameters) {
		return _Utils_ap(
			A2($elm$core$String$join, '/', pathSegments),
			$elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $author$project$Api$EntityApi$strResultToIdResult = $elm$core$Result$andThen(
	A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toInt,
		$elm$core$Result$fromMaybe(
			$author$project$Lib$CustomHttp$BadBody('Id ei ollut kelvollinen'))));
var $author$project$Api$EntityApi$deleteEntity = F4(
	function (url, _v0, id, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$author$project$Lib$CustomHttp$expectStringCustom(
					A2($elm$core$Basics$composeR, $author$project$Api$EntityApi$strResultToIdResult, toMsg)),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					$author$project$Api$ApiUrls$aavoBranchHeader,
					selectedBranchKey,
					$lukewestby$elm_http_builder$HttpBuilder$delete(
						A2(
							$elm$url$Url$Builder$relative,
							_List_fromArray(
								[
									url,
									$elm$core$String$fromInt(id)
								]),
							_List_Nil)))));
	});
var $author$project$Api$EntityApi$deleteCommand = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$commandsUrl);
var $author$project$Api$EntityApi$deleteCommandGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$commandGroupsUrl);
var $author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData = F2(
	function (data, id) {
		return A2(
			$author$project$Lib$RemoteData$map,
			$elm$core$Dict$remove(id),
			data);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId = function (model) {
	var _v0 = model.J;
	if (!_v0.$) {
		if (_v0.a.$ === 1) {
			var group = _v0.a.a;
			return $elm$core$Maybe$Just(group.db);
		} else {
			var entity = _v0.a.a;
			return entity.j_;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pages$Main$OutMsg$OnHttpError = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult = F3(
	function (onOk, model, result) {
		if (!result.$) {
			var content = result.a;
			return onOk(content);
		} else {
			var err = result.a;
			return _Utils_Tuple3(
				model,
				$elm$core$Platform$Cmd$none,
				$author$project$Pages$Main$OutMsg$OnHttpError(err));
		}
	});
var $author$project$Lib$RemoteData$update = F2(
	function (f, remoteData) {
		switch (remoteData.$) {
			case 3:
				var data = remoteData.a;
				var _v1 = f(data);
				var first = _v1.a;
				var second = _v1.b;
				return _Utils_Tuple2(
					$author$project$Lib$RemoteData$Success(first),
					second);
			case 0:
				return _Utils_Tuple2($author$project$Lib$RemoteData$EmptyLoading, $elm$core$Platform$Cmd$none);
			case 1:
				var oldData = remoteData.a;
				return _Utils_Tuple2(
					$author$project$Lib$RemoteData$Loading(
						f(oldData).a),
					$elm$core$Platform$Cmd$none);
			default:
				var error = remoteData.a;
				return _Utils_Tuple2(
					$author$project$Lib$RemoteData$Failure(error),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Lib$RemoteData$updateData = F2(
	function (f, remoteData) {
		return A2(
			$author$project$Lib$RemoteData$update,
			function (l) {
				return _Utils_Tuple2(
					f(l),
					$elm$core$Platform$Cmd$none);
			},
			remoteData).a;
	});
var $author$project$Utils$RemoteDataUtils$insertToEntityDictWebData = F2(
	function (data, item) {
		return A2(
			$author$project$Lib$RemoteData$updateData,
			function (dict) {
				return A3($elm$core$Dict$insert, item.db, item, dict);
			},
			data);
	});
var $author$project$Entity$Command$commandTypeOptions = _List_fromArray(
	[
		_Utils_Tuple2('singleClause', 'Yksi lause'),
		_Utils_Tuple2('transferData', 'Datan siirto'),
		_Utils_Tuple2('dataView', 'Datanäkymä'),
		_Utils_Tuple2('formView', 'Lomake'),
		_Utils_Tuple2('productionLine', 'Linjastonäkymä'),
		_Utils_Tuple2('mail', 'Sähköpostikomento'),
		_Utils_Tuple2('notification', 'Ilmoituskomento'),
		_Utils_Tuple2('downloadFile', 'Tiedoston lataus käyttäjälle'),
		_Utils_Tuple2('uploadFiles', 'Tiedostojen vienti palvelimelle'),
		_Utils_Tuple2('report', 'Raportti'),
		_Utils_Tuple2('chart', 'Kuvaaja'),
		_Utils_Tuple2('diagram', 'Diagrammi'),
		_Utils_Tuple2('treeView', 'Puunäkymä'),
		_Utils_Tuple2('calendar', 'Kalenterinäkymä'),
		_Utils_Tuple2('quickSight', 'QuickSight-komento'),
		_Utils_Tuple2('cron', 'Ajastettu komento'),
		_Utils_Tuple2('viewComponents', 'Näkymän komponentit')
	]);
var $author$project$Pages$Main$EntityListing$EntityListingFilters$TextFilter = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Main$EntityListing$EntityListingFilters$createStringFilter = F3(
	function (filterProps, filterValue, element) {
		return A2(
			$elm$core$List$any,
			function (f) {
				return A2(
					$elm$core$String$contains,
					filterValue,
					f(element));
			},
			filterProps);
	});
var $author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter = F2(
	function (entityFilterProps, groupFilterProps) {
		return {
			cZ: $author$project$Pages$Main$EntityListing$EntityListingFilters$createStringFilter(entityFilterProps),
			c_: $author$project$Pages$Main$EntityListing$EntityListingFilters$createStringFilter(groupFilterProps),
			e0: $author$project$Pages$Main$EntityListing$EntityListingFilters$TextFilter('Haku'),
			db: 'common_string_filter'
		};
	});
var $author$project$Pages$Main$EntityListing$EntityListingFilters$SelectionFilter = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EntityListing$EntityListingFilters$entityTypeFilter = F2(
	function (filter, options) {
		return {
			cZ: filter,
			c_: F2(
				function (_v0, _v1) {
					return false;
				}),
			e0: $author$project$Pages$Main$EntityListing$EntityListingFilters$SelectionFilter(
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('', 'Kaikki tyypit'),
					options)),
			db: 'entity_type_filter'
		};
	});
var $author$project$Pages$Main$CommandsView$filterByCommandType = F2(
	function (filterValue, command) {
		var requiredValue = function () {
			var _v0 = command.mh;
			switch (_v0.$) {
				case 0:
					return 'singleClause';
				case 1:
					return 'dataView';
				case 2:
					return 'formView';
				case 3:
					return 'productionLine';
				case 4:
					return 'mail';
				case 5:
					return 'notification';
				case 6:
					return 'downloadFile';
				case 7:
					return 'uploadFiles';
				case 8:
					return 'report';
				case 9:
					return 'chart';
				case 10:
					return 'diagram';
				case 11:
					return 'treeView';
				case 12:
					return 'calendar';
				case 13:
					return 'quickSight';
				case 14:
					return 'cron';
				default:
					return 'viewComponents';
			}
		}();
		return _Utils_eq(filterValue, requiredValue);
	});
var $author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns = function (entity) {
	var _v0 = entity.hM;
	if (!_v0.$) {
		var projectId = _v0.a;
		return _List_fromArray(
			[
				'APRO ' + $elm$core$String$fromInt(projectId)
			]);
	} else {
		return _List_Nil;
	}
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm_community$list_extra$List$Extra$takeWhile = function (predicate) {
	var takeWhileMemo = F2(
		function (memo, list) {
			takeWhileMemo:
			while (true) {
				if (!list.b) {
					return $elm$core$List$reverse(memo);
				} else {
					var x = list.a;
					var xs = list.b;
					if (predicate(x)) {
						var $temp$memo = A2($elm$core$List$cons, x, memo),
							$temp$list = xs;
						memo = $temp$memo;
						list = $temp$list;
						continue takeWhileMemo;
					} else {
						return $elm$core$List$reverse(memo);
					}
				}
			}
		});
	return takeWhileMemo(_List_Nil);
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $author$project$Utils$StringUtils$firstLine = function (string) {
	return $elm$core$String$fromList(
		A2(
			$elm_community$list_extra$List$Extra$takeWhile,
			$elm$core$Basics$neq('\n'),
			$elm$core$String$toList(string)));
};
var $author$project$Entity$Command$getCommandTypeAsString = function (command) {
	var _v0 = command.mh;
	switch (_v0.$) {
		case 0:
			return 'Yksi lause';
		case 1:
			return 'Datanäkymän komento';
		case 2:
			return 'Lomakenäkymän tallennuskomento';
		case 3:
			return 'Linjastonäkymän komento';
		case 4:
			return 'Sähköpostikomento';
		case 5:
			return 'Ilmoituskomento';
		case 6:
			return 'Tiedoston lataus käyttäjälle';
		case 7:
			return 'Tiedostojen vienti palvelimelle';
		case 8:
			return 'Raporttikomento';
		case 9:
			return 'Kuvaajanäkymän komento';
		case 10:
			return 'Diagramminäkymän komento';
		case 11:
			return 'Puunäkymän komento';
		case 12:
			return 'Kalenterinäkymän komento';
		case 13:
			return 'QuickSight-komento';
		case 14:
			return 'Ajastettu komento';
		default:
			return 'Näkymän komponenttien määrittely';
	}
};
var $elm_community$maybe_extra$Maybe$Extra$toList = function (m) {
	if (m.$ === 1) {
		return _List_Nil;
	} else {
		var x = m.a;
		return _List_fromArray(
			[x]);
	}
};
var $author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns = function (entity) {
	return $elm_community$maybe_extra$Maybe$Extra$toList(entity.j9);
};
var $author$project$Pages$Main$CommandsView$getEntityTreeItemColumns = function (command) {
	return _Utils_ap(
		_List_fromArray(
			[
				$author$project$Entity$Command$getCommandTypeAsString(command),
				$elm$core$String$fromInt(command.db),
				command.kt,
				$author$project$Utils$StringUtils$firstLine(command.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(command),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(command)));
};
var $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields = function (group) {
	return _Utils_ap(
		_List_fromArray(
			[
				'Ryhmä',
				$elm$core$String$fromInt(group.db),
				group.k_,
				$author$project$Utils$StringUtils$firstLine(group.i$)
			]),
		$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(group));
};
var $author$project$Pages$Main$CommandsView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2($author$project$Pages$Main$EntityListing$EntityListingFilters$entityTypeFilter, $author$project$Pages$Main$CommandsView$filterByCommandType, $author$project$Entity$Command$commandTypeOptions),
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.kt;
				},
					function ($) {
					return $.i$;
				}
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.O;
	},
	jT: $author$project$Pages$Main$CommandsView$getEntityTreeItemColumns,
	jU: $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields,
	gp: function ($) {
		return $._;
	}
};
var $author$project$Lib$Form$FormView$ErrorTracking = $elm$core$Basics$identity;
var $author$project$Lib$Form$LoadStatus$Idle = {$: 0};
var $author$project$Lib$Form$FormView$makeModel = function (values) {
	return {
		bu: {ea: false, eb: $elm$core$Set$empty},
		am: $elm$core$Maybe$Nothing,
		K: $author$project$Lib$Form$LoadStatus$Idle,
		hr: values
	};
};
var $author$project$Pages$Main$CommandsView$onGotDelete = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$CommandsView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode = F2(
	function (currentModel, newModel) {
		var _v0 = _Utils_Tuple2(currentModel, newModel);
		_v0$2:
		while (true) {
			switch (_v0.a.$) {
				case 1:
					if (_v0.b.$ === 1) {
						var focusedCodeField = _v0.a.a.am;
						var newModel_ = _v0.b.a;
						return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
							_Utils_update(
								newModel_,
								{am: focusedCodeField}));
					} else {
						break _v0$2;
					}
				case 2:
					if (_v0.b.$ === 2) {
						return newModel;
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		}
		return currentModel;
	});
var $author$project$Pages$Main$CommandsView$onGotPostResult = F2(
	function (_v0, model) {
		var makeNewEditModel = _v0.fa;
		var updateRepository = _v0.fK;
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (command) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							j: A2(
								$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
								model.j,
								makeNewEditModel(command))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(command)));
			},
			model);
	});
var $author$project$Pages$Main$CommandsView$positionUpdatingConfig = {
	go: function ($) {
		return $.O;
	},
	gp: function ($) {
		return $._;
	},
	l6: F2(
		function (commands, repo) {
			return _Utils_update(
				repo,
				{
					O: $author$project$Lib$RemoteData$Success(commands)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					_: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Serializable$encode = function (serializable) {
	switch (serializable.$) {
		case 0:
			var string_ = serializable.a;
			return $elm$json$Json$Encode$string(string_);
		case 1:
			var int_ = serializable.a;
			return $elm$json$Json$Encode$int(int_);
		case 2:
			var float_ = serializable.a;
			return $elm$json$Json$Encode$float(float_);
		default:
			var bool_ = serializable.a;
			return $elm$json$Json$Encode$bool(bool_);
	}
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Encoding$Utils$encodeMaybe = F2(
	function (encoder, maybeVal) {
		if (maybeVal.$ === 1) {
			return $elm$json$Json$Encode$null;
		} else {
			var val = maybeVal.a;
			return encoder(val);
		}
	});
var $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter = function (commandParameter) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'key',
				$elm$json$Json$Encode$string(commandParameter.kt)),
				_Utils_Tuple2(
				'value',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Serializable$encode, commandParameter.mY))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Encoding$CommandCallDefinitionEncoding$encodeCommandCallDefinition = function (definition) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(definition.f2)),
				_Utils_Tuple2(
				'commandParams',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, definition.lk))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeCommandClause = function (clause) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('command')),
				_Utils_Tuple2(
				'commandCallDefinition',
				$author$project$Encoding$CommandCallDefinitionEncoding$encodeCommandCallDefinition(clause.il)),
				_Utils_Tuple2(
				'asyncExecution',
				$elm$json$Json$Encode$bool(clause.hP))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeDatabaseClause = function (clause) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('database')),
				_Utils_Tuple2(
				'sql',
				$elm$json$Json$Encode$string(clause.ij)),
				_Utils_Tuple2(
				'interpretation',
				$elm$json$Json$Encode$string(
					$author$project$Utils$ToStrings$databaseClauseInterpretationToString(clause.kn))),
				_Utils_Tuple2(
				'explicitConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, clause.gi)),
				_Utils_Tuple2(
				'resultSource',
				$elm$json$Json$Encode$string(
					$author$project$Utils$ToStrings$databaseClauseResultSourceToString(clause.lN))),
				_Utils_Tuple2(
				'metadata',
				$author$project$Encoding$ClauseEncoding$encodeDatabaseClauseMetadata(clause.kQ))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeDirectValueClause = function (clause) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('directValue')),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(clause.mY))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeHttpClause = function (clause) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('http')),
				_Utils_Tuple2(
				'path',
				$elm$json$Json$Encode$string(clause.ll)),
				_Utils_Tuple2(
				'method',
				$elm$json$Json$Encode$string(
					$author$project$Utils$ToStrings$httpMethodToString(clause.kR))),
				_Utils_Tuple2(
				'body',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, clause.h$)),
				_Utils_Tuple2(
				'explicitConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, clause.gi))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeKotlinClause = function (_v0) {
	var className = _v0.ic;
	var functionName = _v0.jQ;
	var packageVersion = _v0.le;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('kotlin')),
				_Utils_Tuple2(
				'className',
				$elm$json$Json$Encode$string(className)),
				_Utils_Tuple2(
				'functionName',
				$elm$json$Json$Encode$string(functionName)),
				_Utils_Tuple2(
				'packageVersion',
				$elm$json$Json$Encode$string(packageVersion))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodePythonClauseConnectionRedirection = function (_v0) {
	var name = _v0.k_;
	var connectionId = _v0.iw;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(name)),
				_Utils_Tuple2(
				'connectionId',
				$elm$json$Json$Encode$int(connectionId))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodePythonClause = function (clause) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('python')),
				_Utils_Tuple2(
				'script',
				$elm$json$Json$Encode$string(clause.lW)),
				_Utils_Tuple2(
				'redirectedConnections',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodePythonClauseConnectionRedirection, clause.lz)),
				_Utils_Tuple2(
				'signature',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, clause.mf))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeClause = function (clause) {
	switch (clause.$) {
		case 0:
			var directValueClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodeDirectValueClause(directValueClause);
		case 1:
			var dbClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodeDatabaseClause(dbClause);
		case 2:
			var httpClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodeHttpClause(httpClause);
		case 3:
			var pythonClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodePythonClause(pythonClause);
		case 4:
			var commandClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodeCommandClause(commandClause);
		case 5:
			var kotlinClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodeKotlinClause(kotlinClause);
		default:
			var sequenceClause = clause.a;
			return $author$project$Encoding$ClauseEncoding$encodeSequenceClause(sequenceClause);
	}
};
var $author$project$Encoding$ClauseEncoding$encodeSequenceClause = function (clause) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('sequence')),
				_Utils_Tuple2(
				'units',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeSequenceClauseUnit, clause.mN))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeSequenceClauseUnit = function (unit) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'clause',
				$author$project$Encoding$ClauseEncoding$encodeClause(unit.eH)),
				_Utils_Tuple2(
				'separatePreviousClauseResult',
				$elm$json$Json$Encode$bool(unit.l2))
			]));
};
var $author$project$Encoding$ClauseEncoding$encodeParameterClause = function (parameterClause) {
	if (!parameterClause.$) {
		var clause = parameterClause.a.eH;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('separated')),
					_Utils_Tuple2(
					'clause',
					$author$project$Encoding$ClauseEncoding$encodeClause(clause))
				]));
	} else {
		var clause = parameterClause.a.eH;
		var parameterName = parameterClause.a.lj;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('named')),
					_Utils_Tuple2(
					'parameterName',
					$elm$json$Json$Encode$string(parameterName)),
					_Utils_Tuple2(
					'clause',
					$author$project$Encoding$ClauseEncoding$encodeClause(clause))
				]));
	}
};
var $author$project$Encoding$PredicateEncoding$encodePredicate = function (aavoActionPredicate) {
	switch (aavoActionPredicate.$) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('always'))
					]));
		case 1:
			var minWidth = aavoActionPredicate.a.kV;
			var maxWidth = aavoActionPredicate.a.kL;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('screenSizeLimit')),
						_Utils_Tuple2(
						'minWidth',
						$elm$json$Json$Encode$int(minWidth)),
						_Utils_Tuple2(
						'maxWidth',
						$elm$json$Json$Encode$int(maxWidth))
					]));
		default:
			var parameterName = aavoActionPredicate.a.lj;
			var operator = aavoActionPredicate.a.la;
			var operand = aavoActionPredicate.a.k9;
			var listOperands = aavoActionPredicate.a.kA;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('parameterValue')),
						_Utils_Tuple2(
						'parameterName',
						$elm$json$Json$Encode$string(parameterName)),
						_Utils_Tuple2(
						'operator',
						$elm$json$Json$Encode$string(
							$author$project$Utils$ToStrings$predicateOperatorToString(operator))),
						_Utils_Tuple2(
						'operand',
						A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Serializable$encode, operand)),
						_Utils_Tuple2(
						'listOperands',
						A2(
							$elm$json$Json$Encode$list,
							$author$project$Encoding$Utils$encodeMaybe($author$project$Serializable$encode),
							listOperands))
					]));
	}
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeCommandExecutionAction = function (action) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('commandExecution')),
				_Utils_Tuple2(
				'commandCall',
				$author$project$Encoding$CommandCallDefinitionEncoding$encodeCommandCallDefinition(action.ik)),
				_Utils_Tuple2(
				'requestMoreInput',
				$elm$json$Json$Encode$bool(action.lH)),
				_Utils_Tuple2(
				'displayResult',
				$elm$json$Json$Encode$bool(action.i2)),
				_Utils_Tuple2(
				'parameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, action.dJ)),
				_Utils_Tuple2(
				'predicate',
				$author$project$Encoding$PredicateEncoding$encodePredicate(action.as))
			]));
};
var $author$project$Encoding$InputFieldEncoding$simpleFieldTypeToString = function (simpleFieldTypeStr) {
	switch (simpleFieldTypeStr) {
		case 0:
			return 'Constant';
		case 1:
			return 'Text';
		case 2:
			return 'TextArea';
		case 3:
			return 'Integer';
		case 4:
			return 'Float';
		case 5:
			return 'Date';
		case 6:
			return 'Time';
		case 7:
			return 'DateTime';
		case 8:
			return 'Checkbox';
		default:
			return 'Barcode';
	}
};
var $author$project$Encoding$InputFieldEncoding$encodeInputFieldTypeDefinition = function (definition) {
	switch (definition.$) {
		case 0:
			var fieldType = definition.a.jF;
			var addOffsetToTemporalTypes = definition.a.hE;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('simple')),
						_Utils_Tuple2(
						'simpleFieldType',
						$elm$json$Json$Encode$string(
							$author$project$Encoding$InputFieldEncoding$simpleFieldTypeToString(fieldType))),
						_Utils_Tuple2(
						'addOffsetToTemporalTypes',
						$elm$json$Json$Encode$bool(addOffsetToTemporalTypes))
					]));
		case 1:
			var optionsClause = definition.a.lb;
			var isLazy = definition.a.kq;
			var nullValueEnabled = definition.a.k1;
			var placeholder = definition.a.lm;
			var selectFirstValueByDefault = definition.a.lZ;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('selection')),
						_Utils_Tuple2(
						'isLazy',
						$elm$json$Json$Encode$bool(isLazy)),
						_Utils_Tuple2(
						'optionsClause',
						$author$project$Encoding$ClauseEncoding$encodeClause(optionsClause)),
						_Utils_Tuple2(
						'nullValueEnabled',
						$elm$json$Json$Encode$bool(nullValueEnabled)),
						_Utils_Tuple2(
						'placeholder',
						$elm$json$Json$Encode$string(placeholder)),
						_Utils_Tuple2(
						'selectFirstValueByDefault',
						$elm$json$Json$Encode$bool(selectFirstValueByDefault))
					]));
		default:
			var allowMultipleFiles = definition.a.hJ;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('file')),
						_Utils_Tuple2(
						'allowMultipleFiles',
						$elm$json$Json$Encode$bool(allowMultipleFiles))
					]));
	}
};
var $author$project$Encoding$InputFieldEncoding$encodeInputFieldDefinition = function (definition) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'identifier',
				$elm$json$Json$Encode$string(definition.kb)),
				_Utils_Tuple2(
				'label',
				$elm$json$Json$Encode$string(definition.kv)),
				_Utils_Tuple2(
				'defaultValue',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Serializable$encode, definition.iV)),
				_Utils_Tuple2(
				'required',
				$elm$json$Json$Encode$bool(definition.lI)),
				_Utils_Tuple2(
				'typeDefinition',
				$author$project$Encoding$InputFieldEncoding$encodeInputFieldTypeDefinition(definition.mL))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeOpenUrlAction = function (action) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('openUrl')),
				_Utils_Tuple2(
				'url',
				$elm$json$Json$Encode$string(action.mO)),
				_Utils_Tuple2(
				'targetName',
				$elm$json$Json$Encode$string(action.mu)),
				_Utils_Tuple2(
				'specs',
				$elm$json$Json$Encode$string(action.mj)),
				_Utils_Tuple2(
				'params',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, action.lk)),
				_Utils_Tuple2(
				'predicate',
				$author$project$Encoding$PredicateEncoding$encodePredicate(action.as))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeScriptAction = function (action) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('scriptAction')),
				_Utils_Tuple2(
				'script',
				$elm$json$Json$Encode$string(action.lW)),
				_Utils_Tuple2(
				'params',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, action.lk)),
				_Utils_Tuple2(
				'parameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, action.dJ)),
				_Utils_Tuple2(
				'predicate',
				$author$project$Encoding$PredicateEncoding$encodePredicate(action.as))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewActionTarget = function (target) {
	var wrapWithTypeField = F2(
		function (type_, others) {
			return $elm$json$Json$Encode$object(
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string(type_)),
					others));
		});
	switch (target.$) {
		case 0:
			return A2(wrapWithTypeField, 'global', _List_Nil);
		case 1:
			return A2(wrapWithTypeField, 'self', _List_Nil);
		case 2:
			return A2(wrapWithTypeField, 'sourceView', _List_Nil);
		case 3:
			return A2(wrapWithTypeField, 'allSiblingsAndSelf', _List_Nil);
		default:
			var viewId = target.a;
			return A2(
				wrapWithTypeField,
				'view',
				_List_fromArray(
					[
						_Utils_Tuple2(
						'viewId',
						$elm$json$Json$Encode$int(viewId))
					]));
	}
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewAction = function (action) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('viewAction')),
				_Utils_Tuple2(
				'target',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeViewActionTarget(action.ms)),
				_Utils_Tuple2(
				'actionSpecifier',
				$elm$json$Json$Encode$string(action.hy)),
				_Utils_Tuple2(
				'params',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, action.lk)),
				_Utils_Tuple2(
				'parameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, action.dJ)),
				_Utils_Tuple2(
				'predicate',
				$author$project$Encoding$PredicateEncoding$encodePredicate(action.as))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute = function (attr) {
	if (!attr.$) {
		var customAttr = attr.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('custom')),
					_Utils_Tuple2(
					'attributeId',
					$elm$json$Json$Encode$string(customAttr.hQ)),
					_Utils_Tuple2(
					'attributeValue',
					$author$project$Serializable$encode(customAttr.hR)),
					_Utils_Tuple2(
					'predicate',
					$author$project$Encoding$PredicateEncoding$encodePredicate(customAttr.as))
				]));
	} else {
		var scenario = attr.a;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('predefined')),
					_Utils_Tuple2(
					'scenario',
					$elm$json$Json$Encode$string(scenario))
				]));
	}
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeButtonViewComponent = function (componentDef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('button')),
				_Utils_Tuple2(
				'label',
				$elm$json$Json$Encode$string(componentDef.kv)),
				_Utils_Tuple2(
				'icon',
				$elm$json$Json$Encode$string(componentDef.j8)),
				_Utils_Tuple2(
				'action',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction(componentDef.fO)),
				_Utils_Tuple2(
				'dropZoneConfig',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, componentDef.cV)),
				_Utils_Tuple2(
				'cssCode',
				$elm$json$Json$Encode$string(componentDef.bm)),
				_Utils_Tuple2(
				'attributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute, componentDef.ba)),
				_Utils_Tuple2(
				'componentParameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, componentDef.cG))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeContainerComponent = function (componentDef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('container')),
				_Utils_Tuple2(
				'childComponents',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition, componentDef.ia)),
				_Utils_Tuple2(
				'clickAction',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, componentDef.f$)),
				_Utils_Tuple2(
				'doubleClickAction',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, componentDef.ga)),
				_Utils_Tuple2(
				'contextMenuComponents',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition, componentDef.f4)),
				_Utils_Tuple2(
				'dropZoneConfig',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, componentDef.cV)),
				_Utils_Tuple2(
				'cssCode',
				$elm$json$Json$Encode$string(componentDef.bm)),
				_Utils_Tuple2(
				'attributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute, componentDef.ba)),
				_Utils_Tuple2(
				'componentParameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, componentDef.cG))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeDialogAction = function (action) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('dialogAction')),
				_Utils_Tuple2(
				'title',
				$elm$json$Json$Encode$string(action.mC)),
				_Utils_Tuple2(
				'containerCssClass',
				$elm$json$Json$Encode$string(action.iz)),
				_Utils_Tuple2(
				'containerId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, action.iA)),
				_Utils_Tuple2(
				'cssCode',
				$elm$json$Json$Encode$string(action.bm)),
				_Utils_Tuple2(
				'modalSize',
				$elm$json$Json$Encode$string(action.kY)),
				_Utils_Tuple2(
				'components',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition, action.it)),
				_Utils_Tuple2(
				'params',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, action.lk)),
				_Utils_Tuple2(
				'parameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, action.dJ)),
				_Utils_Tuple2(
				'predicate',
				$author$project$Encoding$PredicateEncoding$encodePredicate(action.as))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig = function (elementDropZoneConfig) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'dropAction',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction(elementDropZoneConfig.i8))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeGeneratorComponent = function (componentDef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('generator')),
				_Utils_Tuple2(
				'generatorClause',
				$author$project$Encoding$ClauseEncoding$encodeClause(componentDef.jR)),
				_Utils_Tuple2(
				'template',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition(componentDef.mv)),
				_Utils_Tuple2(
				'dynamicTemplates',
				A3(
					$elm$json$Json$Encode$dict,
					$elm$core$Basics$identity,
					$author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition,
					$elm$core$Dict$fromList(componentDef.je))),
				_Utils_Tuple2(
				'attributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute, componentDef.ba))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeInputViewComponent = function (componentDef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('input')),
				_Utils_Tuple2(
				'fieldDefinition',
				$author$project$Encoding$InputFieldEncoding$encodeInputFieldDefinition(componentDef.jE)),
				_Utils_Tuple2(
				'submitAction',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeInputViewComponentSubmitAction(componentDef.mq)),
				_Utils_Tuple2(
				'dropZoneConfig',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, componentDef.cV)),
				_Utils_Tuple2(
				'cssCode',
				$elm$json$Json$Encode$string(componentDef.bm)),
				_Utils_Tuple2(
				'autoFocus',
				$elm$json$Json$Encode$bool(componentDef.hT)),
				_Utils_Tuple2(
				'attributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute, componentDef.ba)),
				_Utils_Tuple2(
				'componentParameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, componentDef.cG))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeInputViewComponentSubmitAction = function (action) {
	switch (action.$) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('none'))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('viewDefault'))
					]));
		default:
			var customAction = action.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('custom')),
						_Utils_Tuple2(
						'action',
						$author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction(customAction))
					]));
	}
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction = function (action) {
	switch (action.$) {
		case 0:
			var action_ = action.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeCommandExecutionAction(action_);
		case 1:
			var action_ = action.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewAction(action_);
		case 2:
			var action_ = action.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeOpenUrlAction(action_);
		case 3:
			var action_ = action.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeDialogAction(action_);
		case 4:
			var action_ = action.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeScriptAction(action_);
		default:
			var action_ = action.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeSequenceAction(action_);
	}
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeSequenceAction = function (action) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('sequenceAction')),
				_Utils_Tuple2(
				'actions',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, action.hA)),
				_Utils_Tuple2(
				'predicate',
				$author$project$Encoding$PredicateEncoding$encodePredicate(action.as))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeTextViewComponent = function (componentDef) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string('text')),
				_Utils_Tuple2(
				'contentClause',
				$author$project$Encoding$ClauseEncoding$encodeClause(componentDef.iC)),
				_Utils_Tuple2(
				'useMarkdown',
				$elm$json$Json$Encode$bool(componentDef.mR)),
				_Utils_Tuple2(
				'useHtml',
				$elm$json$Json$Encode$bool(componentDef.mQ)),
				_Utils_Tuple2(
				'clickAction',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, componentDef.f$)),
				_Utils_Tuple2(
				'doubleClickAction',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, componentDef.ga)),
				_Utils_Tuple2(
				'contextMenuComponents',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition, componentDef.f4)),
				_Utils_Tuple2(
				'dropZoneConfig',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, componentDef.cV)),
				_Utils_Tuple2(
				'dragEnabled',
				$elm$json$Json$Encode$bool(componentDef.i4)),
				_Utils_Tuple2(
				'cssCode',
				$elm$json$Json$Encode$string(componentDef.bm)),
				_Utils_Tuple2(
				'attributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute, componentDef.ba)),
				_Utils_Tuple2(
				'componentParameterClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, componentDef.cG))
			]));
};
var $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition = function (def) {
	switch (def.$) {
		case 0:
			var def_ = def.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeButtonViewComponent(def_);
		case 1:
			var def_ = def.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeInputViewComponent(def_);
		case 2:
			var def_ = def.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeTextViewComponent(def_);
		case 3:
			var def_ = def.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeContainerComponent(def_);
		default:
			var def_ = def.a;
			return $author$project$Encoding$ViewComponentAndActionEncoding$encodeGeneratorComponent(def_);
	}
};
var $author$project$Encoding$CommandEncoding$encodeAavoActionBinding = function (_v0) {
	var bindKey = _v0.fW;
	var selectItemOnClick = _v0.l_;
	var action = _v0.fO;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bindKey',
				$elm$json$Json$Encode$string(bindKey)),
				_Utils_Tuple2(
				'selectItemOnClick',
				$elm$json$Json$Encode$bool(selectItemOnClick)),
				_Utils_Tuple2(
				'action',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction(action))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeActionRequest = function (_v0) {
	var actionRequest = _v0.hu;
	return _Utils_Tuple2(
		'actionRequest',
		A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, actionRequest));
};
var $author$project$Encoding$CommandEncoding$encodeActionRequestsClause = function (_v0) {
	var actionRequestsClause = _v0.hw;
	return _Utils_Tuple2(
		'actionRequestsClause',
		A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, actionRequestsClause));
};
var $author$project$Encoding$CommandEncoding$encodeChartCommandContextMenuBinding = function (_v0) {
	var bindKey = _v0.fW;
	var menuComponents = _v0.kO;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'bindKey',
				$elm$json$Json$Encode$string(bindKey)),
				_Utils_Tuple2(
				'menuComponents',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition, menuComponents))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeChartCommandReturnType = function (chartCommandReturnType) {
	if (!chartCommandReturnType.$) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('json'))
				]));
	} else {
		var fileNameClause = chartCommandReturnType.a.jG;
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'type',
					$elm$json$Json$Encode$string('exportedFile')),
					_Utils_Tuple2(
					'fileNameClause',
					$author$project$Encoding$ClauseEncoding$encodeClause(fileNameClause))
				]));
	}
};
var $author$project$Encoding$CommandEncoding$encodeChartCommandSeriesConfig = function (config) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'mainClause',
				$author$project$Encoding$ClauseEncoding$encodeClause(config.gC)),
				_Utils_Tuple2(
				'dataClause',
				$author$project$Encoding$ClauseEncoding$encodeClause(config.eO))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeDataViewColumnDefinition = function (column) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'fieldDefinition',
				$author$project$Encoding$InputFieldEncoding$encodeInputFieldDefinition(column.ki)),
				_Utils_Tuple2(
				'isHidden',
				$elm$json$Json$Encode$bool(column.kp)),
				_Utils_Tuple2(
				'isEditable',
				$elm$json$Json$Encode$bool(column.jg)),
				_Utils_Tuple2(
				'cellRenderer',
				$elm$json$Json$Encode$string(
					$author$project$Utils$ToStrings$dataViewCellRendererToString(column.h6))),
				_Utils_Tuple2(
				'useValuesAsParameters',
				$elm$json$Json$Encode$bool(column.mT))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeDataViewCommandInsertConfiguration = function (_v0) {
	var directInsertClause = _v0.i0;
	var formInsertEnabled = _v0.jN;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'directInsertClause',
				A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, directInsertClause)),
				_Utils_Tuple2(
				'formInsertEnabled',
				$elm$json$Json$Encode$bool(formInsertEnabled))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeDataViewRowClassRule = function (rule) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'className',
				$elm$json$Json$Encode$string(rule.ic)),
				_Utils_Tuple2(
				'columnName',
				$elm$json$Json$Encode$string(rule.ih)),
				_Utils_Tuple2(
				'rule',
				$elm$json$Json$Encode$string(rule.lV))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeCommandSpec = function (untypedSpec) {
	return $elm$json$Json$Encode$object(
		function () {
			switch (untypedSpec.$) {
				case 0:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('singleClause')),
							_Utils_Tuple2(
							'clause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.eH)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 1:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('dataView')),
							_Utils_Tuple2(
							'fetchDataClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.jD)),
							_Utils_Tuple2(
							'deleteClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.iY)),
							_Utils_Tuple2(
							'updateClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.hn)),
							_Utils_Tuple2(
							'insertConfig',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$CommandEncoding$encodeDataViewCommandInsertConfiguration, spec.kl)),
							_Utils_Tuple2(
							'staticColumnDefinitions',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeDataViewColumnDefinition, spec.ml)),
							_Utils_Tuple2(
							'defineColumnsByData',
							$elm$json$Json$Encode$bool(spec.iX)),
							_Utils_Tuple2(
							'dynamicColumnDefinitionsClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.jb)),
							_Utils_Tuple2(
							'classRules',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeDataViewRowClassRule, spec.lT)),
							_Utils_Tuple2(
							'dynamicClassRulesClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.ja)),
							_Utils_Tuple2(
							'selectionMode',
							$elm$json$Json$Encode$string(
								$author$project$Utils$ToStrings$dataViewSelectionModeToString(spec.l0))),
							_Utils_Tuple2(
							'paginationMode',
							$elm$json$Json$Encode$string(
								$author$project$Utils$ToStrings$dataViewPaginationModeToString(spec.lf))),
							_Utils_Tuple2(
							'draggableRows',
							$elm$json$Json$Encode$bool(spec.i7)),
							_Utils_Tuple2(
							'rowDropZoneConfig',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, spec.lU)),
							_Utils_Tuple2(
							'idColumnName',
							A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, spec.ka)),
							_Utils_Tuple2(
							'selectFirstRowByDefault',
							$elm$json$Json$Encode$bool(spec.lY)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 2:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('form')),
							_Utils_Tuple2(
							'updateClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.hn)),
							_Utils_Tuple2(
							'insertClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.kk)),
							_Utils_Tuple2(
							'postSubmitAction',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, spec.lq)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 3:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('productionLine')),
							_Utils_Tuple2(
							'dataClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.eO)),
							_Utils_Tuple2(
							'boxStylingRulesClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.h2)),
							_Utils_Tuple2(
							'timeStylingRulesClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.mz)),
							_Utils_Tuple2(
							'boxOrderingClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.h1)),
							_Utils_Tuple2(
							'boxesPerRowClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.h3)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 4:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('mail')),
							_Utils_Tuple2(
							'subjectClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.mo)),
							_Utils_Tuple2(
							'contentClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.iC)),
							_Utils_Tuple2(
							'receiversClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.gU)),
							_Utils_Tuple2(
							'resultClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.fu)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 5:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('notification')),
							_Utils_Tuple2(
							'titleClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.mD)),
							_Utils_Tuple2(
							'bodyClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.h0)),
							_Utils_Tuple2(
							'receiversClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.gU)),
							_Utils_Tuple2(
							'resultClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.fu)),
							_Utils_Tuple2(
							'action',
							$author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction(spec.fO)),
							_Utils_Tuple2(
							'imageClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.ke)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 6:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('downloadFile')),
							_Utils_Tuple2(
							'filePathClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.jH)),
							_Utils_Tuple2(
							'tryConvertToPdf',
							$elm$json$Json$Encode$bool(spec.mK)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 7:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('uploadFiles')),
							_Utils_Tuple2(
							'targetFolderPathClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.mt)),
							_Utils_Tuple2(
							'postUploadClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.lr)),
							_Utils_Tuple2(
							'resultClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.fu)),
							_Utils_Tuple2(
							'filesParameterName',
							$elm$json$Json$Encode$string(spec.jJ)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 8:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('report')),
							_Utils_Tuple2(
							'templateFileCommandCall',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$CommandCallDefinitionEncoding$encodeCommandCallDefinition, spec.mx)),
							_Utils_Tuple2(
							'templateFileClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.mw)),
							_Utils_Tuple2(
							'dataClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.eO)),
							_Utils_Tuple2(
							'resultFileNameClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.lL)),
							_Utils_Tuple2(
							'reportTimeZoneId',
							$elm$json$Json$Encode$string(spec.lF)),
							_Utils_Tuple2(
							'reportLocale',
							$elm$json$Json$Encode$string(spec.lE)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 9:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('chart')),
							_Utils_Tuple2(
							'chartBaseType',
							$elm$json$Json$Encode$string(
								$author$project$Utils$ToStrings$chartBaseTypeToString(spec.h8))),
							_Utils_Tuple2(
							'mainClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.gC)),
							_Utils_Tuple2(
							'series',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeChartCommandSeriesConfig, spec.l3)),
							_Utils_Tuple2(
							'actionBindings',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeAavoActionBinding, spec.fP)),
							_Utils_Tuple2('chartEventHandlers', spec.h9),
							_Utils_Tuple2(
							'contextMenuBindings',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeChartCommandContextMenuBinding, spec.iD)),
							_Utils_Tuple2(
							'returnType',
							$author$project$Encoding$CommandEncoding$encodeChartCommandReturnType(spec.lQ)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 10:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('diagram')),
							_Utils_Tuple2(
							'markdownClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.kG)),
							_Utils_Tuple2(
							'actionBindings',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeAavoActionBinding, spec.fP)),
							_Utils_Tuple2(
							'contextMenuBindings',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeChartCommandContextMenuBinding, spec.f5)),
							_Utils_Tuple2(
							'nodeParametersClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.k0)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 11:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('treeView')),
							_Utils_Tuple2(
							'elementsClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.jl)),
							_Utils_Tuple2(
							'actions',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeAavoActionBinding, spec.hA)),
							_Utils_Tuple2(
							'contextMenus',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandEncoding$encodeChartCommandContextMenuBinding, spec.f5)),
							_Utils_Tuple2(
							'onElementSelectionChanged',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, spec.k5)),
							_Utils_Tuple2(
							'onElementClick',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, spec.k3)),
							_Utils_Tuple2(
							'onElementDoubleClick',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, spec.k4)),
							_Utils_Tuple2(
							'elementDropZoneConfig',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, spec.jj)),
							_Utils_Tuple2(
							'elementGapDropZoneConfig',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeElementDropZoneConfig, spec.jk)),
							_Utils_Tuple2(
							'draggableElements',
							$elm$json$Json$Encode$bool(spec.i6)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 12:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('calendar')),
							_Utils_Tuple2(
							'eventsClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.jx)),
							_Utils_Tuple2(
							'resourcesClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.lK)),
							_Utils_Tuple2(
							'eventEditedAction',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, spec.jv)),
							_Utils_Tuple2(
							'eventClickAction',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction, spec.jt)),
							_Utils_Tuple2(
							'eventContextMenu',
							A2(
								$author$project$Encoding$Utils$encodeMaybe,
								$elm$json$Json$Encode$list($author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition),
								spec.ju)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 13:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('quickSight')),
							_Utils_Tuple2(
							'dashboardIdClause',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.iN)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
				case 14:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('cron')),
							_Utils_Tuple2(
							'cronExpression',
							$elm$json$Json$Encode$string(spec.iK)),
							_Utils_Tuple2(
							'firstExecutionTime',
							$elm$json$Json$Encode$string(spec.jL)),
							_Utils_Tuple2(
							'clauseToExecute',
							$author$project$Encoding$ClauseEncoding$encodeClause(spec.id)),
							_Utils_Tuple2(
							'doNotLogIfNoMatch',
							$elm$json$Json$Encode$bool(spec.i3))
						]);
				default:
					var spec = untypedSpec.a;
					return _List_fromArray(
						[
							_Utils_Tuple2(
							'type',
							$elm$json$Json$Encode$string('viewComponents')),
							_Utils_Tuple2(
							'componentDefinitions',
							A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentDefinition, spec.ir)),
							_Utils_Tuple2(
							'forcedValuesForExistingRecordClause',
							A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ClauseEncoding$encodeClause, spec.jM)),
							$author$project$Encoding$CommandEncoding$encodeActionRequestsClause(spec),
							$author$project$Encoding$CommandEncoding$encodeActionRequest(spec)
						]);
			}
		}());
};
var $author$project$Encoding$ConnectionEncoding$encodeConnectionReferenceSet = function (set) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'dbConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, set.iQ)),
				_Utils_Tuple2(
				'httpConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, set.j7)),
				_Utils_Tuple2(
				'fileServerConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, set.jI)),
				_Utils_Tuple2(
				'mailServerConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, set.kE)),
				_Utils_Tuple2(
				'awsConnectionId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, set.hW))
			]));
};
var $author$project$Encoding$CommandEncoding$encodeCommand = function (cmd) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'key',
				$elm$json$Json$Encode$string(cmd.kt)),
				_Utils_Tuple2(
				'connections',
				$author$project$Encoding$ConnectionEncoding$encodeConnectionReferenceSet(cmd.iy)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(cmd.i$)),
				_Utils_Tuple2(
				'groupId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, cmd.j_)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(cmd.dM)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, cmd.hM)),
				_Utils_Tuple2(
				'idAnchor',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, cmd.j9)),
				_Utils_Tuple2(
				'extraInputFieldDefinitions',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$InputFieldEncoding$encodeInputFieldDefinition, cmd.jC)),
				_Utils_Tuple2(
				'parameterMappings',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, cmd.li)),
				_Utils_Tuple2(
				'baseClauses',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ClauseEncoding$encodeParameterClause, cmd.hZ)),
				_Utils_Tuple2(
				'timeoutSeconds',
				$elm$json$Json$Encode$int(cmd.mB)),
				_Utils_Tuple2(
				'specification',
				$author$project$Encoding$CommandEncoding$encodeCommandSpec(cmd.mh)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(cmd.db))
			]));
};
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $lukewestby$elm_http_builder$HttpBuilder$withBody = F2(
	function (body, builder) {
		return _Utils_update(
			builder,
			{h$: body});
	});
var $lukewestby$elm_http_builder$HttpBuilder$withJsonBody = function (value) {
	return $lukewestby$elm_http_builder$HttpBuilder$withBody(
		$elm$http$Http$jsonBody(value));
};
var $author$project$Api$EntityApi$postEntity = F6(
	function (url, encoder, decoder, _v0, entity, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, decoder),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					$author$project$Api$ApiUrls$aavoBranchHeader,
					selectedBranchKey,
					A2(
						$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
						encoder(entity),
						$lukewestby$elm_http_builder$HttpBuilder$post(url)))));
	});
var $author$project$Api$EntityApi$postCommand = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$commandsUrl, $author$project$Encoding$CommandEncoding$encodeCommand, $author$project$Decoding$CommandDecoding$commandDecoder);
var $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup = function (group) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(group.k_)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(group.i$)),
				_Utils_Tuple2(
				'parentId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, group.fk)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(group.dM)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(group.db)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, group.hM))
			]));
};
var $author$project$Api$EntityApi$postCommandGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$commandGroupsUrl, $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Api$EntityApi$encodesaveEntityPositionRequest = function (_v0) {
	var id = _v0.db;
	var pos = _v0.dM;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(id)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(pos))
			]));
};
var $lukewestby$elm_http_builder$HttpBuilder$put = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('PUT');
var $author$project$Api$EntityApi$saveEntityPosition = F4(
	function (url, _v0, _v1, toMsg) {
		var selectedBranchKey = _v0.l$;
		var id = _v1.db;
		var pos = _v1.dM;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$author$project$Lib$CustomHttp$expectWhateverCustom(toMsg),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
					$author$project$Api$EntityApi$encodesaveEntityPositionRequest(
						{db: id, dM: pos}),
					A3(
						$lukewestby$elm_http_builder$HttpBuilder$withHeader,
						$author$project$Api$ApiUrls$aavoBranchHeader,
						selectedBranchKey,
						$lukewestby$elm_http_builder$HttpBuilder$put(
							A2(
								$elm$url$Url$Builder$relative,
								_List_fromArray(
									[url, 'position']),
								_List_Nil))))));
	});
var $author$project$Api$EntityApi$saveCommandGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$commandGroupsUrl);
var $author$project$Api$EntityApi$saveCommandPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$commandsUrl);
var $author$project$Pages$Main$CommandsView$Confirm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Lib$Form$FormView$setStatusTo = F2(
	function (state, model) {
		return _Utils_update(
			model,
			{K: state});
	});
var $author$project$Pages$Main$CommandsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			switch (_v1.$) {
				case 3:
					var formModel = _v1.a;
					return $author$project$Pages$Main$CommandsView$AddNewCommandModal(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var config = _v1.a;
					return $author$project$Pages$Main$CommandsView$Confirm(
						_Utils_update(
							config,
							{ha: status}));
				default:
					return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Lib$Form$FormView$setFocusedCodeFieldValue = F2(
	function (newValue, model) {
		var _v0 = model.am;
		if (!_v0.$) {
			var codeField = _v0.a;
			return _Utils_update(
				model,
				{
					am: $elm$core$Maybe$Just(
						_Utils_update(
							codeField,
							{mY: newValue})),
					hr: codeField.a(newValue)
				});
		} else {
			return model;
		}
	});
var $author$project$Pages$Main$EditEntityOrGroupModel$setFocusedCodeFieldValue = F2(
	function (model, value) {
		if (model.$ === 1) {
			var formModel = model.a;
			return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
				A2($author$project$Lib$Form$FormView$setFocusedCodeFieldValue, value, formModel));
		} else {
			return model;
		}
	});
var $author$project$Pages$Main$CommandsView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$SubViewUpdateUtils$withoutActions = function (model) {
	return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
};
var $author$project$Pages$Main$CommandsView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$CommandsView$Confirm(
						{h4: $author$project$Pages$Main$CommandsView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewEntityOutMsg = {$: 4};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewGroupOutMsg = {$: 5};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditEntityOutMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditGroupOutMsg = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginRefreshOutMsg = {$: 1};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$ConfirmDeleteEntity = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$ConfirmDeleteGroup = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$OutMsgNoOp = {$: 0};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$SetEntityPositionOutMsg = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$SetGroupPositionOutMsg = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Pages$Main$EntityListing$TreeElement$getPositionOfElement = function (element) {
	if (!element.$) {
		var entity = element.a;
		return entity.dM;
	} else {
		var group = element.a;
		return group.dM;
	}
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$getNewPositionOnAfterDropToElementSpanArea = F2(
	function (element, lowerElement) {
		return (_Utils_cmp(
			$author$project$Pages$Main$EntityListing$TreeElement$getPositionOfElement(lowerElement),
			element.dM) < 0) ? $author$project$Pages$Main$EntityListing$TreeElement$getPositionOfElement(lowerElement) : ((_Utils_cmp(
			$author$project$Pages$Main$EntityListing$TreeElement$getPositionOfElement(lowerElement),
			element.dM) > 0) ? ($author$project$Pages$Main$EntityListing$TreeElement$getPositionOfElement(lowerElement) - 1) : element.dM);
	});
var $author$project$Pages$Main$EntityListing$TreeElement$getParentIdOfElement = function (element) {
	if (!element.$) {
		var entity = element.a;
		return entity.j_;
	} else {
		var group = element.a;
		return group.fk;
	}
};
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$getPositionOfLastChildOfGroup = F3(
	function (config, repository, groupId) {
		var _v0 = _Utils_Tuple2(
			config.gp(repository),
			config.go(repository));
		if ((_v0.a.$ === 3) && (_v0.b.$ === 3)) {
			var groups = _v0.a.a;
			var entities = _v0.b.a;
			var childrenPositions = _Utils_ap(
				A2(
					$elm$core$List$map,
					function ($) {
						return $.dM;
					},
					A2(
						$elm$core$List$filter,
						function (e) {
							return _Utils_eq(e.j_, groupId);
						},
						$elm$core$Dict$values(entities))),
				A2(
					$elm$core$List$map,
					function ($) {
						return $.dM;
					},
					A2(
						$elm$core$List$filter,
						function (g) {
							return _Utils_eq(g.fk, groupId);
						},
						$elm$core$Dict$values(groups))));
			return A2(
				$elm$core$Maybe$withDefault,
				-1,
				$elm$core$List$maximum(childrenPositions));
		} else {
			return -1;
		}
	});
var $author$project$Lib$DragDrop$DraggedOver = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $author$project$Lib$DragDrop$Dragging = function (a) {
	return {$: 1, a: a};
};
var $author$project$Lib$DragDrop$updateCommon = F3(
	function (sticky, msg, model) {
		var _v0 = _Utils_Tuple3(msg, model, sticky);
		_v0$9:
		while (true) {
			switch (_v0.a.$) {
				case 0:
					var _v1 = _v0.a;
					var dragId = _v1.a;
					return _Utils_Tuple2(
						$author$project$Lib$DragDrop$Dragging(dragId),
						$elm$core$Maybe$Nothing);
				case 1:
					var _v2 = _v0.a;
					return _Utils_Tuple2($author$project$Lib$DragDrop$NotDragging, $elm$core$Maybe$Nothing);
				case 2:
					switch (_v0.b.$) {
						case 1:
							var dropId = _v0.a.a;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								A4($author$project$Lib$DragDrop$DraggedOver, dragId, dropId, 0, $elm$core$Maybe$Nothing),
								$elm$core$Maybe$Nothing);
						case 2:
							var dropId = _v0.a.a;
							var _v3 = _v0.b;
							var dragId = _v3.a;
							var pos = _v3.d;
							return _Utils_Tuple2(
								A4($author$project$Lib$DragDrop$DraggedOver, dragId, dropId, 0, pos),
								$elm$core$Maybe$Nothing);
						default:
							break _v0$9;
					}
				case 3:
					if ((_v0.b.$ === 2) && (!_v0.c)) {
						var dropId_ = _v0.a.a;
						var _v4 = _v0.b;
						var dragId = _v4.a;
						var dropId = _v4.b;
						return _Utils_eq(dropId_, dropId) ? _Utils_Tuple2(
							$author$project$Lib$DragDrop$Dragging(dragId),
							$elm$core$Maybe$Nothing) : _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
					} else {
						break _v0$9;
					}
				case 4:
					switch (_v0.b.$) {
						case 1:
							var _v5 = _v0.a;
							var dropId = _v5.a;
							var timeStamp = _v5.b;
							var pos = _v5.c;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								A4(
									$author$project$Lib$DragDrop$DraggedOver,
									dragId,
									dropId,
									timeStamp,
									$elm$core$Maybe$Just(pos)),
								$elm$core$Maybe$Nothing);
						case 2:
							var _v6 = _v0.a;
							var dropId = _v6.a;
							var timeStamp = _v6.b;
							var pos = _v6.c;
							var _v7 = _v0.b;
							var dragId = _v7.a;
							var currentDropId = _v7.b;
							var currentTimeStamp = _v7.c;
							var currentPos = _v7.d;
							return _Utils_eq(timeStamp, currentTimeStamp) ? _Utils_Tuple2(model, $elm$core$Maybe$Nothing) : ((_Utils_eq(
								$elm$core$Maybe$Just(pos),
								currentPos) && _Utils_eq(dropId, currentDropId)) ? _Utils_Tuple2(model, $elm$core$Maybe$Nothing) : _Utils_Tuple2(
								A4(
									$author$project$Lib$DragDrop$DraggedOver,
									dragId,
									dropId,
									timeStamp,
									$elm$core$Maybe$Just(pos)),
								$elm$core$Maybe$Nothing));
						default:
							break _v0$9;
					}
				default:
					switch (_v0.b.$) {
						case 1:
							var _v8 = _v0.a;
							var dropId = _v8.a;
							var pos = _v8.b;
							var dragId = _v0.b.a;
							return _Utils_Tuple2(
								$author$project$Lib$DragDrop$NotDragging,
								$elm$core$Maybe$Just(
									_Utils_Tuple3(dragId, dropId, pos)));
						case 2:
							var _v9 = _v0.a;
							var dropId = _v9.a;
							var pos = _v9.b;
							var _v10 = _v0.b;
							var dragId = _v10.a;
							return _Utils_Tuple2(
								$author$project$Lib$DragDrop$NotDragging,
								$elm$core$Maybe$Just(
									_Utils_Tuple3(dragId, dropId, pos)));
						default:
							break _v0$9;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Maybe$Nothing);
	});
var $author$project$Lib$DragDrop$update = $author$project$Lib$DragDrop$updateCommon(false);
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$updateOnDragDropCommandTreeItemMsg = F4(
	function (config, repository, model, msg) {
		var _v0 = A2($author$project$Lib$DragDrop$update, msg, model.ag);
		var dragDropModel = _v0.a;
		var dropResult = _v0.b;
		var saveOutMsg = function () {
			_v1$6:
			while (true) {
				if (!dropResult.$) {
					if (!dropResult.a.a.$) {
						switch (dropResult.a.b.$) {
							case 2:
								var _v2 = dropResult.a;
								var entity = _v2.a.a;
								var group = _v2.b.a;
								return A2(
									$author$project$Pages$Main$EntityListing$GenericEntityListingView$SetEntityPositionOutMsg,
									_Utils_update(
										entity,
										{
											j_: $elm$core$Maybe$Just(group.db),
											dM: A3(
												$author$project$Pages$Main$EntityListing$GenericEntityListingView$getPositionOfLastChildOfGroup,
												config,
												repository,
												$elm$core$Maybe$Just(group.db))
										}),
									{gJ: entity.dM});
							case 1:
								var _v4 = dropResult.a;
								var entity = _v4.a.a;
								var _v5 = _v4.b;
								return A2(
									$author$project$Pages$Main$EntityListing$GenericEntityListingView$SetEntityPositionOutMsg,
									_Utils_update(
										entity,
										{
											j_: $elm$core$Maybe$Nothing,
											dM: A3($author$project$Pages$Main$EntityListing$GenericEntityListingView$getPositionOfLastChildOfGroup, config, repository, $elm$core$Maybe$Nothing)
										}),
									{gJ: entity.dM});
							case 3:
								var _v8 = dropResult.a;
								var entity = _v8.a.a;
								var lowerElement = _v8.b.a.$7;
								return A2(
									$author$project$Pages$Main$EntityListing$GenericEntityListingView$SetEntityPositionOutMsg,
									_Utils_update(
										entity,
										{
											j_: $author$project$Pages$Main$EntityListing$TreeElement$getParentIdOfElement(lowerElement),
											dM: A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$getNewPositionOnAfterDropToElementSpanArea, entity, lowerElement)
										}),
									{gJ: entity.dM});
							default:
								break _v1$6;
						}
					} else {
						switch (dropResult.a.b.$) {
							case 2:
								var _v3 = dropResult.a;
								var group = _v3.a.a;
								var parentGroup = _v3.b.a;
								return A2(
									$author$project$Pages$Main$EntityListing$GenericEntityListingView$SetGroupPositionOutMsg,
									_Utils_update(
										group,
										{
											fk: $elm$core$Maybe$Just(parentGroup.db),
											dM: A3(
												$author$project$Pages$Main$EntityListing$GenericEntityListingView$getPositionOfLastChildOfGroup,
												config,
												repository,
												$elm$core$Maybe$Just(group.db))
										}),
									{gJ: group.dM});
							case 1:
								var _v6 = dropResult.a;
								var group = _v6.a.a;
								var _v7 = _v6.b;
								return A2(
									$author$project$Pages$Main$EntityListing$GenericEntityListingView$SetGroupPositionOutMsg,
									_Utils_update(
										group,
										{
											fk: $elm$core$Maybe$Nothing,
											dM: A3($author$project$Pages$Main$EntityListing$GenericEntityListingView$getPositionOfLastChildOfGroup, config, repository, $elm$core$Maybe$Nothing)
										}),
									{gJ: group.dM});
							case 3:
								var _v9 = dropResult.a;
								var group = _v9.a.a;
								var lowerElement = _v9.b.a.$7;
								return A2(
									$author$project$Pages$Main$EntityListing$GenericEntityListingView$SetGroupPositionOutMsg,
									_Utils_update(
										group,
										{
											fk: $author$project$Pages$Main$EntityListing$TreeElement$getParentIdOfElement(lowerElement),
											dM: A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$getNewPositionOnAfterDropToElementSpanArea, group, lowerElement)
										}),
									{gJ: group.dM});
							default:
								break _v1$6;
						}
					}
				} else {
					break _v1$6;
				}
			}
			return $author$project$Pages$Main$EntityListing$GenericEntityListingView$OutMsgNoOp;
		}();
		return _Utils_Tuple3(
			_Utils_update(
				model,
				{ag: dragDropModel}),
			$elm$core$Platform$Cmd$none,
			saveOutMsg);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$update = F4(
	function (config, repository, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$EntityListing$GenericEntityListingView$OutMsgNoOp);
			case 1:
				var newState = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{en: newState}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$EntityListing$GenericEntityListingView$OutMsgNoOp);
			case 2:
				var filterId = msg.a;
				var value = msg.b;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aJ: A3($elm$core$Dict$insert, filterId, value, model.aJ)
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$EntityListing$GenericEntityListingView$OutMsgNoOp);
			case 3:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginRefreshOutMsg);
			case 4:
				var commandOrGroup = msg.a;
				var outMsg = function () {
					if (!commandOrGroup.$) {
						var entity = commandOrGroup.a;
						return $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditEntityOutMsg(entity);
					} else {
						var group = commandOrGroup.a;
						return $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditGroupOutMsg(group);
					}
				}();
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, outMsg);
			case 6:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewEntityOutMsg);
			case 7:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewGroupOutMsg);
			case 5:
				var commandOrGroup = msg.a;
				var outMsg = function () {
					if (!commandOrGroup.$) {
						var entity = commandOrGroup.a;
						return $author$project$Pages$Main$EntityListing$GenericEntityListingView$ConfirmDeleteEntity(entity);
					} else {
						var group = commandOrGroup.a;
						return $author$project$Pages$Main$EntityListing$GenericEntityListingView$ConfirmDeleteGroup(group);
					}
				}();
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, outMsg);
			case 8:
				var element = msg.a;
				var newSelection = _Utils_eq(
					model.J,
					$elm$core$Maybe$Just(element)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(element);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{J: newSelection}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$EntityListing$GenericEntityListingView$OutMsgNoOp);
			default:
				var dragDropMsg = msg.a;
				return A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$updateOnDragDropCommandTreeItemMsg, config, repository, model, dragDropMsg);
		}
	});
var $author$project$Pages$Main$EntityListing$PositionUpdating$decrementPositionIfHigherThan = F2(
	function (limit, resource) {
		return (_Utils_cmp(resource.dM, limit) > 0) ? _Utils_update(
			resource,
			{dM: resource.dM - 1}) : resource;
	});
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Utils$DictUtils$mapValues = F2(
	function (_function, dict) {
		return A2(
			$elm$core$Dict$map,
			F2(
				function (_v0, v) {
					return _function(v);
				}),
			dict);
	});
var $author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete = F3(
	function (config, repository, deletedPos) {
		var _v0 = _Utils_Tuple2(
			config.go(repository),
			config.gp(repository));
		if ((_v0.a.$ === 3) && (_v0.b.$ === 3)) {
			var entities = _v0.a.a;
			var groups = _v0.b.a;
			var groups_ = A2(
				$author$project$Utils$DictUtils$mapValues,
				$author$project$Pages$Main$EntityListing$PositionUpdating$decrementPositionIfHigherThan(deletedPos),
				groups);
			var entities_ = A2(
				$author$project$Utils$DictUtils$mapValues,
				$author$project$Pages$Main$EntityListing$PositionUpdating$decrementPositionIfHigherThan(deletedPos),
				entities);
			return A2(
				config.l7,
				groups_,
				A2(config.l6, entities_, repository));
		} else {
			return repository;
		}
	});
var $author$project$Entity$Common$entityOrGroupsEquals = F2(
	function (a, b) {
		var _v0 = _Utils_Tuple2(a, b);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var a_ = _v0.a.a;
					var b_ = _v0.b.a;
					return _Utils_eq(a_.db, b_.db);
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b.$ === 1) {
					var a_ = _v0.a.a;
					var b_ = _v0.b.a;
					return _Utils_eq(a_.db, b_.db);
				} else {
					break _v0$2;
				}
			}
		}
		return false;
	});
var $author$project$Pages$Main$EntityListing$PositionUpdating$getEntityOrGroupPosition = function (entityOrGroup) {
	if (!entityOrGroup.$) {
		var entity = entityOrGroup.a;
		return entity.dM;
	} else {
		var entityGroup = entityOrGroup.a;
		return entityGroup.dM;
	}
};
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionIfInRange = F3(
	function (oldPos, newPos, resource) {
		return (_Utils_cmp(newPos, oldPos) < 0) ? (((_Utils_cmp(resource.dM, newPos) > -1) && (_Utils_cmp(resource.dM, oldPos) < 0)) ? _Utils_update(
			resource,
			{dM: resource.dM + 1}) : resource) : ((_Utils_cmp(newPos, oldPos) > 0) ? (((_Utils_cmp(resource.dM, newPos) < 1) && (_Utils_cmp(resource.dM, oldPos) > 0)) ? _Utils_update(
			resource,
			{dM: resource.dM - 1}) : resource) : resource);
	});
var $author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove = F4(
	function (config, repository, oldPos, updatedResource) {
		var _v0 = _Utils_Tuple2(
			config.go(repository),
			config.gp(repository));
		if ((_v0.a.$ === 3) && (_v0.b.$ === 3)) {
			var entities = _v0.a.a;
			var groups = _v0.b.a;
			var newPos = $author$project$Pages$Main$EntityListing$PositionUpdating$getEntityOrGroupPosition(updatedResource);
			var groups_ = A2(
				$author$project$Utils$DictUtils$mapValues,
				function (g) {
					return A2(
						$author$project$Entity$Common$entityOrGroupsEquals,
						updatedResource,
						$author$project$Entity$Common$TGroup(g)) ? _Utils_update(
						g,
						{dM: newPos}) : A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionIfInRange, oldPos, newPos, g);
				},
				groups);
			var entities_ = A2(
				$author$project$Utils$DictUtils$mapValues,
				function (e) {
					return A2(
						$author$project$Entity$Common$entityOrGroupsEquals,
						updatedResource,
						$author$project$Entity$Common$TEntity(e)) ? _Utils_update(
						e,
						{dM: newPos}) : A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionIfInRange, oldPos, newPos, e);
				},
				entities);
			return A2(
				config.l7,
				groups_,
				A2(config.l6, entities_, repository));
		} else {
			return repository;
		}
	});
var $author$project$Pages$Main$CommandsView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$CommandsView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$CommandsView$BeginEditCommand(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$CommandsView$BeginEditGroup(group));
			case 4:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$CommandsView$AddNewCommandModal(
								$author$project$Lib$Form$FormView$makeModel($author$project$Forms$CreateNewCommandForm$createNewCommandFormValues))
						}));
			case 5:
				return newMsg(
					$author$project$Pages$Main$CommandsView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var command = outMsg.a;
				return A3(
					$author$project$Pages$Main$CommandsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa komennon ' + (command.kt + '?'),
					$author$project$Pages$Main$CommandsView$BeginDeleteCommand(command));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$CommandsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$CommandsView$BeginDeleteGroup(group));
			case 8:
				var command = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$CommandsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(command));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$CommandsView$update, apiConfig, repo),
					$author$project$Pages$Main$CommandsView$BeginSaveCommand(command),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveCommandPosition,
							apiConfig,
							command,
							$elm$core$Basics$always($author$project$Pages$Main$CommandsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$CommandsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$CommandsView$update, apiConfig, repo),
					$author$project$Pages$Main$CommandsView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveCommandGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$CommandsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$CommandsView$update = F4(
	function (apiConfig, repository, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
				case 1:
					var listingViewMsg = msg.a;
					var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$CommandsView$listingViewConfig, repository, listingViewMsg, model.ap);
					var listingViewModel = _v1.a;
					var listingViewCmd = _v1.b;
					var listingViewOutMsg = _v1.c;
					return A5(
						$author$project$Pages$Main$CommandsView$handleListingViewOutMsg,
						apiConfig,
						repository,
						_Utils_update(
							model,
							{ap: listingViewModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$CommandsView$ListingViewMsg, listingViewCmd),
						listingViewOutMsg);
				case 4:
					var command = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
									$author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$CommandForms$CommandForm$createCommandFormValues(command)))
							}));
				case 5:
					var group = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
									$author$project$Lib$Form$FormView$makeModel(group))
							}));
				case 2:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{bO: $author$project$Pages$Main$CommandsView$Hidden}));
				case 6:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
							}));
				case 7:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
							}));
				case 8:
					var newFormModel = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								bO: $author$project$Pages$Main$CommandsView$AddNewCommandModal(newFormModel)
							}));
				case 9:
					var command = msg.a;
					var $temp$apiConfig = apiConfig,
						$temp$repository = repository,
						$temp$msg = $author$project$Pages$Main$CommandsView$BeginEditCommand(
						_Utils_update(
							command,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})),
						$temp$model = _Utils_update(
						model,
						{bO: $author$project$Pages$Main$CommandsView$Hidden});
					apiConfig = $temp$apiConfig;
					repository = $temp$repository;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 10:
					var code = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: A2($author$project$Pages$Main$EditEntityOrGroupModel$setFocusedCodeFieldValue, model.j, code)
							}));
				case 3:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
				case 11:
					var command = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$CommandsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postCommand, apiConfig, command, $author$project$Pages$Main$CommandsView$GotPostCommand),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 12:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$CommandsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postCommandGroup, apiConfig, group, $author$project$Pages$Main$CommandsView$GotPostGroup),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 13:
					var command = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$CommandsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteCommand, apiConfig, command.db, $author$project$Pages$Main$CommandsView$GotDeleteCommand),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$CommandsView$positionUpdatingConfig, repository, command.dM)));
				case 14:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$CommandsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteCommandGroup, apiConfig, group.db, $author$project$Pages$Main$CommandsView$GotDeleteGroup),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$CommandsView$positionUpdatingConfig, repository, group.dM)));
				case 17:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$CommandsView$onGotPostResult,
						{
							fa: A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel, $author$project$Lib$Form$FormView$makeModel),
								$author$project$Forms$CommandForms$CommandForm$createCommandFormValues),
							fK: function (command) {
								return _Utils_update(
									repository,
									{
										O: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.O, command)
									});
							}
						},
						model,
						result);
				case 18:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$CommandsView$onGotPostResult,
						{
							fa: A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel, $author$project$Lib$Form$FormView$makeModel),
							fK: function (group) {
								return _Utils_update(
									repository,
									{
										_: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository._, group)
									});
							}
						},
						model,
						result);
				case 15:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$CommandsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									O: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.O, id)
								});
						},
						model,
						httpResult);
				case 16:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$CommandsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									_: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository._, id)
								});
						},
						model,
						httpResult);
				default:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
						$elm$core$Basics$always(
							$author$project$Pages$Main$SubViewUpdateUtils$withoutActions(model)),
						model,
						httpResult);
			}
		}
	});
var $author$project$Pages$Main$ConnectionsView$AddNewConnectionModal = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$ConnectionsView$BeginDeleteConnection = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$ConnectionsView$BeginDeleteGroup = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$ConnectionsView$BeginEditConnection = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$ConnectionsView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$ConnectionsView$BeginSaveConnection = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$ConnectionsView$BeginSaveGroup = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$ConnectionsView$GotDeleteConnection = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$ConnectionsView$GotDeleteGroup = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$ConnectionsView$GotPostConnection = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$ConnectionsView$GotPostGroup = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Main$ConnectionsView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$ConnectionsView$NoOp = {$: 0};
var $author$project$Forms$ConnectionForms$AwsConnectionFormValues = function (a) {
	return {$: 4, a: a};
};
var $author$project$Forms$ConnectionForms$AzureAdConnectionFormValues = function (a) {
	return {$: 3, a: a};
};
var $author$project$Forms$ConnectionForms$DatabaseFormValues = function (a) {
	return {$: 1, a: a};
};
var $author$project$Forms$ConnectionForms$DynamicConnectionFormValues = function (a) {
	return {$: 0, a: a};
};
var $author$project$Forms$ConnectionForms$HttpFormValues = function (a) {
	return {$: 2, a: a};
};
var $author$project$Forms$ConnectionForms$MailServerFormValues = function (a) {
	return {$: 5, a: a};
};
var $author$project$Forms$ConnectionForms$createCommonPropsValues = function (connection) {
	return {hM: connection.hM, i$: connection.i$, j_: connection.j_, db: connection.db, j9: connection.j9, k_: connection.k_, dM: connection.dM};
};
var $author$project$Forms$ConnectionForms$createAwsFormValues = F2(
	function (connection, spec) {
		return {
			hU: spec.hU,
			hV: spec.hV,
			hX: spec.hX,
			ip: $author$project$Forms$ConnectionForms$createCommonPropsValues(connection),
			iU: spec.iU
		};
	});
var $author$project$Forms$ConnectionForms$createAzureAdFileServerFormValues = F2(
	function (connection, spec) {
		return {
			hY: spec.hY,
			ie: spec.ie,
			$8: spec.$8,
			ip: $author$project$Forms$ConnectionForms$createCommonPropsValues(connection),
			l9: spec.l9
		};
	});
var $author$project$Forms$ConnectionForms$DatabaseConnectionFormValuesRec = function (commonPropsValues) {
	return function (dbTypeStr) {
		return function (serverName) {
			return function (portNumber) {
				return function (databaseName) {
					return function (user) {
						return function (password) {
							return function (poolSettings) {
								return function (properties) {
									return function (convertTimesBetweenClientTimeZoneAndUTC) {
										return {ip: commonPropsValues, iF: convertTimesBetweenClientTimeZoneAndUTC, iP: databaseName, eP: dbTypeStr, fl: password, ln: poolSettings, lo: portNumber, lx: properties, l4: serverName, hp: user};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Forms$ConnectionForms$KeyValuePairEntryFormValues = F2(
	function (key, value) {
		return {kt: key, mY: value};
	});
var $author$project$Utils$ToStrings$databaseTypeToString = function (databaseType) {
	switch (databaseType) {
		case 0:
			return 'PostgreSQL';
		case 1:
			return 'SqlServer';
		case 2:
			return 'OracleDB';
		default:
			return 'MariaDB';
	}
};
var $author$project$Forms$ConnectionForms$createDatabaseConnectionFormValues = F2(
	function (connection, spec) {
		var poolSettings = spec.ln;
		return $author$project$Forms$ConnectionForms$DatabaseConnectionFormValuesRec(
			$author$project$Forms$ConnectionForms$createCommonPropsValues(connection))(
			$author$project$Utils$ToStrings$databaseTypeToString(spec.iR))(spec.l4)(
			$elm$core$String$fromInt(spec.lo))(spec.iP)(spec.hp)(spec.fl)(
			{
				ix: $elm$core$String$fromInt(poolSettings.ix),
				kd: $elm$core$String$fromInt(poolSettings.kd),
				ks: $elm$core$String$fromInt(poolSettings.ks),
				ky: $elm$core$String$fromInt(poolSettings.ky),
				kJ: $elm$core$String$fromInt(poolSettings.kJ),
				kK: $elm$core$String$fromInt(poolSettings.kK),
				kM: $elm$core$String$fromInt(poolSettings.kM),
				kW: $elm$core$String$fromInt(poolSettings.kW),
				lP: $elm$core$String$fromInt(poolSettings.lP)
			})(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var key = _v0.a;
					var value = _v0.b;
					return A2($author$project$Forms$ConnectionForms$KeyValuePairEntryFormValues, key, value);
				},
				spec.lx))(spec.iF);
	});
var $author$project$Forms$ConnectionForms$DynamicConnectionFormValuesRec = function (commonPropsValues) {
	return {ip: commonPropsValues};
};
var $author$project$Forms$ConnectionForms$createDynamicConnectionFormValues = function (connection) {
	return $author$project$Forms$ConnectionForms$DynamicConnectionFormValuesRec(
		$author$project$Forms$ConnectionForms$createCommonPropsValues(connection));
};
var $author$project$Forms$ConnectionForms$HttpConnectionFormValuesRec = F6(
	function (commonPropsValues, url, basicAuthCredentialValues, credentialsJson, defaultHeaders, installKtorJsonFeature) {
		return {eD: basicAuthCredentialValues, ip: commonPropsValues, iH: credentialsJson, iT: defaultHeaders, km: installKtorJsonFeature, mO: url};
	});
var $author$project$Forms$ConnectionForms$createHttpConnectionFormValues = F2(
	function (connection, spec) {
		return A6(
			$author$project$Forms$ConnectionForms$HttpConnectionFormValuesRec,
			$author$project$Forms$ConnectionForms$createCommonPropsValues(connection),
			spec.mO,
			A2(
				$author$project$Forms$CommonForms$createOptionalFormValues,
				A2($author$project$Entity$Connection$HttpCredentials, '', ''),
				spec.h_),
			A2($elm$json$Json$Encode$encode, 4, spec.iH),
			spec.iT,
			spec.km);
	});
var $author$project$Forms$ConnectionForms$MailServerConnectionFormValuesRec = F6(
	function (commonPropsValues, host, port_, from, username, password) {
		return {ip: commonPropsValues, jP: from, j5: host, fl: password, lp: port_, mX: username};
	});
var $author$project$Forms$ConnectionForms$createMailServerConnectionFormValues = F2(
	function (connection, spec) {
		return A6(
			$author$project$Forms$ConnectionForms$MailServerConnectionFormValuesRec,
			$author$project$Forms$ConnectionForms$createCommonPropsValues(connection),
			spec.j5,
			$elm$core$String$fromInt(spec.lp),
			spec.jP,
			spec.mX,
			spec.fl);
	});
var $author$project$Forms$ConnectionForms$createConnectionFormValues = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return $author$project$Forms$ConnectionForms$DynamicConnectionFormValues(
				$author$project$Forms$ConnectionForms$createDynamicConnectionFormValues(connection));
		case 1:
			var spec = _v0.a;
			return $author$project$Forms$ConnectionForms$DatabaseFormValues(
				A2($author$project$Forms$ConnectionForms$createDatabaseConnectionFormValues, connection, spec));
		case 2:
			var spec = _v0.a;
			return $author$project$Forms$ConnectionForms$HttpFormValues(
				A2($author$project$Forms$ConnectionForms$createHttpConnectionFormValues, connection, spec));
		case 3:
			var spec = _v0.a;
			return $author$project$Forms$ConnectionForms$MailServerFormValues(
				A2($author$project$Forms$ConnectionForms$createMailServerConnectionFormValues, connection, spec));
		case 5:
			var spec = _v0.a;
			return $author$project$Forms$ConnectionForms$AzureAdConnectionFormValues(
				A2($author$project$Forms$ConnectionForms$createAzureAdFileServerFormValues, connection, spec));
		default:
			var spec = _v0.a;
			return $author$project$Forms$ConnectionForms$AwsConnectionFormValues(
				A2($author$project$Forms$ConnectionForms$createAwsFormValues, connection, spec));
	}
};
var $author$project$Forms$CreateNewConnectionForm$createNewConnectionFormValues = {cJ: 'database'};
var $author$project$Api$EntityApi$deleteConnection = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$connectionsUrl);
var $author$project$Api$EntityApi$deleteConnectionGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$connectionGroupsUrl);
var $author$project$Entity$Connection$connectionTypeOptions = _List_fromArray(
	[
		_Utils_Tuple2('dynamic', 'Dynaaminen yhteys'),
		_Utils_Tuple2('database', 'Tietokantayhteys'),
		_Utils_Tuple2('http', 'HTTP-yhteys'),
		_Utils_Tuple2('mail_server', 'Sähköpostiserveri'),
		_Utils_Tuple2('aws', 'AWS'),
		_Utils_Tuple2('azure_ad', 'Azure AD')
	]);
var $author$project$Pages$Main$ConnectionsView$filterByConnectionType = F2(
	function (filterValue, connection) {
		var requiredValue = function () {
			var _v0 = connection.mi;
			switch (_v0.$) {
				case 0:
					return 'dynamic';
				case 1:
					return 'database';
				case 2:
					return 'http';
				case 4:
					return 'aws';
				case 5:
					return 'azure_ad';
				default:
					return 'mail_server';
			}
		}();
		return _Utils_eq(filterValue, requiredValue);
	});
var $author$project$Entity$Connection$getConnectionTypeAsString = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return 'Dynaaminen yhteys';
		case 1:
			return 'Tietokanta';
		case 2:
			return 'HTTP';
		case 3:
			return 'Sähköpostiserveri';
		case 4:
			return 'AWS';
		default:
			return 'Azure AD';
	}
};
var $author$project$Pages$Main$ConnectionsView$getEntityTreeItemColumns = function (connection) {
	return _Utils_ap(
		_List_fromArray(
			[
				$author$project$Entity$Connection$getConnectionTypeAsString(connection),
				$elm$core$String$fromInt(connection.db),
				connection.k_,
				$author$project$Utils$StringUtils$firstLine(connection.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(connection),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(connection)));
};
var $author$project$Pages$Main$ConnectionsView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2($author$project$Pages$Main$EntityListing$EntityListingFilters$entityTypeFilter, $author$project$Pages$Main$ConnectionsView$filterByConnectionType, $author$project$Entity$Connection$connectionTypeOptions),
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.iy;
	},
	jT: $author$project$Pages$Main$ConnectionsView$getEntityTreeItemColumns,
	jU: $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields,
	gp: function ($) {
		return $.ab;
	}
};
var $author$project$Pages$Main$ConnectionsView$onGotDelete = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$ConnectionsView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$ConnectionsView$onGotPostResult = F2(
	function (_v0, model) {
		var makeNewEditModel = _v0.fa;
		var updateRepository = _v0.fK;
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (connection) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							j: A2(
								$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
								model.j,
								makeNewEditModel(connection))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(connection)));
			},
			model);
	});
var $author$project$Pages$Main$ConnectionsView$positionUpdatingConfig = {
	go: function ($) {
		return $.iy;
	},
	gp: function ($) {
		return $.ab;
	},
	l6: F2(
		function (connections, repo) {
			return _Utils_update(
				repo,
				{
					iy: $author$project$Lib$RemoteData$Success(connections)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					ab: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $author$project$Encoding$ConnectionEncoding$connTypeFieldName = 'conn_type';
var $author$project$Encoding$ConnectionEncoding$encodeAwsConnectionSpec = function (spec) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Encoding$ConnectionEncoding$connTypeFieldName,
			$elm$json$Json$Encode$string('aws')),
			_Utils_Tuple2(
			'awsRegion',
			$elm$json$Json$Encode$string(spec.hX)),
			_Utils_Tuple2(
			'awsAccessKey',
			$elm$json$Json$Encode$string(spec.hU)),
			_Utils_Tuple2(
			'awsAccessKeySecret',
			$elm$json$Json$Encode$string(spec.hV)),
			_Utils_Tuple2(
			'defaultS3Bucket',
			$elm$json$Json$Encode$string(spec.iU))
		]);
};
var $author$project$Encoding$ConnectionEncoding$encodeAzureAdConnectionSpec = function (spec) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Encoding$ConnectionEncoding$connTypeFieldName,
			$elm$json$Json$Encode$string('azure_ad')),
			_Utils_Tuple2(
			'azureAdTenantId',
			$elm$json$Json$Encode$string(spec.hY)),
			_Utils_Tuple2(
			'clientId',
			$elm$json$Json$Encode$string(spec.ie)),
			_Utils_Tuple2(
			'clientSecret',
			$elm$json$Json$Encode$string(spec.$8)),
			_Utils_Tuple2(
			'sharePointRootSiteId',
			$elm$json$Json$Encode$string(spec.l9))
		]);
};
var $author$project$Encoding$ConnectionEncoding$encodeDatabaseConnectionPoolSettings = function (settings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'maximumPoolSize',
				$elm$json$Json$Encode$int(settings.kM)),
				_Utils_Tuple2(
				'minimumIdle',
				$elm$json$Json$Encode$int(settings.kW)),
				_Utils_Tuple2(
				'connectionTimeout',
				$elm$json$Json$Encode$int(settings.ix)),
				_Utils_Tuple2(
				'maxLifeTime',
				$elm$json$Json$Encode$int(settings.kJ)),
				_Utils_Tuple2(
				'keepaliveTime',
				$elm$json$Json$Encode$int(settings.ks)),
				_Utils_Tuple2(
				'idleTimeout',
				$elm$json$Json$Encode$int(settings.kd)),
				_Utils_Tuple2(
				'leakDetectionThreshold',
				$elm$json$Json$Encode$int(settings.ky)),
				_Utils_Tuple2(
				'maxOverflowPoolSize',
				$elm$json$Json$Encode$int(settings.kK)),
				_Utils_Tuple2(
				'retryAttempts',
				$elm$json$Json$Encode$int(settings.lP))
			]));
};
var $author$project$Encoding$ConnectionEncoding$encodeDatabaseConnectionSpec = function (spec) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Encoding$ConnectionEncoding$connTypeFieldName,
			$elm$json$Json$Encode$string('database')),
			_Utils_Tuple2(
			'dbType',
			$elm$json$Json$Encode$string(
				$author$project$Utils$ToStrings$databaseTypeToString(spec.iR))),
			_Utils_Tuple2(
			'serverName',
			$elm$json$Json$Encode$string(spec.l4)),
			_Utils_Tuple2(
			'portNumber',
			$elm$json$Json$Encode$int(spec.lo)),
			_Utils_Tuple2(
			'databaseName',
			$elm$json$Json$Encode$string(spec.iP)),
			_Utils_Tuple2(
			'username',
			$elm$json$Json$Encode$string(spec.hp)),
			_Utils_Tuple2(
			'password',
			$elm$json$Json$Encode$string(spec.fl)),
			_Utils_Tuple2(
			'poolSettings',
			$author$project$Encoding$ConnectionEncoding$encodeDatabaseConnectionPoolSettings(spec.ln)),
			_Utils_Tuple2(
			'properties',
			$elm$json$Json$Encode$object(
				A2(
					$elm$core$List$map,
					function (_v0) {
						var key = _v0.a;
						var value = _v0.b;
						return _Utils_Tuple2(
							key,
							$elm$json$Json$Encode$string(value));
					},
					spec.lx))),
			_Utils_Tuple2(
			'convertTimesBetweenClientTimeZoneAndUTC',
			$elm$json$Json$Encode$bool(spec.iF))
		]);
};
var $author$project$Encoding$ConnectionEncoding$encodeHttpCredentials = function (cred) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'username',
				$elm$json$Json$Encode$string(cred.hp)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(cred.fl))
			]));
};
var $author$project$Encoding$ConnectionEncoding$encodeHttpHeader = function (header) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(header.k_)),
				_Utils_Tuple2(
				'value',
				$elm$json$Json$Encode$string(header.mY)),
				_Utils_Tuple2(
				'encrypt',
				$elm$json$Json$Encode$bool(header.jn))
			]));
};
var $author$project$Encoding$ConnectionEncoding$encodeHttpConnectionSpec = function (conn) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Encoding$ConnectionEncoding$connTypeFieldName,
			$elm$json$Json$Encode$string('http')),
			_Utils_Tuple2(
			'url',
			$elm$json$Json$Encode$string(conn.mO)),
			_Utils_Tuple2(
			'basicAuthCredentials',
			A2($author$project$Encoding$Utils$encodeMaybe, $author$project$Encoding$ConnectionEncoding$encodeHttpCredentials, conn.h_)),
			_Utils_Tuple2('credentials', conn.iH),
			_Utils_Tuple2(
			'defaultHeaders',
			A2($elm$json$Json$Encode$list, $author$project$Encoding$ConnectionEncoding$encodeHttpHeader, conn.iT)),
			_Utils_Tuple2(
			'installKtorJsonFeature',
			$elm$json$Json$Encode$bool(conn.km))
		]);
};
var $author$project$Encoding$ConnectionEncoding$encodeMailServerConnectionSpec = function (spec) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			$author$project$Encoding$ConnectionEncoding$connTypeFieldName,
			$elm$json$Json$Encode$string('mail_server')),
			_Utils_Tuple2(
			'host',
			$elm$json$Json$Encode$string(spec.j5)),
			_Utils_Tuple2(
			'port',
			$elm$json$Json$Encode$int(spec.lp)),
			_Utils_Tuple2(
			'fromAddress',
			$elm$json$Json$Encode$string(spec.jP)),
			_Utils_Tuple2(
			'username',
			$elm$json$Json$Encode$string(spec.mX)),
			_Utils_Tuple2(
			'password',
			$elm$json$Json$Encode$string(spec.fl))
		]);
};
var $author$project$Encoding$ConnectionEncoding$encodeConnectionSpecification = function (spec) {
	switch (spec.$) {
		case 0:
			return _List_fromArray(
				[
					_Utils_Tuple2(
					$author$project$Encoding$ConnectionEncoding$connTypeFieldName,
					$elm$json$Json$Encode$string('dynamic'))
				]);
		case 1:
			var spec_ = spec.a;
			return $author$project$Encoding$ConnectionEncoding$encodeDatabaseConnectionSpec(spec_);
		case 2:
			var spec_ = spec.a;
			return $author$project$Encoding$ConnectionEncoding$encodeHttpConnectionSpec(spec_);
		case 3:
			var spec_ = spec.a;
			return $author$project$Encoding$ConnectionEncoding$encodeMailServerConnectionSpec(spec_);
		case 4:
			var spec_ = spec.a;
			return $author$project$Encoding$ConnectionEncoding$encodeAwsConnectionSpec(spec_);
		default:
			var spec_ = spec.a;
			return $author$project$Encoding$ConnectionEncoding$encodeAzureAdConnectionSpec(spec_);
	}
};
var $author$project$Encoding$ConnectionEncoding$encodeConnection = function (conn) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(conn.db)),
					_Utils_Tuple2(
					'groupId',
					A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, conn.j_)),
					_Utils_Tuple2(
					'pos',
					$elm$json$Json$Encode$int(conn.dM)),
					_Utils_Tuple2(
					'aproProjectId',
					A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, conn.hM)),
					_Utils_Tuple2(
					'idAnchor',
					A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, conn.j9)),
					_Utils_Tuple2(
					'name',
					$elm$json$Json$Encode$string(conn.k_)),
					_Utils_Tuple2(
					'description',
					$elm$json$Json$Encode$string(conn.i$))
				]),
			$author$project$Encoding$ConnectionEncoding$encodeConnectionSpecification(conn.mi)));
};
var $author$project$Api$EntityApi$postConnection = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$connectionsUrl, $author$project$Encoding$ConnectionEncoding$encodeConnection, $author$project$Decoding$ConnectionDecoding$connectionDecoder);
var $author$project$Api$EntityApi$postConnectionGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$connectionGroupsUrl, $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Api$EntityApi$saveConnectionGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$connectionGroupsUrl);
var $author$project$Api$EntityApi$saveConnectionPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$connectionsUrl);
var $author$project$Pages$Main$ConnectionsView$Confirm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$ConnectionsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			switch (_v1.$) {
				case 3:
					var formModel = _v1.a;
					return $author$project$Pages$Main$ConnectionsView$AddNewConnectionModal(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var config = _v1.a;
					return $author$project$Pages$Main$ConnectionsView$Confirm(
						_Utils_update(
							config,
							{ha: status}));
				default:
					return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Pages$Main$ConnectionsView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$ConnectionsView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$ConnectionsView$Confirm(
						{h4: $author$project$Pages$Main$ConnectionsView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Pages$Main$ConnectionsView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$ConnectionsView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$ConnectionsView$BeginEditConnection(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$ConnectionsView$BeginEditGroup(group));
			case 4:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$ConnectionsView$AddNewConnectionModal(
								$author$project$Lib$Form$FormView$makeModel($author$project$Forms$CreateNewConnectionForm$createNewConnectionFormValues))
						}));
			case 5:
				return newMsg(
					$author$project$Pages$Main$ConnectionsView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var connection = outMsg.a;
				return A3(
					$author$project$Pages$Main$ConnectionsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa yhteyden ' + (connection.k_ + '?'),
					$author$project$Pages$Main$ConnectionsView$BeginDeleteConnection(connection));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$ConnectionsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$ConnectionsView$BeginDeleteGroup(group));
			case 8:
				var connection = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$ConnectionsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(connection));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$ConnectionsView$update, apiConfig, repo),
					$author$project$Pages$Main$ConnectionsView$BeginSaveConnection(connection),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveConnectionPosition,
							apiConfig,
							connection,
							$elm$core$Basics$always($author$project$Pages$Main$ConnectionsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$ConnectionsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$ConnectionsView$update, apiConfig, repo),
					$author$project$Pages$Main$ConnectionsView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveConnectionGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$ConnectionsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$ConnectionsView$update = F4(
	function (apiConfig, repository, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
				case 1:
					var listingViewMsg = msg.a;
					var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$ConnectionsView$listingViewConfig, repository, listingViewMsg, model.ap);
					var listingViewModel = _v1.a;
					var listingViewCmd = _v1.b;
					var listingViewOutMsg = _v1.c;
					return A5(
						$author$project$Pages$Main$ConnectionsView$handleListingViewOutMsg,
						apiConfig,
						repository,
						_Utils_update(
							model,
							{ap: listingViewModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$ConnectionsView$ListingViewMsg, listingViewCmd),
						listingViewOutMsg);
				case 4:
					var connection = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
									$author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$ConnectionForms$createConnectionFormValues(connection)))
							}));
				case 5:
					var group = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
									$author$project$Lib$Form$FormView$makeModel(group))
							}));
				case 2:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{bO: $author$project$Pages$Main$ConnectionsView$Hidden}));
				case 6:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
							}));
				case 7:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
							}));
				case 8:
					var newFormModel = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								bO: $author$project$Pages$Main$ConnectionsView$AddNewConnectionModal(newFormModel)
							}));
				case 9:
					var connection = msg.a;
					var $temp$apiConfig = apiConfig,
						$temp$repository = repository,
						$temp$msg = $author$project$Pages$Main$ConnectionsView$BeginEditConnection(
						_Utils_update(
							connection,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})),
						$temp$model = _Utils_update(
						model,
						{bO: $author$project$Pages$Main$ConnectionsView$Hidden});
					apiConfig = $temp$apiConfig;
					repository = $temp$repository;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 3:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
				case 10:
					var connection = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ConnectionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postConnection, apiConfig, connection, $author$project$Pages$Main$ConnectionsView$GotPostConnection),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 11:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ConnectionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postConnectionGroup, apiConfig, group, $author$project$Pages$Main$ConnectionsView$GotPostGroup),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 12:
					var connection = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ConnectionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteConnection, apiConfig, connection.db, $author$project$Pages$Main$ConnectionsView$GotDeleteConnection),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$ConnectionsView$positionUpdatingConfig, repository, connection.dM)));
				case 13:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ConnectionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteConnectionGroup, apiConfig, group.db, $author$project$Pages$Main$ConnectionsView$GotDeleteGroup),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$ConnectionsView$positionUpdatingConfig, repository, group.dM)));
				case 16:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$ConnectionsView$onGotPostResult,
						{
							fa: A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel, $author$project$Lib$Form$FormView$makeModel),
								$author$project$Forms$ConnectionForms$createConnectionFormValues),
							fK: function (connection) {
								return _Utils_update(
									repository,
									{
										iy: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.iy, connection)
									});
							}
						},
						model,
						result);
				case 17:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$ConnectionsView$onGotPostResult,
						{
							fa: A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel, $author$project$Lib$Form$FormView$makeModel),
							fK: function (group) {
								return _Utils_update(
									repository,
									{
										ab: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.ab, group)
									});
							}
						},
						model,
						result);
				case 14:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$ConnectionsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									iy: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.iy, id)
								});
						},
						model,
						httpResult);
				default:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$ConnectionsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									ab: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.ab, id)
								});
						},
						model,
						httpResult);
			}
		}
	});
var $author$project$Pages$Main$EndpointsView$BeginDeleteEndpoint = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$EndpointsView$BeginDeleteGroup = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$EndpointsView$BeginEditEndpoint = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$EndpointsView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$EndpointsView$BeginSaveEndpoint = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Main$EndpointsView$BeginSaveGroup = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$EndpointsView$GotDeleteEndpoint = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$EndpointsView$GotDeleteGroup = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$EndpointsView$GotPostEndpoint = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$EndpointsView$GotPostGroup = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$EndpointsView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EndpointsView$NoOp = {$: 0};
var $author$project$Utils$ToStrings$endpointReturnTypeToString = function (returnType) {
	switch (returnType) {
		case 0:
			return 'Json';
		case 1:
			return 'PlainText';
		default:
			return 'BinaryFile';
	}
};
var $author$project$Forms$EndpointForms$createEndpointFormValues = function (endpoint) {
	return {
		hM: endpoint.hM,
		f2: endpoint.f2,
		io: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, endpoint.io),
		cQ: A2($elm$core$List$member, 3, endpoint.kS),
		i$: endpoint.i$,
		c1: A2($elm$core$List$member, 0, endpoint.kS),
		j_: endpoint.j_,
		db: endpoint.db,
		j9: endpoint.j9,
		k_: endpoint.k_,
		ll: endpoint.ll,
		dM: endpoint.dM,
		dN: A2($elm$core$List$member, 1, endpoint.kS),
		dY: A2($elm$core$List$member, 2, endpoint.kS),
		lQ: $author$project$Utils$ToStrings$endpointReturnTypeToString(endpoint.lQ)
	};
};
var $author$project$Entity$Endpoint$createNewEndpoint = {hM: $elm$core$Maybe$Nothing, f2: $author$project$Entity$Common$nonExistentId, io: _List_Nil, i$: '', j_: $elm$core$Maybe$Nothing, db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, kS: _List_Nil, k_: '', ll: '/', dM: $author$project$Entity$Common$lastPosition, lQ: 0};
var $author$project$Api$EntityApi$deleteEndpoint = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$endpointsUrl);
var $author$project$Api$EntityApi$deleteEndpointGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$endpointGroupsUrl);
var $author$project$Pages$Main$EndpointsView$getEntityTreeItemColumns = function (endpoint) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$core$String$fromInt(endpoint.db),
				endpoint.k_,
				$author$project$Utils$StringUtils$firstLine(endpoint.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(endpoint),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(endpoint)));
};
var $author$project$Pages$Main$EndpointsView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.aj;
	},
	jT: $author$project$Pages$Main$EndpointsView$getEntityTreeItemColumns,
	jU: $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields,
	gp: function ($) {
		return $.ai;
	}
};
var $author$project$Pages$Main$EndpointsView$onGotDelete = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$EndpointsView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$EndpointsView$onGotPostResult = F2(
	function (_v0, model) {
		var makeNewEditModel = _v0.fa;
		var updateRepository = _v0.fK;
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (endpoint) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							j: A2(
								$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
								model.j,
								makeNewEditModel(endpoint))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(endpoint)));
			},
			model);
	});
var $author$project$Pages$Main$EndpointsView$positionUpdatingConfig = {
	go: function ($) {
		return $.aj;
	},
	gp: function ($) {
		return $.ai;
	},
	l6: F2(
		function (endpoints, repo) {
			return _Utils_update(
				repo,
				{
					aj: $author$project$Lib$RemoteData$Success(endpoints)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					ai: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $author$project$Encoding$EndpointEncoding$encodeEndpoint = function (endpoint) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(endpoint.db)),
				_Utils_Tuple2(
				'groupId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, endpoint.j_)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(endpoint.dM)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, endpoint.hM)),
				_Utils_Tuple2(
				'idAnchor',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, endpoint.j9)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(endpoint.k_)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(endpoint.i$)),
				_Utils_Tuple2(
				'path',
				$elm$json$Json$Encode$string(endpoint.ll)),
				_Utils_Tuple2(
				'methods',
				A2(
					$elm$json$Json$Encode$list,
					A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Utils$ToStrings$httpMethodToString),
					endpoint.kS)),
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(endpoint.f2)),
				_Utils_Tuple2(
				'commandParamMappings',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, endpoint.io)),
				_Utils_Tuple2(
				'returnType',
				A2($elm$core$Basics$composeL, $elm$json$Json$Encode$string, $author$project$Utils$ToStrings$endpointReturnTypeToString)(endpoint.lQ))
			]));
};
var $author$project$Api$EntityApi$postEndpoint = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$endpointsUrl, $author$project$Encoding$EndpointEncoding$encodeEndpoint, $author$project$Decoding$EndpointDecoding$endpointDecoder);
var $author$project$Api$EntityApi$postEndpointGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$endpointGroupsUrl, $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Api$EntityApi$saveEndpointGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$endpointGroupsUrl);
var $author$project$Api$EntityApi$saveEndpointPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$endpointsUrl);
var $author$project$Pages$Main$EndpointsView$Confirm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$EndpointsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			if (_v1.$ === 2) {
				var config = _v1.a;
				return $author$project$Pages$Main$EndpointsView$Confirm(
					_Utils_update(
						config,
						{ha: status}));
			} else {
				return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Pages$Main$EndpointsView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$EndpointsView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$EndpointsView$Confirm(
						{h4: $author$project$Pages$Main$EndpointsView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Pages$Main$EndpointsView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$EndpointsView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$EndpointsView$BeginEditEndpoint(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$EndpointsView$BeginEditGroup(group));
			case 4:
				return newMsg(
					$author$project$Pages$Main$EndpointsView$BeginEditEndpoint(
						_Utils_update(
							$author$project$Entity$Endpoint$createNewEndpoint,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 5:
				return newMsg(
					$author$project$Pages$Main$EndpointsView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var endpoint = outMsg.a;
				return A3(
					$author$project$Pages$Main$EndpointsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa päätepisteen ' + (endpoint.k_ + '?'),
					$author$project$Pages$Main$EndpointsView$BeginDeleteEndpoint(endpoint));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$EndpointsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$EndpointsView$BeginDeleteGroup(group));
			case 8:
				var endpoint = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$EndpointsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(endpoint));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$EndpointsView$update, apiConfig, repo),
					$author$project$Pages$Main$EndpointsView$BeginSaveEndpoint(endpoint),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveEndpointPosition,
							apiConfig,
							endpoint,
							$elm$core$Basics$always($author$project$Pages$Main$EndpointsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$EndpointsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$EndpointsView$update, apiConfig, repo),
					$author$project$Pages$Main$EndpointsView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveEndpointGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$EndpointsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$EndpointsView$update = F4(
	function (apiConfig, repository, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
				case 1:
					var listingViewMsg = msg.a;
					var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$EndpointsView$listingViewConfig, repository, listingViewMsg, model.ap);
					var listingViewModel = _v1.a;
					var listingViewCmd = _v1.b;
					var listingViewOutMsg = _v1.c;
					return A5(
						$author$project$Pages$Main$EndpointsView$handleListingViewOutMsg,
						apiConfig,
						repository,
						_Utils_update(
							model,
							{ap: listingViewModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$EndpointsView$ListingViewMsg, listingViewCmd),
						listingViewOutMsg);
				case 4:
					var endpoint = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
									$author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$EndpointForms$createEndpointFormValues(endpoint)))
							}));
				case 5:
					var group = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
									$author$project$Lib$Form$FormView$makeModel(group))
							}));
				case 2:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{bO: $author$project$Pages$Main$EndpointsView$Hidden}));
				case 6:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
							}));
				case 7:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
							}));
				case 8:
					var endpoint = msg.a;
					var $temp$apiConfig = apiConfig,
						$temp$repository = repository,
						$temp$msg = $author$project$Pages$Main$EndpointsView$BeginEditEndpoint(
						_Utils_update(
							endpoint,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})),
						$temp$model = _Utils_update(
						model,
						{bO: $author$project$Pages$Main$EndpointsView$Hidden});
					apiConfig = $temp$apiConfig;
					repository = $temp$repository;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 3:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
				case 9:
					var endpoint = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EndpointsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postEndpoint, apiConfig, endpoint, $author$project$Pages$Main$EndpointsView$GotPostEndpoint),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 10:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EndpointsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postEndpointGroup, apiConfig, group, $author$project$Pages$Main$EndpointsView$GotPostGroup),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 11:
					var endpoint = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EndpointsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteEndpoint, apiConfig, endpoint.db, $author$project$Pages$Main$EndpointsView$GotDeleteEndpoint),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$EndpointsView$positionUpdatingConfig, repository, endpoint.dM)));
				case 12:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EndpointsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteEndpointGroup, apiConfig, group.db, $author$project$Pages$Main$EndpointsView$GotDeleteGroup),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$EndpointsView$positionUpdatingConfig, repository, group.dM)));
				case 15:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$EndpointsView$onGotPostResult,
						{
							fa: A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel, $author$project$Lib$Form$FormView$makeModel),
								$author$project$Forms$EndpointForms$createEndpointFormValues),
							fK: function (endpoint) {
								return _Utils_update(
									repository,
									{
										aj: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.aj, endpoint)
									});
							}
						},
						model,
						result);
				case 16:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$EndpointsView$onGotPostResult,
						{
							fa: A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel, $author$project$Lib$Form$FormView$makeModel),
							fK: function (group) {
								return _Utils_update(
									repository,
									{
										ai: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.ai, group)
									});
							}
						},
						model,
						result);
				case 13:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$EndpointsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									aj: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.aj, id)
								});
						},
						model,
						httpResult);
				default:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$EndpointsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									ai: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.ai, id)
								});
						},
						model,
						httpResult);
			}
		}
	});
var $author$project$Pages$Main$EnvironmentsView$BeginDeleteEnvironment = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$BeginDeleteGroup = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$BeginEditEnvironment = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$BeginSaveEnvironment = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$BeginSaveGroup = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$GotDeleteEnvironment = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$GotDeleteGroup = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$GotPostEnvironment = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$GotPostGroup = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$NoOp = {$: 0};
var $author$project$Forms$EnvironmentForms$createEnvironmentFormValues = function (env) {
	return {
		hM: env.hM,
		cI: $author$project$Forms$ConnectionForms$createConnectionSetFormValues(env.iy),
		i$: env.i$,
		i1: env.i1,
		jd: env.jd,
		jq: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, env.jq),
		jr: env.jr,
		j_: env.j_,
		db: env.db,
		j9: env.j9,
		k_: env.k_,
		dM: env.dM
	};
};
var $author$project$Entity$Connection$createNewConnectionReferenceSet = A5($author$project$Entity$Connection$ConnectionReferenceSet, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
var $author$project$Entity$Environment$createNewEnvironment = {hM: $elm$core$Maybe$Nothing, iy: $author$project$Entity$Connection$createNewConnectionReferenceSet, i$: '', i1: '', jd: _List_Nil, jq: _List_Nil, jr: '', j_: $elm$core$Maybe$Nothing, db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, k_: '', dM: $author$project$Entity$Common$lastPosition};
var $author$project$Api$EntityApi$deleteEnvironment = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$environmentsUrl);
var $author$project$Api$EntityApi$deleteEnvironmentGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$environmentGroupsUrl);
var $author$project$Pages$Main$EnvironmentsView$getEntityTreeItemColumns = function (environment) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$core$String$fromInt(environment.db),
				environment.k_,
				environment.i1,
				$author$project$Utils$StringUtils$firstLine(environment.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(environment),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(environment)));
};
var $author$project$Pages$Main$EnvironmentsView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.gg;
	},
	jT: $author$project$Pages$Main$EnvironmentsView$getEntityTreeItemColumns,
	jU: $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields,
	gp: function ($) {
		return $.ak;
	}
};
var $author$project$Pages$Main$EnvironmentsView$onGotDelete = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$EnvironmentsView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$EnvironmentsView$onGotPostResult = F2(
	function (_v0, model) {
		var makeNewEditModel = _v0.fa;
		var updateRepository = _v0.fK;
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (environment) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							j: A2(
								$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
								model.j,
								makeNewEditModel(environment))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(environment)));
			},
			model);
	});
var $author$project$Pages$Main$EnvironmentsView$positionUpdatingConfig = {
	go: function ($) {
		return $.gg;
	},
	gp: function ($) {
		return $.ak;
	},
	l6: F2(
		function (environments, repo) {
			return _Utils_update(
				repo,
				{
					gg: $author$project$Lib$RemoteData$Success(environments)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					ak: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $author$project$Encoding$EnvironmentEncoding$encodeDynamicConnectionAssociation = function (_v0) {
	var dynamicConnectionId = _v0.jc;
	var actualConnectionId = _v0.hC;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'dynamicConnectionId',
				$elm$json$Json$Encode$int(dynamicConnectionId)),
				_Utils_Tuple2(
				'actualConnectionId',
				$elm$json$Json$Encode$int(actualConnectionId))
			]));
};
var $author$project$Encoding$EnvironmentEncoding$encodeEnvironment = function (environment) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(environment.k_)),
				_Utils_Tuple2(
				'displayName',
				$elm$json$Json$Encode$string(environment.i1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(environment.i$)),
				_Utils_Tuple2(
				'environmentType',
				$elm$json$Json$Encode$string(environment.jr)),
				_Utils_Tuple2(
				'connections',
				$author$project$Encoding$ConnectionEncoding$encodeConnectionReferenceSet(environment.iy)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(environment.db)),
				_Utils_Tuple2(
				'groupId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, environment.j_)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(environment.dM)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, environment.hM)),
				_Utils_Tuple2(
				'idAnchor',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, environment.j9)),
				_Utils_Tuple2(
				'dynamicConnections',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$EnvironmentEncoding$encodeDynamicConnectionAssociation, environment.jd)),
				_Utils_Tuple2(
				'environmentParameters',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, environment.jq))
			]));
};
var $author$project$Api$EntityApi$postEnvironment = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$environmentsUrl, $author$project$Encoding$EnvironmentEncoding$encodeEnvironment, $author$project$Decoding$EnvironmentDecoding$environmentDecoder);
var $author$project$Api$EntityApi$postEnvironmentGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$environmentGroupsUrl, $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Api$EntityApi$saveEnvironmentGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$environmentGroupsUrl);
var $author$project$Api$EntityApi$saveEnvironmentPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$environmentsUrl);
var $author$project$Pages$Main$EnvironmentsView$Confirm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			if (_v1.$ === 2) {
				var config = _v1.a;
				return $author$project$Pages$Main$EnvironmentsView$Confirm(
					_Utils_update(
						config,
						{ha: status}));
			} else {
				return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Pages$Main$EnvironmentsView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$EnvironmentsView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$EnvironmentsView$Confirm(
						{h4: $author$project$Pages$Main$EnvironmentsView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Pages$Main$EnvironmentsView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$EnvironmentsView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$EnvironmentsView$BeginEditEnvironment(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$EnvironmentsView$BeginEditGroup(group));
			case 4:
				return newMsg(
					$author$project$Pages$Main$EnvironmentsView$BeginEditEnvironment(
						_Utils_update(
							$author$project$Entity$Environment$createNewEnvironment,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 5:
				return newMsg(
					$author$project$Pages$Main$EnvironmentsView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var environment = outMsg.a;
				return A3(
					$author$project$Pages$Main$EnvironmentsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ympäristön ' + (environment.k_ + '?'),
					$author$project$Pages$Main$EnvironmentsView$BeginDeleteEnvironment(environment));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$EnvironmentsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$EnvironmentsView$BeginDeleteGroup(group));
			case 8:
				var environment = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$EnvironmentsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(environment));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$EnvironmentsView$update, apiConfig, repo),
					$author$project$Pages$Main$EnvironmentsView$BeginSaveEnvironment(environment),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveEnvironmentPosition,
							apiConfig,
							environment,
							$elm$core$Basics$always($author$project$Pages$Main$EnvironmentsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$EnvironmentsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$EnvironmentsView$update, apiConfig, repo),
					$author$project$Pages$Main$EnvironmentsView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveEnvironmentGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$EnvironmentsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$EnvironmentsView$update = F4(
	function (apiConfig, repository, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
				case 1:
					var listingViewMsg = msg.a;
					var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$EnvironmentsView$listingViewConfig, repository, listingViewMsg, model.ap);
					var listingViewModel = _v1.a;
					var listingViewCmd = _v1.b;
					var listingViewOutMsg = _v1.c;
					return A5(
						$author$project$Pages$Main$EnvironmentsView$handleListingViewOutMsg,
						apiConfig,
						repository,
						_Utils_update(
							model,
							{ap: listingViewModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$EnvironmentsView$ListingViewMsg, listingViewCmd),
						listingViewOutMsg);
				case 4:
					var environment = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
									$author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$EnvironmentForms$createEnvironmentFormValues(environment)))
							}));
				case 5:
					var group = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
									$author$project$Lib$Form$FormView$makeModel(group))
							}));
				case 2:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{bO: $author$project$Pages$Main$EnvironmentsView$Hidden}));
				case 6:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
							}));
				case 7:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
							}));
				case 8:
					var environment = msg.a;
					var $temp$apiConfig = apiConfig,
						$temp$repository = repository,
						$temp$msg = $author$project$Pages$Main$EnvironmentsView$BeginEditEnvironment(
						_Utils_update(
							environment,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})),
						$temp$model = _Utils_update(
						model,
						{bO: $author$project$Pages$Main$EnvironmentsView$Hidden});
					apiConfig = $temp$apiConfig;
					repository = $temp$repository;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 3:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
				case 9:
					var environment = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EnvironmentsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postEnvironment, apiConfig, environment, $author$project$Pages$Main$EnvironmentsView$GotPostEnvironment),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 10:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EnvironmentsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postEnvironmentGroup, apiConfig, group, $author$project$Pages$Main$EnvironmentsView$GotPostGroup),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 11:
					var environment = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EnvironmentsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteEnvironment, apiConfig, environment.db, $author$project$Pages$Main$EnvironmentsView$GotDeleteEnvironment),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$EnvironmentsView$positionUpdatingConfig, repository, environment.dM)));
				case 12:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$EnvironmentsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteEnvironmentGroup, apiConfig, group.db, $author$project$Pages$Main$EnvironmentsView$GotDeleteGroup),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$EnvironmentsView$positionUpdatingConfig, repository, group.dM)));
				case 15:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$EnvironmentsView$onGotPostResult,
						{
							fa: A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel, $author$project$Lib$Form$FormView$makeModel),
								$author$project$Forms$EnvironmentForms$createEnvironmentFormValues),
							fK: function (environment) {
								return _Utils_update(
									repository,
									{
										gg: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.gg, environment)
									});
							}
						},
						model,
						result);
				case 16:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$EnvironmentsView$onGotPostResult,
						{
							fa: A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel, $author$project$Lib$Form$FormView$makeModel),
							fK: function (group) {
								return _Utils_update(
									repository,
									{
										ak: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.ak, group)
									});
							}
						},
						model,
						result);
				case 13:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$EnvironmentsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									gg: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.gg, id)
								});
						},
						model,
						httpResult);
				default:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$EnvironmentsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									ak: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.ak, id)
								});
						},
						model,
						httpResult);
			}
		}
	});
var $author$project$Pages$Main$LogsView$BeginLoadLogPage = {$: 1};
var $author$project$Pages$Main$LogsView$GotLogPage = function (a) {
	return {$: 2, a: a};
};
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $author$project$Api$LogApi$LogPageData = F2(
	function (logEntries, totalPageCount) {
		return {kB: logEntries, mF: totalPageCount};
	});
var $author$project$Api$LogApi$logPageResponseDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Api$LogApi$LogPageData,
	A2(
		$elm$json$Json$Decode$field,
		'logEntries',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string)),
	A2($elm$json$Json$Decode$field, 'totalPageCount', $elm$json$Json$Decode$int));
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Utils$UrlBuilderUtils$maybeString = F3(
	function (key, value, params) {
		if (!value.$) {
			var value_ = value.a;
			return A2(
				$elm$core$List$cons,
				A2($elm$url$Url$Builder$string, key, value_),
				params);
		} else {
			return params;
		}
	});
var $Chadtech$elm_bool_extra$Bool$Extra$toString = function (bool) {
	return bool ? 'True' : 'False';
};
var $author$project$Api$LogApi$getLogPage = F3(
	function (_v0, logPageQuery, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Api$LogApi$logPageResponseDecoder),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					$author$project$Api$ApiUrls$aavoBranchHeader,
					selectedBranchKey,
					$lukewestby$elm_http_builder$HttpBuilder$get(
						A2(
							$elm$url$Url$Builder$relative,
							_List_fromArray(
								[$author$project$Api$ApiUrls$adminUrlRoot, 'logs']),
							A3(
								$author$project$Utils$UrlBuilderUtils$maybeString,
								'commandKey',
								logPageQuery.f3,
								A3(
									$author$project$Utils$UrlBuilderUtils$maybeString,
									'commandId',
									logPageQuery.f2,
									A3(
										$author$project$Utils$UrlBuilderUtils$maybeString,
										'logId',
										logPageQuery.gB,
										_List_fromArray(
											[
												A2($elm$url$Url$Builder$int, 'pageNumber', logPageQuery.gL),
												A2($elm$url$Url$Builder$int, 'pageSize', logPageQuery.gM),
												A2(
												$elm$url$Url$Builder$string,
												'errorsOnly',
												$Chadtech$elm_bool_extra$Bool$Extra$toString(logPageQuery.gh))
											])))))))));
	});
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {gu: index, kH: match, aS: number, mp: submatches};
	});
var $elm$regex$Regex$contains = _Regex_contains;
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{h5: false, kZ: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $elm_community$string_extra$String$Extra$regexFromString = A2(
	$elm$core$Basics$composeR,
	$elm$regex$Regex$fromString,
	$elm$core$Maybe$withDefault($elm$regex$Regex$never));
var $elm_community$string_extra$String$Extra$isBlank = function (string) {
	return A2(
		$elm$regex$Regex$contains,
		$elm_community$string_extra$String$Extra$regexFromString('^\\s*$'),
		string);
};
var $elm_community$string_extra$String$Extra$nonBlank = function (string) {
	return $elm_community$string_extra$String$Extra$isBlank(string) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(string);
};
var $author$project$Pages$Main$LogsView$update = F4(
	function (apiConfig, repository, msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(model);
			case 1:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							Q: $author$project$Lib$RemoteData$toLoading(model.Q)
						}),
					A3(
						$author$project$Api$LogApi$getLogPage,
						apiConfig,
						{
							f2: $elm_community$string_extra$String$Extra$nonBlank(model.bh),
							f3: $elm_community$string_extra$String$Extra$nonBlank(model.bi),
							gh: model.bv,
							gB: $elm_community$string_extra$String$Extra$nonBlank(model.bL),
							gL: model.gL,
							gM: model.gM
						},
						$author$project$Pages$Main$LogsView$GotLogPage),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 2:
				var httpResult = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							Q: $author$project$Lib$RemoteData$fromResult(httpResult)
						}));
			case 3:
				var index = msg.a;
				var newState = msg.b;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bH: A3($elm$core$Dict$insert, index, newState, model.bH)
						}));
			case 4:
				return A4(
					$author$project$Pages$Main$LogsView$update,
					apiConfig,
					repository,
					$author$project$Pages$Main$LogsView$BeginLoadLogPage,
					_Utils_update(
						model,
						{gL: model.gL - 1}));
			case 5:
				return A4(
					$author$project$Pages$Main$LogsView$update,
					apiConfig,
					repository,
					$author$project$Pages$Main$LogsView$BeginLoadLogPage,
					_Utils_update(
						model,
						{gL: model.gL + 1}));
			case 8:
				var errorsOnlyInput = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bv: errorsOnlyInput}));
			case 6:
				var pageNumberInput = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							gL: A2(
								$elm$core$Maybe$withDefault,
								0,
								$elm$core$String$toInt(pageNumberInput))
						}));
			case 7:
				var pageSizeInput = msg.a;
				return A4(
					$author$project$Pages$Main$LogsView$update,
					apiConfig,
					repository,
					$author$project$Pages$Main$LogsView$BeginLoadLogPage,
					_Utils_update(
						model,
						{
							gM: A2(
								$elm$core$Maybe$withDefault,
								model.gM,
								$elm$core$String$toInt(pageSizeInput))
						}));
			case 9:
				var logIdInput = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bL: logIdInput}));
			case 10:
				var commandIdInput = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bh: commandIdInput}));
			default:
				var commandKeyInput = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bi: commandKeyInput}));
		}
	});
var $author$project$Pages$Main$PrincipalsView$AddNewPrincipalAssociationModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$PrincipalsView$BeginDeleteGroup = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$PrincipalsView$BeginDeletePrincipal = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$PrincipalsView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$PrincipalsView$BeginEditPrincipal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$PrincipalsView$BeginSaveGroup = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$PrincipalsView$BeginSavePrincipal = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$PrincipalsView$GotDeleteGroup = function (a) {
	return {$: 19, a: a};
};
var $author$project$Pages$Main$PrincipalsView$GotDeletePrincipal = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Main$PrincipalsView$GotPostGroup = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$PrincipalsView$GotPostNewApiTokenAssociation = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$PrincipalsView$GotPostNewRegularUserAssociation = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$PrincipalsView$GotPutPrincipalAssociation = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Main$PrincipalsView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$PrincipalsView$NoOp = {$: 0};
var $author$project$Pages$Main$PrincipalsView$RevealApiTokenModal = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Forms$PrincipalForms$createUserSettingsFormValues = function (_v0) {
	var userParameters = _v0.mV;
	var useNfc = _v0.mS;
	return {
		mS: useNfc,
		mV: A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, userParameters)
	};
};
var $author$project$Forms$PrincipalForms$createPrincipalFormValues = function (principal) {
	return {
		i$: principal.i$,
		jo: principal.jo,
		j_: principal.j_,
		db: principal.db,
		dM: principal.dM,
		lv: principal.lv,
		lw: principal.lw,
		l5: $elm$core$String$fromInt(principal.l5),
		mW: $author$project$Forms$PrincipalForms$createUserSettingsFormValues(principal.mW)
	};
};
var $author$project$Api$EntityApi$deletePrincipalAssociation = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$principalAssociationsUrl);
var $author$project$Api$EntityApi$deletePrincipalAssociationGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$principalAssociationGroupsUrl);
var $author$project$Pages$Main$PrincipalsView$insertPrincipalAssociationOutMsg = F2(
	function (repository, principalAssociation) {
		return $author$project$Pages$Main$OutMsg$UpdateRepository(
			_Utils_update(
				repository,
				{
					fq: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.fq, principalAssociation)
				}));
	});
var $author$project$Pages$Main$PrincipalsView$filterByPrincipalType = F2(
	function (filterValue, principal) {
		var requiredValue = function () {
			var _v0 = principal.lw;
			if (!_v0.$) {
				return 'user';
			} else {
				return 'api_token';
			}
		}();
		return _Utils_eq(filterValue, requiredValue);
	});
var $author$project$Entity$PrincipalAssociation$getEmailOrClientId = function (principalAssociation) {
	var _v0 = principalAssociation.lw;
	if (!_v0.$) {
		var email = _v0.a.gd;
		return email;
	} else {
		var clientId = _v0.a.ie;
		return clientId;
	}
};
var $author$project$Entity$PrincipalAssociation$getTypeAsString = function (principalAssociation) {
	var _v0 = principalAssociation.lw;
	if (!_v0.$) {
		return 'Käyttäjä';
	} else {
		return 'Token';
	}
};
var $author$project$Pages$Main$PrincipalsView$getEntityTreeItemColumns = function (principal) {
	return _List_fromArray(
		[
			$author$project$Entity$PrincipalAssociation$getTypeAsString(principal),
			$elm$core$String$fromInt(principal.db),
			$author$project$Entity$PrincipalAssociation$getEmailOrClientId(principal),
			$author$project$Utils$StringUtils$firstLine(principal.i$)
		]);
};
var $author$project$Entity$PrincipalAssociation$getPrincipalId = function (principalAssociation) {
	var _v0 = principalAssociation.lv;
	if (!_v0.$) {
		var preparedPrincipalId = _v0.a.ls;
		return preparedPrincipalId;
	} else {
		var principalId = _v0.a.lu;
		return principalId;
	}
};
var $author$project$Pages$Main$PrincipalsView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$entityTypeFilter,
			$author$project$Pages$Main$PrincipalsView$filterByPrincipalType,
			_List_fromArray(
				[
					_Utils_Tuple2('user', 'Käyttäjä'),
					_Utils_Tuple2('api_token', 'API-token')
				])),
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					$author$project$Entity$PrincipalAssociation$getEmailOrClientId,
					function ($) {
					return $.i$;
				},
					$author$project$Entity$PrincipalAssociation$getPrincipalId
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.fq;
	},
	jT: $author$project$Pages$Main$PrincipalsView$getEntityTreeItemColumns,
	jU: $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields,
	gp: function ($) {
		return $.fp;
	}
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Entity$IdentityProvider$isInternalIdentityProvider = function (identityProvider) {
	var _v0 = identityProvider.mM;
	if (!_v0) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Basics$not = _Basics_not;
var $author$project$Entity$IdentityProvider$isExternalIdentityProvider = function (identityProvider) {
	return !$author$project$Entity$IdentityProvider$isInternalIdentityProvider(identityProvider);
};
var $elm_community$maybe_extra$Maybe$Extra$or = F2(
	function (ma, mb) {
		if (ma.$ === 1) {
			return mb;
		} else {
			return ma;
		}
	});
var $author$project$Forms$CreateNewPrincipalAssociationForm$makeNewPrincipalAssociationFormValues = function (identityProviders) {
	var defaultIdentityProviderId = A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.db;
			},
			A2(
				$elm_community$maybe_extra$Maybe$Extra$or,
				A2($elm_community$list_extra$List$Extra$find, $author$project$Entity$IdentityProvider$isExternalIdentityProvider, identityProviders),
				$elm$core$List$head(identityProviders))));
	return {
		kc: defaultIdentityProviderId,
		fl: A2($author$project$Forms$CommonForms$createOptionalFormValues, '', $elm$core$Maybe$Nothing),
		at: 'user',
		b7: ''
	};
};
var $author$project$Pages$Main$PrincipalsView$onGotDeleteResult = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$PrincipalsView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$PrincipalsView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$PrincipalsView$MessageModal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$PrincipalsView$ConfirmationModal = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			switch (_v1.$) {
				case 1:
					var errorText = _v1.a.aH;
					var formModel = _v1.a.gl;
					return $author$project$Pages$Main$PrincipalsView$AddNewPrincipalAssociationModal(
						{
							aH: errorText,
							gl: A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel)
						});
				case 3:
					var config = _v1.a;
					return $author$project$Pages$Main$PrincipalsView$ConfirmationModal(
						_Utils_update(
							config,
							{ha: status}));
				default:
					return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Pages$Main$PrincipalsView$updateNewPrincipalModal = F2(
	function (func, model) {
		var modal = function () {
			var _v0 = model.bO;
			if (_v0.$ === 1) {
				var modalRec = _v0.a;
				return $author$project$Pages$Main$PrincipalsView$AddNewPrincipalAssociationModal(
					func(modalRec));
			} else {
				return model.bO;
			}
		}();
		return _Utils_update(
			model,
			{bO: modal});
	});
var $author$project$Pages$Main$PrincipalsView$onPostNewRegularUserAssociationOkResult = F3(
	function (repository, model, result) {
		var model_ = A2($author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$Idle, model);
		if (!result.$) {
			var userAlreadyExisted = result.a.mU;
			var principalAssociation = result.a.lt;
			var _v1 = userAlreadyExisted ? _Utils_Tuple2('Vanha käyttäjä yhdistettiin', 'Olemassa oleva käyttäjä yhdistettiin asiakkaaseen.') : _Utils_Tuple2('Uusi käyttäjä luotiin', 'Uusi käyttäjä luotiin ja yhdistettiin asiakkaaseen.');
			var messageTitle = _v1.a;
			var messageContent = _v1.b;
			return _Utils_Tuple3(
				_Utils_update(
					model_,
					{
						j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
							$author$project$Lib$Form$FormView$makeModel(
								$author$project$Forms$PrincipalForms$createPrincipalFormValues(principalAssociation))),
						bO: $author$project$Pages$Main$PrincipalsView$MessageModal(
							{ig: $author$project$Pages$Main$PrincipalsView$CloseCurrentModal, iB: messageContent, mC: messageTitle})
					}),
				$elm$core$Platform$Cmd$none,
				A2($author$project$Pages$Main$PrincipalsView$insertPrincipalAssociationOutMsg, repository, principalAssociation));
		} else {
			return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
				A2(
					$author$project$Pages$Main$PrincipalsView$updateNewPrincipalModal,
					function (modalState) {
						return _Utils_update(
							modalState,
							{aH: 'Käyttäjä on jo olemassa ja yhdistetty.'});
					},
					model));
		}
	});
var $author$project$Pages$Main$PrincipalsView$positionUpdatingConfig = {
	go: function ($) {
		return $.fq;
	},
	gp: function ($) {
		return $.fp;
	},
	l6: F2(
		function (principals, repo) {
			return _Utils_update(
				repo,
				{
					fq: $author$project$Lib$RemoteData$Success(principals)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					fp: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $author$project$Entity$PrincipalAssociation$ApiTokenWithSecret = F2(
	function (clientId, clientSecret) {
		return {ie: clientId, $8: clientSecret};
	});
var $author$project$Entity$PrincipalAssociation$CreateApiTokenAssociationResult = F2(
	function (association, token) {
		return {hO: association, mE: token};
	});
var $author$project$Decoding$PrincipalDecoding$createApiTokenAssociationResultDecoder = A3(
	$elm$json$Json$Decode$map2,
	$author$project$Entity$PrincipalAssociation$CreateApiTokenAssociationResult,
	A2($elm$json$Json$Decode$field, 'principalAssociation', $author$project$Decoding$PrincipalDecoding$principalDecoder),
	A2(
		$elm$json$Json$Decode$field,
		'token',
		A3(
			$elm$json$Json$Decode$map2,
			$author$project$Entity$PrincipalAssociation$ApiTokenWithSecret,
			A2($elm$json$Json$Decode$field, 'clientId', $elm$json$Json$Decode$string),
			A2($elm$json$Json$Decode$field, 'clientSecret', $elm$json$Json$Decode$string))));
var $author$project$Api$EntityApi$postApiTokenAssociation = F2(
	function (_v0, toMsg) {
		var selectedBranchKey = _v0.l$;
		var url = $author$project$Api$ApiUrls$principalAssociationsUrl + '/apiToken';
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A3(
				$lukewestby$elm_http_builder$HttpBuilder$withHeader,
				$author$project$Api$ApiUrls$aavoBranchHeader,
				selectedBranchKey,
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withExpect,
					A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Decoding$PrincipalDecoding$createApiTokenAssociationResultDecoder),
					$lukewestby$elm_http_builder$HttpBuilder$post(url))));
	});
var $author$project$Api$EntityApi$postPrincipalAssociationGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$principalAssociationGroupsUrl, $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Entity$PrincipalAssociation$CreateUserSucceeded = function (a) {
	return {$: 0, a: a};
};
var $author$project$Entity$PrincipalAssociation$TCreateUserSucceeded = F2(
	function (userAlreadyExisted, principalAssociation) {
		return {lt: principalAssociation, mU: userAlreadyExisted};
	});
var $author$project$Entity$PrincipalAssociation$UserAlreadyAssociated = {$: 1};
var $author$project$Decoding$PrincipalDecoding$createRegularPrincipalAssociationResultDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (type_) {
		var mapSuccessfulResult = function (userAlreadyExisted) {
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Entity$PrincipalAssociation$CreateUserSucceeded,
				A3(
					$elm$json$Json$Decode$map2,
					$author$project$Entity$PrincipalAssociation$TCreateUserSucceeded,
					$elm$json$Json$Decode$succeed(userAlreadyExisted),
					A2($elm$json$Json$Decode$field, 'association', $author$project$Decoding$PrincipalDecoding$principalDecoder)));
		};
		switch (type_) {
			case 'newUserCreated':
				return mapSuccessfulResult(false);
			case 'existingUserAssociated':
				return mapSuccessfulResult(true);
			case 'userAlreadyAssociated':
				return $elm$json$Json$Decode$succeed($author$project$Entity$PrincipalAssociation$UserAlreadyAssociated);
			default:
				return $elm$json$Json$Decode$fail('Unknown CreateRegularUserAssociationResult type ' + type_);
		}
	},
	A2($elm$json$Json$Decode$field, 'type', $elm$json$Json$Decode$string));
var $author$project$Encoding$PrincipalEncoding$encodePostRegularUserAssociationDto = function (dto) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				$elm$json$Json$Encode$string(dto.gd)),
				_Utils_Tuple2(
				'identityProviderId',
				$elm$json$Json$Encode$string(dto.kc)),
				_Utils_Tuple2(
				'password',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, dto.fl))
			]));
};
var $author$project$Api$EntityApi$postRegularUserAssociation = function () {
	var url = $author$project$Api$ApiUrls$principalAssociationsUrl + '/user';
	return A3($author$project$Api$EntityApi$postEntity, url, $author$project$Encoding$PrincipalEncoding$encodePostRegularUserAssociationDto, $author$project$Decoding$PrincipalDecoding$createRegularPrincipalAssociationResultDecoder);
}();
var $author$project$Utils$TimeUtils$daysToSeconds = function (days) {
	return ((days * 60) * 60) * 24;
};
var $author$project$Encoding$PrincipalEncoding$encodeEnvironmentAssociation = function (environmentAssociation) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'environmentId',
				$elm$json$Json$Encode$int(environmentAssociation.jp)),
				_Utils_Tuple2(
				'roleId',
				$elm$json$Json$Encode$int(environmentAssociation.lS))
			]));
};
var $author$project$Encoding$PrincipalEncoding$encodePrincipalState = function (principalState) {
	return $elm$json$Json$Encode$object(
		function () {
			if (!principalState.$) {
				var preparedPrincipalId = principalState.a.ls;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('prepared')),
						_Utils_Tuple2(
						'preparedPrincipalId',
						$elm$json$Json$Encode$string(preparedPrincipalId))
					]);
			} else {
				var principalId = principalState.a.lu;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('ready')),
						_Utils_Tuple2(
						'principalId',
						$elm$json$Json$Encode$string(principalId))
					]);
			}
		}());
};
var $author$project$Encoding$PrincipalEncoding$encodePrincipalType = function (principalType) {
	return $elm$json$Json$Encode$object(
		function () {
			if (!principalType.$) {
				var email = principalType.a.gd;
				var mfaEnabled = principalType.a.kT;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('user')),
						_Utils_Tuple2(
						'email',
						$elm$json$Json$Encode$string(email)),
						_Utils_Tuple2(
						'mfaEnabled',
						$elm$json$Json$Encode$bool(mfaEnabled))
					]);
			} else {
				var clientId = principalType.a.ie;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'type',
						$elm$json$Json$Encode$string('token')),
						_Utils_Tuple2(
						'tokenCredentials',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'clientId',
									$elm$json$Json$Encode$string(clientId))
								])))
					]);
			}
		}());
};
var $author$project$Encoding$PrincipalEncoding$encodeUserSettings = function (userSettings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'userParameters',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, userSettings.mV)),
				_Utils_Tuple2(
				'useNfc',
				$elm$json$Json$Encode$bool(userSettings.mS))
			]));
};
var $author$project$Encoding$PrincipalEncoding$encodePrincipalAssociation = function (principal) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'principalState',
				$author$project$Encoding$PrincipalEncoding$encodePrincipalState(principal.lv)),
				_Utils_Tuple2(
				'principalType',
				$author$project$Encoding$PrincipalEncoding$encodePrincipalType(principal.lw)),
				_Utils_Tuple2(
				'sessionExpirationDurationSeconds',
				$elm$json$Json$Encode$int(
					$author$project$Utils$TimeUtils$daysToSeconds(principal.l5))),
				_Utils_Tuple2(
				'environmentAssociations',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$PrincipalEncoding$encodeEnvironmentAssociation, principal.jo)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(principal.i$)),
				_Utils_Tuple2(
				'userSettings',
				$author$project$Encoding$PrincipalEncoding$encodeUserSettings(principal.mW)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(principal.db)),
				_Utils_Tuple2(
				'groupId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, principal.j_)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(principal.dM))
			]));
};
var $author$project$Api$EntityApi$putPrincipalAssociation = F3(
	function (_v0, principalAssociation, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Decoding$PrincipalDecoding$principalDecoder),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					$author$project$Api$ApiUrls$aavoBranchHeader,
					selectedBranchKey,
					A2(
						$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
						$author$project$Encoding$PrincipalEncoding$encodePrincipalAssociation(principalAssociation),
						$lukewestby$elm_http_builder$HttpBuilder$put($author$project$Api$ApiUrls$principalAssociationsUrl)))));
	});
var $author$project$Api$EntityApi$savePrincipalAssociationGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$principalAssociationGroupsUrl);
var $author$project$Api$EntityApi$savePrincipalAssociationPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$principalAssociationsUrl);
var $author$project$Pages$Main$PrincipalsView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$PrincipalsView$ConfirmationModal(
						{h4: $author$project$Pages$Main$PrincipalsView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Lib$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$Pages$Main$PrincipalsView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$PrincipalsView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$PrincipalsView$BeginEditPrincipal(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$PrincipalsView$BeginEditGroup(group));
			case 4:
				var identityProviders = A2($author$project$Lib$RemoteData$withDefault, _List_Nil, repository.e5);
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$PrincipalsView$AddNewPrincipalAssociationModal(
								{
									aH: '',
									gl: $author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$CreateNewPrincipalAssociationForm$makeNewPrincipalAssociationFormValues(identityProviders))
								})
						}));
			case 5:
				return newMsg(
					$author$project$Pages$Main$PrincipalsView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var principal = outMsg.a;
				var message = function () {
					var _v5 = principal.lw;
					if (!_v5.$) {
						var email = _v5.a.gd;
						return 'Haluatko varmasti poistaa käyttäjän ' + (email + '?');
					} else {
						var clientId = _v5.a.ie;
						return 'Haluatko varmasti poistaa API-Tokenin ' + (clientId + '?');
					}
				}();
				return A3(
					$author$project$Pages$Main$PrincipalsView$showConfirmModal,
					model,
					message,
					$author$project$Pages$Main$PrincipalsView$BeginDeletePrincipal(principal));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$PrincipalsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$PrincipalsView$BeginDeleteGroup(group));
			case 8:
				var principal = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$PrincipalsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(principal));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$PrincipalsView$update, apiConfig, repo),
					$author$project$Pages$Main$PrincipalsView$BeginSavePrincipal(principal),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$savePrincipalAssociationPosition,
							apiConfig,
							principal,
							$elm$core$Basics$always($author$project$Pages$Main$PrincipalsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$PrincipalsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$PrincipalsView$update, apiConfig, repo),
					$author$project$Pages$Main$PrincipalsView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$savePrincipalAssociationGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$PrincipalsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$PrincipalsView$update = F4(
	function (apiConfig, repository, msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				var listingViewMsg = msg.a;
				var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$PrincipalsView$listingViewConfig, repository, listingViewMsg, model.ap);
				var listingViewModel = _v1.a;
				var listingViewCmd = _v1.b;
				var listingViewOutMsg = _v1.c;
				return A5(
					$author$project$Pages$Main$PrincipalsView$handleListingViewOutMsg,
					apiConfig,
					repository,
					_Utils_update(
						model,
						{ap: listingViewModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$PrincipalsView$ListingViewMsg, listingViewCmd),
					listingViewOutMsg);
			case 4:
				var principal = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
								$author$project$Lib$Form$FormView$makeModel(
									$author$project$Forms$PrincipalForms$createPrincipalFormValues(principal))),
							bO: $author$project$Pages$Main$PrincipalsView$Hidden
						}));
			case 5:
				var group = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
								$author$project$Lib$Form$FormView$makeModel(group))
						}));
			case 2:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$PrincipalsView$Hidden}));
			case 6:
				var newValues = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
						}));
			case 7:
				var newValues = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
						}));
			case 8:
				var newFormModel = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					A2(
						$author$project$Pages$Main$PrincipalsView$updateNewPrincipalModal,
						function (modalState) {
							return _Utils_update(
								modalState,
								{gl: newFormModel});
						},
						model));
			case 9:
				var principalType = msg.a.lw;
				var identityProviderId = msg.a.kc;
				var password = msg.a.fl;
				var cmd = function () {
					if (!principalType.$) {
						var email = principalType.a.gd;
						return A3(
							$author$project$Api$EntityApi$postRegularUserAssociation,
							apiConfig,
							{gd: email, kc: identityProviderId, fl: password},
							$author$project$Pages$Main$PrincipalsView$GotPostNewRegularUserAssociation);
					} else {
						return A2($author$project$Api$EntityApi$postApiTokenAssociation, apiConfig, $author$project$Pages$Main$PrincipalsView$GotPostNewApiTokenAssociation);
					}
				}();
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					cmd,
					$author$project$Pages$Main$OutMsg$NoOp);
			case 3:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
			case 10:
				var principalAssociation = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A3($author$project$Api$EntityApi$putPrincipalAssociation, apiConfig, principalAssociation, $author$project$Pages$Main$PrincipalsView$GotPutPrincipalAssociation),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 11:
				var group = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A3($author$project$Api$EntityApi$postPrincipalAssociationGroup, apiConfig, group, $author$project$Pages$Main$PrincipalsView$GotPostGroup),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 12:
				var principal = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A3($author$project$Api$EntityApi$deletePrincipalAssociation, apiConfig, principal.db, $author$project$Pages$Main$PrincipalsView$GotDeletePrincipal),
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$PrincipalsView$positionUpdatingConfig, repository, principal.dM)));
			case 13:
				var group = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$PrincipalsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A3($author$project$Api$EntityApi$deletePrincipalAssociationGroup, apiConfig, group.db, $author$project$Pages$Main$PrincipalsView$GotDeleteGroup),
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$PrincipalsView$positionUpdatingConfig, repository, group.dM)));
			case 18:
				var result = msg.a;
				return A3(
					$author$project$Pages$Main$PrincipalsView$onGotDeleteResult,
					function (id) {
						return _Utils_update(
							repository,
							{
								fq: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.fq, id)
							});
					},
					model,
					result);
			case 19:
				var result = msg.a;
				return A3(
					$author$project$Pages$Main$PrincipalsView$onGotDeleteResult,
					function (id) {
						return _Utils_update(
							repository,
							{
								fp: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.fp, id)
							});
					},
					model,
					result);
			case 17:
				var httpResult = msg.a;
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
					function (principal) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									j: A2(
										$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
										model.j,
										$author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
											$author$project$Lib$Form$FormView$makeModel(
												$author$project$Forms$PrincipalForms$createPrincipalFormValues(principal))))
								}),
							$elm$core$Platform$Cmd$none,
							A2($author$project$Pages$Main$PrincipalsView$insertPrincipalAssociationOutMsg, repository, principal));
					},
					model,
					httpResult);
			case 14:
				var httpResult = msg.a;
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
					A2($author$project$Pages$Main$PrincipalsView$onPostNewRegularUserAssociationOkResult, repository, model),
					model,
					httpResult);
			case 15:
				var httpResult = msg.a;
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
					function (_v3) {
						var token = _v3.mE;
						var association = _v3.hO;
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									bO: A2($author$project$Pages$Main$PrincipalsView$RevealApiTokenModal, token, association)
								}),
							$elm$core$Platform$Cmd$none,
							A2($author$project$Pages$Main$PrincipalsView$insertPrincipalAssociationOutMsg, repository, association));
					},
					model,
					httpResult);
			default:
				var httpResult = msg.a;
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
					function (group) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									j: A2(
										$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
										model.j,
										$author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
											$author$project$Lib$Form$FormView$makeModel(group)))
								}),
							$elm$core$Platform$Cmd$none,
							$author$project$Pages$Main$OutMsg$UpdateRepository(
								_Utils_update(
									repository,
									{
										fp: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.fp, group)
									})));
					},
					model,
					httpResult);
		}
	});
var $author$project$Pages$Main$RevisionsView$BeginLoadRevisions = {$: 3};
var $author$project$Pages$Main$RevisionsView$CloseCurrentModal = {$: 1};
var $author$project$Pages$Main$RevisionsView$CopyBranchModal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$RevisionsView$CreateRevisionModal = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$RevisionsView$ErrorModal = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$RevisionsView$GotCopyBranch = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Main$RevisionsView$GotCreateNewRevision = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Main$RevisionsView$GotRestoreRevision = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$RevisionsView$GotRevisions = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$RevisionsView$RestoreRevisionModal = function (a) {
	return {$: 3, a: a};
};
var $author$project$Api$RevisionApi$encodeCopyBranchRequest = function (request) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'sourceBranchKey',
				$elm$json$Json$Encode$string(request.ed)),
				_Utils_Tuple2(
				'targetBranchKey',
				$elm$json$Json$Encode$string(request.ei)),
				_Utils_Tuple2(
				'targetBackupRevisionName',
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$map($elm$json$Json$Encode$string),
					$elm$core$Maybe$withDefault($elm$json$Json$Encode$null))(request.hd))
			]));
};
var $author$project$Api$RevisionApi$revisionsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/revisions';
var $author$project$Api$RevisionApi$copyBranchToAnother = F2(
	function (toMsg, request) {
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$author$project$Lib$CustomHttp$expectWhateverCustom(toMsg),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
					$author$project$Api$RevisionApi$encodeCopyBranchRequest(request),
					$lukewestby$elm_http_builder$HttpBuilder$post($author$project$Api$RevisionApi$revisionsUrl + '/copyBranch'))));
	});
var $author$project$Api$RevisionApi$encodeCreateRevisionRequest = function (request) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'sourceBranchKey',
				$elm$json$Json$Encode$string(request.ed)),
				_Utils_Tuple2(
				'revisionName',
				$elm$json$Json$Encode$string(request.g$)),
				_Utils_Tuple2(
				'revisionDescription',
				$elm$json$Json$Encode$string(request.gZ))
			]));
};
var $author$project$Entity$Revision$Revision = F5(
	function (id, name, description, dumpFileName, createdAt) {
		return {iG: createdAt, i$: description, i9: dumpFileName, db: id, k_: name};
	});
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $author$project$Decoding$RevisionDecoding$revisionDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'createdAtUnixMillis',
	A2($elm$json$Json$Decode$map, $elm$time$Time$millisToPosix, $elm$json$Json$Decode$int),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dumpFileName',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'description',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$Entity$Revision$Revision))))));
var $author$project$Api$RevisionApi$createNewRevision = F2(
	function (toMsg, request) {
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Decoding$RevisionDecoding$revisionDecoder),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
					$author$project$Api$RevisionApi$encodeCreateRevisionRequest(request),
					$lukewestby$elm_http_builder$HttpBuilder$post($author$project$Api$RevisionApi$revisionsUrl))));
	});
var $author$project$Encoding$Utils$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.fC, posixMinutes) < 0) {
					return posixMinutes + era.gH;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		f6: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		gE: month,
		ht: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).f6;
	});
var $elm$core$Basics$modBy = _Basics_modBy;
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gE;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ht;
	});
var $author$project$Encoding$Utils$formatTime = F2(
	function (posix, zone) {
		var formatToUnit = function (toUnit) {
			return $elm$core$String$fromInt(
				A2(toUnit, zone, posix));
		};
		return formatToUnit($elm$time$Time$toYear) + ('-' + ($elm$core$String$fromInt(
			$author$project$Encoding$Utils$monthToInt(
				A2($elm$time$Time$toMonth, zone, posix))) + ('-' + (formatToUnit($elm$time$Time$toDay) + ('T' + (formatToUnit($elm$time$Time$toHour) + (':' + (formatToUnit($elm$time$Time$toMinute) + (':' + formatToUnit($elm$time$Time$toSecond))))))))));
	});
var $author$project$Api$RevisionApi$getRevisions = function (toMsg) {
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			A2(
				$author$project$Lib$CustomHttp$expectJsonCustom,
				toMsg,
				$elm$json$Json$Decode$list($author$project$Decoding$RevisionDecoding$revisionDecoder)),
			$lukewestby$elm_http_builder$HttpBuilder$get($author$project$Api$RevisionApi$revisionsUrl)));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Api$RevisionApi$encodeRestoreRevisionRequest = function (request) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'targetBranchKey',
				$elm$json$Json$Encode$string(request.ei)),
				_Utils_Tuple2(
				'revisionId',
				$elm$json$Json$Encode$int(request.g_))
			]));
};
var $author$project$Api$RevisionApi$restoreRevision = F2(
	function (toMsg, request) {
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$author$project$Lib$CustomHttp$expectWhateverCustom(toMsg),
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
					$author$project$Api$RevisionApi$encodeRestoreRevisionRequest(request),
					$lukewestby$elm_http_builder$HttpBuilder$post($author$project$Api$RevisionApi$revisionsUrl + '/restore'))));
	});
var $author$project$Pages$Main$RevisionsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v0 = model.bO;
			switch (_v0.$) {
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$RevisionsView$CreateRevisionModal(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 3:
					var formModel = _v0.a;
					return $author$project$Pages$Main$RevisionsView$RestoreRevisionModal(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 4:
					var formModel = _v0.a;
					return $author$project$Pages$Main$RevisionsView$CopyBranchModal(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return model.bO;
			}
		}();
		return _Utils_update(
			model,
			{bO: modal});
	});
var $author$project$Pages$Main$RevisionsView$update = F3(
	function (apiConfig, msg, model) {
		var handleHttpResult = F3(
			function (onSuccess, result, model_) {
				if (result.$ === 1) {
					var err = result.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model_,
							{
								bO: $author$project$Pages$Main$RevisionsView$ErrorModal(
									{
										ig: $author$project$Pages$Main$RevisionsView$CloseCurrentModal,
										iB: $author$project$Utils$HttpUtils$httpErrorToString(err)
									})
							}));
				} else {
					var result_ = result.a;
					return onSuccess(result_);
				}
			});
		switch (msg.$) {
			case 0:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(model);
			case 2:
				var revisionId = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							av: $elm$core$Maybe$Just(revisionId)
						}));
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aW: $author$project$Lib$RemoteData$toLoading(model.aW),
							av: $elm$core$Maybe$Nothing
						}),
					$author$project$Api$RevisionApi$getRevisions($author$project$Pages$Main$RevisionsView$GotRevisions),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 4:
				var httpResult = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							aW: $author$project$Lib$RemoteData$fromResult(httpResult)
						}));
			case 5:
				var nextMsg = msg.a;
				return _Utils_Tuple3(
					model,
					A2(
						$elm$core$Task$perform,
						function (_v1) {
							var zone = _v1.a;
							var posix = _v1.b;
							return A2(nextMsg, zone, posix);
						},
						A2(
							$elm$core$Task$andThen,
							function (posix) {
								return A2(
									$elm$core$Task$map,
									function (zone) {
										return _Utils_Tuple2(zone, posix);
									},
									$elm$time$Time$here);
							},
							$elm$time$Time$now)),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 6:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$RevisionsView$CreateRevisionModal(
								$author$project$Lib$Form$FormView$makeModel(
									{gZ: '', g$: '', ed: apiConfig.l$}))
						}));
			case 7:
				var formModel = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$RevisionsView$CreateRevisionModal(formModel)
						}));
			case 8:
				var createRevisionRequest = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$RevisionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A2($author$project$Api$RevisionApi$createNewRevision, $author$project$Pages$Main$RevisionsView$GotCreateNewRevision, createRevisionRequest),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 9:
				var httpResult = msg.a;
				return A3(
					handleHttpResult,
					function (_v2) {
						return A3(
							$author$project$Pages$Main$SubViewUpdateUtils$andThen,
							$author$project$Pages$Main$RevisionsView$update(apiConfig),
							$author$project$Pages$Main$RevisionsView$BeginLoadRevisions,
							A3(
								$author$project$Pages$Main$SubViewUpdateUtils$andThen,
								$author$project$Pages$Main$RevisionsView$update(apiConfig),
								$author$project$Pages$Main$RevisionsView$CloseCurrentModal,
								$author$project$Pages$Main$SubViewUpdateUtils$withoutActions(model)));
					},
					httpResult,
					model);
			case 1:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$RevisionsView$Hidden}));
			case 10:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$RevisionsView$RestoreRevisionModal(
								$author$project$Lib$Form$FormView$makeModel(
									{
										g_: A2($elm$core$Maybe$withDefault, -1, model.av),
										ei: apiConfig.l$
									}))
						}));
			case 11:
				var formModel = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$RevisionsView$RestoreRevisionModal(formModel)
						}));
			case 12:
				var request = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$RevisionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A2($author$project$Api$RevisionApi$restoreRevision, $author$project$Pages$Main$RevisionsView$GotRestoreRevision, request),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 13:
				var httpResult = msg.a;
				return A3(
					handleHttpResult,
					function (_v3) {
						return A3($author$project$Pages$Main$RevisionsView$update, apiConfig, $author$project$Pages$Main$RevisionsView$CloseCurrentModal, model);
					},
					httpResult,
					model);
			case 14:
				var zone = msg.a;
				var posix = msg.b;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$RevisionsView$CopyBranchModal(
								$author$project$Lib$Form$FormView$makeModel(
									{
										bb: false,
										ed: 'dev',
										eh: 'prod_backup_' + A2($author$project$Encoding$Utils$formatTime, posix, zone),
										ei: 'prod'
									}))
						}));
			case 15:
				var formModel = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$RevisionsView$CopyBranchModal(formModel)
						}));
			case 16:
				var request = msg.a;
				return _Utils_Tuple3(
					A2($author$project$Pages$Main$RevisionsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
					A2($author$project$Api$RevisionApi$copyBranchToAnother, $author$project$Pages$Main$RevisionsView$GotCopyBranch, request),
					$author$project$Pages$Main$OutMsg$NoOp);
			default:
				var httpResult = msg.a;
				return A3(
					handleHttpResult,
					function (_v4) {
						return A3($author$project$Pages$Main$RevisionsView$update, apiConfig, $author$project$Pages$Main$RevisionsView$CloseCurrentModal, model);
					},
					httpResult,
					model);
		}
	});
var $author$project$Pages$Main$RolesView$BeginDeleteGroup = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$RolesView$BeginDeleteRole = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$RolesView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$RolesView$BeginEditRole = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$RolesView$BeginSaveGroup = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$RolesView$BeginSaveRole = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Main$RolesView$GotDeleteGroup = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$RolesView$GotDeleteRole = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$RolesView$GotPostGroup = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$RolesView$GotPostRole = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$RolesView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$RolesView$NoOp = {$: 0};
var $author$project$Entity$Role$createNewRole = {hL: _List_Nil, hM: $elm$core$Maybe$Nothing, $9: _List_Nil, iW: $elm$core$Maybe$Nothing, i$: '', j_: $elm$core$Maybe$Nothing, db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, kg: _List_Nil, kr: false, k_: '', gN: _List_Nil, dM: $author$project$Entity$Common$lastPosition, l8: _List_Nil, m$: _List_Nil};
var $author$project$Forms$EntitySelectFormList$EntitySelectFormListValues = function (selectedEntities) {
	return {au: selectedEntities};
};
var $author$project$Forms$EntitySelectFormList$SelectEntityFormValues = function (id) {
	return {db: id};
};
var $author$project$Forms$EntitySelectFormList$createEntitySelectFormListValues = function (selectedIds) {
	return $author$project$Forms$EntitySelectFormList$EntitySelectFormListValues(
		A2($elm$core$List$map, $author$project$Forms$EntitySelectFormList$SelectEntityFormValues, selectedIds));
};
var $author$project$Forms$RoleForms$ViewAssociationFormValues = F3(
	function (viewId, showDirectly, permissionsValues) {
		return {dL: permissionsValues, mc: showDirectly, m_: viewId};
	});
var $author$project$Forms$RoleForms$createViewAssociationFormValues = function (viewAssociation) {
	return A3(
		$author$project$Forms$RoleForms$ViewAssociationFormValues,
		viewAssociation.m_,
		viewAssociation.mc,
		{bo: viewAssociation.gN.iO.jh});
};
var $author$project$Forms$RoleForms$createRoleFormValues = function (role) {
	return {
		cp: $author$project$Forms$EntitySelectFormList$createEntitySelectFormListValues(role.hL),
		hM: role.hM,
		cF: $author$project$Forms$EntitySelectFormList$createEntitySelectFormListValues(role.$9),
		iW: A2($elm$core$Maybe$withDefault, $author$project$Entity$Common$nonExistentId, role.iW),
		i$: role.i$,
		j_: role.j_,
		db: role.db,
		j9: role.j9,
		dd: $author$project$Forms$EntitySelectFormList$createEntitySelectFormListValues(role.kg),
		kr: role.kr,
		k_: role.k_,
		dK: A2($elm$core$String$join, ',', role.gN),
		dM: role.dM,
		d9: $author$project$Forms$EntitySelectFormList$createEntitySelectFormListValues(role.l8),
		ca: A2($elm$core$List$map, $author$project$Forms$RoleForms$createViewAssociationFormValues, role.m$)
	};
};
var $author$project$Api$EntityApi$deleteRole = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$rolesUrl);
var $author$project$Api$EntityApi$deleteRoleGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$roleGroupsUrl);
var $author$project$Pages$Main$RolesView$getEntityTreeItemColumns = function (role) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$core$String$fromInt(role.db),
				role.k_,
				$author$project$Utils$StringUtils$firstLine(role.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(role),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(role)));
};
var $author$project$Pages$Main$RolesView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.g0;
	},
	jT: $author$project$Pages$Main$RolesView$getEntityTreeItemColumns,
	jU: $author$project$Entity$GenericEntityGroup$getGenericEntityGroupListingFields,
	gp: function ($) {
		return $.fx;
	}
};
var $author$project$Pages$Main$RolesView$onGotDelete = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$RolesView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$RolesView$onGotPostResult = F2(
	function (_v0, model) {
		var makeNewEditModel = _v0.fa;
		var updateRepository = _v0.fK;
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (role) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							j: A2(
								$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
								model.j,
								makeNewEditModel(role))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(role)));
			},
			model);
	});
var $author$project$Pages$Main$RolesView$positionUpdatingConfig = {
	go: function ($) {
		return $.g0;
	},
	gp: function ($) {
		return $.fx;
	},
	l6: F2(
		function (roles, repo) {
			return _Utils_update(
				repo,
				{
					g0: $author$project$Lib$RemoteData$Success(roles)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					fx: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $author$project$Encoding$RoleEncoding$encodeViewPermissions = function (permissions) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'dataViewPermissions',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'editingEnabled',
							$elm$json$Json$Encode$bool(permissions.iO.jh))
						])))
			]));
};
var $author$project$Encoding$RoleEncoding$encodeViewAssociation = function (viewAssociation) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'viewId',
				$elm$json$Json$Encode$int(viewAssociation.m_)),
				_Utils_Tuple2(
				'showDirectly',
				$elm$json$Json$Encode$bool(viewAssociation.mc)),
				_Utils_Tuple2(
				'permissions',
				$author$project$Encoding$RoleEncoding$encodeViewPermissions(viewAssociation.gN))
			]));
};
var $author$project$Encoding$RoleEncoding$encodeRole = function (role) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(role.k_)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(role.i$)),
				_Utils_Tuple2(
				'isUserRole',
				$elm$json$Json$Encode$bool(role.kr)),
				_Utils_Tuple2(
				'views',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$RoleEncoding$encodeViewAssociation, role.m$)),
				_Utils_Tuple2(
				'defaultViewId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, role.iW)),
				_Utils_Tuple2(
				'commandIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, role.$9)),
				_Utils_Tuple2(
				'includedRoleIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, role.kg)),
				_Utils_Tuple2(
				'permissions',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, role.gN)),
				_Utils_Tuple2(
				'settingsViewComponentCommandIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, role.l8)),
				_Utils_Tuple2(
				'appBarViewComponentCommandIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, role.hL)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(role.db)),
				_Utils_Tuple2(
				'groupId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, role.j_)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(role.dM)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, role.hM)),
				_Utils_Tuple2(
				'idAnchor',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, role.j9))
			]));
};
var $author$project$Api$EntityApi$postRole = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$rolesUrl, $author$project$Encoding$RoleEncoding$encodeRole, $author$project$Decoding$RoleDecoding$roleDecoder);
var $author$project$Api$EntityApi$postRoleGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$roleGroupsUrl, $author$project$Entity$GenericEntityGroup$encodeGenericEntityGroup, $author$project$Entity$GenericEntityGroup$genericEntityGroupDecoder);
var $author$project$Api$EntityApi$saveRoleGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$roleGroupsUrl);
var $author$project$Api$EntityApi$saveRolePosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$rolesUrl);
var $author$project$Pages$Main$RolesView$Confirm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$RolesView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			if (_v1.$ === 2) {
				var config = _v1.a;
				return $author$project$Pages$Main$RolesView$Confirm(
					_Utils_update(
						config,
						{ha: status}));
			} else {
				return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Pages$Main$RolesView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$RolesView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$RolesView$Confirm(
						{h4: $author$project$Pages$Main$RolesView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Pages$Main$RolesView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$RolesView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$RolesView$BeginEditRole(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$RolesView$BeginEditGroup(group));
			case 4:
				return newMsg(
					$author$project$Pages$Main$RolesView$BeginEditRole(
						_Utils_update(
							$author$project$Entity$Role$createNewRole,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 5:
				return newMsg(
					$author$project$Pages$Main$RolesView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$GenericEntityGroup$createNewGenericEntityGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var role = outMsg.a;
				return A3(
					$author$project$Pages$Main$RolesView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa yhteyden ' + (role.k_ + '?'),
					$author$project$Pages$Main$RolesView$BeginDeleteRole(role));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$RolesView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$RolesView$BeginDeleteGroup(group));
			case 8:
				var role = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$RolesView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(role));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$RolesView$update, apiConfig, repo),
					$author$project$Pages$Main$RolesView$BeginSaveRole(role),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveRolePosition,
							apiConfig,
							role,
							$elm$core$Basics$always($author$project$Pages$Main$RolesView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$RolesView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$RolesView$update, apiConfig, repo),
					$author$project$Pages$Main$RolesView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveRoleGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$RolesView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$RolesView$update = F4(
	function (apiConfig, repository, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
				case 1:
					var listingViewMsg = msg.a;
					var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$RolesView$listingViewConfig, repository, listingViewMsg, model.ap);
					var listingViewModel = _v1.a;
					var listingViewCmd = _v1.b;
					var listingViewOutMsg = _v1.c;
					return A5(
						$author$project$Pages$Main$RolesView$handleListingViewOutMsg,
						apiConfig,
						repository,
						_Utils_update(
							model,
							{ap: listingViewModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$RolesView$ListingViewMsg, listingViewCmd),
						listingViewOutMsg);
				case 4:
					var role = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
									$author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$RoleForms$createRoleFormValues(role)))
							}));
				case 5:
					var group = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
									$author$project$Lib$Form$FormView$makeModel(group))
							}));
				case 2:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{bO: $author$project$Pages$Main$RolesView$Hidden}));
				case 6:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
							}));
				case 7:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
							}));
				case 8:
					var role = msg.a;
					var $temp$apiConfig = apiConfig,
						$temp$repository = repository,
						$temp$msg = $author$project$Pages$Main$RolesView$BeginEditRole(
						_Utils_update(
							role,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})),
						$temp$model = _Utils_update(
						model,
						{bO: $author$project$Pages$Main$RolesView$Hidden});
					apiConfig = $temp$apiConfig;
					repository = $temp$repository;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 3:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
				case 9:
					var role = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$RolesView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postRole, apiConfig, role, $author$project$Pages$Main$RolesView$GotPostRole),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 10:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$RolesView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postRoleGroup, apiConfig, group, $author$project$Pages$Main$RolesView$GotPostGroup),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$RolesView$positionUpdatingConfig, repository, group.dM)));
				case 11:
					var role = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$RolesView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteRole, apiConfig, role.db, $author$project$Pages$Main$RolesView$GotDeleteRole),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$RolesView$positionUpdatingConfig, repository, role.dM)));
				case 12:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$RolesView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteRoleGroup, apiConfig, group.db, $author$project$Pages$Main$RolesView$GotDeleteGroup),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 15:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$RolesView$onGotPostResult,
						{
							fa: A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel, $author$project$Lib$Form$FormView$makeModel),
								$author$project$Forms$RoleForms$createRoleFormValues),
							fK: function (role) {
								return _Utils_update(
									repository,
									{
										g0: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.g0, role)
									});
							}
						},
						model,
						result);
				case 16:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$RolesView$onGotPostResult,
						{
							fa: A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel, $author$project$Lib$Form$FormView$makeModel),
							fK: function (group) {
								return _Utils_update(
									repository,
									{
										fx: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.fx, group)
									});
							}
						},
						model,
						result);
				case 13:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$RolesView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									g0: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.g0, id)
								});
						},
						model,
						httpResult);
				default:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$RolesView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									fx: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.fx, id)
								});
						},
						model,
						httpResult);
			}
		}
	});
var $author$project$Pages$Main$TenantSettingsView$GotTenantSettings = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$TenantSettingsView$createTenantSettingsFormValues = function (settings) {
	return {
		iI: A2($elm$core$Maybe$withDefault, $author$project$Entity$Common$nonExistentId, settings.iI),
		iJ: A2($elm$core$Maybe$withDefault, $author$project$Entity$Common$nonExistentId, settings.iJ),
		i_: settings.i_,
		jm: settings.jm,
		j4: settings.j4,
		kC: settings.kC
	};
};
var $author$project$Entity$TenantSettings$TenantSettings = F6(
	function (delimitersString, cronCommandRunnerRoleId, cronCommandRunnerEnvironmentId, enableMfaForNewUsers, logSuccessfulExecutions, hooks) {
		return {iI: cronCommandRunnerEnvironmentId, iJ: cronCommandRunnerRoleId, i_: delimitersString, jm: enableMfaForNewUsers, j4: hooks, kC: logSuccessfulExecutions};
	});
var $author$project$Entity$TenantSettings$TenantHook = F3(
	function (hookType, commandId, environmentId) {
		return {f2: commandId, jp: environmentId, j3: hookType};
	});
var $author$project$Decoding$TenantSettingsDecoding$tenantHookDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'environmentId',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'commandId',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'hookType',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Entity$TenantSettings$TenantHook))));
var $author$project$Decoding$TenantSettingsDecoding$tenantSettingsDecoder = A7(
	$elm$json$Json$Decode$map6,
	$author$project$Entity$TenantSettings$TenantSettings,
	A2($elm$json$Json$Decode$field, 'delimitersString', $elm$json$Json$Decode$string),
	A2(
		$elm$json$Json$Decode$field,
		'cronCommandRunnerRoleId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2(
		$elm$json$Json$Decode$field,
		'cronCommandRunnerEnvironmentId',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int)),
	A2($elm$json$Json$Decode$field, 'enableMfaForNewUsers', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'logSuccessfulExecutions', $elm$json$Json$Decode$bool),
	A2(
		$elm$json$Json$Decode$field,
		'hooks',
		$elm$json$Json$Decode$list($author$project$Decoding$TenantSettingsDecoding$tenantHookDecoder)));
var $author$project$Api$ApiUrls$tenantSettingsUrl = $author$project$Api$ApiUrls$adminUrlRoot + '/tenantSettings';
var $author$project$Api$EntityApi$getTenantSettings = F2(
	function (_v0, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A3(
				$lukewestby$elm_http_builder$HttpBuilder$withHeader,
				$author$project$Api$ApiUrls$aavoBranchHeader,
				selectedBranchKey,
				A2(
					$lukewestby$elm_http_builder$HttpBuilder$withExpect,
					A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Decoding$TenantSettingsDecoding$tenantSettingsDecoder),
					$lukewestby$elm_http_builder$HttpBuilder$get($author$project$Api$ApiUrls$tenantSettingsUrl))));
	});
var $author$project$Encoding$TenantSettingsEncoding$encodeTenantHook = function (tenantHook) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'hookType',
				$elm$json$Json$Encode$string(tenantHook.j3)),
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(tenantHook.f2)),
				_Utils_Tuple2(
				'environmentId',
				$elm$json$Json$Encode$int(tenantHook.jp))
			]));
};
var $author$project$Encoding$TenantSettingsEncoding$encodeTenantSettings = function (tenantSettings) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'delimitersString',
				$elm$json$Json$Encode$string(tenantSettings.i_)),
				_Utils_Tuple2(
				'cronCommandRunnerRoleId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, tenantSettings.iJ)),
				_Utils_Tuple2(
				'cronCommandRunnerEnvironmentId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, tenantSettings.iI)),
				_Utils_Tuple2(
				'enableMfaForNewUsers',
				$elm$json$Json$Encode$bool(tenantSettings.jm)),
				_Utils_Tuple2(
				'logSuccessfulExecutions',
				$elm$json$Json$Encode$bool(tenantSettings.kC)),
				_Utils_Tuple2(
				'hooks',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$TenantSettingsEncoding$encodeTenantHook, tenantSettings.j4))
			]));
};
var $author$project$Api$EntityApi$postTenantSettings = F3(
	function (_v0, tenantSettings, toMsg) {
		var selectedBranchKey = _v0.l$;
		return $lukewestby$elm_http_builder$HttpBuilder$request(
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				A2($author$project$Lib$CustomHttp$expectJsonCustom, toMsg, $author$project$Decoding$TenantSettingsDecoding$tenantSettingsDecoder),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					$author$project$Api$ApiUrls$aavoBranchHeader,
					selectedBranchKey,
					A2(
						$lukewestby$elm_http_builder$HttpBuilder$withJsonBody,
						$author$project$Encoding$TenantSettingsEncoding$encodeTenantSettings(tenantSettings),
						$lukewestby$elm_http_builder$HttpBuilder$post($author$project$Api$ApiUrls$tenantSettingsUrl)))));
	});
var $author$project$Pages$Main$TenantSettingsView$update = F4(
	function (apiConfig, _v0, msg, model) {
		switch (msg.$) {
			case 0:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(model);
			case 1:
				return _Utils_Tuple3(
					model,
					A2($author$project$Api$EntityApi$getTenantSettings, apiConfig, $author$project$Pages$Main$TenantSettingsView$GotTenantSettings),
					$author$project$Pages$Main$OutMsg$NoOp);
			case 2:
				var result = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							gl: A2(
								$author$project$Lib$RemoteData$map,
								A2($elm$core$Basics$composeL, $author$project$Lib$Form$FormView$makeModel, $author$project$Pages$Main$TenantSettingsView$createTenantSettingsFormValues),
								$author$project$Lib$RemoteData$fromResult(result))
						}));
			case 3:
				var formModel = msg.a;
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							gl: $author$project$Lib$RemoteData$Success(formModel)
						}));
			default:
				var tenantSettings = msg.a;
				return _Utils_Tuple3(
					model,
					A3($author$project$Api$EntityApi$postTenantSettings, apiConfig, tenantSettings, $author$project$Pages$Main$TenantSettingsView$GotTenantSettings),
					$author$project$Pages$Main$OutMsg$NoOp);
		}
	});
var $author$project$Pages$Main$ViewsView$AddNewViewModal = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$ViewsView$BeginDeleteGroup = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pages$Main$ViewsView$BeginDeleteView = function (a) {
	return {$: 13, a: a};
};
var $author$project$Pages$Main$ViewsView$BeginEditGroup = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$ViewsView$BeginEditView = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$ViewsView$BeginSaveGroup = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$ViewsView$BeginSaveView = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$ViewsView$GotDeleteGroup = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$ViewsView$GotDeleteView = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$ViewsView$GotPostGroup = function (a) {
	return {$: 18, a: a};
};
var $author$project$Pages$Main$ViewsView$GotPostView = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pages$Main$ViewsView$ListingViewMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$ViewsView$NoOp = {$: 0};
var $author$project$Forms$CreateNewViewForm$NewViewFormValues = function (viewType) {
	return {fM: viewType};
};
var $author$project$Forms$CreateNewViewForm$createNewViewFormValues = $author$project$Forms$CreateNewViewForm$NewViewFormValues('commandLineView');
var $author$project$Entity$ViewGroup$createNewViewGroup = {hM: $elm$core$Maybe$Nothing, i$: '', i1: '', j8: '', db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, k_: '', fk: $elm$core$Maybe$Nothing, dM: $author$project$Entity$Common$lastPosition, fJ: false};
var $author$project$Forms$ViewForms$CommandLineViewForm = function (a) {
	return {$: 0, a: a};
};
var $author$project$Forms$ViewForms$ComponentViewForm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Forms$ViewForms$ContainerViewForm = function (a) {
	return {$: 1, a: a};
};
var $author$project$Forms$ViewForms$DataViewForm = function (a) {
	return {$: 3, a: a};
};
var $author$project$Forms$ViewForms$FormViewForm = function (a) {
	return {$: 4, a: a};
};
var $author$project$Forms$ViewForms$GeneratorViewForm = function (a) {
	return {$: 5, a: a};
};
var $author$project$Forms$ViewForms$ViewWithSingleCommandForm = function (a) {
	return {$: 6, a: a};
};
var $author$project$Forms$ViewForms$ViewCommonPropValues = function (id) {
	return function (groupId) {
		return function (pos) {
			return function (aproProjectId) {
				return function (idAnchor) {
					return function (name) {
						return function (displayName) {
							return function (description) {
								return function (icon) {
									return function (scssCode) {
										return function (actionTriggers) {
											return function (staticParams) {
												return function (requiredParameters) {
													return function (componentCommands) {
														return function (defaultComponentAttributes) {
															return {hz: actionTriggers, hM: aproProjectId, iq: componentCommands, iS: defaultComponentAttributes, i$: description, i1: displayName, j_: groupId, j8: icon, db: id, j9: idAnchor, k_: name, dM: pos, lJ: requiredParameters, lX: scssCode, mm: staticParams};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Forms$ViewForms$createViewActionTriggerValues = function (actionTrigger) {
	return {
		fO: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues(actionTrigger.fO),
		mG: actionTrigger.mG,
		mI: actionTrigger.mI
	};
};
var $author$project$Utils$ToStrings$viewDefaultComponentTypeToString = function (componentType) {
	switch (componentType) {
		case 0:
			return 'COMMON|REFRESH_BUTTON';
		case 1:
			return 'DATA_VIEW|EDIT_BUTTON';
		case 2:
			return 'DATA_VIEW|NEW_ROW_BUTTON';
		case 3:
			return 'DATA_VIEW|SAVE_BUTTON';
		case 4:
			return 'DATA_VIEW|DELETE_BUTTON';
		case 5:
			return 'FORM_VIEW|TOGGLE_EDIT_BUTTON';
		default:
			return 'FORM_VIEW|SUBMIT_BUTTON';
	}
};
var $author$project$Forms$ViewForms$createViewDefaultComponentAttributesValues = function (_v0) {
	var componentType = _v0.is;
	var attributes = _v0.ba;
	return {
		ba: A2($elm$core$List$map, $author$project$Forms$ViewComponentAndActionForms$createViewComponentAttributeValues, attributes),
		is: $author$project$Utils$ToStrings$viewDefaultComponentTypeToString(componentType)
	};
};
var $author$project$Forms$ViewForms$createViewCommonPropValues = function (view) {
	return $author$project$Forms$ViewForms$ViewCommonPropValues(view.db)(view.j_)(view.dM)(view.hM)(view.j9)(view.k_)(view.i1)(view.i$)(view.j8)(view.lX)(
		A2($elm$core$List$map, $author$project$Forms$ViewForms$createViewActionTriggerValues, view.hz))(
		A2($elm$core$List$map, $author$project$Forms$CommandParameterForm$createCommandParameterFormValues, view.mm))(view.lJ)(view.iq)(
		A2($elm$core$List$map, $author$project$Forms$ViewForms$createViewDefaultComponentAttributesValues, view.iS));
};
var $author$project$Forms$ViewForms$createCommandLineSpecFormValues = F2(
	function (view, _v0) {
		var commandId = _v0.f2;
		return {
			f2: A2($elm$core$Maybe$withDefault, $author$project$Entity$Common$nonExistentId, commandId),
			B: $author$project$Forms$ViewForms$createViewCommonPropValues(view)
		};
	});
var $author$project$Forms$ViewForms$ContainerViewFormValues = F5(
	function (commonPropValues, childViewValues, isDynamic, mainViewId, layoutConfig) {
		return {cA: childViewValues, B: commonPropValues, ko: isDynamic, kw: layoutConfig, kF: mainViewId};
	});
var $author$project$Forms$ViewForms$createContainerViewFormValues = F2(
	function (view, spec) {
		return A5(
			$author$project$Forms$ViewForms$ContainerViewFormValues,
			$author$project$Forms$ViewForms$createViewCommonPropValues(view),
			$author$project$Forms$EntitySelectFormList$createEntitySelectFormListValues(spec.ib),
			spec.ko,
			A2($elm$core$Maybe$withDefault, $author$project$Entity$Common$nonExistentId, spec.kF),
			spec.kw);
	});
var $author$project$Forms$ViewForms$DataViewFormValues = F3(
	function (commonPropValues, commandId, formViewIdValues) {
		return {f2: commandId, B: commonPropValues, e3: formViewIdValues};
	});
var $author$project$Forms$ViewForms$createDataViewFormValues = F2(
	function (view, spec) {
		return A3(
			$author$project$Forms$ViewForms$DataViewFormValues,
			$author$project$Forms$ViewForms$createViewCommonPropValues(view),
			spec.f2,
			A2($author$project$Forms$CommonForms$createOptionalFormValues, $author$project$Entity$Common$nonExistentId, spec.jO));
	});
var $author$project$Forms$ViewForms$EmptyViewSpecFormValues = function (commonPropValues) {
	return {B: commonPropValues};
};
var $author$project$Forms$ViewForms$createEmptyViewSpecFormValues = function (view) {
	return $author$project$Forms$ViewForms$EmptyViewSpecFormValues(
		$author$project$Forms$ViewForms$createViewCommonPropValues(view));
};
var $author$project$Forms$ViewForms$FormViewFormValues = F3(
	function (commonPropValues, commandId, editMode) {
		return {f2: commandId, B: commonPropValues, jf: editMode};
	});
var $author$project$Utils$ToStrings$formViewEditModeToString = function (formViewEditMode) {
	switch (formViewEditMode) {
		case 0:
			return 'AlwaysEditable';
		case 1:
			return 'AlwaysReadOnly';
		default:
			return 'OptionallyEditable';
	}
};
var $author$project$Forms$ViewForms$createFormViewFormValues = F2(
	function (view, spec) {
		return A3(
			$author$project$Forms$ViewForms$FormViewFormValues,
			$author$project$Forms$ViewForms$createViewCommonPropValues(view),
			spec.f2,
			$author$project$Utils$ToStrings$formViewEditModeToString(spec.jf));
	});
var $author$project$Forms$ViewForms$GeneratorViewFormValues = F3(
	function (commonPropValues, generatorCommandId, templateViewId) {
		return {B: commonPropValues, jS: generatorCommandId, my: templateViewId};
	});
var $author$project$Forms$ViewForms$createGeneratorViewFormValues = F2(
	function (view, spec) {
		return A3(
			$author$project$Forms$ViewForms$GeneratorViewFormValues,
			$author$project$Forms$ViewForms$createViewCommonPropValues(view),
			spec.jS,
			spec.my);
	});
var $author$project$Forms$ViewForms$ViewWithSingleCommandFormValues = F3(
	function (commonPropValues, commandId, type_) {
		return {f2: commandId, B: commonPropValues, mM: type_};
	});
var $author$project$Forms$ViewForms$createNewViewWithSingleCommandFormValues = F2(
	function (view, spec) {
		return A3(
			$author$project$Forms$ViewForms$ViewWithSingleCommandFormValues,
			$author$project$Forms$ViewForms$createViewCommonPropValues(view),
			spec.f2,
			spec.mM);
	});
var $author$project$Forms$ViewForms$createViewFormValues = function (view) {
	var _v0 = view.mh;
	switch (_v0.$) {
		case 0:
			var spec_ = _v0.a;
			return $author$project$Forms$ViewForms$ContainerViewForm(
				A2($author$project$Forms$ViewForms$createContainerViewFormValues, view, spec_));
		case 1:
			return $author$project$Forms$ViewForms$ComponentViewForm(
				$author$project$Forms$ViewForms$createEmptyViewSpecFormValues(view));
		case 2:
			var spec_ = _v0.a;
			return $author$project$Forms$ViewForms$CommandLineViewForm(
				A2($author$project$Forms$ViewForms$createCommandLineSpecFormValues, view, spec_));
		case 3:
			var spec_ = _v0.a;
			return $author$project$Forms$ViewForms$DataViewForm(
				A2($author$project$Forms$ViewForms$createDataViewFormValues, view, spec_));
		case 4:
			var spec_ = _v0.a;
			return $author$project$Forms$ViewForms$FormViewForm(
				A2($author$project$Forms$ViewForms$createFormViewFormValues, view, spec_));
		case 6:
			var spec_ = _v0.a;
			return $author$project$Forms$ViewForms$GeneratorViewForm(
				A2($author$project$Forms$ViewForms$createGeneratorViewFormValues, view, spec_));
		default:
			var spec_ = _v0.a;
			return $author$project$Forms$ViewForms$ViewWithSingleCommandForm(
				A2($author$project$Forms$ViewForms$createNewViewWithSingleCommandFormValues, view, spec_));
	}
};
var $author$project$Api$EntityApi$deleteView = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$viewsUrl);
var $author$project$Api$EntityApi$deleteViewGroup = $author$project$Api$EntityApi$deleteEntity($author$project$Api$ApiUrls$viewGroupsUrl);
var $author$project$Pages$Main$ViewsView$filterByViewType = F2(
	function (filterValue, view_) {
		var requiredValue = function () {
			var _v0 = view_.mh;
			switch (_v0.$) {
				case 0:
					return 'containerView';
				case 1:
					return 'componentView';
				case 2:
					return 'commandLineView';
				case 3:
					return 'dataView';
				case 4:
					return 'formView';
				case 6:
					return 'generatorView';
				default:
					var type_ = _v0.a.mM;
					switch (type_) {
						case 0:
							return 'productionLine';
						case 1:
							return 'chartView';
						case 2:
							return 'documentView';
						case 3:
							return 'diagramView';
						case 4:
							return 'treeView';
						case 5:
							return 'mapView';
						case 6:
							return 'quickSightView';
						case 7:
							return 'calendarView';
						default:
							return 'customHtmlView';
					}
			}
		}();
		return _Utils_eq(filterValue, requiredValue);
	});
var $author$project$Entity$View$getViewSpecAsString = function (view_) {
	var _v0 = view_.mh;
	switch (_v0.$) {
		case 0:
			return 'Säiliönäkymä';
		case 1:
			return 'Komponenttinäkymä';
		case 2:
			return 'Komentorivi';
		case 3:
			return 'Datanäkymä';
		case 4:
			return 'Lomakenäkymä';
		case 5:
			var type_ = _v0.a.mM;
			switch (type_) {
				case 0:
					return 'Linjastonäkymä';
				case 1:
					return 'Kuvaajanäkymä';
				case 2:
					return 'Dokumenttinäkymä';
				case 3:
					return 'Diagramminäkymä';
				case 4:
					return 'Puunäkymä';
				case 5:
					return 'Karttanäkymä';
				case 6:
					return 'QuickSight-näkymä';
				case 7:
					return 'Kalenterinäkymä';
				default:
					return 'HTML-näkymä';
			}
		default:
			return 'Generaattorinäkymä';
	}
};
var $author$project$Pages$Main$ViewsView$getEntityTreeItemColumns = function (view_) {
	return _Utils_ap(
		_List_fromArray(
			[
				$author$project$Entity$View$getViewSpecAsString(view_),
				$elm$core$String$fromInt(view_.db),
				view_.k_,
				view_.i1,
				$author$project$Utils$StringUtils$firstLine(view_.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(view_),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(view_)));
};
var $author$project$Pages$Main$ViewsView$getGroupListingFields = function (group) {
	return _Utils_ap(
		_List_fromArray(
			[
				'Ryhmä',
				$elm$core$String$fromInt(group.db),
				group.k_,
				group.i1,
				$author$project$Utils$StringUtils$firstLine(group.i$)
			]),
		_Utils_ap(
			$author$project$Pages$Main$EntityListing$ListingViewUtils$aproProjectIdColumns(group),
			$author$project$Pages$Main$EntityListing$ListingViewUtils$idAnchorColumns(group)));
};
var $author$project$Entity$View$viewTypeOptions = _List_fromArray(
	[
		_Utils_Tuple2('commandLineView', 'Komentorivinäkymä'),
		_Utils_Tuple2('dataView', 'Datanäkymä'),
		_Utils_Tuple2('formView', 'Lomakenäkymä'),
		_Utils_Tuple2('productionLineView', 'Linjastonäkymä'),
		_Utils_Tuple2('chartView', 'Kuvaajanäkymä'),
		_Utils_Tuple2('documentView', 'Dokumenttinäkymä'),
		_Utils_Tuple2('diagramView', 'Diagramminäkymä'),
		_Utils_Tuple2('treeView', 'Puunäkymä'),
		_Utils_Tuple2('mapView', 'Karttanäkymä'),
		_Utils_Tuple2('quickSightView', 'QuickSight-näkymä'),
		_Utils_Tuple2('customHtmlView', 'HTML-näkymä'),
		_Utils_Tuple2('calendarView', 'Kalenterinäkymä'),
		_Utils_Tuple2('containerView', 'Säiliönäkymä'),
		_Utils_Tuple2('componentView', 'Komponenttinäkymä'),
		_Utils_Tuple2('generatorView', 'Generaattorinäkymä')
	]);
var $author$project$Pages$Main$ViewsView$listingViewConfig = {
	jK: _List_fromArray(
		[
			A2($author$project$Pages$Main$EntityListing$EntityListingFilters$entityTypeFilter, $author$project$Pages$Main$ViewsView$filterByViewType, $author$project$Entity$View$viewTypeOptions),
			A2(
			$author$project$Pages$Main$EntityListing$EntityListingFilters$commonStringFilter,
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i1;
				},
					function ($) {
					return $.i$;
				}
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.db;
					},
					$elm$core$String$fromInt),
					function ($) {
					return $.k_;
				},
					function ($) {
					return $.i1;
				},
					function ($) {
					return $.i$;
				}
				]))
		]),
	go: function ($) {
		return $.m$;
	},
	jT: $author$project$Pages$Main$ViewsView$getEntityTreeItemColumns,
	jU: $author$project$Pages$Main$ViewsView$getGroupListingFields,
	gp: function ($) {
		return $.fL;
	}
};
var $author$project$Pages$Main$ViewsView$onGotDelete = F2(
	function (updateRepository, model) {
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (id) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$ViewsView$Hidden}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(id)));
			},
			model);
	});
var $author$project$Pages$Main$ViewsView$onGotPostResult = F2(
	function (_v0, model) {
		var makeNewEditModel = _v0.fa;
		var updateRepository = _v0.fK;
		return A2(
			$author$project$Pages$Main$SubViewUpdateUtils$handleHttpResult,
			function (view_) {
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							j: A2(
								$author$project$Pages$Main$EditEntityOrGroupModel$updateKeepingMode,
								model.j,
								makeNewEditModel(view_))
						}),
					$elm$core$Platform$Cmd$none,
					$author$project$Pages$Main$OutMsg$UpdateRepository(
						updateRepository(view_)));
			},
			model);
	});
var $author$project$Pages$Main$ViewsView$positionUpdatingConfig = {
	go: function ($) {
		return $.m$;
	},
	gp: function ($) {
		return $.fL;
	},
	l6: F2(
		function (views, repo) {
			return _Utils_update(
				repo,
				{
					m$: $author$project$Lib$RemoteData$Success(views)
				});
		}),
	l7: F2(
		function (groups, repo) {
			return _Utils_update(
				repo,
				{
					fL: $author$project$Lib$RemoteData$Success(groups)
				});
		})
};
var $author$project$Encoding$ViewEncoding$encodeViewActionTrigger = function (actionTrigger) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'trigger',
				$elm$json$Json$Encode$string(actionTrigger.mG)),
				_Utils_Tuple2(
				'triggerConfig',
				$elm$json$Json$Encode$string(actionTrigger.mI)),
				_Utils_Tuple2(
				'action',
				$author$project$Encoding$ViewComponentAndActionEncoding$encodeKappaAction(actionTrigger.fO))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeViewComponentCommandBinding = function (_v0) {
	var commandId = _v0.f2;
	var renderLocation = _v0.lB;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(commandId)),
				_Utils_Tuple2(
				'renderLocation',
				$elm$json$Json$Encode$string(renderLocation))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeViewDefaultComponentAttributes = function (_v0) {
	var componentType = _v0.is;
	var attributes = _v0.ba;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'componentType',
				$elm$json$Json$Encode$string(
					$author$project$Utils$ToStrings$viewDefaultComponentTypeToString(componentType))),
				_Utils_Tuple2(
				'attributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewComponentAndActionEncoding$encodeViewComponentAttribute, attributes))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeViewRequiredParameter = function (requiredParam) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'paramName',
				$elm$json$Json$Encode$string(requiredParam.lh)),
				_Utils_Tuple2(
				'placeholder',
				$elm$json$Json$Encode$string(requiredParam.lm)),
				_Utils_Tuple2(
				'allowEmptyValue',
				$elm$json$Json$Encode$bool(requiredParam.hI))
			]));
};
var $author$project$Encoding$ViewEncoding$viewTypeField = function (value) {
	return _Utils_Tuple2(
		'view_type',
		$elm$json$Json$Encode$string(value));
};
var $author$project$Encoding$ViewEncoding$encodeContainerViewSpec = function (spec) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				$author$project$Encoding$ViewEncoding$viewTypeField('containerView'),
				_Utils_Tuple2(
				'childViewIds',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$int, spec.ib)),
				_Utils_Tuple2(
				'mainViewId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, spec.kF)),
				_Utils_Tuple2(
				'isDynamic',
				$elm$json$Json$Encode$bool(spec.ko)),
				_Utils_Tuple2(
				'layoutConfig',
				$elm$json$Json$Encode$string(spec.kw))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeDataViewSpec = function (spec) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				$author$project$Encoding$ViewEncoding$viewTypeField('dataView'),
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(spec.f2)),
				_Utils_Tuple2(
				'formViewId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, spec.jO))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeFormViewSpec = function (spec) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				$author$project$Encoding$ViewEncoding$viewTypeField('formView'),
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(spec.f2)),
				_Utils_Tuple2(
				'editMode',
				$elm$json$Json$Encode$string(
					$author$project$Utils$ToStrings$formViewEditModeToString(spec.jf)))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeGeneratorViewSpec = function (spec) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				$author$project$Encoding$ViewEncoding$viewTypeField('generatorView'),
				_Utils_Tuple2(
				'generatorCommandId',
				$elm$json$Json$Encode$int(spec.jS)),
				_Utils_Tuple2(
				'templateViewId',
				$elm$json$Json$Encode$int(spec.my))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeViewWithSingleCommandType = function (type_) {
	return $elm$json$Json$Encode$string(
		function () {
			switch (type_) {
				case 0:
					return 'ProductionLineView';
				case 1:
					return 'ChartView';
				case 2:
					return 'DocumentView';
				case 3:
					return 'DiagramView';
				case 4:
					return 'TreeView';
				case 5:
					return 'MapView';
				case 6:
					return 'QuickSightView';
				case 7:
					return 'CalendarView';
				default:
					return 'CustomHtmlView';
			}
		}());
};
var $author$project$Encoding$ViewEncoding$encodeViewWithSingleCommand = function (spec) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				$author$project$Encoding$ViewEncoding$viewTypeField('viewWithSingleCommand'),
				_Utils_Tuple2(
				'commandId',
				$elm$json$Json$Encode$int(spec.f2)),
				_Utils_Tuple2(
				'type',
				$author$project$Encoding$ViewEncoding$encodeViewWithSingleCommandType(spec.mM))
			]));
};
var $author$project$Encoding$ViewEncoding$encodeViewSpec = function (spec) {
	switch (spec.$) {
		case 0:
			var spec_ = spec.a;
			return $author$project$Encoding$ViewEncoding$encodeContainerViewSpec(spec_);
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						$author$project$Encoding$ViewEncoding$viewTypeField('componentView')
					]));
		case 2:
			var commandId = spec.a.f2;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						$author$project$Encoding$ViewEncoding$viewTypeField('commandLineView'),
						_Utils_Tuple2(
						'commandId',
						A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, commandId))
					]));
		case 3:
			var spec_ = spec.a;
			return $author$project$Encoding$ViewEncoding$encodeDataViewSpec(spec_);
		case 4:
			var spec_ = spec.a;
			return $author$project$Encoding$ViewEncoding$encodeFormViewSpec(spec_);
		case 6:
			var spec_ = spec.a;
			return $author$project$Encoding$ViewEncoding$encodeGeneratorViewSpec(spec_);
		default:
			var spec_ = spec.a;
			return $author$project$Encoding$ViewEncoding$encodeViewWithSingleCommand(spec_);
	}
};
var $author$project$Encoding$ViewEncoding$encodeView = function (view) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(view.db)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(view.dM)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, view.hM)),
				_Utils_Tuple2(
				'idAnchor',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, view.j9)),
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(view.k_)),
				_Utils_Tuple2(
				'displayName',
				$elm$json$Json$Encode$string(view.i1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(view.i$)),
				_Utils_Tuple2(
				'icon',
				$elm$json$Json$Encode$string(view.j8)),
				_Utils_Tuple2(
				'groupId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, view.j_)),
				_Utils_Tuple2(
				'scssCode',
				$elm$json$Json$Encode$string(view.lX)),
				_Utils_Tuple2(
				'actionTriggers',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewEncoding$encodeViewActionTrigger, view.hz)),
				_Utils_Tuple2(
				'staticParams',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$CommandParameterEncoding$encodeCommandParameter, view.mm)),
				_Utils_Tuple2(
				'requiredParameters',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewEncoding$encodeViewRequiredParameter, view.lJ)),
				_Utils_Tuple2(
				'componentCommands',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewEncoding$encodeViewComponentCommandBinding, view.iq)),
				_Utils_Tuple2(
				'defaultComponentAttributes',
				A2($elm$json$Json$Encode$list, $author$project$Encoding$ViewEncoding$encodeViewDefaultComponentAttributes, view.iS)),
				_Utils_Tuple2(
				'specification',
				$author$project$Encoding$ViewEncoding$encodeViewSpec(view.mh))
			]));
};
var $author$project$Api$EntityApi$postView = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$viewsUrl, $author$project$Encoding$ViewEncoding$encodeView, $author$project$Decoding$ViewDecoding$viewDecoder);
var $author$project$Entity$ViewGroup$encodeViewGroup = function (group) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'name',
				$elm$json$Json$Encode$string(group.k_)),
				_Utils_Tuple2(
				'displayName',
				$elm$json$Json$Encode$string(group.i1)),
				_Utils_Tuple2(
				'description',
				$elm$json$Json$Encode$string(group.i$)),
				_Utils_Tuple2(
				'parentId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, group.fk)),
				_Utils_Tuple2(
				'icon',
				$elm$json$Json$Encode$string(group.j8)),
				_Utils_Tuple2(
				'transparent',
				$elm$json$Json$Encode$bool(group.fJ)),
				_Utils_Tuple2(
				'pos',
				$elm$json$Json$Encode$int(group.dM)),
				_Utils_Tuple2(
				'id',
				$elm$json$Json$Encode$int(group.db)),
				_Utils_Tuple2(
				'aproProjectId',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$int, group.hM)),
				_Utils_Tuple2(
				'idAnchor',
				A2($author$project$Encoding$Utils$encodeMaybe, $elm$json$Json$Encode$string, group.j9))
			]));
};
var $author$project$Api$EntityApi$postViewGroup = A3($author$project$Api$EntityApi$postEntity, $author$project$Api$ApiUrls$viewGroupsUrl, $author$project$Entity$ViewGroup$encodeViewGroup, $author$project$Entity$ViewGroup$viewGroupDecoder);
var $author$project$Api$EntityApi$saveViewGroupPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$viewGroupsUrl);
var $author$project$Api$EntityApi$saveViewPosition = $author$project$Api$EntityApi$saveEntityPosition($author$project$Api$ApiUrls$viewsUrl);
var $author$project$Pages$Main$ViewsView$Confirm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$ViewsView$setCurrentViewLoadStatusTo = F2(
	function (status, model) {
		var modal = function () {
			var _v1 = model.bO;
			switch (_v1.$) {
				case 3:
					var formModel = _v1.a;
					return $author$project$Pages$Main$ViewsView$AddNewViewModal(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var config = _v1.a;
					return $author$project$Pages$Main$ViewsView$Confirm(
						_Utils_update(
							config,
							{ha: status}));
				default:
					return model.bO;
			}
		}();
		var editItemModel = function () {
			var _v0 = model.j;
			switch (_v0.$) {
				case 1:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				case 2:
					var formModel = _v0.a;
					return $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
						A2($author$project$Lib$Form$FormView$setStatusTo, status, formModel));
				default:
					return $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing;
			}
		}();
		return _Utils_update(
			model,
			{j: editItemModel, bO: modal});
	});
var $author$project$Pages$Main$ViewsView$CloseCurrentModal = {$: 2};
var $author$project$Pages$Main$ViewsView$showConfirmModal = F3(
	function (model, message, onConfirmed) {
		return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					bO: $author$project$Pages$Main$ViewsView$Confirm(
						{h4: $author$project$Pages$Main$ViewsView$CloseCurrentModal, iv: onConfirmed, iB: message, ha: $author$project$Lib$Form$LoadStatus$Idle})
				}));
	});
var $author$project$Pages$Main$ViewsView$handleListingViewOutMsg = F5(
	function (apiConfig, repository, model, cmd, outMsg) {
		var newMsg = function (msg) {
			return A3(
				$author$project$Pages$Main$SubViewUpdateUtils$andThen,
				A2($author$project$Pages$Main$ViewsView$update, apiConfig, repository),
				msg,
				_Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp));
		};
		switch (outMsg.$) {
			case 0:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$NoOp);
			case 1:
				return _Utils_Tuple3(model, cmd, $author$project$Pages$Main$OutMsg$BeginReloadAllEntities);
			case 2:
				var entity = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$ViewsView$BeginEditView(entity));
			case 3:
				var group = outMsg.a;
				return newMsg(
					$author$project$Pages$Main$ViewsView$BeginEditGroup(group));
			case 4:
				return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$ViewsView$AddNewViewModal(
								$author$project$Lib$Form$FormView$makeModel($author$project$Forms$CreateNewViewForm$createNewViewFormValues))
						}));
			case 5:
				return newMsg(
					$author$project$Pages$Main$ViewsView$BeginEditGroup(
						_Utils_update(
							$author$project$Entity$ViewGroup$createNewViewGroup,
							{
								fk: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})));
			case 6:
				var view_ = outMsg.a;
				return A3(
					$author$project$Pages$Main$ViewsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa näkymän ' + (view_.k_ + '?'),
					$author$project$Pages$Main$ViewsView$BeginDeleteView(view_));
			case 7:
				var group = outMsg.a;
				return A3(
					$author$project$Pages$Main$ViewsView$showConfirmModal,
					model,
					'Haluatko varmasti poistaa ryhmän ' + (group.k_ + '?'),
					$author$project$Pages$Main$ViewsView$BeginDeleteGroup(group));
			case 8:
				var view_ = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$ViewsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TEntity(view_));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$ViewsView$update, apiConfig, repo),
					$author$project$Pages$Main$ViewsView$BeginSaveView(view_),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveViewPosition,
							apiConfig,
							view_,
							$elm$core$Basics$always($author$project$Pages$Main$ViewsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
			default:
				var group = outMsg.a;
				var oldPos = outMsg.b.gJ;
				var repo = A4(
					$author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterMove,
					$author$project$Pages$Main$ViewsView$positionUpdatingConfig,
					repository,
					oldPos,
					$author$project$Entity$Common$TGroup(group));
				return A3(
					$author$project$Pages$Main$SubViewUpdateUtils$andThen,
					A2($author$project$Pages$Main$ViewsView$update, apiConfig, repo),
					$author$project$Pages$Main$ViewsView$BeginSaveGroup(group),
					_Utils_Tuple3(
						model,
						A3(
							$author$project$Api$EntityApi$saveViewGroupPosition,
							apiConfig,
							group,
							$elm$core$Basics$always($author$project$Pages$Main$ViewsView$NoOp)),
						$author$project$Pages$Main$OutMsg$UpdateRepository(repo)));
		}
	});
var $author$project$Pages$Main$ViewsView$update = F4(
	function (apiConfig, repository, msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$Pages$Main$OutMsg$NoOp);
				case 1:
					var listingViewMsg = msg.a;
					var _v1 = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$update, $author$project$Pages$Main$ViewsView$listingViewConfig, repository, listingViewMsg, model.ap);
					var listingViewModel = _v1.a;
					var listingViewCmd = _v1.b;
					var listingViewOutMsg = _v1.c;
					return A5(
						$author$project$Pages$Main$ViewsView$handleListingViewOutMsg,
						apiConfig,
						repository,
						_Utils_update(
							model,
							{ap: listingViewModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Pages$Main$ViewsView$ListingViewMsg, listingViewCmd),
						listingViewOutMsg);
				case 4:
					var view_ = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(
									$author$project$Lib$Form$FormView$makeModel(
										$author$project$Forms$ViewForms$createViewFormValues(view_)))
							}));
				case 5:
					var group = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(
									$author$project$Lib$Form$FormView$makeModel(group))
							}));
				case 2:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{bO: $author$project$Pages$Main$ViewsView$Hidden}));
				case 6:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel(newValues)
							}));
				case 7:
					var newValues = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel(newValues)
							}));
				case 8:
					var newFormModel = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								bO: $author$project$Pages$Main$ViewsView$AddNewViewModal(newFormModel)
							}));
				case 9:
					var view_ = msg.a;
					var $temp$apiConfig = apiConfig,
						$temp$repository = repository,
						$temp$msg = $author$project$Pages$Main$ViewsView$BeginEditView(
						_Utils_update(
							view_,
							{
								j_: $author$project$Pages$Main$EntityListing$GenericEntityListingView$getFocusedGroupId(model.ap)
							})),
						$temp$model = _Utils_update(
						model,
						{bO: $author$project$Pages$Main$ViewsView$Hidden});
					apiConfig = $temp$apiConfig;
					repository = $temp$repository;
					msg = $temp$msg;
					model = $temp$model;
					continue update;
				case 10:
					var code = msg.a;
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								j: A2($author$project$Pages$Main$EditEntityOrGroupModel$setFocusedCodeFieldValue, model.j, code)
							}));
				case 3:
					return $author$project$Pages$Main$SubViewUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{j: $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing}));
				case 11:
					var view_ = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ViewsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postView, apiConfig, view_, $author$project$Pages$Main$ViewsView$GotPostView),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 12:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ViewsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$postViewGroup, apiConfig, group, $author$project$Pages$Main$ViewsView$GotPostGroup),
						$author$project$Pages$Main$OutMsg$NoOp);
				case 13:
					var view_ = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ViewsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteView, apiConfig, view_.db, $author$project$Pages$Main$ViewsView$GotDeleteView),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$ViewsView$positionUpdatingConfig, repository, view_.dM)));
				case 14:
					var group = msg.a;
					return _Utils_Tuple3(
						A2($author$project$Pages$Main$ViewsView$setCurrentViewLoadStatusTo, $author$project$Lib$Form$LoadStatus$InProgress, model),
						A3($author$project$Api$EntityApi$deleteViewGroup, apiConfig, group.db, $author$project$Pages$Main$ViewsView$GotDeleteGroup),
						$author$project$Pages$Main$OutMsg$UpdateRepository(
							A3($author$project$Pages$Main$EntityListing$PositionUpdating$updatePositionsAfterDelete, $author$project$Pages$Main$ViewsView$positionUpdatingConfig, repository, group.dM)));
				case 17:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$ViewsView$onGotPostResult,
						{
							fa: A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditEntityModel, $author$project$Lib$Form$FormView$makeModel),
								$author$project$Forms$ViewForms$createViewFormValues),
							fK: function (view_) {
								return _Utils_update(
									repository,
									{
										m$: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.m$, view_)
									});
							}
						},
						model,
						result);
				case 18:
					var result = msg.a;
					return A3(
						$author$project$Pages$Main$ViewsView$onGotPostResult,
						{
							fa: A2($elm$core$Basics$composeL, $author$project$Pages$Main$EditEntityOrGroupModel$EditGroupModel, $author$project$Lib$Form$FormView$makeModel),
							fK: function (group) {
								return _Utils_update(
									repository,
									{
										fL: A2($author$project$Utils$RemoteDataUtils$insertToEntityDictWebData, repository.fL, group)
									});
							}
						},
						model,
						result);
				case 15:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$ViewsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									m$: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.m$, id)
								});
						},
						model,
						httpResult);
				default:
					var httpResult = msg.a;
					return A3(
						$author$project$Pages$Main$ViewsView$onGotDelete,
						function (id) {
							return _Utils_update(
								repository,
								{
									fL: A2($author$project$Utils$RemoteDataUtils$deleteFromEntityDictWebData, repository.fL, id)
								});
						},
						model,
						httpResult);
			}
		}
	});
var $author$project$Pages$Main$MainPage$updateRepository = F2(
	function (updater, model) {
		return $author$project$Pages$PageUpdateUtils$withoutActions(
			_Utils_update(
				model,
				{
					n: updater(model.n)
				}));
	});
var $author$project$AppMsg$NavigateTo = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$allMainPageRoutes = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 9, 10]);
var $author$project$Authentication$UserPermissions$requiredPermissionForRoute = function (mainPageRoute) {
	switch (mainPageRoute) {
		case 10:
			return $elm$core$Maybe$Nothing;
		case 0:
			return $elm$core$Maybe$Just(8);
		case 1:
			return $elm$core$Maybe$Just(9);
		case 2:
			return $elm$core$Maybe$Just(13);
		case 3:
			return $elm$core$Maybe$Just(10);
		case 4:
			return $elm$core$Maybe$Just(11);
		case 5:
			return $elm$core$Maybe$Just(12);
		case 6:
			return $elm$core$Maybe$Just(14);
		case 9:
			return $elm$core$Maybe$Just(15);
		case 7:
			return $elm$core$Maybe$Just(16);
		default:
			return $elm$core$Maybe$Just(17);
	}
};
var $author$project$Authentication$UserPermissions$hasPermissionToRoute = F2(
	function (userPermissions, mainPageRoute) {
		var _v0 = $author$project$Authentication$UserPermissions$requiredPermissionForRoute(mainPageRoute);
		if (_v0.$ === 1) {
			return true;
		} else {
			var requiredPermission = _v0.a;
			return A2($elm$core$List$member, requiredPermission, userPermissions);
		}
	});
var $author$project$Authentication$UserPermissions$getFirstValidMainPageRoute = function (userPermissions) {
	return A2(
		$elm$core$Maybe$withDefault,
		10,
		A2(
			$elm_community$list_extra$List$Extra$find,
			$author$project$Authentication$UserPermissions$hasPermissionToRoute(userPermissions),
			$author$project$Routes$allMainPageRoutes));
};
var $author$project$Pages$Main$MainPage$validateCurrentRoute = function (_v0) {
	var model = _v0.a;
	var cmd = _v0.b;
	var appMsg = _v0.c;
	var _v1 = model.Z;
	if (_v1.$ === 3) {
		var userPermissions = _v1.a;
		return A2($author$project$Authentication$UserPermissions$hasPermissionToRoute, userPermissions, model.aX) ? _Utils_Tuple3(model, cmd, appMsg) : _Utils_Tuple3(
			model,
			cmd,
			$author$project$AppMsg$NavigateTo(
				$author$project$Routes$MainPage(
					$author$project$Authentication$UserPermissions$getFirstValidMainPageRoute(userPermissions))));
	} else {
		return _Utils_Tuple3(model, cmd, appMsg);
	}
};
var $author$project$Pages$Main$MainPage$update = F2(
	function (msg, model) {
		var handleGotWebData = F2(
			function (updateRepo, result) {
				return A2(
					$author$project$Pages$Main$MainPage$updateRepository,
					function (repo) {
						return A2(
							updateRepo,
							repo,
							$author$project$Lib$RemoteData$fromResult(result));
					},
					model);
			});
		var emptyReturn = _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$AppMsg$NoOp);
		var apiConfig = $author$project$Pages$Main$MainPage$getApiConfig(model);
		switch (msg.$) {
			case 0:
				return emptyReturn;
			case 1:
				var pageMsg = msg.a;
				return A2($author$project$Pages$Main$MainPage$updateOnOutMsg, pageMsg, model);
			case 2:
				var appMsg = msg.a;
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, appMsg);
			case 3:
				var route = msg.a;
				return $author$project$Pages$Main$MainPage$validateCurrentRoute(
					$author$project$Pages$PageUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{aX: route})));
			case 4:
				var branchKey = msg.a;
				return A3(
					$author$project$Pages$PageUpdateUtils$andThen,
					$author$project$Pages$Main$MainPage$update,
					$author$project$Pages$Main$MainPage$OutMsg($author$project$Pages$Main$OutMsg$BeginReloadAllEntities),
					_Utils_Tuple3(
						_Utils_update(
							model,
							{l$: branchKey}),
						$author$project$Ports$selectedBranchChanged(branchKey),
						$author$project$AppMsg$NoOp));
			case 5:
				var commandsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bj;
						},
						w: $author$project$Pages$Main$MainPage$CommandsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bj: subModel});
							}),
						a: $author$project$Pages$Main$CommandsView$update
					},
					model,
					commandsMsg);
			case 6:
				var viewsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.b9;
						},
						w: $author$project$Pages$Main$MainPage$ViewsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{b9: subModel});
							}),
						a: $author$project$Pages$Main$ViewsView$update
					},
					model,
					viewsMsg);
			case 7:
				var rolesMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bX;
						},
						w: $author$project$Pages$Main$MainPage$RolesMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bX: subModel});
							}),
						a: $author$project$Pages$Main$RolesView$update
					},
					model,
					rolesMsg);
			case 8:
				var connectionsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bk;
						},
						w: $author$project$Pages$Main$MainPage$ConnectionsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bk: subModel});
							}),
						a: $author$project$Pages$Main$ConnectionsView$update
					},
					model,
					connectionsMsg);
			case 9:
				var environmentsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bt;
						},
						w: $author$project$Pages$Main$MainPage$EnvironmentsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bt: subModel});
							}),
						a: $author$project$Pages$Main$EnvironmentsView$update
					},
					model,
					environmentsMsg);
			case 10:
				var endpointsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bs;
						},
						w: $author$project$Pages$Main$MainPage$EndpointsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bs: subModel});
							}),
						a: $author$project$Pages$Main$EndpointsView$update
					},
					model,
					endpointsMsg);
			case 11:
				var principalsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bV;
						},
						w: $author$project$Pages$Main$MainPage$PrincipalsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bV: subModel});
							}),
						a: $author$project$Pages$Main$PrincipalsView$update
					},
					model,
					principalsMsg);
			case 12:
				var tenantSettingsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.b1;
						},
						w: $author$project$Pages$Main$MainPage$TenantSettingsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{b1: subModel});
							}),
						a: $author$project$Pages$Main$TenantSettingsView$update
					},
					model,
					tenantSettingsMsg);
			case 13:
				var logsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bM;
						},
						w: $author$project$Pages$Main$MainPage$LogsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bM: subModel});
							}),
						a: $author$project$Pages$Main$LogsView$update
					},
					model,
					logsMsg);
			case 14:
				var revisionsMsg = msg.a;
				return A3(
					$author$project$Pages$Main$MainPage$updateOnSubViewMsg,
					{
						v: function ($) {
							return $.bW;
						},
						w: $author$project$Pages$Main$MainPage$RevisionsMsg,
						A: F2(
							function (subModel, model_) {
								return _Utils_update(
									model_,
									{bW: subModel});
							}),
						a: F4(
							function (appConfig, _v3, msg_, model_) {
								return A3($author$project$Pages$Main$RevisionsView$update, appConfig, msg_, model_);
							})
					},
					model,
					revisionsMsg);
			case 15:
				return $author$project$Pages$PageUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{bO: $author$project$Pages$Main$MainPage$Hidden}));
			case 16:
				var config = msg.a;
				return $author$project$Pages$PageUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{
							bO: $author$project$Pages$Main$MainPage$Message(config)
						}));
			case 18:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							Z: $author$project$Lib$RemoteData$Loading(_List_Nil)
						}),
					$author$project$Api$UserApi$getUserPermissions($author$project$Pages$Main$MainPage$GotUserPermissions),
					$author$project$AppMsg$NoOp);
			case 20:
				var result = msg.a;
				return $author$project$Pages$Main$MainPage$validateCurrentRoute(
					A3(
						$author$project$Pages$PageUpdateUtils$andThen,
						$author$project$Pages$Main$MainPage$update,
						$author$project$Pages$Main$MainPage$BeginLoadBranches,
						$author$project$Pages$PageUpdateUtils$withoutActions(
							_Utils_update(
								model,
								{
									Z: $author$project$Lib$RemoteData$fromResult(result)
								}))));
			case 19:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							aA: $author$project$Lib$RemoteData$Loading(_List_Nil)
						}),
					$author$project$Api$BranchApi$getBranches($author$project$Pages$Main$MainPage$GotBranches),
					$author$project$AppMsg$NoOp);
			case 21:
				var result = msg.a;
				return A3(
					$author$project$Pages$PageUpdateUtils$andThen,
					$author$project$Pages$Main$MainPage$update,
					$author$project$Pages$Main$MainPage$OutMsg($author$project$Pages$Main$OutMsg$BeginReloadAllEntities),
					$author$project$Pages$PageUpdateUtils$withoutActions(
						_Utils_update(
							model,
							{
								aA: $author$project$Lib$RemoteData$fromResult(result)
							})));
			case 17:
				return A2($author$project$Pages$PageUpdateUtils$justAppMsg, model, $author$project$AppMsg$RequestLogout);
			case 22:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getCommands, apiConfig, $author$project$Pages$Main$MainPage$GotCommands),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									O: $author$project$Lib$RemoteData$toLoading(repo.O)
								});
						},
						model));
			case 23:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getCommandGroups, apiConfig, $author$project$Pages$Main$MainPage$GotCommandGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									_: $author$project$Lib$RemoteData$toLoading(repo._)
								});
						},
						model));
			case 24:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getRoles, apiConfig, $author$project$Pages$Main$MainPage$GotRoles),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									g0: $author$project$Lib$RemoteData$toLoading(repo.g0)
								});
						},
						model));
			case 25:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getRoleGroups, apiConfig, $author$project$Pages$Main$MainPage$GotRoleGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									fx: $author$project$Lib$RemoteData$toLoading(repo.fx)
								});
						},
						model));
			case 26:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getConnections, apiConfig, $author$project$Pages$Main$MainPage$GotConnections),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									iy: $author$project$Lib$RemoteData$toLoading(repo.iy)
								});
						},
						model));
			case 27:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2(
						$author$project$Api$EntityApi$getConnectionGroups,
						$author$project$Pages$Main$MainPage$getApiConfig(model),
						$author$project$Pages$Main$MainPage$GotConnectionGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									ab: $author$project$Lib$RemoteData$toLoading(repo.ab)
								});
						},
						model));
			case 28:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2(
						$author$project$Api$EntityApi$getEnvironments,
						$author$project$Pages$Main$MainPage$getApiConfig(model),
						$author$project$Pages$Main$MainPage$GotEnvironments),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									gg: $author$project$Lib$RemoteData$toLoading(repo.gg)
								});
						},
						model));
			case 29:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getEndpoints, apiConfig, $author$project$Pages$Main$MainPage$GotEndpoints),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									aj: $author$project$Lib$RemoteData$toLoading(repo.aj)
								});
						},
						model));
			case 30:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getEnvironmentGroups, apiConfig, $author$project$Pages$Main$MainPage$GotEnvironmentGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									ak: $author$project$Lib$RemoteData$toLoading(repo.ak)
								});
						},
						model));
			case 31:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getEndpointGroups, apiConfig, $author$project$Pages$Main$MainPage$GotEndpointGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									ai: $author$project$Lib$RemoteData$toLoading(repo.ai)
								});
						},
						model));
			case 32:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getViews, apiConfig, $author$project$Pages$Main$MainPage$GotViews),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									m$: $author$project$Lib$RemoteData$toLoading(repo.m$)
								});
						},
						model));
			case 33:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getViewGroups, apiConfig, $author$project$Pages$Main$MainPage$GotViewGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									fL: $author$project$Lib$RemoteData$toLoading(repo.fL)
								});
						},
						model));
			case 34:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getPrincipals, apiConfig, $author$project$Pages$Main$MainPage$GotPrincipals),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									fq: $author$project$Lib$RemoteData$toLoading(repo.fq)
								});
						},
						model));
			case 35:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					A2($author$project$Api$EntityApi$getPrincipalGroups, apiConfig, $author$project$Pages$Main$MainPage$GotPrincipalGroups),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									fp: $author$project$Lib$RemoteData$toLoading(repo.fp)
								});
						},
						model));
			case 36:
				return A2(
					$author$project$Pages$PageUpdateUtils$addCmd,
					$author$project$Api$MiscApi$getIdentityProviders($author$project$Pages$Main$MainPage$GotIdentityProviders),
					A2(
						$author$project$Pages$Main$MainPage$updateRepository,
						function (repo) {
							return _Utils_update(
								repo,
								{
									e5: $author$project$Lib$RemoteData$toLoading(repo.e5)
								});
						},
						model));
			case 37:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{O: data});
						}),
					result);
			case 38:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{_: data});
						}),
					result);
			case 39:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{g0: data});
						}),
					result);
			case 40:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{fx: data});
						}),
					result);
			case 41:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{iy: data});
						}),
					result);
			case 42:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{ab: data});
						}),
					result);
			case 43:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{gg: data});
						}),
					result);
			case 45:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{ak: data});
						}),
					result);
			case 44:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{aj: data});
						}),
					result);
			case 46:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{ai: data});
						}),
					result);
			case 47:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{m$: data});
						}),
					result);
			case 48:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{fL: data});
						}),
					result);
			case 49:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{fq: data});
						}),
					result);
			case 50:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{fp: data});
						}),
					result);
			default:
				var result = msg.a;
				return A2(
					handleGotWebData,
					F2(
						function (repo, data) {
							return _Utils_update(
								repo,
								{e5: data});
						}),
					result);
		}
	});
var $author$project$Pages$Main$MainPage$updateOnOutMsg = F2(
	function (appMsg, model) {
		var emptyReturn = _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$AppMsg$NoOp);
		switch (appMsg.$) {
			case 0:
				return emptyReturn;
			case 3:
				var err = appMsg.a;
				return _Utils_Tuple3(
					model,
					$elm$core$Platform$Cmd$none,
					$author$project$AppMsg$OnHttpError(err));
			case 1:
				return A3(
					$author$project$Pages$PageUpdateUtils$sequence,
					$author$project$Pages$Main$MainPage$update,
					$author$project$Pages$Main$MainPage$getLoadMessagesForAllPermittedEntities(model),
					emptyReturn);
			case 2:
				var newRepository = appMsg.a;
				return $author$project$Pages$PageUpdateUtils$withoutActions(
					_Utils_update(
						model,
						{n: newRepository}));
			default:
				var msgs = appMsg.a;
				return A3(
					$author$project$Pages$PageUpdateUtils$sequence,
					$author$project$Pages$Main$MainPage$updateOnOutMsg,
					msgs,
					_Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $author$project$AppMsg$NoOp));
		}
	});
var $author$project$Pages$Main$MainPage$updateOnSubViewMsg = F3(
	function (config, model, msg) {
		var _v0 = A4(
			config.a,
			$author$project$Pages$Main$MainPage$getApiConfig(model),
			model.n,
			msg,
			config.v(model));
		var updatedSubModel = _v0.a;
		var cmd = _v0.b;
		var pageMsg = _v0.c;
		return A3(
			$author$project$Pages$PageUpdateUtils$andThen,
			$author$project$Pages$Main$MainPage$update,
			$author$project$Pages$Main$MainPage$OutMsg(pageMsg),
			_Utils_Tuple3(
				A2(config.A, updatedSubModel, model),
				A2($elm$core$Platform$Cmd$map, config.w, cmd),
				$author$project$AppMsg$NoOp));
	});
var $ccapndave$elm_update_extra$Update$Extra$updateModel = F2(
	function (f, _v0) {
		var model = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			f(model),
			cmd);
	});
var $author$project$Main$onUrlChanged = F2(
	function (url, model) {
		var newRoute = $author$project$Routes$urlToRoute(url);
		var _v7 = function () {
			switch (newRoute.$) {
				case 0:
					var newMainPageRoute = newRoute.a;
					var _v9 = model.F;
					if (!_v9.$) {
						return _Utils_Tuple2(
							model.F,
							$elm$core$Maybe$Just(
								$author$project$Main$MainPageMsg(
									$author$project$Pages$Main$MainPage$setRoute(newMainPageRoute))));
					} else {
						return _Utils_Tuple2(
							A2($author$project$Main$routeToDefaultPage, model.c$, newRoute),
							$elm$core$Maybe$Just(
								$author$project$Main$MainPageMsg($author$project$Pages$Main$MainPage$init)));
					}
				case 1:
					return _Utils_Tuple2(
						A2($author$project$Main$routeToDefaultPage, model.c$, newRoute),
						$elm$core$Maybe$Just(
							$author$project$Main$DocsPageMsg($author$project$Pages$DocsPage$init)));
				default:
					return _Utils_Tuple2($author$project$Main$LoggedOutPage, $elm$core$Maybe$Nothing);
			}
		}();
		var page = _v7.a;
		var msg = _v7.b;
		var onNavigatedToMsg = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Main$NoOp,
			A2($elm$core$Maybe$map, $author$project$Main$PageMsg, msg));
		return A3(
			$ccapndave$elm_update_extra$Update$Extra$andThen,
			$author$project$Main$update,
			onNavigatedToMsg,
			_Utils_Tuple2(
				_Utils_update(
					model,
					{F: page}),
				$elm$core$Platform$Cmd$none));
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 6:
					var urlRequest = msg.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.dr,
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 3:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bO: $author$project$Main$Hidden}),
						$elm$core$Platform$Cmd$none);
				case 7:
					var url = msg.a;
					return A2($author$project$Main$onUrlChanged, url, model);
				case 1:
					var appMsg = msg.a;
					switch (appMsg.$) {
						case 0:
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						case 1:
							var route = appMsg.a;
							return _Utils_Tuple2(
								model,
								A2($author$project$Main$navigateToCmd, model, route));
						case 2:
							return _Utils_Tuple2(
								model,
								$author$project$Api$UserApi$requestLogout($author$project$Main$GotLogout));
						case 3:
							var error = appMsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										bO: $author$project$Main$Error(
											$author$project$Utils$HttpUtils$httpErrorToString(error))
									}),
								$elm$core$Platform$Cmd$none);
						default:
							var appMsgs = appMsg.a;
							return A3(
								$ccapndave$elm_update_extra$Update$Extra$sequence,
								$author$project$Main$update,
								A2($elm$core$List$map, $author$project$Main$AppMsg, appMsgs),
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none));
					}
				case 2:
					var pageMsg = msg.a;
					return A2($author$project$Main$updateOnPageMsg, pageMsg, model);
				case 4:
					return _Utils_Tuple2(
						model,
						$author$project$Api$UserApi$requestLogin(
							$elm$core$Basics$always($author$project$Main$NoOp)));
				default:
					var result = msg.a;
					if (!result.$) {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					} else {
						var err = result.a;
						var $temp$msg = $author$project$Main$AppMsg(
							$author$project$AppMsg$OnHttpError(err)),
							$temp$model = model;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					}
			}
		}
	});
var $author$project$Main$updateOnPageMsg = F2(
	function (pageMsg, model) {
		var _v0 = _Utils_Tuple2(pageMsg, model.F);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var pageMsg_ = _v0.a.a;
					var page = _v0.b.a;
					var _v1 = A2($author$project$Pages$Main$MainPage$update, pageMsg_, page);
					var pageModel = _v1.a;
					var cmd = _v1.b;
					var appMsg = _v1.c;
					return A3(
						$ccapndave$elm_update_extra$Update$Extra$andThen,
						$author$project$Main$update,
						$author$project$Main$AppMsg(appMsg),
						A2(
							$ccapndave$elm_update_extra$Update$Extra$addCmd,
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Main$PageMsg, $author$project$Main$MainPageMsg),
								cmd),
							A2(
								$ccapndave$elm_update_extra$Update$Extra$updateModel,
								function (m) {
									return _Utils_update(
										m,
										{
											F: $author$project$Main$MainPage(pageModel)
										});
								},
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none))));
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b.$ === 1) {
					var pageMsg_ = _v0.a.a;
					var page = _v0.b.a;
					var _v2 = A2($author$project$Pages$DocsPage$update, pageMsg_, page);
					var pageModel = _v2.a;
					var cmd = _v2.b;
					var appMsg = _v2.c;
					return A3(
						$ccapndave$elm_update_extra$Update$Extra$andThen,
						$author$project$Main$update,
						$author$project$Main$AppMsg(appMsg),
						A2(
							$ccapndave$elm_update_extra$Update$Extra$addCmd,
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Main$PageMsg, $author$project$Main$DocsPageMsg),
								cmd),
							A2(
								$ccapndave$elm_update_extra$Update$Extra$updateModel,
								function (m) {
									return _Utils_update(
										m,
										{
											F: $author$project$Main$DocsPage(pageModel)
										});
								},
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none))));
				} else {
					break _v0$2;
				}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $author$project$Main$CloseCurrentModal = {$: 3};
var $author$project$Utils$CommonModals$MessageModalConfig = F3(
	function (title, content, close) {
		return {ig: close, iB: content, mC: title};
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$Body = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$body = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				h$: $elm$core$Maybe$Just(
					{ba: attributes, f_: children})
			});
	});
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size = modifier.a;
				return _Utils_update(
					options,
					{
						g9: $elm$core$Maybe$Just(size)
					});
			case 1:
				var coloring = modifier.a;
				return _Utils_update(
					options,
					{
						N: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				return _Utils_update(
					options,
					{cs: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{i: val});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs)
					});
		}
	});
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions = {ba: _List_Nil, cs: false, N: $elm$core$Maybe$Nothing, i: false, g9: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass = function (role) {
	switch (role) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'info';
		case 4:
			return 'warning';
		case 5:
			return 'danger';
		case 6:
			return 'dark';
		case 7:
			return 'light';
		default:
			return 'link';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('sm');
		case 2:
			return $elm$core$Maybe$Just('md');
		case 3:
			return $elm$core$Maybe$Just('lg');
		default:
			return $elm$core$Maybe$Just('xl');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier, $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2('btn-block', options.cs),
						_Utils_Tuple2('disabled', options.i)
					])),
				$elm$html$Html$Attributes$disabled(options.i)
			]),
		_Utils_ap(
			function () {
				var _v0 = A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption, options.g9);
				if (!_v0.$) {
					var s = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn-' + s)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.N;
					if (!_v1.$) {
						if (!_v1.a.$) {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						} else {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-outline-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						}
					} else {
						return _List_Nil;
					}
				}(),
				options.ba)));
};
var $rundis$elm_bootstrap$Bootstrap$Button$button = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$button,
			$rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$config = function (closeMsg) {
	return {
		h$: $elm$core$Maybe$Nothing,
		bf: closeMsg,
		e1: $elm$core$Maybe$Nothing,
		c9: $elm$core$Maybe$Nothing,
		aT: {eG: true, bA: true, kY: $elm$core$Maybe$Nothing},
		az: $elm$core$Maybe$Nothing
	};
};
var $rundis$elm_bootstrap$Bootstrap$Modal$Footer = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$footer = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				e1: $elm$core$Maybe$Just(
					{ba: attributes, f_: children})
			});
	});
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $rundis$elm_bootstrap$Bootstrap$Modal$Header = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$header = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				c9: $elm$core$Maybe$Just(
					{ba: attributes, f_: children})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$titledHeader = F3(
	function (itemFn, attributes, children) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Modal$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					itemFn,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('modal-title'),
						attributes),
					children)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$h3 = $rundis$elm_bootstrap$Bootstrap$Modal$titledHeader($elm$html$Html$h3);
var $rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick = F2(
	function (hide, _v0) {
		var conf = _v0;
		var options = conf.aT;
		return _Utils_update(
			conf,
			{
				aT: _Utils_update(
					options,
					{bA: hide})
			});
	});
var $elm$html$Html$br = _VirtualDom_node('br');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Utils$CommonView$multilineTextLines = function (string) {
	return $elm$core$List$concat(
		A2(
			$elm$core$List$map,
			function (line) {
				return _List_fromArray(
					[
						$elm$html$Html$text(line),
						A2($elm$html$Html$br, _List_Nil, _List_Nil)
					]);
			},
			$elm$core$String$lines(string)));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs(attrs_);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rundis$elm_bootstrap$Bootstrap$Button$onClick = function (message) {
	return $rundis$elm_bootstrap$Bootstrap$Button$attrs(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Events$preventDefaultOn,
				'click',
				$elm$json$Json$Decode$succeed(
					_Utils_Tuple2(message, true)))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Primary = 0;
var $rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(0));
var $rundis$elm_bootstrap$Bootstrap$Modal$Show = 0;
var $rundis$elm_bootstrap$Bootstrap$Modal$shown = 0;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $rundis$elm_bootstrap$Bootstrap$Modal$StartClose = 1;
var $rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg = function (config_) {
	var _v0 = config_.az;
	if (!_v0.$) {
		var animationMsg = _v0.a;
		return animationMsg(1);
	} else {
		return config_.bf;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$isFade = function (conf) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return true;
			},
			conf.az));
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$backdrop = F2(
	function (visibility, conf) {
		var attributes = function () {
			switch (visibility) {
				case 0:
					return _Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal-backdrop', true),
										_Utils_Tuple2(
										'fade',
										$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
										_Utils_Tuple2('show', true)
									]))
							]),
						conf.aT.bA ? _List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf))
							]) : _List_Nil);
				case 1:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', true)
								]))
						]);
				case 2:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', false)
								]))
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', false),
									_Utils_Tuple2(
									'fade',
									$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
									_Utils_Tuple2('show', false)
								]))
						]);
			}
		}();
		return _List_fromArray(
			[
				A2($elm$html$Html$div, attributes, _List_Nil)
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['className']),
	$elm$json$Json$Decode$string);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder = function (closeMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (c) {
			return A2($elm$core$String$contains, 'elm-bootstrap-modal', c) ? $elm$json$Json$Decode$succeed(closeMsg) : $elm$json$Json$Decode$fail('ignoring');
		},
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className));
};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $rundis$elm_bootstrap$Bootstrap$Modal$display = F2(
	function (visibility, conf) {
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 1:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', false)
							])),
						A2(
						$elm$html$Html$Events$on,
						'transitionend',
						$elm$json$Json$Decode$succeed(conf.bf))
					]);
			default:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '0px'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', false)
							]))
					]);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$modalClass = function (size) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size);
	if (!_v0.$) {
		var s = _v0.a;
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-' + s)
			]);
	} else {
		return _List_Nil;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('modal-dialog', true),
						_Utils_Tuple2('modal-dialog-centered', options.eG)
					])),
				A2($elm$html$Html$Attributes$style, 'pointer-events', 'auto')
			]),
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Modal$modalClass, options.kY)));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderBody = function (maybeBody) {
	if (!maybeBody.$) {
		var cfg = maybeBody.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-body'),
					cfg.ba),
				cfg.f_));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderFooter = function (maybeFooter) {
	if (!maybeFooter.$) {
		var cfg = maybeFooter.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-footer'),
					cfg.ba),
				cfg.f_));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$closeButton = function (closeMsg) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('close'),
				$elm$html$Html$Events$onClick(closeMsg)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('×')
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderHeader = function (conf_) {
	var _v0 = conf_.c9;
	if (!_v0.$) {
		var cfg = _v0.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-header'),
					cfg.ba),
				_Utils_ap(
					cfg.f_,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Modal$closeButton(
							$rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf_))
						]))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$view = F2(
	function (visibility, _v0) {
		var conf = _v0;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$tabindex(-1)
								]),
							A2($rundis$elm_bootstrap$Bootstrap$Modal$display, visibility, conf)),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_Utils_ap(
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'role', 'document'),
											$elm$html$Html$Attributes$class('elm-bootstrap-modal')
										]),
									_Utils_ap(
										$rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes(conf.aT),
										conf.aT.bA ? _List_fromArray(
											[
												A2(
												$elm$html$Html$Events$on,
												'click',
												$rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder(conf.bf))
											]) : _List_Nil)),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-content')
											]),
										A2(
											$elm$core$List$filterMap,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Modal$renderHeader(conf),
													$rundis$elm_bootstrap$Bootstrap$Modal$renderBody(conf.h$),
													$rundis$elm_bootstrap$Bootstrap$Modal$renderFooter(conf.e1)
												])))
									]))
							]))
					]),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$backdrop, visibility, conf)));
	});
var $author$project$Utils$CommonModals$messageModal = function (_v0) {
	var title = _v0.mC;
	var content = _v0.iB;
	var close = _v0.ig;
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Modal$view,
		$rundis$elm_bootstrap$Bootstrap$Modal$shown,
		A3(
			$rundis$elm_bootstrap$Bootstrap$Modal$footer,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(close)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('OK')
						]))
				]),
			A3(
				$rundis$elm_bootstrap$Bootstrap$Modal$body,
				_List_Nil,
				$author$project$Utils$CommonView$multilineTextLines(content),
				A3(
					$rundis$elm_bootstrap$Bootstrap$Modal$h3,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick,
						false,
						$rundis$elm_bootstrap$Bootstrap$Modal$config(close))))));
};
var $author$project$Utils$CommonModals$errorModal = function (_v0) {
	var content = _v0.iB;
	var close = _v0.ig;
	return $author$project$Utils$CommonModals$messageModal(
		A3($author$project$Utils$CommonModals$MessageModalConfig, 'Virhe', content, close));
};
var $author$project$Main$renderModal = function (model) {
	var _v0 = model.bO;
	if (!_v0.$) {
		return $elm$html$Html$text('');
	} else {
		var message = _v0.a;
		return $author$project$Utils$CommonModals$errorModal(
			{ig: $author$project$Main$CloseCurrentModal, iB: message});
	}
};
var $author$project$Main$RequestLogin = {$: 4};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m2 = $elm$html$Html$Attributes$class('m-2');
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Pages$DocsPage$missingPermissionsView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m2]),
	_List_fromArray(
		[
			$elm$html$Html$text('Sinulla ei ole oikeutta tähän käyttöalueeseen')
		]));
var $rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignItemsCenter = $elm$html$Html$Attributes$class('align-items-center');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Size$h100 = $elm$html$Html$Attributes$class('h-100');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Flex$justifyCenter = $elm$html$Html$Attributes$class('justify-content-center');
var $author$project$Utils$CommonView$spinner = function (attrs) {
	return A2(
		$elm$html$Html$div,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spinner-border')
				]),
			attrs),
		_List_Nil);
};
var $author$project$Utils$CommonView$centeredLargeSpinner = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('d-flex flex-1'),
			$rundis$elm_bootstrap$Bootstrap$Utilities$Flex$justifyCenter,
			$rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignItemsCenter,
			$rundis$elm_bootstrap$Bootstrap$Utilities$Size$h100
		]),
	_List_fromArray(
		[
			$author$project$Utils$CommonView$spinner(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('spinner-large')
				]))
		]));
var $author$project$Utils$CommonView$multilineText = function (string) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		$author$project$Utils$CommonView$multilineTextLines(string));
};
var $author$project$Utils$CommonView$renderWebData = F2(
	function (renderSuccess, webData) {
		switch (webData.$) {
			case 3:
				var content = webData.a;
				return renderSuccess(content);
			case 2:
				var err = webData.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Utils$CommonView$multilineText(
							$author$project$Utils$HttpUtils$httpErrorToString(err))
						]));
			default:
				return $author$project$Utils$CommonView$centeredLargeSpinner;
		}
	});
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Pages$DocsPage$viewDocs = A2(
	$elm$html$Html$iframe,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$src('/static/docs/index.html'),
			$elm$html$Html$Attributes$class('flex-1')
		]),
	_List_Nil);
var $author$project$Pages$DocsPage$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('docs-page-root')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Utils$CommonView$renderWebData,
				function (hasPermissions) {
					if (hasPermissions) {
						return $author$project$Pages$DocsPage$viewDocs;
					} else {
						return $author$project$Pages$DocsPage$missingPermissionsView;
					}
				},
				model.bz)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Flex$col = $elm$html$Html$Attributes$class('flex-column');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3 = $elm$html$Html$Attributes$class('mb-3');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$primary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(0));
var $author$project$Pages$LoggedOutPage$view = function (_v0) {
	var requestLogin = _v0.lG;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('d-flex flex-1'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Size$h100,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Flex$col,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignItemsCenter,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Flex$justifyCenter
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h4,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]),
				_List_fromArray(
					[
						$elm$html$Html$text('Olet kirjautunut ulos')
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$onClick(requestLogin),
						$rundis$elm_bootstrap$Bootstrap$Button$primary
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Kirjaudu sisään')
					]))
			]));
};
var $author$project$Pages$Main$MainPage$BranchSelected = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$MainPage$RequestLogout = {$: 17};
var $author$project$Forms$CommandForms$CommandFormConfig$CommandFormConfig = F3(
	function (allConnections, allCommands, allViews) {
		return {fU: allCommands, eA: allConnections, hG: allViews};
	});
var $author$project$Lib$RemoteData$andMap = F2(
	function (wrappedValue, wrappedFunction) {
		var _v0 = _Utils_Tuple2(wrappedFunction, wrappedValue);
		_v0$5:
		while (true) {
			_v0$6:
			while (true) {
				_v0$7:
				while (true) {
					switch (_v0.a.$) {
						case 1:
							switch (_v0.b.$) {
								case 1:
									var f = _v0.a.a;
									var value = _v0.b.a;
									return $author$project$Lib$RemoteData$Loading(
										f(value));
								case 3:
									var f = _v0.a.a;
									var value = _v0.b.a;
									return $author$project$Lib$RemoteData$Loading(
										f(value));
								case 2:
									break _v0$5;
								default:
									break _v0$7;
							}
						case 3:
							switch (_v0.b.$) {
								case 3:
									var f = _v0.a.a;
									var value = _v0.b.a;
									return $author$project$Lib$RemoteData$Success(
										f(value));
								case 1:
									var f = _v0.a.a;
									var value = _v0.b.a;
									return $author$project$Lib$RemoteData$Loading(
										f(value));
								case 2:
									break _v0$5;
								default:
									break _v0$7;
							}
						case 2:
							var error = _v0.a.a;
							return $author$project$Lib$RemoteData$Failure(error);
						default:
							switch (_v0.b.$) {
								case 2:
									break _v0$5;
								case 0:
									break _v0$6;
								default:
									break _v0$6;
							}
					}
				}
				var _v2 = _v0.b;
				return $author$project$Lib$RemoteData$EmptyLoading;
			}
			var _v1 = _v0.a;
			return $author$project$Lib$RemoteData$EmptyLoading;
		}
		var error = _v0.b.a;
		return $author$project$Lib$RemoteData$Failure(error);
	});
var $author$project$Lib$RemoteData$map3 = F4(
	function (f, a, b, c) {
		return A2(
			$author$project$Lib$RemoteData$andMap,
			c,
			A2(
				$author$project$Lib$RemoteData$andMap,
				b,
				A2($author$project$Lib$RemoteData$map, f, a)));
	});
var $author$project$Pages$Main$CommandsView$CodeEditorValueChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$CommandsView$CommandFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Main$CommandsView$EditFormBackButtonClicked = {$: 3};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Utils$CommonView$icon = F2(
	function (iconName, attrs) {
		return A2(
			$elm$html$Html$span,
			attrs,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$i,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('fas fa-' + iconName)
						]),
					_List_Nil)
				]));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Utils$CommonView$iconButton = F3(
	function (iconName, options, innerHtml) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			options,
			_List_fromArray(
				[
					A2(
					$author$project$Utils$CommonView$icon,
					iconName,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'mr-2',
									!$elm$core$List$isEmpty(innerHtml))
								]))
						])),
					A2($elm$html$Html$span, _List_Nil, innerHtml)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$SM = 1;
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Size = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$small = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Size(1);
var $author$project$Pages$Main$FormViewHelpers$editEntityView = F3(
	function (backMsg, title, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('edit-entity-view')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('edit-entity-view__top-bar')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Utils$CommonView$iconButton,
							'arrow-left',
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Button$onClick(backMsg),
									$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
									$rundis$elm_bootstrap$Bootstrap$Button$small
								]),
							_List_Nil),
							A2(
							$elm$html$Html$h4,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(title)
								]))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('full-height-container')
						]),
					content)
				]));
	});
var $author$project$Lib$Form$Base$Base$Form = $elm$core$Basics$identity;
var $author$project$Lib$Form$Base$Base$fill = function (_v0) {
	var fill_ = _v0;
	return fill_;
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Lib$Form$Base$Base$append = F2(
	function (_new, current) {
		return function (values) {
			var filledNew = A2($author$project$Lib$Form$Base$Base$fill, _new, values);
			var filledCurrent = A2($author$project$Lib$Form$Base$Base$fill, current, values);
			var isEmpty = filledCurrent.b && filledNew.b;
			var fields = _Utils_ap(filledCurrent.c, filledNew.c);
			var _v0 = filledCurrent.d;
			if (!_v0.$) {
				var fn = _v0.a;
				return {
					c: fields,
					b: isEmpty,
					d: A2($elm$core$Result$map, fn, filledNew.d)
				};
			} else {
				var errors = _v0.a;
				var firstError = errors.a;
				var otherErrors = errors.b;
				var _v1 = filledNew.d;
				if (!_v1.$) {
					return {
						c: fields,
						b: isEmpty,
						d: $elm$core$Result$Err(errors)
					};
				} else {
					var _v2 = _v1.a;
					var newFirstError = _v2.a;
					var newOtherErrors = _v2.b;
					return {
						c: fields,
						b: isEmpty,
						d: $elm$core$Result$Err(
							_Utils_Tuple2(
								firstError,
								_Utils_ap(
									otherErrors,
									A2($elm$core$List$cons, newFirstError, newOtherErrors))))
					};
				}
			}
		};
	});
var $author$project$Lib$Form$Form$append = $author$project$Lib$Form$Base$Base$append;
var $author$project$Lib$Form$Base$Base$andThen = F2(
	function (child, parent) {
		return function (values) {
			var filled = A2($author$project$Lib$Form$Base$Base$fill, parent, values);
			var _v0 = filled.d;
			if (!_v0.$) {
				var output = _v0.a;
				var childFilled = A2(
					$author$project$Lib$Form$Base$Base$fill,
					child(output),
					values);
				return {
					c: _Utils_ap(filled.c, childFilled.c),
					b: filled.b && childFilled.b,
					d: childFilled.d
				};
			} else {
				var errors = _v0.a;
				return {
					c: filled.c,
					b: filled.b,
					d: $elm$core$Result$Err(errors)
				};
			}
		};
	});
var $author$project$Lib$Form$Form$andThen = $author$project$Lib$Form$Base$Base$andThen;
var $author$project$Lib$Form$Form$Checkbox = function (a) {
	return {$: 4, a: a};
};
var $author$project$Lib$Form$Base$Error$RequiredFieldIsEmpty = {$: 0};
var $author$project$Lib$Form$Base$Error$ValidationFailed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Lib$Form$Base$Base$field = F3(
	function (_v0, build, config) {
		var isEmpty = _v0.b;
		var requiredParser = function (value) {
			return isEmpty(value) ? $elm$core$Result$Err(
				_Utils_Tuple2($author$project$Lib$Form$Base$Error$RequiredFieldIsEmpty, _List_Nil)) : A2(
				$elm$core$Result$mapError,
				function (error) {
					return _Utils_Tuple2(
						$author$project$Lib$Form$Base$Error$ValidationFailed(error),
						_List_Nil);
				},
				config.U(value));
		};
		var parse = A2($elm$core$Basics$composeR, config.mY, requiredParser);
		var field_ = function (values) {
			var value = config.mY(values);
			var update = function (newValue) {
				return A2(config.a, newValue, values);
			};
			return build(
				{ba: config.ba, a: update, mY: value});
		};
		return function (values) {
			var result = parse(values);
			var _v1 = function () {
				if (!result.$) {
					return _Utils_Tuple2($elm$core$Maybe$Nothing, false);
				} else {
					var _v3 = result.a;
					var firstError = _v3.a;
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(firstError),
						_Utils_eq(firstError, $author$project$Lib$Form$Base$Error$RequiredFieldIsEmpty));
				}
			}();
			var error = _v1.a;
			var isEmpty_ = _v1.b;
			return {
				c: _List_fromArray(
					[
						_Utils_Tuple2(
						field_(values),
						error)
					]),
				b: isEmpty_,
				d: result
			};
		};
	});
var $author$project$Lib$Form$Base$CheckboxField$form = $author$project$Lib$Form$Base$Base$field(
	{
		b: $elm$core$Basics$always(false)
	});
var $author$project$Lib$Form$Form$checkboxField = $author$project$Lib$Form$Base$CheckboxField$form($author$project$Lib$Form$Form$Checkbox);
var $author$project$Lib$Form$Base$Base$map = F2(
	function (fn, form) {
		return function (values) {
			var filled = A2($author$project$Lib$Form$Base$Base$fill, form, values);
			return {
				c: filled.c,
				b: filled.b,
				d: A2($elm$core$Result$map, fn, filled.d)
			};
		};
	});
var $author$project$Lib$Form$Form$map = $author$project$Lib$Form$Base$Base$map;
var $author$project$Lib$Form$Base$Base$mapField = F2(
	function (fn, form) {
		return function (values) {
			var filled = A2($author$project$Lib$Form$Base$Base$fill, form, values);
			return {
				c: A2(
					$elm$core$List$map,
					function (_v0) {
						var field_ = _v0.a;
						var error = _v0.b;
						return _Utils_Tuple2(
							fn(field_),
							error);
					},
					filled.c),
				b: filled.b,
				d: filled.d
			};
		};
	});
var $author$project$Lib$Form$Form$Code = function (a) {
	return {$: 3, a: a};
};
var $author$project$Lib$Form$Form$Constant = function (a) {
	return {$: 0, a: a};
};
var $author$project$Lib$Form$Form$InlineGroup = function (a) {
	return {$: 6, a: a};
};
var $author$project$Lib$Form$Form$List = function (a) {
	return {$: 8, a: a};
};
var $author$project$Lib$Form$Form$Number = function (a) {
	return {$: 2, a: a};
};
var $author$project$Lib$Form$Form$Section = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Lib$Form$Form$Select = function (a) {
	return {$: 5, a: a};
};
var $author$project$Lib$Form$Form$Text = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Lib$Form$Base$Field$mapValues = F2(
	function (fn, _v0) {
		var value = _v0.mY;
		var update = _v0.a;
		var attributes = _v0.ba;
		return {
			ba: attributes,
			a: A2($elm$core$Basics$composeR, update, fn),
			mY: value
		};
	});
var $author$project$Lib$Form$Form$mapFieldValues = F3(
	function (update, values, field) {
		var newUpdate = function (oldValues) {
			return A2(update, oldValues, values);
		};
		var mapUpdate = F2(
			function (fn, value) {
				return A2(
					update,
					fn(value),
					values);
			});
		switch (field.$) {
			case 0:
				var field_ = field.a;
				return $author$project$Lib$Form$Form$Constant(
					A2($author$project$Lib$Form$Base$Field$mapValues, newUpdate, field_));
			case 1:
				var textType = field.a;
				var field_ = field.b;
				return A2(
					$author$project$Lib$Form$Form$Text,
					textType,
					A2($author$project$Lib$Form$Base$Field$mapValues, newUpdate, field_));
			case 2:
				var field_ = field.a;
				return $author$project$Lib$Form$Form$Number(
					A2($author$project$Lib$Form$Base$Field$mapValues, newUpdate, field_));
			case 3:
				var field_ = field.a;
				return $author$project$Lib$Form$Form$Code(
					A2($author$project$Lib$Form$Base$Field$mapValues, newUpdate, field_));
			case 4:
				var field_ = field.a;
				return $author$project$Lib$Form$Form$Checkbox(
					A2($author$project$Lib$Form$Base$Field$mapValues, newUpdate, field_));
			case 5:
				var field_ = field.a;
				return $author$project$Lib$Form$Form$Select(
					A2($author$project$Lib$Form$Base$Field$mapValues, newUpdate, field_));
			case 6:
				var fields = field.a;
				return $author$project$Lib$Form$Form$InlineGroup(
					A2(
						$elm$core$List$map,
						function (_v1) {
							var field_ = _v1.a;
							var error = _v1.b;
							return _Utils_Tuple2(
								A3($author$project$Lib$Form$Form$mapFieldValues, update, values, field_),
								error);
						},
						fields));
			case 7:
				var title = field.a;
				var fields = field.b;
				return A2(
					$author$project$Lib$Form$Form$Section,
					title,
					A2(
						$elm$core$List$map,
						function (_v2) {
							var field_ = _v2.a;
							var error = _v2.b;
							return _Utils_Tuple2(
								A3($author$project$Lib$Form$Form$mapFieldValues, update, values, field_),
								error);
						},
						fields));
			default:
				var forms = field.a.gm;
				var add = field.a.fR;
				var attributes = field.a.ba;
				return $author$project$Lib$Form$Form$List(
					{
						fR: function (_v3) {
							return A2(
								update,
								add(0),
								values);
						},
						ba: attributes,
						gm: A2(
							$elm$core$List$map,
							function (_v4) {
								var fields = _v4.c;
								var _delete = _v4.f8;
								var move = _v4.gF;
								return {
									f8: function (_v5) {
										return A2(
											update,
											_delete(0),
											values);
									},
									c: A2(
										$elm$core$List$map,
										function (_v6) {
											var field_ = _v6.a;
											var error = _v6.b;
											return _Utils_Tuple2(
												A3($author$project$Lib$Form$Form$mapFieldValues, update, values, field_),
												error);
										},
										fields),
									gF: function (direction) {
										return A2(
											update,
											move(direction),
											values);
									}
								};
							},
							forms)
					});
		}
	});
var $author$project$Lib$Form$Base$Base$mapValues = F2(
	function (fn, form) {
		return A2(
			$elm$core$Basics$composeR,
			fn,
			$author$project$Lib$Form$Base$Base$fill(form));
	});
var $author$project$Lib$Form$Base$Base$meta = function (fn) {
	return function (values) {
		return A2(
			$author$project$Lib$Form$Base$Base$fill,
			fn(values),
			values);
	};
};
var $author$project$Lib$Form$Form$mapValues = F2(
	function (_v0, form) {
		var value = _v0.mY;
		var update = _v0.a;
		return $author$project$Lib$Form$Base$Base$meta(
			function (values) {
				return A2(
					$author$project$Lib$Form$Base$Base$mapField,
					A2($author$project$Lib$Form$Form$mapFieldValues, update, values),
					A2($author$project$Lib$Form$Base$Base$mapValues, value, form));
			});
	});
var $author$project$Lib$Form$Base$Base$succeed = function (output) {
	return $elm$core$Basics$always(
		{
			c: _List_Nil,
			b: true,
			d: $elm$core$Result$Ok(output)
		});
};
var $author$project$Lib$Form$Form$succeed = $author$project$Lib$Form$Base$Base$succeed;
var $author$project$Forms$CommonForms$convertFormToOptional = F2(
	function (label, form) {
		return A2(
			$author$project$Lib$Form$Form$andThen,
			function (isDefined) {
				return isDefined ? A2(
					$author$project$Lib$Form$Form$map,
					$elm$core$Maybe$Just,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{bD: newValue});
								}),
							mY: function ($) {
								return $.bD;
							}
						},
						form)) : $author$project$Lib$Form$Form$succeed($elm$core$Maybe$Nothing);
			},
			$author$project$Lib$Form$Form$checkboxField(
				{
					ba: {kv: label},
					U: $elm$core$Result$Ok,
					a: F2(
						function (newValue, values) {
							return _Utils_update(
								values,
								{bF: newValue});
						}),
					mY: function ($) {
						return $.bF;
					}
				}));
	});
var $author$project$Forms$ClauseForms$ClauseFormConfig = F4(
	function (codeEditorMode, title, allConnections, allCommands) {
		return {fU: allCommands, eA: allConnections, f0: codeEditorMode, mC: title};
	});
var $author$project$Lib$CodeMirror$JSON = 4;
var $author$project$Forms$ClauseForms$jsonClauseFormConfig = $author$project$Forms$ClauseForms$ClauseFormConfig(4);
var $author$project$Forms$ClauseForms$CommandClauseOption = 4;
var $author$project$Forms$ClauseForms$DatabaseOption = 1;
var $author$project$Forms$ClauseForms$DirectValueOption = 0;
var $author$project$Forms$ClauseForms$HttpOption = 2;
var $author$project$Forms$ClauseForms$KotlinClauseOption = 5;
var $author$project$Forms$ClauseForms$PythonOption = 3;
var $author$project$Forms$ClauseForms$SequenceClauseOption = 6;
var $author$project$Lib$Form$Base$SelectField$form = $author$project$Lib$Form$Base$Base$field(
	{b: $elm$core$String$isEmpty});
var $author$project$Lib$Form$Form$selectField = $author$project$Lib$Form$Base$SelectField$form($author$project$Lib$Form$Form$Select);
var $author$project$Forms$ClauseForms$clauseTypeSelectField = $author$project$Lib$Form$Form$selectField(
	{
		ba: {
			kv: 'Tyyppi',
			aT: _List_fromArray(
				[
					_Utils_Tuple2('directValue', 'Suora arvo'),
					_Utils_Tuple2('database', 'Tietokantalause'),
					_Utils_Tuple2('http', 'HTTP-kutsu'),
					_Utils_Tuple2('python', 'Python'),
					_Utils_Tuple2('command', 'Komento'),
					_Utils_Tuple2('kotlin', 'Kotlin'),
					_Utils_Tuple2('sequence', 'Sarjalause')
				]),
			lm: $elm$core$Maybe$Nothing
		},
		U: function (str) {
			switch (str) {
				case 'directValue':
					return $elm$core$Result$Ok(0);
				case 'database':
					return $elm$core$Result$Ok(1);
				case 'http':
					return $elm$core$Result$Ok(2);
				case 'python':
					return $elm$core$Result$Ok(3);
				case 'command':
					return $elm$core$Result$Ok(4);
				case 'kotlin':
					return $elm$core$Result$Ok(5);
				case 'sequence':
					return $elm$core$Result$Ok(6);
				default:
					return $elm$core$Result$Err('Invalid clause type');
			}
		},
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{mM: value});
			}),
		mY: function ($) {
			return $.mM;
		}
	});
var $author$project$Lib$Form$Form$TextRaw = 0;
var $author$project$Lib$Form$Base$TextField$form = $author$project$Lib$Form$Base$Base$field(
	{b: $elm$core$String$isEmpty});
var $author$project$Lib$Form$Form$textField = $author$project$Lib$Form$Base$TextField$form(
	$author$project$Lib$Form$Form$Text(0));
var $author$project$Lib$Form$Form$simpleTextField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	return $author$project$Lib$Form$Form$textField(
		{
			ba: {hS: $elm$core$Maybe$Nothing, kv: label, lm: ''},
			U: $elm$core$Result$Ok,
			a: update,
			mY: value
		});
};
var $author$project$Forms$ClauseForms$kotlinClauseForm = function () {
	var packageVersionField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Paketin versio',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{le: value});
				}),
			mY: function ($) {
				return $.le;
			}
		});
	var functionNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Funktio',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jQ: value});
				}),
			mY: function ($) {
				return $.jQ;
			}
		});
	var classNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Luokka',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ic: value});
				}),
			mY: function ($) {
				return $.ic;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		packageVersionField,
		A2(
			$author$project$Lib$Form$Form$append,
			functionNameField,
			A2(
				$author$project$Lib$Form$Form$append,
				classNameField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$KotlinClauseData))));
}();
var $author$project$Forms$CommandParameterForm$ResolveParamsOnBackend = 1;
var $author$project$Lib$Form$Base$Base$custom = function (fillField) {
	return function (values) {
		var filled = fillField(values);
		return {
			c: _List_fromArray(
				[
					_Utils_Tuple2(
					filled.gj,
					function () {
						var _v0 = filled.d;
						if (!_v0.$) {
							return $elm$core$Maybe$Nothing;
						} else {
							var _v1 = _v0.a;
							var firstError = _v1.a;
							return $elm$core$Maybe$Just(firstError);
						}
					}())
				]),
			b: filled.b,
			d: filled.d
		};
	};
};
var $author$project$Lib$Form$Form$inlineGroup = function (form) {
	return $author$project$Lib$Form$Base$Base$custom(
		function (values) {
			var _v0 = A2($author$project$Lib$Form$Base$Base$fill, form, values);
			var fields = _v0.c;
			var result = _v0.d;
			var isEmpty = _v0.b;
			return {
				gj: $author$project$Lib$Form$Form$InlineGroup(fields),
				b: isEmpty,
				d: result
			};
		});
};
var $author$project$Lib$Form$Base$Base$allowEmpty = F2(
	function (resultForEmpty, form) {
		return function (values) {
			var filled = A2($author$project$Lib$Form$Base$Base$fill, form, values);
			var _v0 = filled.d;
			if (!_v0.$) {
				return filled;
			} else {
				var _v1 = _v0.a;
				var firstError = _v1.a;
				var otherErrors = _v1.b;
				return filled.b ? {
					c: A2(
						$elm$core$List$map,
						function (_v2) {
							var field_ = _v2.a;
							return _Utils_Tuple2(field_, $elm$core$Maybe$Nothing);
						},
						filled.c),
					b: true,
					d: $elm$core$Result$Ok(resultForEmpty)
				} : {
					c: filled.c,
					b: false,
					d: $elm$core$Result$Err(
						_Utils_Tuple2(firstError, otherErrors))
				};
			}
		};
	});
var $author$project$Lib$Form$Form$allowEmpty = $author$project$Lib$Form$Base$Base$allowEmpty;
var $elm_community$string_extra$String$Extra$nonEmpty = function (string) {
	return $elm$core$String$isEmpty(string) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(string);
};
var $author$project$Lib$Form$Form$nullableTextField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	return A2(
		$author$project$Lib$Form$Form$allowEmpty,
		$elm$core$Maybe$Nothing,
		$author$project$Lib$Form$Form$textField(
			{
				ba: {hS: $elm$core$Maybe$Nothing, kv: label, lm: ''},
				U: A2($elm$core$Basics$composeR, $elm_community$string_extra$String$Extra$nonEmpty, $elm$core$Result$Ok),
				a: F2(
					function (value_, values) {
						return A2(
							update,
							$elm_community$string_extra$String$Extra$nonEmpty(value_),
							values);
					}),
				mY: A2(
					$elm$core$Basics$composeR,
					value,
					$elm$core$Maybe$withDefault(''))
			}));
};
var $elm_community$maybe_extra$Maybe$Extra$orElse = F2(
	function (ma, mb) {
		if (mb.$ === 1) {
			return ma;
		} else {
			return mb;
		}
	});
var $author$project$Utils$StringUtils$parseToBool = function (string) {
	return (($elm$core$String$toLower(string) === 'true') || (string === '1')) ? $elm$core$Maybe$Just(true) : ((($elm$core$String$toLower(string) === 'false') || (string === '0')) ? $elm$core$Maybe$Just(false) : $elm$core$Maybe$Nothing);
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Serializable$parse = function (str) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Serializable$String_(str),
		A2(
			$elm_community$maybe_extra$Maybe$Extra$orElse,
			A2(
				$elm$core$Maybe$map,
				$author$project$Serializable$Bool_,
				$author$project$Utils$StringUtils$parseToBool(str)),
			A2(
				$elm_community$maybe_extra$Maybe$Extra$orElse,
				A2(
					$elm$core$Maybe$map,
					$author$project$Serializable$Float_,
					$elm$core$String$toFloat(str)),
				A2(
					$elm$core$Maybe$map,
					$author$project$Serializable$Int_,
					$elm$core$String$toInt(str)))));
};
var $author$project$Forms$CommandParameterForm$commandParameterField = function (templateResolving) {
	var valueFieldLabelSuffix = function () {
		switch (templateResolving) {
			case 0:
				return '';
			case 1:
				return ' {{B}}';
			default:
				return ' {{F}}';
		}
	}();
	var valueField = A2(
		$author$project$Lib$Form$Form$map,
		$elm$core$Maybe$map($author$project$Serializable$parse),
		$author$project$Lib$Form$Form$nullableTextField(
			{
				kv: 'Arvo (tyhjä => NULL)' + valueFieldLabelSuffix,
				a: F2(
					function (value_, values) {
						return _Utils_update(
							values,
							{mY: value_});
					}),
				mY: function ($) {
					return $.mY;
				}
			}));
	var keyField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Nimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kt: value});
				}),
			mY: function ($) {
				return $.kt;
			}
		});
	return $author$project$Lib$Form$Form$inlineGroup(
		A2(
			$author$project$Lib$Form$Form$append,
			valueField,
			A2(
				$author$project$Lib$Form$Form$append,
				keyField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$CommandParameter$CommandParameter))));
};
var $author$project$Lib$Form$Form$fill = $author$project$Lib$Form$Base$Base$fill;
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var tail = $elm$core$List$tail(
				A2($elm$core$List$drop, index, l));
			var head = A2($elm$core$List$take, index, l);
			if (tail.$ === 1) {
				return l;
			} else {
				var t = tail.a;
				return A2($elm$core$List$append, head, t);
			}
		}
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $elm_community$list_extra$List$Extra$setAt = F2(
	function (index, value) {
		return A2(
			$elm_community$list_extra$List$Extra$updateAt,
			index,
			$elm$core$Basics$always(value));
	});
var $elm_community$list_extra$List$Extra$splitAt = F2(
	function (n, xs) {
		return _Utils_Tuple2(
			A2($elm$core$List$take, n, xs),
			A2($elm$core$List$drop, n, xs));
	});
var $elm_community$list_extra$List$Extra$uncons = function (list) {
	if (!list.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		var first = list.a;
		var rest = list.b;
		return $elm$core$Maybe$Just(
			_Utils_Tuple2(first, rest));
	}
};
var $elm_community$list_extra$List$Extra$swapAt = F3(
	function (index1, index2, l) {
		swapAt:
		while (true) {
			if (_Utils_eq(index1, index2) || (index1 < 0)) {
				return l;
			} else {
				if (_Utils_cmp(index1, index2) > 0) {
					var $temp$index1 = index2,
						$temp$index2 = index1,
						$temp$l = l;
					index1 = $temp$index1;
					index2 = $temp$index2;
					l = $temp$l;
					continue swapAt;
				} else {
					var _v0 = A2($elm_community$list_extra$List$Extra$splitAt, index1, l);
					var part1 = _v0.a;
					var tail1 = _v0.b;
					var _v1 = A2($elm_community$list_extra$List$Extra$splitAt, index2 - index1, tail1);
					var head2 = _v1.a;
					var tail2 = _v1.b;
					var _v2 = _Utils_Tuple2(
						$elm_community$list_extra$List$Extra$uncons(head2),
						$elm_community$list_extra$List$Extra$uncons(tail2));
					if ((!_v2.a.$) && (!_v2.b.$)) {
						var _v3 = _v2.a.a;
						var value1 = _v3.a;
						var part2 = _v3.b;
						var _v4 = _v2.b.a;
						var value2 = _v4.a;
						var part3 = _v4.b;
						return $elm$core$List$concat(
							_List_fromArray(
								[
									part1,
									A2($elm$core$List$cons, value2, part2),
									A2($elm$core$List$cons, value1, part3)
								]));
					} else {
						return l;
					}
				}
			}
		}
	});
var $author$project$Lib$Form$Base$FormList$form = F3(
	function (tagger, _v0, buildElement) {
		var value = _v0.mY;
		var update = _v0.a;
		var _default = _v0.f7;
		var attributes = _v0.ba;
		return $author$project$Lib$Form$Base$Base$custom(
			function (values) {
				var listOfElementValues = value(values);
				var toForm = F2(
					function (index, _v8) {
						var fields = _v8.c;
						return {
							f8: function (_v6) {
								return A2(
									update,
									A2($elm_community$list_extra$List$Extra$removeAt, index, listOfElementValues),
									values);
							},
							c: fields,
							gF: function (dir) {
								var newIndex = function () {
									if (!dir) {
										return index - 1;
									} else {
										return index + 1;
									}
								}();
								return A2(
									update,
									A3($elm_community$list_extra$List$Extra$swapAt, index, newIndex, listOfElementValues),
									values);
							}
						};
					});
				var gatherResults = F2(
					function (next, current) {
						var _v2 = next.d;
						if (!_v2.$) {
							var output = _v2.a;
							return A2(
								$elm$core$Result$map,
								$elm$core$List$cons(output),
								current);
						} else {
							var _v3 = _v2.a;
							var head = _v3.a;
							var errors = _v3.b;
							if (!current.$) {
								return $elm$core$Result$Err(
									_Utils_Tuple2(head, errors));
							} else {
								var _v5 = current.a;
								var currentHead = _v5.a;
								var currentErrors = _v5.b;
								return $elm$core$Result$Err(
									_Utils_Tuple2(
										head,
										_Utils_ap(
											errors,
											A2($elm$core$List$cons, currentHead, currentErrors))));
							}
						}
					});
				var elementForIndex = F2(
					function (index, elementValues) {
						return buildElement(
							{
								gc: elementValues,
								gu: index,
								a: F2(
									function (newElementValues, values_) {
										var newList = A3($elm_community$list_extra$List$Extra$setAt, index, newElementValues, listOfElementValues);
										return A2(update, newList, values_);
									}),
								hr: values
							});
					});
				var filledElements = A2($elm$core$List$indexedMap, elementForIndex, listOfElementValues);
				var result = A3(
					$elm$core$List$foldr,
					gatherResults,
					$elm$core$Result$Ok(_List_Nil),
					filledElements);
				return {
					gj: tagger(
						{
							fR: function (_v1) {
								return A2(
									update,
									_Utils_ap(
										listOfElementValues,
										_List_fromArray(
											[_default])),
									values);
							},
							ba: attributes,
							gm: A2($elm$core$List$indexedMap, toForm, filledElements)
						}),
					b: A2(
						$elm$core$List$all,
						function ($) {
							return $.b;
						},
						filledElements),
					d: result
				};
			});
	});
var $author$project$Lib$Form$Form$list = F2(
	function (config, elementForIndex) {
		var fillElement = function (_v1) {
			var index = _v1.gu;
			var update = _v1.a;
			var values = _v1.hr;
			var elementValues = _v1.gc;
			var filledElement = A2(
				$author$project$Lib$Form$Form$fill,
				elementForIndex(index),
				elementValues);
			return {
				c: A2(
					$elm$core$List$map,
					function (_v0) {
						var field = _v0.a;
						var error = _v0.b;
						return _Utils_Tuple2(
							A3($author$project$Lib$Form$Form$mapFieldValues, update, values, field),
							error);
					},
					filledElement.c),
				b: filledElement.b,
				d: filledElement.d
			};
		};
		return A3($author$project$Lib$Form$Base$FormList$form, $author$project$Lib$Form$Form$List, config, fillElement);
	});
var $author$project$Lib$Form$Form$simpleList = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var _default = _v0.f7;
	var label = _v0.kv;
	return $author$project$Lib$Form$Form$list(
		{
			ba: {
				hD: $elm$core$Maybe$Just('Uusi'),
				iZ: true,
				kv: label,
				ld: true
			},
			f7: _default,
			a: update,
			mY: value
		});
};
var $author$project$Forms$CommandParameterForm$commandParameterFormList = function (templateResolving) {
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {kt: '', mY: $elm$core$Maybe$Nothing},
			kv: 'Parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lk: value});
				}),
			mY: function ($) {
				return $.lk;
			}
		},
		function (_v0) {
			return $author$project$Forms$CommandParameterForm$commandParameterField(templateResolving);
		});
};
var $author$project$Entity$Command$commandTypeComparator = F2(
	function (a, b) {
		var getPriority = function (command) {
			var _v0 = command.mh;
			switch (_v0.$) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				case 3:
					return 4;
				case 4:
					return 5;
				case 5:
					return 6;
				case 6:
					return 7;
				case 7:
					return 8;
				case 8:
					return 9;
				case 9:
					return 10;
				case 10:
					return 11;
				case 11:
					return 12;
				case 14:
					return 13;
				case 15:
					return 14;
				case 13:
					return 15;
				default:
					return 16;
			}
		};
		return A2(
			$elm$core$Basics$compare,
			getPriority(a),
			getPriority(b));
	});
var $author$project$Entity$Command$getCommandLongDescription = function (command) {
	return $elm$core$String$fromInt(command.db) + (' | ' + ($author$project$Entity$Command$getCommandTypeAsString(command) + (' | ' + (command.kt + (' | ' + $author$project$Utils$StringUtils$firstLine(command.i$))))));
};
var $author$project$Utils$ListUtils$mapValues = function (tagger) {
	return $elm$core$List$map(
		function (_v0) {
			var k = _v0.a;
			var v = _v0.b;
			return _Utils_Tuple2(
				k,
				tagger(v));
		});
};
var $author$project$Forms$CommonForms$selectIdFieldInternal = function (config) {
	return $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: config.kv,
				aT: A2(
					$elm$core$List$map,
					function (_v0) {
						var id = _v0.a;
						var text = _v0.b;
						return _Utils_Tuple2(
							$elm$core$String$fromInt(id),
							text);
					},
					config.aT),
				lm: $elm$core$Maybe$Just('')
			},
			U: config.U,
			a: F2(
				function (value, values) {
					return A2(
						config.a,
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Entity$Common$nonExistentId,
							$elm$core$String$toInt(value)),
						values);
				}),
			mY: function (values) {
				var value = config.mY(values);
				return _Utils_eq(value, $author$project$Entity$Common$nonExistentId) ? A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $elm$core$String$fromInt),
						$elm$core$List$head(config.aT))) : $elm$core$String$fromInt(value);
			}
		});
};
var $author$project$Forms$CommonForms$selectIdField = function (config) {
	return $author$project$Forms$CommonForms$selectIdFieldInternal(
		{
			kv: config.kv,
			aT: config.aT,
			U: A2(
				$elm$core$Basics$composeR,
				$elm$core$String$toInt,
				$elm$core$Result$fromMaybe('Virhe')),
			a: config.a,
			mY: config.mY
		});
};
var $elm$core$List$sortWith = _List_sortWith;
var $author$project$Utils$ListUtils$sortByValues = function (comparator) {
	return $elm$core$List$sortWith(
		F2(
			function (a, b) {
				return A2(comparator, a.b, b.b);
			}));
};
var $author$project$Forms$CommandCallDefinitionForm$makeCommandCallDefinitionForm = F2(
	function (templateResolving, acceptableCommands) {
		var commandIdField = $author$project$Forms$CommonForms$selectIdField(
			{
				kv: 'Komento',
				aT: A2(
					$author$project$Utils$ListUtils$mapValues,
					$author$project$Entity$Command$getCommandLongDescription,
					A2(
						$author$project$Utils$ListUtils$sortByValues,
						$author$project$Entity$Command$commandTypeComparator,
						$elm$core$Dict$toList(acceptableCommands))),
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{f2: value});
					}),
				mY: function ($) {
					return $.f2;
				}
			});
		return A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandParameterForm$commandParameterFormList(templateResolving),
			A2(
				$author$project$Lib$Form$Form$append,
				commandIdField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$CommandCallDefinition$CommandCallDefinition)));
	});
var $author$project$Lib$Form$Form$simpleCheckboxField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	return $author$project$Lib$Form$Form$checkboxField(
		{
			ba: {kv: label},
			U: $elm$core$Result$Ok,
			a: update,
			mY: value
		});
};
var $author$project$Forms$ClauseForms$makeCommandClauseForm = function (allCommands) {
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Lib$Form$Form$simpleCheckboxField(
			{
				kv: 'Asynkroninen suoritus',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{hP: value});
					}),
				mY: function ($) {
					return $.hP;
				}
			}),
		A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{il: value});
						}),
					mY: function ($) {
						return $.il;
					}
				},
				A2($author$project$Forms$CommandCallDefinitionForm$makeCommandCallDefinitionForm, 1, allCommands)),
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$CommandClauseData)));
};
var $author$project$Lib$CodeMirror$SqlServer = 7;
var $author$project$Lib$Form$Base$CodeField$form = $author$project$Lib$Form$Base$Base$field(
	{b: $elm$core$String$isEmpty});
var $author$project$Lib$Form$Form$codeField = $author$project$Lib$Form$Base$CodeField$form($author$project$Lib$Form$Form$Code);
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Utils$DictUtils$filterByValue = function (isGood) {
	return $elm$core$Dict$filter(
		F2(
			function (_v0, v) {
				return isGood(v);
			}));
};
var $author$project$Entity$Connection$isDatabaseOrDynamicConnection = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return true;
		case 1:
			return true;
		default:
			return false;
	}
};
var $author$project$Forms$ClauseForms$makeExplicitConnectionIdField = function (options) {
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{gi: value});
				}),
			mY: function ($) {
				return $.gi;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Määritä yhteys',
			$author$project$Forms$CommonForms$selectIdField(
				{
					kv: 'Yhteys',
					aT: $elm$core$Dict$toList(options),
					a: F2(
						function (value, _v0) {
							return value;
						}),
					mY: $elm$core$Basics$identity
				})));
};
var $author$project$Forms$ClauseForms$makeDatabaseClauseForm = function (allConnections) {
	var resultSourceField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tuloksen lähde',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('LastSelect', 'Viimeinen SELECT'),
						_Utils_Tuple2('GenericOutParameter', 'OUT-parametri (__CLAUSE_RESULT_OUT__)'),
						_Utils_Tuple2('OracleRefCursor', 'Oraclen ref-kursori (__CLAUSE_RESULT_ORACLE_CURSOR__)')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseDatabaseClauseResultSource,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lN: value});
				}),
			mY: function ($) {
				return $.lN;
			}
		});
	var metadataField = $author$project$Lib$Form$Form$codeField(
		{
			ba: {kv: 'Tuloksen metadata', aP: 4, lm: ''},
			U: A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeString($author$project$Decoding$ClauseDecoding$databaseClauseMetadataDecoder),
				$elm$core$Result$mapError($elm$json$Json$Decode$errorToString)),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kQ: value});
				}),
			mY: function ($) {
				return $.kQ;
			}
		});
	var interpretationField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tuloksen tulkinta',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('Scalar', 'Skalaari'),
						_Utils_Tuple2('HorizontalList', 'Vaakasuuntainen lista (ensimmäinen rivi)'),
						_Utils_Tuple2('VerticalList', 'Pystysuuntainen lista (ensimmäinen sarake)'),
						_Utils_Tuple2('HorizontalMap', 'Vaakasuuntainen avain-arvo lista (ensimmäinen rivi ja sarakkeiden nimet)'),
						_Utils_Tuple2('VerticalMap', 'Pystysuuntainen avain-arvo lista (kaksi ensimmäistä saraketta)'),
						_Utils_Tuple2('Table', 'Taulukko')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseDatabaseClauseInterpretation,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kn: value});
				}),
			mY: function ($) {
				return $.kn;
			}
		});
	var commandField = $author$project$Lib$Form$Form$codeField(
		{
			ba: {kv: 'Komento', aP: 7, lm: 'Komento'},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ij: value});
				}),
			mY: function ($) {
				return $.ij;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		metadataField,
		A2(
			$author$project$Lib$Form$Form$append,
			resultSourceField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$ClauseForms$makeExplicitConnectionIdField(
					A2(
						$author$project$Utils$DictUtils$mapValues,
						function ($) {
							return $.k_;
						},
						A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Connection$isDatabaseOrDynamicConnection, allConnections))),
				A2(
					$author$project$Lib$Form$Form$append,
					interpretationField,
					A2(
						$author$project$Lib$Form$Form$append,
						commandField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$DatabaseClauseData))))));
};
var $author$project$Lib$Form$Form$allowEmptyString = $author$project$Lib$Form$Base$Base$allowEmpty('');
var $author$project$Forms$ClauseForms$makeDirectValueForm = function (mode) {
	var valueField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$codeField(
			{
				ba: {kv: 'Lause {{B}}', aP: mode, lm: 'Lause'},
				U: $elm$core$Result$Ok,
				a: F2(
					function (newValue, values) {
						return _Utils_update(
							values,
							{mY: newValue});
					}),
				mY: function ($) {
					return $.mY;
				}
			}));
	return A2(
		$author$project$Lib$Form$Form$append,
		valueField,
		$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$DirectValueClauseData));
};
var $author$project$Lib$CodeMirror$PlainText = 0;
var $author$project$Entity$Connection$isHttpOrDynamicConnection = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return true;
		case 2:
			return true;
		default:
			return false;
	}
};
var $author$project$Lib$Form$Base$Base$optional = function (form) {
	return function (values) {
		var filled = A2($author$project$Lib$Form$Base$Base$fill, form, values);
		var _v0 = filled.d;
		if (!_v0.$) {
			var value = _v0.a;
			return {
				c: filled.c,
				b: filled.b,
				d: $elm$core$Result$Ok(
					$elm$core$Maybe$Just(value))
			};
		} else {
			var _v1 = _v0.a;
			var firstError = _v1.a;
			var otherErrors = _v1.b;
			return filled.b ? {
				c: A2(
					$elm$core$List$map,
					function (_v2) {
						var field_ = _v2.a;
						return _Utils_Tuple2(field_, $elm$core$Maybe$Nothing);
					},
					filled.c),
				b: true,
				d: $elm$core$Result$Ok($elm$core$Maybe$Nothing)
			} : {
				c: filled.c,
				b: false,
				d: $elm$core$Result$Err(
					_Utils_Tuple2(firstError, otherErrors))
			};
		}
	};
};
var $author$project$Lib$Form$Form$optional = $author$project$Lib$Form$Base$Base$optional;
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $author$project$Lib$Form$Form$simpleCodeField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	var mode = _v0.aP;
	return $author$project$Lib$Form$Form$codeField(
		{
			ba: {kv: label, aP: mode, lm: ''},
			U: $elm$core$Result$Ok,
			a: update,
			mY: value
		});
};
var $author$project$Forms$ClauseForms$makeHttpClauseForm = function (allConnections) {
	var pathField = $author$project$Lib$Form$Form$simpleCodeField(
		{
			kv: 'Polku {{B}}',
			aP: 0,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ll: value});
				}),
			mY: function ($) {
				return $.ll;
			}
		});
	var methodField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Metodi',
				aT: A2(
					$elm$core$List$map,
					function (m) {
						return A2($elm$core$Tuple$pair, m, m);
					},
					_List_fromArray(
						['GET', 'PUT', 'POST', 'DELETE'])),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseHttpMethod,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kR: value});
				}),
			mY: function ($) {
				return $.kR;
			}
		});
	var bodyField = $author$project$Lib$Form$Form$optional(
		$author$project$Lib$Form$Form$simpleCodeField(
			{
				kv: 'Body {{B}}',
				aP: 4,
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{h$: value});
					}),
				mY: function ($) {
					return $.h$;
				}
			}));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ClauseForms$makeExplicitConnectionIdField(
			A2(
				$author$project$Utils$DictUtils$mapValues,
				function ($) {
					return $.k_;
				},
				A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Connection$isHttpOrDynamicConnection, allConnections))),
		A2(
			$author$project$Lib$Form$Form$append,
			bodyField,
			A2(
				$author$project$Lib$Form$Form$append,
				methodField,
				A2(
					$author$project$Lib$Form$Form$append,
					pathField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$HttpClauseData)))));
};
var $author$project$Lib$CodeMirror$Python = 6;
var $author$project$Forms$ClauseForms$makePythonClauseConnectionRedirectionForm = function (config) {
	var nameField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Yhteyden nimi',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{k_: value});
					}),
				mY: function ($) {
					return $.k_;
				}
			}));
	var connectionIdField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Yhteys',
			aT: A2(
				$elm$core$List$map,
				function (_v0) {
					var id = _v0.db;
					var name = _v0.k_;
					return _Utils_Tuple2(id, name);
				},
				$elm$core$Dict$values(config.eA)),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iw: value});
				}),
			mY: function ($) {
				return $.iw;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		connectionIdField,
		A2(
			$author$project$Lib$Form$Form$append,
			nameField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$PythonClauseConnectionRedirection)));
};
var $author$project$Forms$ClauseForms$makePythonClauseForm = function (config) {
	var signatureField = $author$project$Lib$Form$Form$nullableTextField(
		{
			kv: 'Allekirjoitus',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mf: value});
				}),
			mY: function ($) {
				return $.mf;
			}
		});
	var scriptField = $author$project$Lib$Form$Form$codeField(
		{
			ba: {kv: 'Koodi', aP: 6, lm: ''},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lW: value});
				}),
			mY: function ($) {
				return $.lW;
			}
		});
	var connectionRedirectionsForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {iw: $author$project$Entity$Common$nonExistentId, k_: ''},
			kv: 'Välitettävät yhteydet',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lz: value});
				}),
			mY: function ($) {
				return $.lz;
			}
		},
		function (_v0) {
			return $author$project$Forms$ClauseForms$makePythonClauseConnectionRedirectionForm(config);
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		signatureField,
		A2(
			$author$project$Lib$Form$Form$append,
			connectionRedirectionsForm,
			A2(
				$author$project$Lib$Form$Form$append,
				scriptField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$PythonClauseData))));
};
var $author$project$Forms$ClauseForms$makeClauseFormWithoutTitle = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var codeEditorMode = config.f0;
	return A2(
		$author$project$Lib$Form$Form$andThen,
		function (clauseType) {
			switch (clauseType) {
				case 0:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{cR: newValue});
								}),
							mY: function ($) {
								return $.cR;
							}
						},
						A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Clauses$DirectValueClause,
							$author$project$Forms$ClauseForms$makeDirectValueForm(codeEditorMode)));
				case 1:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{cO: newValue});
								}),
							mY: function ($) {
								return $.cO;
							}
						},
						A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Clauses$DatabaseClause,
							$author$project$Forms$ClauseForms$makeDatabaseClauseForm(allConnections)));
				case 2:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{da: newValue});
								}),
							mY: function ($) {
								return $.da;
							}
						},
						A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Clauses$HttpClause,
							$author$project$Forms$ClauseForms$makeHttpClauseForm(allConnections)));
				case 3:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{dZ: newValue});
								}),
							mY: function ($) {
								return $.dZ;
							}
						},
						A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Clauses$PythonClause,
							$author$project$Forms$ClauseForms$makePythonClauseForm(config)));
				case 4:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{cE: newValue});
								}),
							mY: function ($) {
								return $.cE;
							}
						},
						A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Clauses$CommandClause,
							$author$project$Forms$ClauseForms$makeCommandClauseForm(allCommands)));
				case 5:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{dk: newValue});
								}),
							mY: function ($) {
								return $.dk;
							}
						},
						A2($author$project$Lib$Form$Form$map, $author$project$Entity$Clauses$KotlinClause, $author$project$Forms$ClauseForms$kotlinClauseForm));
				default:
					return A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{d8: newValue});
								}),
							mY: function ($) {
								return $.d8;
							}
						},
						A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Clauses$SequenceClause,
							$author$project$Forms$ClauseForms$makeSequenceClauseForm(config)));
			}
		},
		$author$project$Forms$ClauseForms$clauseTypeSelectField);
};
var $author$project$Forms$ClauseForms$makeSequenceClauseForm = function (config) {
	var separatePreviousClauseResultField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Erottele edellisen lauseen tulos parametreiksi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{l2: value});
				}),
			mY: function ($) {
				return $.l2;
			}
		});
	var resultParamNameField = $author$project$Lib$Form$Form$nullableTextField(
		{
			kv: 'Tulosparametri',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lM: value});
				}),
			mY: function ($) {
				return $.lM;
			}
		});
	var clauseForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{be: value});
				}),
			mY: function (values) {
				var _v1 = values.be;
				var clauseValues = _v1;
				return clauseValues;
			}
		},
		$author$project$Forms$ClauseForms$makeClauseFormWithoutTitle(config));
	var unitField = A2(
		$author$project$Lib$Form$Form$append,
		resultParamNameField,
		A2(
			$author$project$Lib$Form$Form$append,
			separatePreviousClauseResultField,
			A2(
				$author$project$Lib$Form$Form$append,
				clauseForm,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$SequenceClauseUnit))));
	var unitFieldList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {
				be: $author$project$Forms$ClauseForms$createClauseValues($author$project$Entity$Clauses$createNewClause),
				lM: $elm$core$Maybe$Nothing,
				l2: false
			},
			kv: 'Lauseet',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mN: value});
				}),
			mY: function ($) {
				return $.mN;
			}
		},
		function (_v0) {
			return unitField;
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		unitFieldList,
		$author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$SequenceClauseData));
};
var $author$project$Lib$Form$Form$section = F2(
	function (title, form) {
		return $author$project$Lib$Form$Base$Base$custom(
			function (values) {
				var _v0 = A2($author$project$Lib$Form$Base$Base$fill, form, values);
				var fields = _v0.c;
				var result = _v0.d;
				var isEmpty = _v0.b;
				return {
					gj: A2($author$project$Lib$Form$Form$Section, title, fields),
					b: isEmpty,
					d: result
				};
			});
	});
var $author$project$Forms$ClauseForms$makeClauseForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$section,
		config.mC,
		$author$project$Forms$ClauseForms$makeClauseFormWithoutTitle(config));
};
var $author$project$Forms$ClauseForms$makeOptionalClauseForm = F2(
	function (checkBoxTitle, clauseFormConfig_) {
		return A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			checkBoxTitle,
			$author$project$Forms$ClauseForms$makeClauseForm(clauseFormConfig_));
	});
var $author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm = function (_v0) {
	var allCommands = _v0.fU;
	var allConnections = _v0.eA;
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hx: value});
				}),
			mY: function ($) {
				return $.hx;
			}
		},
		A2(
			$author$project$Forms$ClauseForms$makeOptionalClauseForm,
			'Määritä jatkotoimintojen lause',
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Jatkotoimintojen lause', allConnections, allCommands)));
};
var $author$project$Forms$CommandParameterForm$ResolveParamsOnFrontend = 2;
var $author$project$Lib$Form$Form$meta = $author$project$Lib$Form$Base$Base$meta;
var $author$project$Forms$ParameterClauseForm$makeParameterClauseForm = function (config) {
	var selectTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('separated', 'Eroteltu'),
						_Utils_Tuple2('named', 'Nimetty parametri')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	var parameterNameForm = $author$project$Lib$Form$Form$meta(
		function (_v1) {
			var typeStr = _v1.a4;
			if (typeStr === 'named') {
				return $author$project$Lib$Form$Form$simpleTextField(
					{
						kv: 'Tulosparametrin nimi',
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{lj: value});
							}),
						mY: function ($) {
							return $.lj;
						}
					});
			} else {
				return $author$project$Lib$Form$Form$succeed('');
			}
		});
	var mapper = F3(
		function (typeStr, clause, parameterName) {
			if (typeStr === 'named') {
				return $author$project$Entity$ParameterClause$NamedParameterClause(
					{eH: clause, lj: parameterName});
			} else {
				return $author$project$Entity$ParameterClause$SeparatedParameterClause(
					{eH: clause});
			}
		});
	var clauseForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{be: value});
				}),
			mY: function ($) {
				return $.be;
			}
		},
		$author$project$Forms$ClauseForms$makeClauseForm(
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Lause', config.eA, config.fU)));
	return A2(
		$author$project$Lib$Form$Form$append,
		parameterNameForm,
		A2(
			$author$project$Lib$Form$Form$append,
			clauseForm,
			A2(
				$author$project$Lib$Form$Form$append,
				selectTypeField,
				$author$project$Lib$Form$Form$succeed(mapper))));
};
var $author$project$Forms$ParameterClauseForm$makeParameterClauseFormList = F2(
	function (_v0, commandFormConfig) {
		var value = _v0.mY;
		var update = _v0.a;
		var label = _v0.kv;
		return A2(
			$author$project$Lib$Form$Form$simpleList,
			{
				f7: $author$project$Forms$ParameterClauseForm$createParameterClauseFormValues(
					$author$project$Entity$ParameterClause$SeparatedParameterClause(
						{eH: $author$project$Entity$Clauses$createNewClause})),
				kv: label,
				a: update,
				mY: value
			},
			function (_v1) {
				return $author$project$Forms$ParameterClauseForm$makeParameterClauseForm(commandFormConfig);
			});
	});
var $author$project$Forms$ViewComponentAndActionForms$actionParameterClausesFieldList = $author$project$Forms$ParameterClauseForm$makeParameterClauseFormList(
	{
		kv: 'Parametrien lauseet',
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{dJ: value});
			}),
		mY: function ($) {
			return $.dJ;
		}
	});
var $author$project$Forms$PredicateForm$operandField = A2(
	$author$project$Lib$Form$Form$map,
	$elm$core$Maybe$map($author$project$Serializable$parse),
	$author$project$Lib$Form$Form$nullableTextField(
		{
			kv: 'Ehdon operandi (tyhjä => NULL) {{F}}',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{k9: value});
				}),
			mY: function ($) {
				return $.k9;
			}
		}));
var $author$project$Forms$PredicateForm$parameterValuePredicateForm = function () {
	var paramNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Ehtoparametrin nimi {{F}}',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lj: value});
				}),
			mY: function ($) {
				return $.lj;
			}
		});
	var operatorField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Ehdon operaattori',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('Aina', 'Aina tosi'),
						_Utils_Tuple2('Never', 'Ei koskaan tosi'),
						_Utils_Tuple2('Exists', 'On olemassa'),
						_Utils_Tuple2('NotExists', 'Ei ole olemassa'),
						_Utils_Tuple2('Equals', 'On sama kuin'),
						_Utils_Tuple2('NotEquals', 'On eri kuin'),
						_Utils_Tuple2('IsTruthy', 'On tosi'),
						_Utils_Tuple2('IsFalsy', 'On epätosi'),
						_Utils_Tuple2('NumberLessThan', 'On pienempi kuin'),
						_Utils_Tuple2('NumberGreaterThan', 'On suurempi kuin'),
						_Utils_Tuple2('NumberLessThanOrEquals', 'On pienempi tai yhtä suuri kuin'),
						_Utils_Tuple2('NumberGreaterThanOrEquals', 'On suurempi tai yhtä suuri kuin'),
						_Utils_Tuple2('StringIsEmpty', 'On tyhjä merkkijonon'),
						_Utils_Tuple2('StringIsBlank', 'Sisältää vain tyhjiä merkkejä'),
						_Utils_Tuple2('StringNotIsEmpty', 'Ei ole tyhjä merkkijonon'),
						_Utils_Tuple2('StringNotIsBlank', 'Ei sisällä vain tyhjiä merkkejä'),
						_Utils_Tuple2('StringContains', 'Sisältää merkkijonon'),
						_Utils_Tuple2('StringStartsWith', 'Alkaa merkkijonolla'),
						_Utils_Tuple2('StringEndWith', 'Loppuu merkkijonoon'),
						_Utils_Tuple2('StringRegex', 'Regex'),
						_Utils_Tuple2('ListContains', 'Lista sisältää'),
						_Utils_Tuple2('ListNotContains', 'Lista ei sisällä')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parsePredicateOperator,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{la: value});
				}),
			mY: function ($) {
				return $.la;
			}
		});
	var listOperandFields = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {k9: $elm$core$Maybe$Nothing},
			kv: 'Listaoperandit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kA: value});
				}),
			mY: function ($) {
				return $.kA;
			}
		},
		$elm$core$Basics$always($author$project$Forms$PredicateForm$operandField));
	return A2(
		$author$project$Lib$Form$Form$append,
		listOperandFields,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$PredicateForm$operandField,
			A2(
				$author$project$Lib$Form$Form$append,
				operatorField,
				A2(
					$author$project$Lib$Form$Form$append,
					paramNameField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Predicate$TParameterValuePredicate)))));
}();
var $author$project$Lib$Form$Base$NumberField$form = $author$project$Lib$Form$Base$Base$field(
	{b: $elm$core$String$isEmpty});
var $author$project$Lib$Form$Form$numberField = $author$project$Lib$Form$Base$NumberField$form($author$project$Lib$Form$Form$Number);
var $author$project$Lib$Form$Form$simpleIntegerField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	return $author$project$Lib$Form$Form$numberField(
		{
			ba: {kv: label, kI: $elm$core$Maybe$Nothing, kU: $elm$core$Maybe$Nothing, lm: '', mn: $elm$core$Maybe$Nothing},
			U: A2(
				$elm$core$Basics$composeR,
				$elm$core$String$toInt,
				$elm$core$Result$fromMaybe('Ei kelvollinen')),
			a: update,
			mY: value
		});
};
var $author$project$Forms$PredicateForm$screenSizePredicateForm = function () {
	var minWidthField = $author$project$Lib$Form$Form$simpleIntegerField(
		{
			kv: 'Minimileveys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kV: value});
				}),
			mY: function ($) {
				return $.kV;
			}
		});
	var maxWidthField = $author$project$Lib$Form$Form$simpleIntegerField(
		{
			kv: 'Maksimileveys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kL: value});
				}),
			mY: function ($) {
				return $.kL;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		maxWidthField,
		A2(
			$author$project$Lib$Form$Form$append,
			minWidthField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Predicate$TScreenSizePredicate)));
}();
var $author$project$Forms$PredicateForm$predicateForm = function () {
	var typeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Ehdon tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('parameterValue', 'Parametrin arvo'),
						_Utils_Tuple2('screenSize', 'Näytön koko'),
						_Utils_Tuple2('always', 'Aina tosi')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$section,
		'Ehto',
		A2(
			$author$project$Lib$Form$Form$andThen,
			function (typeStr) {
				switch (typeStr) {
					case 'parameterValue':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Predicate$ParameterValuePredicate,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{bT: value});
										}),
									mY: function ($) {
										return $.bT;
									}
								},
								$author$project$Forms$PredicateForm$parameterValuePredicateForm));
					case 'screenSize':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$Predicate$ScreenSizePredicate,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{bY: value});
										}),
									mY: function ($) {
										return $.bY;
									}
								},
								$author$project$Forms$PredicateForm$screenSizePredicateForm));
					default:
						return $author$project$Lib$Form$Form$succeed($author$project$Entity$Predicate$AlwaysPredicate);
				}
			},
			typeField));
}();
var $author$project$Forms$ViewComponentAndActionForms$actionPredicateForm = A2(
	$author$project$Lib$Form$Form$mapValues,
	{
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{m: value});
			}),
		mY: function ($) {
			return $.m;
		}
	},
	$author$project$Forms$PredicateForm$predicateForm);
var $author$project$Entity$ActionAndViewComponent$createNewContainerViewComponent = {ba: _List_Nil, ia: _List_Nil, f$: $elm$core$Maybe$Nothing, cG: _List_Nil, f4: _List_Nil, bm: '', ga: $elm$core$Maybe$Nothing, cV: $elm$core$Maybe$Nothing};
var $author$project$Entity$ActionAndViewComponent$createNewViewComponent = $author$project$Entity$ActionAndViewComponent$InputViewComponent($author$project$Entity$ActionAndViewComponent$createNewInputViewComponent);
var $author$project$Entity$ActionAndViewComponent$createNewGeneratorViewComponent = {ba: _List_Nil, je: _List_Nil, jR: $author$project$Entity$Clauses$createNewClause, mv: $author$project$Entity$ActionAndViewComponent$createNewViewComponent};
var $author$project$Lib$CodeMirror$CSS = 2;
var $author$project$Forms$ViewComponentAndActionForms$cssCodeField = $author$project$Lib$Form$Form$allowEmptyString(
	$author$project$Lib$Form$Form$simpleCodeField(
		{
			kv: 'CSS-koodi',
			aP: 2,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{bm: value});
				}),
			mY: function ($) {
				return $.bm;
			}
		}));
var $author$project$Forms$ViewComponentAndActionForms$makeCommandExecutionActionForm = function (config) {
	var allCommands = config.fU;
	var requestMoreInputField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Kysy lisäparametreja',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lH: value});
				}),
			mY: function ($) {
				return $.lH;
			}
		});
	var displayResultField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Näytä tulos käyttäjälle',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i2: value});
				}),
			mY: function ($) {
				return $.i2;
			}
		});
	var commandCallForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ik: value});
				}),
			mY: function ($) {
				return $.ik;
			}
		},
		A2($author$project$Forms$CommandCallDefinitionForm$makeCommandCallDefinitionForm, 2, allCommands));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$actionPredicateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$actionParameterClausesFieldList(config),
			A2(
				$author$project$Lib$Form$Form$append,
				displayResultField,
				A2(
					$author$project$Lib$Form$Form$append,
					requestMoreInputField,
					A2(
						$author$project$Lib$Form$Form$append,
						commandCallForm,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TCommandExecutionAction))))));
};
var $author$project$Forms$InputFieldForms$makeInputFieldTypeDefinitionForm = function (_v0) {
	var allConnections = _v0.eA;
	var allCommands = _v0.fU;
	var visibleIfSelectionField = F2(
		function (_default, inner) {
			return $author$project$Lib$Form$Form$meta(
				function (_v2) {
					var typeStr = _v2.a4;
					if (typeStr === 'selection') {
						return inner;
					} else {
						return $author$project$Lib$Form$Form$succeed(_default);
					}
				});
		});
	var typeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('constant', 'Vakio'),
						_Utils_Tuple2('text', 'Teksti'),
						_Utils_Tuple2('textArea', 'Iso tekstikenttä'),
						_Utils_Tuple2('integer', 'Kokonaisluku'),
						_Utils_Tuple2('float', 'Liukuluku'),
						_Utils_Tuple2('date', 'Päivämäärä'),
						_Utils_Tuple2('time', 'Kellonaika'),
						_Utils_Tuple2('dateTime', 'Päivämäärä kellonajalla'),
						_Utils_Tuple2('checkbox', 'Kyllä/ei -valinta'),
						_Utils_Tuple2('barcode', 'Viivakoodi kameralla'),
						_Utils_Tuple2('file', 'Tiedosto'),
						_Utils_Tuple2('selection', 'Valinta')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	var selectionFieldSelectFirstValueByDefault = A2(
		visibleIfSelectionField,
		false,
		$author$project$Lib$Form$Form$simpleCheckboxField(
			{
				kv: 'Valitse ensimmäinen vaihtoehto oletuksena',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{a1: value});
					}),
				mY: function ($) {
					return $.a1;
				}
			}));
	var selectionFieldPlaceholderField = A2(
		visibleIfSelectionField,
		'Valitse...',
		$author$project$Lib$Form$Form$allowEmptyString(
			$author$project$Lib$Form$Form$simpleTextField(
				{
					kv: 'Placeholder',
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{a0: value});
						}),
					mY: function ($) {
						return $.a0;
					}
				})));
	var selectionFieldOptionsClauseField = A2(
		visibleIfSelectionField,
		$author$project$Entity$Clauses$createNewClause,
		A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values_) {
						return _Utils_update(
							values_,
							{a$: value});
					}),
				mY: function ($) {
					return $.a$;
				}
			},
			$author$project$Forms$ClauseForms$makeClauseForm(
				A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Vaihtoehtolause', allConnections, allCommands))));
	var selectionFieldNullValueEnabledField = A2(
		visibleIfSelectionField,
		false,
		$author$project$Lib$Form$Form$simpleCheckboxField(
			{
				kv: 'Tyhjä vaihtoehto',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{a_: value});
					}),
				mY: function ($) {
					return $.a_;
				}
			}));
	var selectionFieldIsLazyField = A2(
		visibleIfSelectionField,
		false,
		$author$project$Lib$Form$Form$simpleCheckboxField(
			{
				kv: 'Viivästetty vaihtoehtojen haku',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{aZ: value});
					}),
				mY: function ($) {
					return $.aZ;
				}
			}));
	var mapToResult = F8(
		function (typeStr, selectionFieldIsLazy, selectionFieldNullValueEnabled, selectionFieldPlaceholder, selectionFieldOptionsClause, selectionSelectFirstValueByDefault, addOffsetToTemporalTypes, allowMultipleFiles) {
			var simpleField = function (fieldType) {
				return $author$project$Entity$InputFieldDefinition$SimpleField(
					{hE: addOffsetToTemporalTypes, jF: fieldType});
			};
			switch (typeStr) {
				case 'text':
					return simpleField(1);
				case 'textArea':
					return simpleField(2);
				case 'integer':
					return simpleField(3);
				case 'float':
					return simpleField(4);
				case 'date':
					return simpleField(5);
				case 'time':
					return simpleField(6);
				case 'dateTime':
					return simpleField(7);
				case 'checkbox':
					return simpleField(8);
				case 'barcode':
					return simpleField(9);
				case 'selection':
					return $author$project$Entity$InputFieldDefinition$SelectionField(
						{kq: selectionFieldIsLazy, k1: selectionFieldNullValueEnabled, lb: selectionFieldOptionsClause, lm: selectionFieldPlaceholder, lZ: selectionSelectFirstValueByDefault});
				case 'file':
					return $author$project$Entity$InputFieldDefinition$FileInputField(
						{hJ: allowMultipleFiles});
				default:
					return simpleField(0);
			}
		});
	var fileInputFieldAllowMultipleFiles = $author$project$Lib$Form$Form$meta(
		function (values) {
			return A2(
				$elm$core$List$member,
				values.a4,
				_List_fromArray(
					['file'])) ? $author$project$Lib$Form$Form$simpleCheckboxField(
				{
					kv: 'Salli useiden tiedostojen valinta',
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{aI: value});
						}),
					mY: function ($) {
						return $.aI;
					}
				}) : $author$project$Lib$Form$Form$succeed(false);
		});
	var addOffsetToTemporalTypesField = $author$project$Lib$Form$Form$meta(
		function (values) {
			return A2(
				$elm$core$List$member,
				values.a4,
				_List_fromArray(
					['time', 'dateTime'])) ? $author$project$Lib$Form$Form$simpleCheckboxField(
				{
					kv: 'Välitä aikavyöhyketieto',
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{hE: value});
						}),
					mY: function ($) {
						return $.hE;
					}
				}) : $author$project$Lib$Form$Form$succeed(false);
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		fileInputFieldAllowMultipleFiles,
		A2(
			$author$project$Lib$Form$Form$append,
			addOffsetToTemporalTypesField,
			A2(
				$author$project$Lib$Form$Form$append,
				selectionFieldSelectFirstValueByDefault,
				A2(
					$author$project$Lib$Form$Form$append,
					selectionFieldOptionsClauseField,
					A2(
						$author$project$Lib$Form$Form$append,
						selectionFieldPlaceholderField,
						A2(
							$author$project$Lib$Form$Form$append,
							selectionFieldNullValueEnabledField,
							A2(
								$author$project$Lib$Form$Form$append,
								selectionFieldIsLazyField,
								A2(
									$author$project$Lib$Form$Form$append,
									typeField,
									$author$project$Lib$Form$Form$succeed(mapToResult)))))))));
};
var $author$project$Forms$InputFieldForms$makeInputFieldDefinitionForm = function (config) {
	var labelField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Otsikko {{F}}',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kv: value});
				}),
			mY: function ($) {
				return $.kv;
			}
		});
	var isRequiredField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Pakollinen',
			a: F2(
				function (value, values_) {
					return _Utils_update(
						values_,
						{lI: value});
				}),
			mY: function ($) {
				return $.lI;
			}
		});
	var identifierField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Tunniste {{F}}',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kb: value});
				}),
			mY: function ($) {
				return $.kb;
			}
		});
	var defaultValueField = A2(
		$author$project$Lib$Form$Form$map,
		$elm$core$Maybe$map($author$project$Serializable$parse),
		$author$project$Lib$Form$Form$nullableTextField(
			{
				kv: 'Oletusarvo {{B}}',
				a: F2(
					function (value, values_) {
						return _Utils_update(
							values_,
							{iV: value});
					}),
				mY: function ($) {
					return $.iV;
				}
			}));
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{eo: value});
					}),
				mY: function ($) {
					return $.eo;
				}
			},
			$author$project$Forms$InputFieldForms$makeInputFieldTypeDefinitionForm(config)),
		A2(
			$author$project$Lib$Form$Form$append,
			isRequiredField,
			A2(
				$author$project$Lib$Form$Form$append,
				defaultValueField,
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Lib$Form$Form$allowEmptyString(labelField),
					A2(
						$author$project$Lib$Form$Form$append,
						identifierField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$InputFieldDefinition$InputFieldDefinition))))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeOpenUrlActionForm = function () {
	var urlField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'URL {{F}}',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mO: value});
				}),
			mY: function ($) {
				return $.mO;
			}
		});
	var targetNameField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Target',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{mu: value});
					}),
				mY: function ($) {
					return $.mu;
				}
			}));
	var specsField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Specs',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{mj: value});
					}),
				mY: function ($) {
					return $.mj;
				}
			}));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$actionPredicateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandParameterForm$commandParameterFormList(2),
			A2(
				$author$project$Lib$Form$Form$append,
				specsField,
				A2(
					$author$project$Lib$Form$Form$append,
					targetNameField,
					A2(
						$author$project$Lib$Form$Form$append,
						urlField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TOpenUrlAction))))));
}();
var $author$project$Lib$CodeMirror$JavaScript = 5;
var $author$project$Forms$ViewComponentAndActionForms$makeScriptActionForm = function (config) {
	var scriptField = $author$project$Lib$Form$Form$simpleCodeField(
		{
			kv: 'Koodi',
			aP: 5,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lW: value});
				}),
			mY: function ($) {
				return $.lW;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$actionPredicateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$actionParameterClausesFieldList(config),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommandParameterForm$commandParameterFormList(2),
				A2(
					$author$project$Lib$Form$Form$append,
					scriptField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TScriptAction)))));
};
var $author$project$Forms$ViewComponentAndActionForms$getViewActionSpecifierOptions = function (_v0) {
	var target = _v0.ms;
	if (target === 'global') {
		return _List_fromArray(
			[
				_Utils_Tuple2('GLOBAL|SHOW_TOAST', 'Globaali: Näytä toast (__MSG_TITLE__, __MSG_CONTENT__)'),
				_Utils_Tuple2('GLOBAL|CLOSE_UPPERMOST_FLOATING_VIEW', 'Globaali: Sulje päällimäisin kelluva näkymä')
			]);
	} else {
		return _List_fromArray(
			[
				_Utils_Tuple2('VIEW_COMMON|SET_VIEW_PARAMS', 'Yleinen: Aseta kaikki näkymän parametrit ja päivitä'),
				_Utils_Tuple2('VIEW_COMMON|REFRESH', 'Yleinen: Päivitä'),
				_Utils_Tuple2('VIEW_COMMON|SILENT_REFRESH', 'Yleinen: Päivitä hiljaisesti'),
				_Utils_Tuple2('VIEW_COMMON|OPEN_FLOATING_VIEW', 'Yleinen: Avaa näkymä kelluvana'),
				_Utils_Tuple2('VIEW_COMMON|RESET_STATE_PARAMS', 'Yleinen: Alusta tilaparametrit'),
				_Utils_Tuple2('VIEW_COMMON|INSERT_STATE_PARAMS', 'Yleinen: Aseta yksittäisiä tilaparametreja'),
				_Utils_Tuple2('VIEW_COMMON|FOCUS_TO_ELEMENT_TO_FOCUS', 'Yleinen: Fokusoi fokusoitavaan elementtiin'),
				_Utils_Tuple2('DATA_VIEW|SAVE_EDITED_ROWS', 'Datanäkymä: Tallenna rivit'),
				_Utils_Tuple2('DATA_VIEW|ADD_NEW_ROW', 'Datanäkymä: Lisää rivi'),
				_Utils_Tuple2('DATA_VIEW|ADD_NEW_ROW_WITH_FORM', 'Datanäkymä: Lisää rivi lomakkeella'),
				_Utils_Tuple2('DATA_VIEW|DELETE_ROW', 'Datanäkymä: Poista valittu rivi'),
				_Utils_Tuple2('CONTAINER_VIEW|UPDATE_LAYOUT', 'Säiliönäkymä: Päivitä layout'),
				_Utils_Tuple2('DIALOG|CLOSE', 'Dialogi: Sulje')
			]);
	}
};
var $author$project$Entity$View$getLongViewDescription = function (view) {
	var appendIfNotEmpty = function (str) {
		return (str !== '') ? (' | ' + str) : '';
	};
	return $elm$core$String$fromInt(view.db) + (' | ' + ($author$project$Entity$View$getViewSpecAsString(view) + (' | ' + (view.k_ + (appendIfNotEmpty(view.i1) + appendIfNotEmpty(
		$author$project$Utils$StringUtils$firstLine(view.i$)))))));
};
var $author$project$Entity$View$viewTypeComparator = F2(
	function (a, b) {
		var getposber = function (view) {
			var _v0 = view.mh;
			switch (_v0.$) {
				case 0:
					return 1;
				case 1:
					return 2;
				case 2:
					return 3;
				case 3:
					return 4;
				case 4:
					return 5;
				case 5:
					var type_ = _v0.a.mM;
					switch (type_) {
						case 0:
							return 6;
						case 1:
							return 7;
						case 2:
							return 8;
						case 3:
							return 9;
						case 4:
							return 10;
						case 5:
							return 11;
						case 6:
							return 12;
						case 7:
							return 13;
						default:
							return 14;
					}
				default:
					return 15;
			}
		};
		return A2(
			$elm$core$Basics$compare,
			getposber(a),
			getposber(b));
	});
var $author$project$Forms$ViewComponentAndActionForms$selectViewActionTargetForm = function (_v0) {
	var allViews = _v0.hG;
	var viewOptions = A2(
		$elm$core$List$map,
		function (_v2) {
			var viewId = _v2.a;
			var view = _v2.b;
			return _Utils_Tuple2(
				$elm$core$String$fromInt(viewId),
				$author$project$Entity$View$getLongViewDescription(view));
		},
		A2(
			$author$project$Utils$ListUtils$sortByValues,
			$author$project$Entity$View$viewTypeComparator,
			$elm$core$Dict$toList(allViews)));
	var allOptions = A2(
		$elm$core$List$cons,
		_Utils_Tuple2('global', 'Globaali'),
		A2(
			$elm$core$List$cons,
			_Utils_Tuple2('self', 'Tämä näkymä'),
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2('sourceView', 'Lähdenäkymä'),
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('allSiblingsAndSelf', 'Tämä näkymä ja kaikki sisarnäkymät'),
					viewOptions))));
	return $author$project$Lib$Form$Form$selectField(
		{
			ba: {kv: 'Kohde', aT: allOptions, lm: $elm$core$Maybe$Nothing},
			U: function (str) {
				switch (str) {
					case 'global':
						return $elm$core$Result$Ok($author$project$Entity$ActionAndViewComponent$GlobalTarget);
					case 'self':
						return $elm$core$Result$Ok($author$project$Entity$ActionAndViewComponent$SelfTarget);
					case 'sourceView':
						return $elm$core$Result$Ok($author$project$Entity$ActionAndViewComponent$SourceViewTarget);
					case 'allSiblingsAndSelf':
						return $elm$core$Result$Ok($author$project$Entity$ActionAndViewComponent$AllSiblingsAndSelfTarget);
					default:
						return A2(
							$elm$core$Result$fromMaybe,
							'Virhe',
							A2(
								$elm$core$Maybe$map,
								$author$project$Entity$ActionAndViewComponent$ViewTarget,
								$elm$core$String$toInt(str)));
				}
			},
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ms: value});
				}),
			mY: function ($) {
				return $.ms;
			}
		});
};
var $author$project$Forms$ViewComponentAndActionForms$makeViewActionForm = function (config) {
	var actionSpecifierField = $author$project$Lib$Form$Form$meta(
		function (upperValues) {
			return $author$project$Lib$Form$Form$selectField(
				{
					ba: {
						kv: 'Toiminto',
						aT: $author$project$Forms$ViewComponentAndActionForms$getViewActionSpecifierOptions(upperValues),
						lm: $elm$core$Maybe$Just('Valitse')
					},
					U: $elm$core$Result$Ok,
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{hy: value});
						}),
					mY: function ($) {
						return $.hy;
					}
				});
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$actionPredicateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$actionParameterClausesFieldList(config),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommandParameterForm$commandParameterFormList(2),
				A2(
					$author$project$Lib$Form$Form$append,
					actionSpecifierField,
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$ViewComponentAndActionForms$selectViewActionTargetForm(config),
						$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TViewAction))))));
};
var $author$project$Forms$ViewComponentAndActionForms$getComponentAttributeIdOptions = function (componentType) {
	var typeSpecificOptions = function () {
		switch (componentType) {
			case 'button':
				return _List_fromArray(
					[
						_Utils_Tuple2('BUTTON|DISABLED', 'Painike | Ei käytössä')
					]);
			case 'input':
				return _List_fromArray(
					[
						_Utils_Tuple2('INPUT|READ_ONLY', 'Syöttökenttä | Ei muokattavissa')
					]);
			default:
				return _List_Nil;
		}
	}();
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2('COMMON|HIDDEN', 'Yleinen | Piilotettu'),
				_Utils_Tuple2('COMMON|DISABLED', 'Yleinen | Ei käytössä'),
				_Utils_Tuple2('COMMON|CSS_CLASS', 'Yleinen | CSS-luokka'),
				_Utils_Tuple2('COMMON|CSS_STYLE', 'Yleinen | CSS-tyyli')
			]),
		typeSpecificOptions);
};
var $author$project$Forms$ViewComponentAndActionForms$getComponentAttributeValueOptions = function (attributeId) {
	var booleanAttributeValues = _List_fromArray(
		[
			_Utils_Tuple2('true', 'true'),
			_Utils_Tuple2('false', 'false')
		]);
	switch (attributeId) {
		case 'COMMON|HIDDEN':
			return booleanAttributeValues;
		case 'COMMON|DISABLED':
			return booleanAttributeValues;
		case 'BUTTON|DISABLED':
			return booleanAttributeValues;
		case 'INPUT|READ_ONLY':
			return booleanAttributeValues;
		default:
			return _List_Nil;
	}
};
var $author$project$Forms$ViewComponentAndActionForms$getComponentAttributeValueField = function (attributeId) {
	var isSelectField = A2(
		$elm$core$List$member,
		attributeId,
		_List_fromArray(
			['COMMON|HIDDEN', 'COMMON|DISABLED', 'BUTTON|DISABLED', 'INPUT|READ_ONLY']));
	return A2(
		$author$project$Lib$Form$Form$map,
		$author$project$Serializable$parse,
		isSelectField ? $author$project$Lib$Form$Form$selectField(
			{
				ba: {
					kv: 'Attribuutin arvo {{F}}',
					aT: $author$project$Forms$ViewComponentAndActionForms$getComponentAttributeValueOptions(attributeId),
					lm: $elm$core$Maybe$Just('Valitse')
				},
				U: $elm$core$Result$Ok,
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{ae: value});
					}),
				mY: function ($) {
					return $.ae;
				}
			}) : $author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Attribuutin arvo {{F}}',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{ae: value});
					}),
				mY: function ($) {
					return $.ae;
				}
			}));
};
var $author$project$Forms$ViewComponentAndActionForms$customViewComponentAttributeForm = function (componentType) {
	var customPredicateForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{bn: value});
				}),
			mY: function ($) {
				return $.bn;
			}
		},
		$author$project$Forms$PredicateForm$predicateForm);
	var attributeValueField = $author$project$Lib$Form$Form$meta(
		A2(
			$elm$core$Basics$composeL,
			$author$project$Forms$ViewComponentAndActionForms$getComponentAttributeValueField,
			function ($) {
				return $.aF;
			}));
	var attributeIdField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Attribuutin ID',
				aT: $author$project$Forms$ViewComponentAndActionForms$getComponentAttributeIdOptions(componentType),
				lm: $elm$core$Maybe$Just('Valitse')
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{aF: value});
				}),
			mY: function ($) {
				return $.aF;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$map,
		$author$project$Entity$ActionAndViewComponent$CustomAttribute,
		A2(
			$author$project$Lib$Form$Form$append,
			customPredicateForm,
			A2(
				$author$project$Lib$Form$Form$append,
				attributeValueField,
				A2(
					$author$project$Lib$Form$Form$append,
					attributeIdField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$CustomViewComponentAttribute)))));
};
var $author$project$Forms$ViewComponentAndActionForms$getAttributePredefinedScenarioOptions = function (componentType) {
	var typeSpecificOptions = function () {
		switch (componentType) {
			case 'button':
				return _List_fromArray(
					[
						_Utils_Tuple2('EnabledOnlyIfSelections', 'Käytössä, kun rivejä valittuna'),
						_Utils_Tuple2('EnabledOnlyIfSingleItemSelected', 'Käytössä, kun täsmälleen yksi rivi valittuna'),
						_Utils_Tuple2('PrimaryButton', 'Bootstrap btn-primary'),
						_Utils_Tuple2('AutoFocus', 'Fokusoitava elementti')
					]);
			case 'input':
				return _List_fromArray(
					[
						_Utils_Tuple2('AutoFocus', 'Fokusoitava elementti'),
						_Utils_Tuple2('EditableForNewRecordOnly', 'Muokattavissa vain uudelle tietueelle')
					]);
			default:
				return _List_Nil;
		}
	}();
	return typeSpecificOptions;
};
var $author$project$Forms$ViewComponentAndActionForms$predefinedViewComponentAttributeForm = function (componentType) {
	var scenarioField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Skenaario',
				aT: $author$project$Forms$ViewComponentAndActionForms$getAttributePredefinedScenarioOptions(componentType),
				lm: $elm$core$Maybe$Just('Valitse')
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{bU: value});
				}),
			mY: function ($) {
				return $.bU;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		scenarioField,
		$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$PredefinedAttribute));
};
var $author$project$Forms$ViewComponentAndActionForms$viewComponentAttributesFormList = function (componentType) {
	var selectTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Määrittelytapa',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('predefined', 'Yleinen skenaario'),
						_Utils_Tuple2('custom', 'Kustomoitu')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	var attributeForm = A2(
		$author$project$Lib$Form$Form$andThen,
		function (typeStr) {
			if (typeStr === 'predefined') {
				return $author$project$Forms$ViewComponentAndActionForms$predefinedViewComponentAttributeForm(componentType);
			} else {
				return $author$project$Forms$ViewComponentAndActionForms$customViewComponentAttributeForm(componentType);
			}
		},
		selectTypeField);
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: $author$project$Forms$ViewComponentAndActionForms$createViewComponentAttributeValues(
				$author$project$Entity$ActionAndViewComponent$PredefinedAttribute('')),
			kv: 'Attribuutit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ba: value});
				}),
			mY: function ($) {
				return $.ba;
			}
		},
		function (_v0) {
			return attributeForm;
		});
};
var $author$project$Forms$ViewComponentAndActionForms$componentClickActionField = function (config) {
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f$: value});
				}),
			mY: function ($) {
				return $.f$;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Määritä klikkauksen toiminto',
			A2(
				$author$project$Lib$Form$Form$section,
				'Klikkauksen toiminto',
				$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config))));
};
var $author$project$Forms$ViewComponentAndActionForms$componentDoubleClickActionField = function (config) {
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ga: value});
				}),
			mY: function ($) {
				return $.ga;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Määritä tuplaklikkauksen toiminto',
			A2(
				$author$project$Lib$Form$Form$section,
				'Tuplaklikkauksen toiminto',
				$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config))));
};
var $author$project$Forms$ViewComponentAndActionForms$contextMenuComponentsFieldList = function (config) {
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f4: value});
				}),
			mY: function (values) {
				var _v14 = values.f4;
				var components = _v14;
				return components;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList(config));
};
var $author$project$Forms$ViewComponentAndActionForms$makeButtonViewComponentForm = function (config) {
	var labelField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Otsikko {{F}}',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{kv: value});
					}),
				mY: function ($) {
					return $.kv;
				}
			}));
	var iconField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Ikoni {{F}}',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{j8: value});
					}),
				mY: function ($) {
					return $.j8;
				}
			}));
	var actionField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{fO: value});
				}),
			mY: function ($) {
				return $.fO;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$cssCodeField,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigFormMapped(config),
			A2(
				$author$project$Lib$Form$Form$append,
				actionField,
				A2(
					$author$project$Lib$Form$Form$append,
					iconField,
					A2(
						$author$project$Lib$Form$Form$append,
						labelField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TButtonViewComponent))))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeContainerViewComponentForm = function (config) {
	var childComponentsFormList = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cz: value});
				}),
			mY: function (values) {
				var _v13 = values.cz;
				var childComponents = _v13;
				return childComponents;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList(config));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$cssCodeField,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigFormMapped(config),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$ViewComponentAndActionForms$contextMenuComponentsFieldList(config),
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$ViewComponentAndActionForms$componentDoubleClickActionField(config),
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$ViewComponentAndActionForms$componentClickActionField(config),
						A2(
							$author$project$Lib$Form$Form$append,
							childComponentsFormList,
							$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TContainerViewComponent)))))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeDialogActionForm = function (config) {
	var titleField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Otsikko',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mC: value});
				}),
			mY: function ($) {
				return $.mC;
			}
		});
	var modalSizeField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Ikkunan koko',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{kY: value});
					}),
				mY: function ($) {
					return $.kY;
				}
			}));
	var containerIdField = $author$project$Lib$Form$Form$optional(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Säiliön ID',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{iA: value});
					}),
				mY: function ($) {
					return $.iA;
				}
			}));
	var containerCssClassField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Säiliön CSS-luokka',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{iz: value});
					}),
				mY: function ($) {
					return $.iz;
				}
			}));
	var componentField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cH: value});
				}),
			mY: function (_v11) {
				var componentValues = _v11.cH;
				var components_ = componentValues;
				return components_;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList(config));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$actionPredicateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$actionParameterClausesFieldList(config),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommandParameterForm$commandParameterFormList(2),
				A2(
					$author$project$Lib$Form$Form$append,
					componentField,
					A2(
						$author$project$Lib$Form$Form$append,
						modalSizeField,
						A2(
							$author$project$Lib$Form$Form$append,
							$author$project$Forms$ViewComponentAndActionForms$cssCodeField,
							A2(
								$author$project$Lib$Form$Form$append,
								containerIdField,
								A2(
									$author$project$Lib$Form$Form$append,
									containerCssClassField,
									A2(
										$author$project$Lib$Form$Form$append,
										titleField,
										$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TDialogAction))))))))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigForm = function (config) {
	var dropActionForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cU: value});
				}),
			mY: function ($) {
				return $.cU;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config));
	return A2(
		$author$project$Lib$Form$Form$section,
		'Pudotuksen toiminto',
		A2(
			$author$project$Lib$Form$Form$append,
			dropActionForm,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$ElementDropZoneConfig)));
};
var $author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigFormMapped = function (config) {
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{D: value});
				}),
			mY: function ($) {
				return $.D;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Raahauksen pudotusalue',
			$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigForm(config)));
};
var $author$project$Forms$ViewComponentAndActionForms$makeGeneratorViewComponentForm = function (config) {
	var templateForm = A2(
		$author$project$Lib$Form$Form$section,
		'Mallikomponentti',
		A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{aw: value});
					}),
				mY: function (values) {
					var _v10 = values.aw;
					var templateValues = _v10;
					return templateValues;
				}
			},
			$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionForm(config)));
	var generatorClauseForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{c0: value});
				}),
			mY: function ($) {
				return $.c0;
			}
		},
		$author$project$Forms$ClauseForms$makeClauseForm(
			{fU: config.fU, eA: config.eA, f0: 4, mC: 'Generoiva lause'}));
	var dynamicTemplateForm = A2(
		$author$project$Lib$Form$Form$append,
		templateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Lib$Form$Form$simpleTextField(
				{
					kv: 'Valitsin (__TEMPLATE_KEY__)',
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{kt: value});
						}),
					mY: function ($) {
						return $.kt;
					}
				}),
			$author$project$Lib$Form$Form$succeed(
				F2(
					function (key, template) {
						return _Utils_Tuple2(key, template);
					}))));
	var dynamicTemplateFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {
				kt: '',
				aw: $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewViewComponent)
			},
			kv: 'Dynaamiset mallikomponentit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{je: value});
				}),
			mY: function ($) {
				return $.je;
			}
		},
		$elm$core$Basics$always(dynamicTemplateForm));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Lib$Form$Form$succeed(_List_Nil),
		A2(
			$author$project$Lib$Form$Form$append,
			dynamicTemplateFormList,
			A2(
				$author$project$Lib$Form$Form$append,
				templateForm,
				A2(
					$author$project$Lib$Form$Form$append,
					generatorClauseForm,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TGeneratorViewComponent)))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeInputViewComponentForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Lib$Form$Form$simpleCheckboxField(
			{
				kv: 'Fokusoi automaattisesti',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{hT: value});
					}),
				mY: function ($) {
					return $.hT;
				}
			}),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$ViewComponentAndActionForms$cssCodeField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigFormMapped(config),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (value, values) {
									return _Utils_update(
										values,
										{eg: value});
								}),
							mY: function ($) {
								return $.eg;
							}
						},
						$author$project$Forms$ViewComponentAndActionForms$makeInputViewComponentSubmitActionForm(config)),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (value, values) {
										return _Utils_update(
											values,
											{cY: value});
									}),
								mY: function ($) {
									return $.cY;
								}
							},
							$author$project$Forms$InputFieldForms$makeInputFieldDefinitionForm(config)),
						$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TInputViewComponent))))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeInputViewComponentSubmitActionForm = function (config) {
	var selectTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Syöttökentän toiminto',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('none', 'Ei mitään'),
						_Utils_Tuple2('viewDefault', 'Näkymän oletus'),
						_Utils_Tuple2('custom', 'Kustomoitu')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	var mapper = F2(
		function (typeStr, customAction) {
			switch (typeStr) {
				case 'none':
					return $author$project$Entity$ActionAndViewComponent$NoneSubmitAction;
				case 'viewDefault':
					return $author$project$Entity$ActionAndViewComponent$ViewDefaultSubmitAction;
				default:
					return $author$project$Entity$ActionAndViewComponent$CustomSubmitAction(customAction);
			}
		});
	var customActionForm = $author$project$Lib$Form$Form$meta(
		function (_v8) {
			var typeStr = _v8.a4;
			var customActionValues = _v8.ad;
			return (typeStr === 'custom') ? A2(
				$author$project$Lib$Form$Form$section,
				'Toiminto',
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{ad: value});
							}),
						mY: function ($) {
							return $.ad;
						}
					},
					$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config))) : $author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$createNewKappaAction);
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		customActionForm,
		A2(
			$author$project$Lib$Form$Form$append,
			selectTypeField,
			$author$project$Lib$Form$Form$succeed(mapper)));
};
var $author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm = function (config) {
	var selectActionTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Toiminnon tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('commandExecution', 'Komennon suoritus'),
						_Utils_Tuple2('viewAction', 'Näkymän toiminto'),
						_Utils_Tuple2('openUrl', 'Linkin avaaminen'),
						_Utils_Tuple2('dialogAction', 'Dialogitoiminto'),
						_Utils_Tuple2('scriptAction', 'JavaScript-toiminto'),
						_Utils_Tuple2('sequenceAction', 'Sarjatoiminto')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$section,
		'Toiminto',
		A2(
			$author$project$Lib$Form$Form$andThen,
			function (typeStr) {
				switch (typeStr) {
					case 'commandExecution':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$ActionAndViewComponent$CommandExecutionAction,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{bg: value});
										}),
									mY: function ($) {
										return $.bg;
									}
								},
								$author$project$Forms$ViewComponentAndActionForms$makeCommandExecutionActionForm(config)));
					case 'viewAction':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$ActionAndViewComponent$ViewAction,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{b8: value});
										}),
									mY: function ($) {
										return $.b8;
									}
								},
								$author$project$Forms$ViewComponentAndActionForms$makeViewActionForm(config)));
					case 'openUrl':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$ActionAndViewComponent$OpenUrlAction,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{bQ: value});
										}),
									mY: function ($) {
										return $.bQ;
									}
								},
								$author$project$Forms$ViewComponentAndActionForms$makeOpenUrlActionForm));
					case 'dialogAction':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$ActionAndViewComponent$DialogAction,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{bp: value});
										}),
									mY: function ($) {
										return $.bp;
									}
								},
								$author$project$Forms$ViewComponentAndActionForms$makeDialogActionForm(config)));
					case 'scriptAction':
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$ActionAndViewComponent$ScriptAction,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{bZ: value});
										}),
									mY: function ($) {
										return $.bZ;
									}
								},
								$author$project$Forms$ViewComponentAndActionForms$makeScriptActionForm(config)));
					default:
						return A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Entity$ActionAndViewComponent$SequenceAction,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{b_: value});
										}),
									mY: function ($) {
										return $.b_;
									}
								},
								$author$project$Forms$ViewComponentAndActionForms$makeSequenceActionForm(config)));
				}
			},
			selectActionTypeField));
};
var $author$project$Forms$ViewComponentAndActionForms$makeSequenceActionForm = function (config) {
	var childActionForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, _v5) {
					return value;
				}),
			mY: function (values) {
				var childValues = values;
				return childValues;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config));
	var actionsListForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues($author$project$Entity$ActionAndViewComponent$createNewKappaAction),
			kv: 'Toiminnot',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hA: value});
				}),
			mY: function ($) {
				return $.hA;
			}
		},
		function (_v4) {
			return childActionForm;
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$actionPredicateForm,
		A2(
			$author$project$Lib$Form$Form$append,
			actionsListForm,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TSequenceAction)));
};
var $author$project$Forms$ViewComponentAndActionForms$makeTextViewComponentForm = function (config) {
	var useMarkdownField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Markdown',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mR: value});
				}),
			mY: function ($) {
				return $.mR;
			}
		});
	var useHtmlField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'HTML',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mQ: value});
				}),
			mY: function ($) {
				return $.mQ;
			}
		});
	var dragEnabledField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Raahattavissa',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i4: value});
				}),
			mY: function ($) {
				return $.i4;
			}
		});
	var clauseFormConfig = {fU: config.fU, eA: config.eA, f0: 4, mC: 'Sisällön lause'};
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$cssCodeField,
		A2(
			$author$project$Lib$Form$Form$append,
			dragEnabledField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigFormMapped(config),
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$ViewComponentAndActionForms$contextMenuComponentsFieldList(config),
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$ViewComponentAndActionForms$componentDoubleClickActionField(config),
						A2(
							$author$project$Lib$Form$Form$append,
							$author$project$Forms$ViewComponentAndActionForms$componentClickActionField(config),
							A2(
								$author$project$Lib$Form$Form$append,
								useHtmlField,
								A2(
									$author$project$Lib$Form$Form$append,
									useMarkdownField,
									A2(
										$author$project$Lib$Form$Form$append,
										A2(
											$author$project$Lib$Form$Form$mapValues,
											{
												a: F2(
													function (value, values) {
														return _Utils_update(
															values,
															{cK: value});
													}),
												mY: function ($) {
													return $.cK;
												}
											},
											$author$project$Forms$ClauseForms$makeClauseForm(clauseFormConfig)),
										$author$project$Lib$Form$Form$succeed($author$project$Entity$ActionAndViewComponent$TTextViewComponent))))))))));
};
var $author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionForm = function (config) {
	var selectComponentTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('input', 'Syöttökenttä'),
						_Utils_Tuple2('button', 'Painike'),
						_Utils_Tuple2('text', 'Tekstikenttä'),
						_Utils_Tuple2('container', 'Säiliö'),
						_Utils_Tuple2('generator', 'Generaattorikomponentti')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	var componentParameterClausesFieldList = A2(
		$author$project$Forms$ParameterClauseForm$makeParameterClauseFormList,
		{
			kv: 'Parametrien lauseet',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cG: value});
				}),
			mY: function ($) {
				return $.cG;
			}
		},
		config);
	var componentAttributesForm = $author$project$Lib$Form$Form$meta(
		function (_v3) {
			var typeStr = _v3.a4;
			return $author$project$Forms$ViewComponentAndActionForms$viewComponentAttributesFormList(typeStr);
		});
	return A2(
		$author$project$Lib$Form$Form$andThen,
		function (typeStr) {
			switch (typeStr) {
				case 'button':
					return A2(
						$author$project$Lib$Form$Form$map,
						$author$project$Entity$ActionAndViewComponent$ButtonViewComponent,
						A2(
							$author$project$Lib$Form$Form$append,
							componentParameterClausesFieldList,
							A2(
								$author$project$Lib$Form$Form$append,
								componentAttributesForm,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (value, values) {
												return _Utils_update(
													values,
													{bd: value});
											}),
										mY: function ($) {
											return $.bd;
										}
									},
									$author$project$Forms$ViewComponentAndActionForms$makeButtonViewComponentForm(config)))));
				case 'input':
					return A2(
						$author$project$Lib$Form$Form$map,
						$author$project$Entity$ActionAndViewComponent$InputViewComponent,
						A2(
							$author$project$Lib$Form$Form$append,
							componentParameterClausesFieldList,
							A2(
								$author$project$Lib$Form$Form$append,
								componentAttributesForm,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (value, values) {
												return _Utils_update(
													values,
													{bE: value});
											}),
										mY: function ($) {
											return $.bE;
										}
									},
									$author$project$Forms$ViewComponentAndActionForms$makeInputViewComponentForm(config)))));
				case 'text':
					return A2(
						$author$project$Lib$Form$Form$map,
						$author$project$Entity$ActionAndViewComponent$TextViewComponent,
						A2(
							$author$project$Lib$Form$Form$append,
							componentParameterClausesFieldList,
							A2(
								$author$project$Lib$Form$Form$append,
								componentAttributesForm,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (value, values) {
												return _Utils_update(
													values,
													{b2: value});
											}),
										mY: function ($) {
											return $.b2;
										}
									},
									$author$project$Forms$ViewComponentAndActionForms$makeTextViewComponentForm(config)))));
				case 'container':
					return A2(
						$author$project$Lib$Form$Form$map,
						$author$project$Entity$ActionAndViewComponent$ContainerViewComponent,
						A2(
							$author$project$Lib$Form$Form$append,
							componentParameterClausesFieldList,
							A2(
								$author$project$Lib$Form$Form$append,
								componentAttributesForm,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (value, values) {
												return _Utils_update(
													values,
													{
														bl: $elm$core$Maybe$Just(value)
													});
											}),
										mY: A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.bl;
											},
											$elm$core$Maybe$withDefault(
												$author$project$Forms$ViewComponentAndActionForms$createContainerComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewContainerViewComponent)))
									},
									$author$project$Forms$ViewComponentAndActionForms$makeContainerViewComponentForm(config)))));
				default:
					return A2(
						$author$project$Lib$Form$Form$map,
						$author$project$Entity$ActionAndViewComponent$GeneratorViewComponent,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (value, values) {
										return _Utils_update(
											values,
											{
												by: $elm$core$Maybe$Just(value)
											});
									}),
								mY: A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.by;
									},
									$elm$core$Maybe$withDefault(
										$author$project$Forms$ViewComponentAndActionForms$createGeneratorComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewGeneratorViewComponent)))
							},
							$author$project$Forms$ViewComponentAndActionForms$makeGeneratorViewComponentForm(config)));
			}
		},
		selectComponentTypeField);
};
var $author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList = function (config) {
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: $author$project$Forms$ViewComponentAndActionForms$createViewComponentFormValues($author$project$Entity$ActionAndViewComponent$createNewViewComponent),
			kv: 'Komponentit',
			a: F2(
				function (value, _v0) {
					return value;
				}),
			mY: $elm$core$Basics$identity
		},
		function (_v1) {
			return $author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionForm(config);
		});
};
var $author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$section,
		'Jatkotoiminto',
		A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{hv: value});
					}),
				mY: function ($) {
					return $.hv;
				}
			},
			A2(
				$author$project$Forms$CommonForms$convertFormToOptional,
				'Määritä jatkotoiminto',
				$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config))));
};
var $author$project$Forms$CommandForms$CalendarViewCommandForm$makeCalendarViewCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var makeClauseFormConfig = function (title) {
		return A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands);
	};
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{eV: newValue});
							}),
						mY: function ($) {
							return $.eV;
						}
					},
					A2(
						$author$project$Forms$CommonForms$convertFormToOptional,
						'Määritä tapahtuman kontekstivalikko',
						A2(
							$author$project$Lib$Form$Form$section,
							'Tapahtuman kontekstivalikko',
							$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList(config)))),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{eU: newValue});
								}),
							mY: function ($) {
								return $.eU;
							}
						},
						A2(
							$author$project$Forms$CommonForms$convertFormToOptional,
							'Määritä tapahtuman painalluksen toiminto',
							A2(
								$author$project$Lib$Form$Form$section,
								'Tapahtuman painalluksen toiminto',
								$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config)))),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{eW: newValue});
									}),
								mY: function ($) {
									return $.eW;
								}
							},
							A2(
								$author$project$Forms$CommonForms$convertFormToOptional,
								'Muokattavat tapahtumat',
								A2(
									$author$project$Lib$Form$Form$section,
									'Tapahtuman muokkauksen toiminto',
									$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config)))),
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (newValue, values) {
											return _Utils_update(
												values,
												{ft: newValue});
										}),
									mY: function ($) {
										return $.ft;
									}
								},
								A2(
									$author$project$Forms$ClauseForms$makeOptionalClauseForm,
									'Resurssinäkymä',
									makeClauseFormConfig('Resurssien lause'))),
							A2(
								$author$project$Lib$Form$Form$append,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (newValue, values) {
												return _Utils_update(
													values,
													{eX: newValue});
											}),
										mY: function ($) {
											return $.eX;
										}
									},
									$author$project$Forms$ClauseForms$makeClauseForm(
										makeClauseFormConfig('Tapahtumadatan lause'))),
								$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$CalendarViewCommandSpecification))))))));
};
var $author$project$Forms$CommonForms$constantMetaField = function (getter) {
	return $author$project$Lib$Form$Form$meta(
		A2($elm$core$Basics$composeL, $author$project$Lib$Form$Form$succeed, getter));
};
var $author$project$Forms$CommonForms$aproProjectIdField = $author$project$Forms$CommonForms$constantMetaField(
	function ($) {
		return $.hM;
	});
var $author$project$Lib$Form$Form$TextArea = 3;
var $author$project$Lib$Form$Form$textareaField = $author$project$Lib$Form$Base$TextField$form(
	$author$project$Lib$Form$Form$Text(3));
var $author$project$Forms$CommonForms$descriptionField = $author$project$Lib$Form$Form$allowEmptyString(
	$author$project$Lib$Form$Form$textareaField(
		{
			ba: {hS: $elm$core$Maybe$Nothing, kv: 'Kuvaus', lm: ''},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i$: value});
				}),
			mY: function ($) {
				return $.i$;
			}
		}));
var $author$project$Utils$DictUtils$filterByKey = function (isGood) {
	return $elm$core$Dict$filter(
		F2(
			function (k, _v0) {
				return isGood(k);
			}));
};
var $author$project$Forms$CommonForms$idAnchorField = $author$project$Forms$CommonForms$constantMetaField(
	function ($) {
		return $.j9;
	});
var $author$project$Lib$Form$Base$ConstantField$form = $author$project$Lib$Form$Base$Base$field(
	{
		b: $elm$core$Basics$always(false)
	});
var $author$project$Lib$Form$Form$intConstantField = function (_v0) {
	var value = _v0.mY;
	var attributes = _v0.ba;
	return A2(
		$author$project$Lib$Form$Form$map,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$String$toInt,
			$elm$core$Maybe$withDefault(-1)),
		A2(
			$author$project$Lib$Form$Base$ConstantField$form,
			$author$project$Lib$Form$Form$Constant,
			{
				ba: attributes,
				U: $elm$core$Result$Ok,
				a: F2(
					function (_v1, values) {
						return values;
					}),
				mY: A2($elm$core$Basics$composeR, value, $elm$core$String$fromInt)
			}));
};
var $author$project$Forms$CommonForms$idField = $author$project$Lib$Form$Form$intConstantField(
	{
		ba: {kv: 'Id'},
		mY: function ($) {
			return $.db;
		}
	});
var $author$project$Forms$CommonForms$limitedIntegerField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	var min = _v0.kU;
	var max = _v0.kI;
	var upperLimitParser = function (numValue) {
		return (_Utils_cmp(numValue, max) > 0) ? $elm$core$Result$Err(
			'Tulee olla korkeintaan ' + $elm$core$String$fromInt(max)) : $elm$core$Result$Ok(numValue);
	};
	var lowerLimitParser = function (numValue) {
		return (_Utils_cmp(numValue, min) < 0) ? $elm$core$Result$Err(
			'Tulee olla vähintään ' + $elm$core$String$fromInt(min)) : $elm$core$Result$Ok(numValue);
	};
	var intParser = A2(
		$elm$core$Basics$composeR,
		$elm$core$String$toInt,
		$elm$core$Result$fromMaybe('Kokonaisluku vaaditaan'));
	return $author$project$Lib$Form$Form$numberField(
		{
			ba: {
				kv: label,
				kI: $elm$core$Maybe$Just(max),
				kU: $elm$core$Maybe$Just(min),
				lm: '',
				mn: $elm$core$Maybe$Just(1)
			},
			U: A2(
				$elm$core$Basics$composeR,
				intParser,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Result$andThen(lowerLimitParser),
					$elm$core$Result$andThen(upperLimitParser))),
			a: update,
			mY: value
		});
};
var $author$project$Entity$Connection$isAwsOrDynamicConnection = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return true;
		case 4:
			return true;
		default:
			return false;
	}
};
var $author$project$Entity$Connection$isFileServerOrDynamicConnection = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return true;
		case 4:
			return true;
		case 5:
			return true;
		default:
			return false;
	}
};
var $author$project$Entity$Connection$isMailServerOrDynamicConnection = function (connection) {
	var _v0 = connection.mi;
	switch (_v0.$) {
		case 0:
			return true;
		case 3:
			return true;
		default:
			return false;
	}
};
var $author$project$Forms$CommonForms$selectIdOptionallyField = function (config) {
	var parser = function (str) {
		var _v0 = $elm$core$String$toInt(str);
		if (!_v0.$) {
			var id = _v0.a;
			return _Utils_eq(id, $author$project$Entity$Common$nonExistentId) ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : $elm$core$Result$Ok(
				$elm$core$Maybe$Just(id));
		} else {
			return $elm$core$Result$Err('Virhe');
		}
	};
	var allOptions = A2(
		$elm$core$List$cons,
		_Utils_Tuple2($author$project$Entity$Common$nonExistentId, config.k$),
		config.aT);
	return A2(
		$author$project$Lib$Form$Form$allowEmpty,
		$elm$core$Maybe$Nothing,
		$author$project$Forms$CommonForms$selectIdFieldInternal(
			{kv: config.kv, aT: allOptions, U: parser, a: config.a, mY: config.mY}));
};
var $author$project$Forms$ConnectionForms$selectOptionalConnectionField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var allConnections = _v0.eA;
	var connectionFilter = _v0.aa;
	var label = _v0.kv;
	return $author$project$Forms$CommonForms$selectIdOptionallyField(
		{
			kv: label,
			k$: 'Ei yhteyttä',
			aT: A2(
				$elm$core$List$map,
				function (c) {
					return _Utils_Tuple2(c.db, c.k_);
				},
				A2(
					$elm$core$List$filter,
					connectionFilter,
					$elm$core$Dict$values(allConnections))),
			a: update,
			mY: value
		});
};
var $author$project$Forms$ConnectionForms$makeConnectionSetForm = function (_v0) {
	var allConnections = _v0.eA;
	var showFileServerField = _v0.md;
	var showMailServerField = _v0.me;
	var mailServerConnectionField = showMailServerField ? $author$project$Forms$ConnectionForms$selectOptionalConnectionField(
		{
			eA: allConnections,
			aa: $author$project$Entity$Connection$isMailServerOrDynamicConnection,
			kv: 'Sähköpostiserveriyhteys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kE: value});
				}),
			mY: function ($) {
				return $.kE;
			}
		}) : $author$project$Forms$CommonForms$constantMetaField(
		$elm$core$Basics$always($elm$core$Maybe$Nothing));
	var httpConnectionField = $author$project$Forms$ConnectionForms$selectOptionalConnectionField(
		{
			eA: allConnections,
			aa: $author$project$Entity$Connection$isHttpOrDynamicConnection,
			kv: 'HTTP-yhteys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{j7: value});
				}),
			mY: function ($) {
				return $.j7;
			}
		});
	var fileServerConnectionField = showFileServerField ? $author$project$Forms$ConnectionForms$selectOptionalConnectionField(
		{
			eA: allConnections,
			aa: $author$project$Entity$Connection$isFileServerOrDynamicConnection,
			kv: 'Tiedostoserveriyhteys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jI: value});
				}),
			mY: function ($) {
				return $.jI;
			}
		}) : $author$project$Forms$CommonForms$constantMetaField(
		$elm$core$Basics$always($elm$core$Maybe$Nothing));
	var dbConnectionField = $author$project$Forms$ConnectionForms$selectOptionalConnectionField(
		{
			eA: allConnections,
			aa: $author$project$Entity$Connection$isDatabaseOrDynamicConnection,
			kv: 'Tietokantayhteys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iQ: value});
				}),
			mY: function ($) {
				return $.iQ;
			}
		});
	var awsConnectionField = showMailServerField ? $author$project$Forms$ConnectionForms$selectOptionalConnectionField(
		{
			eA: allConnections,
			aa: $author$project$Entity$Connection$isAwsOrDynamicConnection,
			kv: 'AWS-yhteys',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hW: value});
				}),
			mY: function ($) {
				return $.hW;
			}
		}) : $author$project$Forms$CommonForms$constantMetaField(
		$elm$core$Basics$always($elm$core$Maybe$Nothing));
	return A2(
		$author$project$Lib$Form$Form$append,
		awsConnectionField,
		A2(
			$author$project$Lib$Form$Form$append,
			mailServerConnectionField,
			A2(
				$author$project$Lib$Form$Form$append,
				fileServerConnectionField,
				A2(
					$author$project$Lib$Form$Form$append,
					httpConnectionField,
					A2(
						$author$project$Lib$Form$Form$append,
						dbConnectionField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$ConnectionReferenceSet))))));
};
var $author$project$Forms$InputFieldForms$makeInputFieldDefinitionFormList = F2(
	function (config, title) {
		return A2(
			$author$project$Lib$Form$Form$simpleList,
			{
				f7: {
					iV: $elm$core$Maybe$Nothing,
					kb: '',
					kv: '',
					lI: false,
					eo: {
						hE: false,
						aI: false,
						aZ: false,
						a_: true,
						a$: $author$project$Forms$ClauseForms$createClauseValues($author$project$Entity$Clauses$createNewClause),
						a0: 'Valitse...',
						a1: true,
						a4: 'text'
					}
				},
				kv: title,
				a: F2(
					function (value, _v0) {
						return value;
					}),
				mY: $elm$core$Basics$identity
			},
			function (_v1) {
				return $author$project$Forms$InputFieldForms$makeInputFieldDefinitionForm(config);
			});
	});
var $author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm = function (config) {
	var timeoutField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'Timeout [s]',
			kI: 120,
			kU: 3,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mB: value});
				}),
			mY: function ($) {
				return $.mB;
			}
		});
	var parameterMappingsForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {kt: '', mY: $elm$core$Maybe$Nothing},
			kv: 'Parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{li: value});
				}),
			mY: function ($) {
				return $.li;
			}
		},
		function (_v1) {
			return $author$project$Forms$CommandParameterForm$commandParameterField(1);
		});
	var keyField = $author$project$Lib$Form$Form$meta(
		function (values) {
			var keysAlreadyInUse = A2(
				$elm$core$List$map,
				function ($) {
					return $.kt;
				},
				$elm$core$Dict$values(
					A2(
						$author$project$Utils$DictUtils$filterByKey,
						$elm$core$Basics$neq(values.db),
						config.fU)));
			return $author$project$Lib$Form$Form$textField(
				{
					ba: {hS: $elm$core$Maybe$Nothing, kv: 'Tunniste', lm: ''},
					U: function (value) {
						return A2($elm$core$List$member, value, keysAlreadyInUse) ? $elm$core$Result$Err('Tunniste on jo käytössä') : $elm$core$Result$Ok(value);
					},
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{kt: value});
						}),
					mY: function ($) {
						return $.kt;
					}
				});
		});
	var extraParamsInputForm = $author$project$Lib$Form$Form$meta(
		function (_v0) {
			var metadata = _v0.kQ;
			return metadata.g6 ? A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{eZ: value});
						}),
					mY: function ($) {
						return $.eZ;
					}
				},
				A2($author$project$Forms$InputFieldForms$makeInputFieldDefinitionFormList, config, 'Lisäparametrien kentät')) : $author$project$Lib$Form$Form$succeed(_List_Nil);
		});
	var connectionSetForm = $author$project$Lib$Form$Form$meta(
		function (values) {
			return A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{eL: value});
						}),
					mY: function ($) {
						return $.eL;
					}
				},
				$author$project$Forms$ConnectionForms$makeConnectionSetForm(
					{eA: config.eA, mb: values.kQ.g5, md: values.kQ.g7, me: values.kQ.g8}));
		});
	return A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ip: value});
				}),
			mY: function ($) {
				return $.ip;
			}
		},
		A2(
			$author$project$Lib$Form$Form$append,
			timeoutField,
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Forms$ParameterClauseForm$makeParameterClauseFormList,
					{
						kv: 'Pohjalauseet',
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{hZ: value});
							}),
						mY: function ($) {
							return $.hZ;
						}
					},
					config),
				A2(
					$author$project$Lib$Form$Form$append,
					parameterMappingsForm,
					A2(
						$author$project$Lib$Form$Form$append,
						extraParamsInputForm,
						A2(
							$author$project$Lib$Form$Form$append,
							connectionSetForm,
							A2(
								$author$project$Lib$Form$Form$append,
								$author$project$Lib$Form$Form$allowEmptyString($author$project$Forms$CommonForms$descriptionField),
								A2(
									$author$project$Lib$Form$Form$append,
									keyField,
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$idAnchorField,
										A2(
											$author$project$Lib$Form$Form$append,
											$author$project$Forms$CommonForms$aproProjectIdField,
											A2(
												$author$project$Lib$Form$Form$append,
												$author$project$Forms$CommonForms$constantMetaField(
													function ($) {
														return $.dM;
													}),
												A2(
													$author$project$Lib$Form$Form$append,
													$author$project$Forms$CommonForms$constantMetaField(
														function ($) {
															return $.j_;
														}),
													A2(
														$author$project$Lib$Form$Form$append,
														$author$project$Forms$CommonForms$idField,
														$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$Command))))))))))))));
};
var $author$project$Forms$CommandForms$CalendarViewCommandForm$makeCalendarViewCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecCalendarViewCommand,
			$author$project$Forms$CommandForms$CalendarViewCommandForm$makeCalendarViewCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommonForms$jsonField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	return $author$project$Lib$Form$Form$codeField(
		{
			ba: {kv: label, aP: 4, lm: ''},
			U: A2(
				$elm$core$Basics$composeR,
				$elm$json$Json$Decode$decodeString($elm$json$Json$Decode$value),
				$elm$core$Result$mapError($elm$json$Json$Decode$errorToString)),
			a: update,
			mY: value
		});
};
var $author$project$Forms$CommandForms$CommandCommonForms$bindKeyField = $author$project$Lib$Form$Form$simpleTextField(
	{
		kv: 'Tunniste',
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{fW: value});
			}),
		mY: function ($) {
			return $.fW;
		}
	});
var $author$project$Forms$CommandForms$CommandCommonForms$makeAavoActionBindingForm = function (config) {
	var selectItemOnClickField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Valitse klikatessa',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{l_: value});
				}),
			mY: function ($) {
				return $.l_;
			}
		});
	var actionForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (newValue, values) {
					return _Utils_update(
						values,
						{a7: newValue});
				}),
			mY: function ($) {
				return $.a7;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config));
	return A2(
		$author$project$Lib$Form$Form$append,
		actionForm,
		A2(
			$author$project$Lib$Form$Form$append,
			selectItemOnClickField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommandForms$CommandCommonForms$bindKeyField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$AavoActionBinding))));
};
var $author$project$Forms$CommandForms$CommandCommonForms$makeAavoActionBindingFormList = F2(
	function (config, label) {
		return A2(
			$author$project$Lib$Form$Form$simpleList,
			{
				f7: {
					a7: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues($author$project$Entity$ActionAndViewComponent$createNewKappaAction),
					fW: '',
					l_: false
				},
				kv: label,
				a: F2(
					function (value, _v0) {
						return value;
					}),
				mY: $elm$core$Basics$identity
			},
			function (_v1) {
				return $author$project$Forms$CommandForms$CommandCommonForms$makeAavoActionBindingForm(config);
			});
	});
var $author$project$Forms$CommandForms$ChartCommandForm$makeChartCommandSeriesConfigForm = function (_v0) {
	var allConnections = _v0.eA;
	var allCommands = _v0.fU;
	var makeClauseForm = function (title) {
		return $author$project$Forms$ClauseForms$makeClauseForm(
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands));
	};
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (newValue, values) {
						return _Utils_update(
							values,
							{cN: newValue});
					}),
				mY: function ($) {
					return $.cN;
				}
			},
			makeClauseForm('Datan lause')),
		A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (newValue, values) {
							return _Utils_update(
								values,
								{aq: newValue});
						}),
					mY: function ($) {
						return $.aq;
					}
				},
				makeClauseForm('Määrittelylause')),
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$ChartCommandSeriesConfig)));
};
var $author$project$Forms$CommandForms$CommandCommonForms$makeContextMenuBindingForm = function (config) {
	var menuComponentsFormList = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kN: value});
				}),
			mY: function ($) {
				return $.kN;
			}
		},
		$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList(config));
	return A2(
		$author$project$Lib$Form$Form$append,
		menuComponentsFormList,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$bindKeyField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$ContextMenuBinding)));
};
var $author$project$Forms$CommandForms$ChartCommandForm$makeReturnTypeForm = function (config) {
	var typeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Palautuksen tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('json', 'JSON'),
						_Utils_Tuple2('exportedFile', 'Tiedosto')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{a4: value});
				}),
			mY: function ($) {
				return $.a4;
			}
		});
	var exportedFileNameForm = $author$project$Lib$Form$Form$meta(
		function (_v0) {
			var typeStr = _v0.a4;
			return (typeStr === 'json') ? $author$project$Lib$Form$Form$succeed($author$project$Entity$Clauses$createNewClause) : A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{bw: value});
						}),
					mY: function ($) {
						return $.bw;
					}
				},
				$author$project$Forms$ClauseForms$makeClauseForm(
					{fU: config.fU, eA: config.eA, f0: 0, mC: 'Palautettavan tiedoston nimi'}));
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		exportedFileNameForm,
		A2(
			$author$project$Lib$Form$Form$append,
			typeField,
			$author$project$Lib$Form$Form$succeed(
				F2(
					function (typeStr, exportFileName) {
						return (typeStr === 'json') ? $author$project$Entity$Command$JsonReturnType : $author$project$Entity$Command$ExportedFileReturnType(
							{jG: exportFileName});
					}))));
};
var $author$project$Forms$CommandForms$ChartCommandForm$makeChartCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var seriesFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {cN: $author$project$Forms$ClauseForms$createDefaultClauseValues, aq: $author$project$Forms$ClauseForms$createDefaultClauseValues},
			kv: 'Sarjat',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{fz: value});
				}),
			mY: function ($) {
				return $.fz;
			}
		},
		function (_v1) {
			return $author$project$Forms$CommandForms$ChartCommandForm$makeChartCommandSeriesConfigForm(config);
		});
	var eventHandlersField = $author$project$Forms$CommonForms$jsonField(
		{
			kv: 'Events',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{h9: value});
				}),
			mY: function ($) {
				return $.h9;
			}
		});
	var contextMenuBindingsFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {fW: '', kN: _List_Nil},
			kv: 'Kontekstivalikot',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{eM: value});
				}),
			mY: function ($) {
				return $.eM;
			}
		},
		function (_v0) {
			return $author$project$Forms$CommandForms$CommandCommonForms$makeContextMenuBindingForm(config);
		});
	var chartBaseTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('CommonCharts', 'Yleinen'),
						_Utils_Tuple2('GanttChart', 'Gantt')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseChartBaseType,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{h8: value});
				}),
			mY: function ($) {
				return $.h8;
			}
		});
	var actionBindingFormList = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ez: value});
				}),
			mY: function ($) {
				return $.ez;
			}
		},
		A2($author$project$Forms$CommandForms$CommandCommonForms$makeAavoActionBindingFormList, config, 'Toiminnot'));
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{fw: newValue});
							}),
						mY: function ($) {
							return $.fw;
						}
					},
					$author$project$Forms$CommandForms$ChartCommandForm$makeReturnTypeForm(config)),
				A2(
					$author$project$Lib$Form$Form$append,
					eventHandlersField,
					A2(
						$author$project$Lib$Form$Form$append,
						contextMenuBindingsFormList,
						A2(
							$author$project$Lib$Form$Form$append,
							actionBindingFormList,
							A2(
								$author$project$Lib$Form$Form$append,
								seriesFormList,
								A2(
									$author$project$Lib$Form$Form$append,
									A2(
										$author$project$Lib$Form$Form$mapValues,
										{
											a: F2(
												function (newValue, values) {
													return _Utils_update(
														values,
														{aq: newValue});
												}),
											mY: function ($) {
												return $.aq;
											}
										},
										$author$project$Forms$ClauseForms$makeClauseForm(
											A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Määrittelylause', allConnections, allCommands))),
									A2(
										$author$project$Lib$Form$Form$append,
										chartBaseTypeField,
										$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$ChartCommandSpecification))))))))));
};
var $author$project$Forms$CommandForms$ChartCommandForm$makeChartCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecChartCommand,
			$author$project$Forms$CommandForms$ChartCommandForm$makeChartCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$CronCommandForm$makeCronCommandSpecForm = function (_v0) {
	var allConnections = _v0.eA;
	var allCommands = _v0.fU;
	var firstExecutionTimeField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: '\"Ensimmäinen\" suoritusaika (ISO-8601)',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jL: value});
				}),
			mY: function ($) {
				return $.jL;
			}
		});
	var doNotLogIfNoMatchField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Älä tallenna lokeja suorittamatta jätetyistä kutsuista',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i3: value});
				}),
			mY: function ($) {
				return $.i3;
			}
		});
	var cronExpressionField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'cron-määritelmä',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iK: value});
				}),
			mY: function ($) {
				return $.iK;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		doNotLogIfNoMatchField,
		A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (newValue, values) {
							return _Utils_update(
								values,
								{eI: newValue});
						}),
					mY: function ($) {
						return $.eI;
					}
				},
				$author$project$Forms$ClauseForms$makeClauseForm(
					A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Suoritettava lause', allConnections, allCommands))),
			A2(
				$author$project$Lib$Form$Form$append,
				firstExecutionTimeField,
				A2(
					$author$project$Lib$Form$Form$append,
					cronExpressionField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$CronCommandSpecification)))));
};
var $author$project$Forms$CommandForms$CronCommandForm$makeCronCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecCronCommand,
			$author$project$Forms$CommandForms$CronCommandForm$makeCronCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$DataViewCommandForm$dataViewRowClassRulesFormList = function () {
	var ruleField = $author$project$Lib$Form$Form$simpleCodeField(
		{
			kv: 'Ehto',
			aP: 5,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lV: value});
				}),
			mY: function ($) {
				return $.lV;
			}
		});
	var columnNameField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Sarake (tyhjä -> koko rivi)',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{ih: value});
					}),
				mY: function ($) {
					return $.ih;
				}
			}));
	var classNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'CSS-luokka',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ic: value});
				}),
			mY: function ($) {
				return $.ic;
			}
		});
	var rowStylingForm = A2(
		$author$project$Lib$Form$Form$append,
		ruleField,
		A2(
			$author$project$Lib$Form$Form$append,
			columnNameField,
			A2(
				$author$project$Lib$Form$Form$append,
				classNameField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$DataViewClassRule))));
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {ic: '', ih: '', lV: 'data.?.value > 0'},
			kv: 'CSS-säännöt',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cD: value});
				}),
			mY: function ($) {
				return $.cD;
			}
		},
		function (_v0) {
			return rowStylingForm;
		});
}();
var $author$project$Forms$CommandForms$DataViewCommandForm$makeInsertConfigForm = function (config) {
	var formInsertEnabledField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Lomakkeella lisääminen',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jN: value});
				}),
			mY: function ($) {
				return $.jN;
			}
		});
	var directInsertClauseForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (newValue, values) {
					return _Utils_update(
						values,
						{bq: newValue});
				}),
			mY: function ($) {
				return $.bq;
			}
		},
		A2(
			$author$project$Forms$ClauseForms$makeOptionalClauseForm,
			'Suora lisääminen',
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Uuden rivin tallennuslause', config.eA, config.fU)));
	return A2(
		$author$project$Forms$CommonForms$convertFormToOptional,
		'Rivien lisääminen',
		A2(
			$author$project$Lib$Form$Form$section,
			'Rivien lisääminen',
			A2(
				$author$project$Lib$Form$Form$append,
				formInsertEnabledField,
				A2(
					$author$project$Lib$Form$Form$append,
					directInsertClauseForm,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$DataViewCommandInsertConfiguration)))));
};
var $author$project$Forms$CommandForms$DataViewCommandForm$makeStaticColumnDefinitionsFormList = function (config) {
	var useValuesAsParametersField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Käytä arvoja parametreinä',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mT: value});
				}),
			mY: function ($) {
				return $.mT;
			}
		});
	var isHiddenField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Piilotettu',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kp: value});
				}),
			mY: function ($) {
				return $.kp;
			}
		});
	var isEditableField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Muokattava',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{di: value});
				}),
			mY: function ($) {
				return $.di;
			}
		});
	var inputFieldForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{de: value});
				}),
			mY: function ($) {
				return $.de;
			}
		},
		$author$project$Forms$InputFieldForms$makeInputFieldDefinitionForm(config));
	var cellRendererField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Renderöinti',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('Default', 'Oletus'),
						_Utils_Tuple2('Html', 'HTML'),
						_Utils_Tuple2('Image', 'Kuva'),
						_Utils_Tuple2('Link', 'Linkki (URL[;Otsikko])'),
						_Utils_Tuple2('Icon', 'Ikoni')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$dataViewCellRendererParser,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{h6: value});
				}),
			mY: function ($) {
				return $.h6;
			}
		});
	var dataViewColumnDefinitionForm = function (_v0) {
		return A2(
			$author$project$Lib$Form$Form$append,
			useValuesAsParametersField,
			A2(
				$author$project$Lib$Form$Form$append,
				cellRendererField,
				A2(
					$author$project$Lib$Form$Form$append,
					isEditableField,
					A2(
						$author$project$Lib$Form$Form$append,
						isHiddenField,
						A2(
							$author$project$Lib$Form$Form$append,
							inputFieldForm,
							$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$DataViewColumn))))));
	};
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {
				h6: $author$project$Utils$ToStrings$dataViewCellRendererToString(0),
				de: $author$project$Forms$InputFieldForms$createFieldDefinitionFormValues($author$project$Entity$InputFieldDefinition$createNewInputFieldDefinition),
				di: false,
				kp: false,
				mT: true
			},
			kv: 'Staattiset sarakkeiden lisämääritteet',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ml: value});
				}),
			mY: function ($) {
				return $.ml;
			}
		},
		dataViewColumnDefinitionForm);
};
var $author$project$Forms$CommandForms$DataViewCommandForm$makeDataViewCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var selectionModeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Rivien valitseminen',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('SelectionDisabled', 'Poissa käytöstä'),
						_Utils_Tuple2('SingleRow', 'Yksi rivi'),
						_Utils_Tuple2('MultiRow', 'Monta riviä')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseDataViewSelectionMode,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{d7: value});
				}),
			mY: function ($) {
				return $.d7;
			}
		});
	var selectFirstRowByDefaultField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Valitse ensimmäinen rivi automaattisesti',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lY: value});
				}),
			mY: function ($) {
				return $.lY;
			}
		});
	var paginationModeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Datan sivutus',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('PaginationDisabled', 'Poissa käytöstä'),
						_Utils_Tuple2('ClientSidePagination', 'Sivutus selaimessa'),
						_Utils_Tuple2('ServerSidePagination', 'Sivutus palvelimella')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseDataViewPaginationMode,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{dI: value});
				}),
			mY: function ($) {
				return $.dI;
			}
		});
	var makeOptionalClauseForm = F2(
		function (optionalityLabel, title) {
			return A2(
				$author$project$Forms$ClauseForms$makeOptionalClauseForm,
				optionalityLabel,
				A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands));
		});
	var makeClauseForm = function (title) {
		return $author$project$Forms$ClauseForms$makeClauseForm(
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands));
	};
	var idColumnNameField = $author$project$Lib$Form$Form$optional(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'ID-sarake',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{ka: value});
					}),
				mY: function ($) {
					return $.ka;
				}
			}));
	var draggableRowsField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Rivit raahattavissa',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i7: value});
				}),
			mY: function ($) {
				return $.i7;
			}
		});
	var defineColumnsByDataField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Määrittele sarakkeet datan perusteella',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iX: value});
				}),
			mY: function ($) {
				return $.iX;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		selectFirstRowByDefaultField,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (value, values) {
									return _Utils_update(
										values,
										{d2: value});
								}),
							mY: function ($) {
								return $.d2;
							}
						},
						A2(
							$author$project$Forms$CommonForms$convertFormToOptional,
							'Rivitason pudotusalue käytössä',
							$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigForm(config))),
					A2(
						$author$project$Lib$Form$Form$append,
						draggableRowsField,
						A2(
							$author$project$Lib$Form$Form$append,
							paginationModeField,
							A2(
								$author$project$Lib$Form$Form$append,
								selectionModeField,
								A2(
									$author$project$Lib$Form$Form$append,
									$author$project$Forms$CommonForms$constantMetaField(
										function ($) {
											return $.ja;
										}),
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommandForms$DataViewCommandForm$dataViewRowClassRulesFormList,
										A2(
											$author$project$Lib$Form$Form$append,
											A2(
												$author$project$Lib$Form$Form$mapValues,
												{
													a: F2(
														function (newValue, values) {
															return _Utils_update(
																values,
																{df: newValue});
														}),
													mY: function ($) {
														return $.df;
													}
												},
												$author$project$Forms$CommandForms$DataViewCommandForm$makeInsertConfigForm(config)),
											A2(
												$author$project$Lib$Form$Form$append,
												A2(
													$author$project$Lib$Form$Form$mapValues,
													{
														a: F2(
															function (newValue, values) {
																return _Utils_update(
																	values,
																	{eq: newValue});
															}),
														mY: function ($) {
															return $.eq;
														}
													},
													A2(makeOptionalClauseForm, 'Rivien muokkaus', 'Päivityslause')),
												A2(
													$author$project$Lib$Form$Form$append,
													A2(
														$author$project$Lib$Form$Form$mapValues,
														{
															a: F2(
																function (newValue, values) {
																	return _Utils_update(
																		values,
																		{cP: newValue});
																}),
															mY: function ($) {
																return $.cP;
															}
														},
														A2(makeOptionalClauseForm, 'Rivien poistaminen', 'Poistolause')),
													A2(
														$author$project$Lib$Form$Form$append,
														A2(
															$author$project$Lib$Form$Form$mapValues,
															{
																a: F2(
																	function (newValue, values) {
																		return _Utils_update(
																			values,
																			{jb: newValue});
																	}),
																mY: function ($) {
																	return $.jb;
																}
															},
															makeClauseForm('Dynaamiset sarakkeiden lisämääritteet')),
														A2(
															$author$project$Lib$Form$Form$append,
															idColumnNameField,
															A2(
																$author$project$Lib$Form$Form$append,
																defineColumnsByDataField,
																A2(
																	$author$project$Lib$Form$Form$append,
																	$author$project$Forms$CommandForms$DataViewCommandForm$makeStaticColumnDefinitionsFormList(config),
																	A2(
																		$author$project$Lib$Form$Form$append,
																		A2(
																			$author$project$Lib$Form$Form$mapValues,
																			{
																				a: F2(
																					function (newValue, values) {
																						return _Utils_update(
																							values,
																							{cX: newValue});
																					}),
																				mY: function ($) {
																					return $.cX;
																				}
																			},
																			makeClauseForm('Hakulause')),
																		$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$DataViewCommandSpec))))))))))))))))));
};
var $author$project$Forms$CommandForms$DataViewCommandForm$makeDataViewCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecDataViewCommand,
			$author$project$Forms$CommandForms$DataViewCommandForm$makeDataViewCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$DiagramCommandForm$makeDiagramCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var contextMenusFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {fW: '', kN: _List_Nil},
			kv: 'Solmujen kontekstivalikot',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f5: value});
				}),
			mY: function ($) {
				return $.f5;
			}
		},
		function (_v1) {
			return $author$project$Forms$CommandForms$CommandCommonForms$makeContextMenuBindingForm(config);
		});
	var actionBindingsFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {
				a7: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues($author$project$Entity$ActionAndViewComponent$createNewKappaAction),
				fW: '',
				l_: true
			},
			kv: 'Solmujen toiminnot',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{fP: value});
				}),
			mY: function ($) {
				return $.fP;
			}
		},
		function (_v0) {
			return $author$project$Forms$CommandForms$CommandCommonForms$makeAavoActionBindingForm(config);
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{fc: newValue});
							}),
						mY: function ($) {
							return $.fc;
						}
					},
					$author$project$Forms$ClauseForms$makeClauseForm(
						A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Solmujen parametrien lause', allConnections, allCommands))),
				A2(
					$author$project$Lib$Form$Form$append,
					contextMenusFormList,
					A2(
						$author$project$Lib$Form$Form$append,
						actionBindingsFormList,
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (newValue, values) {
											return _Utils_update(
												values,
												{fb: newValue});
										}),
									mY: function ($) {
										return $.fb;
									}
								},
								$author$project$Forms$ClauseForms$makeClauseForm(
									A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Määrittelylause', allConnections, allCommands))),
							$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$DiagramCommandSpecification)))))));
};
var $author$project$Forms$CommandForms$DiagramCommandForm$makeDiagramCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecDiagramCommand,
			$author$project$Forms$CommandForms$DiagramCommandForm$makeDiagramCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$DownloadFileCommandForm$makeDownloadFileCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var tryConvertToPdfField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Yritä muuntaa PDF-muotoon',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mK: value});
				}),
			mY: function ($) {
				return $.mK;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				tryConvertToPdfField,
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{e$: newValue});
								}),
							mY: function ($) {
								return $.e$;
							}
						},
						$author$project$Forms$ClauseForms$makeClauseForm(
							A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Tiedoston sijainnin lause', allConnections, allCommands))),
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$DownloadFileCommandSpecification)))));
};
var $author$project$Forms$CommandForms$DownloadFileCommandForm$makeDownloadFileCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecDownloadFileCommand,
			$author$project$Forms$CommandForms$DownloadFileCommandForm$makeDownloadFileCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$FormCommandForm$makeFormCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var allViews = config.hG;
	var makeOptionalClauseForm = F2(
		function (optionalityTitle, title) {
			return A2(
				$author$project$Forms$ClauseForms$makeOptionalClauseForm,
				optionalityTitle,
				A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands));
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{fn: newValue});
							}),
						mY: function ($) {
							return $.fn;
						}
					},
					A2(
						$author$project$Forms$CommonForms$convertFormToOptional,
						'Määritä tallennuksen jälkeinen toiminto',
						A2(
							$author$project$Lib$Form$Form$section,
							'Tallennuksen jälkeinen toiminto',
							$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config)))),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{e9: newValue});
								}),
							mY: function ($) {
								return $.e9;
							}
						},
						A2(makeOptionalClauseForm, 'Määritä lisäyksen lause (kun avattu datanäkymästä)', 'Tallennuslause')),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{eq: newValue});
									}),
								mY: function ($) {
									return $.eq;
								}
							},
							A2(makeOptionalClauseForm, 'Määritä päivityslause', 'Tallennuslause')),
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$FormCommandSpecification))))));
};
var $author$project$Forms$CommandForms$FormCommandForm$makeFormCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecFormCommand,
			$author$project$Forms$CommandForms$FormCommandForm$makeFormCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Lib$CodeMirror$HTML = 3;
var $author$project$Forms$CommandForms$MailCommandForm$makeMailCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var makeClauseForm = F2(
		function (title, codeEditorMode) {
			return $author$project$Forms$ClauseForms$makeClauseForm(
				A4($author$project$Forms$ClauseForms$ClauseFormConfig, codeEditorMode, title, allConnections, allCommands));
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{d0: newValue});
							}),
						mY: function ($) {
							return $.d0;
						}
					},
					A2(makeClauseForm, 'Komennon tuloksen lause', 0)),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{d$: newValue});
								}),
							mY: function ($) {
								return $.d$;
							}
						},
						A2(makeClauseForm, 'Vastaanottajien lause', 4)),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{cK: newValue});
									}),
								mY: function ($) {
									return $.cK;
								}
							},
							A2(makeClauseForm, 'Sisällön lause', 3)),
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (newValue, values) {
											return _Utils_update(
												values,
												{ef: newValue});
										}),
									mY: function ($) {
										return $.ef;
									}
								},
								A2(makeClauseForm, 'Otsikon lause', 0)),
							$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$MailCommandSpecification)))))));
};
var $author$project$Forms$CommandForms$MailCommandForm$makeMailCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecMailCommand,
			$author$project$Forms$CommandForms$MailCommandForm$makeMailCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$NotificationCommandForm$makeNotificationCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var makeOptionalClauseForm = F3(
		function (optionalityTitle, title, codeEditorMode) {
			return A2(
				$author$project$Forms$ClauseForms$makeOptionalClauseForm,
				optionalityTitle,
				A4($author$project$Forms$ClauseForms$ClauseFormConfig, codeEditorMode, title, allConnections, allCommands));
		});
	var makeClauseForm = F2(
		function (title, codeEditorMode) {
			return $author$project$Forms$ClauseForms$makeClauseForm(
				A4($author$project$Forms$ClauseForms$ClauseFormConfig, codeEditorMode, title, allConnections, allCommands));
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{dc: newValue});
							}),
						mY: function ($) {
							return $.dc;
						}
					},
					A3(makeOptionalClauseForm, 'Määritä kuva', 'Kuvan lause', 0)),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$section,
						'Toiminto',
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{a7: newValue});
									}),
								mY: function ($) {
									return $.a7;
								}
							},
							$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config))),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{d0: newValue});
									}),
								mY: function ($) {
									return $.d0;
								}
							},
							A2(makeClauseForm, 'Komennon tuloksen lause', 0)),
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (newValue, values) {
											return _Utils_update(
												values,
												{d$: newValue});
										}),
									mY: function ($) {
										return $.d$;
									}
								},
								A2(makeClauseForm, 'Vastaanottajien lause', 4)),
							A2(
								$author$project$Lib$Form$Form$append,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (newValue, values) {
												return _Utils_update(
													values,
													{ct: newValue});
											}),
										mY: function ($) {
											return $.ct;
										}
									},
									A2(makeClauseForm, 'Sisällön lause', 0)),
								A2(
									$author$project$Lib$Form$Form$append,
									A2(
										$author$project$Lib$Form$Form$mapValues,
										{
											a: F2(
												function (newValue, values) {
													return _Utils_update(
														values,
														{el: newValue});
												}),
											mY: function ($) {
												return $.el;
											}
										},
										A2(makeClauseForm, 'Otsikon lause', 0)),
									$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$NotificationCommandSpecification)))))))));
};
var $author$project$Forms$CommandForms$NotificationCommandForm$makeNotificationCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecNotificationCommand,
			$author$project$Forms$CommandForms$NotificationCommandForm$makeNotificationCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$ProductionLineCommandForm$makeProductionLineCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var makeClauseForm = function (title) {
		return $author$project$Forms$ClauseForms$makeClauseForm(
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands));
	};
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{cw: newValue});
							}),
						mY: function ($) {
							return $.cw;
						}
					},
					makeClauseForm('Laatikkoa rivillä -lause')),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{cu: newValue});
								}),
							mY: function ($) {
								return $.cu;
							}
						},
						makeClauseForm('Laatikoiden järjestämisen lause')),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{ek: newValue});
									}),
								mY: function ($) {
									return $.ek;
								}
							},
							makeClauseForm('Ajan ulkoasun lause')),
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (newValue, values) {
											return _Utils_update(
												values,
												{cv: newValue});
										}),
									mY: function ($) {
										return $.cv;
									}
								},
								makeClauseForm('Laatikoiden ulkoasun lause')),
							A2(
								$author$project$Lib$Form$Form$append,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (newValue, values) {
												return _Utils_update(
													values,
													{cN: newValue});
											}),
										mY: function ($) {
											return $.cN;
										}
									},
									makeClauseForm('Datan lause')),
								$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$ProductionLineCommandSpecification))))))));
};
var $author$project$Forms$CommandForms$ProductionLineCommandForm$makeProductionLineCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecProductionLineCommand,
			$author$project$Forms$CommandForms$ProductionLineCommandForm$makeProductionLineCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$QuickSightCommandForm$makeQuickSightCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{iN: newValue});
							}),
						mY: function ($) {
							return $.iN;
						}
					},
					$author$project$Forms$ClauseForms$makeClauseForm(
						A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Dashboardin lause (tyhjä -> koko konsoli)', allConnections, allCommands))),
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$QuickSightCommandSpecification))));
};
var $author$project$Forms$CommandForms$QuickSightCommandForm$makeQuickSightCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecQuickSightCommand,
			$author$project$Forms$CommandForms$QuickSightCommandForm$makeQuickSightCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Entity$Command$isFileDownloadCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 6) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Forms$CommandForms$ReportCommandForm$makeReportCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var templateFileCommandCallDefinitionForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (newValue, values) {
					return _Utils_update(
						values,
						{fI: newValue});
				}),
			mY: function ($) {
				return $.fI;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Hae pohja komennon avulla',
			A2(
				$author$project$Forms$CommandCallDefinitionForm$makeCommandCallDefinitionForm,
				1,
				A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Command$isFileDownloadCommand, allCommands))));
	var reportTimeZoneIdField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Raportin aikavyöhyke (IANA)',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lF: value});
				}),
			mY: function ($) {
				return $.lF;
			}
		});
	var reportLocaleField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Raportin locale (ISO 639)',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lE: value});
				}),
			mY: function ($) {
				return $.lE;
			}
		});
	var makeOptionalClauseForm = F2(
		function (checkBoxTitle, label) {
			return A2(
				$author$project$Forms$ClauseForms$makeOptionalClauseForm,
				checkBoxTitle,
				A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, label, allConnections, allCommands));
		});
	var makeClauseForm = function (label) {
		return $author$project$Forms$ClauseForms$makeClauseForm(
			A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, label, allConnections, allCommands));
	};
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				reportLocaleField,
				A2(
					$author$project$Lib$Form$Form$append,
					reportTimeZoneIdField,
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{fv: newValue});
									}),
								mY: function ($) {
									return $.fv;
								}
							},
							A2(makeOptionalClauseForm, 'Määritä tulostiedoston nimi', 'Tulostiedoston nimen lause')),
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$mapValues,
								{
									a: F2(
										function (newValue, values) {
											return _Utils_update(
												values,
												{cN: newValue});
										}),
									mY: function ($) {
										return $.cN;
									}
								},
								makeClauseForm('Datan lause')),
							A2(
								$author$project$Lib$Form$Form$append,
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (newValue, values) {
												return _Utils_update(
													values,
													{fH: newValue});
											}),
										mY: function ($) {
											return $.fH;
										}
									},
									A2(makeOptionalClauseForm, 'Hae pohja lauseen avulla', 'Pohjatiedoston hakulause')),
								A2(
									$author$project$Lib$Form$Form$append,
									templateFileCommandCallDefinitionForm,
									$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$ReportCommandSpecification)))))))));
};
var $author$project$Forms$CommandForms$ReportCommandForm$makeReportCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecReportCommand,
			$author$project$Forms$CommandForms$ReportCommandForm$makeReportCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$SingleClauseCommandForm$makeSingleClauseCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{be: newValue});
							}),
						mY: function ($) {
							return $.be;
						}
					},
					$author$project$Forms$ClauseForms$makeClauseForm(
						A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Suoritettava lause', allConnections, allCommands))),
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$SingleClauseCommandSpec))));
};
var $author$project$Forms$CommandForms$SingleClauseCommandForm$makeSingleClauseCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecSingleClauseCommand,
			$author$project$Forms$CommandForms$SingleClauseCommandForm$makeSingleClauseCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$TreeViewCommandForm$makeTreeViewCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var draggableElementsField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Elementit raahattavissa',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i6: value});
				}),
			mY: function ($) {
				return $.i6;
			}
		});
	var contextMenusFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {fW: '', kN: _List_Nil},
			kv: 'Liitetyt kontekstivalikot',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f5: value});
				}),
			mY: function ($) {
				return $.f5;
			}
		},
		function (_v1) {
			return $author$project$Forms$CommandForms$CommandCommonForms$makeContextMenuBindingForm(config);
		});
	var actionsFormList = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {
				a7: $author$project$Forms$ViewComponentAndActionForms$createKappaActionFormValues($author$project$Entity$ActionAndViewComponent$createNewKappaAction),
				fW: '',
				l_: true
			},
			kv: 'Liitetyt toiminnot',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hA: value});
				}),
			mY: function ($) {
				return $.hA;
			}
		},
		function (_v0) {
			return $author$project$Forms$CommandForms$CommandCommonForms$makeAavoActionBindingForm(config);
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				draggableElementsField,
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (value, values) {
									return _Utils_update(
										values,
										{eS: value});
								}),
							mY: function ($) {
								return $.eS;
							}
						},
						A2(
							$author$project$Forms$CommonForms$convertFormToOptional,
							'Elementtien välien pudotusalue käytössä',
							$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigForm(config))),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (value, values) {
										return _Utils_update(
											values,
											{eR: value});
									}),
								mY: function ($) {
									return $.eR;
								}
							},
							A2(
								$author$project$Forms$CommonForms$convertFormToOptional,
								'Elementtien pudotusalue käytössä',
								$author$project$Forms$ViewComponentAndActionForms$makeElementDropZoneConfigForm(config))),
						A2(
							$author$project$Lib$Form$Form$append,
							A2(
								$author$project$Lib$Form$Form$section,
								'Elementin tuplaklikkauksen toiminto',
								A2(
									$author$project$Lib$Form$Form$mapValues,
									{
										a: F2(
											function (newValue, values) {
												return _Utils_update(
													values,
													{fg: newValue});
											}),
										mY: function ($) {
											return $.fg;
										}
									},
									A2(
										$author$project$Forms$CommonForms$convertFormToOptional,
										'Määritä elementin tuplaklikkauksen toiminto',
										$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config)))),
							A2(
								$author$project$Lib$Form$Form$append,
								A2(
									$author$project$Lib$Form$Form$section,
									'Elementin klikkauksen toiminto',
									A2(
										$author$project$Lib$Form$Form$mapValues,
										{
											a: F2(
												function (newValue, values) {
													return _Utils_update(
														values,
														{ff: newValue});
												}),
											mY: function ($) {
												return $.ff;
											}
										},
										A2(
											$author$project$Forms$CommonForms$convertFormToOptional,
											'Määritä elementin klikkauksen toiminto',
											$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config)))),
								A2(
									$author$project$Lib$Form$Form$append,
									A2(
										$author$project$Lib$Form$Form$section,
										'Elementin valinnan toiminto',
										A2(
											$author$project$Lib$Form$Form$mapValues,
											{
												a: F2(
													function (newValue, values) {
														return _Utils_update(
															values,
															{fh: newValue});
													}),
												mY: function ($) {
													return $.fh;
												}
											},
											A2(
												$author$project$Forms$CommonForms$convertFormToOptional,
												'Määritä elementin valinnan toiminto',
												$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config)))),
									A2(
										$author$project$Lib$Form$Form$append,
										contextMenusFormList,
										A2(
											$author$project$Lib$Form$Form$append,
											actionsFormList,
											A2(
												$author$project$Lib$Form$Form$append,
												A2(
													$author$project$Lib$Form$Form$mapValues,
													{
														a: F2(
															function (newValue, values) {
																return _Utils_update(
																	values,
																	{eT: newValue});
															}),
														mY: function ($) {
															return $.eT;
														}
													},
													$author$project$Forms$ClauseForms$makeClauseForm(
														A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, 'Datan lause', allConnections, allCommands))),
												$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$TreeViewCommandSpecification))))))))))));
};
var $author$project$Forms$CommandForms$TreeViewCommandForm$makeTreeViewCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecTreeViewCommand,
			$author$project$Forms$CommandForms$TreeViewCommandForm$makeTreeViewCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$UploadFilesCommandForm$makeUploadFilesCommandSpecForm = function (config) {
	var allConnections = config.eA;
	var allCommands = config.fU;
	var makeClauseFormConfig = function (title) {
		return A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, allConnections, allCommands);
	};
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (newValue, values) {
								return _Utils_update(
									values,
									{fu: newValue});
							}),
						mY: function ($) {
							return $.fu;
						}
					},
					A2(
						$author$project$Forms$ClauseForms$makeOptionalClauseForm,
						'Määritä tuloksen lause',
						makeClauseFormConfig('Tuloksen lause lause'))),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (newValue, values) {
									return _Utils_update(
										values,
										{lr: newValue});
								}),
							mY: function ($) {
								return $.lr;
							}
						},
						A2(
							$author$project$Forms$ClauseForms$makeOptionalClauseForm,
							'Määritä latauksen jälkeinen lause',
							makeClauseFormConfig('Latauksen jälkeinen lause'))),
					A2(
						$author$project$Lib$Form$Form$append,
						A2(
							$author$project$Lib$Form$Form$mapValues,
							{
								a: F2(
									function (newValue, values) {
										return _Utils_update(
											values,
											{mt: newValue});
									}),
								mY: function ($) {
									return $.mt;
								}
							},
							$author$project$Forms$ClauseForms$makeClauseForm(
								makeClauseFormConfig('Kohdekansion polun lause'))),
						A2(
							$author$project$Lib$Form$Form$append,
							$author$project$Lib$Form$Form$simpleTextField(
								{
									kv: 'Tiedostoparametrin nimi',
									a: F2(
										function (value, values) {
											return _Utils_update(
												values,
												{jJ: value});
										}),
									mY: function ($) {
										return $.jJ;
									}
								}),
							$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$UploadFilesCommandSpecification)))))));
};
var $author$project$Forms$CommandForms$UploadFilesCommandForm$makeUploadFilesCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecUploadFilesCommand,
			$author$project$Forms$CommandForms$UploadFilesCommandForm$makeUploadFilesCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$ViewComponentsCommandForm$makeViewComponentsCommandSpecForm = function (config) {
	var makeOptionalClauseForm_ = F2(
		function (checkboxTitle, title) {
			return A2(
				$author$project$Forms$ClauseForms$makeOptionalClauseForm,
				checkboxTitle,
				A3($author$project$Forms$ClauseForms$jsonClauseFormConfig, title, config.eA, config.fU));
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestForm(config),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommandForms$CommandCommonForms$makeCommandActionRequestClauseForm(config),
			A2(
				$author$project$Lib$Form$Form$append,
				A2(
					$author$project$Lib$Form$Form$mapValues,
					{
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{e2: value});
							}),
						mY: function ($) {
							return $.e2;
						}
					},
					A2(makeOptionalClauseForm_, 'Määritä oletusarvojen lause', 'Oletusarvojen lause')),
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (value, values) {
									return _Utils_update(
										values,
										{ir: value});
								}),
							mY: function ($) {
								return $.ir;
							}
						},
						$author$project$Forms$ViewComponentAndActionForms$makeViewComponentDefinitionsFormList(config)),
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Command$ViewComponentsCommandSpecification)))));
};
var $author$project$Forms$CommandForms$ViewComponentsCommandForm$makeViewComponentsCommandForm = function (config) {
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$map,
			$author$project$Entity$Command$SpecViewComponentsCommand,
			$author$project$Forms$CommandForms$ViewComponentsCommandForm$makeViewComponentsCommandSpecForm(config)),
		$author$project$Forms$CommandForms$CommandCommonForms$makeCommandCommonPropsForm(config));
};
var $author$project$Forms$CommandForms$CommandForm$makeCommandForm = function (config) {
	var mapValues = F2(
		function (values, updateMapper) {
			return $author$project$Lib$Form$Form$mapValues(
				{
					a: F2(
						function (value, _v1) {
							return updateMapper(value);
						}),
					mY: function (_v2) {
						return values;
					}
				});
		});
	return $author$project$Lib$Form$Form$meta(
		function (values) {
			switch (values.$) {
				case 0:
					var singleClauseFormValues = values.a;
					return A3(
						mapValues,
						singleClauseFormValues,
						$author$project$Forms$CommandForms$CommandForm$SingleClauseCommandForm,
						$author$project$Forms$CommandForms$SingleClauseCommandForm$makeSingleClauseCommandForm(config));
				case 1:
					var dataViewCommandFormValues = values.a;
					return A3(
						mapValues,
						dataViewCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$DataViewCommandForm,
						$author$project$Forms$CommandForms$DataViewCommandForm$makeDataViewCommandForm(config));
				case 2:
					var formCommandFormValues = values.a;
					return A3(
						mapValues,
						formCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$FormCommandForm,
						$author$project$Forms$CommandForms$FormCommandForm$makeFormCommandForm(config));
				case 3:
					var productionLineCommandFormValues = values.a;
					return A3(
						mapValues,
						productionLineCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$ProductionLineCommandForm,
						$author$project$Forms$CommandForms$ProductionLineCommandForm$makeProductionLineCommandForm(config));
				case 4:
					var mailCommandFormValues = values.a;
					return A3(
						mapValues,
						mailCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$MailCommandForm,
						$author$project$Forms$CommandForms$MailCommandForm$makeMailCommandForm(config));
				case 5:
					var notificationCommandFormValues = values.a;
					return A3(
						mapValues,
						notificationCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$NotificationCommandForm,
						$author$project$Forms$CommandForms$NotificationCommandForm$makeNotificationCommandForm(config));
				case 6:
					var downloadFileCommandFormValues = values.a;
					return A3(
						mapValues,
						downloadFileCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$DownloadFileCommandForm,
						$author$project$Forms$CommandForms$DownloadFileCommandForm$makeDownloadFileCommandForm(config));
				case 7:
					var uploadFilesCommandFormValues = values.a;
					return A3(
						mapValues,
						uploadFilesCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$UploadFilesCommandForm,
						$author$project$Forms$CommandForms$UploadFilesCommandForm$makeUploadFilesCommandForm(config));
				case 8:
					var reportCommandFormValues = values.a;
					return A3(
						mapValues,
						reportCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$ReportCommandForm,
						$author$project$Forms$CommandForms$ReportCommandForm$makeReportCommandForm(config));
				case 9:
					var chartCommandFormValues = values.a;
					return A3(
						mapValues,
						chartCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$ChartCommandForm,
						$author$project$Forms$CommandForms$ChartCommandForm$makeChartCommandForm(config));
				case 10:
					var diagramCommandFormValues = values.a;
					return A3(
						mapValues,
						diagramCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$DiagramCommandForm,
						$author$project$Forms$CommandForms$DiagramCommandForm$makeDiagramCommandForm(config));
				case 11:
					var treeViewCommandFormValues = values.a;
					return A3(
						mapValues,
						treeViewCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$TreeViewCommandForm,
						$author$project$Forms$CommandForms$TreeViewCommandForm$makeTreeViewCommandForm(config));
				case 12:
					var calendarViewCommandFormValues = values.a;
					return A3(
						mapValues,
						calendarViewCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$CalendarViewCommandForm,
						$author$project$Forms$CommandForms$CalendarViewCommandForm$makeCalendarViewCommandForm(config));
				case 13:
					var quickSightCommandFormValues = values.a;
					return A3(
						mapValues,
						quickSightCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$QuickSightCommandForm,
						$author$project$Forms$CommandForms$QuickSightCommandForm$makeQuickSightCommandForm(config));
				case 14:
					var cronCommandFormValues = values.a;
					return A3(
						mapValues,
						cronCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$CronCommandForm,
						$author$project$Forms$CommandForms$CronCommandForm$makeCronCommandForm(config));
				default:
					var viewComponentsCommandFormValues = values.a;
					return A3(
						mapValues,
						viewComponentsCommandFormValues,
						$author$project$Forms$CommandForms$CommandForm$ViewComponentsCommandForm,
						$author$project$Forms$CommandForms$ViewComponentsCommandForm$makeViewComponentsCommandForm(config));
			}
		});
};
var $author$project$Lib$Form$FormView$ValidateOnBlur = 1;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $author$project$Lib$Form$FormView$appendToId = F2(
	function (prefix, index) {
		return prefix + ('_' + $elm$core$String$fromInt(index));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Email = 8;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Input = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Type = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$create = F2(
	function (tipe, options) {
		return {
			aT: A2(
				$elm$core$List$cons,
				$rundis$elm_bootstrap$Bootstrap$Form$Input$Type(tipe),
				options)
		};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						g9: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						db: $elm$core$Maybe$Just(id_)
					});
			case 2:
				var tipe = modifier.a;
				return _Utils_update(
					options,
					{b3: tipe});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{i: val});
			case 4:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						mY: $elm$core$Maybe$Just(value_)
					});
			case 7:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						lm: $elm$core$Maybe$Just(value_)
					});
			case 5:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						dA: $elm$core$Maybe$Just(onInput_)
					});
			case 6:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						hq: $elm$core$Maybe$Just(validation_)
					});
			case 8:
				var val = modifier.a;
				return _Utils_update(
					options,
					{d_: val});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Text = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions = {ba: _List_Nil, i: false, db: $elm$core$Maybe$Nothing, dA: $elm$core$Maybe$Nothing, lm: $elm$core$Maybe$Nothing, d_: false, g9: $elm$core$Maybe$Nothing, b3: 0, hq: $elm$core$Maybe$Nothing, mY: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute = function (size) {
	return A2(
		$elm$core$Maybe$map,
		function (s) {
			return $elm$html$Html$Attributes$class('form-control-' + s);
		},
		$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute = function (inputType) {
	return $elm$html$Html$Attributes$type_(
		function () {
			switch (inputType) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 2:
					return 'datetime-local';
				case 3:
					return 'date';
				case 4:
					return 'month';
				case 5:
					return 'time';
				case 6:
					return 'week';
				case 7:
					return 'number';
				case 8:
					return 'email';
				case 9:
					return 'url';
				case 10:
					return 'search';
				case 11:
					return 'tel';
				default:
					return 'color';
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString = function (validation) {
	if (!validation) {
		return 'is-valid';
	} else {
		return 'is-invalid';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute = function (validation) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-control'),
				$elm$html$Html$Attributes$disabled(options.i),
				$elm$html$Html$Attributes$readonly(options.d_),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute(options.b3)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.db),
						A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute, options.g9),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$value, options.mY),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.lm),
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, options.dA),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute, options.hq)
					])),
			options.ba));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$view = function (_v0) {
	var options = _v0.aT;
	return A2(
		$elm$html$Html$input,
		$rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes(options),
		_List_Nil);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$input = F2(
	function (tipe, options) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Input$create, tipe, options));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$email = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(8);
var $author$project$Utils$MaybeUtils$fromBool = F2(
	function (valueIfTrue, bool) {
		return bool ? $elm$core$Maybe$Just(valueIfTrue) : $elm$core$Maybe$Nothing;
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Lib$Form$FormView$maybeIgnoreChildError = function (maybeParentError) {
	if (!maybeParentError.$) {
		return $elm$core$Basics$identity;
	} else {
		return $elm$core$Tuple$mapSecond(
			function (_v1) {
				return $elm$core$Maybe$Nothing;
			});
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Password = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$password = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(1);
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Attrs = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Off = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$On = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked = function (isCheck) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value(
		isCheck ? 0 : 1);
};
var $author$project$Lib$MiscUtils$consIf = F3(
	function (cond, item, list) {
		return cond ? A2($elm$core$List$cons, item, list) : list;
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Custom = {$: 4};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Checkbox = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create = F2(
	function (options, label) {
		return {kv: label, aT: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						db: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{K: val});
			case 2:
				return _Utils_update(
					options,
					{bC: true});
			case 3:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						dy: $elm$core$Maybe$Just(toMsg)
					});
			case 4:
				return _Utils_update(
					options,
					{t: true});
			case 5:
				var val = modifier.a;
				return _Utils_update(
					options,
					{i: val});
			case 6:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						hq: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions = {ba: _List_Nil, t: false, i: false, db: $elm$core$Maybe$Nothing, bC: false, dy: $elm$core$Maybe$Nothing, K: 1, hq: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $elm$html$Html$label = _VirtualDom_node('label');
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute = function (state) {
	switch (state) {
		case 0:
			return $elm$html$Html$Attributes$checked(true);
		case 1:
			return $elm$html$Html$Attributes$checked(false);
		default:
			return A2($elm$html$Html$Attributes$attribute, 'indeterminate', 'true');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.t),
						_Utils_Tuple2('custom-control-input', options.t)
					])),
				$elm$html$Html$Attributes$type_('checkbox'),
				$elm$html$Html$Attributes$disabled(options.i),
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute(options.K)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onCheck, options.dy),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.db)
					])),
			_Utils_ap(
				function () {
					var _v0 = options.hq;
					if (!_v0.$) {
						var v = _v0.a;
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$class(
								$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(v))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				options.ba)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view = function (_v0) {
	var chk = _v0;
	var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions, chk.aT);
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.t),
						_Utils_Tuple2('form-check-inline', (!opts.t) && opts.bC),
						_Utils_Tuple2('custom-control', opts.t),
						_Utils_Tuple2('custom-checkbox', opts.t),
						_Utils_Tuple2('custom-control-inline', opts.bC && opts.t)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes(opts),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-check-label', !opts.t),
									_Utils_Tuple2('custom-control-label', opts.t)
								]))
						]),
					function () {
						var _v1 = opts.db;
						if (!_v1.$) {
							var v = _v1.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$for(v)
								]);
						} else {
							return _List_Nil;
						}
					}()),
				_List_fromArray(
					[
						$elm$html$Html$text(chk.kv)
					]))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$custom = function (options) {
	return A2(
		$elm$core$Basics$composeL,
		$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view,
		$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create(
			A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Custom, options)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$FormInternal$Danger = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Validation = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$danger = $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Validation(1);
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Disabled = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Disabled(disabled_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Id = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id = function (theId) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Id(theId);
};
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked(toMsg);
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Lib$Form$FormView$withMaybeAttribute = F3(
	function (toAttribute, maybeValue, attrs) {
		return A2(
			$elm$core$Maybe$withDefault,
			attrs,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					toAttribute,
					function (attr) {
						return A2($elm$core$List$cons, attr, attrs);
					}),
				maybeValue));
	});
var $author$project$Lib$Form$FormView$renderCheckboxField = F2(
	function (_v0, _v1) {
		var onChange = _v0.x;
		var onFocus = _v0.S;
		var onBlur = _v0.I;
		var disabled = _v0.i;
		var value = _v0.mY;
		var error = _v0.al;
		var showError = _v0.G;
		var label = _v0.kv;
		var id = _v0.db;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$custom,
			A3(
				$author$project$Lib$MiscUtils$consIf,
				showError && $elm_community$maybe_extra$Maybe$Extra$isJust(error),
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$danger,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id(id),
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(onChange),
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(value),
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$disabled(disabled),
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$attrs(
						A3(
							$author$project$Lib$Form$FormView$withMaybeAttribute,
							$elm$html$Html$Events$onBlur,
							onBlur,
							_List_fromArray(
								[
									$elm$html$Html$Events$onFocus(onFocus)
								])))
					])),
			label);
	});
var $author$project$Lib$Form$FormView$renderConstantField = F2(
	function (value, _v0) {
		var label = _v0.kv;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('constant-field')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-weight-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label + ': ')
						])),
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled(disabled_);
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $author$project$Lib$Form$FormView$renderFormList = function (_v0) {
	var forms = _v0.gm;
	var label = _v0.kv;
	var add = _v0.fR;
	var disabled = _v0.i;
	var addButton = function () {
		if (!add.$) {
			var add_ = add.a;
			return A2(
				$elm$html$Html$map,
				function (f) {
					return f(0);
				},
				A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(add_.fO),
							$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-list-add-btn')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$i,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('fas fa-plus')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(add_.kv)
								]))
						])));
		} else {
			return $elm$html$Html$text('');
		}
	}();
	return A2(
		$elm$html$Html$fieldset,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-list')
			]),
		A2(
			$elm$core$List$cons,
			A2(
				$elm$html$Html$legend,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					])),
			_Utils_ap(
				forms,
				_List_fromArray(
					[addButton]))));
};
var $author$project$Lib$Form$Base$FormList$MoveDown = 1;
var $author$project$Lib$Form$Base$FormList$MoveUp = 0;
var $author$project$Lib$Form$FormView$renderFormListItem = function (_v0) {
	var fields = _v0.c;
	var _delete = _v0.f8;
	var disabled = _v0.i;
	var move = _v0.gF;
	var maybeActionButton = F2(
		function (iconName, maybeAction) {
			if (!maybeAction.$) {
				var action = maybeAction.a;
				return A2(
					$elm$html$Html$map,
					function (f) {
						return f(0);
					},
					A2(
						$rundis$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$onClick(action),
								$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled),
								$rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('form-list-action-btn')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$i,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('fas fa-' + iconName)
									]),
								_List_Nil)
							])));
			} else {
				return $elm$html$Html$text('');
			}
		});
	var moveDownButton = A2(
		maybeActionButton,
		'chevron-down',
		A2(
			$elm$core$Maybe$map,
			function (move_) {
				return $elm$core$Basics$always(
					move_(1));
			},
			move));
	var moveUpButton = A2(
		maybeActionButton,
		'chevron-up',
		A2(
			$elm$core$Maybe$map,
			function (move_) {
				return $elm$core$Basics$always(
					move_(0));
			},
			move));
	var deleteButton = A2(maybeActionButton, 'times', _delete);
	var buttons = A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-list-action-buttons')
			]),
		_List_fromArray(
			[moveUpButton, moveDownButton, deleteButton]));
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-list-item')
			]),
		A2($elm$core$List$cons, buttons, fields));
};
var $elm$html$Html$form = _VirtualDom_node('form');
var $rundis$elm_bootstrap$Bootstrap$Form$form = F2(
	function (attributes, children) {
		return A2($elm$html$Html$form, attributes, children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$formInline = function (attributes) {
	return $rundis$elm_bootstrap$Bootstrap$Form$form(
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('form-inline'),
			attributes));
};
var $author$project$Lib$Form$FormView$renderGroup = $rundis$elm_bootstrap$Bootstrap$Form$formInline(_List_Nil);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs = function (a) {
	return {$: 9, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs(attrs_);
};
var $author$project$Lib$Form$Base$TextField$autoCompleteToStr = function (autoComplete) {
	switch (autoComplete) {
		case 0:
			return 'username';
		case 1:
			return 'current-password';
		default:
			return 'new-password';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Validation = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$danger = $rundis$elm_bootstrap$Bootstrap$Form$Input$Validation(1);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Disabled(disabled_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Id = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$id = function (id_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Id(id_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$onInput = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder(value_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Value = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$value = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Value(value_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$applyModifier = F2(
	function (modifier, options) {
		var value = modifier;
		return _Utils_update(
			options,
			{
				ba: _Utils_ap(options.ba, value)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions = {ba: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Form$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		options.ba);
};
var $rundis$elm_bootstrap$Bootstrap$Form$group = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Form$toAttributes(options),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$label = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$label,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('form-control-label'),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$invalidFeedback = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('invalid-feedback'),
				attributes),
			children);
	});
var $author$project$Lib$Form$FormView$errorLabel = function (msg) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Form$invalidFeedback,
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(msg)
			]));
};
var $author$project$Lib$Form$FormView$errorToString = function (error) {
	if (!error.$) {
		return 'Vaaditaan';
	} else {
		var validationError = error.a;
		return validationError;
	}
};
var $author$project$Lib$Form$FormView$maybeErrorMessage = F2(
	function (showError, maybeError) {
		return showError ? A2(
			$elm$core$Maybe$withDefault,
			$elm$html$Html$text(''),
			A2(
				$elm$core$Maybe$map,
				$author$project$Lib$Form$FormView$errorLabel,
				A2($elm$core$Maybe$map, $author$project$Lib$Form$FormView$errorToString, maybeError))) : $elm$html$Html$text('');
	});
var $author$project$Lib$Form$FormView$withLabelAndError = F4(
	function (label, showError, error, fieldAsHtml) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					fieldAsHtml,
					A2($author$project$Lib$Form$FormView$maybeErrorMessage, showError, error)
				]));
	});
var $author$project$Lib$Form$FormView$renderInputField = F3(
	function (factory, _v0, _v1) {
		var onChange = _v0.x;
		var onFocus = _v0.S;
		var onBlur = _v0.I;
		var disabled = _v0.i;
		var value = _v0.mY;
		var error = _v0.al;
		var showError = _v0.G;
		var label = _v0.kv;
		var id = _v0.db;
		var placeholder = _v1.lm;
		var autoComplete = _v1.hS;
		return A4(
			$author$project$Lib$Form$FormView$withLabelAndError,
			label,
			showError,
			error,
			factory(
				A3(
					$author$project$Lib$MiscUtils$consIf,
					showError && $elm_community$maybe_extra$Maybe$Extra$isJust(error),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$danger,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(onChange),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$id(id),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(value),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							A3(
								$author$project$Lib$Form$FormView$withMaybeAttribute,
								$elm$html$Html$Attributes$attribute('autocomplete'),
								A2($elm$core$Maybe$map, $author$project$Lib$Form$Base$TextField$autoCompleteToStr, autoComplete),
								A3(
									$author$project$Lib$Form$FormView$withMaybeAttribute,
									$elm$html$Html$Events$onBlur,
									onBlur,
									_List_fromArray(
										[
											$elm$html$Html$Events$onFocus(onFocus)
										]))))
						]))));
	});
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Number = 7;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$number = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(7);
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Lib$Form$FormView$renderNumberField = F2(
	function (_v0, attributes) {
		var onChange = _v0.x;
		var onFocus = _v0.S;
		var onBlur = _v0.I;
		var disabled = _v0.i;
		var value = _v0.mY;
		var error = _v0.al;
		var showError = _v0.G;
		var label = _v0.kv;
		var id = _v0.db;
		var stepAttrValue = A2(
			$elm$core$Maybe$withDefault,
			'any',
			A2($elm$core$Maybe$map, $elm$core$String$fromFloat, attributes.mn));
		return A4(
			$author$project$Lib$Form$FormView$withLabelAndError,
			label,
			showError,
			error,
			$rundis$elm_bootstrap$Bootstrap$Form$Input$number(
				A3(
					$author$project$Lib$MiscUtils$consIf,
					showError && $elm_community$maybe_extra$Maybe$Extra$isJust(error),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$danger,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(onChange),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$id(id),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(value),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(attributes.lm),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							A3(
								$author$project$Lib$Form$FormView$withMaybeAttribute,
								A2($elm$core$Basics$composeR, $elm$core$String$fromFloat, $elm$html$Html$Attributes$min),
								attributes.kU,
								A3(
									$author$project$Lib$Form$FormView$withMaybeAttribute,
									A2($elm$core$Basics$composeR, $elm$core$String$fromFloat, $elm$html$Html$Attributes$max),
									attributes.kI,
									A3(
										$author$project$Lib$Form$FormView$withMaybeAttribute,
										$elm$html$Html$Events$onBlur,
										onBlur,
										_List_fromArray(
											[
												$elm$html$Html$Events$onFocus(onFocus),
												$elm$html$Html$Attributes$step(stepAttrValue)
											])))))
						]))));
	});
var $author$project$Lib$Form$FormView$renderSection = F2(
	function (title, fields) {
		return A2(
			$elm$html$Html$fieldset,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-fieldset')
				]),
			A2(
				$elm$core$List$cons,
				A2(
					$elm$html$Html$legend,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
				fields));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Custom = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Select = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Select$create = F2(
	function (options, items) {
		return {gx: items, aT: options};
	});
var $elm$html$Html$select = _VirtualDom_node('select');
var $rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						g9: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						db: $elm$core$Maybe$Just(id_)
					});
			case 2:
				return _Utils_update(
					options,
					{t: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{i: val});
			case 4:
				var onChange_ = modifier.a;
				return _Utils_update(
					options,
					{
						x: $elm$core$Maybe$Just(onChange_)
					});
			case 5:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						hq: $elm$core$Maybe$Just(validation_)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions = {ba: _List_Nil, t: false, i: false, db: $elm$core$Maybe$Nothing, x: $elm$core$Maybe$Nothing, g9: $elm$core$Maybe$Nothing, hq: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute = F2(
	function (isCustom, size_) {
		var prefix = isCustom ? 'custom-select-' : 'form-control-';
		return A2(
			$elm$core$Maybe$map,
			function (s) {
				return $elm$html$Html$Attributes$class(
					_Utils_ap(prefix, s));
			},
			$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size_));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute = function (validation_) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation_));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-control', !options.t),
						_Utils_Tuple2('custom-select', options.t)
					])),
				$elm$html$Html$Attributes$disabled(options.i)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.db),
						A2(
						$elm$core$Maybe$andThen,
						$rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute(options.t),
						options.g9),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange, options.x),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute, options.hq)
					])),
			options.ba));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$view = function (_v0) {
	var options = _v0.aT;
	var items = _v0.gx;
	return A2(
		$elm$html$Html$select,
		$rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes(options),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var e = _v1;
				return e;
			},
			items));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$custom = function (options) {
	return A2(
		$elm$core$Basics$composeL,
		$rundis$elm_bootstrap$Bootstrap$Form$Select$view,
		$rundis$elm_bootstrap$Bootstrap$Form$Select$create(
			A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Form$Select$Custom, options)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Validation = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$danger = $rundis$elm_bootstrap$Bootstrap$Form$Select$Validation(1);
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Disabled(disabled_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Id = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$id = function (id_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Id(id_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Item = $elm$core$Basics$identity;
var $elm$html$Html$option = _VirtualDom_node('option');
var $rundis$elm_bootstrap$Bootstrap$Form$Select$item = F2(
	function (attributes, children) {
		return A2($elm$html$Html$option, attributes, children);
	});
var $author$project$Lib$MiscUtils$maybeCons = F2(
	function (maybeItem, list) {
		if (!maybeItem.$) {
			var item = maybeItem.a;
			return A2($elm$core$List$cons, item, list);
		} else {
			return list;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$OnChange = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$onChange = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$OnChange(toMsg);
};
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$Lib$Form$FormView$renderSelectField = F2(
	function (_v0, _v1) {
		var onChange = _v0.x;
		var onFocus = _v0.S;
		var disabled = _v0.i;
		var value = _v0.mY;
		var error = _v0.al;
		var showError = _v0.G;
		var label = _v0.kv;
		var id = _v0.db;
		var options = _v1.aT;
		var placeholder = _v1.lm;
		var toOption = function (_v3) {
			var key = _v3.a;
			var label_ = _v3.b;
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(key),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(value, key))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label_)
					]));
		};
		var selectedOptionExists = A2(
			$elm$core$List$any,
			function (_v2) {
				var key = _v2.a;
				return _Utils_eq(key, value);
			},
			options);
		var placeholderOption = A2(
			$elm$core$Maybe$map,
			function (placeHolder) {
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$disabled(true),
							$elm$html$Html$Attributes$selected((value === '') || (!selectedOptionExists))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('-- ' + (placeHolder + ' --'))
						]));
			},
			placeholder);
		return A4(
			$author$project$Lib$Form$FormView$withLabelAndError,
			label,
			showError,
			error,
			A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Select$custom,
				A3(
					$author$project$Lib$MiscUtils$consIf,
					showError && $elm_community$maybe_extra$Maybe$Extra$isJust(error),
					$rundis$elm_bootstrap$Bootstrap$Form$Select$danger,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(onChange),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$id(id),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Events$onFocus(onFocus)
								]))
						])),
				A2(
					$author$project$Lib$MiscUtils$maybeCons,
					placeholderOption,
					A2($elm$core$List$map, toOption, options))));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Attrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Validation = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$danger = $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Validation(1);
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Disabled = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$disabled = $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Disabled;
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Id = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$id = function (id_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Id(id_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$OnInput = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$onInput = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Textarea$OnInput(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Textarea = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$create = function (options) {
	return {aT: options};
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						db: $elm$core$Maybe$Just(id_)
					});
			case 1:
				var rows_ = modifier.a;
				return _Utils_update(
					options,
					{
						d3: $elm$core$Maybe$Just(rows_)
					});
			case 2:
				return _Utils_update(
					options,
					{i: true});
			case 3:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						mY: $elm$core$Maybe$Just(value_)
					});
			case 4:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						dA: $elm$core$Maybe$Just(onInput_)
					});
			case 5:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						hq: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$defaultOptions = {ba: _List_Nil, i: false, db: $elm$core$Maybe$Nothing, dA: $elm$core$Maybe$Nothing, d3: $elm$core$Maybe$Nothing, hq: $elm$core$Maybe$Nothing, mY: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$rows = function (n) {
	return A2(
		_VirtualDom_attribute,
		'rows',
		$elm$core$String$fromInt(n));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$validationAttribute = function (validation) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Textarea$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Textarea$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-control'),
				$elm$html$Html$Attributes$disabled(options.i)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.db),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$rows, options.d3),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$value, options.mY),
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, options.dA),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Textarea$validationAttribute, options.hq)
					])),
			options.ba));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$view = function (_v0) {
	var options = _v0.aT;
	return A2(
		$elm$html$Html$textarea,
		$rundis$elm_bootstrap$Bootstrap$Form$Textarea$toAttributes(options),
		_List_Nil);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$textarea = A2($elm$core$Basics$composeL, $rundis$elm_bootstrap$Bootstrap$Form$Textarea$view, $rundis$elm_bootstrap$Bootstrap$Form$Textarea$create);
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Value = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Textarea$value = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Textarea$Value(value_);
};
var $author$project$Lib$Form$FormView$renderTextareaField = F2(
	function (_v0, _v1) {
		var onChange = _v0.x;
		var onFocus = _v0.S;
		var onBlur = _v0.I;
		var disabled = _v0.i;
		var value = _v0.mY;
		var error = _v0.al;
		var showError = _v0.G;
		var label = _v0.kv;
		var id = _v0.db;
		var placeholder = _v1.lm;
		return A4(
			$author$project$Lib$Form$FormView$withLabelAndError,
			label,
			showError,
			error,
			$rundis$elm_bootstrap$Bootstrap$Form$Textarea$textarea(
				A3(
					$author$project$Lib$MiscUtils$consIf,
					disabled,
					$rundis$elm_bootstrap$Bootstrap$Form$Textarea$disabled,
					A3(
						$author$project$Lib$MiscUtils$consIf,
						showError && $elm_community$maybe_extra$Maybe$Extra$isJust(error),
						$rundis$elm_bootstrap$Bootstrap$Form$Textarea$danger,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Form$Textarea$onInput(onChange),
								$rundis$elm_bootstrap$Bootstrap$Form$Textarea$id(id),
								$rundis$elm_bootstrap$Bootstrap$Form$Textarea$value(value),
								$rundis$elm_bootstrap$Bootstrap$Form$Textarea$attrs(
								A3(
									$author$project$Lib$Form$FormView$withMaybeAttribute,
									$elm$html$Html$Events$onBlur,
									onBlur,
									_List_fromArray(
										[
											$elm$html$Html$Events$onFocus(onFocus),
											$elm$html$Html$Attributes$placeholder(placeholder)
										])))
							])))));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Search = 10;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$search = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(10);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$text = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(0);
var $author$project$Lib$Form$FormView$renderField = F2(
	function (fieldConfig, _v0) {
		var onChange = fieldConfig.x;
		var setFocusedCodeField = fieldConfig.fA;
		var onBlur = fieldConfig.I;
		var disabled = fieldConfig.i;
		var showError = fieldConfig.G;
		var id = fieldConfig.db;
		var field = _v0.a;
		var maybeError = _v0.b;
		var blur = function (label) {
			return A2(
				$elm$core$Maybe$map,
				function (onBlurEvent) {
					return onBlurEvent(label);
				},
				onBlur);
		};
		var createFieldConfig = F3(
			function (update, label, value) {
				return {
					i: disabled,
					al: maybeError,
					db: id,
					kv: label,
					I: blur(label),
					x: A2($elm$core$Basics$composeR, update, onChange),
					S: setFocusedCodeField($elm$core$Maybe$Nothing),
					G: showError(label),
					mY: value
				};
			});
		switch (field.$) {
			case 0:
				var value = field.a.mY;
				var attributes = field.a.ba;
				return A2($author$project$Lib$Form$FormView$renderConstantField, value, attributes);
			case 1:
				var type_ = field.a;
				var update = field.b.a;
				var value = field.b.mY;
				var attributes = field.b.ba;
				var config = A3(createFieldConfig, update, attributes.kv, value);
				switch (type_) {
					case 0:
						return A3($author$project$Lib$Form$FormView$renderInputField, $rundis$elm_bootstrap$Bootstrap$Form$Input$text, config, attributes);
					case 3:
						return A2($author$project$Lib$Form$FormView$renderTextareaField, config, attributes);
					case 2:
						return A3($author$project$Lib$Form$FormView$renderInputField, $rundis$elm_bootstrap$Bootstrap$Form$Input$password, config, attributes);
					case 1:
						return A3($author$project$Lib$Form$FormView$renderInputField, $rundis$elm_bootstrap$Bootstrap$Form$Input$email, config, attributes);
					default:
						return A3($author$project$Lib$Form$FormView$renderInputField, $rundis$elm_bootstrap$Bootstrap$Form$Input$search, config, attributes);
				}
			case 2:
				var attributes = field.a.ba;
				var value = field.a.mY;
				var update = field.a.a;
				return A2(
					$author$project$Lib$Form$FormView$renderNumberField,
					A3(createFieldConfig, update, attributes.kv, value),
					attributes);
			case 3:
				var field_ = field.a;
				var attributes = field_.ba;
				var value = field_.mY;
				var update = field_.a;
				var textFieldAttrs = {hS: $elm$core$Maybe$Nothing, kv: attributes.kv, lm: attributes.lm};
				var baseConfig = A3(createFieldConfig, update, attributes.kv, value);
				var config = _Utils_update(
					baseConfig,
					{
						S: setFocusedCodeField(
							$elm$core$Maybe$Just(field_))
					});
				return A3($author$project$Lib$Form$FormView$renderInputField, $rundis$elm_bootstrap$Bootstrap$Form$Input$text, config, textFieldAttrs);
			case 4:
				var attributes = field.a.ba;
				var value = field.a.mY;
				var update = field.a.a;
				return A2(
					$author$project$Lib$Form$FormView$renderCheckboxField,
					A3(createFieldConfig, update, attributes.kv, value),
					attributes);
			case 5:
				var attributes = field.a.ba;
				var value = field.a.mY;
				var update = field.a.a;
				return A2(
					$author$project$Lib$Form$FormView$renderSelectField,
					A3(createFieldConfig, update, attributes.kv, value),
					attributes);
			case 6:
				var fields = field.a;
				return $author$project$Lib$Form$FormView$renderGroup(
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Lib$Form$FormView$maybeIgnoreChildError(maybeError),
							$author$project$Lib$Form$FormView$renderField(fieldConfig)),
						fields));
			case 7:
				var title = field.a;
				var fields = field.b;
				return A2(
					$author$project$Lib$Form$FormView$renderSection,
					title,
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (index, item) {
								var newFieldConfig = _Utils_update(
									fieldConfig,
									{
										db: id + ('_' + $elm$core$String$fromInt(index))
									});
								return A2(
									$elm$core$Basics$composeR,
									$author$project$Lib$Form$FormView$maybeIgnoreChildError(maybeError),
									$author$project$Lib$Form$FormView$renderField(newFieldConfig))(item);
							}),
						fields));
			default:
				var forms = field.a.gm;
				var add = field.a.fR;
				var attributes = field.a.ba;
				return $author$project$Lib$Form$FormView$renderFormList(
					{
						fR: A2(
							$elm$core$Maybe$map,
							function (addLabel) {
								return {
									fO: A2($elm$core$Basics$composeR, add, onChange),
									kv: addLabel
								};
							},
							attributes.hD),
						i: disabled,
						gm: A2(
							$elm$core$List$indexedMap,
							F2(
								function (listItemIndex, _v3) {
									var fields = _v3.c;
									var _delete = _v3.f8;
									var move = _v3.gF;
									var idPrefix = A2($author$project$Lib$Form$FormView$appendToId, id, listItemIndex);
									var fieldMapper = F2(
										function (index, field_) {
											return A2(
												$author$project$Lib$Form$FormView$renderField,
												_Utils_update(
													fieldConfig,
													{
														db: A2($author$project$Lib$Form$FormView$appendToId, idPrefix, index)
													}),
												field_);
										});
									return $author$project$Lib$Form$FormView$renderFormListItem(
										{
											f8: attributes.iZ ? $elm$core$Maybe$Just(
												A2($elm$core$Basics$composeR, _delete, onChange)) : $elm$core$Maybe$Nothing,
											i: disabled,
											c: A2($elm$core$List$indexedMap, fieldMapper, fields),
											gF: A2(
												$author$project$Utils$MaybeUtils$fromBool,
												A2($elm$core$Basics$composeR, move, onChange),
												attributes.ld)
										});
								}),
							forms),
						kv: attributes.kv
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignSelfStart = $elm$html$Html$Attributes$class('align-self-start');
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter = {$: 37};
var $Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent = F7(
	function (altKey, ctrlKey, key, keyCode, metaKey, repeat, shiftKey) {
		return {hK: altKey, iL: ctrlKey, kt: key, ku: keyCode, kP: metaKey, lD: repeat, ma: shiftKey};
	});
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKey = $elm$json$Json$Decode$maybe(
	A2(
		$elm$json$Json$Decode$andThen,
		function (key) {
			return $elm$core$String$isEmpty(key) ? $elm$json$Json$Decode$fail('empty key') : $elm$json$Json$Decode$succeed(key);
		},
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string)));
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero = A2(
	$elm$json$Json$Decode$andThen,
	function (code) {
		return (!code) ? $elm$json$Json$Decode$fail('code was zero') : $elm$json$Json$Decode$succeed(code);
	},
	$elm$json$Json$Decode$int);
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$field, 'keyCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'which', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			A2($elm$json$Json$Decode$field, 'charCode', $Gizra$elm_keyboard_event$Keyboard$Event$decodeNonZero),
			$elm$json$Json$Decode$succeed(0)
		]));
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$A = {$: 0};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add = {$: 85};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt = {$: 32};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous = function (a) {
	return {$: 89, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$B = {$: 1};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace = {$: 38};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$C = {$: 2};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock = {$: 34};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch = {$: 59};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command = {$: 58};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl = function (a) {
	return {$: 31, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$D = {$: 3};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal = {$: 87};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete = {$: 39};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide = {$: 88};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down = {$: 29};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$E = {$: 4};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight = {$: 52};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$End = {$: 42};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape = {$: 36};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F = {$: 5};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1 = {$: 62};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10 = {$: 71};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11 = {$: 72};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12 = {$: 73};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2 = {$: 63};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3 = {$: 64};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4 = {$: 65};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5 = {$: 66};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6 = {$: 67};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7 = {$: 68};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8 = {$: 69};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9 = {$: 70};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five = {$: 49};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four = {$: 48};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$G = {$: 6};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$H = {$: 7};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home = {$: 43};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$I = {$: 8};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert = {$: 54};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$J = {$: 9};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$K = {$: 10};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$L = {$: 11};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left = {$: 26};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$M = {$: 12};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply = {$: 84};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$N = {$: 13};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine = {$: 53};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock = {$: 60};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight = {$: 82};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive = {$: 79};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour = {$: 78};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine = {$: 83};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne = {$: 75};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven = {$: 81};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix = {$: 80};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree = {$: 77};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo = {$: 76};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero = {$: 74};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$O = {$: 14};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$One = {$: 45};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$P = {$: 15};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown = {$: 41};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp = {$: 40};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak = {$: 56};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen = {$: 55};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q = {$: 16};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$R = {$: 17};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right = {$: 27};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$S = {$: 18};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock = {$: 61};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven = {$: 51};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift = function (a) {
	return {$: 30, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six = {$: 50};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar = {$: 35};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract = {$: 86};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$T = {$: 19};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab = {$: 33};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three = {$: 47};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two = {$: 46};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$U = {$: 20};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown = function (a) {
	return {$: 90, a: a};
};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up = {$: 28};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$V = {$: 21};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$W = {$: 22};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows = {$: 57};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$X = {$: 23};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y = {$: 24};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z = {$: 25};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero = {$: 44};
var $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode = function (keyCode) {
	switch (keyCode) {
		case 8:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Backspace;
		case 9:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Tab;
		case 13:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter;
		case 16:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Shift($elm$core$Maybe$Nothing);
		case 17:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ctrl($elm$core$Maybe$Nothing);
		case 18:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Alt;
		case 19:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PauseBreak;
		case 20:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$CapsLock;
		case 27:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Escape;
		case 32:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Spacebar;
		case 33:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageUp;
		case 34:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PageDown;
		case 35:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$End;
		case 36:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Home;
		case 37:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Left;
		case 38:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Up;
		case 39:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Right;
		case 40:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Down;
		case 44:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$PrintScreen;
		case 45:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Insert;
		case 46:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Delete;
		case 48:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Zero;
		case 49:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$One;
		case 50:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Two;
		case 51:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Three;
		case 52:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Four;
		case 53:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Five;
		case 54:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Six;
		case 55:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Seven;
		case 56:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Eight;
		case 57:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Nine;
		case 65:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$A;
		case 66:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$B;
		case 67:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$C;
		case 68:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$D;
		case 69:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$E;
		case 70:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F;
		case 71:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$G;
		case 72:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$H;
		case 73:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$I;
		case 74:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$J;
		case 75:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$K;
		case 76:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$L;
		case 77:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$M;
		case 78:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$N;
		case 79:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$O;
		case 80:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$P;
		case 81:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Q;
		case 82:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$R;
		case 83:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$S;
		case 84:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$T;
		case 85:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$U;
		case 86:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$V;
		case 87:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$W;
		case 88:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$X;
		case 89:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Y;
		case 90:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Z;
		case 91:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Ambiguous(
				_List_fromArray(
					[$SwiftsNamesake$proper_keyboard$Keyboard$Key$Windows, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Command, $SwiftsNamesake$proper_keyboard$Keyboard$Key$ChromeSearch]));
		case 96:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadZero;
		case 97:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadOne;
		case 98:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadTwo;
		case 99:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadThree;
		case 100:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFour;
		case 101:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadFive;
		case 102:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSix;
		case 103:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadSeven;
		case 104:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadEight;
		case 105:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumpadNine;
		case 106:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Multiply;
		case 107:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Add;
		case 109:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Subtract;
		case 110:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Decimal;
		case 111:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Divide;
		case 112:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F1;
		case 113:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F2;
		case 114:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F3;
		case 115:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F4;
		case 116:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F5;
		case 117:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F6;
		case 118:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F7;
		case 119:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F8;
		case 120:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F9;
		case 121:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F10;
		case 122:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F11;
		case 123:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$F12;
		case 144:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$NumLock;
		case 145:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$ScrollLock;
		default:
			return $SwiftsNamesake$proper_keyboard$Keyboard$Key$Unknown(keyCode);
	}
};
var $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent = A8(
	$elm$json$Json$Decode$map7,
	$Gizra$elm_keyboard_event$Keyboard$Event$KeyboardEvent,
	A2($elm$json$Json$Decode$field, 'altKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
	$Gizra$elm_keyboard_event$Keyboard$Event$decodeKey,
	A2($elm$json$Json$Decode$map, $SwiftsNamesake$proper_keyboard$Keyboard$Key$fromCode, $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyCode),
	A2($elm$json$Json$Decode$field, 'metaKey', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'repeat', $elm$json$Json$Decode$bool),
	A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool));
var $author$project$Lib$Form$FormView$submitOnCtrlEnterAttr = function (onSubmit) {
	var isCtrlEnter = function (keyEvent) {
		return (_Utils_eq(keyEvent.ku, $SwiftsNamesake$proper_keyboard$Keyboard$Key$Enter) && keyEvent.iL) ? $elm$json$Json$Decode$succeed(onSubmit) : $elm$json$Json$Decode$fail('');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keyup',
		A2($elm$json$Json$Decode$andThen, isCtrlEnter, $Gizra$elm_keyboard_event$Keyboard$Event$decodeKeyboardEvent));
};
var $author$project$Lib$Form$FormView$renderForm = function (_v0) {
	var loadingLabel = _v0.bK;
	var onSubmit = _v0.fi;
	var action = _v0.fO;
	var state = _v0.K;
	var fields = _v0.c;
	var submitOnCtrlEnter = _v0.b$;
	var rootOnEnterAttrs = function () {
		var _v1 = _Utils_Tuple2(onSubmit, submitOnCtrlEnter);
		if ((!_v1.a.$) && _v1.b) {
			var onSubmit_ = _v1.a.a;
			return _List_fromArray(
				[
					$author$project$Lib$Form$FormView$submitOnCtrlEnterAttr(onSubmit_)
				]);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$html$Html$div,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-root')
				]),
			rootOnEnterAttrs),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-body')
					]),
				fields),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-footer')
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Button$button,
						A2(
							$author$project$Lib$MiscUtils$maybeCons,
							A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Button$onClick, onSubmit),
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Button$primary,
									$rundis$elm_bootstrap$Bootstrap$Button$attrs(
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignSelfStart,
											$elm$html$Html$Attributes$disabled(
											_Utils_eq(onSubmit, $elm$core$Maybe$Nothing) || _Utils_eq(state, $author$project$Lib$Form$LoadStatus$InProgress))
										]))
								])),
						_Utils_eq(state, $author$project$Lib$Form$LoadStatus$InProgress) ? _List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('spinner-border spinner-border-sm mr-2')
									]),
								_List_Nil),
								$elm$html$Html$text(loadingLabel)
							]) : _List_fromArray(
							[
								$elm$html$Html$text(action)
							]))
					]))
			]));
};
var $author$project$Lib$Form$FormView$asHtml = F3(
	function (_v0, form_, model) {
		var loadingLabel = _v0.bK;
		var onChange = _v0.x;
		var actionLabel = _v0.fQ;
		var validation = _v0.hq;
		var submitOnCtrlEnter = _v0.b$;
		var id = _v0.db;
		var errorTracking = function (_v4) {
			var e = _v4;
			return e;
		}(model.bu);
		var onBlur = function () {
			if (!validation) {
				return $elm$core$Maybe$Nothing;
			} else {
				return $elm$core$Maybe$Just(
					function (label) {
						return onChange(
							_Utils_update(
								model,
								{
									bu: _Utils_update(
										errorTracking,
										{
											eb: A2($elm$core$Set$insert, label, errorTracking.eb)
										})
								}));
					});
			}
		}();
		var showError = function (label) {
			return errorTracking.ea || A2($elm$core$Set$member, label, errorTracking.eb);
		};
		var fieldToElement = function (index) {
			return $author$project$Lib$Form$FormView$renderField(
				{
					i: _Utils_eq(model.K, $author$project$Lib$Form$LoadStatus$InProgress),
					db: id + ('_' + $elm$core$String$fromInt(index)),
					I: onBlur,
					x: function (values) {
						return onChange(
							_Utils_update(
								model,
								{hr: values}));
					},
					fA: function (maybeCodeField) {
						return onChange(
							_Utils_update(
								model,
								{am: maybeCodeField}));
					},
					G: showError
				});
		};
		var _v1 = A2($author$project$Lib$Form$Form$fill, form_, model.hr);
		var fields = _v1.c;
		var result = _v1.d;
		var onSubmit = function () {
			if (!result.$) {
				var msg = result.a;
				return _Utils_eq(model.K, $author$project$Lib$Form$LoadStatus$InProgress) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(msg);
			} else {
				return errorTracking.ea ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					onChange(
						_Utils_update(
							model,
							{
								bu: _Utils_update(
									errorTracking,
									{ea: true})
							})));
			}
		}();
		return $author$project$Lib$Form$FormView$renderForm(
			{
				fO: actionLabel,
				c: A2($elm$core$List$indexedMap, fieldToElement, fields),
				bK: loadingLabel,
				fi: onSubmit,
				K: model.K,
				b$: submitOnCtrlEnter
			});
	});
var $author$project$Pages$Main$FormViewHelpers$renderCommonForm = function (_v0) {
	var onChange = _v0.x;
	var id = _v0.db;
	var form = _v0.gk;
	var formModel = _v0.gl;
	return A3(
		$author$project$Lib$Form$FormView$asHtml,
		{fQ: 'Tallenna', db: id, bK: 'Tallennetaan', x: onChange, b$: true, hq: 1},
		form,
		formModel);
};
var $author$project$Lib$Form$FormView$getFocusedCodeFieldMode = function (model) {
	return A2(
		$elm$core$Maybe$map,
		function (f) {
			return f.ba.aP;
		},
		model.am);
};
var $author$project$Lib$Form$FormView$getFocusedCodeValueOrEmpty = function (model) {
	var _v0 = model.am;
	if (!_v0.$) {
		var codeField = _v0.a;
		return codeField.mY;
	} else {
		return '';
	}
};
var $author$project$Pages$Main$CodeField$getIndentWidth = function (mode) {
	if (mode === 6) {
		return 4;
	} else {
		return 2;
	}
};
var $author$project$Lib$CodeMirror$Attr = $elm$core$Basics$identity;
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$Lib$CodeMirror$hasFocus = function (v) {
	return A2(
		$elm$html$Html$Attributes$property,
		'hasFocus',
		$elm$json$Json$Encode$bool(v));
};
var $author$project$Lib$CodeMirror$indentWidth = function (value_) {
	return A2(
		$elm$html$Html$Attributes$property,
		'indentWidth',
		$elm$json$Json$Encode$int(value_));
};
var $author$project$Lib$CodeMirror$modeToStr = function (mode_) {
	switch (mode_) {
		case 0:
			return 'text/plain';
		case 6:
			return 'text/x-python';
		case 4:
			return 'application/json';
		case 5:
			return 'text/javascript';
		case 7:
			return 'text/x-mssql';
		case 8:
			return 'text/x-pgsql';
		case 1:
			return 'text/x-scss';
		case 2:
			return 'text/css';
		default:
			return 'text/html';
	}
};
var $author$project$Lib$CodeMirror$mode = function (value_) {
	return A2(
		$elm$html$Html$Attributes$property,
		'mode',
		$elm$json$Json$Encode$string(
			$author$project$Lib$CodeMirror$modeToStr(value_)));
};
var $author$project$Lib$CodeMirror$onChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'code_change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $author$project$Lib$CodeMirror$readOnly = function (v) {
	return A2(
		$elm$html$Html$Attributes$property,
		'readOnly',
		$elm$json$Json$Encode$bool(v));
};
var $author$project$Lib$CodeMirror$value = A2(
	$elm$core$Basics$composeR,
	$elm$json$Json$Encode$string,
	A2(
		$elm$core$Basics$composeR,
		$elm$html$Html$Attributes$property('value'),
		$elm$core$Basics$identity));
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Lib$CodeMirror$unattr = function (_v0) {
	var a = _v0;
	return a;
};
var $author$project$Lib$CodeMirror$view = function (attributes) {
	return A3(
		$elm$html$Html$node,
		'code-mirror',
		A2($elm$core$List$map, $author$project$Lib$CodeMirror$unattr, attributes),
		_List_Nil);
};
var $author$project$Pages$Main$CodeField$viewCodeEditorForFormCodeField = F2(
	function (formModel, onChange) {
		var mode = A2(
			$elm$core$Maybe$withDefault,
			0,
			$author$project$Lib$Form$FormView$getFocusedCodeFieldMode(formModel));
		return $author$project$Lib$CodeMirror$view(
			_List_fromArray(
				[
					$author$project$Lib$CodeMirror$onChange(onChange),
					$author$project$Lib$CodeMirror$value(
					$author$project$Lib$Form$FormView$getFocusedCodeValueOrEmpty(formModel)),
					$author$project$Lib$CodeMirror$mode(mode),
					$author$project$Lib$CodeMirror$indentWidth(
					$author$project$Pages$Main$CodeField$getIndentWidth(mode)),
					$author$project$Lib$CodeMirror$readOnly(
					_Utils_eq(formModel.am, $elm$core$Maybe$Nothing)),
					$author$project$Lib$CodeMirror$hasFocus(
					!_Utils_eq(formModel.am, $elm$core$Maybe$Nothing))
				]));
	});
var $author$project$Pages$Main$CommandsView$renderEditCommandForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$CommandsView$EditFormBackButtonClicked,
			'Muokkaa komentoa',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('full-height-container flex-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-1')
								]),
							_List_fromArray(
								[
									$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
									{
										gk: A2(
											$author$project$Lib$Form$Form$map,
											$author$project$Pages$Main$CommandsView$BeginSaveCommand,
											$author$project$Forms$CommandForms$CommandForm$makeCommandForm(config)),
										gl: formModel,
										db: 'edit_command',
										x: $author$project$Pages$Main$CommandsView$CommandFormChanged
									})
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-2 overflow-hidden')
								]),
							_List_fromArray(
								[
									A2($author$project$Pages$Main$CodeField$viewCodeEditorForFormCodeField, formModel, $author$project$Pages$Main$CommandsView$CodeEditorValueChanged)
								]))
						]))
				]));
	});
var $author$project$Pages$Main$CommandsView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Forms$CommonForms$nameField = $author$project$Lib$Form$Form$textField(
	{
		ba: {hS: $elm$core$Maybe$Nothing, kv: 'Nimi', lm: ''},
		U: $elm$core$Result$Ok,
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{k_: value});
			}),
		mY: function ($) {
			return $.k_;
		}
	});
var $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm = A2(
	$author$project$Lib$Form$Form$append,
	$author$project$Forms$CommonForms$constantMetaField(
		function ($) {
			return $.fk;
		}),
	A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommonForms$descriptionField,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommonForms$nameField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommonForms$aproProjectIdField,
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$CommonForms$constantMetaField(
						function ($) {
							return $.dM;
						}),
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$CommonForms$idField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$GenericEntityGroup$GenericEntityGroup)))))));
var $author$project$Pages$Main$CommandsView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$CommandsView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$CommandsView$BeginSaveGroup, $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm),
					gl: formModel,
					db: 'edit_command_group',
					x: $author$project$Pages$Main$CommandsView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$CommandsView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$CommandsView$renderEditCommandForm(formModel),
					A4($author$project$Lib$RemoteData$map3, $author$project$Forms$CommandForms$CommandFormConfig$CommandFormConfig, repository.iy, repository.O, repository.m$));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$CommandsView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewEntity = {$: 6};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewGroup = {$: 7};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditElement = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginRefresh = {$: 3};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$ConfirmDeleteElement = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$FormViewHelpers$entityActionButton = F3(
	function (icon, options, innerHtml) {
		return A3(
			$author$project$Utils$CommonView$iconButton,
			icon,
			A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Button$primary, options),
			innerHtml);
	});
var $author$project$Utils$Visibility$isHidden = function (visibility) {
	if (visibility === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Lib$RemoteData$map2 = F3(
	function (f, a, b) {
		return A2(
			$author$project$Lib$RemoteData$andMap,
			b,
			A2($author$project$Lib$RemoteData$map, f, a));
	});
var $author$project$Pages$Main$EntityListing$TreeView$LeafElement = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EntityListing$TreeView$NodeElement = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$TreeViewStateChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EntityListing$TreeElement$GroupTreeElement = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pages$Main$EntityListing$TreeElement$getParentGroupOfElement = F2(
	function (allGroups, element) {
		var getGroupById = function (id) {
			return A2(
				$elm_community$list_extra$List$Extra$find,
				function (g) {
					return _Utils_eq(g.db, id);
				},
				allGroups);
		};
		return A2(
			$elm$core$Maybe$andThen,
			getGroupById,
			$author$project$Pages$Main$EntityListing$TreeElement$getParentIdOfElement(element));
	});
var $author$project$Pages$Main$EntityListing$TreeElement$getAllAncestors = F2(
	function (allGroups, element) {
		var _v0 = A2($author$project$Pages$Main$EntityListing$TreeElement$getParentGroupOfElement, allGroups, element);
		if (_v0.$ === 1) {
			return _List_Nil;
		} else {
			var parentGroup = _v0.a;
			return A2(
				$elm$core$List$cons,
				parentGroup,
				A2(
					$author$project$Pages$Main$EntityListing$TreeElement$getAllAncestors,
					allGroups,
					$author$project$Pages$Main$EntityListing$TreeElement$GroupTreeElement(parentGroup)));
		}
	});
var $author$project$Pages$Main$EntityListing$TreeElement$getIdOfElement = function (element) {
	if (!element.$) {
		var entity = element.a;
		return entity.db;
	} else {
		var group = element.a;
		return group.db;
	}
};
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Pages$Main$EntityListing$TreeElement$getAllDescendants = F2(
	function (allElements, element) {
		var _v0 = A2(
			$elm$core$List$partition,
			function (el) {
				return _Utils_eq(
					$author$project$Pages$Main$EntityListing$TreeElement$getParentIdOfElement(el),
					$elm$core$Maybe$Just(
						$author$project$Pages$Main$EntityListing$TreeElement$getIdOfElement(element)));
			},
			allElements);
		var directChildren = _v0.a;
		var others = _v0.b;
		return _Utils_ap(
			directChildren,
			A2(
				$elm$core$List$concatMap,
				$author$project$Pages$Main$EntityListing$TreeElement$getAllDescendants(others),
				directChildren));
	});
var $author$project$Pages$Main$EntityListing$TreeElement$getGroupElements = $elm$core$List$filterMap(
	function (el) {
		if (el.$ === 1) {
			var group = el.a;
			return $elm$core$Maybe$Just(group);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $author$project$Pages$Main$EntityListing$EntityListingFilters$applyNonEmptyFilter = F3(
	function (filter, filterValue, allElements) {
		var directResults = A2(
			$elm$core$List$filter,
			function (treeElement) {
				if (!treeElement.$) {
					var entity = treeElement.a;
					return A2(filter.cZ, filterValue, entity);
				} else {
					var group = treeElement.a;
					return A2(filter.c_, filterValue, group);
				}
			},
			allElements);
		var descendants = A2(
			$elm$core$List$concatMap,
			$author$project$Pages$Main$EntityListing$TreeElement$getAllDescendants(allElements),
			directResults);
		var allGroups = $author$project$Pages$Main$EntityListing$TreeElement$getGroupElements(allElements);
		var ancestors = A2(
			$elm$core$List$map,
			$author$project$Pages$Main$EntityListing$TreeElement$GroupTreeElement,
			A2(
				$elm$core$List$concatMap,
				$author$project$Pages$Main$EntityListing$TreeElement$getAllAncestors(allGroups),
				directResults));
		return A2(
			$elm_community$list_extra$List$Extra$uniqueBy,
			$author$project$Pages$Main$EntityListing$TreeElement$getIdOfElement,
			_Utils_ap(
				directResults,
				_Utils_ap(ancestors, descendants)));
	});
var $author$project$Pages$Main$EntityListing$EntityListingFilters$applyFilter = F3(
	function (filter, filterValue, allElements) {
		return $elm$core$String$isEmpty(filterValue) ? allElements : A3($author$project$Pages$Main$EntityListing$EntityListingFilters$applyNonEmptyFilter, filter, filterValue, allElements);
	});
var $author$project$Pages$Main$EntityListing$EntityListingFilters$applyFilters = F3(
	function (filters, filterValues, allElements) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (filter, acc) {
					var _v0 = A2($elm$core$Dict$get, filter.db, filterValues);
					if (!_v0.$) {
						var filterValue = _v0.a;
						return A3($author$project$Pages$Main$EntityListing$EntityListingFilters$applyFilter, filter, filterValue, acc);
					} else {
						return acc;
					}
				}),
			allElements,
			filters);
	});
var $author$project$Pages$Main$EntityListing$TreeElement$EntityTreeElement = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pages$Main$EntityListing$TreeElement$mapEntitiesAndGroupsToTreeElements = F2(
	function (entities, groups) {
		return _Utils_ap(
			A2($elm$core$List$map, $author$project$Pages$Main$EntityListing$TreeElement$EntityTreeElement, entities),
			A2($elm$core$List$map, $author$project$Pages$Main$EntityListing$TreeElement$GroupTreeElement, groups));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$DragDropMsg = function (a) {
	return {$: 9, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$SelectTreeElement = function (a) {
	return {$: 8, a: a};
};
var $author$project$Lib$DragDrop$DragEnd = {$: 1};
var $author$project$Lib$DragDrop$DragStart = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $elm$html$Html$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $author$project$Lib$DragDrop$onWithOptions = F3(
	function (name, _v0, decoder) {
		var stopPropagation = _v0.W;
		var preventDefault = _v0.V;
		return A2(
			$elm$html$Html$Events$custom,
			name,
			A2(
				$elm$json$Json$Decode$map,
				function (msg) {
					return {gD: msg, V: preventDefault, W: stopPropagation};
				},
				decoder));
	});
var $author$project$Lib$DragDrop$draggable = F2(
	function (wrap, drag) {
		return _List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'draggable', 'true'),
				A3(
				$author$project$Lib$DragDrop$onWithOptions,
				'dragstart',
				{V: false, W: true},
				A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						wrap,
						$author$project$Lib$DragDrop$DragStart(drag)),
					$elm$json$Json$Decode$value)),
				A3(
				$author$project$Lib$DragDrop$onWithOptions,
				'dragend',
				{V: false, W: true},
				$elm$json$Json$Decode$succeed(
					wrap($author$project$Lib$DragDrop$DragEnd)))
			]);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$DropAreaConfig = F2(
	function (droppableAttrs, isActiveDropArea) {
		return {cW: droppableAttrs, dg: isActiveDropArea};
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$GroupDropArea = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$dropIdToMaybeGroup = function (dropId) {
	if (dropId.$ === 2) {
		var group = dropId.a;
		return $elm$core$Maybe$Just(group);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Lib$DragDrop$DragEnter = function (a) {
	return {$: 2, a: a};
};
var $author$project$Lib$DragDrop$DragLeave = function (a) {
	return {$: 3, a: a};
};
var $author$project$Lib$DragDrop$DragOver = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $author$project$Lib$DragDrop$Drop = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Lib$DragDrop$Position = F4(
	function (width, height, x, y) {
		return {j0: height, m0: width, m2: x, m3: y};
	});
var $author$project$Lib$DragDrop$positionDecoder = A5(
	$elm$json$Json$Decode$map4,
	$author$project$Lib$DragDrop$Position,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['currentTarget', 'clientWidth']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['currentTarget', 'clientHeight']),
		$elm$json$Json$Decode$int),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$round,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['offsetX']),
			$elm$json$Json$Decode$float)),
	A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$round,
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['offsetY']),
			$elm$json$Json$Decode$float)));
var $author$project$Lib$DragDrop$timeStampDecoder = A2(
	$elm$json$Json$Decode$map,
	$elm$core$Basics$round,
	A2(
		$elm$json$Json$Decode$at,
		_List_fromArray(
			['timeStamp']),
		$elm$json$Json$Decode$float));
var $author$project$Lib$DragDrop$droppable = F2(
	function (wrap, dropId) {
		return _List_fromArray(
			[
				A3(
				$author$project$Lib$DragDrop$onWithOptions,
				'dragenter',
				{V: true, W: true},
				$elm$json$Json$Decode$succeed(
					wrap(
						$author$project$Lib$DragDrop$DragEnter(dropId)))),
				A3(
				$author$project$Lib$DragDrop$onWithOptions,
				'dragleave',
				{V: true, W: true},
				$elm$json$Json$Decode$succeed(
					wrap(
						$author$project$Lib$DragDrop$DragLeave(dropId)))),
				A3(
				$author$project$Lib$DragDrop$onWithOptions,
				'dragover',
				{V: true, W: true},
				A2(
					$elm$json$Json$Decode$map,
					wrap,
					A3(
						$elm$json$Json$Decode$map2,
						$author$project$Lib$DragDrop$DragOver(dropId),
						$author$project$Lib$DragDrop$timeStampDecoder,
						$author$project$Lib$DragDrop$positionDecoder))),
				A3(
				$author$project$Lib$DragDrop$onWithOptions,
				'drop',
				{V: true, W: true},
				A2(
					$elm$json$Json$Decode$map,
					A2(
						$elm$core$Basics$composeL,
						wrap,
						$author$project$Lib$DragDrop$Drop(dropId)),
					$author$project$Lib$DragDrop$positionDecoder))
			]);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$NotInDropArea = {$: 0};
var $author$project$Lib$DragDrop$getDropId = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var dragId = model.a;
			return $elm$core$Maybe$Nothing;
		default:
			var dragId = model.a;
			var dropId = model.b;
			return $elm$core$Maybe$Just(dropId);
	}
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$getActiveDropId = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Pages$Main$EntityListing$GenericEntityListingView$NotInDropArea,
		$author$project$Lib$DragDrop$getDropId(model.ag));
};
var $author$project$Lib$DragDrop$getDragId = function (model) {
	switch (model.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var dragId = model.a;
			return $elm$core$Maybe$Just(dragId);
		default:
			var dragId = model.a;
			var dropId = model.b;
			return $elm$core$Maybe$Just(dragId);
	}
};
var $author$project$Lib$RemoteData$toMaybe = A2(
	$elm$core$Basics$composeR,
	$author$project$Lib$RemoteData$map($elm$core$Maybe$Just),
	$author$project$Lib$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$isAncestorOfGroup = F4(
	function (config, repository, group, possibleAncestor) {
		var _v0 = group.fk;
		if (_v0.$ === 1) {
			return false;
		} else {
			var actualParentId = _v0.a;
			var maybeActualParent = A2(
				$elm$core$Maybe$andThen,
				$elm$core$Dict$get(actualParentId),
				$author$project$Lib$RemoteData$toMaybe(
					config.gp(repository)));
			if (maybeActualParent.$ === 1) {
				return false;
			} else {
				var parent = maybeActualParent.a;
				return A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$isAncestorOfGroupOrSelf, config, repository, parent, possibleAncestor);
			}
		}
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$isAncestorOfGroupOrSelf = F4(
	function (config, repository, group, possibleAncestor) {
		return _Utils_eq(group.db, possibleAncestor.db) ? true : A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$isAncestorOfGroup, config, repository, group, possibleAncestor);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$groupIsCurrentlyDragged = F4(
	function (config, model, repository, group) {
		var currentlyDraggedGroup = function () {
			var _v1 = $author$project$Lib$DragDrop$getDragId(model.ag);
			if ((!_v1.$) && (_v1.a.$ === 1)) {
				var group_ = _v1.a.a;
				return $elm$core$Maybe$Just(group_);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		if (currentlyDraggedGroup.$ === 1) {
			return false;
		} else {
			var draggedGroup = currentlyDraggedGroup.a;
			return A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$isAncestorOfGroupOrSelf, config, repository, group, draggedGroup);
		}
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$getGroupElementDropAreaConfig = F4(
	function (config, model, repository, group) {
		var groupIsCurrentlyDragged_ = A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$groupIsCurrentlyDragged, config, model, repository, group);
		var isActiveDropArea = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (activeDropAreaGroup) {
					return _Utils_eq(activeDropAreaGroup, group) && (!groupIsCurrentlyDragged_);
				},
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$dropIdToMaybeGroup(
					$author$project$Pages$Main$EntityListing$GenericEntityListingView$getActiveDropId(model))));
		var droppableAttrs = (!groupIsCurrentlyDragged_) ? A2(
			$author$project$Lib$DragDrop$droppable,
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$DragDropMsg,
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$GroupDropArea(group)) : _List_Nil;
		return A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$DropAreaConfig, droppableAttrs, isActiveDropArea);
	});
var $elm$html$Html$Events$onDoubleClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'dblclick',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$ElementSpanDropArea = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$elementsEquals = F2(
	function (a, b) {
		var _v0 = _Utils_Tuple2(a, b);
		_v0$2:
		while (true) {
			if (!_v0.a.$) {
				if (!_v0.b.$) {
					var a_ = _v0.a.a;
					var b_ = _v0.b.a;
					return _Utils_eq(a_.db, b_.db);
				} else {
					break _v0$2;
				}
			} else {
				if (_v0.b.$ === 1) {
					var a_ = _v0.a.a;
					var b_ = _v0.b.a;
					return _Utils_eq(a_.db, b_.db);
				} else {
					break _v0$2;
				}
			}
		}
		return false;
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$getElementSpanDropAreaConfig = F2(
	function (model, targetLowerElement) {
		var isActiveDropArea = function () {
			var _v0 = $author$project$Pages$Main$EntityListing$GenericEntityListingView$getActiveDropId(model);
			if (_v0.$ === 3) {
				var activeDropArea = _v0.a;
				return A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$elementsEquals, targetLowerElement, activeDropArea.$7);
			} else {
				return false;
			}
		}();
		var droppableAttrs = A2(
			$author$project$Lib$DragDrop$droppable,
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$DragDropMsg,
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$ElementSpanDropArea(
				{$7: targetLowerElement}));
		return A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$DropAreaConfig, droppableAttrs, isActiveDropArea);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderElementSpanDropArea = F2(
	function (model, lowerElement) {
		var dropAreaConfig = A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$getElementSpanDropAreaConfig, model, lowerElement);
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('tree-view__element-span-drop-area'),
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('active-drop-area', dropAreaConfig.dg)
							])),
					dropAreaConfig.cW)),
			_List_Nil);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeElement = F5(
	function (config, model, repository, element, columns) {
		var isSelected = _Utils_eq(
			model.J,
			$elm$core$Maybe$Just(element));
		var elementDropAreaConfig = function () {
			if (!element.$) {
				return {cW: _List_Nil, dg: false};
			} else {
				var group = element.a;
				return A4($author$project$Pages$Main$EntityListing$GenericEntityListingView$getGroupElementDropAreaConfig, config, model, repository, group);
			}
		}();
		var backgroundColorClass = function () {
			if (!element.$) {
				return 'tree-view__element--entity';
			} else {
				return 'tree-view__element--group';
			}
		}();
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree-view__element-content-wrapper')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$renderElementSpanDropArea, model, element),
					A2(
					$elm$html$Html$div,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$author$project$Pages$Main$EntityListing$GenericEntityListingView$SelectTreeElement(element)),
								$elm$html$Html$Events$onDoubleClick(
								$author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditElement(element)),
								$elm$html$Html$Attributes$class('tree-view__element'),
								$elm$html$Html$Attributes$class(backgroundColorClass),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('active-drop-area', elementDropAreaConfig.dg),
										_Utils_Tuple2('tree-view__element--selected', isSelected)
									]))
							]),
						_Utils_ap(
							A2($author$project$Lib$DragDrop$draggable, $author$project$Pages$Main$EntityListing$GenericEntityListingView$DragDropMsg, element),
							elementDropAreaConfig.cW)),
					columns)
				]));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeElementColumns = $elm$core$List$map(
	function (content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree-element__col')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(content)
				]));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeViewEntityElementContent = F4(
	function (config, model, repository, entity) {
		return A5(
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeElement,
			config,
			model,
			repository,
			$author$project$Pages$Main$EntityListing$TreeElement$EntityTreeElement(entity),
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeElementColumns(
				config.jT(entity)));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeViewGroupElementContent = F4(
	function (config, model, repository, group) {
		return A5(
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeElement,
			config,
			model,
			repository,
			$author$project$Pages$Main$EntityListing$TreeElement$GroupTreeElement(group),
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeElementColumns(
				config.jU(group)));
	});
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$Pages$Main$EntityListing$TreeView$getElementParentKey = F2(
	function (config, el) {
		if (!el.$) {
			var node = el.a;
			return config.jY(node);
		} else {
			var leaf = el.a;
			return config.jW(leaf);
		}
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$Keyed$ol = $elm$html$Html$Keyed$node('ol');
var $elm$virtual_dom$VirtualDom$lazy = _VirtualDom_lazy;
var $elm$html$Html$Lazy$lazy = $elm$virtual_dom$VirtualDom$lazy;
var $elm$html$Html$li = _VirtualDom_node('li');
var $author$project$Pages$Main$EntityListing$TreeView$renderIndents = function (depth) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tree-view__indent')
					]),
				_List_Nil);
		},
		A2($elm$core$List$range, 0, depth - 1));
};
var $author$project$Pages$Main$EntityListing$TreeView$renderElementWithIndents = F4(
	function (depth, attrs, htmlKey, content) {
		return _Utils_Tuple2(
			htmlKey,
			A2(
				$elm$html$Html$Lazy$lazy,
				function (content_) {
					return A2(
						$elm$html$Html$li,
						A2(
							$elm$core$List$cons,
							$elm$html$Html$Attributes$class('tree-view__element-wrapper'),
							attrs),
						_Utils_ap(
							$author$project$Pages$Main$EntityListing$TreeView$renderIndents(depth),
							_List_fromArray(
								[content_])));
				},
				content));
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$Pages$Main$EntityListing$TreeView$toggleNode = F3(
	function (config, state, key) {
		var isOpen = A2($elm$core$Set$member, key, state.T);
		var openNodes = isOpen ? A2($elm$core$Set$remove, key, state.T) : A2($elm$core$Set$insert, key, state.T);
		var newState = _Utils_update(
			state,
			{T: openNodes});
		return config.a(newState);
	});
var $author$project$Pages$Main$EntityListing$TreeView$viewLeaf = F3(
	function (config, depth, leaf) {
		return A4(
			$author$project$Pages$Main$EntityListing$TreeView$renderElementWithIndents,
			depth + 1,
			config.kx(leaf),
			'l_' + $elm$core$String$fromInt(
				config.jV(leaf)),
			config.lA(leaf));
	});
var $author$project$Pages$Main$EntityListing$TreeView$viewElement = F5(
	function (config, state, allElements, depth, element) {
		if (!element.$) {
			var node = element.a;
			return A5($author$project$Pages$Main$EntityListing$TreeView$viewNode, config, state, allElements, depth, node);
		} else {
			var leaf = element.a;
			return _List_fromArray(
				[
					A3($author$project$Pages$Main$EntityListing$TreeView$viewLeaf, config, depth, leaf)
				]);
		}
	});
var $author$project$Pages$Main$EntityListing$TreeView$viewNode = F5(
	function (config, state, allElements, depth, node) {
		var nodeKey = config.jX(node);
		var renderToggleIcon = F2(
			function (iconName, isVisible) {
				return A2(
					$author$project$Utils$CommonView$icon,
					iconName,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('icon-wrapper'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('d-none', !isVisible)
								])),
							$elm$html$Html$Events$onClick(
							A3($author$project$Pages$Main$EntityListing$TreeView$toggleNode, config, state, nodeKey))
						]));
			});
		var isOpen = A2($elm$core$Set$member, nodeKey, state.T);
		var children = isOpen ? A5($author$project$Pages$Main$EntityListing$TreeView$viewNodeChildren, config, state, allElements, depth, nodeKey) : _List_Nil;
		return A2(
			$elm$core$List$cons,
			A4(
				$author$project$Pages$Main$EntityListing$TreeView$renderElementWithIndents,
				depth,
				_List_Nil,
				'n_' + $elm$core$String$fromInt(
					config.jX(node)),
				A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('tree-view__node')
						]),
					_List_fromArray(
						[
							A2(renderToggleIcon, 'chevron-up', isOpen),
							A2(renderToggleIcon, 'chevron-down', !isOpen),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('content-wrapper')
								]),
							_List_fromArray(
								[
									config.lC(node)
								]))
						]))),
			children);
	});
var $author$project$Pages$Main$EntityListing$TreeView$viewNodeChildren = F5(
	function (config, state, allElements, parentDepth, nodeKey) {
		var childElements = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pages$Main$EntityListing$TreeView$getElementParentKey(config),
				$elm$core$Basics$eq(
					$elm$core$Maybe$Just(nodeKey))),
			allElements);
		var childDepth = parentDepth + 1;
		return A2(
			$elm$core$List$concatMap,
			A4($author$project$Pages$Main$EntityListing$TreeView$viewElement, config, state, allElements, childDepth),
			childElements);
	});
var $author$project$Pages$Main$EntityListing$TreeView$view = F3(
	function (config, _v0, allElements) {
		var state = _v0;
		var rootElements = A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pages$Main$EntityListing$TreeView$getElementParentKey(config),
				$elm$core$Basics$eq($elm$core$Maybe$Nothing)),
			allElements);
		var rootDepth = 0;
		return A2(
			$elm$html$Html$Keyed$ol,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree-view__root')
				]),
			A2(
				$elm$core$List$concatMap,
				A4($author$project$Pages$Main$EntityListing$TreeView$viewElement, config, state, allElements, rootDepth),
				rootElements));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderEntityTree = F5(
	function (config, model, repository, allEntities, allGroups) {
		var treeElementToTreeViewElement = function (el) {
			if (!el.$) {
				var e = el.a;
				return $author$project$Pages$Main$EntityListing$TreeView$LeafElement(e);
			} else {
				var g = el.a;
				return $author$project$Pages$Main$EntityListing$TreeView$NodeElement(g);
			}
		};
		var treeViewElements = A2(
			$elm$core$List$map,
			treeElementToTreeViewElement,
			A2(
				$elm$core$List$sortBy,
				$author$project$Pages$Main$EntityListing$TreeElement$getPositionOfElement,
				A3(
					$author$project$Pages$Main$EntityListing$EntityListingFilters$applyFilters,
					config.jK,
					model.aJ,
					A2($author$project$Pages$Main$EntityListing$TreeElement$mapEntitiesAndGroupsToTreeElements, allEntities, allGroups))));
		return A3(
			$author$project$Pages$Main$EntityListing$TreeView$view,
			{
				jV: function ($) {
					return $.db;
				},
				jW: function ($) {
					return $.j_;
				},
				jX: function ($) {
					return $.db;
				},
				jY: function ($) {
					return $.fk;
				},
				kx: $elm$core$Basics$always(_List_Nil),
				lA: A3($author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeViewEntityElementContent, config, model, repository),
				lC: A3($author$project$Pages$Main$EntityListing$GenericEntityListingView$renderTreeViewGroupElementContent, config, model, repository),
				a: $author$project$Pages$Main$EntityListing$GenericEntityListingView$TreeViewStateChanged
			},
			model.en,
			treeViewElements);
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$RootDropArea = {$: 1};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderRootDropArea = function (model) {
	var rootDropAreaIsActive = _Utils_eq(
		$author$project$Pages$Main$EntityListing$GenericEntityListingView$getActiveDropId(model),
		$author$project$Pages$Main$EntityListing$GenericEntityListingView$RootDropArea);
	var isDragging = !_Utils_eq(
		$author$project$Lib$DragDrop$getDragId(model.ag),
		$elm$core$Maybe$Nothing);
	return A2(
		$elm$html$Html$div,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree-view__root-drop-area'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('active-drop-area', rootDropAreaIsActive),
							_Utils_Tuple2('d-none', !isDragging)
						]))
				]),
			A2($author$project$Lib$DragDrop$droppable, $author$project$Pages$Main$EntityListing$GenericEntityListingView$DragDropMsg, $author$project$Pages$Main$EntityListing$GenericEntityListingView$RootDropArea)),
		_List_fromArray(
			[
				$elm$html$Html$text('Siirrä juuritasolle viimeiseksi')
			]));
};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderCommandTreeWithRootDropArea = F5(
	function (config, model, repository, allEntities, allGroups) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tree-view__container')
				]),
			_List_fromArray(
				[
					A5($author$project$Pages$Main$EntityListing$GenericEntityListingView$renderEntityTree, config, model, repository, allEntities, allGroups),
					$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderRootDropArea(model)
				]));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$maybeRenderTreeView = F3(
	function (config, model, repository) {
		return A2(
			$author$project$Utils$CommonView$renderWebData,
			function (_v0) {
				var commands = _v0.a;
				var groups = _v0.b;
				return A5(
					$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderCommandTreeWithRootDropArea,
					config,
					model,
					repository,
					$elm$core$Dict$values(commands),
					$elm$core$Dict$values(groups));
			},
			A3(
				$author$project$Lib$RemoteData$map2,
				$elm$core$Tuple$pair,
				config.go(repository),
				config.gp(repository)));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$NoOp = {$: 0};
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$nothingToNoOp = F2(
	function (toMsg, maybe) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Pages$Main$EntityListing$GenericEntityListingView$NoOp,
			A2($elm$core$Maybe$map, toMsg, maybe));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$FilterValueChanged = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$select = F2(
	function (options, items) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Select$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Select$create, options, items));
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$renderFilter = F2(
	function (model, filter) {
		var filterValue = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2($elm$core$Dict$get, filter.db, model.aJ));
		var _v0 = filter.e0;
		if (!_v0.$) {
			var placeholder = _v0.a;
			return $rundis$elm_bootstrap$Bootstrap$Form$Input$text(
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
						$author$project$Pages$Main$EntityListing$GenericEntityListingView$FilterValueChanged(filter.db)),
						$rundis$elm_bootstrap$Bootstrap$Form$Input$value(filterValue),
						$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder(placeholder),
						$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('w-auto')
							]))
					]));
		} else {
			var options = _v0.a;
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(
						$author$project$Pages$Main$EntityListing$GenericEntityListingView$FilterValueChanged(filter.db)),
						$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('w-auto')
							]))
					]),
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var value = _v1.b;
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(key),
									$elm$html$Html$Attributes$selected(
									_Utils_eq(filterValue, key))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(value)
								]));
					},
					options));
		}
	});
var $author$project$Pages$Main$EntityListing$GenericEntityListingView$view = F4(
	function (config, model, repository, visibility) {
		var editSelectedItemMsg = A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$nothingToNoOp, $author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginEditElement, model.J);
		var disabledWhenNoSelection = $rundis$elm_bootstrap$Bootstrap$Button$disabled(
			_Utils_eq(model.J, $elm$core$Maybe$Nothing));
		var deleteSelectedItem = A2($author$project$Pages$Main$EntityListing$GenericEntityListingView$nothingToNoOp, $author$project$Pages$Main$EntityListing$GenericEntityListingView$ConfirmDeleteElement, model.J);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('entity-listing-view__root'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'd-none',
							$author$project$Utils$Visibility$isHidden(visibility))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('entity-listing-view__action-bar')
						]),
					A2(
						$elm$core$List$cons,
						A3(
							$author$project$Pages$Main$FormViewHelpers$entityActionButton,
							'sync',
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginRefresh)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Päivitä')
								])),
						A2(
							$elm$core$List$cons,
							A3(
								$author$project$Pages$Main$FormViewHelpers$entityActionButton,
								'pen',
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Button$onClick(editSelectedItemMsg),
										disabledWhenNoSelection
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Muokkaa')
									])),
							A2(
								$elm$core$List$cons,
								A3(
									$author$project$Pages$Main$FormViewHelpers$entityActionButton,
									'trash',
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Button$onClick(deleteSelectedItem),
											disabledWhenNoSelection
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Poista')
										])),
								A2(
									$elm$core$List$cons,
									A3(
										$author$project$Pages$Main$FormViewHelpers$entityActionButton,
										'plus',
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewEntity)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Uusi')
											])),
									A2(
										$elm$core$List$cons,
										A3(
											$author$project$Pages$Main$FormViewHelpers$entityActionButton,
											'plus',
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$EntityListing$GenericEntityListingView$BeginAddNewGroup)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Uusi ryhmä')
												])),
										A2(
											$elm$core$List$map,
											$author$project$Pages$Main$EntityListing$GenericEntityListingView$renderFilter(model),
											config.jK))))))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-1 overflow-hidden')
						]),
					_List_fromArray(
						[
							A3($author$project$Pages$Main$EntityListing$GenericEntityListingView$maybeRenderTreeView, config, model, repository)
						]))
				]));
	});
var $author$project$Utils$Visibility$Hidden = 1;
var $author$project$Utils$Visibility$Visible = 0;
var $author$project$Utils$Visibility$visibleIf = function (isVisible) {
	return isVisible ? 0 : 1;
};
var $author$project$Pages$Main$CommandsView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$CommandsView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$CommandsView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$small = function (_v0) {
	var conf = _v0;
	var options = conf.aT;
	return _Utils_update(
		conf,
		{
			aT: _Utils_update(
				options,
				{
					kY: $elm$core$Maybe$Just(1)
				})
		});
};
var $author$project$Utils$CommonModals$confirmationModal = function (_v0) {
	var content = _v0.iB;
	var cancel = _v0.h4;
	var confirm = _v0.iv;
	var status = _v0.ha;
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Modal$view,
		$rundis$elm_bootstrap$Bootstrap$Modal$shown,
		$rundis$elm_bootstrap$Bootstrap$Modal$small(
			A3(
				$rundis$elm_bootstrap$Bootstrap$Modal$footer,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Utils$CommonView$spinner(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'invisible',
										!_Utils_eq(status, $author$project$Lib$Form$LoadStatus$InProgress))
									]))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$primary,
								$rundis$elm_bootstrap$Bootstrap$Button$onClick(confirm)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Ok')
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
								$rundis$elm_bootstrap$Bootstrap$Button$onClick(cancel)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Peruuta')
							]))
					]),
				A3(
					$rundis$elm_bootstrap$Bootstrap$Modal$body,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(content)
						]),
					A3(
						$rundis$elm_bootstrap$Bootstrap$Modal$h3,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Oletko varma?')
							]),
						A2(
							$rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick,
							false,
							$rundis$elm_bootstrap$Bootstrap$Modal$config(cancel)))))));
};
var $author$project$Pages$Main$CommandsView$AddNewCommandFormChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Main$CommandsView$AddNewCommandFormSubmitted = function (a) {
	return {$: 9, a: a};
};
var $author$project$Entity$Command$createNewCommand = function (spec) {
	return {hM: $elm$core$Maybe$Nothing, hZ: _List_Nil, iy: $author$project$Entity$Connection$createNewConnectionReferenceSet, i$: '', jC: _List_Nil, j_: $elm$core$Maybe$Nothing, db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, kt: '', li: _List_Nil, dM: $author$project$Entity$Common$lastPosition, mh: spec, mB: 60};
};
var $author$project$Entity$Command$createNewCalendarViewCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecCalendarViewCommand(
		{hu: $elm$core$Maybe$Nothing, hw: $elm$core$Maybe$Nothing, jt: $elm$core$Maybe$Nothing, ju: $elm$core$Maybe$Nothing, jv: $elm$core$Maybe$Nothing, jx: $author$project$Entity$Clauses$createNewClause, lK: $elm$core$Maybe$Nothing}));
var $author$project$Encoding$Utils$emptyList = A2($elm$json$Json$Encode$list, $elm$core$Basics$identity, _List_Nil);
var $author$project$Entity$Command$createNewChartCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecChartCommand(
		A9($author$project$Entity$Command$ChartCommandSpecification, 1, $author$project$Entity$Clauses$createNewClause, _List_Nil, _List_Nil, _List_Nil, $author$project$Encoding$Utils$emptyList, $author$project$Entity$Command$JsonReturnType, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewCronCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecCronCommand(
		A4($author$project$Entity$Command$CronCommandSpecification, '0 * * ? * *', '2000-01-01T00:00:00Z', $author$project$Entity$Clauses$createNewClause, true)));
var $author$project$Entity$Command$createNewDataViewCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecDataViewCommand(
		{
			hu: $elm$core$Maybe$Nothing,
			hw: $elm$core$Maybe$Nothing,
			iX: true,
			iY: $elm$core$Maybe$Nothing,
			i7: false,
			ja: $elm$core$Maybe$Nothing,
			jb: $author$project$Entity$Clauses$DirectValueClause(
				{mY: '[]'}),
			jD: $author$project$Entity$Clauses$createNewClause,
			ka: $elm$core$Maybe$Nothing,
			kl: $elm$core$Maybe$Nothing,
			lf: 0,
			lT: _List_Nil,
			lU: $elm$core$Maybe$Nothing,
			lY: false,
			l0: 1,
			ml: _List_Nil,
			hn: $elm$core$Maybe$Nothing
		}));
var $author$project$Entity$Command$createNewDiagramCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecDiagramCommand(
		A6(
			$author$project$Entity$Command$DiagramCommandSpecification,
			$author$project$Entity$Clauses$createNewClause,
			_List_Nil,
			_List_Nil,
			$author$project$Entity$Clauses$DirectValueClause(
				{mY: '[]'}),
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewDownloadFileCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecDownloadFileCommand(
		A4($author$project$Entity$Command$DownloadFileCommandSpecification, $author$project$Entity$Clauses$createNewClause, false, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewFormCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecFormCommand(
		A5(
			$author$project$Entity$Command$FormCommandSpecification,
			$elm$core$Maybe$Just($author$project$Entity$Clauses$createNewClause),
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewMailCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecMailCommand(
		A6($author$project$Entity$Command$MailCommandSpecification, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewNotificationCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecNotificationCommand(
		A8($author$project$Entity$Command$NotificationCommandSpecification, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$ActionAndViewComponent$createNewKappaAction, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewProductionLineCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecProductionLineCommand(
		A7($author$project$Entity$Command$ProductionLineCommandSpecification, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $author$project$Entity$Clauses$createNewClause, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewQuickSightCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecQuickSightCommand(
		A3($author$project$Entity$Command$QuickSightCommandSpecification, $author$project$Entity$Clauses$createNewClause, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewReportCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecReportCommand(
		A8(
			$author$project$Entity$Command$ReportCommandSpecification,
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Just($author$project$Entity$Clauses$createNewClause),
			$author$project$Entity$Clauses$createNewClause,
			$elm$core$Maybe$Nothing,
			'Europe/Helsinki',
			'fi',
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewSingleClauseCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecSingleClauseCommand(
		A3($author$project$Entity$Command$SingleClauseCommandSpec, $author$project$Entity$Clauses$createNewClause, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewTreeViewCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecTreeViewCommand(
		$author$project$Entity$Command$TreeViewCommandSpecification($author$project$Entity$Clauses$createNewClause)(_List_Nil)(_List_Nil)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)(false)($elm$core$Maybe$Nothing)($elm$core$Maybe$Nothing)));
var $author$project$Entity$Command$createNewUploadFilesCommand = function () {
	var command = $author$project$Entity$Command$createNewCommand(
		$author$project$Entity$Command$SpecUploadFilesCommand(
			A6($author$project$Entity$Command$UploadFilesCommandSpecification, '__FILES__', $author$project$Entity$Clauses$createNewClause, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
	return _Utils_update(
		command,
		{
			jC: _List_fromArray(
				[
					{
					iV: $elm$core$Maybe$Nothing,
					kb: '__FILES__',
					kv: 'Tiedostot',
					lI: true,
					mL: $author$project$Entity$InputFieldDefinition$FileInputField(
						{hJ: true})
				}
				])
		});
}();
var $author$project$Entity$Command$createNewViewComponentsCommand = $author$project$Entity$Command$createNewCommand(
	$author$project$Entity$Command$SpecViewComponentsCommand(
		A4($author$project$Entity$Command$ViewComponentsCommandSpecification, _List_Nil, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing)));
var $author$project$Forms$CreateNewCommandForm$newCommandForm = function () {
	var selectTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tyyppi',
				aT: $author$project$Entity$Command$commandTypeOptions,
				lm: $elm$core$Maybe$Just('Valitse')
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mM: value});
				}),
			mY: function ($) {
				return $.mM;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$andThen,
		function (type_) {
			switch (type_) {
				case 'singleClause':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewSingleClauseCommand);
				case 'dataView':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewDataViewCommand);
				case 'productionLine':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewProductionLineCommand);
				case 'mail':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewMailCommand);
				case 'notification':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewNotificationCommand);
				case 'downloadFile':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewDownloadFileCommand);
				case 'uploadFiles':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewUploadFilesCommand);
				case 'report':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewReportCommand);
				case 'chart':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewChartCommand);
				case 'diagram':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewDiagramCommand);
				case 'treeView':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewTreeViewCommand);
				case 'calendar':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewCalendarViewCommand);
				case 'quickSight':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewQuickSightCommand);
				case 'cron':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewCronCommand);
				case 'viewComponents':
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewViewComponentsCommand);
				default:
					return $author$project$Lib$Form$Form$succeed($author$project$Entity$Command$createNewFormCommand);
			}
		},
		selectTypeField);
}();
var $author$project$Pages$Main$FormViewHelpers$renderCommonModal = F3(
	function (title, close, content) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Modal$view,
			$rundis$elm_bootstrap$Bootstrap$Modal$shown,
			A3(
				$rundis$elm_bootstrap$Bootstrap$Modal$body,
				_List_Nil,
				_List_fromArray(
					[content]),
				$rundis$elm_bootstrap$Bootstrap$Modal$small(
					A3(
						$rundis$elm_bootstrap$Bootstrap$Modal$h3,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(title)
							]),
						A2(
							$rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick,
							false,
							$rundis$elm_bootstrap$Bootstrap$Modal$config(close))))));
	});
var $author$project$Pages$Main$CommandsView$renderNewCommandForm = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
		'Uusi komento',
		$author$project$Pages$Main$CommandsView$CloseCurrentModal,
		A3(
			$author$project$Lib$Form$FormView$asHtml,
			{fQ: 'OK', db: 'create_new_command', bK: 'Tallennetaan', x: $author$project$Pages$Main$CommandsView$AddNewCommandFormChanged, b$: true, hq: 1},
			A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$CommandsView$AddNewCommandFormSubmitted, $author$project$Forms$CreateNewCommandForm$newCommandForm),
			formModel));
};
var $author$project$Pages$Main$CommandsView$renderModal = function (model) {
	var _v0 = model.bO;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$messageModal(config);
		case 2:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$confirmationModal(config);
		default:
			var form = _v0.a;
			return $author$project$Pages$Main$CommandsView$renderNewCommandForm(form);
	}
};
var $author$project$Pages$Main$CommandsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Main$CommandsView$renderModal(model),
					A2($author$project$Pages$Main$CommandsView$renderListingView, model, repository),
					A2($author$project$Pages$Main$CommandsView$renderEditView, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$commandsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$CommandsMsg,
		A2($author$project$Pages$Main$CommandsView$view, model.bj, model.n));
};
var $author$project$Forms$ConnectionForms$ConnectionFormConfig = function (allConnections) {
	return {eA: allConnections};
};
var $author$project$Pages$Main$ConnectionsView$ConnectionFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Main$ConnectionsView$EditFormBackButtonClicked = {$: 3};
var $author$project$Lib$Form$Form$TextPassword = 2;
var $author$project$Lib$Form$Form$passwordField = $author$project$Lib$Form$Base$TextField$form(
	$author$project$Lib$Form$Form$Text(2));
var $author$project$Lib$Form$Form$simplePasswordField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	var autoComplete = _v0.hS;
	return $author$project$Lib$Form$Form$passwordField(
		{
			ba: {hS: autoComplete, kv: label, lm: ''},
			U: $elm$core$Result$Ok,
			a: update,
			mY: value
		});
};
var $author$project$Forms$ConnectionForms$awsConnectionSpecForm = function () {
	var defaultBucketField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'S3 default bucket',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{iU: value});
					}),
				mY: function ($) {
					return $.iU;
				}
			}));
	var awsRegionField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'AWS Region',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hX: value});
				}),
			mY: function ($) {
				return $.hX;
			}
		});
	var awsAccessKeySecretField = $author$project$Lib$Form$Form$simplePasswordField(
		{
			hS: $elm$core$Maybe$Nothing,
			kv: 'Access Key Secret',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hV: value});
				}),
			mY: function ($) {
				return $.hV;
			}
		});
	var awsAccessKeyField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Access Key',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hU: value});
				}),
			mY: function ($) {
				return $.hU;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		defaultBucketField,
		A2(
			$author$project$Lib$Form$Form$append,
			awsAccessKeySecretField,
			A2(
				$author$project$Lib$Form$Form$append,
				awsAccessKeyField,
				A2(
					$author$project$Lib$Form$Form$append,
					awsRegionField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$AwsConnectionSpecification)))));
}();
var $author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm = A2(
	$author$project$Lib$Form$Form$mapValues,
	{
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{ip: value});
			}),
		mY: function ($) {
			return $.ip;
		}
	},
	A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommonForms$descriptionField,
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommonForms$nameField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommonForms$idAnchorField,
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$CommonForms$aproProjectIdField,
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$CommonForms$constantMetaField(
							function ($) {
								return $.dM;
							}),
						A2(
							$author$project$Lib$Form$Form$append,
							$author$project$Forms$CommonForms$constantMetaField(
								function ($) {
									return $.j_;
								}),
							A2(
								$author$project$Lib$Form$Form$append,
								$author$project$Forms$CommonForms$idField,
								$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$Connection)))))))));
var $author$project$Forms$ConnectionForms$makeAwsConnectionForm = A2(
	$author$project$Lib$Form$Form$append,
	A2($author$project$Lib$Form$Form$map, $author$project$Entity$Connection$SpecAwsConnection, $author$project$Forms$ConnectionForms$awsConnectionSpecForm),
	$author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm);
var $author$project$Forms$ConnectionForms$azureAdConnectionSpecForm = function () {
	var sharePointRootSiteIdField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'SharePoint juurisivuston ID',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{l9: value});
					}),
				mY: function ($) {
					return $.l9;
				}
			}));
	var clientSecretField = $author$project$Lib$Form$Form$simplePasswordField(
		{
			hS: $elm$core$Maybe$Nothing,
			kv: 'Client secret',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{$8: value});
				}),
			mY: function ($) {
				return $.$8;
			}
		});
	var clientIdField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Client ID',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ie: value});
				}),
			mY: function ($) {
				return $.ie;
			}
		});
	var azureAdTenantIdField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Azure AD asiakas ID',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hY: value});
				}),
			mY: function ($) {
				return $.hY;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		sharePointRootSiteIdField,
		A2(
			$author$project$Lib$Form$Form$append,
			clientSecretField,
			A2(
				$author$project$Lib$Form$Form$append,
				clientIdField,
				A2(
					$author$project$Lib$Form$Form$append,
					azureAdTenantIdField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$AzureAdConnectionSpecification)))));
}();
var $author$project$Forms$ConnectionForms$makeAzureAdConnectionForm = A2(
	$author$project$Lib$Form$Form$append,
	A2($author$project$Lib$Form$Form$map, $author$project$Entity$Connection$SpecAzureAdConnection, $author$project$Forms$ConnectionForms$azureAdConnectionSpecForm),
	$author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm);
var $author$project$Forms$ConnectionForms$connectionPoolSettingsForm = function () {
	var retryAttemptsField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'retryAttempts (https://github.com/vladmihalcea/flexy-pool)',
			kI: 10,
			kU: 0,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lP: value});
				}),
			mY: function ($) {
				return $.lP;
			}
		});
	var minimumIdleField = $author$project$Lib$Form$Form$meta(
		function (_v2) {
			var maximumPoolSize = _v2.kM;
			return $author$project$Forms$CommonForms$limitedIntegerField(
				{
					kv: 'minimumIdle',
					kI: A2(
						$elm$core$Maybe$withDefault,
						100,
						$elm$core$String$toInt(maximumPoolSize)),
					kU: 0,
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{kW: value});
						}),
					mY: function ($) {
						return $.kW;
					}
				});
		});
	var maximumPoolSizeField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'maximumPoolSize',
			kI: 100,
			kU: 0,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kM: value});
				}),
			mY: function ($) {
				return $.kM;
			}
		});
	var maxOverflowPoolSizeField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'maxOverflowPoolSize (https://github.com/vladmihalcea/flexy-pool)',
			kI: 50,
			kU: 0,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kK: value});
				}),
			mY: function ($) {
				return $.kK;
			}
		});
	var maxLifeTimeField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'maxLifeTime [s]',
			kI: 120 * 60,
			kU: 0,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kJ: value});
				}),
			mY: function ($) {
				return $.kJ;
			}
		});
	var leakDetectionThresholdField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'leakDetectionThreshold [s]',
			kI: 600,
			kU: 0,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ky: value});
				}),
			mY: function ($) {
				return $.ky;
			}
		});
	var keepaliveTimeField = $author$project$Lib$Form$Form$meta(
		function (_v1) {
			var maxLifetime = _v1.kJ;
			return $author$project$Forms$CommonForms$limitedIntegerField(
				{
					kv: 'keepaliveTime [s]',
					kI: A2(
						$elm$core$Maybe$withDefault,
						120 * 60,
						$elm$core$String$toInt(maxLifetime)),
					kU: 0,
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{ks: value});
						}),
					mY: function ($) {
						return $.ks;
					}
				});
		});
	var idleTimeoutField = $author$project$Lib$Form$Form$meta(
		function (_v0) {
			var maxLifetime = _v0.kJ;
			return $author$project$Forms$CommonForms$limitedIntegerField(
				{
					kv: 'idleTimeout [s]',
					kI: A2(
						$elm$core$Maybe$withDefault,
						120 * 60,
						$elm$core$String$toInt(maxLifetime)),
					kU: 0,
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{kd: value});
						}),
					mY: function ($) {
						return $.kd;
					}
				});
		});
	var connectionTimeoutField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'connectionTimeout [s]',
			kI: 5 * 60,
			kU: 1,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ix: value});
				}),
			mY: function ($) {
				return $.ix;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$section,
		'Yhteyspoolin asetukset (https://github.com/brettwooldridge/HikariCP)',
		A2(
			$author$project$Lib$Form$Form$append,
			retryAttemptsField,
			A2(
				$author$project$Lib$Form$Form$append,
				maxOverflowPoolSizeField,
				A2(
					$author$project$Lib$Form$Form$append,
					leakDetectionThresholdField,
					A2(
						$author$project$Lib$Form$Form$append,
						idleTimeoutField,
						A2(
							$author$project$Lib$Form$Form$append,
							keepaliveTimeField,
							A2(
								$author$project$Lib$Form$Form$append,
								maxLifeTimeField,
								A2(
									$author$project$Lib$Form$Form$append,
									connectionTimeoutField,
									A2(
										$author$project$Lib$Form$Form$append,
										minimumIdleField,
										A2(
											$author$project$Lib$Form$Form$append,
											maximumPoolSizeField,
											$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$ConnectionPoolSettings)))))))))));
}();
var $author$project$Forms$ConnectionForms$keyValuePairEntryForm = function () {
	var valueField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Arvo',
			a: F2(
				function (value_, values) {
					return _Utils_update(
						values,
						{mY: value_});
				}),
			mY: function ($) {
				return $.mY;
			}
		});
	var keyField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Avain',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kt: value});
				}),
			mY: function ($) {
				return $.kt;
			}
		});
	return $author$project$Lib$Form$Form$inlineGroup(
		A2(
			$author$project$Lib$Form$Form$append,
			valueField,
			A2(
				$author$project$Lib$Form$Form$append,
				keyField,
				$author$project$Lib$Form$Form$succeed($elm$core$Tuple$pair))));
}();
var $author$project$Forms$CommonForms$passwordField = function (autoComplete) {
	return $author$project$Lib$Form$Form$passwordField(
		{
			ba: {hS: autoComplete, kv: 'Salasana', lm: ''},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{fl: value});
				}),
			mY: function ($) {
				return $.fl;
			}
		});
};
var $author$project$Forms$CommonForms$usernameField = function (autoComplete) {
	return $author$project$Lib$Form$Form$textField(
		{
			ba: {hS: autoComplete, kv: 'Tunnus', lm: ''},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hp: value});
				}),
			mY: function ($) {
				return $.hp;
			}
		});
};
var $author$project$Forms$ConnectionForms$databaseConnectionSpecForm = function () {
	var serverNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Palvelin',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{l4: value});
				}),
			mY: function ($) {
				return $.l4;
			}
		});
	var propertiesForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {kt: '', mY: ''},
			kv: 'Parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lx: value});
				}),
			mY: function ($) {
				return $.lx;
			}
		},
		function (_v0) {
			return $author$project$Forms$ConnectionForms$keyValuePairEntryForm;
		});
	var portNumberField = $author$project$Lib$Form$Form$simpleIntegerField(
		{
			kv: 'Porttinumero',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lo: value});
				}),
			mY: function ($) {
				return $.lo;
			}
		});
	var poolSettingsForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ln: value});
				}),
			mY: function ($) {
				return $.ln;
			}
		},
		$author$project$Forms$ConnectionForms$connectionPoolSettingsForm);
	var dbTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Tietokannan tyyppi',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('SqlServer', 'SQL Server'),
						_Utils_Tuple2('OracleDB', 'Oracle DB'),
						_Utils_Tuple2('PostgreSQL', 'PostgreSQL'),
						_Utils_Tuple2('MariaDB', 'MariaDB')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseDatabaseType,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{eP: value});
				}),
			mY: function ($) {
				return $.eP;
			}
		});
	var databaseNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Tietokanta',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iP: value});
				}),
			mY: function ($) {
				return $.iP;
			}
		});
	var convertTimeZonesField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Tee päivämäärille ja ajoille muunnos [UTC <-> käyttäjän aikavyöhyke]',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iF: value});
				}),
			mY: function ($) {
				return $.iF;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		convertTimeZonesField,
		A2(
			$author$project$Lib$Form$Form$append,
			propertiesForm,
			A2(
				$author$project$Lib$Form$Form$append,
				poolSettingsForm,
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$CommonForms$passwordField($elm$core$Maybe$Nothing),
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$CommonForms$usernameField($elm$core$Maybe$Nothing),
						A2(
							$author$project$Lib$Form$Form$append,
							databaseNameField,
							A2(
								$author$project$Lib$Form$Form$append,
								portNumberField,
								A2(
									$author$project$Lib$Form$Form$append,
									serverNameField,
									A2(
										$author$project$Lib$Form$Form$append,
										dbTypeField,
										$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$DatabaseConnectionSpecification))))))))));
}();
var $author$project$Forms$ConnectionForms$makeDatabaseConnectionForm = A2(
	$author$project$Lib$Form$Form$append,
	A2($author$project$Lib$Form$Form$map, $author$project$Entity$Connection$SpecDatabaseConnection, $author$project$Forms$ConnectionForms$databaseConnectionSpecForm),
	$author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm);
var $author$project$Forms$ConnectionForms$makeDynamicConnectionForm = A2(
	$author$project$Lib$Form$Form$append,
	$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$SpecDynamicConnection),
	$author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm);
var $author$project$Forms$ConnectionForms$connectionCredentialsField = $author$project$Lib$Form$Form$textField(
	{
		ba: {hS: $elm$core$Maybe$Nothing, kv: 'Tunnistautuminen', lm: ''},
		U: A2(
			$elm$core$Basics$composeR,
			$elm$json$Json$Decode$decodeString($elm$json$Json$Decode$value),
			$elm$core$Result$mapError($elm$json$Json$Decode$errorToString)),
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{iH: value});
			}),
		mY: function ($) {
			return $.iH;
		}
	});
var $author$project$Forms$ConnectionForms$httpCredentialsForm = A2(
	$author$project$Forms$CommonForms$convertFormToOptional,
	'Basic-autentikaatio',
	$author$project$Lib$Form$Form$inlineGroup(
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CommonForms$passwordField($elm$core$Maybe$Nothing),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommonForms$usernameField($elm$core$Maybe$Nothing),
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$HttpCredentials)))));
var $author$project$Forms$ConnectionForms$httpHeaderListForm = function () {
	var valueField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Arvo',
			a: F2(
				function (value_, values) {
					return _Utils_update(
						values,
						{mY: value_});
				}),
			mY: function ($) {
				return $.mY;
			}
		});
	var nameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Nimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{k_: value});
				}),
			mY: function ($) {
				return $.k_;
			}
		});
	var encryptField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Tallenna arvo salattuna',
			a: F2(
				function (value_, values) {
					return _Utils_update(
						values,
						{jn: value_});
				}),
			mY: function ($) {
				return $.jn;
			}
		});
	var headerForm = A2(
		$author$project$Lib$Form$Form$append,
		encryptField,
		A2(
			$author$project$Lib$Form$Form$append,
			valueField,
			A2(
				$author$project$Lib$Form$Form$append,
				nameField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$HttpHeader))));
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {jn: false, k_: '', mY: ''},
			kv: 'Headerit',
			a: F2(
				function (value, _v0) {
					return value;
				}),
			mY: $elm$core$Basics$identity
		},
		$elm$core$Basics$always(headerForm));
}();
var $author$project$Forms$ConnectionForms$httpConnectionSpecForm = function () {
	var urlField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'URL',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mO: value});
				}),
			mY: function ($) {
				return $.mO;
			}
		});
	var installKtorJsonFeatureField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Ktor JsonFeature -käytössä',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{km: value});
				}),
			mY: function ($) {
				return $.km;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		installKtorJsonFeatureField,
		A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{iT: value});
						}),
					mY: function ($) {
						return $.iT;
					}
				},
				$author$project$Forms$ConnectionForms$httpHeaderListForm),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$ConnectionForms$connectionCredentialsField,
				A2(
					$author$project$Lib$Form$Form$append,
					A2(
						$author$project$Lib$Form$Form$mapValues,
						{
							a: F2(
								function (value, values) {
									return _Utils_update(
										values,
										{eD: value});
								}),
							mY: function ($) {
								return $.eD;
							}
						},
						$author$project$Forms$ConnectionForms$httpCredentialsForm),
					A2(
						$author$project$Lib$Form$Form$append,
						urlField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$HttpConnectionSpecification))))));
}();
var $author$project$Forms$ConnectionForms$makeHttpConnectionForm = A2(
	$author$project$Lib$Form$Form$append,
	A2($author$project$Lib$Form$Form$map, $author$project$Entity$Connection$SpecHttpConnection, $author$project$Forms$ConnectionForms$httpConnectionSpecForm),
	$author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm);
var $author$project$Forms$ConnectionForms$mailServerConnectionSpecForm = function () {
	var usernameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Tunnus',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mX: value});
				}),
			mY: function ($) {
				return $.mX;
			}
		});
	var portField = $author$project$Lib$Form$Form$simpleIntegerField(
		{
			kv: 'SMTP-portti',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lp: value});
				}),
			mY: function ($) {
				return $.lp;
			}
		});
	var hostField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'SMTP-host',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{j5: value});
				}),
			mY: function ($) {
				return $.j5;
			}
		});
	var fromField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Lähettäjä',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jP: value});
				}),
			mY: function ($) {
				return $.jP;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommonForms$passwordField($elm$core$Maybe$Nothing),
		A2(
			$author$project$Lib$Form$Form$append,
			usernameField,
			A2(
				$author$project$Lib$Form$Form$append,
				fromField,
				A2(
					$author$project$Lib$Form$Form$append,
					portField,
					A2(
						$author$project$Lib$Form$Form$append,
						hostField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$MailServerConnectionSpecification))))));
}();
var $author$project$Forms$ConnectionForms$makeMailServerConnectionForm = A2(
	$author$project$Lib$Form$Form$append,
	A2($author$project$Lib$Form$Form$map, $author$project$Entity$Connection$SpecMailServerConnection, $author$project$Forms$ConnectionForms$mailServerConnectionSpecForm),
	$author$project$Forms$ConnectionForms$makeConnectionCommonPropsForm);
var $author$project$Forms$ConnectionForms$makeConnectionForm = function (config) {
	var mapValues = F2(
		function (values, updateMapper) {
			return $author$project$Lib$Form$Form$mapValues(
				{
					a: F2(
						function (value, _v1) {
							return updateMapper(value);
						}),
					mY: function (_v2) {
						return values;
					}
				});
		});
	return $author$project$Lib$Form$Form$meta(
		function (values) {
			switch (values.$) {
				case 0:
					var values_ = values.a;
					return A3(mapValues, values_, $author$project$Forms$ConnectionForms$DynamicConnectionFormValues, $author$project$Forms$ConnectionForms$makeDynamicConnectionForm);
				case 1:
					var values_ = values.a;
					return A3(mapValues, values_, $author$project$Forms$ConnectionForms$DatabaseFormValues, $author$project$Forms$ConnectionForms$makeDatabaseConnectionForm);
				case 2:
					var values_ = values.a;
					return A3(mapValues, values_, $author$project$Forms$ConnectionForms$HttpFormValues, $author$project$Forms$ConnectionForms$makeHttpConnectionForm);
				case 3:
					var values_ = values.a;
					return A3(mapValues, values_, $author$project$Forms$ConnectionForms$AzureAdConnectionFormValues, $author$project$Forms$ConnectionForms$makeAzureAdConnectionForm);
				case 4:
					var values_ = values.a;
					return A3(mapValues, values_, $author$project$Forms$ConnectionForms$AwsConnectionFormValues, $author$project$Forms$ConnectionForms$makeAwsConnectionForm);
				default:
					var values_ = values.a;
					return A3(mapValues, values_, $author$project$Forms$ConnectionForms$MailServerFormValues, $author$project$Forms$ConnectionForms$makeMailServerConnectionForm);
			}
		});
};
var $author$project$Pages$Main$ConnectionsView$renderEditConnectionForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$ConnectionsView$EditFormBackButtonClicked,
			'Muokkaa yhteyttä',
			_List_fromArray(
				[
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
					{
						gk: A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Pages$Main$ConnectionsView$BeginSaveConnection,
							$author$project$Forms$ConnectionForms$makeConnectionForm(config)),
						gl: formModel,
						db: 'edit_connection',
						x: $author$project$Pages$Main$ConnectionsView$ConnectionFormChanged
					})
				]));
	});
var $author$project$Pages$Main$ConnectionsView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$ConnectionsView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$ConnectionsView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$ConnectionsView$BeginSaveGroup, $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm),
					gl: formModel,
					db: 'edit_connection_group',
					x: $author$project$Pages$Main$ConnectionsView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$ConnectionsView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$ConnectionsView$renderEditConnectionForm(formModel),
					A2($author$project$Lib$RemoteData$map, $author$project$Forms$ConnectionForms$ConnectionFormConfig, repository.iy));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$ConnectionsView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$ConnectionsView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$ConnectionsView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$ConnectionsView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $author$project$Pages$Main$ConnectionsView$AddNewConnectionFormChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Main$ConnectionsView$AddNewConnectionFormSubmitted = function (a) {
	return {$: 9, a: a};
};
var $author$project$Entity$Connection$createNewConnection = function (spec) {
	return {hM: $elm$core$Maybe$Nothing, i$: '', j_: $elm$core$Maybe$Nothing, db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, k_: '', dM: $author$project$Entity$Common$lastPosition, mi: spec};
};
var $author$project$Entity$Connection$createNewAwsConnection = $author$project$Entity$Connection$createNewConnection(
	$author$project$Entity$Connection$SpecAwsConnection(
		A4($author$project$Entity$Connection$AwsConnectionSpecification, '', '', '', '')));
var $author$project$Entity$Connection$createNewAzureAdConnection = $author$project$Entity$Connection$createNewConnection(
	$author$project$Entity$Connection$SpecAzureAdConnection(
		A4($author$project$Entity$Connection$AzureAdConnectionSpecification, '', '', '', '')));
var $author$project$Entity$Connection$createNewDatabaseConnectionSpec = {
	iF: false,
	iP: '',
	iR: 1,
	fl: '',
	ln: {ix: 30, kd: 5 * 60, ks: 5 * 60, ky: 60, kJ: 10 * 60, kK: 3, kM: 5, kW: 2, lP: 1},
	lo: 1433,
	lx: _List_fromArray(
		[
			_Utils_Tuple2('encrypt', 'true')
		]),
	l4: '',
	hp: ''
};
var $author$project$Entity$Connection$createNewDatabaseConnection = $author$project$Entity$Connection$createNewConnection(
	$author$project$Entity$Connection$SpecDatabaseConnection($author$project$Entity$Connection$createNewDatabaseConnectionSpec));
var $author$project$Entity$Connection$createNewDynamicConnection = $author$project$Entity$Connection$createNewConnection($author$project$Entity$Connection$SpecDynamicConnection);
var $author$project$Entity$Connection$createNewHttpConnectionSpec = {h_: $elm$core$Maybe$Nothing, iH: $elm$json$Json$Encode$null, iT: _List_Nil, km: true, mO: ''};
var $author$project$Entity$Connection$createNewHttpConnection = $author$project$Entity$Connection$createNewConnection(
	$author$project$Entity$Connection$SpecHttpConnection($author$project$Entity$Connection$createNewHttpConnectionSpec));
var $author$project$Entity$Connection$createNewMailServerConnectionSpec = A5($author$project$Entity$Connection$MailServerConnectionSpecification, '', 587, '', '', '');
var $author$project$Entity$Connection$createNewMailServerConnection = $author$project$Entity$Connection$createNewConnection(
	$author$project$Entity$Connection$SpecMailServerConnection($author$project$Entity$Connection$createNewMailServerConnectionSpec));
var $author$project$Forms$CreateNewConnectionForm$newConnectionForm = A2(
	$author$project$Lib$Form$Form$andThen,
	function (type_) {
		switch (type_) {
			case 'dynamic':
				return $author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$createNewDynamicConnection);
			case 'database':
				return $author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$createNewDatabaseConnection);
			case 'http':
				return $author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$createNewHttpConnection);
			case 'azure_ad':
				return $author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$createNewAzureAdConnection);
			case 'aws':
				return $author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$createNewAwsConnection);
			default:
				return $author$project$Lib$Form$Form$succeed($author$project$Entity$Connection$createNewMailServerConnection);
		}
	},
	$author$project$Lib$Form$Form$selectField(
		{
			ba: {kv: 'Tyyppi', aT: $author$project$Entity$Connection$connectionTypeOptions, lm: $elm$core$Maybe$Nothing},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cJ: value});
				}),
			mY: function ($) {
				return $.cJ;
			}
		}));
var $author$project$Pages$Main$ConnectionsView$renderNewConnectionForm = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
		'Uusi yhteys',
		$author$project$Pages$Main$ConnectionsView$CloseCurrentModal,
		A3(
			$author$project$Lib$Form$FormView$asHtml,
			{fQ: 'OK', db: 'create_new_connection', bK: 'Tallennetaan', x: $author$project$Pages$Main$ConnectionsView$AddNewConnectionFormChanged, b$: true, hq: 1},
			A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$ConnectionsView$AddNewConnectionFormSubmitted, $author$project$Forms$CreateNewConnectionForm$newConnectionForm),
			formModel));
};
var $author$project$Pages$Main$ConnectionsView$renderModal = function (model) {
	var _v0 = model.bO;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$messageModal(config);
		case 2:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$confirmationModal(config);
		default:
			var form = _v0.a;
			return $author$project$Pages$Main$ConnectionsView$renderNewConnectionForm(form);
	}
};
var $author$project$Pages$Main$ConnectionsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Main$ConnectionsView$renderModal(model),
					A2($author$project$Pages$Main$ConnectionsView$renderListingView, model, repository),
					A2($author$project$Pages$Main$ConnectionsView$renderEditView, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$connectionsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$ConnectionsMsg,
		A2($author$project$Pages$Main$ConnectionsView$view, model.bk, model.n));
};
var $author$project$Forms$EndpointForms$EndpointFormConfig = function (allCommands) {
	return {fU: allCommands};
};
var $author$project$Pages$Main$EndpointsView$EditFormBackButtonClicked = {$: 3};
var $author$project$Pages$Main$EndpointsView$EndpointFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Lib$MiscUtils$addIf = F3(
	function (cond, item, list) {
		return cond ? _Utils_ap(
			list,
			_List_fromArray(
				[item])) : list;
	});
var $author$project$Forms$EndpointForms$methodsForm = function () {
	var putField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'PUT',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{dY: value});
				}),
			mY: function ($) {
				return $.dY;
			}
		});
	var postField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'POST',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{dN: value});
				}),
			mY: function ($) {
				return $.dN;
			}
		});
	var getField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'GET',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{c1: value});
				}),
			mY: function ($) {
				return $.c1;
			}
		});
	var deleteField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'DELETE',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cQ: value});
				}),
			mY: function ($) {
				return $.cQ;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$section,
		'HTTP-metodit',
		A2(
			$author$project$Lib$Form$Form$append,
			deleteField,
			A2(
				$author$project$Lib$Form$Form$append,
				putField,
				A2(
					$author$project$Lib$Form$Form$append,
					postField,
					A2(
						$author$project$Lib$Form$Form$append,
						getField,
						$author$project$Lib$Form$Form$succeed(
							F4(
								function (get, post, put, _delete) {
									return A3(
										$author$project$Lib$MiscUtils$addIf,
										_delete,
										3,
										A3(
											$author$project$Lib$MiscUtils$addIf,
											put,
											2,
											A3(
												$author$project$Lib$MiscUtils$addIf,
												post,
												1,
												A3($author$project$Lib$MiscUtils$addIf, get, 0, _List_Nil))));
								})))))));
}();
var $author$project$Forms$EndpointForms$returnTypeField = $author$project$Lib$Form$Form$selectField(
	{
		ba: {
			kv: 'Palautettava tyyppi',
			aT: _List_fromArray(
				[
					_Utils_Tuple2('Json', 'Json'),
					_Utils_Tuple2('PlainText', 'Teksti'),
					_Utils_Tuple2('BinaryFile', 'Tiedosto binäärisenä')
				]),
			lm: $elm$core$Maybe$Nothing
		},
		U: $author$project$Utils$TypeParsers$parseEndpointReturnType,
		a: F2(
			function (value, values) {
				return _Utils_update(
					values,
					{lQ: value});
			}),
		mY: function ($) {
			return $.lQ;
		}
	});
var $author$project$Forms$EndpointForms$makeEndpointForm = function (_v0) {
	var allCommands = _v0.fU;
	var pathField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Polku',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ll: value});
				}),
			mY: function ($) {
				return $.ll;
			}
		});
	var paramMappingsForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {kt: '', mY: $elm$core$Maybe$Nothing},
			kv: 'Parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{io: value});
				}),
			mY: function ($) {
				return $.io;
			}
		},
		function (_v1) {
			return $author$project$Forms$CommandParameterForm$commandParameterField(1);
		});
	var nameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Nimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{k_: value});
				}),
			mY: function ($) {
				return $.k_;
			}
		});
	var commandIdField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Suoritettava komento',
			aT: A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$Command$getCommandLongDescription,
				A2(
					$author$project$Utils$ListUtils$sortByValues,
					$author$project$Entity$Command$commandTypeComparator,
					$elm$core$Dict$toList(allCommands))),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f2: value});
				}),
			mY: function ($) {
				return $.f2;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$EndpointForms$returnTypeField,
		A2(
			$author$project$Lib$Form$Form$append,
			paramMappingsForm,
			A2(
				$author$project$Lib$Form$Form$append,
				commandIdField,
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$EndpointForms$methodsForm,
					A2(
						$author$project$Lib$Form$Form$append,
						pathField,
						A2(
							$author$project$Lib$Form$Form$append,
							$author$project$Forms$CommonForms$descriptionField,
							A2(
								$author$project$Lib$Form$Form$append,
								nameField,
								A2(
									$author$project$Lib$Form$Form$append,
									$author$project$Forms$CommonForms$idAnchorField,
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$aproProjectIdField,
										A2(
											$author$project$Lib$Form$Form$append,
											$author$project$Forms$CommonForms$constantMetaField(
												function ($) {
													return $.dM;
												}),
											A2(
												$author$project$Lib$Form$Form$append,
												$author$project$Forms$CommonForms$constantMetaField(
													function ($) {
														return $.j_;
													}),
												A2(
													$author$project$Lib$Form$Form$append,
													$author$project$Forms$CommonForms$idField,
													$author$project$Lib$Form$Form$succeed($author$project$Entity$Endpoint$Endpoint)))))))))))));
};
var $author$project$Pages$Main$EndpointsView$renderEditEndpointForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$EndpointsView$EditFormBackButtonClicked,
			'Muokkaa päätepistettä',
			_List_fromArray(
				[
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
					{
						gk: A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Pages$Main$EndpointsView$BeginSaveEndpoint,
							$author$project$Forms$EndpointForms$makeEndpointForm(config)),
						gl: formModel,
						db: 'edit_endpoint',
						x: $author$project$Pages$Main$EndpointsView$EndpointFormChanged
					})
				]));
	});
var $author$project$Pages$Main$EndpointsView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$EndpointsView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$EndpointsView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$EndpointsView$BeginSaveGroup, $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm),
					gl: formModel,
					db: 'edit_endpoint_group',
					x: $author$project$Pages$Main$EndpointsView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$EndpointsView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$EndpointsView$renderEditEndpointForm(formModel),
					A2($author$project$Lib$RemoteData$map, $author$project$Forms$EndpointForms$EndpointFormConfig, repository.O));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$EndpointsView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$EndpointsView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$EndpointsView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$EndpointsView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $author$project$Pages$Main$EndpointsView$renderModal = function (model) {
	var _v0 = model.bO;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$messageModal(config);
		default:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$confirmationModal(config);
	}
};
var $author$project$Pages$Main$EndpointsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Main$EndpointsView$renderModal(model),
					A2($author$project$Pages$Main$EndpointsView$renderListingView, model, repository),
					A2($author$project$Pages$Main$EndpointsView$renderEditView, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$endpointsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$EndpointsMsg,
		A2($author$project$Pages$Main$EndpointsView$view, model.bs, model.n));
};
var $author$project$Forms$EnvironmentForms$EnvironmentFormConfig = function (allConnections) {
	return {eA: allConnections};
};
var $author$project$Pages$Main$EnvironmentsView$EditFormBackButtonClicked = {$: 3};
var $author$project$Pages$Main$EnvironmentsView$EnvironmentFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Forms$CommandParameterForm$NoParameterResolving = 0;
var $author$project$Forms$EnvironmentForms$makeDynamicConnectionAssociationForm = function (config) {
	var connectionOptions = A2(
		$elm$core$List$map,
		function (_v0) {
			var id = _v0.a;
			var conn = _v0.b;
			return _Utils_Tuple2(id, conn.k_ + (' (' + (conn.i$ + ')')));
		},
		$elm$core$Dict$toList(config.eA));
	var dynamicConnectionIdField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Dynaaminen yhteys',
			aT: connectionOptions,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jc: value});
				}),
			mY: function ($) {
				return $.jc;
			}
		});
	var actualConnectionIdField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Varsinainen yhteys',
			aT: connectionOptions,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hC: value});
				}),
			mY: function ($) {
				return $.hC;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		actualConnectionIdField,
		A2(
			$author$project$Lib$Form$Form$append,
			dynamicConnectionIdField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Environment$DynamicConnectionAssociation)));
};
var $author$project$Forms$EnvironmentForms$makeEnvironmentForm = function (config) {
	var allConnections = config.eA;
	var nameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Nimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{k_: value});
				}),
			mY: function ($) {
				return $.k_;
			}
		});
	var envTypeField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Tyyppi',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{jr: value});
					}),
				mY: function ($) {
					return $.jr;
				}
			}));
	var envParamsForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {kt: '', mY: $elm$core$Maybe$Nothing},
			kv: 'Parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jq: value});
				}),
			mY: function ($) {
				return $.jq;
			}
		},
		function (_v1) {
			return $author$project$Forms$CommandParameterForm$commandParameterField(0);
		});
	var dynamicConnectionsForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {hC: $author$project$Entity$Common$nonExistentId, jc: $author$project$Entity$Common$nonExistentId},
			kv: 'Dynaamiset yhteydet',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jd: value});
				}),
			mY: function ($) {
				return $.jd;
			}
		},
		function (_v0) {
			return $author$project$Forms$EnvironmentForms$makeDynamicConnectionAssociationForm(config);
		});
	var displayNameField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextField(
			{
				kv: 'Näyttönimi',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{i1: value});
					}),
				mY: function ($) {
					return $.i1;
				}
			}));
	var connectionsForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cI: value});
				}),
			mY: function ($) {
				return $.cI;
			}
		},
		$author$project$Forms$ConnectionForms$makeConnectionSetForm(
			{eA: allConnections, mb: true, md: true, me: true}));
	return A2(
		$author$project$Lib$Form$Form$append,
		envParamsForm,
		A2(
			$author$project$Lib$Form$Form$append,
			dynamicConnectionsForm,
			A2(
				$author$project$Lib$Form$Form$append,
				connectionsForm,
				A2(
					$author$project$Lib$Form$Form$append,
					envTypeField,
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$CommonForms$descriptionField,
						A2(
							$author$project$Lib$Form$Form$append,
							displayNameField,
							A2(
								$author$project$Lib$Form$Form$append,
								nameField,
								A2(
									$author$project$Lib$Form$Form$append,
									$author$project$Forms$CommonForms$idAnchorField,
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$aproProjectIdField,
										A2(
											$author$project$Lib$Form$Form$append,
											$author$project$Forms$CommonForms$constantMetaField(
												function ($) {
													return $.dM;
												}),
											A2(
												$author$project$Lib$Form$Form$append,
												$author$project$Forms$CommonForms$constantMetaField(
													function ($) {
														return $.j_;
													}),
												A2(
													$author$project$Lib$Form$Form$append,
													$author$project$Forms$CommonForms$idField,
													$author$project$Lib$Form$Form$succeed($author$project$Entity$Environment$Environment)))))))))))));
};
var $author$project$Pages$Main$EnvironmentsView$renderEditEnvironmentForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$EnvironmentsView$EditFormBackButtonClicked,
			'Muokkaa ympäristöä',
			_List_fromArray(
				[
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
					{
						gk: A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Pages$Main$EnvironmentsView$BeginSaveEnvironment,
							$author$project$Forms$EnvironmentForms$makeEnvironmentForm(config)),
						gl: formModel,
						db: 'edit_environment',
						x: $author$project$Pages$Main$EnvironmentsView$EnvironmentFormChanged
					})
				]));
	});
var $author$project$Pages$Main$EnvironmentsView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$EnvironmentsView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$EnvironmentsView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$EnvironmentsView$BeginSaveGroup, $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm),
					gl: formModel,
					db: 'edit_environment_group',
					x: $author$project$Pages$Main$EnvironmentsView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$EnvironmentsView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$EnvironmentsView$renderEditEnvironmentForm(formModel),
					A2($author$project$Lib$RemoteData$map, $author$project$Forms$EnvironmentForms$EnvironmentFormConfig, repository.iy));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$EnvironmentsView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$EnvironmentsView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$EnvironmentsView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$EnvironmentsView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $author$project$Pages$Main$EnvironmentsView$renderModal = function (model) {
	var _v0 = model.bO;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$messageModal(config);
		default:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$confirmationModal(config);
	}
};
var $author$project$Pages$Main$EnvironmentsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Main$EnvironmentsView$renderModal(model),
					A2($author$project$Pages$Main$EnvironmentsView$renderListingView, model, repository),
					A2($author$project$Pages$Main$EnvironmentsView$renderEditView, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$environmentsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$EnvironmentsMsg,
		A2($author$project$Pages$Main$EnvironmentsView$view, model.bt, model.n));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Column = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$col = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Column(
			{f_: children, aT: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Border$all = $elm$html$Html$Attributes$class('border');
var $author$project$Pages$Main$LogsView$JsonTreeStateChanged = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Lib$JsonTree$defaultColors = {aE: 'firebrick', aR: 'gray', aS: 'blue', aY: '#fafad2', a3: 'green'};
var $author$project$Lib$JsonTree$State = $elm$core$Basics$identity;
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Lib$JsonTree$stateFullyExpanded = $elm$core$Set$fromList(_List_Nil);
var $author$project$Lib$JsonTree$defaultState = $author$project$Lib$JsonTree$stateFullyExpanded;
var $author$project$Lib$JsonTree$TKeyValuePairs = function (a) {
	return {$: 4, a: a};
};
var $author$project$Lib$JsonTree$TList = function (a) {
	return {$: 3, a: a};
};
var $author$project$Lib$JsonTree$annotate = F2(
	function (pathSoFar, node) {
		var annotateList = F2(
			function (index, val) {
				return A2(
					$author$project$Lib$JsonTree$annotate,
					pathSoFar + ('[' + ($elm$core$String$fromInt(index) + ']')),
					val);
			});
		var annotateKeyValuePairs = F2(
			function (fieldName, val) {
				return A2($author$project$Lib$JsonTree$annotate, pathSoFar + ('.' + fieldName), val);
			});
		var _v0 = node.mY;
		switch (_v0.$) {
			case 0:
				return _Utils_update(
					node,
					{q: pathSoFar});
			case 1:
				return _Utils_update(
					node,
					{q: pathSoFar});
			case 2:
				return _Utils_update(
					node,
					{q: pathSoFar});
			case 5:
				return _Utils_update(
					node,
					{q: pathSoFar});
			case 3:
				var children = _v0.a;
				return _Utils_update(
					node,
					{
						q: pathSoFar,
						mY: $author$project$Lib$JsonTree$TList(
							A2($elm$core$List$indexedMap, annotateList, children))
					});
			default:
				var keyValuePairs = _v0.a;
				return _Utils_update(
					node,
					{
						q: pathSoFar,
						mY: $author$project$Lib$JsonTree$TKeyValuePairs(
							A2(
								$elm$core$List$map,
								function (_v1) {
									var key = _v1.a;
									var node_ = _v1.b;
									return _Utils_Tuple2(
										key,
										A2(annotateKeyValuePairs, key, node_));
								},
								keyValuePairs))
					});
		}
	});
var $author$project$Lib$JsonTree$TBool = function (a) {
	return {$: 2, a: a};
};
var $author$project$Lib$JsonTree$TFloat = function (a) {
	return {$: 1, a: a};
};
var $author$project$Lib$JsonTree$TNull = {$: 5};
var $author$project$Lib$JsonTree$TString = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$lazy = function (thunk) {
	return A2(
		$elm$json$Json$Decode$andThen,
		thunk,
		$elm$json$Json$Decode$succeed(0));
};
function $author$project$Lib$JsonTree$cyclic$coreDecoder() {
	var makeNode = function (v) {
		return {q: '', mY: v};
	};
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, makeNode, $author$project$Lib$JsonTree$TString),
				$elm$json$Json$Decode$string),
				A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, makeNode, $author$project$Lib$JsonTree$TFloat),
				$elm$json$Json$Decode$float),
				A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, makeNode, $author$project$Lib$JsonTree$TBool),
				$elm$json$Json$Decode$bool),
				A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, makeNode, $author$project$Lib$JsonTree$TList),
				$elm$json$Json$Decode$list(
					$elm$json$Json$Decode$lazy(
						function (_v0) {
							return $author$project$Lib$JsonTree$cyclic$coreDecoder();
						}))),
				A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeL, makeNode, $author$project$Lib$JsonTree$TKeyValuePairs),
				$elm$json$Json$Decode$keyValuePairs(
					$elm$json$Json$Decode$lazy(
						function (_v1) {
							return $author$project$Lib$JsonTree$cyclic$coreDecoder();
						}))),
				$elm$json$Json$Decode$null(
				makeNode($author$project$Lib$JsonTree$TNull))
			]));
}
var $author$project$Lib$JsonTree$coreDecoder = $author$project$Lib$JsonTree$cyclic$coreDecoder();
$author$project$Lib$JsonTree$cyclic$coreDecoder = function () {
	return $author$project$Lib$JsonTree$coreDecoder;
};
var $author$project$Lib$JsonTree$parseValue = function (json) {
	var rootKeyPath = '';
	var decoder = A2(
		$elm$json$Json$Decode$map,
		$author$project$Lib$JsonTree$annotate(rootKeyPath),
		$author$project$Lib$JsonTree$coreDecoder);
	return A2($elm$json$Json$Decode$decodeValue, decoder, json);
};
var $author$project$Lib$JsonTree$parseString = function (string) {
	return A2(
		$elm$core$Result$andThen,
		$author$project$Lib$JsonTree$parseValue,
		A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, string));
};
var $author$project$Lib$JsonTree$css = {
	aE: function (colors) {
		return _List_fromArray(
			[
				_Utils_Tuple2('color', colors.aE)
			]);
	},
	eJ: _List_fromArray(
		[
			_Utils_Tuple2('position', 'absolute'),
			_Utils_Tuple2('cursor', 'pointer'),
			_Utils_Tuple2('top', '1px'),
			_Utils_Tuple2('left', '-15px')
		]),
	e_: _List_fromArray(
		[
			_Utils_Tuple2('font-weight', 'bold')
		]),
	dm: _List_fromArray(
		[
			_Utils_Tuple2('position', 'relative')
		]),
	aR: function (colors) {
		return _List_fromArray(
			[
				_Utils_Tuple2('color', colors.aR)
			]);
	},
	aS: function (colors) {
		return _List_fromArray(
			[
				_Utils_Tuple2('color', colors.aS)
			]);
	},
	fy: _List_fromArray(
		[
			_Utils_Tuple2('font-family', 'monospace'),
			_Utils_Tuple2('white-space', 'pre')
		]),
	aY: function (colors) {
		return _List_fromArray(
			[
				_Utils_Tuple2('background-color', colors.aY),
				_Utils_Tuple2('cursor', 'pointer')
			]);
	},
	a3: function (colors) {
		return _List_fromArray(
			[
				_Utils_Tuple2('color', colors.a3)
			]);
	},
	ep: _List_fromArray(
		[
			_Utils_Tuple2('list-style-type', 'none'),
			_Utils_Tuple2('margin-left', '26px'),
			_Utils_Tuple2('padding-left', '0px')
		])
};
var $author$project$Lib$JsonTree$selectableNodeClass = 'selectableJsonTreeNode';
var $author$project$Lib$JsonTree$hoverStyles = function (config) {
	var selectableStyleString = A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			function (_v0) {
				var name = _v0.a;
				var value = _v0.b;
				return name + (': ' + (value + ';'));
			},
			$author$project$Lib$JsonTree$css.aY(config.eK)));
	var styleBody = '.' + ($author$project$Lib$JsonTree$selectableNodeClass + (':hover {\n' + (selectableStyleString + '\n}\n')));
	return A3(
		$elm$html$Html$node,
		'style',
		_List_Nil,
		_List_fromArray(
			[
				$elm$html$Html$text(styleBody)
			]));
};
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $author$project$Lib$JsonTree$styleList = function (styles) {
	return A2(
		$elm$core$List$map,
		function (_v0) {
			var name = _v0.a;
			var value = _v0.b;
			return A2($elm$html$Html$Attributes$style, name, value);
		},
		styles);
};
var $author$project$Lib$JsonTree$isCollapsed = F2(
	function (keyPath, state) {
		var visiblePaths = state;
		return !(A2($elm$core$Set$member, keyPath, visiblePaths) || (keyPath === ''));
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Lib$JsonTree$collapse = F2(
	function (keyPath, state) {
		var visiblePaths = state;
		return A2($elm$core$Set$remove, keyPath, visiblePaths);
	});
var $author$project$Lib$JsonTree$lazyStateChangeOnClick = F2(
	function (newStateThunk, toMsg) {
		var force = function (thunk) {
			return thunk(0);
		};
		return A2(
			$elm$html$Html$Events$on,
			'click',
			A2(
				$elm$json$Json$Decode$map,
				A2($elm$core$Basics$composeR, force, toMsg),
				$elm$json$Json$Decode$succeed(newStateThunk)));
	});
var $author$project$Lib$JsonTree$viewCollapser = F4(
	function (depth, config, newStateThunk, displayText) {
		return (!depth) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$span,
			A2(
				$elm$core$List$cons,
				A2($author$project$Lib$JsonTree$lazyStateChangeOnClick, newStateThunk, config.hg),
				$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.eJ)),
			_List_fromArray(
				[
					$elm$html$Html$text(displayText)
				]));
	});
var $author$project$Lib$JsonTree$viewCollapseButton = F4(
	function (depth, keyPath, config, state) {
		return A4(
			$author$project$Lib$JsonTree$viewCollapser,
			depth,
			config,
			function (_v0) {
				return A2($author$project$Lib$JsonTree$collapse, keyPath, state);
			},
			'-');
	});
var $author$project$Lib$JsonTree$expand = F2(
	function (keyPath, state) {
		var visiblePaths = state;
		return A2($elm$core$Set$insert, keyPath, visiblePaths);
	});
var $author$project$Lib$JsonTree$viewExpandButton = F4(
	function (depth, keyPath, config, state) {
		return A4(
			$author$project$Lib$JsonTree$viewCollapser,
			depth,
			config,
			function (_v0) {
				return A2($author$project$Lib$JsonTree$expand, keyPath, state);
			},
			'+');
	});
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $author$project$Lib$JsonTree$viewScalar = F4(
	function (someCss, str, node, config) {
		return $elm$core$List$singleton(
			A2(
				$elm$html$Html$span,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(node.q)
						]),
					_Utils_ap(
						$author$project$Lib$JsonTree$styleList(someCss),
						function () {
							var _v0 = config.gK;
							if (!_v0.$) {
								var onSelect = _v0.a;
								return _List_fromArray(
									[
										$elm$html$Html$Events$onClick(
										onSelect(node.q)),
										$elm$html$Html$Attributes$class($author$project$Lib$JsonTree$selectableNodeClass)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				_List_fromArray(
					[
						$elm$html$Html$text(str)
					])));
	});
var $author$project$Lib$JsonTree$viewArray = F5(
	function (depth, nodes, keyPath, config, state) {
		var viewListItem = function (node) {
			return A2(
				$elm$html$Html$li,
				$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.dm),
				A2(
					$elm$core$List$append,
					A4($author$project$Lib$JsonTree$viewNodeInternal, depth + 1, config, node, state),
					_List_fromArray(
						[
							$elm$html$Html$text(',')
						])));
		};
		var innerContent = $elm$core$List$isEmpty(nodes) ? _List_Nil : (A2($author$project$Lib$JsonTree$isCollapsed, keyPath, state) ? _List_fromArray(
			[
				A4($author$project$Lib$JsonTree$viewExpandButton, depth, keyPath, config, state),
				$elm$html$Html$text('…')
			]) : _List_fromArray(
			[
				A4($author$project$Lib$JsonTree$viewCollapseButton, depth, keyPath, config, state),
				A2(
				$elm$html$Html$ul,
				$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.ep),
				A2($elm$core$List$map, viewListItem, nodes))
			]));
		return _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$text('[')
				]),
			_Utils_ap(
				innerContent,
				_List_fromArray(
					[
						$elm$html$Html$text(']')
					])));
	});
var $author$project$Lib$JsonTree$viewKeyValuePairs = F5(
	function (depth, keyValuePairs, keyPath, config, state) {
		var viewListItem = function (_v1) {
			var fieldName = _v1.a;
			var node = _v1.b;
			return A2(
				$elm$html$Html$li,
				$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.dm),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.e_),
							_List_fromArray(
								[
									$elm$html$Html$text(fieldName)
								])),
							$elm$html$Html$text(': ')
						]),
					_Utils_ap(
						A4($author$project$Lib$JsonTree$viewNodeInternal, depth + 1, config, node, state),
						_List_fromArray(
							[
								$elm$html$Html$text(',')
							]))));
		};
		var innerContent = $elm$core$List$isEmpty(keyValuePairs) ? _List_Nil : (A2($author$project$Lib$JsonTree$isCollapsed, keyPath, state) ? _List_fromArray(
			[
				A4($author$project$Lib$JsonTree$viewExpandButton, depth, keyPath, config, state),
				$elm$html$Html$text('…')
			]) : _List_fromArray(
			[
				A4($author$project$Lib$JsonTree$viewCollapseButton, depth, keyPath, config, state),
				A2(
				$elm$html$Html$ul,
				$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.ep),
				A2($elm$core$List$map, viewListItem, keyValuePairs))
			]));
		return _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$text('{')
				]),
			_Utils_ap(
				innerContent,
				_List_fromArray(
					[
						$elm$html$Html$text('}')
					])));
	});
var $author$project$Lib$JsonTree$viewNodeInternal = F4(
	function (depth, config, node, state) {
		var colors = config.eK;
		var _v0 = node.mY;
		switch (_v0.$) {
			case 0:
				var str = _v0.a;
				return A4(
					$author$project$Lib$JsonTree$viewScalar,
					$author$project$Lib$JsonTree$css.a3(colors),
					'\"' + (str + '\"'),
					node,
					config);
			case 1:
				var x = _v0.a;
				return A4(
					$author$project$Lib$JsonTree$viewScalar,
					$author$project$Lib$JsonTree$css.aS(colors),
					$elm$core$String$fromFloat(x),
					node,
					config);
			case 2:
				var bool = _v0.a;
				return A4(
					$author$project$Lib$JsonTree$viewScalar,
					$author$project$Lib$JsonTree$css.aE(colors),
					$Chadtech$elm_bool_extra$Bool$Extra$toString(bool),
					node,
					config);
			case 5:
				return A4(
					$author$project$Lib$JsonTree$viewScalar,
					$author$project$Lib$JsonTree$css.aR(colors),
					'null',
					node,
					config);
			case 3:
				var nodes = _v0.a;
				return A5($author$project$Lib$JsonTree$viewArray, depth, nodes, node.q, config, state);
			default:
				var keyValuePairs = _v0.a;
				return A5($author$project$Lib$JsonTree$viewKeyValuePairs, depth, keyValuePairs, node.q, config, state);
		}
	});
var $author$project$Lib$JsonTree$view = $elm$html$Html$Lazy$lazy3(
	F3(
		function (node, config, state) {
			return A2(
				$elm$html$Html$div,
				$author$project$Lib$JsonTree$styleList($author$project$Lib$JsonTree$css.fy),
				A2(
					$elm$core$List$cons,
					$author$project$Lib$JsonTree$hoverStyles(config),
					A4($author$project$Lib$JsonTree$viewNodeInternal, 0, config, node, state)));
		}));
var $author$project$Pages$Main$LogsView$renderLogEntryJson = F3(
	function (model, index, entry) {
		var state = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Lib$JsonTree$defaultState,
			A2($elm$core$Dict$get, index, model.bH));
		var jsonTreeConfig = {
			eK: $author$project$Lib$JsonTree$defaultColors,
			gK: $elm$core$Maybe$Nothing,
			hg: $author$project$Pages$Main$LogsView$JsonTreeStateChanged(index)
		};
		return A2(
			$elm$core$Result$withDefault,
			$elm$html$Html$text('JSON-lokin lukeminen epäonnistui'),
			A2(
				$elm$core$Result$map,
				function (tree) {
					return A3($author$project$Lib$JsonTree$view, tree, jsonTreeConfig, state);
				},
				$author$project$Lib$JsonTree$parseString(entry)));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Border$top = $elm$html$Html$Attributes$class('border-top');
var $author$project$Pages$Main$LogsView$renderLogEntry = F3(
	function (model, index, entry) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Utilities$Border$top]),
			_List_fromArray(
				[
					A3($author$project$Pages$Main$LogsView$renderLogEntryJson, model, index, entry)
				]));
	});
var $author$project$Pages$Main$LogsView$renderLogEntries = F2(
	function (model, logEntries) {
		return $elm$core$List$isEmpty(logEntries) ? $elm$html$Html$text('Ei tapahtumia') : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('overflow-auto'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Size$h100,
					$rundis$elm_bootstrap$Bootstrap$Utilities$Border$all
				]),
			A2(
				$elm$core$List$indexedMap,
				$author$project$Pages$Main$LogsView$renderLogEntry(model),
				logEntries));
	});
var $author$project$Pages$Main$LogsView$renderLogEntriesWebData = function (model) {
	var _v0 = model.Q;
	switch (_v0.$) {
		case 3:
			var data = _v0.a;
			return A2($author$project$Pages$Main$LogsView$renderLogEntries, model, data.kB);
		case 0:
			return $elm$html$Html$text('Ei ladattuja tapahtumia');
		case 1:
			return $author$project$Utils$CommonView$centeredLargeSpinner;
		default:
			var error = _v0.a;
			return $elm$html$Html$text(
				$author$project$Utils$HttpUtils$httpErrorToString(error));
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width = F2(
	function (screenSize, columnCount) {
		return {f1: columnCount, g4: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$XS = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign = F2(
	function (align_, options) {
		var _v0 = align_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						cn: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						cl: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						ck: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						cj: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						cm: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset = F2(
	function (offset_, options) {
		var _v0 = offset_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						dx: $elm$core$Maybe$Just(offset_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						du: $elm$core$Maybe$Just(offset_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						dt: $elm$core$Maybe$Just(offset_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						ds: $elm$core$Maybe$Just(offset_)
					});
			default:
				return _Utils_update(
					options,
					{
						dw: $elm$core$Maybe$Just(offset_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder = F2(
	function (order_, options) {
		var _v0 = order_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						dG: $elm$core$Maybe$Just(order_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						dE: $elm$core$Maybe$Just(order_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						dD: $elm$core$Maybe$Just(order_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						dC: $elm$core$Maybe$Just(order_)
					});
			default:
				return _Utils_update(
					options,
					{
						dF: $elm$core$Maybe$Just(order_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull = F2(
	function (pull_, options) {
		var _v0 = pull_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						dS: $elm$core$Maybe$Just(pull_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						dQ: $elm$core$Maybe$Just(pull_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						dP: $elm$core$Maybe$Just(pull_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						dO: $elm$core$Maybe$Just(pull_)
					});
			default:
				return _Utils_update(
					options,
					{
						dR: $elm$core$Maybe$Just(pull_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush = F2(
	function (push_, options) {
		var _v0 = push_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						dX: $elm$core$Maybe$Just(push_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						dV: $elm$core$Maybe$Just(push_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						dU: $elm$core$Maybe$Just(push_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						dT: $elm$core$Maybe$Just(push_)
					});
			default:
				return _Utils_update(
					options,
					{
						dW: $elm$core$Maybe$Just(push_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth = F2(
	function (width_, options) {
		var _v0 = width_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						cf: $elm$core$Maybe$Just(width_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						cd: $elm$core$Maybe$Just(width_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						cc: $elm$core$Maybe$Just(width_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						cb: $elm$core$Maybe$Just(width_)
					});
			default:
				return _Utils_update(
					options,
					{
						ce: $elm$core$Maybe$Just(width_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 6:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs)
					});
			case 0:
				var width_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth, width_, options);
			case 1:
				var offset_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset, offset_, options);
			case 2:
				var pull_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull, pull_, options);
			case 3:
				var push_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush, push_, options);
			case 4:
				var order_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder, order_, options);
			case 5:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign, align, options);
			default:
				var align = modifier.a;
				return _Utils_update(
					options,
					{
						ej: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('1');
		case 2:
			return $elm$core$Maybe$Just('2');
		case 3:
			return $elm$core$Maybe$Just('3');
		case 4:
			return $elm$core$Maybe$Just('4');
		case 5:
			return $elm$core$Maybe$Just('5');
		case 6:
			return $elm$core$Maybe$Just('6');
		case 7:
			return $elm$core$Maybe$Just('7');
		case 8:
			return $elm$core$Maybe$Just('8');
		case 9:
			return $elm$core$Maybe$Just('9');
		case 10:
			return $elm$core$Maybe$Just('10');
		case 11:
			return $elm$core$Maybe$Just('11');
		case 12:
			return $elm$core$Maybe$Just('12');
		default:
			return $elm$core$Maybe$Just('auto');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass = function (_v0) {
	var screenSize = _v0.g4;
	var columnCount = _v0.f1;
	return $elm$html$Html$Attributes$class(
		'col' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption(columnCount)))));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes = function (widths) {
	var width_ = function (w) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass, w);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, width_, widths));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions = {cj: $elm$core$Maybe$Nothing, ck: $elm$core$Maybe$Nothing, cl: $elm$core$Maybe$Nothing, cm: $elm$core$Maybe$Nothing, cn: $elm$core$Maybe$Nothing, ba: _List_Nil, ds: $elm$core$Maybe$Nothing, dt: $elm$core$Maybe$Nothing, du: $elm$core$Maybe$Nothing, dw: $elm$core$Maybe$Nothing, dx: $elm$core$Maybe$Nothing, dC: $elm$core$Maybe$Nothing, dD: $elm$core$Maybe$Nothing, dE: $elm$core$Maybe$Nothing, dF: $elm$core$Maybe$Nothing, dG: $elm$core$Maybe$Nothing, dO: $elm$core$Maybe$Nothing, dP: $elm$core$Maybe$Nothing, dQ: $elm$core$Maybe$Nothing, dR: $elm$core$Maybe$Nothing, dS: $elm$core$Maybe$Nothing, dT: $elm$core$Maybe$Nothing, dU: $elm$core$Maybe$Nothing, dV: $elm$core$Maybe$Nothing, dW: $elm$core$Maybe$Nothing, dX: $elm$core$Maybe$Nothing, ej: $elm$core$Maybe$Nothing, cb: $elm$core$Maybe$Nothing, cc: $elm$core$Maybe$Nothing, cd: $elm$core$Maybe$Nothing, ce: $elm$core$Maybe$Nothing, cf: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		default:
			return '11';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString = function (screenSize) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize);
	if (!_v0.$) {
		var s = _v0.a;
		return '-' + (s + '-');
	} else {
		return '-';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass = function (_v0) {
	var screenSize = _v0.g4;
	var offsetCount = _v0.gI;
	return $elm$html$Html$Attributes$class(
		'offset' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption(offsetCount)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes = function (offsets) {
	var offset_ = function (m) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass, m);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, offset_, offsets));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption = function (size) {
	switch (size) {
		case 0:
			return 'first';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		case 12:
			return '12';
		default:
			return 'last';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes = function (orders) {
	var order_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.g4;
			var moveCount = m.a.aQ;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'order' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, order_, orders));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		default:
			return '12';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes = function (pulls) {
	var pull_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.g4;
			var moveCount = m.a.aQ;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'pull' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, pull_, pulls));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes = function (pushes) {
	var push_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.g4;
			var moveCount = m.a.aQ;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'push' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, push_, pushes));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption = function (dir) {
	switch (dir) {
		case 1:
			return 'center';
		case 0:
			return 'left';
		default:
			return 'right';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass = function (_v0) {
	var dir = _v0.f9;
	var size = _v0.g9;
	return $elm$html$Html$Attributes$class(
		'text' + (A2(
			$elm$core$Maybe$withDefault,
			'-',
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return '-' + (s + '-');
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size))) + $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption(dir)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		default:
			return 'end';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass = F2(
	function (prefix, _v0) {
		var align = _v0.fT;
		var screenSize = _v0.g4;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(
				prefix,
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return v + '-';
							},
							$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))),
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption(align))));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes = F2(
	function (prefix, aligns) {
		var align = function (a) {
			return A2(
				$elm$core$Maybe$map,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass(prefix),
				a);
		};
		return A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, align, aligns));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions, modifiers);
	var shouldAddDefaultXs = !$elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[options.cf, options.cd, options.cc, options.cb, options.ce])));
	return _Utils_ap(
		$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes(
			_List_fromArray(
				[
					shouldAddDefaultXs ? $elm$core$Maybe$Just(
					A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, 0, 0)) : options.cf,
					options.cd,
					options.cc,
					options.cb,
					options.ce
				])),
		_Utils_ap(
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes(
				_List_fromArray(
					[options.dx, options.du, options.dt, options.ds, options.dw])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes(
					_List_fromArray(
						[options.dS, options.dQ, options.dP, options.dO, options.dR])),
				_Utils_ap(
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes(
						_List_fromArray(
							[options.dX, options.dV, options.dU, options.dT, options.dW])),
					_Utils_ap(
						$rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes(
							_List_fromArray(
								[options.dG, options.dE, options.dD, options.dC, options.dF])),
						_Utils_ap(
							A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
								'align-self-',
								_List_fromArray(
									[options.cn, options.cl, options.ck, options.cj, options.cm])),
							_Utils_ap(
								function () {
									var _v0 = options.ej;
									if (!_v0.$) {
										var a = _v0.a;
										return _List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(a)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								options.ba)))))));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$renderCol = function (column) {
	switch (column.$) {
		case 0:
			var options = column.a.aT;
			var children = column.a.f_;
			return A2(
				$elm$html$Html$div,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
		case 1:
			var e = column.a;
			return e;
		default:
			var options = column.a.aT;
			var children = column.a.f_;
			return A3(
				$elm$html$Html$Keyed$node,
				'div',
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign = F2(
	function (align, options) {
		var _v0 = align.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						c7: $elm$core$Maybe$Just(align)
					});
			case 1:
				return _Utils_update(
					options,
					{
						c5: $elm$core$Maybe$Just(align)
					});
			case 2:
				return _Utils_update(
					options,
					{
						c4: $elm$core$Maybe$Just(align)
					});
			case 3:
				return _Utils_update(
					options,
					{
						c3: $elm$core$Maybe$Just(align)
					});
			default:
				return _Utils_update(
					options,
					{
						c6: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign = F2(
	function (align_, options) {
		var _v0 = align_.g4;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						ex: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						ev: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						eu: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						et: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						ew: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 2:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						ba: _Utils_ap(options.ba, attrs)
					});
			case 0:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign, align, options);
			default:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign, align, options);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions = {ba: _List_Nil, c3: $elm$core$Maybe$Nothing, c4: $elm$core$Maybe$Nothing, c5: $elm$core$Maybe$Nothing, c6: $elm$core$Maybe$Nothing, c7: $elm$core$Maybe$Nothing, et: $elm$core$Maybe$Nothing, eu: $elm$core$Maybe$Nothing, ev: $elm$core$Maybe$Nothing, ew: $elm$core$Maybe$Nothing, ex: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		case 2:
			return 'end';
		case 3:
			return 'around';
		default:
			return 'between';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass = function (_v0) {
	var align = _v0.fT;
	var screenSize = _v0.g4;
	return $elm$html$Html$Attributes$class(
		'justify-content-' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return v + '-';
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption(align)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes = function (aligns) {
	var align = function (a) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass, a);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, align, aligns));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		_Utils_ap(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
				'align-items-',
				_List_fromArray(
					[options.ex, options.ev, options.eu, options.et, options.ew])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes(
					_List_fromArray(
						[options.c7, options.c5, options.c4, options.c3, options.c6])),
				options.ba)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$row = F2(
	function (options, cols) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Grid$renderCol, cols));
	});
var $author$project$Pages$Main$LogsView$renderLogEntriesRow = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('overflow-hidden'),
						$rundis$elm_bootstrap$Bootstrap$Utilities$Size$h100
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('overflow-hidden'),
								$rundis$elm_bootstrap$Bootstrap$Utilities$Size$h100
							]))
					]),
				_List_fromArray(
					[
						$author$project$Pages$Main$LogsView$renderLogEntriesWebData(model)
					]))
			]));
};
var $author$project$Pages$Main$LogsView$CommandIdInputChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$LogsView$CommandKeyInputChanged = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pages$Main$LogsView$LogIdInputChanged = function (a) {
	return {$: 9, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignItemsEnd = $elm$html$Html$Attributes$class('align-items-end');
var $author$project$Pages$Main$LogsView$ErrorsOnlyInputChanged = function (a) {
	return {$: 8, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAuto = 13;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$width = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 13);
var $author$project$Pages$Main$LogsView$renderErrorsOnlyCheckBox = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$col,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$custom,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck($author$project$Pages$Main$LogsView$ErrorsOnlyInputChanged),
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(model.bv),
						$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id('log_view_errors_only')
					]),
				'Vain virheet')
			]));
};
var $author$project$Pages$Main$LogsView$NextPageButtonClicked = {$: 5};
var $author$project$Pages$Main$LogsView$PreviousPageButtonClicked = {$: 4};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Secondary = 1;
var $rundis$elm_bootstrap$Bootstrap$Button$outlineSecondary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(1));
var $author$project$Pages$Main$LogsView$PageNumberInputChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pages$Main$LogsView$getTotalLogPageCount = function (model) {
	return A2(
		$author$project$Lib$RemoteData$withDefault,
		1,
		A2(
			$author$project$Lib$RemoteData$map,
			function ($) {
				return $.mF;
			},
			model.Q));
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $elm_community$html_extra$Html$Events$Extra$onEnter = function (onEnterAction) {
	return A2(
		$elm$html$Html$Events$on,
		'keyup',
		A2(
			$elm$json$Json$Decode$andThen,
			function (keyCode) {
				return (keyCode === 13) ? $elm$json$Json$Decode$succeed(onEnterAction) : $elm$json$Json$Decode$fail(
					$elm$core$String$fromInt(keyCode));
			},
			$elm$html$Html$Events$keyCode));
};
var $author$project$Pages$Main$LogsView$renderPageNumberInput = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('page-number-input-wrapper')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Sivu'),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Pages$Main$LogsView$PageNumberInputChanged),
						$rundis$elm_bootstrap$Bootstrap$Form$Input$value(
						$elm$core$String$fromInt(model.gL)),
						$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
						_List_fromArray(
							[
								$elm_community$html_extra$Html$Events$Extra$onEnter($author$project$Pages$Main$LogsView$BeginLoadLogPage)
							]))
					])),
				$elm$html$Html$text(
				'/' + $elm$core$String$fromInt(
					$author$project$Pages$Main$LogsView$getTotalLogPageCount(model)))
			]));
};
var $author$project$Pages$Main$LogsView$PageSizeInputChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$LogsView$renderPageSizeSelect = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Select$custom,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange($author$project$Pages$Main$LogsView$PageSizeInputChanged)
					]),
				A2(
					$elm$core$List$map,
					function (pageSizeStr) {
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(
									_Utils_eq(
										$elm$core$String$fromInt(model.gM),
										pageSizeStr))
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(pageSizeStr)
								]));
					},
					_List_fromArray(
						['10', '20', '50', '100'])))
			]));
};
var $author$project$Pages$Main$LogsView$renderPaginationBarItems = F2(
	function (model, logPageData) {
		var isLastPage = _Utils_eq(model.gL, logPageData.mF);
		var isFirstPage = model.gL === 1;
		var changePageButton = F3(
			function (icon, onClick, isDisabled) {
				return A3(
					$author$project$Utils$CommonView$iconButton,
					icon,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Button$outlineSecondary,
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(onClick),
							$rundis$elm_bootstrap$Bootstrap$Button$disabled(isDisabled)
						]),
					_List_Nil);
			});
		return _List_fromArray(
			[
				A3(changePageButton, 'chevron-left', $author$project$Pages$Main$LogsView$PreviousPageButtonClicked, isFirstPage),
				$author$project$Pages$Main$LogsView$renderPageNumberInput(model),
				$author$project$Pages$Main$LogsView$renderPageSizeSelect(model),
				A3(changePageButton, 'chevron-right', $author$project$Pages$Main$LogsView$NextPageButtonClicked, isLastPage)
			]);
	});
var $author$project$Pages$Main$LogsView$renderPaginationBarItemsWebData = function (model) {
	var _v0 = model.Q;
	if (_v0.$ === 3) {
		var logPageData = _v0.a;
		return A2($author$project$Pages$Main$LogsView$renderPaginationBarItems, model, logPageData);
	} else {
		return _List_fromArray(
			[
				$elm$html$Html$text('')
			]);
	}
};
var $author$project$Pages$Main$LogsView$renderTopBarInputCol = F3(
	function (label, value, toMsg) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$col,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$label,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								])),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(toMsg),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$value(value)
								]))
						]))
				]));
	});
var $author$project$Pages$Main$LogsView$renderTopBarRow = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3, $rundis$elm_bootstrap$Bootstrap$Utilities$Flex$alignItemsEnd]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto]),
				_List_fromArray(
					[
						A3(
						$author$project$Utils$CommonView$iconButton,
						'sync',
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$primary,
								$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$LogsView$BeginLoadLogPage)
							]),
						_List_Nil)
					])),
				A3($author$project$Pages$Main$LogsView$renderTopBarInputCol, 'Loki id:', model.bL, $author$project$Pages$Main$LogsView$LogIdInputChanged),
				A3($author$project$Pages$Main$LogsView$renderTopBarInputCol, 'Komennon id:', model.bh, $author$project$Pages$Main$LogsView$CommandIdInputChanged),
				A3($author$project$Pages$Main$LogsView$renderTopBarInputCol, 'Komennon tunniste:', model.bi, $author$project$Pages$Main$LogsView$CommandKeyInputChanged),
				$author$project$Pages$Main$LogsView$renderErrorsOnlyCheckBox(model),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$xsAuto,
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('log-pagination-bar')
							]))
					]),
				$author$project$Pages$Main$LogsView$renderPaginationBarItemsWebData(model))
			]));
};
var $author$project$Pages$Main$LogsView$mainContent = function (model) {
	return _List_fromArray(
		[
			$author$project$Pages$Main$LogsView$renderTopBarRow(model),
			$author$project$Pages$Main$LogsView$renderLogEntriesRow(model)
		]);
};
var $author$project$Pages$Main$LogsView$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tab-view-root-container')
			]),
		$author$project$Pages$Main$LogsView$mainContent(model));
};
var $author$project$Pages$Main$MainPage$logsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$LogsMsg,
		$author$project$Pages$Main$LogsView$view(model.bM));
};
var $author$project$Pages$Main$MainPage$missingPermissionsView = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$m2]),
	_List_fromArray(
		[
			$elm$html$Html$text('Sinulla ei ole oikeutta tähän käyttöalueeseen')
		]));
var $author$project$Forms$PrincipalForms$PrincipalFormConfig = F2(
	function (roles, environments) {
		return {gg: environments, g0: roles};
	});
var $author$project$Pages$Main$PrincipalsView$EditFormBackButtonClicked = {$: 3};
var $author$project$Pages$Main$PrincipalsView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$PrincipalsView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$PrincipalsView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$PrincipalsView$BeginSaveGroup, $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm),
					gl: formModel,
					db: 'edit_principal_group',
					x: $author$project$Pages$Main$PrincipalsView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$PrincipalsView$PrincipalFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Entity$Environment$displayNameOrName = function (env) {
	return A2(
		$elm$core$Maybe$withDefault,
		env.k_,
		$elm_community$string_extra$String$Extra$nonBlank(env.i1));
};
var $author$project$Forms$PrincipalForms$makeEnvironmentAssociationForm = function (_v0) {
	var roles = _v0.g0;
	var environments = _v0.gg;
	var selectRoleField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Rooli',
			aT: $elm$core$Dict$toList(
				A2(
					$author$project$Utils$DictUtils$mapValues,
					function ($) {
						return $.k_;
					},
					A2(
						$author$project$Utils$DictUtils$filterByValue,
						function ($) {
							return $.kr;
						},
						roles))),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lS: value});
				}),
			mY: function ($) {
				return $.lS;
			}
		});
	var selectEnvField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Ympäristö',
			aT: $elm$core$Dict$toList(
				A2($author$project$Utils$DictUtils$mapValues, $author$project$Entity$Environment$displayNameOrName, environments)),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jp: value});
				}),
			mY: function ($) {
				return $.jp;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		selectRoleField,
		A2(
			$author$project$Lib$Form$Form$append,
			selectEnvField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$Environment$EnvironmentAssociation)));
};
var $author$project$Lib$Form$Form$simpleConstantField = function (_v0) {
	var value = _v0.mY;
	var label = _v0.kv;
	return A2(
		$author$project$Lib$Form$Base$ConstantField$form,
		$author$project$Lib$Form$Form$Constant,
		{
			ba: {kv: label},
			U: $elm$core$Result$Ok,
			a: F2(
				function (_v1, values) {
					return values;
				}),
			mY: value
		});
};
var $author$project$Forms$PrincipalForms$principalTypeField = function () {
	var mfaEnabledField = $author$project$Lib$Form$Form$meta(
		function (_v4) {
			var principalType = _v4.lw;
			if (!principalType.$) {
				var email = principalType.a.gd;
				var mfaEnabled = principalType.a.kT;
				return $author$project$Lib$Form$Form$simpleCheckboxField(
					{
						kv: 'Vaadi kaksivaiheinen tunnistautuminen',
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{
										lw: $author$project$Entity$PrincipalAssociation$UserPrincipal(
											{gd: email, kT: value})
									});
							}),
						mY: $elm$core$Basics$always(mfaEnabled)
					});
			} else {
				return $author$project$Lib$Form$Form$succeed(false);
			}
		});
	var emailOrClientIdField = $author$project$Lib$Form$Form$simpleConstantField(
		{
			kv: 'Sähköposti',
			mY: function (_v2) {
				var principalType = _v2.lw;
				if (!principalType.$) {
					var email = principalType.a.gd;
					return email;
				} else {
					var clientId = principalType.a.ie;
					return 'API-token (' + (clientId + ')');
				}
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		mfaEnabledField,
		A2(
			$author$project$Lib$Form$Form$append,
			emailOrClientIdField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommonForms$constantMetaField(
					function ($) {
						return $.lw;
					}),
				$author$project$Lib$Form$Form$succeed(
					F3(
						function (principalType, _v0, mfaEnabled) {
							if (!principalType.$) {
								var email = principalType.a.gd;
								return $author$project$Entity$PrincipalAssociation$UserPrincipal(
									{gd: email, kT: mfaEnabled});
							} else {
								var clientId = principalType.a.ie;
								return principalType;
							}
						})))));
}();
var $author$project$Forms$PrincipalForms$userSettingsForm = function () {
	var userParametersListForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {kt: '', mY: $elm$core$Maybe$Nothing},
			kv: 'Käyttäjän parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mV: value});
				}),
			mY: function ($) {
				return $.mV;
			}
		},
		function (_v0) {
			return $author$project$Forms$CommandParameterForm$commandParameterField(0);
		});
	var useNfcField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Käytä NFC-lukijaa',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{mS: value});
				}),
			mY: function ($) {
				return $.mS;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		useNfcField,
		A2(
			$author$project$Lib$Form$Form$append,
			userParametersListForm,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$PrincipalAssociation$UserSettings)));
}();
var $author$project$Forms$PrincipalForms$makePrincipalForm = function (config) {
	var sessionExpirationDurationField = $author$project$Forms$CommonForms$limitedIntegerField(
		{
			kv: 'Session kesto (vuorokautta)',
			kI: 365,
			kU: 1,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{l5: value});
				}),
			mY: function ($) {
				return $.l5;
			}
		});
	var principalStateForm = A2(
		$author$project$Lib$Form$Form$andThen,
		function (_v3) {
			return $author$project$Forms$CommonForms$constantMetaField(
				function ($) {
					return $.lv;
				});
		},
		$author$project$Lib$Form$Form$simpleConstantField(
			{
				kv: 'Tila',
				mY: function (_v1) {
					var principalState = _v1.lv;
					if (principalState.$ === 1) {
						var principalId = principalState.a.lu;
						return 'Valmis, käyttäjän ID: (' + (principalId + ')');
					} else {
						var preparedPrincipalId = principalState.a.ls;
						return 'Odottaa ensimmäistä kirjautumista';
					}
				}
			}));
	var environmentAssociationsForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {jp: $author$project$Entity$Common$nonExistentId, lS: $author$project$Entity$Common$nonExistentId},
			kv: 'Ympäristöt',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jo: value});
				}),
			mY: function ($) {
				return $.jo;
			}
		},
		function (_v0) {
			return $author$project$Forms$PrincipalForms$makeEnvironmentAssociationForm(config);
		});
	var descriptionField = $author$project$Lib$Form$Form$textareaField(
		{
			ba: {hS: $elm$core$Maybe$Nothing, kv: 'Kuvaus', lm: ''},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i$: value});
				}),
			mY: function ($) {
				return $.i$;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{mW: value});
					}),
				mY: function ($) {
					return $.mW;
				}
			},
			$author$project$Forms$PrincipalForms$userSettingsForm),
		A2(
			$author$project$Lib$Form$Form$append,
			environmentAssociationsForm,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Lib$Form$Form$allowEmptyString(descriptionField),
				A2(
					$author$project$Lib$Form$Form$append,
					sessionExpirationDurationField,
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$PrincipalForms$principalTypeField,
						A2(
							$author$project$Lib$Form$Form$append,
							principalStateForm,
							A2(
								$author$project$Lib$Form$Form$append,
								$author$project$Forms$CommonForms$constantMetaField(
									function ($) {
										return $.dM;
									}),
								A2(
									$author$project$Lib$Form$Form$append,
									$author$project$Forms$CommonForms$constantMetaField(
										function ($) {
											return $.j_;
										}),
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$idField,
										$author$project$Lib$Form$Form$succeed($author$project$Entity$PrincipalAssociation$PrincipalAssociation))))))))));
};
var $author$project$Pages$Main$PrincipalsView$renderEditPrincipalForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$PrincipalsView$EditFormBackButtonClicked,
			'Muokkaa yhteyttä',
			_List_fromArray(
				[
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
					{
						gk: A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Pages$Main$PrincipalsView$BeginSavePrincipal,
							$author$project$Forms$PrincipalForms$makePrincipalForm(config)),
						gl: formModel,
						db: 'edit_principal',
						x: $author$project$Pages$Main$PrincipalsView$PrincipalFormChanged
					})
				]));
	});
var $author$project$Pages$Main$PrincipalsView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$PrincipalsView$renderEditPrincipalForm(formModel),
					A3($author$project$Lib$RemoteData$map2, $author$project$Forms$PrincipalForms$PrincipalFormConfig, repository.g0, repository.gg));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$PrincipalsView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$PrincipalsView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$PrincipalsView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$PrincipalsView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $author$project$Pages$Main$PrincipalsView$AddNewPrincipalFormChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Main$PrincipalsView$AddNewPrincipalFormSubmitted = function (a) {
	return {$: 9, a: a};
};
var $author$project$Forms$CreateNewPrincipalAssociationForm$NewPrincipalAssociationFormResult = F3(
	function (principalType, identityProviderId, password) {
		return {kc: identityProviderId, fl: password, lw: principalType};
	});
var $author$project$Entity$IdentityProvider$getIdentityProviderDescription = function (identityProvider) {
	var _v0 = identityProvider.mM;
	if (!_v0) {
		return 'Sisäinen';
	} else {
		return 'Ulkoinen';
	}
};
var $author$project$Forms$CreateNewPrincipalAssociationForm$selectIdentityProviderIdField = function (allProviders) {
	var selectProviderField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Käyttäjätietokanta',
				aT: A2(
					$elm$core$List$map,
					function (provider) {
						return _Utils_Tuple2(
							provider.db,
							$author$project$Entity$IdentityProvider$getIdentityProviderDescription(provider));
					},
					allProviders),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values_) {
					return _Utils_update(
						values_,
						{kc: value});
				}),
			mY: function ($) {
				return $.kc;
			}
		});
	return $author$project$Lib$Form$Form$meta(
		function (_v0) {
			var principalTypeStr = _v0.at;
			if (principalTypeStr === 'user') {
				return selectProviderField;
			} else {
				return $author$project$Lib$Form$Form$succeed('');
			}
		});
};
var $author$project$Lib$Form$Form$TextEmail = 1;
var $author$project$Lib$Form$Form$emailField = $author$project$Lib$Form$Base$TextField$form(
	$author$project$Lib$Form$Form$Text(1));
var $author$project$Forms$CreateNewPrincipalAssociationForm$selectPrincipalTypeField = function () {
	var typeField = $author$project$Lib$Form$Form$meta(
		function (values) {
			return $author$project$Lib$Form$Form$selectField(
				{
					ba: {
						kv: 'Tyyppi',
						aT: _List_fromArray(
							[
								_Utils_Tuple2('user', 'Käyttäjä'),
								_Utils_Tuple2('token', 'API-token')
							]),
						lm: $elm$core$Maybe$Nothing
					},
					U: function (str) {
						switch (str) {
							case 'user':
								return $elm$core$Result$Ok(
									$author$project$Entity$PrincipalAssociation$UserPrincipal(
										{gd: values.b7, kT: false}));
							case 'token':
								return $elm$core$Result$Ok(
									$author$project$Entity$PrincipalAssociation$TokenPrincipal(
										{ie: ''}));
							default:
								return $elm$core$Result$Err('Valitse tyyppi');
						}
					},
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{at: value});
						}),
					mY: function ($) {
						return $.at;
					}
				});
		});
	var emailField = $author$project$Lib$Form$Form$meta(
		function (_v1) {
			var principalTypeStr = _v1.at;
			if (principalTypeStr === 'user') {
				return $author$project$Lib$Form$Form$emailField(
					{
						ba: {hS: $elm$core$Maybe$Nothing, kv: 'Sähköposti', lm: ''},
						U: $elm$core$Result$Ok,
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{b7: value});
							}),
						mY: function ($) {
							return $.b7;
						}
					});
			} else {
				return $author$project$Lib$Form$Form$succeed('');
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		emailField,
		A2(
			$author$project$Lib$Form$Form$append,
			typeField,
			$author$project$Lib$Form$Form$succeed(
				F2(
					function (type_, _v0) {
						return type_;
					}))));
}();
var $author$project$Forms$CreateNewPrincipalAssociationForm$userPasswordField = function (identityProviders) {
	var field = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values_) {
					return _Utils_update(
						values_,
						{fl: value});
				}),
			mY: function ($) {
				return $.fl;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Aseta salasana',
			$author$project$Lib$Form$Form$simplePasswordField(
				{
					hS: $elm$core$Maybe$Nothing,
					kv: 'Salasana',
					a: F2(
						function (value, _v2) {
							return value;
						}),
					mY: $elm$core$Basics$identity
				})));
	return $author$project$Lib$Form$Form$meta(
		function (_v0) {
			var principalTypeStr = _v0.at;
			var identityProviderId = _v0.kc;
			var internalIdentityProviderSelected = A2(
				$elm$core$Maybe$withDefault,
				true,
				A2(
					$elm$core$Maybe$map,
					$author$project$Entity$IdentityProvider$isInternalIdentityProvider,
					A2(
						$elm_community$list_extra$List$Extra$find,
						function (idp) {
							return _Utils_eq(idp.db, identityProviderId);
						},
						identityProviders)));
			var _v1 = _Utils_Tuple2(principalTypeStr, internalIdentityProviderSelected);
			if ((_v1.a === 'user') && _v1.b) {
				return field;
			} else {
				return $author$project$Lib$Form$Form$succeed($elm$core$Maybe$Nothing);
			}
		});
};
var $author$project$Forms$CreateNewPrincipalAssociationForm$makeNewPrincipalAssociationForm = function (allIdentityProviders) {
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CreateNewPrincipalAssociationForm$userPasswordField(allIdentityProviders),
		A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Forms$CreateNewPrincipalAssociationForm$selectIdentityProviderIdField(allIdentityProviders),
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CreateNewPrincipalAssociationForm$selectPrincipalTypeField,
				$author$project$Lib$Form$Form$succeed($author$project$Forms$CreateNewPrincipalAssociationForm$NewPrincipalAssociationFormResult))));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb0 = $elm$html$Html$Attributes$class('mb-0');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2 = $elm$html$Html$Attributes$class('mt-2');
var $elm$html$Html$p = _VirtualDom_node('p');
var $author$project$Pages$Main$PrincipalsView$renderNewPrincipalForm = F2(
	function (repository, _v0) {
		var formModel = _v0.gl;
		var errorText = _v0.aH;
		var errorTextHtml = $elm$core$String$isEmpty(errorText) ? $elm$html$Html$text('') : A2(
			$elm$html$Html$p,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-danger'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2,
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb0
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(errorText)
				]));
		var renderFormContent = function (identityProviders) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('d-flex flex-column')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-1')
							]),
						_List_fromArray(
							[
								A3(
								$author$project$Lib$Form$FormView$asHtml,
								{fQ: 'OK', db: 'add_new_principal_association', bK: 'Ladataan', x: $author$project$Pages$Main$PrincipalsView$AddNewPrincipalFormChanged, b$: true, hq: 1},
								A2(
									$author$project$Lib$Form$Form$map,
									$author$project$Pages$Main$PrincipalsView$AddNewPrincipalFormSubmitted,
									$author$project$Forms$CreateNewPrincipalAssociationForm$makeNewPrincipalAssociationForm(identityProviders)),
								formModel)
							])),
						errorTextHtml
					]));
		};
		return A2(
			$author$project$Utils$CommonView$renderWebData,
			function (identityProviders) {
				return A3(
					$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
					'Uusi käyttäjä',
					$author$project$Pages$Main$PrincipalsView$CloseCurrentModal,
					renderFormContent(identityProviders));
			},
			repository.e5);
	});
var $author$project$Utils$CommonView$boldText = function (content) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('font-weight-bold')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(content)
			]));
};
var $author$project$Pages$Main$PrincipalsView$viewRevealApiTokenModalBody = function (apiToken) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$p,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Tässä on uuden API-tokenin tiedot. Tallenna salasana - sitä ei voi katsoa tämän ikkunan sulkemisen jälkeen')
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Id: ')
						])),
					$author$project$Utils$CommonView$boldText(apiToken.ie)
				])),
			A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Salasana: ')
						])),
					$author$project$Utils$CommonView$boldText(apiToken.$8)
				]))
		]);
};
var $author$project$Pages$Main$PrincipalsView$renderModal = F2(
	function (model, repository) {
		var _v0 = model.bO;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 4:
				var config = _v0.a;
				return $author$project$Utils$CommonModals$messageModal(config);
			case 3:
				var config = _v0.a;
				return $author$project$Utils$CommonModals$confirmationModal(config);
			case 1:
				var modalState = _v0.a;
				return A2($author$project$Pages$Main$PrincipalsView$renderNewPrincipalForm, repository, modalState);
			default:
				var apiToken = _v0.a;
				var principal = _v0.b;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Modal$view,
					$rundis$elm_bootstrap$Bootstrap$Modal$shown,
					A3(
						$rundis$elm_bootstrap$Bootstrap$Modal$footer,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Button$button,
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Button$onClick(
										$author$project$Pages$Main$PrincipalsView$BeginEditPrincipal(principal)),
										$rundis$elm_bootstrap$Bootstrap$Button$primary
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('OK')
									]))
							]),
						A3(
							$rundis$elm_bootstrap$Bootstrap$Modal$body,
							_List_Nil,
							$author$project$Pages$Main$PrincipalsView$viewRevealApiTokenModalBody(apiToken),
							A3(
								$rundis$elm_bootstrap$Bootstrap$Modal$h3,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('API-Token')
									]),
								A2(
									$rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick,
									false,
									$rundis$elm_bootstrap$Bootstrap$Modal$config($author$project$Pages$Main$PrincipalsView$CloseCurrentModal))))));
		}
	});
var $author$project$Entity$PrincipalAssociation$isUserPrincipal = function (principalAssociation) {
	var _v0 = principalAssociation.lw;
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pages$Main$PrincipalsView$renderSummary = F2(
	function (model, repository) {
		var _v0 = _Utils_Tuple2(repository.fq, model.j);
		if ((_v0.a.$ === 3) && (!_v0.b.$)) {
			var principals = _v0.a.a;
			var _v1 = _v0.b;
			var userCount = $elm$core$List$length(
				A2(
					$elm$core$List$filter,
					$author$project$Entity$PrincipalAssociation$isUserPrincipal,
					$elm$core$Dict$values(principals)));
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						'Käyttäjiä yhteensä: ' + $elm$core$String$fromInt(userCount))
					]));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $author$project$Pages$Main$PrincipalsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Main$PrincipalsView$renderModal, model, repository),
					A2($author$project$Pages$Main$PrincipalsView$renderListingView, model, repository),
					A2($author$project$Pages$Main$PrincipalsView$renderEditView, model, repository),
					A2($author$project$Pages$Main$PrincipalsView$renderSummary, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$principalsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$PrincipalsMsg,
		A2($author$project$Pages$Main$PrincipalsView$view, model.bV, model.n));
};
var $author$project$Pages$Main$RevisionsView$OpenCopyBranchModal = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Pages$Main$RevisionsView$OpenCreateRevisionModal = {$: 6};
var $author$project$Pages$Main$RevisionsView$OpenRestoreRevisionModal = {$: 10};
var $author$project$Pages$Main$RevisionsView$RequestCurrentTime = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pages$Main$RevisionsView$renderActionBar = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('action-bar-container')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Utils$CommonView$iconButton,
				'sync',
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$primary,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$RevisionsView$BeginLoadRevisions)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Päivitä')
					])),
				A3(
				$author$project$Utils$CommonView$iconButton,
				'plus',
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$primary,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$RevisionsView$OpenCreateRevisionModal)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Uusi')
					])),
				A3(
				$author$project$Utils$CommonView$iconButton,
				'download',
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$primary,
						$rundis$elm_bootstrap$Bootstrap$Button$disabled(
						_Utils_eq(model.av, $elm$core$Maybe$Nothing)),
						$rundis$elm_bootstrap$Bootstrap$Button$onClick($author$project$Pages$Main$RevisionsView$OpenRestoreRevisionModal)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Palauta')
					])),
				A3(
				$author$project$Utils$CommonView$iconButton,
				'clone',
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$primary,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick(
						$author$project$Pages$Main$RevisionsView$RequestCurrentTime($author$project$Pages$Main$RevisionsView$OpenCopyBranchModal))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Kopioi haara')
					]))
			]));
};
var $author$project$Pages$Main$RevisionsView$BeginCopyBranch = function (a) {
	return {$: 16, a: a};
};
var $author$project$Pages$Main$RevisionsView$BeginCreateNewRevision = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Main$RevisionsView$BeginRestoreRevision = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pages$Main$RevisionsView$CopyBranchFormChanged = function (a) {
	return {$: 15, a: a};
};
var $author$project$Pages$Main$RevisionsView$CreateRevisionFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$RevisionsView$RestoreRevisionFormChanged = function (a) {
	return {$: 11, a: a};
};
var $author$project$Api$RevisionApi$CopyBranchRequest = F3(
	function (sourceBranchKey, targetBranchKey, targetBackupRevisionName) {
		return {ed: sourceBranchKey, hd: targetBackupRevisionName, ei: targetBranchKey};
	});
var $author$project$Pages$Main$RevisionsView$copyBranchForm = function (_v0) {
	var allBranches = _v0.hF;
	var createBackupCheckbox = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Varmuuskopioi kohdehaara',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{bb: value});
				}),
			mY: function ($) {
				return $.bb;
			}
		});
	var branchOptions = A2(
		$elm$core$List$map,
		function (_v3) {
			var key = _v3.kt;
			var title = _v3.mC;
			return _Utils_Tuple2(key, title);
		},
		allBranches);
	var sourceBranchKeyField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {kv: 'Lähdehaara', aT: branchOptions, lm: $elm$core$Maybe$Nothing},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ed: value});
				}),
			mY: function ($) {
				return $.ed;
			}
		});
	var targetBranchKeyField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {kv: 'Kohdehaara', aT: branchOptions, lm: $elm$core$Maybe$Nothing},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ei: value});
				}),
			mY: function ($) {
				return $.ei;
			}
		});
	var backupNameTextField = $author$project$Lib$Form$Form$meta(
		function (_v2) {
			var backupTargetBranch = _v2.bb;
			return backupTargetBranch ? A2(
				$author$project$Lib$Form$Form$map,
				$elm$core$Maybe$Just,
				$author$project$Lib$Form$Form$simpleTextField(
					{
						kv: 'Varmuuskopiorevision nimi',
						a: F2(
							function (value, values) {
								return _Utils_update(
									values,
									{eh: value});
							}),
						mY: function ($) {
							return $.eh;
						}
					})) : $author$project$Lib$Form$Form$succeed($elm$core$Maybe$Nothing);
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		A2(
			$author$project$Lib$Form$Form$append,
			backupNameTextField,
			A2(
				$author$project$Lib$Form$Form$append,
				createBackupCheckbox,
				$author$project$Lib$Form$Form$succeed(
					F2(
						function (_v1, backupName) {
							return backupName;
						})))),
		A2(
			$author$project$Lib$Form$Form$append,
			targetBranchKeyField,
			A2(
				$author$project$Lib$Form$Form$append,
				sourceBranchKeyField,
				$author$project$Lib$Form$Form$succeed($author$project$Api$RevisionApi$CopyBranchRequest))));
};
var $author$project$Api$RevisionApi$CreateRevisionRequest = F3(
	function (sourceBranchKey, revisionName, revisionDescription) {
		return {gZ: revisionDescription, g$: revisionName, ed: sourceBranchKey};
	});
var $author$project$Lib$Form$Form$simpleTextareaField = function (_v0) {
	var value = _v0.mY;
	var update = _v0.a;
	var label = _v0.kv;
	return $author$project$Lib$Form$Form$textareaField(
		{
			ba: {hS: $elm$core$Maybe$Nothing, kv: label, lm: ''},
			U: $elm$core$Result$Ok,
			a: update,
			mY: value
		});
};
var $author$project$Pages$Main$RevisionsView$createNewRevisionForm = function (_v0) {
	var allBranches = _v0.hF;
	var sourceBranchKeyField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Lähdehaara',
				aT: A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.kt;
						var title = _v1.mC;
						return _Utils_Tuple2(key, title);
					},
					allBranches),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ed: value});
				}),
			mY: function ($) {
				return $.ed;
			}
		});
	var revisionNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Revision nimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{g$: value});
				}),
			mY: function ($) {
				return $.g$;
			}
		});
	var revisionDescriptionField = $author$project$Lib$Form$Form$allowEmptyString(
		$author$project$Lib$Form$Form$simpleTextareaField(
			{
				kv: 'Kuvaus',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{gZ: value});
					}),
				mY: function ($) {
					return $.gZ;
				}
			}));
	return A2(
		$author$project$Lib$Form$Form$append,
		revisionDescriptionField,
		A2(
			$author$project$Lib$Form$Form$append,
			revisionNameField,
			A2(
				$author$project$Lib$Form$Form$append,
				sourceBranchKeyField,
				$author$project$Lib$Form$Form$succeed($author$project$Api$RevisionApi$CreateRevisionRequest))));
};
var $author$project$Api$RevisionApi$RestoreRevisionRequest = F2(
	function (targetBranchKey, revisionId) {
		return {g_: revisionId, ei: targetBranchKey};
	});
var $author$project$Pages$Main$RevisionsView$restoreRevisionForm = function (_v0) {
	var allBranches = _v0.hF;
	var targetBranchKeyField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Kohdehaara',
				aT: A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.kt;
						var title = _v1.mC;
						return _Utils_Tuple2(key, title);
					},
					allBranches),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ei: value});
				}),
			mY: function ($) {
				return $.ei;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommonForms$constantMetaField(
			function ($) {
				return $.g_;
			}),
		A2(
			$author$project$Lib$Form$Form$append,
			targetBranchKeyField,
			$author$project$Lib$Form$Form$succeed($author$project$Api$RevisionApi$RestoreRevisionRequest)));
};
var $author$project$Pages$Main$RevisionsView$renderModal = F2(
	function (config, modal) {
		switch (modal.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var config_ = modal.a;
				return $author$project$Utils$CommonModals$errorModal(config_);
			case 2:
				var formModel = modal.a;
				return A3(
					$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
					'Uusi revisio',
					$author$project$Pages$Main$RevisionsView$CloseCurrentModal,
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
						{
							gk: A2(
								$author$project$Lib$Form$Form$map,
								$author$project$Pages$Main$RevisionsView$BeginCreateNewRevision,
								$author$project$Pages$Main$RevisionsView$createNewRevisionForm(config)),
							gl: formModel,
							db: 'create_new_revision_form',
							x: $author$project$Pages$Main$RevisionsView$CreateRevisionFormChanged
						}));
			case 3:
				var formModel = modal.a;
				return A3(
					$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
					'Palauta revisio',
					$author$project$Pages$Main$RevisionsView$CloseCurrentModal,
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
						{
							gk: A2(
								$author$project$Lib$Form$Form$map,
								$author$project$Pages$Main$RevisionsView$BeginRestoreRevision,
								$author$project$Pages$Main$RevisionsView$restoreRevisionForm(config)),
							gl: formModel,
							db: 'restore_revision_form',
							x: $author$project$Pages$Main$RevisionsView$RestoreRevisionFormChanged
						}));
			default:
				var formModel = modal.a;
				return A3(
					$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
					'Kopioi haara',
					$author$project$Pages$Main$RevisionsView$CloseCurrentModal,
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
						{
							gk: A2(
								$author$project$Lib$Form$Form$map,
								$author$project$Pages$Main$RevisionsView$BeginCopyBranch,
								$author$project$Pages$Main$RevisionsView$copyBranchForm(config)),
							gl: formModel,
							db: 'copy_branch_form',
							x: $author$project$Pages$Main$RevisionsView$CopyBranchFormChanged
						}));
		}
	});
var $author$project$Pages$Main$RevisionsView$SelectRevision = function (a) {
	return {$: 2, a: a};
};
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Pages$Main$RevisionsView$renderRevisionRow = F2(
	function (model, revision) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('revision-row'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'revision-row--selected',
							_Utils_eq(
								model.av,
								$elm$core$Maybe$Just(revision.db)))
						])),
					$elm$html$Html$Events$onClick(
					$author$project$Pages$Main$RevisionsView$SelectRevision(revision.db))
				]),
			A2(
				$elm$core$List$map,
				function (content) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('revision-row-column')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(content)
							]));
				},
				_List_fromArray(
					[
						$elm$core$String$fromInt(revision.db),
						revision.k_,
						revision.i$,
						function (posix) {
						return A2($author$project$Encoding$Utils$formatTime, posix, $elm$time$Time$utc);
					}(revision.iG)
					])));
	});
var $author$project$Pages$Main$RevisionsView$renderRevisions = F2(
	function (model, revisions) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('revisions-container')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Pages$Main$RevisionsView$renderRevisionRow(model),
				revisions));
	});
var $author$project$Pages$Main$RevisionsView$renderRevisionsWebData = function (model) {
	var _v0 = model.aW;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('Revisioita ei ladattu');
		case 2:
			var err = _v0.a;
			return $elm$html$Html$text(
				$author$project$Utils$HttpUtils$httpErrorToString(err));
		case 1:
			return $author$project$Utils$CommonView$centeredLargeSpinner;
		default:
			var revisions = _v0.a;
			return A2($author$project$Pages$Main$RevisionsView$renderRevisions, model, revisions);
	}
};
var $author$project$Pages$Main$RevisionsView$mainContent = F2(
	function (config, model) {
		return _List_fromArray(
			[
				$author$project$Pages$Main$RevisionsView$renderActionBar(model),
				$author$project$Pages$Main$RevisionsView$renderRevisionsWebData(model),
				A2($author$project$Pages$Main$RevisionsView$renderModal, config, model.bO)
			]);
	});
var $author$project$Pages$Main$RevisionsView$view = F2(
	function (config, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container revision-view-root')
				]),
			A2($author$project$Pages$Main$RevisionsView$mainContent, config, model));
	});
var $author$project$Pages$Main$MainPage$revisionsView = F2(
	function (model, branches) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$MainPage$RevisionsMsg,
			A2(
				$author$project$Pages$Main$RevisionsView$view,
				{hF: branches},
				model.bW));
	});
var $author$project$Forms$RoleForms$RoleFormConfig = F3(
	function (allViews, allRoles, allCommands) {
		return {fU: allCommands, fV: allRoles, hG: allViews};
	});
var $author$project$Pages$Main$RolesView$EditFormBackButtonClicked = {$: 3};
var $author$project$Pages$Main$RolesView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Pages$Main$RolesView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$RolesView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$RolesView$BeginSaveGroup, $author$project$Forms$GenericEntityGroupForm$genericEntityGroupForm),
					gl: formModel,
					db: 'edit_role_group',
					x: $author$project$Pages$Main$RolesView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$RolesView$RoleFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Forms$RoleForms$ViewAssociationFormConfig = F2(
	function (allViews, usedViews) {
		return {hG: allViews, ho: usedViews};
	});
var $author$project$Forms$RoleForms$createDefaultViewAssociationFormValues = {
	dL: {bo: false},
	mc: true,
	m_: $author$project$Entity$Common$nonExistentId
};
var $author$project$Entity$Command$isViewComponentsCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 15) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Forms$EntitySelectFormList$makeSelectEntityForm = F2(
	function (_v0, index) {
		var allEntities = _v0.a9;
		var selectedEntities = _v0.au;
		var label = _v0.kv;
		var extraFilter = _v0.bx;
		return $author$project$Lib$Form$Form$meta(
			function (values) {
				var entityIsNotInUse = function (id) {
					return !A2($elm$core$List$member, id, selectedEntities);
				};
				var filteredEntities = A2(
					$elm$core$List$filter,
					function (_v3) {
						var id = _v3.a;
						return _Utils_eq(values.db, id) || entityIsNotInUse(id);
					},
					allEntities);
				var extraFilteredEntities = function () {
					if (!extraFilter.$) {
						var filter = extraFilter.a;
						return A2(
							$elm$core$List$filter,
							function (_v2) {
								var id = _v2.a;
								return filter(id);
							},
							filteredEntities);
					} else {
						return filteredEntities;
					}
				}();
				return $author$project$Forms$CommonForms$selectIdField(
					{
						kv: label,
						aT: extraFilteredEntities,
						a: F2(
							function (value, values_) {
								return _Utils_update(
									values_,
									{db: value});
							}),
						mY: function ($) {
							return $.db;
						}
					});
			});
	});
var $author$project$Forms$EntitySelectFormList$makeEntitySelectFormList = function (_v0) {
	var allEntities = _v0.a9;
	var listLabel = _v0.gA;
	var entitySelectLabel = _v0.gf;
	var extraFilter = _v0.bx;
	return $author$project$Lib$Form$Form$meta(
		function (values) {
			var selectSingleEntityFormConfig = {
				a9: allEntities,
				bx: extraFilter,
				kv: entitySelectLabel,
				au: A2(
					$elm$core$List$map,
					function ($) {
						return $.db;
					},
					values.au)
			};
			return A2(
				$author$project$Lib$Form$Form$simpleList,
				{
					f7: {db: $author$project$Entity$Common$nonExistentId},
					kv: listLabel,
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{au: value});
						}),
					mY: function ($) {
						return $.au;
					}
				},
				$author$project$Forms$EntitySelectFormList$makeSelectEntityForm(selectSingleEntityFormConfig));
		});
};
var $author$project$Entity$Role$defaultDataViewPermissions = {jh: false};
var $author$project$Entity$Role$defaultViewPermissions = {iO: $author$project$Entity$Role$defaultDataViewPermissions};
var $author$project$Entity$View$isContainerView = function (view) {
	var _v0 = view.mh;
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$View$isDataView = function (view) {
	var _v0 = view.mh;
	if (_v0.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Forms$RoleForms$makeViewPermissionsForm = function (view) {
	var dataViewPermissionsForm = ($author$project$Entity$View$isDataView(view) || $author$project$Entity$View$isContainerView(view)) ? A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Lib$Form$Form$simpleCheckboxField(
			{
				kv: 'Taulujen muokkausoikeus',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{bo: value});
					}),
				mY: function ($) {
					return $.bo;
				}
			}),
		$author$project$Lib$Form$Form$succeed($author$project$Entity$Role$DataViewPermissions)) : $author$project$Lib$Form$Form$succeed($author$project$Entity$Role$defaultDataViewPermissions);
	return A2(
		$author$project$Lib$Form$Form$append,
		dataViewPermissionsForm,
		$author$project$Lib$Form$Form$succeed($author$project$Entity$Role$ViewPermissions));
};
var $author$project$Forms$RoleForms$makeSelectViewAssociationForm = function (_v0) {
	var allViews = _v0.hG;
	var usedViews = _v0.ho;
	return $author$project$Lib$Form$Form$meta(
		function (values) {
			var viewPermissionsForm = $author$project$Lib$Form$Form$meta(
				function (_v2) {
					var viewId = _v2.m_;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Role$defaultViewPermissions),
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Forms$RoleForms$makeViewPermissionsForm,
								$author$project$Lib$Form$Form$mapValues(
									{
										a: F2(
											function (value, values_) {
												return _Utils_update(
													values_,
													{dL: value});
											}),
										mY: function ($) {
											return $.dL;
										}
									})),
							A2($elm$core$Dict$get, viewId, allViews)));
				});
			var viewIsNotInUse = function (id) {
				return !A2($elm$core$List$member, id, usedViews);
			};
			var showDirectlyField = $author$project$Lib$Form$Form$simpleCheckboxField(
				{
					kv: 'Näytä päävalikossa',
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{mc: value});
						}),
					mY: function ($) {
						return $.mc;
					}
				});
			var filteredViews = A2(
				$elm$core$Dict$filter,
				F2(
					function (id, _v1) {
						return _Utils_eq(id, values.m_) || viewIsNotInUse(id);
					}),
				allViews);
			var viewOptions = A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$View$getLongViewDescription,
				A2(
					$author$project$Utils$ListUtils$sortByValues,
					$author$project$Entity$View$viewTypeComparator,
					$elm$core$Dict$toList(filteredViews)));
			var selectViewField = $author$project$Forms$CommonForms$selectIdField(
				{
					kv: 'Valitse näkymä',
					aT: viewOptions,
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{m_: value});
						}),
					mY: function ($) {
						return $.m_;
					}
				});
			return A2(
				$author$project$Lib$Form$Form$append,
				viewPermissionsForm,
				A2(
					$author$project$Lib$Form$Form$append,
					showDirectlyField,
					A2(
						$author$project$Lib$Form$Form$append,
						selectViewField,
						$author$project$Lib$Form$Form$succeed($author$project$Entity$Role$ViewAssociation))));
		});
};
var $author$project$Forms$RoleForms$makeRoleForm = function (config) {
	var viewsField = $author$project$Lib$Form$Form$meta(
		function (topLevelValues) {
			var usedViews = A2(
				$elm$core$List$map,
				function ($) {
					return $.m_;
				},
				topLevelValues.ca);
			return A2(
				$author$project$Lib$Form$Form$simpleList,
				{
					f7: $author$project$Forms$RoleForms$createDefaultViewAssociationFormValues,
					kv: 'Näkymät',
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{ca: value});
						}),
					mY: function ($) {
						return $.ca;
					}
				},
				function (_v0) {
					return $author$project$Forms$RoleForms$makeSelectViewAssociationForm(
						A2($author$project$Forms$RoleForms$ViewAssociationFormConfig, config.hG, usedViews));
				});
		});
	var viewComponentCommandOptions = A2(
		$author$project$Utils$ListUtils$mapValues,
		$author$project$Entity$Command$getCommandLongDescription,
		A2(
			$author$project$Utils$ListUtils$sortByValues,
			$author$project$Entity$Command$commandTypeComparator,
			$elm$core$Dict$toList(
				A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Command$isViewComponentsCommand, config.fU))));
	var settingsViewComponentCommandField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{d9: value});
				}),
			mY: function ($) {
				return $.d9;
			}
		},
		$author$project$Forms$EntitySelectFormList$makeEntitySelectFormList(
			{a9: viewComponentCommandOptions, gf: 'Valitse komento', bx: $elm$core$Maybe$Nothing, gA: 'Asetusnäkymän komponenttikomennot'}));
	var permissionsField = A2(
		$author$project$Lib$Form$Form$allowEmpty,
		_List_Nil,
		$author$project$Lib$Form$Form$textField(
			{
				ba: {hS: $elm$core$Maybe$Nothing, kv: 'Käyttöoikeustagit', lm: ''},
				U: A2(
					$elm$core$Basics$composeR,
					$elm$core$String$split(','),
					$elm$core$Result$Ok),
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{dK: value});
					}),
				mY: function ($) {
					return $.dK;
				}
			}));
	var isUserRoleField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Käyttäjän rooli',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kr: value});
				}),
			mY: function ($) {
				return $.kr;
			}
		});
	var includedRolesField = $author$project$Lib$Form$Form$meta(
		function (values) {
			return A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values_) {
							return _Utils_update(
								values_,
								{dd: value});
						}),
					mY: function ($) {
						return $.dd;
					}
				},
				$author$project$Forms$EntitySelectFormList$makeEntitySelectFormList(
					{
						a9: $elm$core$Dict$toList(
							A2(
								$author$project$Utils$DictUtils$mapValues,
								function ($) {
									return $.k_;
								},
								config.fV)),
						gf: 'Valitse rooli',
						bx: $elm$core$Maybe$Just(
							$elm$core$Basics$neq(values.db)),
						gA: 'Aliroolit'
					}));
		});
	var defaultViewField = $author$project$Forms$CommonForms$selectIdOptionallyField(
		{
			kv: 'Oletusnäkymä',
			k$: 'Ei mitään',
			aT: A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$View$getLongViewDescription,
				A2(
					$author$project$Utils$ListUtils$sortByValues,
					$author$project$Entity$View$viewTypeComparator,
					$elm$core$Dict$toList(config.hG))),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iW: value});
				}),
			mY: function ($) {
				return $.iW;
			}
		});
	var commandsField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cF: value});
				}),
			mY: function ($) {
				return $.cF;
			}
		},
		$author$project$Forms$EntitySelectFormList$makeEntitySelectFormList(
			{
				a9: A2(
					$author$project$Utils$ListUtils$mapValues,
					$author$project$Entity$Command$getCommandLongDescription,
					A2(
						$author$project$Utils$ListUtils$sortByValues,
						$author$project$Entity$Command$commandTypeComparator,
						$elm$core$Dict$toList(config.fU))),
				gf: 'Valitse komento',
				bx: $elm$core$Maybe$Nothing,
				gA: 'Komennot'
			}));
	var appBarViewComponentCommandField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cp: value});
				}),
			mY: function ($) {
				return $.cp;
			}
		},
		$author$project$Forms$EntitySelectFormList$makeEntitySelectFormList(
			{a9: viewComponentCommandOptions, gf: 'Valitse komento', bx: $elm$core$Maybe$Nothing, gA: 'Sovelluspalkin komponenttikomennot'}));
	return A2(
		$author$project$Lib$Form$Form$append,
		appBarViewComponentCommandField,
		A2(
			$author$project$Lib$Form$Form$append,
			settingsViewComponentCommandField,
			A2(
				$author$project$Lib$Form$Form$append,
				permissionsField,
				A2(
					$author$project$Lib$Form$Form$append,
					includedRolesField,
					A2(
						$author$project$Lib$Form$Form$append,
						commandsField,
						A2(
							$author$project$Lib$Form$Form$append,
							viewsField,
							A2(
								$author$project$Lib$Form$Form$append,
								defaultViewField,
								A2(
									$author$project$Lib$Form$Form$append,
									isUserRoleField,
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$descriptionField,
										A2(
											$author$project$Lib$Form$Form$append,
											$author$project$Forms$CommonForms$nameField,
											A2(
												$author$project$Lib$Form$Form$append,
												$author$project$Forms$CommonForms$idAnchorField,
												A2(
													$author$project$Lib$Form$Form$append,
													$author$project$Forms$CommonForms$aproProjectIdField,
													A2(
														$author$project$Lib$Form$Form$append,
														$author$project$Forms$CommonForms$constantMetaField(
															function ($) {
																return $.dM;
															}),
														A2(
															$author$project$Lib$Form$Form$append,
															$author$project$Forms$CommonForms$constantMetaField(
																function ($) {
																	return $.j_;
																}),
															A2(
																$author$project$Lib$Form$Form$append,
																$author$project$Forms$CommonForms$idField,
																$author$project$Lib$Form$Form$succeed($author$project$Entity$Role$Role))))))))))))))));
};
var $author$project$Pages$Main$RolesView$renderEditRoleForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$RolesView$EditFormBackButtonClicked,
			'Muokkaa roolia',
			_List_fromArray(
				[
					$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
					{
						gk: A2(
							$author$project$Lib$Form$Form$map,
							$author$project$Pages$Main$RolesView$BeginSaveRole,
							$author$project$Forms$RoleForms$makeRoleForm(config)),
						gl: formModel,
						db: 'edit_role',
						x: $author$project$Pages$Main$RolesView$RoleFormChanged
					})
				]));
	});
var $author$project$Pages$Main$RolesView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$RolesView$renderEditRoleForm(formModel),
					A4($author$project$Lib$RemoteData$map3, $author$project$Forms$RoleForms$RoleFormConfig, repository.m$, repository.g0, repository.O));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$RolesView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$RolesView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$RolesView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$RolesView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $author$project$Pages$Main$RolesView$renderModal = function (model) {
	var _v0 = model.bO;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$messageModal(config);
		default:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$confirmationModal(config);
	}
};
var $author$project$Pages$Main$RolesView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Main$RolesView$renderModal(model),
					A2($author$project$Pages$Main$RolesView$renderListingView, model, repository),
					A2($author$project$Pages$Main$RolesView$renderEditView, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$rolesView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$RolesMsg,
		A2($author$project$Pages$Main$RolesView$view, model.bX, model.n));
};
var $author$project$Pages$Main$TenantSettingsView$TenantSettingsFormConfig = F3(
	function (allRoles, allEnvironments, allCommands) {
		return {fU: allCommands, eB: allEnvironments, fV: allRoles};
	});
var $author$project$Pages$Main$TenantSettingsView$FormChanged = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pages$Main$TenantSettingsView$SaveTenantSettings = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pages$Main$TenantSettingsView$makeTenantHookForm = function (config) {
	var hookTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Triggeri',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('PostPrincipalCreate', 'Käyttäjän luonnin jälkeen')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $elm$core$Result$Ok,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{j3: value});
				}),
			mY: function ($) {
				return $.j3;
			}
		});
	var environmentIdField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Ympäristö',
			aT: A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$Environment$displayNameOrName,
				$elm$core$Dict$toList(config.eB)),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jp: value});
				}),
			mY: function ($) {
				return $.jp;
			}
		});
	var commandIdForm = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Komento',
			aT: A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$Command$getCommandLongDescription,
				A2(
					$author$project$Utils$ListUtils$sortByValues,
					$author$project$Entity$Command$commandTypeComparator,
					$elm$core$Dict$toList(config.fU))),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f2: value});
				}),
			mY: function ($) {
				return $.f2;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		environmentIdField,
		A2(
			$author$project$Lib$Form$Form$append,
			commandIdForm,
			A2(
				$author$project$Lib$Form$Form$append,
				hookTypeField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$TenantSettings$TenantHook))));
};
var $author$project$Pages$Main$TenantSettingsView$tenantSettingsForm = function (config) {
	var logSuccessfulExecutionsField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Tallenna onnistuneiden suoritusten lokit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kC: value});
				}),
			mY: function ($) {
				return $.kC;
			}
		});
	var hooksForm = A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {f2: $author$project$Entity$Common$nonExistentId, jp: $author$project$Entity$Common$nonExistentId, j3: 'PostPrincipalCreate'},
			kv: 'Tapahtumat',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{j4: value});
				}),
			mY: function ($) {
				return $.j4;
			}
		},
		function (_v0) {
			return $author$project$Pages$Main$TenantSettingsView$makeTenantHookForm(config);
		});
	var enableMfaForNewUsersField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Kaksivaiheinen tunnistus oletuksena päällä',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jm: value});
				}),
			mY: function ($) {
				return $.jm;
			}
		});
	var delimitersStringField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Koodien erottelumerkit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i_: value});
				}),
			mY: function ($) {
				return $.i_;
			}
		});
	var cronCommandRoleIdField = $author$project$Forms$CommonForms$selectIdOptionallyField(
		{
			kv: 'Ajastettujen komentojen suorittajan rooli',
			k$: 'Ei ajastettua suorittajaa',
			aT: $elm$core$Dict$toList(
				A2(
					$author$project$Utils$DictUtils$mapValues,
					function ($) {
						return $.k_;
					},
					config.fV)),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iJ: value});
				}),
			mY: function ($) {
				return $.iJ;
			}
		});
	var cronCommandEnvironmentIdField = $author$project$Forms$CommonForms$selectIdOptionallyField(
		{
			kv: 'Ajastettujen komentojen suorittajan ympäristö',
			k$: 'Ei ajastettua suorittajaa',
			aT: $elm$core$Dict$toList(
				A2(
					$author$project$Utils$DictUtils$mapValues,
					function ($) {
						return $.k_;
					},
					config.eB)),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iI: value});
				}),
			mY: function ($) {
				return $.iI;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		hooksForm,
		A2(
			$author$project$Lib$Form$Form$append,
			logSuccessfulExecutionsField,
			A2(
				$author$project$Lib$Form$Form$append,
				enableMfaForNewUsersField,
				A2(
					$author$project$Lib$Form$Form$append,
					cronCommandEnvironmentIdField,
					A2(
						$author$project$Lib$Form$Form$append,
						cronCommandRoleIdField,
						A2(
							$author$project$Lib$Form$Form$append,
							delimitersStringField,
							$author$project$Lib$Form$Form$succeed($author$project$Entity$TenantSettings$TenantSettings)))))));
};
var $author$project$Pages$Main$TenantSettingsView$viewTenantSettingsForm = F2(
	function (config, formModel) {
		return A3(
			$author$project$Lib$Form$FormView$asHtml,
			{fQ: 'Tallenna', db: 'tenant_settings', bK: 'Tallennetaan', x: $author$project$Pages$Main$TenantSettingsView$FormChanged, b$: true, hq: 1},
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Pages$Main$TenantSettingsView$SaveTenantSettings,
				$author$project$Pages$Main$TenantSettingsView$tenantSettingsForm(config)),
			formModel);
	});
var $author$project$Pages$Main$TenantSettingsView$viewTenantSettingsFormWebData = F2(
	function (model, repository) {
		var formConfig = A4($author$project$Lib$RemoteData$map3, $author$project$Pages$Main$TenantSettingsView$TenantSettingsFormConfig, repository.g0, repository.gg, repository.O);
		var _v0 = A3($author$project$Lib$RemoteData$map2, $elm$core$Tuple$pair, formConfig, model.gl);
		switch (_v0.$) {
			case 3:
				var _v1 = _v0.a;
				var config = _v1.a;
				var formModel = _v1.b;
				return A2($author$project$Pages$Main$TenantSettingsView$viewTenantSettingsForm, config, formModel);
			case 2:
				var error = _v0.a;
				return $author$project$Utils$CommonView$multilineText(
					$author$project$Utils$HttpUtils$httpErrorToString(error));
			default:
				return $author$project$Utils$CommonView$centeredLargeSpinner;
		}
	});
var $author$project$Pages$Main$TenantSettingsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					A2($author$project$Pages$Main$TenantSettingsView$viewTenantSettingsFormWebData, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$tenantSettingsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$TenantSettingsMsg,
		A2($author$project$Pages$Main$TenantSettingsView$view, model.b1, model.n));
};
var $author$project$Forms$ViewForms$ViewFormConfig = F3(
	function (allViews, allCommands, allConnections) {
		return {fU: allCommands, eA: allConnections, hG: allViews};
	});
var $author$project$Pages$Main$ViewsView$EditFormBackButtonClicked = {$: 3};
var $author$project$Pages$Main$ViewsView$GroupFormChanged = function (a) {
	return {$: 7, a: a};
};
var $author$project$Forms$CommonForms$iconField = $author$project$Lib$Form$Form$allowEmptyString(
	$author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Ikoni (https://fontawesome.com/icons)',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{j8: value});
				}),
			mY: function ($) {
				return $.j8;
			}
		}));
var $author$project$Forms$ViewGroupForm$viewGroupForm = function () {
	var transparentField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Läpinäkyvä käyttäjälle',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{fJ: value});
				}),
			mY: function ($) {
				return $.fJ;
			}
		});
	var displayNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Näyttönimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{i1: value});
				}),
			mY: function ($) {
				return $.i1;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommonForms$constantMetaField(
			function ($) {
				return $.fk;
			}),
		A2(
			$author$project$Lib$Form$Form$append,
			transparentField,
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$CommonForms$iconField,
				A2(
					$author$project$Lib$Form$Form$append,
					$author$project$Forms$CommonForms$descriptionField,
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Lib$Form$Form$allowEmptyString(displayNameField),
						A2(
							$author$project$Lib$Form$Form$append,
							$author$project$Forms$CommonForms$nameField,
							A2(
								$author$project$Lib$Form$Form$append,
								$author$project$Forms$CommonForms$idAnchorField,
								A2(
									$author$project$Lib$Form$Form$append,
									$author$project$Forms$CommonForms$aproProjectIdField,
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$constantMetaField(
											function ($) {
												return $.dM;
											}),
										A2(
											$author$project$Lib$Form$Form$append,
											$author$project$Forms$CommonForms$idField,
											$author$project$Lib$Form$Form$succeed($author$project$Entity$ViewGroup$ViewGroup)))))))))));
}();
var $author$project$Pages$Main$ViewsView$renderEditGroupView = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$editEntityView,
		$author$project$Pages$Main$ViewsView$EditFormBackButtonClicked,
		'Muokkaa ryhmää',
		_List_fromArray(
			[
				$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
				{
					gk: A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$ViewsView$BeginSaveGroup, $author$project$Forms$ViewGroupForm$viewGroupForm),
					gl: formModel,
					db: 'edit_view_group',
					x: $author$project$Pages$Main$ViewsView$GroupFormChanged
				})
			]));
};
var $author$project$Pages$Main$ViewsView$CodeEditorValueChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pages$Main$ViewsView$ViewFormChanged = function (a) {
	return {$: 6, a: a};
};
var $author$project$Forms$ViewForms$makeCommandLineViewSpecForm = function (_v0) {
	var allCommands = _v0.fU;
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$CommonForms$selectIdOptionallyField(
			{
				kv: 'Oletuskomento',
				k$: 'Ei oletuskomentoa',
				aT: A2(
					$author$project$Utils$ListUtils$mapValues,
					$author$project$Entity$Command$getCommandLongDescription,
					A2(
						$author$project$Utils$ListUtils$sortByValues,
						$author$project$Entity$Command$commandTypeComparator,
						$elm$core$Dict$toList(allCommands))),
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{f2: value});
					}),
				mY: function ($) {
					return $.f2;
				}
			}),
		$author$project$Lib$Form$Form$succeed($author$project$Entity$View$CommandLineViewSpecification));
};
var $author$project$Lib$CodeMirror$SCSS = 1;
var $author$project$Forms$ViewForms$getViewChannelTriggerOptions = function (viewFormValues) {
	var viewTypeSpecificTriggers = function () {
		if (viewFormValues.$ === 3) {
			return _List_fromArray(
				[
					_Utils_Tuple2('DATA_VIEW|SINGLE_ROW_SELECTED', 'Datanäkymä: Yksittäisen rivin valinta')
				]);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$elm$core$List$cons,
		_Utils_Tuple2('COMMON|SCHEDULED_REPEAT', 'Yleinen: Ajastettu toisto'),
		A2(
			$elm$core$List$cons,
			_Utils_Tuple2('COMMON|ON_VIEW_FOCUSED', 'Yleinen: Näkymään siirtyminen'),
			viewTypeSpecificTriggers));
};
var $author$project$Forms$ViewForms$makeViewActionTriggerForm = F2(
	function (config, topLevelValues) {
		var triggerField = $author$project$Lib$Form$Form$selectField(
			{
				ba: {
					kv: 'Triggeri',
					aT: $author$project$Forms$ViewForms$getViewChannelTriggerOptions(topLevelValues),
					lm: $elm$core$Maybe$Just('Valitse')
				},
				U: $elm$core$Result$Ok,
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{mG: value});
					}),
				mY: function ($) {
					return $.mG;
				}
			});
		var triggerConfigField = $author$project$Lib$Form$Form$allowEmptyString(
			$author$project$Lib$Form$Form$simpleCodeField(
				{
					kv: 'Triggerin konfiguraatio',
					aP: 4,
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{mI: value});
						}),
					mY: function ($) {
						return $.mI;
					}
				}));
		var actionForm = A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{fO: value});
					}),
				mY: function ($) {
					return $.fO;
				}
			},
			$author$project$Forms$ViewComponentAndActionForms$makeKappaActionForm(config));
		return A2(
			$author$project$Lib$Form$Form$append,
			actionForm,
			A2(
				$author$project$Lib$Form$Form$append,
				triggerConfigField,
				A2(
					$author$project$Lib$Form$Form$append,
					triggerField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$View$ViewActionTrigger))));
	});
var $author$project$Forms$ViewForms$makeViewActionTriggerFormList = F2(
	function (config, topLevelValues) {
		return A2(
			$author$project$Lib$Form$Form$simpleList,
			{
				f7: $author$project$Forms$ViewForms$createViewActionTriggerValues(
					{fO: $author$project$Entity$ActionAndViewComponent$createNewKappaAction, mG: '', mI: ''}),
				kv: 'Toimintojen triggerit',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{hz: value});
					}),
				mY: function ($) {
					return $.hz;
				}
			},
			function (_v0) {
				return A2($author$project$Forms$ViewForms$makeViewActionTriggerForm, config, topLevelValues);
			});
	});
var $author$project$Forms$ViewForms$getViewComponentRenderLocationOptions = function (viewFormValues) {
	var viewTypeSpecificLocations = function () {
		switch (viewFormValues.$) {
			case 3:
				return _List_fromArray(
					[
						_Utils_Tuple2('DATA_VIEW|ROW_CONTEXT_MENU', 'Datanäkymä | Rivin kontekstivalikko')
					]);
			case 2:
				return _List_fromArray(
					[
						_Utils_Tuple2('COMPONENT_VIEW|CENTER', 'Komponenttinäkymä | Keskellä')
					]);
			case 4:
				return _List_fromArray(
					[
						_Utils_Tuple2('FORM_VIEW|CENTER', 'Lomakenäkymä | Keskellä')
					]);
			default:
				return _List_Nil;
		}
	}();
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2('COMMON|ACTION_BAR', 'Toimintopalkki'),
				_Utils_Tuple2('COMMON|LEFT_SIDE', 'Näkymän vasemmalla puolella'),
				_Utils_Tuple2('COMMON|RIGHT_SIDE', 'Näkymän oikealla puolella'),
				_Utils_Tuple2('COMMON|BOTTOM_SIDE', 'Näkymän alla')
			]),
		viewTypeSpecificLocations);
};
var $author$project$Forms$ViewForms$makeViewComponentCommandsFormList = F2(
	function (config, topLevelValues) {
		var renderLocationField = $author$project$Lib$Form$Form$selectField(
			{
				ba: {
					kv: 'Sijainti',
					aT: $author$project$Forms$ViewForms$getViewComponentRenderLocationOptions(topLevelValues),
					lm: $elm$core$Maybe$Nothing
				},
				U: $elm$core$Result$Ok,
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{lB: value});
					}),
				mY: function ($) {
					return $.lB;
				}
			});
		var commandIdField = $author$project$Forms$CommonForms$selectIdField(
			{
				kv: 'Komento',
				aT: A2(
					$author$project$Utils$ListUtils$mapValues,
					$author$project$Entity$Command$getCommandLongDescription,
					$elm$core$Dict$toList(
						A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Command$isViewComponentsCommand, config.fU))),
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{f2: value});
					}),
				mY: function ($) {
					return $.f2;
				}
			});
		var commandBindingForm = A2(
			$author$project$Lib$Form$Form$append,
			renderLocationField,
			A2(
				$author$project$Lib$Form$Form$append,
				commandIdField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$View$ViewComponentCommandBinding)));
		return A2(
			$author$project$Lib$Form$Form$simpleList,
			{
				f7: {f2: $author$project$Entity$Common$nonExistentId, lB: 'COMMON|ACTION_BAR'},
				kv: 'Komponenttitoiminnot',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{iq: value});
					}),
				mY: function ($) {
					return $.iq;
				}
			},
			function (_v0) {
				return commandBindingForm;
			});
	});
var $author$project$Forms$ViewForms$getDefaultComponentTypeOptions = function (viewFormValues) {
	var viewTypeSpecificLocations = function () {
		switch (viewFormValues.$) {
			case 3:
				return _List_fromArray(
					[
						_Utils_Tuple2('DATA_VIEW|EDIT_BUTTON', 'Datanäkymä: rivin muokkauspainike'),
						_Utils_Tuple2('DATA_VIEW|NEW_ROW_BUTTON', 'Datanäkymä: uuden rivin lisäyspainike'),
						_Utils_Tuple2('DATA_VIEW|SAVE_BUTTON', 'Datanäkymä: rivin tallennuspainike'),
						_Utils_Tuple2('DATA_VIEW|DELETE_BUTTON', 'Datanäkymä: rivin poistopainike')
					]);
			case 4:
				return _List_fromArray(
					[
						_Utils_Tuple2('FORM_VIEW|TOGGLE_EDIT_BUTTON', 'Lomakenäkymä: editoinnin kytkentäpainike'),
						_Utils_Tuple2('FORM_VIEW|SUBMIT_BUTTON', 'Lomakenäkymä: OK-painike')
					]);
			default:
				return _List_Nil;
		}
	}();
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2('COMMON|REFRESH_BUTTON', 'Päivityspainike')
			]),
		viewTypeSpecificLocations);
};
var $author$project$Forms$ViewForms$makeViewDefaultComponentAttributesForm = function (topLevelValues) {
	var componentTypeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Kohde',
				aT: $author$project$Forms$ViewForms$getDefaultComponentTypeOptions(topLevelValues),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$viewDefaultComponentTypeParser,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{is: value});
				}),
			mY: function ($) {
				return $.is;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		$author$project$Forms$ViewComponentAndActionForms$viewComponentAttributesFormList('button'),
		A2(
			$author$project$Lib$Form$Form$append,
			componentTypeField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$View$ViewDefaultComponentAttributes)));
};
var $author$project$Forms$ViewForms$makeViewDefaultComponentAttributesFormList = function (topLevelValues) {
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: {
				ba: _List_Nil,
				is: $author$project$Utils$ToStrings$viewDefaultComponentTypeToString(0)
			},
			kv: 'Oletuskomponenttien attribuutit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{iS: value});
				}),
			mY: function ($) {
				return $.iS;
			}
		},
		function (_v0) {
			return $author$project$Forms$ViewForms$makeViewDefaultComponentAttributesForm(topLevelValues);
		});
};
var $author$project$Forms$ViewForms$viewRequiredParametersFormList = function () {
	var placeholderField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Näytettävä teksti',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lm: value});
				}),
			mY: function ($) {
				return $.lm;
			}
		});
	var paramNameField = $author$project$Lib$Form$Form$simpleTextField(
		{
			kv: 'Parametrin nimi',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lh: value});
				}),
			mY: function ($) {
				return $.lh;
			}
		});
	var allowEmptyValueField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Salli tyhjä arvo',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{hI: value});
				}),
			mY: function ($) {
				return $.hI;
			}
		});
	var requiredParameterForm = A2(
		$author$project$Lib$Form$Form$append,
		allowEmptyValueField,
		A2(
			$author$project$Lib$Form$Form$append,
			placeholderField,
			A2(
				$author$project$Lib$Form$Form$append,
				paramNameField,
				$author$project$Lib$Form$Form$succeed($author$project$Entity$View$ViewRequiredParameter))));
	return A2(
		$author$project$Lib$Form$Form$simpleList,
		{
			f7: A3($author$project$Entity$View$ViewRequiredParameter, '', '', false),
			kv: 'Vaaditut näkymän parametrit',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{lJ: value});
				}),
			mY: function ($) {
				return $.lJ;
			}
		},
		function (_v0) {
			return requiredParameterForm;
		});
}();
var $author$project$Forms$ViewForms$makeViewCommonPropsForm = F2(
	function (topLevelValues, config) {
		var staticParamsForm = A2(
			$author$project$Lib$Form$Form$simpleList,
			{
				f7: {kt: '', mY: $elm$core$Maybe$Nothing},
				kv: 'Staattiset parametrit',
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{mm: value});
					}),
				mY: function ($) {
					return $.mm;
				}
			},
			function (_v0) {
				return $author$project$Forms$CommandParameterForm$commandParameterField(0);
			});
		var scssCodeField = $author$project$Lib$Form$Form$simpleCodeField(
			{
				kv: 'SCSS-koodi',
				aP: 1,
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{lX: value});
					}),
				mY: function ($) {
					return $.lX;
				}
			});
		var displayNameField = $author$project$Lib$Form$Form$allowEmptyString(
			$author$project$Lib$Form$Form$simpleTextField(
				{
					kv: 'Näytettävä nimi {{F}}',
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{i1: value});
						}),
					mY: function ($) {
						return $.i1;
					}
				}));
		return A2(
			$author$project$Lib$Form$Form$mapValues,
			{
				a: F2(
					function (value, values) {
						return _Utils_update(
							values,
							{B: value});
					}),
				mY: function ($) {
					return $.B;
				}
			},
			A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Forms$ViewForms$makeViewDefaultComponentAttributesFormList(topLevelValues),
				A2(
					$author$project$Lib$Form$Form$append,
					A2($author$project$Forms$ViewForms$makeViewComponentCommandsFormList, config, topLevelValues),
					A2(
						$author$project$Lib$Form$Form$append,
						$author$project$Forms$ViewForms$viewRequiredParametersFormList,
						A2(
							$author$project$Lib$Form$Form$append,
							staticParamsForm,
							A2(
								$author$project$Lib$Form$Form$append,
								A2($author$project$Forms$ViewForms$makeViewActionTriggerFormList, config, topLevelValues),
								A2(
									$author$project$Lib$Form$Form$append,
									$author$project$Lib$Form$Form$allowEmptyString(scssCodeField),
									A2(
										$author$project$Lib$Form$Form$append,
										$author$project$Forms$CommonForms$iconField,
										A2(
											$author$project$Lib$Form$Form$append,
											$author$project$Forms$CommonForms$descriptionField,
											A2(
												$author$project$Lib$Form$Form$append,
												displayNameField,
												A2(
													$author$project$Lib$Form$Form$append,
													$author$project$Forms$CommonForms$nameField,
													A2(
														$author$project$Lib$Form$Form$append,
														$author$project$Forms$CommonForms$idAnchorField,
														A2(
															$author$project$Lib$Form$Form$append,
															$author$project$Forms$CommonForms$aproProjectIdField,
															A2(
																$author$project$Lib$Form$Form$append,
																$author$project$Forms$CommonForms$constantMetaField(
																	function ($) {
																		return $.dM;
																	}),
																A2(
																	$author$project$Lib$Form$Form$append,
																	$author$project$Forms$CommonForms$constantMetaField(
																		function ($) {
																			return $.j_;
																		}),
																	A2(
																		$author$project$Lib$Form$Form$append,
																		$author$project$Forms$CommonForms$idField,
																		$author$project$Lib$Form$Form$succeed($author$project$Entity$View$View)))))))))))))))));
	});
var $author$project$Forms$ViewForms$makeCommandLineViewForm = F2(
	function (topLevelValues, config) {
		return A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Entity$View$VCommandLineView,
				$author$project$Forms$ViewForms$makeCommandLineViewSpecForm(config)),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Forms$ViewForms$makeContainerViewSpecForm = function (config) {
	var mainViewForm = $author$project$Lib$Form$Form$meta(
		function (_v0) {
			var childViewValues = _v0.cA;
			var childViewIds = A2(
				$elm$core$List$map,
				function ($) {
					return $.db;
				},
				childViewValues.au);
			return $author$project$Forms$CommonForms$selectIdOptionallyField(
				{
					kv: 'Oletusnäkymä (pienellä näytöllä)',
					k$: 'Ei mitään',
					aT: A2(
						$author$project$Utils$ListUtils$mapValues,
						$author$project$Entity$View$getLongViewDescription,
						A2(
							$author$project$Utils$ListUtils$sortByValues,
							$author$project$Entity$View$viewTypeComparator,
							A2(
								$elm$core$List$filter,
								function (_v1) {
									var viewId = _v1.a;
									return A2($elm$core$List$member, viewId, childViewIds);
								},
								$elm$core$Dict$toList(config.hG)))),
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{kF: value});
						}),
					mY: function ($) {
						return $.kF;
					}
				});
		});
	var layoutConfigForm = $author$project$Lib$Form$Form$simpleCodeField(
		{
			kv: 'Layout-konfiguraatio',
			aP: 4,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{kw: value});
				}),
			mY: function ($) {
				return $.kw;
			}
		});
	var isDynamicField = $author$project$Lib$Form$Form$simpleCheckboxField(
		{
			kv: 'Dynaaminen',
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{ko: value});
				}),
			mY: function ($) {
				return $.ko;
			}
		});
	var childViewsForm = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{cA: value});
				}),
			mY: function ($) {
				return $.cA;
			}
		},
		$author$project$Forms$EntitySelectFormList$makeEntitySelectFormList(
			{
				a9: A2(
					$author$project$Utils$ListUtils$mapValues,
					$author$project$Entity$View$getLongViewDescription,
					A2(
						$author$project$Utils$ListUtils$sortByValues,
						$author$project$Entity$View$viewTypeComparator,
						$elm$core$Dict$toList(config.hG))),
				gf: 'Valitse näkymä',
				bx: $elm$core$Maybe$Nothing,
				gA: 'Alinäkymät'
			}));
	return A2(
		$author$project$Lib$Form$Form$append,
		layoutConfigForm,
		A2(
			$author$project$Lib$Form$Form$append,
			isDynamicField,
			A2(
				$author$project$Lib$Form$Form$append,
				mainViewForm,
				A2(
					$author$project$Lib$Form$Form$append,
					childViewsForm,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$View$ContainerViewSpecification)))));
};
var $author$project$Forms$ViewForms$makeContainerViewForm = F2(
	function (topLevelValues, config) {
		return A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Entity$View$VContainerView,
				$author$project$Forms$ViewForms$makeContainerViewSpecForm(config)),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Entity$Command$isDataViewCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$View$isFormView = function (view) {
	var _v0 = view.mh;
	if (_v0.$ === 4) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Forms$ViewForms$makeSelectCommandField = F2(
	function (label, validCommands) {
		return $author$project$Forms$CommonForms$selectIdField(
			{
				kv: label,
				aT: A2(
					$author$project$Utils$ListUtils$mapValues,
					$author$project$Entity$Command$getCommandLongDescription,
					A2(
						$author$project$Utils$ListUtils$sortByValues,
						$author$project$Entity$Command$commandTypeComparator,
						$elm$core$Dict$toList(validCommands))),
				a: F2(
					function (value, _v0) {
						return value;
					}),
				mY: $elm$core$Basics$identity
			});
	});
var $author$project$Forms$ViewForms$makeDataViewSpecForm = function (_v0) {
	var allCommands = _v0.fU;
	var allViews = _v0.hG;
	var allFormViews = A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$View$isFormView, allViews);
	var selectFormViewField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{e3: value});
				}),
			mY: function ($) {
				return $.e3;
			}
		},
		A2(
			$author$project$Forms$CommonForms$convertFormToOptional,
			'Määritä lomakenäkymä',
			$author$project$Forms$CommonForms$selectIdField(
				{
					kv: 'Lomakenäkymä',
					aT: A2(
						$author$project$Utils$ListUtils$mapValues,
						$author$project$Entity$View$getLongViewDescription,
						A2(
							$author$project$Utils$ListUtils$sortByValues,
							$author$project$Entity$View$viewTypeComparator,
							$elm$core$Dict$toList(allFormViews))),
					a: F2(
						function (value, _v1) {
							return value;
						}),
					mY: $elm$core$Basics$identity
				})));
	var allDataViewCommands = A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Command$isDataViewCommand, allCommands);
	return A2(
		$author$project$Lib$Form$Form$append,
		selectFormViewField,
		A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{f2: value});
						}),
					mY: function ($) {
						return $.f2;
					}
				},
				A2($author$project$Forms$ViewForms$makeSelectCommandField, 'Komento', allDataViewCommands)),
			$author$project$Lib$Form$Form$succeed($author$project$Entity$View$DataViewSpecification)));
};
var $author$project$Forms$ViewForms$makeDataViewForm = F2(
	function (topLevelValues, config) {
		return A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Entity$View$VDataView,
				$author$project$Forms$ViewForms$makeDataViewSpecForm(config)),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Forms$ViewForms$makeEmptyViewSpecForm = F3(
	function (topLevelValues, config, spec) {
		return A2(
			$author$project$Lib$Form$Form$append,
			$author$project$Lib$Form$Form$succeed(spec),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Entity$Command$isFormCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Forms$ViewForms$makeFormViewSpecForm = function (_v0) {
	var allCommands = _v0.fU;
	var allViews = _v0.hG;
	var editModeField = $author$project$Lib$Form$Form$selectField(
		{
			ba: {
				kv: 'Muokattavuus',
				aT: _List_fromArray(
					[
						_Utils_Tuple2('AlwaysEditable', 'Muokattavissa'),
						_Utils_Tuple2('AlwaysReadOnly', 'Vain luku'),
						_Utils_Tuple2('OptionallyEditable', 'Valinnaisesti muokattavissa')
					]),
				lm: $elm$core$Maybe$Nothing
			},
			U: $author$project$Utils$TypeParsers$parseFormViewEditMode,
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jf: value});
				}),
			mY: function ($) {
				return $.jf;
			}
		});
	var allFormCommands = A2($author$project$Utils$DictUtils$filterByValue, $author$project$Entity$Command$isFormCommand, allCommands);
	var selectCommandField = A2(
		$author$project$Lib$Form$Form$mapValues,
		{
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{f2: value});
				}),
			mY: function ($) {
				return $.f2;
			}
		},
		A2($author$project$Forms$ViewForms$makeSelectCommandField, 'Komento', allFormCommands));
	return A2(
		$author$project$Lib$Form$Form$append,
		editModeField,
		A2(
			$author$project$Lib$Form$Form$append,
			selectCommandField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$View$FormViewSpecification)));
};
var $author$project$Forms$ViewForms$makeFormViewForm = F2(
	function (topLevelValues, config) {
		return A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Entity$View$VFormView,
				$author$project$Forms$ViewForms$makeFormViewSpecForm(config)),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Forms$ViewForms$makeGeneratorViewSpecForm = function (_v0) {
	var allCommands = _v0.fU;
	var allViews = _v0.hG;
	var selectTemplateViewField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Mallinäkymä',
			aT: A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$View$getLongViewDescription,
				A2(
					$author$project$Utils$ListUtils$sortByValues,
					$author$project$Entity$View$viewTypeComparator,
					$elm$core$Dict$toList(allViews))),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{my: value});
				}),
			mY: function ($) {
				return $.my;
			}
		});
	var selectCommandField = $author$project$Forms$CommonForms$selectIdField(
		{
			kv: 'Generoiva komento',
			aT: A2(
				$author$project$Utils$ListUtils$mapValues,
				$author$project$Entity$Command$getCommandLongDescription,
				A2(
					$author$project$Utils$ListUtils$sortByValues,
					$author$project$Entity$Command$commandTypeComparator,
					$elm$core$Dict$toList(allCommands))),
			a: F2(
				function (value, values) {
					return _Utils_update(
						values,
						{jS: value});
				}),
			mY: function ($) {
				return $.jS;
			}
		});
	return A2(
		$author$project$Lib$Form$Form$append,
		selectTemplateViewField,
		A2(
			$author$project$Lib$Form$Form$append,
			selectCommandField,
			$author$project$Lib$Form$Form$succeed($author$project$Entity$View$GeneratorViewSpecification)));
};
var $author$project$Forms$ViewForms$makeGeneratorViewForm = F2(
	function (topLevelValues, config) {
		return A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Entity$View$VGeneratorView,
				$author$project$Forms$ViewForms$makeGeneratorViewSpecForm(config)),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Entity$Command$isCalendarViewCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 12) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$Command$isChartCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 9) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$Command$isDiagramCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 10) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$Command$isFileProducerCommand = function (command) {
	var _v0 = command.mh;
	switch (_v0.$) {
		case 6:
			return true;
		case 8:
			return true;
		case 0:
			return true;
		default:
			return false;
	}
};
var $author$project$Entity$Command$isProductionLineViewCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$Command$isQuickSightCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 13) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$Command$isSingleClauseCommand = function (command) {
	var _v0 = command.mh;
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$Command$isTreeViewCommand = function (command) {
	var _v0 = command.mh;
	if (_v0.$ === 11) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Entity$View$isValidCommandForViewWithSingleCommand = F2(
	function (type_, command) {
		switch (type_) {
			case 0:
				return $author$project$Entity$Command$isProductionLineViewCommand(command);
			case 1:
				return $author$project$Entity$Command$isChartCommand(command);
			case 2:
				return $author$project$Entity$Command$isFileProducerCommand(command);
			case 3:
				return $author$project$Entity$Command$isDiagramCommand(command);
			case 4:
				return $author$project$Entity$Command$isTreeViewCommand(command);
			case 5:
				return true;
			case 6:
				return $author$project$Entity$Command$isQuickSightCommand(command);
			case 7:
				return $author$project$Entity$Command$isCalendarViewCommand(command);
			default:
				return $author$project$Entity$Command$isSingleClauseCommand(command);
		}
	});
var $author$project$Forms$ViewForms$makeViewWithSingleCommandSpecForm = function (_v0) {
	var allCommands = _v0.fU;
	return $author$project$Lib$Form$Form$meta(
		function (_v1) {
			var type_ = _v1.mM;
			var commandFilter = $author$project$Entity$View$isValidCommandForViewWithSingleCommand(type_);
			var allValidCommands = A2($author$project$Utils$DictUtils$filterByValue, commandFilter, allCommands);
			var selectCommandField = A2(
				$author$project$Lib$Form$Form$mapValues,
				{
					a: F2(
						function (value, values) {
							return _Utils_update(
								values,
								{f2: value});
						}),
					mY: function ($) {
						return $.f2;
					}
				},
				A2($author$project$Forms$ViewForms$makeSelectCommandField, 'Komento', allValidCommands));
			return A2(
				$author$project$Lib$Form$Form$append,
				$author$project$Lib$Form$Form$succeed(type_),
				A2(
					$author$project$Lib$Form$Form$append,
					selectCommandField,
					$author$project$Lib$Form$Form$succeed($author$project$Entity$View$ViewWithSingleCommandSpec)));
		});
};
var $author$project$Forms$ViewForms$makeViewWithSingleCommandForm = F2(
	function (topLevelValues, config) {
		return A2(
			$author$project$Lib$Form$Form$append,
			A2(
				$author$project$Lib$Form$Form$map,
				$author$project$Entity$View$VViewWithSingleCommand,
				$author$project$Forms$ViewForms$makeViewWithSingleCommandSpecForm(config)),
			A2($author$project$Forms$ViewForms$makeViewCommonPropsForm, topLevelValues, config));
	});
var $author$project$Forms$ViewForms$makeViewForm = function (config) {
	var mapValues = F2(
		function (values, updateMapper) {
			return $author$project$Lib$Form$Form$mapValues(
				{
					a: F2(
						function (value, _v1) {
							return updateMapper(value);
						}),
					mY: function (_v2) {
						return values;
					}
				});
		});
	return $author$project$Lib$Form$Form$meta(
		function (values) {
			switch (values.$) {
				case 0:
					var commandLineViewValues = values.a;
					return A3(
						mapValues,
						commandLineViewValues,
						$author$project$Forms$ViewForms$CommandLineViewForm,
						A2($author$project$Forms$ViewForms$makeCommandLineViewForm, values, config));
				case 1:
					var containerViewValues = values.a;
					return A3(
						mapValues,
						containerViewValues,
						$author$project$Forms$ViewForms$ContainerViewForm,
						A2($author$project$Forms$ViewForms$makeContainerViewForm, values, config));
				case 2:
					var componentViewValues = values.a;
					return A3(
						mapValues,
						componentViewValues,
						$author$project$Forms$ViewForms$ComponentViewForm,
						A3($author$project$Forms$ViewForms$makeEmptyViewSpecForm, values, config, $author$project$Entity$View$VComponentView));
				case 3:
					var dataViewFormValues = values.a;
					return A3(
						mapValues,
						dataViewFormValues,
						$author$project$Forms$ViewForms$DataViewForm,
						A2($author$project$Forms$ViewForms$makeDataViewForm, values, config));
				case 4:
					var formViewFormValues = values.a;
					return A3(
						mapValues,
						formViewFormValues,
						$author$project$Forms$ViewForms$FormViewForm,
						A2($author$project$Forms$ViewForms$makeFormViewForm, values, config));
				case 5:
					var generatorViewFormValues = values.a;
					return A3(
						mapValues,
						generatorViewFormValues,
						$author$project$Forms$ViewForms$GeneratorViewForm,
						A2($author$project$Forms$ViewForms$makeGeneratorViewForm, values, config));
				default:
					var singleCommandViewFormValues = values.a;
					return A3(
						mapValues,
						singleCommandViewFormValues,
						$author$project$Forms$ViewForms$ViewWithSingleCommandForm,
						A2($author$project$Forms$ViewForms$makeViewWithSingleCommandForm, values, config));
			}
		});
};
var $author$project$Pages$Main$ViewsView$renderEditViewForm = F2(
	function (formModel, config) {
		return A3(
			$author$project$Pages$Main$FormViewHelpers$editEntityView,
			$author$project$Pages$Main$ViewsView$EditFormBackButtonClicked,
			'Muokkaa näkymää',
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('full-height-container flex-row')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-1')
								]),
							_List_fromArray(
								[
									$author$project$Pages$Main$FormViewHelpers$renderCommonForm(
									{
										gk: A2(
											$author$project$Lib$Form$Form$map,
											$author$project$Pages$Main$ViewsView$BeginSaveView,
											$author$project$Forms$ViewForms$makeViewForm(config)),
										gl: formModel,
										db: 'edit_view',
										x: $author$project$Pages$Main$ViewsView$ViewFormChanged
									})
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-2 overflow-hidden')
								]),
							_List_fromArray(
								[
									A2($author$project$Pages$Main$CodeField$viewCodeEditorForFormCodeField, formModel, $author$project$Pages$Main$ViewsView$CodeEditorValueChanged)
								]))
						]))
				]));
	});
var $author$project$Pages$Main$ViewsView$renderEditView = F2(
	function (model, repository) {
		var _v0 = model.j;
		switch (_v0.$) {
			case 0:
				return $elm$html$Html$text('');
			case 1:
				var formModel = _v0.a;
				return A2(
					$author$project$Utils$CommonView$renderWebData,
					$author$project$Pages$Main$ViewsView$renderEditViewForm(formModel),
					A4($author$project$Lib$RemoteData$map3, $author$project$Forms$ViewForms$ViewFormConfig, repository.m$, repository.O, repository.iy));
			default:
				var formModel = _v0.a;
				return $author$project$Pages$Main$ViewsView$renderEditGroupView(formModel);
		}
	});
var $author$project$Pages$Main$ViewsView$renderListingView = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$map,
			$author$project$Pages$Main$ViewsView$ListingViewMsg,
			A4(
				$author$project$Pages$Main$EntityListing$GenericEntityListingView$view,
				$author$project$Pages$Main$ViewsView$listingViewConfig,
				model.ap,
				repository,
				$author$project$Utils$Visibility$visibleIf(
					_Utils_eq(model.j, $author$project$Pages$Main$EditEntityOrGroupModel$NotEditing))));
	});
var $author$project$Pages$Main$ViewsView$AddNewViewFormChanged = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pages$Main$ViewsView$AddNewViewFormSubmitted = function (a) {
	return {$: 9, a: a};
};
var $author$project$Entity$View$createNewView = F2(
	function (icon, spec) {
		return {hz: _List_Nil, hM: $elm$core$Maybe$Nothing, iq: _List_Nil, iS: _List_Nil, i$: '', i1: '', j_: $elm$core$Maybe$Nothing, j8: icon, db: $author$project$Entity$Common$notPersistedId, j9: $elm$core$Maybe$Nothing, k_: '', dM: $author$project$Entity$Common$lastPosition, lJ: _List_Nil, lX: '', mh: spec, mm: _List_Nil};
	});
var $author$project$Entity$View$createNewViewWithSingleCommand = F2(
	function (icon, type_) {
		return A2(
			$author$project$Entity$View$createNewView,
			icon,
			$author$project$Entity$View$VViewWithSingleCommand(
				{f2: $author$project$Entity$Common$nonExistentId, mM: type_}));
	});
var $author$project$Entity$View$createNewCalendarView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'calendar-week', 7);
var $author$project$Entity$View$createNewChartView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'chart-line', 1);
var $author$project$Entity$View$createNewCommandLineView = A2(
	$author$project$Entity$View$createNewView,
	'terminal',
	$author$project$Entity$View$VCommandLineView(
		{f2: $elm$core$Maybe$Nothing}));
var $author$project$Entity$View$createNewComponentView = A2($author$project$Entity$View$createNewView, 'table', $author$project$Entity$View$VComponentView);
var $author$project$Entity$View$createNewContainerView = A2(
	$author$project$Entity$View$createNewView,
	'border-all',
	$author$project$Entity$View$VContainerView(
		A4($author$project$Entity$View$ContainerViewSpecification, _List_Nil, $elm$core$Maybe$Nothing, false, '{}')));
var $author$project$Entity$View$createNewCustomHtmlView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'code', 8);
var $author$project$Entity$View$createNewDataView = A2(
	$author$project$Entity$View$createNewView,
	'table',
	$author$project$Entity$View$VDataView(
		A2($author$project$Entity$View$DataViewSpecification, $author$project$Entity$Common$nonExistentId, $elm$core$Maybe$Nothing)));
var $author$project$Entity$View$createNewDiagramView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'project-diagram', 3);
var $author$project$Entity$View$createNewDocumentView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'file', 2);
var $author$project$Entity$View$createNewFormView = A2(
	$author$project$Entity$View$createNewView,
	'pen',
	$author$project$Entity$View$VFormView(
		A2($author$project$Entity$View$FormViewSpecification, $author$project$Entity$Common$nonExistentId, 2)));
var $author$project$Entity$View$createNewGeneratorView = A2(
	$author$project$Entity$View$createNewView,
	'',
	$author$project$Entity$View$VGeneratorView(
		{jS: $author$project$Entity$Common$nonExistentId, my: $author$project$Entity$Common$nonExistentId}));
var $author$project$Entity$View$createNewMapView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'map-marked', 5);
var $author$project$Entity$View$createNewProductionLineView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'th', 0);
var $author$project$Entity$View$createNewQuickSightView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'chart-pie', 6);
var $author$project$Entity$View$createNewTreeView = A2($author$project$Entity$View$createNewViewWithSingleCommand, 'sitemap', 4);
var $author$project$Forms$CreateNewViewForm$newViewForm = $author$project$Lib$Form$Form$selectField(
	{
		ba: {kv: 'Valitse tyyppi', aT: $author$project$Entity$View$viewTypeOptions, lm: $elm$core$Maybe$Nothing},
		U: function (selection) {
			switch (selection) {
				case 'commandLineView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewCommandLineView);
				case 'containerView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewContainerView);
				case 'componentView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewComponentView);
				case 'dataView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewDataView);
				case 'formView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewFormView);
				case 'productionLineView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewProductionLineView);
				case 'chartView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewChartView);
				case 'diagramView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewDiagramView);
				case 'treeView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewTreeView);
				case 'mapView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewMapView);
				case 'quickSightView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewQuickSightView);
				case 'calendarView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewCalendarView);
				case 'customHtmlView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewCustomHtmlView);
				case 'documentView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewDocumentView);
				case 'generatorView':
					return $elm$core$Result$Ok($author$project$Entity$View$createNewGeneratorView);
				default:
					return $elm$core$Result$Err('Valitse tyyppi');
			}
		},
		a: F2(
			function (newValue, values) {
				return _Utils_update(
					values,
					{fM: newValue});
			}),
		mY: function ($) {
			return $.fM;
		}
	});
var $author$project$Pages$Main$ViewsView$renderNewViewForm = function (formModel) {
	return A3(
		$author$project$Pages$Main$FormViewHelpers$renderCommonModal,
		'Uusi näkymä',
		$author$project$Pages$Main$ViewsView$CloseCurrentModal,
		A3(
			$author$project$Lib$Form$FormView$asHtml,
			{fQ: 'OK', db: 'create_new_view', bK: 'Tallennetaan', x: $author$project$Pages$Main$ViewsView$AddNewViewFormChanged, b$: true, hq: 1},
			A2($author$project$Lib$Form$Form$map, $author$project$Pages$Main$ViewsView$AddNewViewFormSubmitted, $author$project$Forms$CreateNewViewForm$newViewForm),
			formModel));
};
var $author$project$Pages$Main$ViewsView$renderModal = function (model) {
	var _v0 = model.bO;
	switch (_v0.$) {
		case 0:
			return $elm$html$Html$text('');
		case 1:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$messageModal(config);
		case 2:
			var config = _v0.a;
			return $author$project$Utils$CommonModals$confirmationModal(config);
		default:
			var form = _v0.a;
			return $author$project$Pages$Main$ViewsView$renderNewViewForm(form);
	}
};
var $author$project$Pages$Main$ViewsView$view = F2(
	function (model, repository) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tab-view-root-container')
				]),
			_List_fromArray(
				[
					$author$project$Pages$Main$ViewsView$renderModal(model),
					A2($author$project$Pages$Main$ViewsView$renderListingView, model, repository),
					A2($author$project$Pages$Main$ViewsView$renderEditView, model, repository)
				]));
	});
var $author$project$Pages$Main$MainPage$viewsView = function (model) {
	return A2(
		$elm$html$Html$map,
		$author$project$Pages$Main$MainPage$ViewsMsg,
		A2($author$project$Pages$Main$ViewsView$view, model.b9, model.n));
};
var $author$project$Pages$Main$MainPage$getViewForRoute = F3(
	function (model, branches, route) {
		switch (route) {
			case 10:
				return $author$project$Pages$Main$MainPage$missingPermissionsView;
			case 1:
				return $author$project$Pages$Main$MainPage$viewsView(model);
			case 2:
				return $author$project$Pages$Main$MainPage$rolesView(model);
			case 0:
				return $author$project$Pages$Main$MainPage$commandsView(model);
			case 3:
				return $author$project$Pages$Main$MainPage$connectionsView(model);
			case 4:
				return $author$project$Pages$Main$MainPage$environmentsView(model);
			case 5:
				return $author$project$Pages$Main$MainPage$endpointsView(model);
			case 6:
				return $author$project$Pages$Main$MainPage$principalsView(model);
			case 9:
				return $author$project$Pages$Main$MainPage$tenantSettingsView(model);
			case 7:
				return $author$project$Pages$Main$MainPage$logsView(model);
			default:
				return A2($author$project$Pages$Main$MainPage$revisionsView, model, branches);
		}
	});
var $author$project$Pages$Main$MainPage$getViewForRouteIfHasPermission = F4(
	function (model, branches, userPermissions, mainPageRoute) {
		return A2($author$project$Authentication$UserPermissions$hasPermissionToRoute, userPermissions, mainPageRoute) ? A3($author$project$Pages$Main$MainPage$getViewForRoute, model, branches, mainPageRoute) : $author$project$Pages$Main$MainPage$missingPermissionsView;
	});
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Pages$Main$Navbar$navItem = F3(
	function (title, currentRoute, navItemRoute) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('nav-item')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('nav-link'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'active',
									_Utils_eq(currentRoute, navItemRoute))
								])),
							$elm$html$Html$Attributes$href(
							$author$project$Routes$mainPageRouteToUrlStr(navItemRoute))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr4 = $elm$html$Html$Attributes$class('mr-4');
var $author$project$Pages$Main$Navbar$aavoBranchSelectElement = function (config) {
	return A2($elm$core$List$member, 0, config.Z) ? A2(
		$rundis$elm_bootstrap$Bootstrap$Form$Select$custom,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(config.k2),
				$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mr4]))
			]),
		A2(
			$elm$core$List$map,
			function (_v0) {
				var key = _v0.kt;
				var title = _v0.mC;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(
							_Utils_eq(key, config.l$)),
							$elm$html$Html$Attributes$value(key)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						]));
			},
			config.aA)) : $elm$html$Html$text('');
};
var $author$project$Pages$Main$Navbar$logoutButton = function (msg) {
	return A3(
		$author$project$Utils$CommonView$iconButton,
		'sign-out-alt',
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Kirjaudu ulos')
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2 = $elm$html$Html$Attributes$class('mb-2');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mlAuto = $elm$html$Html$Attributes$class('ml-auto');
var $author$project$Pages$Main$Navbar$navbarExtraControls = function (config) {
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-inline'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mlAuto,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2
			]),
		_List_fromArray(
			[
				$author$project$Pages$Main$Navbar$aavoBranchSelectElement(config),
				$author$project$Pages$Main$Navbar$logoutButton(config.kD)
			]));
};
var $author$project$Pages$Main$Navbar$navbar = function (config) {
	var currentRoute = config.iM;
	var userPermissions = config.Z;
	var navItemsFactoriesByRoutes = _List_fromArray(
		[
			_Utils_Tuple2(
			0,
			A2($author$project$Pages$Main$Navbar$navItem, 'Komennot', currentRoute)),
			_Utils_Tuple2(
			1,
			A2($author$project$Pages$Main$Navbar$navItem, 'Näkymät', currentRoute)),
			_Utils_Tuple2(
			2,
			A2($author$project$Pages$Main$Navbar$navItem, 'Roolit', currentRoute)),
			_Utils_Tuple2(
			3,
			A2($author$project$Pages$Main$Navbar$navItem, 'Yhteydet', currentRoute)),
			_Utils_Tuple2(
			4,
			A2($author$project$Pages$Main$Navbar$navItem, 'Ympäristöt', currentRoute)),
			_Utils_Tuple2(
			5,
			A2($author$project$Pages$Main$Navbar$navItem, 'Päätepisteet', currentRoute)),
			_Utils_Tuple2(
			6,
			A2($author$project$Pages$Main$Navbar$navItem, 'Käyttäjät', currentRoute)),
			_Utils_Tuple2(
			9,
			A2($author$project$Pages$Main$Navbar$navItem, 'Asetukset', currentRoute)),
			_Utils_Tuple2(
			7,
			A2($author$project$Pages$Main$Navbar$navItem, 'Loki', currentRoute)),
			_Utils_Tuple2(
			8,
			A2($author$project$Pages$Main$Navbar$navItem, 'Revisiot', currentRoute))
		]);
	var filteredNavItems = A3(
		$elm$core$List$foldr,
		F2(
			function (_v0, acc) {
				var route = _v0.a;
				var factory = _v0.b;
				return A2($author$project$Authentication$UserPermissions$hasPermissionToRoute, userPermissions, route) ? A2(
					$elm$core$List$cons,
					factory(route),
					acc) : acc;
			}),
		_List_Nil,
		navItemsFactoriesByRoutes);
	return A2(
		$elm$html$Html$ul,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('nav nav-tabs')
			]),
		_Utils_ap(
			filteredNavItems,
			_List_fromArray(
				[
					$author$project$Pages$Main$Navbar$navbarExtraControls(config)
				])));
};
var $author$project$Pages$Main$MainPage$CloseCurrentModal = {$: 15};
var $author$project$Pages$Main$MainPage$renderModal = function (model) {
	var _v0 = model.bO;
	if (!_v0.$) {
		return $elm$html$Html$text('');
	} else {
		var title = _v0.a.mC;
		var content = _v0.a.iB;
		return $author$project$Utils$CommonModals$messageModal(
			{ig: $author$project$Pages$Main$MainPage$CloseCurrentModal, iB: content, mC: title});
	}
};
var $author$project$Pages$Main$MainPage$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('main-page-root')
			]),
		function () {
			var _v0 = A3($author$project$Lib$RemoteData$map2, $elm$core$Tuple$pair, model.Z, model.aA);
			switch (_v0.$) {
				case 3:
					var _v1 = _v0.a;
					var userPermissions = _v1.a;
					var allBranches = _v1.b;
					return _List_fromArray(
						[
							$author$project$Pages$Main$Navbar$navbar(
							{aA: allBranches, iM: model.aX, kD: $author$project$Pages$Main$MainPage$RequestLogout, k2: $author$project$Pages$Main$MainPage$BranchSelected, l$: model.l$, Z: userPermissions}),
							A4($author$project$Pages$Main$MainPage$getViewForRouteIfHasPermission, model, allBranches, userPermissions, model.aX),
							$author$project$Pages$Main$MainPage$renderModal(model)
						]);
				case 2:
					var err = _v0.a;
					return _List_fromArray(
						[
							$author$project$Utils$CommonView$multilineText(
							$author$project$Utils$HttpUtils$httpErrorToString(err))
						]);
				default:
					return _List_fromArray(
						[$author$project$Utils$CommonView$centeredLargeSpinner]);
			}
		}());
};
var $author$project$Main$renderPage = function (model) {
	var _v0 = model.F;
	switch (_v0.$) {
		case 0:
			var pageModel = _v0.a;
			return A2(
				$elm$html$Html$map,
				A2($elm$core$Basics$composeL, $author$project$Main$PageMsg, $author$project$Main$MainPageMsg),
				$author$project$Pages$Main$MainPage$view(pageModel));
		case 1:
			var pageModel = _v0.a;
			return A2(
				$elm$html$Html$map,
				A2($elm$core$Basics$composeL, $author$project$Main$PageMsg, $author$project$Main$DocsPageMsg),
				$author$project$Pages$DocsPage$view(pageModel));
		default:
			return $author$project$Pages$LoggedOutPage$view(
				{lG: $author$project$Main$RequestLogin});
	}
};
var $author$project$Main$renderBody = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('root')
			]),
		_List_fromArray(
			[
				$author$project$Main$renderPage(model),
				$author$project$Main$renderModal(model)
			]));
};
var $author$project$Main$view = function (model) {
	return {
		h$: _List_fromArray(
			[
				$author$project$Main$renderBody(model)
			]),
		mC: 'Aavo Admin'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		kh: $author$project$Main$init,
		k6: $author$project$Main$UrlChanged,
		k7: $author$project$Main$LinkClicked,
		mr: $elm$core$Basics$always($elm$core$Platform$Sub$none),
		a: $author$project$Main$update,
		mZ: $author$project$Main$view
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (selectedBranchKey) {
			return $elm$json$Json$Decode$succeed(
				{l$: selectedBranchKey});
		},
		A2($elm$json$Json$Decode$field, 'selectedBranchKey', $elm$json$Json$Decode$string)))(0)}});}(this));