import '@webcomponents/webcomponentsjs';
import './scss/main.scss';
import '@fortawesome/fontawesome-free/js/all';
import {Elm} from './Main.elm';
import registerServiceWorker from './js/registerServiceWorker';
import './js/codeMirrorWebComponent';
import * as ElmDebugger from 'elm-debug-transformer';
import {handleAjaxRedirects} from './js/redirectionInterception';
import {fixFirefoxDragDrop} from './js/workarounds';
import {getAppState, saveAppState} from './js/appStateStorage';

ElmDebugger.register();
registerServiceWorker();
handleAjaxRedirects();
fixFirefoxDragDrop();

const appState = getAppState()

const app = Elm.Main.init({
    node: document.getElementById('root'),
    flags: {
        selectedBranchKey: appState.selectedBranchKey
    }
});

app.ports.selectedBranchChanged.subscribe(branchKey => {
    saveAppState({
        selectedBranchKey: branchKey
    });
});
